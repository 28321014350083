(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', 'snabbdom/helpers/attachto', 'snabbdom/modules/attributes', 'snabbdom/modules/class', 'snabbdom/modules/dataset', 'snabbdom/modules/eventlisteners', 'snabbdom/modules/hero', 'snabbdom/htmldomapi', 'snabbdom/modules/props', 'snabbdom', 'snabbdom/modules/style', 'snabbdom/thunk', 'snabbdom/tovnode', 'snabbdom/vnode', 'navigo', 'jquery', 'snabbdom'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('snabbdom/helpers/attachto'), require('snabbdom/modules/attributes'), require('snabbdom/modules/class'), require('snabbdom/modules/dataset'), require('snabbdom/modules/eventlisteners'), require('snabbdom/modules/hero'), require('snabbdom/htmldomapi'), require('snabbdom/modules/props'), require('snabbdom'), require('snabbdom/modules/style'), require('snabbdom/thunk'), require('snabbdom/tovnode'), require('snabbdom/vnode'), require('navigo'), require('jquery'), require('snabbdom'));
  else {
    if (typeof attachTo === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/helpers/attachto' was not found. Please, check whether 'snabbdom/helpers/attachto' is loaded prior to 'neckarIT-ui'.");
    }if (typeof attributesModule_ext === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/modules/attributes' was not found. Please, check whether 'snabbdom/modules/attributes' is loaded prior to 'neckarIT-ui'.");
    }if (typeof classModule_ext === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/modules/class' was not found. Please, check whether 'snabbdom/modules/class' is loaded prior to 'neckarIT-ui'.");
    }if (typeof datasetModule_ext === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/modules/dataset' was not found. Please, check whether 'snabbdom/modules/dataset' is loaded prior to 'neckarIT-ui'.");
    }if (typeof eventListenersModule_ext === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/modules/eventlisteners' was not found. Please, check whether 'snabbdom/modules/eventlisteners' is loaded prior to 'neckarIT-ui'.");
    }if (typeof heroModule_ext === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/modules/hero' was not found. Please, check whether 'snabbdom/modules/hero' is loaded prior to 'neckarIT-ui'.");
    }if (typeof htmlDomApiModule === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/htmldomapi' was not found. Please, check whether 'snabbdom/htmldomapi' is loaded prior to 'neckarIT-ui'.");
    }if (typeof propsModule_ext === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/modules/props' was not found. Please, check whether 'snabbdom/modules/props' is loaded prior to 'neckarIT-ui'.");
    }if (typeof Snabbdom === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom' was not found. Please, check whether 'snabbdom' is loaded prior to 'neckarIT-ui'.");
    }if (typeof styleModule_ext === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/modules/style' was not found. Please, check whether 'snabbdom/modules/style' is loaded prior to 'neckarIT-ui'.");
    }if (typeof thunkModule === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/thunk' was not found. Please, check whether 'snabbdom/thunk' is loaded prior to 'neckarIT-ui'.");
    }if (typeof toVNodeModule === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/tovnode' was not found. Please, check whether 'snabbdom/tovnode' is loaded prior to 'neckarIT-ui'.");
    }if (typeof vNodeModule === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom/vnode' was not found. Please, check whether 'snabbdom/vnode' is loaded prior to 'neckarIT-ui'.");
    }if (typeof Navigo === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'navigo' was not found. Please, check whether 'navigo' is loaded prior to 'neckarIT-ui'.");
    }if (typeof jQuery === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'jquery' was not found. Please, check whether 'jquery' is loaded prior to 'neckarIT-ui'.");
    }if (typeof snabbdom === 'undefined') {
      throw new Error("Error loading module 'neckarIT-ui'. Its dependency 'snabbdom' was not found. Please, check whether 'snabbdom' is loaded prior to 'neckarIT-ui'.");
    }root['neckarIT-ui'] = factory(typeof this['neckarIT-ui'] === 'undefined' ? {} : this['neckarIT-ui'], attachTo, attributesModule_ext, classModule_ext, datasetModule_ext, eventListenersModule_ext, heroModule_ext, htmlDomApiModule, propsModule_ext, Snabbdom, styleModule_ext, thunkModule, toVNodeModule, vNodeModule, Navigo, jQuery, snabbdom);
  }
}(this, function (_, attachTo, attributesModule_ext, classModule_ext, datasetModule_ext, eventListenersModule_ext, heroModule_ext, htmlDomApiModule, propsModule_ext, Snabbdom, styleModule_ext, thunkModule, toVNodeModule, vNodeModule, Navigo, jQuery, $module$snabbdom) {
  var h = $module$snabbdom.h;
  var h = $module$snabbdom.h;
  var h = $module$snabbdom.h;
  var h = $module$snabbdom.h;
  'use strict';
  AbstractMutableCollection.prototype = Object.create(AbstractCollection.prototype);
  AbstractMutableCollection.prototype.constructor = AbstractMutableCollection;
  AbstractMutableList.prototype = Object.create(AbstractMutableCollection.prototype);
  AbstractMutableList.prototype.constructor = AbstractMutableList;
  ArrayDeque.prototype = Object.create(AbstractMutableList.prototype);
  ArrayDeque.prototype.constructor = ArrayDeque;
  DoubleTimeMark.prototype = Object.create(TimeMark.prototype);
  DoubleTimeMark.prototype.constructor = DoubleTimeMark;
  IntProgressionIterator.prototype = Object.create(IntIterator.prototype);
  IntProgressionIterator.prototype.constructor = IntProgressionIterator;
  IntRange.prototype = Object.create(IntProgression.prototype);
  IntRange.prototype.constructor = IntRange;
  ListIteratorImpl.prototype = Object.create(IteratorImpl.prototype);
  ListIteratorImpl.prototype.constructor = ListIteratorImpl;
  AbstractMutableSet.prototype = Object.create(AbstractMutableCollection.prototype);
  AbstractMutableSet.prototype.constructor = AbstractMutableSet;
  AbstractEntrySet.prototype = Object.create(AbstractMutableSet.prototype);
  AbstractEntrySet.prototype.constructor = AbstractEntrySet;
  _no_name_provided__7.prototype = Object.create(AbstractMutableSet.prototype);
  _no_name_provided__7.prototype.constructor = _no_name_provided__7;
  _no_name_provided__8.prototype = Object.create(AbstractMutableCollection.prototype);
  _no_name_provided__8.prototype.constructor = _no_name_provided__8;
  AbstractMutableMap.prototype = Object.create(AbstractMap.prototype);
  AbstractMutableMap.prototype.constructor = AbstractMutableMap;
  ArrayList.prototype = Object.create(AbstractMutableList.prototype);
  ArrayList.prototype.constructor = ArrayList;
  EntrySet.prototype = Object.create(AbstractEntrySet.prototype);
  EntrySet.prototype.constructor = EntrySet;
  HashMap.prototype = Object.create(AbstractMutableMap.prototype);
  HashMap.prototype.constructor = HashMap;
  HashSet.prototype = Object.create(AbstractMutableSet.prototype);
  HashSet.prototype.constructor = HashSet;
  ChainEntry.prototype = Object.create(SimpleEntry.prototype);
  ChainEntry.prototype.constructor = ChainEntry;
  EntrySet_0.prototype = Object.create(AbstractEntrySet.prototype);
  EntrySet_0.prototype.constructor = EntrySet_0;
  LinkedHashMap.prototype = Object.create(HashMap.prototype);
  LinkedHashMap.prototype.constructor = LinkedHashMap;
  LinkedHashSet.prototype = Object.create(HashSet.prototype);
  LinkedHashSet.prototype.constructor = LinkedHashSet;
  NodeJsOutput_0.prototype = Object.create(BaseOutput.prototype);
  NodeJsOutput_0.prototype.constructor = NodeJsOutput_0;
  BufferedOutput_0.prototype = Object.create(BaseOutput.prototype);
  BufferedOutput_0.prototype.constructor = BufferedOutput_0;
  BufferedOutputToConsoleLog_0.prototype = Object.create(BufferedOutput_0.prototype);
  BufferedOutputToConsoleLog_0.prototype.constructor = BufferedOutputToConsoleLog_0;
  PrimitiveKClassImpl.prototype = Object.create(KClassImpl.prototype);
  PrimitiveKClassImpl.prototype.constructor = PrimitiveKClassImpl;
  NothingKClassImpl.prototype = Object.create(KClassImpl.prototype);
  NothingKClassImpl.prototype.constructor = NothingKClassImpl;
  SimpleKClassImpl.prototype = Object.create(KClassImpl.prototype);
  SimpleKClassImpl.prototype.constructor = SimpleKClassImpl;
  DurationUnit.prototype = Object.create(Enum.prototype);
  DurationUnit.prototype.constructor = DurationUnit;
  _no_name_provided__32.prototype = Object.create(TimeMark.prototype);
  _no_name_provided__32.prototype.constructor = _no_name_provided__32;
  PerformanceTimeSource.prototype = Object.create(AbstractDoubleTimeSource.prototype);
  PerformanceTimeSource.prototype.constructor = PerformanceTimeSource;
  DateNowTimeSource.prototype = Object.create(AbstractDoubleTimeSource.prototype);
  DateNowTimeSource.prototype.constructor = DateNowTimeSource;
  Long.prototype = Object.create(Number_0.prototype);
  Long.prototype.constructor = Long;
  Exception.prototype = Object.create(Error.prototype);
  Exception.prototype.constructor = Exception;
  RuntimeException.prototype = Object.create(Exception.prototype);
  RuntimeException.prototype.constructor = RuntimeException;
  IllegalArgumentException.prototype = Object.create(RuntimeException.prototype);
  IllegalArgumentException.prototype.constructor = IllegalArgumentException;
  NoSuchElementException.prototype = Object.create(RuntimeException.prototype);
  NoSuchElementException.prototype.constructor = NoSuchElementException;
  IllegalStateException.prototype = Object.create(RuntimeException.prototype);
  IllegalStateException.prototype.constructor = IllegalStateException;
  IndexOutOfBoundsException.prototype = Object.create(RuntimeException.prototype);
  IndexOutOfBoundsException.prototype.constructor = IndexOutOfBoundsException;
  UnsupportedOperationException.prototype = Object.create(RuntimeException.prototype);
  UnsupportedOperationException.prototype.constructor = UnsupportedOperationException;
  ArithmeticException.prototype = Object.create(RuntimeException.prototype);
  ArithmeticException.prototype.constructor = ArithmeticException;
  NullPointerException.prototype = Object.create(RuntimeException.prototype);
  NullPointerException.prototype.constructor = NullPointerException;
  NoWhenBranchMatchedException.prototype = Object.create(RuntimeException.prototype);
  NoWhenBranchMatchedException.prototype.constructor = NoWhenBranchMatchedException;
  ClassCastException.prototype = Object.create(RuntimeException.prototype);
  ClassCastException.prototype.constructor = ClassCastException;
  UninitializedPropertyAccessException.prototype = Object.create(RuntimeException.prototype);
  UninitializedPropertyAccessException.prototype.constructor = UninitializedPropertyAccessException;
  ENUM.prototype = Object.create(SerialKind.prototype);
  ENUM.prototype.constructor = ENUM;
  CONTEXTUAL.prototype = Object.create(SerialKind.prototype);
  CONTEXTUAL.prototype.constructor = CONTEXTUAL;
  PrimitiveKind.prototype = Object.create(SerialKind.prototype);
  PrimitiveKind.prototype.constructor = PrimitiveKind;
  StructureKind.prototype = Object.create(SerialKind.prototype);
  StructureKind.prototype.constructor = StructureKind;
  LIST.prototype = Object.create(StructureKind.prototype);
  LIST.prototype.constructor = LIST;
  MAP.prototype = Object.create(StructureKind.prototype);
  MAP.prototype.constructor = MAP;
  PolymorphicKind.prototype = Object.create(SerialKind.prototype);
  PolymorphicKind.prototype.constructor = PolymorphicKind;
  SerialModuleImpl.prototype = Object.create(SerializersModule.prototype);
  SerialModuleImpl.prototype.constructor = SerialModuleImpl;
  Default.prototype = Object.create(Json_0.prototype);
  Default.prototype.constructor = Default;
  JsonImpl.prototype = Object.create(Json_0.prototype);
  JsonImpl.prototype.constructor = JsonImpl;
  UNIT.prototype = Object.create(Enum.prototype);
  UNIT.prototype.constructor = UNIT;
  JustifyContent.prototype = Object.create(Enum.prototype);
  JustifyContent.prototype.constructor = JustifyContent;
  AlignItems.prototype = Object.create(Enum.prototype);
  AlignItems.prototype.constructor = AlignItems;
  Cursor.prototype = Object.create(Enum.prototype);
  Cursor.prototype.constructor = Cursor;
  Display.prototype = Object.create(Enum.prototype);
  Display.prototype.constructor = Display;
  FlexWrap.prototype = Object.create(Enum.prototype);
  FlexWrap.prototype.constructor = FlexWrap;
  Position.prototype = Object.create(Enum.prototype);
  Position.prototype.constructor = Position;
  Overflow.prototype = Object.create(Enum.prototype);
  Overflow.prototype.constructor = Overflow;
  OverflowWrap.prototype = Object.create(Enum.prototype);
  OverflowWrap.prototype.constructor = OverflowWrap;
  Resize.prototype = Object.create(Enum.prototype);
  Resize.prototype.constructor = Resize;
  Direction.prototype = Object.create(Enum.prototype);
  Direction.prototype.constructor = Direction;
  TextAlign.prototype = Object.create(Enum.prototype);
  TextAlign.prototype.constructor = TextAlign;
  TextTransform.prototype = Object.create(Enum.prototype);
  TextTransform.prototype.constructor = TextTransform;
  TextOverflow.prototype = Object.create(Enum.prototype);
  TextOverflow.prototype.constructor = TextOverflow;
  UnicodeBidi.prototype = Object.create(Enum.prototype);
  UnicodeBidi.prototype.constructor = UnicodeBidi;
  VerticalAlign.prototype = Object.create(Enum.prototype);
  VerticalAlign.prototype.constructor = VerticalAlign;
  WhiteSpace.prototype = Object.create(Enum.prototype);
  WhiteSpace.prototype.constructor = WhiteSpace;
  FontStyle.prototype = Object.create(Enum.prototype);
  FontStyle.prototype.constructor = FontStyle;
  FontWeight.prototype = Object.create(Enum.prototype);
  FontWeight.prototype.constructor = FontWeight;
  FontVariant.prototype = Object.create(Enum.prototype);
  FontVariant.prototype.constructor = FontVariant;
  PosFloat.prototype = Object.create(Enum.prototype);
  PosFloat.prototype.constructor = PosFloat;
  Clear.prototype = Object.create(Enum.prototype);
  Clear.prototype.constructor = Clear;
  WordBreak.prototype = Object.create(Enum.prototype);
  WordBreak.prototype.constructor = WordBreak;
  LineBreak.prototype = Object.create(Enum.prototype);
  LineBreak.prototype.constructor = LineBreak;
  FlexDirection.prototype = Object.create(Enum.prototype);
  FlexDirection.prototype.constructor = FlexDirection;
  JustifyItems.prototype = Object.create(Enum.prototype);
  JustifyItems.prototype.constructor = JustifyItems;
  AlignContent.prototype = Object.create(Enum.prototype);
  AlignContent.prototype.constructor = AlignContent;
  GridAutoFlow.prototype = Object.create(Enum.prototype);
  GridAutoFlow.prototype.constructor = GridAutoFlow;
  Trigger.prototype = Object.create(Enum.prototype);
  Trigger.prototype.constructor = Trigger;
  Style.prototype = Object.create(StyledComponent.prototype);
  Style.prototype.constructor = Style;
  Widget.prototype = Object.create(StyledComponent.prototype);
  Widget.prototype.constructor = Widget;
  Easing.prototype = Object.create(Enum.prototype);
  Easing.prototype.constructor = Easing;
  SimplePanel.prototype = Object.create(Widget.prototype);
  SimplePanel.prototype.constructor = SimplePanel;
  WidgetWrapper.prototype = Object.create(SimplePanel.prototype);
  WidgetWrapper.prototype.constructor = WidgetWrapper;
  Tag.prototype = Object.create(SimplePanel.prototype);
  Tag.prototype.constructor = Tag;
  FieldLabel.prototype = Object.create(Tag.prototype);
  FieldLabel.prototype.constructor = FieldLabel;
  ValidationStatus.prototype = Object.create(Enum.prototype);
  ValidationStatus.prototype.constructor = ValidationStatus;
  InputSize.prototype = Object.create(Enum.prototype);
  InputSize.prototype.constructor = InputSize;
  InvalidFeedback.prototype = Object.create(Tag.prototype);
  InvalidFeedback.prototype.constructor = InvalidFeedback;
  CheckInput.prototype = Object.create(Widget.prototype);
  CheckInput.prototype.constructor = CheckInput;
  CheckInputType.prototype = Object.create(Enum.prototype);
  CheckInputType.prototype.constructor = CheckInputType;
  GenericRadioGroup.prototype = Object.create(SimplePanel.prototype);
  GenericRadioGroup.prototype.constructor = GenericRadioGroup;
  Radio.prototype = Object.create(SimplePanel.prototype);
  Radio.prototype.constructor = Radio;
  RadioStyle.prototype = Object.create(Enum.prototype);
  RadioStyle.prototype.constructor = RadioStyle;
  RadioInput.prototype = Object.create(CheckInput.prototype);
  RadioInput.prototype.constructor = RadioInput;
  AbstractText.prototype = Object.create(SimplePanel.prototype);
  AbstractText.prototype.constructor = AbstractText;
  AbstractTextInput.prototype = Object.create(Widget.prototype);
  AbstractTextInput.prototype.constructor = AbstractTextInput;
  Text_0.prototype = Object.create(AbstractText.prototype);
  Text_0.prototype.constructor = Text_0;
  TextInput.prototype = Object.create(AbstractTextInput.prototype);
  TextInput.prototype.constructor = TextInput;
  TextInputType.prototype = Object.create(Enum.prototype);
  TextInputType.prototype.constructor = TextInputType;
  Button.prototype = Object.create(SimplePanel.prototype);
  Button.prototype.constructor = Button;
  ButtonStyle.prototype = Object.create(Enum.prototype);
  ButtonStyle.prototype.constructor = ButtonStyle;
  ButtonType.prototype = Object.create(Enum.prototype);
  ButtonType.prototype.constructor = ButtonType;
  ButtonSize.prototype = Object.create(Enum.prototype);
  ButtonSize.prototype.constructor = ButtonSize;
  Div.prototype = Object.create(Tag.prototype);
  Div.prototype.constructor = Div;
  Footer.prototype = Object.create(Tag.prototype);
  Footer.prototype.constructor = Footer;
  H1.prototype = Object.create(Tag.prototype);
  H1.prototype.constructor = H1;
  H6.prototype = Object.create(Tag.prototype);
  H6.prototype.constructor = H6;
  Header.prototype = Object.create(Tag.prototype);
  Header.prototype.constructor = Header;
  Main.prototype = Object.create(Tag.prototype);
  Main.prototype.constructor = Main;
  Span.prototype = Object.create(Tag.prototype);
  Span.prototype.constructor = Span;
  Align.prototype = Object.create(Enum.prototype);
  Align.prototype.constructor = Align;
  TAG.prototype = Object.create(Enum.prototype);
  TAG.prototype.constructor = TAG;
  FlexPanel.prototype = Object.create(SimplePanel.prototype);
  FlexPanel.prototype.constructor = FlexPanel;
  HPanel.prototype = Object.create(FlexPanel.prototype);
  HPanel.prototype.constructor = HPanel;
  Root.prototype = Object.create(SimplePanel.prototype);
  Root.prototype.constructor = Root;
  ContainerType.prototype = Object.create(Enum.prototype);
  ContainerType.prototype.constructor = ContainerType;
  Routing.prototype = Object.create(Navigo.prototype);
  Routing.prototype.constructor = Routing;
  _no_name_provided__1_0.prototype = Object.create(ObservableProperty.prototype);
  _no_name_provided__1_0.prototype.constructor = _no_name_provided__1_0;
  CloseIcon.prototype = Object.create(Widget.prototype);
  CloseIcon.prototype.constructor = CloseIcon;
  Modal.prototype = Object.create(SimplePanel.prototype);
  Modal.prototype.constructor = Modal;
  Confirm.prototype = Object.create(Modal.prototype);
  Confirm.prototype.constructor = Confirm;
  ModalSize.prototype = Object.create(Enum.prototype);
  ModalSize.prototype.constructor = ModalSize;
  ModalDialog.prototype = Object.create(SimplePanel.prototype);
  ModalDialog.prototype.constructor = ModalDialog;
  ButtonGroup.prototype = Object.create(SimplePanel.prototype);
  ButtonGroup.prototype.constructor = ButtonGroup;
  ButtonGroupSize.prototype = Object.create(Enum.prototype);
  ButtonGroupSize.prototype.constructor = ButtonGroupSize;
  Spinner.prototype = Object.create(SimplePanel.prototype);
  Spinner.prototype.constructor = Spinner;
  ButtonsType.prototype = Object.create(Enum.prototype);
  ButtonsType.prototype.constructor = ButtonsType;
  ForceType.prototype = Object.create(Enum.prototype);
  ForceType.prototype.constructor = ForceType;
  SpinnerInput.prototype = Object.create(Widget.prototype);
  SpinnerInput.prototype.constructor = SpinnerInput;
  DayOfWeek.prototype = Object.create(Enum.prototype);
  DayOfWeek.prototype.constructor = DayOfWeek;
  English.prototype = Object.create(KlockLocale.prototype);
  English.prototype.constructor = English;
  Companion_22.prototype = Object.create(English.prototype);
  Companion_22.prototype.constructor = Companion_22;
  ContentAreaOverrideChartState.prototype = Object.create(DelegatingChartState.prototype);
  ContentAreaOverrideChartState.prototype.constructor = ContentAreaOverrideChartState;
  LinearValueRange.prototype = Object.create(ValueRange.prototype);
  LinearValueRange.prototype.constructor = LinearValueRange;
  AxisOrientationX.prototype = Object.create(Enum.prototype);
  AxisOrientationX.prototype.constructor = AxisOrientationX;
  AxisOrientationY.prototype = Object.create(Enum.prototype);
  AxisOrientationY.prototype.constructor = AxisOrientationY;
  AxisSelection.prototype = Object.create(Enum.prototype);
  AxisSelection.prototype.constructor = AxisSelection;
  Direction_0.prototype = Object.create(Enum.prototype);
  Direction_0.prototype.constructor = Direction_0;
  HorizontalAlignment.prototype = Object.create(Enum.prototype);
  HorizontalAlignment.prototype.constructor = HorizontalAlignment;
  VerticalAlignment.prototype = Object.create(Enum.prototype);
  VerticalAlignment.prototype.constructor = VerticalAlignment;
  ObservableBoolean.prototype = Object.create(ObservableObject.prototype);
  ObservableBoolean.prototype.constructor = ObservableBoolean;
  ObservableDouble.prototype = Object.create(ObservableObject.prototype);
  ObservableDouble.prototype.constructor = ObservableDouble;
  ObservableString.prototype = Object.create(ObservableObject.prototype);
  ObservableString.prototype.constructor = ObservableString;
  KeepCenterOnResize.prototype = Object.create(KeepLocation.prototype);
  KeepCenterOnResize.prototype.constructor = KeepCenterOnResize;
  DefaultChartState.prototype = Object.create(AbstractChartState.prototype);
  DefaultChartState.prototype.constructor = DefaultChartState;
  FittingWithMarginAspectRatio.prototype = Object.create(FittingWithMargin.prototype);
  FittingWithMarginAspectRatio.prototype.constructor = FittingWithMarginAspectRatio;
  LayerType.prototype = Object.create(Enum.prototype);
  LayerType.prototype.constructor = LayerType;
  RubberBandVisualizationLayer.prototype = Object.create(AbstractLayer.prototype);
  RubberBandVisualizationLayer.prototype.constructor = RubberBandVisualizationLayer;
  ZoomAndTranslationLayer.prototype = Object.create(AbstractLayer.prototype);
  ZoomAndTranslationLayer.prototype.constructor = ZoomAndTranslationLayer;
  ObjectFit.prototype = Object.create(Enum.prototype);
  ObjectFit.prototype.constructor = ObjectFit;
  LineJoin.prototype = Object.create(Enum.prototype);
  LineJoin.prototype.constructor = LineJoin;
  LocationType.prototype = Object.create(Enum.prototype);
  LocationType.prototype.constructor = LocationType;
  StrokeLocation.prototype = Object.create(Enum.prototype);
  StrokeLocation.prototype.constructor = StrokeLocation;
  CanvasType.prototype = Object.create(Enum.prototype);
  CanvasType.prototype.constructor = CanvasType;
  SizeClassification.prototype = Object.create(Enum.prototype);
  SizeClassification.prototype.constructor = SizeClassification;
  BindContentArea2WindowSize.prototype = Object.create(AbstractContentAreaSizingStrategy.prototype);
  BindContentArea2WindowSize.prototype.constructor = BindContentArea2WindowSize;
  BindContentArea2Property.prototype = Object.create(AbstractContentAreaSizingStrategy.prototype);
  BindContentArea2Property.prototype.constructor = BindContentArea2Property;
  DebugFeature.prototype = Object.create(Enum.prototype);
  DebugFeature.prototype.constructor = DebugFeature;
  FontDescriptor.prototype = Object.create(FontDescriptorFragment.prototype);
  FontDescriptor.prototype.constructor = FontDescriptor;
  FontStyle_0.prototype = Object.create(Enum.prototype);
  FontStyle_0.prototype.constructor = FontStyle_0;
  FontVariant_0.prototype = Object.create(Enum.prototype);
  FontVariant_0.prototype.constructor = FontVariant_0;
  MouseCursor.prototype = Object.create(Enum.prototype);
  MouseCursor.prototype.constructor = MouseCursor;
  SnapConfiguration.prototype = Object.create(Enum.prototype);
  SnapConfiguration.prototype.constructor = SnapConfiguration;
  KeyEvent.prototype = Object.create(UiEvent.prototype);
  KeyEvent.prototype.constructor = KeyEvent;
  KeyDownEvent.prototype = Object.create(KeyEvent.prototype);
  KeyDownEvent.prototype.constructor = KeyDownEvent;
  KeyUpEvent.prototype = Object.create(KeyEvent.prototype);
  KeyUpEvent.prototype.constructor = KeyUpEvent;
  KeyTypeEvent.prototype = Object.create(KeyEvent.prototype);
  KeyTypeEvent.prototype.constructor = KeyTypeEvent;
  MouseEvent_0.prototype = Object.create(UiEvent.prototype);
  MouseEvent_0.prototype.constructor = MouseEvent_0;
  MouseClickEvent.prototype = Object.create(MouseEvent_0.prototype);
  MouseClickEvent.prototype.constructor = MouseClickEvent;
  MouseDoubleClickEvent.prototype = Object.create(MouseEvent_0.prototype);
  MouseDoubleClickEvent.prototype.constructor = MouseDoubleClickEvent;
  MouseMoveEvent.prototype = Object.create(MouseEvent_0.prototype);
  MouseMoveEvent.prototype.constructor = MouseMoveEvent;
  MouseDragEvent.prototype = Object.create(MouseEvent_0.prototype);
  MouseDragEvent.prototype.constructor = MouseDragEvent;
  MouseWheelEvent.prototype = Object.create(MouseEvent_0.prototype);
  MouseWheelEvent.prototype.constructor = MouseWheelEvent;
  MouseDownEvent.prototype = Object.create(MouseEvent_0.prototype);
  MouseDownEvent.prototype.constructor = MouseDownEvent;
  MouseUpEvent.prototype = Object.create(MouseEvent_0.prototype);
  MouseUpEvent.prototype.constructor = MouseUpEvent;
  PointerEvent_0.prototype = Object.create(UiEvent.prototype);
  PointerEvent_0.prototype.constructor = PointerEvent_0;
  PointerOverEvent.prototype = Object.create(PointerEvent_0.prototype);
  PointerOverEvent.prototype.constructor = PointerOverEvent;
  PointerEnterEvent.prototype = Object.create(PointerEvent_0.prototype);
  PointerEnterEvent.prototype.constructor = PointerEnterEvent;
  PointerDownEvent.prototype = Object.create(PointerEvent_0.prototype);
  PointerDownEvent.prototype.constructor = PointerDownEvent;
  PointerMoveEvent.prototype = Object.create(PointerEvent_0.prototype);
  PointerMoveEvent.prototype.constructor = PointerMoveEvent;
  PointerUpEvent.prototype = Object.create(PointerEvent_0.prototype);
  PointerUpEvent.prototype.constructor = PointerUpEvent;
  PointerCancelEvent.prototype = Object.create(PointerEvent_0.prototype);
  PointerCancelEvent.prototype.constructor = PointerCancelEvent;
  PointerOutEvent.prototype = Object.create(PointerEvent_0.prototype);
  PointerOutEvent.prototype.constructor = PointerOutEvent;
  PointerLeaveEvent.prototype = Object.create(PointerEvent_0.prototype);
  PointerLeaveEvent.prototype.constructor = PointerLeaveEvent;
  TouchEvent_0.prototype = Object.create(UiEvent.prototype);
  TouchEvent_0.prototype.constructor = TouchEvent_0;
  TouchStartEvent.prototype = Object.create(TouchEvent_0.prototype);
  TouchStartEvent.prototype.constructor = TouchStartEvent;
  TouchEndEvent.prototype = Object.create(TouchEvent_0.prototype);
  TouchEndEvent.prototype.constructor = TouchEndEvent;
  TouchMoveEvent.prototype = Object.create(TouchEvent_0.prototype);
  TouchMoveEvent.prototype.constructor = TouchMoveEvent;
  TouchCancelEvent.prototype = Object.create(TouchEvent_0.prototype);
  TouchCancelEvent.prototype.constructor = TouchCancelEvent;
  CanvasFontMetricsCalculatorJS.prototype = Object.create(AbstractCanvasFontMetricsCalculator.prototype);
  CanvasFontMetricsCalculatorJS.prototype.constructor = CanvasFontMetricsCalculatorJS;
  CanvasJS.prototype = Object.create(AbstractCanvas.prototype);
  CanvasJS.prototype.constructor = CanvasJS;
  CanvasRenderingContextJS.prototype = Object.create(AbstractCanvasRenderingContext.prototype);
  CanvasRenderingContextJS.prototype.constructor = CanvasRenderingContextJS;
  QuickChartBuilderJS.prototype = Object.create(QuickChartBuilder.prototype);
  QuickChartBuilderJS.prototype.constructor = QuickChartBuilderJS;
  AbsoluteLayout.prototype = Object.create(ModuleLayout.prototype);
  AbsoluteLayout.prototype.constructor = AbsoluteLayout;
  GridLayout.prototype = Object.create(ModuleLayout.prototype);
  GridLayout.prototype.constructor = GridLayout;
  ModuleOrientation.prototype = Object.create(Enum.prototype);
  ModuleOrientation.prototype.constructor = ModuleOrientation;
  RoofPlanningLayer.prototype = Object.create(AbstractLayer.prototype);
  RoofPlanningLayer.prototype.constructor = RoofPlanningLayer;
  App.prototype = Object.create(Application.prototype);
  App.prototype.constructor = App;
  ConfigureModuleOrientationDialog.prototype = Object.create(AbstractEditDialog.prototype);
  ConfigureModuleOrientationDialog.prototype.constructor = ConfigureModuleOrientationDialog;
  ConfigureModuleSizeDialog.prototype = Object.create(AbstractEditDialog.prototype);
  ConfigureModuleSizeDialog.prototype.constructor = ConfigureModuleSizeDialog;
  ConfigureRoofSizeDialog.prototype = Object.create(AbstractEditDialog.prototype);
  ConfigureRoofSizeDialog.prototype.constructor = ConfigureRoofSizeDialog;
  UnusableAreaDescriptionDialog.prototype = Object.create(AbstractEditDialog.prototype);
  UnusableAreaDescriptionDialog.prototype.constructor = UnusableAreaDescriptionDialog;
  function indexOf(_this_, element) {
    if (element == null) {
      var inductionVariable = 0;
      var last_1 = _this_.length - 1 | 0;
      if (inductionVariable <= last_1)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (_this_[index] == null) {
            return index;
          }}
         while (inductionVariable <= last_1);
    } else {
      var inductionVariable_0 = 0;
      var last_2 = _this_.length - 1 | 0;
      if (inductionVariable_0 <= last_2)
        do {
          var index_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (equals_0(element, _this_[index_0])) {
            return index_0;
          }}
         while (inductionVariable_0 <= last_2);
    }
    return -1;
  }
  function toSet(_this_) {
    var tmp0_subject = _this_.length;
    switch (tmp0_subject) {
      case 0:
        return emptySet();
      case 1:
        return setOf(_this_[0]);
      default:return toCollection(_this_, LinkedHashSet_init_$Create$_1(mapCapacity(_this_.length)));
    }
  }
  function joinToString(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo(_this_, StringBuilder_init_$Create$_0(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function single(_this_) {
    var tmp0_subject = _this_.length;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        throw NoSuchElementException_init_$Create$_0('Array is empty.');
      case 1:
        tmp = _this_[0];
        break;
      default:throw IllegalArgumentException_init_$Create$('Array has more than one element.');
    }
    return tmp;
  }
  function _get_lastIndex_(_this_) {
    return _this_.length - 1 | 0;
  }
  function toCollection(_this_, destination) {
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_1 = indexedObject.length;
    while (inductionVariable < last_1) {
      var item = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      destination.add_57(item);
      Unit_getInstance();
    }
    return destination;
  }
  function joinTo(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_3(prefix);
    Unit_getInstance();
    var count = 0;
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_1 = indexedObject.length;
    $l$break: while (inductionVariable < last_1) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      count = count + 1 | 0;
      if (count > 1) {
        buffer.append_3(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count <= limit) {
        appendElement(buffer, element, transform);
      } else
        break $l$break;
    }
    if (limit >= 0 ? count > limit : false) {
      buffer.append_3(truncated);
      Unit_getInstance();
    }buffer.append_3(postfix);
    Unit_getInstance();
    return buffer;
  }
  function contains(_this_, element) {
    return indexOf(_this_, element) >= 0;
  }
  function joinToString_0(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo_0(_this_, StringBuilder_init_$Create$_0(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default_0(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString_0(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function first(_this_) {
    var tmp0_subject = _this_;
    if (isInterface(tmp0_subject, List))
      return first_0(_this_);
    else {
      {
        var iterator_0 = _this_.iterator_38();
        if (!iterator_0.hasNext_11())
          throw NoSuchElementException_init_$Create$_0('Collection is empty.');
        return iterator_0.next_13();
      }
    }
  }
  function toList(_this_) {
    if (isInterface(_this_, Collection)) {
      var tmp0_subject = _this_._get_size__43();
      var tmp;
      switch (tmp0_subject) {
        case 0:
          tmp = emptyList();
          break;
        case 1:
          var tmp_0;
          if (isInterface(_this_, List)) {
            tmp_0 = _this_.get_44(0);
          } else {
            {
              tmp_0 = _this_.iterator_38().next_13();
            }
          }

          tmp = listOf_0(tmp_0);
          break;
        default:tmp = toMutableList(_this_);
          break;
      }
      return tmp;
    } else {
    }
    return optimizeReadOnlyList(toMutableList_0(_this_));
  }
  function drop(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$(toString_2(message_2));
    }if (n === 0)
      return toList(_this_);
    var list;
    if (isInterface(_this_, Collection)) {
      var resultSize = _this_._get_size__43() - n | 0;
      if (resultSize <= 0)
        return emptyList();
      if (resultSize === 1)
        return listOf_0(last(_this_));
      list = ArrayList_init_$Create$_0(resultSize);
      if (isInterface(_this_, List)) {
        if (isInterface(_this_, RandomAccess)) {
          var inductionVariable = n;
          var last_1 = _this_._get_size__43();
          if (inductionVariable < last_1)
            do {
              var index = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              list.add_57(_this_.get_44(index));
              Unit_getInstance();
            }
             while (inductionVariable < last_1);
        } else {
          {
            var tmp1_iterator_0 = _this_.listIterator_3(n);
            var tmp1_iterator = tmp1_iterator_0;
            while (tmp1_iterator.hasNext_11()) {
              var item = tmp1_iterator.next_13();
              list.add_57(item);
              Unit_getInstance();
            }
          }
        }
        return list;
      } else {
      }
    } else {
      {
        list = ArrayList_init_$Create$();
      }
    }
    var count = 0;
    var tmp2_iterator = _this_.iterator_38();
    while (tmp2_iterator.hasNext_11()) {
      var item_0 = tmp2_iterator.next_13();
      if (count >= n) {
        list.add_57(item_0);
        Unit_getInstance();
      } else {
        count = count + 1 | 0;
        Unit_getInstance();
      }
    }
    return optimizeReadOnlyList(list);
  }
  function reversed(_this_) {
    var tmp;
    if (isInterface(_this_, Collection)) {
      tmp = _this_._get_size__43() <= 1;
    } else {
      {
        tmp = false;
      }
    }
    if (tmp)
      return toList(_this_);
    else {
    }
    var list = toMutableList_0(_this_);
    reverse(list);
    return list;
  }
  function first_0(_this_) {
    if (_this_.isEmpty_33())
      throw NoSuchElementException_init_$Create$_0('List is empty.');
    return _this_.get_44(0);
  }
  function firstOrNull(_this_) {
    return _this_.isEmpty_33() ? null : _this_.get_44(0);
  }
  function toCollection_0(_this_, destination) {
    var tmp0_iterator = _this_.iterator_38();
    while (tmp0_iterator.hasNext_11()) {
      var item = tmp0_iterator.next_13();
      destination.add_57(item);
      Unit_getInstance();
    }
    return destination;
  }
  function joinTo_0(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_3(prefix);
    Unit_getInstance();
    var count = 0;
    var tmp0_iterator = _this_.iterator_38();
    $l$break: while (tmp0_iterator.hasNext_11()) {
      var element = tmp0_iterator.next_13();
      count = count + 1 | 0;
      if (count > 1) {
        buffer.append_3(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count <= limit) {
        appendElement(buffer, element, transform);
      } else
        break $l$break;
    }
    if (limit >= 0 ? count > limit : false) {
      buffer.append_3(truncated);
      Unit_getInstance();
    }buffer.append_3(postfix);
    Unit_getInstance();
    return buffer;
  }
  function toMutableList(_this_) {
    return ArrayList_init_$Create$_1(_this_);
  }
  function toMutableList_0(_this_) {
    if (isInterface(_this_, Collection))
      return toMutableList(_this_);
    else {
    }
    return toCollection_0(_this_, ArrayList_init_$Create$());
  }
  function last(_this_) {
    var tmp0_subject = _this_;
    if (isInterface(tmp0_subject, List))
      return last_0(_this_);
    else {
      {
        var iterator_0 = _this_.iterator_38();
        if (!iterator_0.hasNext_11())
          throw NoSuchElementException_init_$Create$_0('Collection is empty.');
        var last_1 = iterator_0.next_13();
        while (iterator_0.hasNext_11())
          last_1 = iterator_0.next_13();
        return last_1;
      }
    }
  }
  function last_0(_this_) {
    if (_this_.isEmpty_33())
      throw NoSuchElementException_init_$Create$_0('List is empty.');
    return _this_.get_44(_get_lastIndex__0(_this_));
  }
  function toSet_0(_this_) {
    if (isInterface(_this_, Collection)) {
      var tmp0_subject = _this_._get_size__43();
      var tmp;
      switch (tmp0_subject) {
        case 0:
          tmp = emptySet();
          break;
        case 1:
          var tmp_0;
          if (isInterface(_this_, List)) {
            tmp_0 = _this_.get_44(0);
          } else {
            {
              tmp_0 = _this_.iterator_38().next_13();
            }
          }

          tmp = setOf(tmp_0);
          break;
        default:tmp = toCollection_0(_this_, LinkedHashSet_init_$Create$_1(mapCapacity(_this_._get_size__43())));
          break;
      }
      return tmp;
    } else {
    }
    return optimizeReadOnlySet(toCollection_0(_this_, LinkedHashSet_init_$Create$()));
  }
  function toMutableSet(_this_) {
    var tmp0_subject = _this_;
    var tmp;
    if (isInterface(tmp0_subject, Collection)) {
      tmp = LinkedHashSet_init_$Create$_0(_this_);
    } else {
      {
        tmp = toCollection_0(_this_, LinkedHashSet_init_$Create$());
      }
    }
    return tmp;
  }
  function plus(_this_, elements) {
    if (isInterface(elements, Collection)) {
      var result = ArrayList_init_$Create$_0(_this_._get_size__43() + elements._get_size__43() | 0);
      result.addAll_43(_this_);
      Unit_getInstance();
      result.addAll_43(elements);
      Unit_getInstance();
      return result;
    } else {
      {
        var result_0 = ArrayList_init_$Create$_1(_this_);
        addAll(result_0, elements);
        Unit_getInstance();
        return result_0;
      }
    }
  }
  function indexOf_0(_this_, element) {
    return _this_.indexOf_4(element);
  }
  function plus_0(_this_, element) {
    var result = ArrayList_init_$Create$_0(_this_._get_size__43() + 1 | 0);
    result.addAll_43(_this_);
    Unit_getInstance();
    result.add_57(element);
    Unit_getInstance();
    return result;
  }
  function minus(_this_, element) {
    var result = ArrayList_init_$Create$_0(collectionSizeOrDefault(_this_, 10));
    var removed = false;
    var tmp0_iterator_1 = _this_.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      var tmp;
      if (!removed ? equals_0(element_2, element) : false) {
        removed = true;
        tmp = false;
      } else {
        tmp = true;
      }
      if (tmp) {
        result.add_57(element_2);
        Unit_getInstance();
      } else {
      }
    }
    return result;
  }
  function sortedWith(_this_, comparator) {
    if (isInterface(_this_, Collection)) {
      if (_this_._get_size__43() <= 1)
        return toList(_this_);
      var tmp0_toTypedArray_0 = _this_;
      var tmp = copyToArray_0(tmp0_toTypedArray_0);
      var tmp1_apply_0 = isArray(tmp) ? tmp : THROW_CCE();
      sortWith_0(tmp1_apply_0, comparator);
      return asList(tmp1_apply_0);
    } else {
    }
    var tmp2_apply_0 = toMutableList_0(_this_);
    sortWith(tmp2_apply_0, comparator);
    return tmp2_apply_0;
  }
  function single_0(_this_) {
    var tmp0_subject = _this_;
    if (isInterface(tmp0_subject, List))
      return single_1(_this_);
    else {
      {
        var iterator_0 = _this_.iterator_38();
        if (!iterator_0.hasNext_11())
          throw NoSuchElementException_init_$Create$_0('Collection is empty.');
        var single_2 = iterator_0.next_13();
        if (iterator_0.hasNext_11())
          throw IllegalArgumentException_init_$Create$('Collection has more than one element.');
        return single_2;
      }
    }
  }
  function single_1(_this_) {
    var tmp0_subject = _this_._get_size__43();
    var tmp;
    switch (tmp0_subject) {
      case 0:
        throw NoSuchElementException_init_$Create$_0('List is empty.');
      case 1:
        tmp = _this_.get_44(0);
        break;
      default:throw IllegalArgumentException_init_$Create$('List has more than one element.');
    }
    return tmp;
  }
  function until(_this_, to_0) {
    if (to_0 <= IntCompanionObject_getInstance()._MIN_VALUE)
      return Companion_getInstance_5()._EMPTY;
    return numberRangeToNumber(_this_, to_0 - 1 | 0);
  }
  function coerceAtLeast(_this_, minimumValue) {
    return _this_ < minimumValue ? minimumValue : _this_;
  }
  function coerceAtMost(_this_, maximumValue) {
    return _this_ > maximumValue ? maximumValue : _this_;
  }
  function downTo(_this_, to_0) {
    return Companion_getInstance_4().fromClosedRange(_this_, to_0, -1);
  }
  function coerceIn(_this_, minimumValue, maximumValue) {
    if (minimumValue > maximumValue)
      throw IllegalArgumentException_init_$Create$('' + 'Cannot coerce value to an empty range: maximum ' + maximumValue + ' is less than minimum ' + minimumValue + '.');
    if (_this_ < minimumValue)
      return minimumValue;
    if (_this_ > maximumValue)
      return maximumValue;
    return _this_;
  }
  function coerceAtMost_0(_this_, maximumValue) {
    return _this_ > maximumValue ? maximumValue : _this_;
  }
  function coerceAtLeast_0(_this_, minimumValue) {
    return _this_ < minimumValue ? minimumValue : _this_;
  }
  function asIterable(_this_) {
    return new _no_name_provided__1(_this_);
  }
  function _no_name_provided__1($this_asIterable) {
    this._$this_asIterable = $this_asIterable;
  }
  _no_name_provided__1.prototype.iterator_2 = function () {
    return this._$this_asIterable.iterator_38();
  };
  _no_name_provided__1.prototype.iterator_38 = function () {
    return this.iterator_2();
  };
  _no_name_provided__1.$metadata$ = {
    simpleName: '<no name provided>_1',
    kind: 'class',
    interfaces: [Iterable]
  };
  function plus_1(_this_, element) {
    var result = LinkedHashSet_init_$Create$_1(mapCapacity(_this_._get_size__43() + 1 | 0));
    result.addAll_43(_this_);
    Unit_getInstance();
    result.add_57(element);
    Unit_getInstance();
    return result;
  }
  function take(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested character count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$(toString_2(message_2));
    }var tmp1_substring_0 = 0;
    var tmp2_substring_0 = coerceAtMost(n, _this_.length);
    return _this_.substring(tmp1_substring_0, tmp2_substring_0);
  }
  function _no_name_provided_(this$0) {
    this._this$0 = this$0;
  }
  _no_name_provided_.prototype.invoke_5 = function (it) {
    return it === this._this$0 ? '(this Collection)' : toString_1(it);
  };
  _no_name_provided_.prototype.invoke_1082 = function (p1) {
    return this.invoke_5((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided_.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractCollection() {
  }
  AbstractCollection.prototype.contains_31 = function (element) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(this, Collection)) {
        tmp = this.isEmpty_33();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = this.iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        if (equals_0(element_2, element)) {
          tmp$ret$0 = true;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = false;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractCollection.prototype.containsAll_19 = function (elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.isEmpty_33();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = elements.iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        if (!this.contains_31(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractCollection.prototype.isEmpty_33 = function () {
    return this._get_size__43() === 0;
  };
  AbstractCollection.prototype.toString = function () {
    return joinToString$default_0(this, ', ', '[', ']', 0, null, _no_name_provided_$factory(this), 24, null);
  };
  AbstractCollection.prototype.toArray = function () {
    return copyToArrayImpl_0(this);
  };
  AbstractCollection.$metadata$ = {
    simpleName: 'AbstractCollection',
    kind: 'class',
    interfaces: [Collection]
  };
  function _no_name_provided_$factory(this$0) {
    var i = new _no_name_provided_(this$0);
    return function (p1) {
      return i.invoke_5(p1);
    };
  }
  function Companion_0() {
    Companion_instance = this;
  }
  Companion_0.prototype.checkElementIndex = function (index, size) {
    if (index < 0 ? true : index >= size) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', size: ' + size);
    }};
  Companion_0.prototype.checkPositionIndex = function (index, size) {
    if (index < 0 ? true : index > size) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', size: ' + size);
    }};
  Companion_0.prototype.checkRangeIndexes = function (fromIndex, toIndex, size) {
    if (fromIndex < 0 ? true : toIndex > size) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'fromIndex: ' + fromIndex + ', toIndex: ' + toIndex + ', size: ' + size);
    }if (fromIndex > toIndex) {
      throw IllegalArgumentException_init_$Create$('' + 'fromIndex: ' + fromIndex + ' > toIndex: ' + toIndex);
    }};
  Companion_0.prototype.orderedHashCode = function (c) {
    var hashCode_1 = 1;
    var tmp0_iterator = c.iterator_38();
    while (tmp0_iterator.hasNext_11()) {
      var e = tmp0_iterator.next_13();
      var tmp = imul(31, hashCode_1);
      var tmp1_safe_receiver = e;
      var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : hashCode(tmp1_safe_receiver);
      hashCode_1 = tmp + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    }
    return hashCode_1;
  };
  Companion_0.prototype.orderedEquals = function (c, other) {
    if (!(c._get_size__43() === other._get_size__43()))
      return false;
    var otherIterator = other.iterator_38();
    var tmp0_iterator = c.iterator_38();
    while (tmp0_iterator.hasNext_11()) {
      var elem = tmp0_iterator.next_13();
      var elemOther = otherIterator.next_13();
      if (!equals_0(elem, elemOther)) {
        return false;
      }}
    return true;
  };
  Companion_0.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion_0();
    return Companion_instance;
  }
  function toString($this, o) {
    return o === $this ? '(this Map)' : toString_1(o);
  }
  function implFindEntry($this, key) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_firstOrNull_0 = $this._get_entries__5();
      var tmp0_iterator_1 = tmp0_firstOrNull_0.iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        if (equals_0(element_2._get_key__2(), key)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    return tmp$ret$0;
  }
  function Companion_1() {
    Companion_instance_0 = this;
  }
  Companion_1.prototype.entryHashCode = function (e) {
    var tmp2_safe_receiver_4 = e._get_key__2();
    var tmp3_elvis_lhs_3 = tmp2_safe_receiver_4 == null ? null : hashCode(tmp2_safe_receiver_4);
    var tmp = tmp3_elvis_lhs_3 == null ? 0 : tmp3_elvis_lhs_3;
    var tmp0_safe_receiver_6 = e._get_value__18();
    var tmp1_elvis_lhs_5 = tmp0_safe_receiver_6 == null ? null : hashCode(tmp0_safe_receiver_6);
    return tmp ^ (tmp1_elvis_lhs_5 == null ? 0 : tmp1_elvis_lhs_5);
  };
  Companion_1.prototype.entryToString = function (e) {
    return '' + e._get_key__2() + '=' + e._get_value__18();
  };
  Companion_1.prototype.entryEquals = function (e, other) {
    if (!(!(other == null) ? isInterface(other, Entry) : false))
      return false;
    else {
    }
    return equals_0(e._get_key__2(), other._get_key__2()) ? equals_0(e._get_value__18(), other._get_value__18()) : false;
  };
  Companion_1.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_1();
    return Companion_instance_0;
  }
  function _no_name_provided__0(this$0) {
    this._this$0_0 = this$0;
  }
  _no_name_provided__0.prototype.invoke_1 = function (it) {
    return this._this$0_0.toString_0(it);
  };
  _no_name_provided__0.prototype.invoke_1082 = function (p1) {
    return this.invoke_1((!(p1 == null) ? isInterface(p1, Entry) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__0.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMap() {
    Companion_getInstance_0();
    this.__keys = null;
    this.__values = null;
  }
  AbstractMap.prototype.containsKey_6 = function (key) {
    return !(implFindEntry(this, key) == null);
  };
  AbstractMap.prototype.containsValue_2 = function (value) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_any_0 = this._get_entries__5();
      var tmp;
      if (isInterface(tmp0_any_0, Collection)) {
        tmp = tmp0_any_0.isEmpty_33();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_any_0.iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        if (equals_0(element_2._get_value__18(), value)) {
          tmp$ret$0 = true;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = false;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractMap.prototype.containsEntry_5 = function (entry) {
    if (!(!(entry == null) ? isInterface(entry, Entry) : false))
      return false;
    else {
    }
    var key = entry._get_key__2();
    var value = entry._get_value__18();
    var ourValue = (isInterface(this, Map_0) ? this : THROW_CCE()).get_20(key);
    if (!equals_0(value, ourValue)) {
      return false;
    }var tmp;
    if (ourValue == null) {
      tmp = !(isInterface(this, Map_0) ? this : THROW_CCE()).containsKey_6(key);
    } else {
      tmp = false;
    }
    if (tmp) {
      return false;
    } else {
    }
    return true;
  };
  AbstractMap.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Map_0) : false))
      return false;
    else {
    }
    if (!(this._get_size__43() === other._get_size__43()))
      return false;
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_all_0 = other._get_entries__5();
      var tmp;
      if (isInterface(tmp0_all_0, Collection)) {
        tmp = tmp0_all_0.isEmpty_33();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_all_0.iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        if (!this.containsEntry_5(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractMap.prototype.get_20 = function (key) {
    var tmp0_safe_receiver = implFindEntry(this, key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__18();
  };
  AbstractMap.prototype.hashCode = function () {
    return hashCode(this._get_entries__5());
  };
  AbstractMap.prototype.isEmpty_33 = function () {
    return this._get_size__43() === 0;
  };
  AbstractMap.prototype._get_size__43 = function () {
    return this._get_entries__5()._get_size__43();
  };
  AbstractMap.prototype.toString = function () {
    var tmp = this._get_entries__5();
    return joinToString$default_0(tmp, ', ', '{', '}', 0, null, _no_name_provided_$factory_0(this), 24, null);
  };
  AbstractMap.prototype.toString_0 = function (entry) {
    return toString(this, entry._get_key__2()) + '=' + toString(this, entry._get_value__18());
  };
  AbstractMap.$metadata$ = {
    simpleName: 'AbstractMap',
    kind: 'class',
    interfaces: [Map_0]
  };
  function _no_name_provided_$factory_0(this$0) {
    var i = new _no_name_provided__0(this$0);
    return function (p1) {
      return i.invoke_1(p1);
    };
  }
  function Companion_2() {
    Companion_instance_1 = this;
  }
  Companion_2.prototype.unorderedHashCode = function (c) {
    var hashCode_1 = 0;
    var tmp0_iterator = c.iterator_38();
    while (tmp0_iterator.hasNext_11()) {
      var element = tmp0_iterator.next_13();
      var tmp = hashCode_1;
      var tmp1_safe_receiver = element;
      var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : hashCode(tmp1_safe_receiver);
      hashCode_1 = tmp + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    }
    return hashCode_1;
  };
  Companion_2.prototype.setEquals = function (c, other) {
    if (!(c._get_size__43() === other._get_size__43()))
      return false;
    return c.containsAll_19(other);
  };
  Companion_2.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_2();
    return Companion_instance_1;
  }
  function ArrayDeque_init_$Init$($this) {
    AbstractMutableList.call($this);
    ArrayDeque.call($this);
    $this._elementData = Companion_getInstance_2()._emptyElementData;
    return $this;
  }
  function ArrayDeque_init_$Create$() {
    return ArrayDeque_init_$Init$(Object.create(ArrayDeque.prototype));
  }
  function ensureCapacity($this, minCapacity) {
    if (minCapacity < 0)
      throw IllegalStateException_init_$Create$_0('Deque is too big.');
    if (minCapacity <= $this._elementData.length)
      return Unit_getInstance();
    if ($this._elementData === Companion_getInstance_2()._emptyElementData) {
      var tmp = $this;
      var tmp0_arrayOfNulls_0 = coerceAtLeast(minCapacity, 10);
      tmp._elementData = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
      return Unit_getInstance();
    }var newCapacity = Companion_getInstance_2().newCapacity($this._elementData.length, minCapacity);
    copyElements($this, newCapacity);
  }
  function copyElements($this, newCapacity) {
    var newElements = fillArrayVal(Array(newCapacity), null);
    var tmp0_copyInto_0 = $this._elementData;
    var tmp1_copyInto_0 = 0;
    var tmp2_copyInto_0 = $this._head;
    var tmp3_copyInto_0 = $this._elementData.length;
    arrayCopy_0(tmp0_copyInto_0, newElements, tmp1_copyInto_0, tmp2_copyInto_0, tmp3_copyInto_0);
    Unit_getInstance();
    var tmp4_copyInto_0 = $this._elementData;
    var tmp5_copyInto_0 = $this._elementData.length - $this._head | 0;
    var tmp6_copyInto_0 = 0;
    var tmp7_copyInto_0 = $this._head;
    arrayCopy_0(tmp4_copyInto_0, newElements, tmp5_copyInto_0, tmp6_copyInto_0, tmp7_copyInto_0);
    Unit_getInstance();
    $this._head = 0;
    $this._elementData = newElements;
  }
  function positiveMod($this, index) {
    return index >= $this._elementData.length ? index - $this._elementData.length | 0 : index;
  }
  function negativeMod($this, index) {
    return index < 0 ? index + $this._elementData.length | 0 : index;
  }
  function incremented($this, index) {
    return index === _get_lastIndex_($this._elementData) ? 0 : index + 1 | 0;
  }
  function decremented($this, index) {
    return index === 0 ? _get_lastIndex_($this._elementData) : index - 1 | 0;
  }
  function copyCollectionElements($this, internalIndex, elements) {
    var iterator_0 = elements.iterator_38();
    var inductionVariable = internalIndex;
    var last_1 = $this._elementData.length;
    if (inductionVariable < last_1)
      $l$break: do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!iterator_0.hasNext_11())
          break $l$break;
        $this._elementData[index] = iterator_0.next_13();
      }
       while (inductionVariable < last_1);
    var inductionVariable_0 = 0;
    var last_2 = $this._head;
    if (inductionVariable_0 < last_2)
      $l$break_0: do {
        var index_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        if (!iterator_0.hasNext_11())
          break $l$break_0;
        $this._elementData[index_0] = iterator_0.next_13();
      }
       while (inductionVariable_0 < last_2);
    var tmp2_this = $this;
    tmp2_this._size = tmp2_this._size + elements._get_size__43() | 0;
  }
  function Companion_3() {
    Companion_instance_2 = this;
    var tmp = this;
    tmp._emptyElementData = [];
    this._maxArraySize = 2147483639;
    this._defaultMinCapacity = 10;
  }
  Companion_3.prototype.newCapacity = function (oldCapacity, minCapacity) {
    var newCapacity = oldCapacity + (oldCapacity >> 1) | 0;
    if ((newCapacity - minCapacity | 0) < 0)
      newCapacity = minCapacity;
    if ((newCapacity - 2147483639 | 0) > 0)
      newCapacity = minCapacity > 2147483639 ? IntCompanionObject_getInstance()._MAX_VALUE : 2147483639;
    return newCapacity;
  };
  Companion_3.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_2;
  function Companion_getInstance_2() {
    if (Companion_instance_2 == null)
      new Companion_3();
    return Companion_instance_2;
  }
  ArrayDeque.prototype._get_size__43 = function () {
    return this._size;
  };
  ArrayDeque.prototype.isEmpty_33 = function () {
    return this._size === 0;
  };
  ArrayDeque.prototype.addFirst = function (element) {
    ensureCapacity(this, this._size + 1 | 0);
    this._head = decremented(this, this._head);
    this._elementData[this._head] = element;
    var tmp0_this = this;
    tmp0_this._size = tmp0_this._size + 1 | 0;
  };
  ArrayDeque.prototype.addLast = function (element) {
    ensureCapacity(this, this._size + 1 | 0);
    var tmp = this._elementData;
    var tmp0_internalIndex_0 = this._size;
    tmp[positiveMod(this, this._head + tmp0_internalIndex_0 | 0)] = element;
    var tmp0_this = this;
    tmp0_this._size = tmp0_this._size + 1 | 0;
  };
  ArrayDeque.prototype.removeFirst = function () {
    if (this.isEmpty_33())
      throw NoSuchElementException_init_$Create$_0('ArrayDeque is empty.');
    var tmp0_internalGet_0 = this._head;
    var tmp = this._elementData[tmp0_internalGet_0];
    var element = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
    this._elementData[this._head] = null;
    this._head = incremented(this, this._head);
    var tmp0_this = this;
    tmp0_this._size = tmp0_this._size - 1 | 0;
    return element;
  };
  ArrayDeque.prototype.removeLast = function () {
    if (this.isEmpty_33())
      throw NoSuchElementException_init_$Create$_0('ArrayDeque is empty.');
    var tmp0_internalIndex_0 = _get_lastIndex__0(this);
    var internalLastIndex = positiveMod(this, this._head + tmp0_internalIndex_0 | 0);
    var tmp = this._elementData[internalLastIndex];
    var element = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
    this._elementData[internalLastIndex] = null;
    var tmp0_this = this;
    tmp0_this._size = tmp0_this._size - 1 | 0;
    return element;
  };
  ArrayDeque.prototype.add_57 = function (element) {
    this.addLast(element);
    return true;
  };
  ArrayDeque.prototype.add_20 = function (index, element) {
    Companion_getInstance().checkPositionIndex(index, this._size);
    if (index === this._size) {
      this.addLast(element);
      return Unit_getInstance();
    } else if (index === 0) {
      this.addFirst(element);
      return Unit_getInstance();
    }ensureCapacity(this, this._size + 1 | 0);
    var internalIndex = positiveMod(this, this._head + index | 0);
    if (index < (this._size + 1 | 0) >> 1) {
      var decrementedInternalIndex = decremented(this, internalIndex);
      var decrementedHead = decremented(this, this._head);
      if (decrementedInternalIndex >= this._head) {
        this._elementData[decrementedHead] = this._elementData[this._head];
        var tmp0_copyInto_0 = this._elementData;
        var tmp1_copyInto_0 = this._elementData;
        var tmp2_copyInto_0 = this._head;
        var tmp3_copyInto_0 = this._head + 1 | 0;
        var tmp4_copyInto_0 = decrementedInternalIndex + 1 | 0;
        arrayCopy_0(tmp0_copyInto_0, tmp1_copyInto_0, tmp2_copyInto_0, tmp3_copyInto_0, tmp4_copyInto_0);
        Unit_getInstance();
      } else {
        var tmp5_copyInto_0 = this._elementData;
        var tmp6_copyInto_0 = this._elementData;
        var tmp7_copyInto_0 = this._head - 1 | 0;
        var tmp8_copyInto_0 = this._head;
        var tmp9_copyInto_0 = this._elementData.length;
        arrayCopy_0(tmp5_copyInto_0, tmp6_copyInto_0, tmp7_copyInto_0, tmp8_copyInto_0, tmp9_copyInto_0);
        Unit_getInstance();
        this._elementData[this._elementData.length - 1 | 0] = this._elementData[0];
        var tmp10_copyInto_0 = this._elementData;
        var tmp11_copyInto_0 = this._elementData;
        var tmp12_copyInto_0 = 0;
        var tmp13_copyInto_0 = 1;
        var tmp14_copyInto_0 = decrementedInternalIndex + 1 | 0;
        arrayCopy_0(tmp10_copyInto_0, tmp11_copyInto_0, tmp12_copyInto_0, tmp13_copyInto_0, tmp14_copyInto_0);
        Unit_getInstance();
      }
      this._elementData[decrementedInternalIndex] = element;
      this._head = decrementedHead;
    } else {
      var tmp15_internalIndex_0 = this._size;
      var tail = positiveMod(this, this._head + tmp15_internalIndex_0 | 0);
      if (internalIndex < tail) {
        var tmp16_copyInto_0 = this._elementData;
        var tmp17_copyInto_0 = this._elementData;
        var tmp18_copyInto_0 = internalIndex + 1 | 0;
        arrayCopy_0(tmp16_copyInto_0, tmp17_copyInto_0, tmp18_copyInto_0, internalIndex, tail);
        Unit_getInstance();
      } else {
        var tmp19_copyInto_0 = this._elementData;
        var tmp20_copyInto_0 = this._elementData;
        var tmp21_copyInto_0 = 1;
        var tmp22_copyInto_0 = 0;
        arrayCopy_0(tmp19_copyInto_0, tmp20_copyInto_0, tmp21_copyInto_0, tmp22_copyInto_0, tail);
        Unit_getInstance();
        this._elementData[0] = this._elementData[this._elementData.length - 1 | 0];
        var tmp23_copyInto_0 = this._elementData;
        var tmp24_copyInto_0 = this._elementData;
        var tmp25_copyInto_0 = internalIndex + 1 | 0;
        var tmp26_copyInto_0 = this._elementData.length - 1 | 0;
        arrayCopy_0(tmp23_copyInto_0, tmp24_copyInto_0, tmp25_copyInto_0, internalIndex, tmp26_copyInto_0);
        Unit_getInstance();
      }
      this._elementData[internalIndex] = element;
    }
    var tmp0_this = this;
    tmp0_this._size = tmp0_this._size + 1 | 0;
  };
  ArrayDeque.prototype.addAll_43 = function (elements) {
    if (elements.isEmpty_33())
      return false;
    ensureCapacity(this, this._size + elements._get_size__43() | 0);
    var tmp0_internalIndex_0 = this._size;
    copyCollectionElements(this, positiveMod(this, this._head + tmp0_internalIndex_0 | 0), elements);
    return true;
  };
  ArrayDeque.prototype.get_44 = function (index) {
    Companion_getInstance().checkElementIndex(index, this._size);
    var tmp0_internalGet_0 = positiveMod(this, this._head + index | 0);
    var tmp = this._elementData[tmp0_internalGet_0];
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  ArrayDeque.prototype.set_3 = function (index, element) {
    Companion_getInstance().checkElementIndex(index, this._size);
    var internalIndex = positiveMod(this, this._head + index | 0);
    var tmp = this._elementData[internalIndex];
    var oldElement = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
    this._elementData[internalIndex] = element;
    return oldElement;
  };
  ArrayDeque.prototype.contains_31 = function (element) {
    return !(this.indexOf_4(element) === -1);
  };
  ArrayDeque.prototype.indexOf_4 = function (element) {
    var tmp0_internalIndex_0 = this._size;
    var tail = positiveMod(this, this._head + tmp0_internalIndex_0 | 0);
    if (this._head < tail) {
      var inductionVariable = this._head;
      if (inductionVariable < tail)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (equals_0(element, this._elementData[index]))
            return index - this._head | 0;
        }
         while (inductionVariable < tail);
    } else if (this._head >= tail) {
      var inductionVariable_0 = this._head;
      var last_1 = this._elementData.length;
      if (inductionVariable_0 < last_1)
        do {
          var index_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (equals_0(element, this._elementData[index_0]))
            return index_0 - this._head | 0;
        }
         while (inductionVariable_0 < last_1);
      var inductionVariable_1 = 0;
      if (inductionVariable_1 < tail)
        do {
          var index_1 = inductionVariable_1;
          inductionVariable_1 = inductionVariable_1 + 1 | 0;
          if (equals_0(element, this._elementData[index_1]))
            return (index_1 + this._elementData.length | 0) - this._head | 0;
        }
         while (inductionVariable_1 < tail);
    }return -1;
  };
  ArrayDeque.prototype.remove_59 = function (element) {
    var index = this.indexOf_4(element);
    if (index === -1)
      return false;
    this.removeAt_2(index);
    Unit_getInstance();
    return true;
  };
  ArrayDeque.prototype.removeAt_2 = function (index) {
    Companion_getInstance().checkElementIndex(index, this._size);
    if (index === _get_lastIndex__0(this)) {
      return this.removeLast();
    } else if (index === 0) {
      return this.removeFirst();
    }var internalIndex = positiveMod(this, this._head + index | 0);
    var tmp = this._elementData[internalIndex];
    var element = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
    if (index < this._size >> 1) {
      if (internalIndex >= this._head) {
        var tmp0_copyInto_0 = this._elementData;
        var tmp1_copyInto_0 = this._elementData;
        var tmp2_copyInto_0 = this._head + 1 | 0;
        var tmp3_copyInto_0 = this._head;
        arrayCopy_0(tmp0_copyInto_0, tmp1_copyInto_0, tmp2_copyInto_0, tmp3_copyInto_0, internalIndex);
        Unit_getInstance();
      } else {
        var tmp4_copyInto_0 = this._elementData;
        var tmp5_copyInto_0 = this._elementData;
        var tmp6_copyInto_0 = 1;
        var tmp7_copyInto_0 = 0;
        arrayCopy_0(tmp4_copyInto_0, tmp5_copyInto_0, tmp6_copyInto_0, tmp7_copyInto_0, internalIndex);
        Unit_getInstance();
        this._elementData[0] = this._elementData[this._elementData.length - 1 | 0];
        var tmp8_copyInto_0 = this._elementData;
        var tmp9_copyInto_0 = this._elementData;
        var tmp10_copyInto_0 = this._head + 1 | 0;
        var tmp11_copyInto_0 = this._head;
        var tmp12_copyInto_0 = this._elementData.length - 1 | 0;
        arrayCopy_0(tmp8_copyInto_0, tmp9_copyInto_0, tmp10_copyInto_0, tmp11_copyInto_0, tmp12_copyInto_0);
        Unit_getInstance();
      }
      this._elementData[this._head] = null;
      this._head = incremented(this, this._head);
    } else {
      var tmp13_internalIndex_0 = _get_lastIndex__0(this);
      var internalLastIndex = positiveMod(this, this._head + tmp13_internalIndex_0 | 0);
      if (internalIndex <= internalLastIndex) {
        var tmp14_copyInto_0 = this._elementData;
        var tmp15_copyInto_0 = this._elementData;
        var tmp16_copyInto_0 = internalIndex + 1 | 0;
        var tmp17_copyInto_0 = internalLastIndex + 1 | 0;
        arrayCopy_0(tmp14_copyInto_0, tmp15_copyInto_0, internalIndex, tmp16_copyInto_0, tmp17_copyInto_0);
        Unit_getInstance();
      } else {
        var tmp18_copyInto_0 = this._elementData;
        var tmp19_copyInto_0 = this._elementData;
        var tmp20_copyInto_0 = internalIndex + 1 | 0;
        var tmp21_copyInto_0 = this._elementData.length;
        arrayCopy_0(tmp18_copyInto_0, tmp19_copyInto_0, internalIndex, tmp20_copyInto_0, tmp21_copyInto_0);
        Unit_getInstance();
        this._elementData[this._elementData.length - 1 | 0] = this._elementData[0];
        var tmp22_copyInto_0 = this._elementData;
        var tmp23_copyInto_0 = this._elementData;
        var tmp24_copyInto_0 = 0;
        var tmp25_copyInto_0 = 1;
        var tmp26_copyInto_0 = internalLastIndex + 1 | 0;
        arrayCopy_0(tmp22_copyInto_0, tmp23_copyInto_0, tmp24_copyInto_0, tmp25_copyInto_0, tmp26_copyInto_0);
        Unit_getInstance();
      }
      this._elementData[internalLastIndex] = null;
    }
    var tmp0_this = this;
    tmp0_this._size = tmp0_this._size - 1 | 0;
    return element;
  };
  ArrayDeque.prototype.removeAll_2 = function (elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (this.isEmpty_33()) {
        tmp = true;
      } else {
        var tmp0_isEmpty_0 = this._elementData;
        tmp = tmp0_isEmpty_0.length === 0;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      var tmp1_internalIndex_0 = this._size;
      var tail_1 = positiveMod(this, this._head + tmp1_internalIndex_0 | 0);
      var newTail_2 = this._head;
      var modified_3 = false;
      if (this._head < tail_1) {
        var inductionVariable = this._head;
        if (inductionVariable < tail_1)
          do {
            var index_5 = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var element_6 = this._elementData[index_5];
            var tmp2__anonymous__15 = (element_6 == null ? true : isObject(element_6)) ? element_6 : THROW_CCE();
            if (!elements.contains_31(tmp2__anonymous__15)) {
              var tmp_0 = this._elementData;
              var tmp1_7 = newTail_2;
              newTail_2 = tmp1_7 + 1 | 0;
              tmp_0[tmp1_7] = element_6;
            } else {
              modified_3 = true;
            }
          }
           while (inductionVariable < tail_1);
        fill(this._elementData, null, newTail_2, tail_1);
      } else {
        var inductionVariable_0 = this._head;
        var last_1 = this._elementData.length;
        if (inductionVariable_0 < last_1)
          do {
            var index_9 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var element_10 = this._elementData[index_9];
            this._elementData[index_9] = null;
            var tmp3__anonymous__15 = (element_10 == null ? true : isObject(element_10)) ? element_10 : THROW_CCE();
            if (!elements.contains_31(tmp3__anonymous__15)) {
              var tmp_1 = this._elementData;
              var tmp3_11 = newTail_2;
              newTail_2 = tmp3_11 + 1 | 0;
              tmp_1[tmp3_11] = element_10;
            } else {
              modified_3 = true;
            }
          }
           while (inductionVariable_0 < last_1);
        newTail_2 = positiveMod(this, newTail_2);
        var inductionVariable_1 = 0;
        if (inductionVariable_1 < tail_1)
          do {
            var index_13 = inductionVariable_1;
            inductionVariable_1 = inductionVariable_1 + 1 | 0;
            var element_14 = this._elementData[index_13];
            this._elementData[index_13] = null;
            var tmp4__anonymous__15 = (element_14 == null ? true : isObject(element_14)) ? element_14 : THROW_CCE();
            if (!elements.contains_31(tmp4__anonymous__15)) {
              this._elementData[newTail_2] = element_14;
              newTail_2 = incremented(this, newTail_2);
            } else {
              {
                modified_3 = true;
              }
            }
          }
           while (inductionVariable_1 < tail_1);
      }
      if (modified_3)
        this._size = negativeMod(this, newTail_2 - this._head | 0);
      tmp$ret$0 = modified_3;
    }
     while (false);
    return tmp$ret$0;
  };
  ArrayDeque.prototype.clear_27 = function () {
    var tmp0_internalIndex_0 = this._size;
    var tail = positiveMod(this, this._head + tmp0_internalIndex_0 | 0);
    if (this._head < tail) {
      fill(this._elementData, null, this._head, tail);
    } else {
      if (!this.isEmpty_33()) {
        fill(this._elementData, null, this._head, this._elementData.length);
        fill(this._elementData, null, 0, tail);
      } else {
      }
    }
    this._head = 0;
    this._size = 0;
  };
  function ArrayDeque() {
    Companion_getInstance_2();
    this._head = 0;
    this._size = 0;
  }
  ArrayDeque.$metadata$ = {
    simpleName: 'ArrayDeque',
    kind: 'class',
    interfaces: []
  };
  function emptyList() {
    return EmptyList_getInstance();
  }
  function listOf(elements) {
    return elements.length > 0 ? asList(elements) : emptyList();
  }
  function EmptyList() {
    EmptyList_instance = this;
    this._serialVersionUID = new Long(-1478467534, -1720727600);
  }
  EmptyList.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, List) : false) {
      tmp = other.isEmpty_33();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  EmptyList.prototype.hashCode = function () {
    return 1;
  };
  EmptyList.prototype.toString = function () {
    return '[]';
  };
  EmptyList.prototype._get_size__43 = function () {
    return 0;
  };
  EmptyList.prototype.isEmpty_33 = function () {
    return true;
  };
  EmptyList.prototype.contains_6 = function (element) {
    return false;
  };
  EmptyList.prototype.contains_31 = function (element) {
    if (!false)
      return false;
    else {
    }
    var tmp;
    if (false) {
      tmp = element;
    } else {
      {
        tmp = THROW_CCE();
      }
    }
    return this.contains_6(tmp);
  };
  EmptyList.prototype.containsAll_5 = function (elements) {
    return elements.isEmpty_33();
  };
  EmptyList.prototype.containsAll_19 = function (elements) {
    return this.containsAll_5(elements);
  };
  EmptyList.prototype.get_44 = function (index) {
    throw IndexOutOfBoundsException_init_$Create$('' + "Empty list doesn't contain element at index " + index + '.');
  };
  EmptyList.prototype.indexOf_0 = function (element) {
    return -1;
  };
  EmptyList.prototype.indexOf_4 = function (element) {
    if (!false)
      return -1;
    else {
    }
    var tmp;
    if (false) {
      tmp = element;
    } else {
      {
        tmp = THROW_CCE();
      }
    }
    return this.indexOf_0(tmp);
  };
  EmptyList.prototype.iterator_38 = function () {
    return EmptyIterator_getInstance();
  };
  EmptyList.prototype.listIterator_3 = function (index) {
    if (!(index === 0))
      throw IndexOutOfBoundsException_init_$Create$('' + 'Index: ' + index);
    return EmptyIterator_getInstance();
  };
  EmptyList.$metadata$ = {
    simpleName: 'EmptyList',
    kind: 'object',
    interfaces: [List, Serializable, RandomAccess]
  };
  var EmptyList_instance;
  function EmptyList_getInstance() {
    if (EmptyList_instance == null)
      new EmptyList();
    return EmptyList_instance;
  }
  function EmptyIterator() {
    EmptyIterator_instance = this;
  }
  EmptyIterator.prototype.hasNext_11 = function () {
    return false;
  };
  EmptyIterator.prototype.hasPrevious_1 = function () {
    return false;
  };
  EmptyIterator.prototype.nextIndex_1 = function () {
    return 0;
  };
  EmptyIterator.prototype.next_13 = function () {
    throw NoSuchElementException_init_$Create$();
  };
  EmptyIterator.prototype.previous_1 = function () {
    throw NoSuchElementException_init_$Create$();
  };
  EmptyIterator.$metadata$ = {
    simpleName: 'EmptyIterator',
    kind: 'object',
    interfaces: [ListIterator]
  };
  var EmptyIterator_instance;
  function EmptyIterator_getInstance() {
    if (EmptyIterator_instance == null)
      new EmptyIterator();
    return EmptyIterator_instance;
  }
  function _get_lastIndex__0(_this_) {
    return _this_._get_size__43() - 1 | 0;
  }
  function optimizeReadOnlyList(_this_) {
    var tmp0_subject = _this_._get_size__43();
    switch (tmp0_subject) {
      case 0:
        return emptyList();
      case 1:
        return listOf_0(_this_.get_44(0));
      default:return _this_;
    }
  }
  function arrayListOf(elements) {
    return elements.length === 0 ? ArrayList_init_$Create$() : ArrayList_init_$Create$_1(new ArrayAsCollection(elements, true));
  }
  function throwIndexOverflow() {
    throw ArithmeticException_init_$Create$('Index overflow has happened.');
  }
  function ArrayAsCollection(values_3, isVarargs) {
    this._values = values_3;
    this._isVarargs = isVarargs;
  }
  ArrayAsCollection.prototype._get_size__43 = function () {
    return this._values.length;
  };
  ArrayAsCollection.prototype.isEmpty_33 = function () {
    var tmp0_isEmpty_0 = this._values;
    return tmp0_isEmpty_0.length === 0;
  };
  ArrayAsCollection.prototype.contains_10 = function (element) {
    return contains(this._values, element);
  };
  ArrayAsCollection.prototype.contains_31 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_10((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  ArrayAsCollection.prototype.containsAll_3 = function (elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.isEmpty_33();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = elements.iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        if (!this.contains_10(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  ArrayAsCollection.prototype.containsAll_19 = function (elements) {
    return this.containsAll_3(elements);
  };
  ArrayAsCollection.prototype.iterator_38 = function () {
    return arrayIterator(this._values);
  };
  ArrayAsCollection.$metadata$ = {
    simpleName: 'ArrayAsCollection',
    kind: 'class',
    interfaces: [Collection]
  };
  function collectionSizeOrDefault(_this_, default_0) {
    var tmp;
    if (isInterface(_this_, Collection)) {
      tmp = _this_._get_size__43();
    } else {
      {
        tmp = default_0;
      }
    }
    return tmp;
  }
  function mapOf(pairs) {
    return pairs.length > 0 ? toMap_1(pairs, LinkedHashMap_init_$Create$_1(mapCapacity(pairs.length))) : emptyMap();
  }
  function emptyMap() {
    var tmp = EmptyMap_getInstance();
    return isInterface(tmp, Map_0) ? tmp : THROW_CCE();
  }
  function toMap(_this_) {
    if (isInterface(_this_, Collection)) {
      var tmp0_subject = _this_._get_size__43();
      var tmp;
      switch (tmp0_subject) {
        case 0:
          tmp = emptyMap();
          break;
        case 1:
          var tmp_0;
          if (isInterface(_this_, List)) {
            tmp_0 = _this_.get_44(0);
          } else {
            {
              tmp_0 = _this_.iterator_38().next_13();
            }
          }

          tmp = mapOf_0(tmp_0);
          break;
        default:tmp = toMap_2(_this_, LinkedHashMap_init_$Create$_1(mapCapacity(_this_._get_size__43())));
          break;
      }
      return tmp;
    } else {
    }
    return optimizeReadOnlyMap(toMap_2(_this_, LinkedHashMap_init_$Create$()));
  }
  function toMap_0(_this_) {
    var tmp0_subject = _this_.length;
    switch (tmp0_subject) {
      case 0:
        return emptyMap();
      case 1:
        return mapOf_0(_this_[0]);
      default:return toMap_1(_this_, LinkedHashMap_init_$Create$_1(mapCapacity(_this_.length)));
    }
  }
  function toMap_1(_this_, destination) {
    putAll(destination, _this_);
    return destination;
  }
  function EmptyMap() {
    EmptyMap_instance = this;
    this._serialVersionUID_0 = new Long(-888910638, 1920087921);
  }
  EmptyMap.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, Map_0) : false) {
      tmp = other.isEmpty_33();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  EmptyMap.prototype.hashCode = function () {
    return 0;
  };
  EmptyMap.prototype.toString = function () {
    return '{}';
  };
  EmptyMap.prototype._get_size__43 = function () {
    return 0;
  };
  EmptyMap.prototype.isEmpty_33 = function () {
    return true;
  };
  EmptyMap.prototype.containsKey_0 = function (key) {
    return false;
  };
  EmptyMap.prototype.containsKey_6 = function (key) {
    if (!(key == null ? true : isObject(key)))
      return false;
    else {
    }
    return this.containsKey_0((key == null ? true : isObject(key)) ? key : THROW_CCE());
  };
  EmptyMap.prototype.get_3 = function (key) {
    return null;
  };
  EmptyMap.prototype.get_20 = function (key) {
    if (!(key == null ? true : isObject(key)))
      return null;
    else {
    }
    return this.get_3((key == null ? true : isObject(key)) ? key : THROW_CCE());
  };
  EmptyMap.prototype._get_entries__5 = function () {
    return EmptySet_getInstance();
  };
  EmptyMap.$metadata$ = {
    simpleName: 'EmptyMap',
    kind: 'object',
    interfaces: [Map_0, Serializable]
  };
  var EmptyMap_instance;
  function EmptyMap_getInstance() {
    if (EmptyMap_instance == null)
      new EmptyMap();
    return EmptyMap_instance;
  }
  function toMap_2(_this_, destination) {
    putAll_0(destination, _this_);
    return destination;
  }
  function optimizeReadOnlyMap(_this_) {
    var tmp0_subject = _this_._get_size__43();
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = emptyMap();
        break;
      case 1:
        tmp = _this_;
        break;
      default:tmp = _this_;
        break;
    }
    return tmp;
  }
  function putAll(_this_, pairs) {
    var indexedObject = pairs;
    var inductionVariable = 0;
    var last_1 = indexedObject.length;
    while (inductionVariable < last_1) {
      var tmp1_loop_parameter = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var key = tmp1_loop_parameter.component1_0();
      var value = tmp1_loop_parameter.component2_0();
      _this_.put_6(key, value);
      Unit_getInstance();
    }
  }
  function putAll_0(_this_, pairs) {
    var tmp0_iterator = pairs.iterator_38();
    while (tmp0_iterator.hasNext_11()) {
      var tmp1_loop_parameter = tmp0_iterator.next_13();
      var key = tmp1_loop_parameter.component1_0();
      var value = tmp1_loop_parameter.component2_0();
      _this_.put_6(key, value);
      Unit_getInstance();
    }
  }
  function hashMapOf(pairs) {
    var tmp0_apply_0 = HashMap_init_$Create$_1(mapCapacity(pairs.length));
    putAll(tmp0_apply_0, pairs);
    return tmp0_apply_0;
  }
  function toMutableMap(_this_) {
    return LinkedHashMap_init_$Create$_2(_this_);
  }
  function mutableMapOf(pairs) {
    var tmp0_apply_0 = LinkedHashMap_init_$Create$_1(mapCapacity(pairs.length));
    putAll(tmp0_apply_0, pairs);
    return tmp0_apply_0;
  }
  function removeAll(_this_, predicate) {
    return filterInPlace(_this_, predicate, true);
  }
  function removeAll_0(_this_, predicate) {
    return filterInPlace_0(_this_, predicate, true);
  }
  function filterInPlace(_this_, predicate, predicateResultToRemove) {
    if (!isInterface(_this_, RandomAccess)) {
      return filterInPlace_0(isInterface(_this_, MutableIterable) ? _this_ : THROW_CCE(), predicate, predicateResultToRemove);
    } else {
    }
    var writeIndex = 0;
    var inductionVariable = 0;
    var last_1 = _get_lastIndex__0(_this_);
    if (inductionVariable <= last_1)
      do {
        var readIndex = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var element = _this_.get_44(readIndex);
        if (predicate(element) === predicateResultToRemove)
          continue;
        if (!(writeIndex === readIndex)) {
          _this_.set_3(writeIndex, element);
          Unit_getInstance();
        }var tmp1 = writeIndex;
        writeIndex = tmp1 + 1 | 0;
        Unit_getInstance();
      }
       while (!(readIndex === last_1));
    if (writeIndex < _this_._get_size__43()) {
      var inductionVariable_0 = _get_lastIndex__0(_this_);
      if (writeIndex <= inductionVariable_0)
        do {
          var removeIndex = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + -1 | 0;
          _this_.removeAt_2(removeIndex);
          Unit_getInstance();
        }
         while (!(removeIndex === writeIndex));
      return true;
    } else {
      return false;
    }
  }
  function filterInPlace_0(_this_, predicate, predicateResultToRemove) {
    var result = false;
    var tmp0_with_0 = _this_.iterator_38();
    while (tmp0_with_0.hasNext_11())
      if (predicate(tmp0_with_0.next_13()) === predicateResultToRemove) {
        tmp0_with_0.remove_23();
        result = true;
      }return result;
  }
  function addAll(_this_, elements) {
    var tmp0_subject = elements;
    if (isInterface(tmp0_subject, Collection))
      return _this_.addAll_43(elements);
    else {
      {
        var result = false;
        var tmp1_iterator = elements.iterator_38();
        while (tmp1_iterator.hasNext_11()) {
          var item = tmp1_iterator.next_13();
          if (_this_.add_57(item))
            result = true;
        }
        return result;
      }
    }
  }
  function Sequence() {
  }
  Sequence.$metadata$ = {
    simpleName: 'Sequence',
    kind: 'interface',
    interfaces: []
  };
  function emptySet() {
    return EmptySet_getInstance();
  }
  function EmptySet() {
    EmptySet_instance = this;
    this._serialVersionUID_1 = new Long(1993859828, 793161749);
  }
  EmptySet.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, Set) : false) {
      tmp = other.isEmpty_33();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  EmptySet.prototype.hashCode = function () {
    return 0;
  };
  EmptySet.prototype.toString = function () {
    return '[]';
  };
  EmptySet.prototype._get_size__43 = function () {
    return 0;
  };
  EmptySet.prototype.isEmpty_33 = function () {
    return true;
  };
  EmptySet.prototype.contains_6 = function (element) {
    return false;
  };
  EmptySet.prototype.contains_31 = function (element) {
    if (!false)
      return false;
    else {
    }
    var tmp;
    if (false) {
      tmp = element;
    } else {
      {
        tmp = THROW_CCE();
      }
    }
    return this.contains_6(tmp);
  };
  EmptySet.prototype.containsAll_5 = function (elements) {
    return elements.isEmpty_33();
  };
  EmptySet.prototype.containsAll_19 = function (elements) {
    return this.containsAll_5(elements);
  };
  EmptySet.prototype.iterator_38 = function () {
    return EmptyIterator_getInstance();
  };
  EmptySet.$metadata$ = {
    simpleName: 'EmptySet',
    kind: 'object',
    interfaces: [Set, Serializable]
  };
  var EmptySet_instance;
  function EmptySet_getInstance() {
    if (EmptySet_instance == null)
      new EmptySet();
    return EmptySet_instance;
  }
  function hashSetOf(elements) {
    return toCollection(elements, HashSet_init_$Create$(mapCapacity(elements.length)));
  }
  function optimizeReadOnlySet(_this_) {
    var tmp0_subject = _this_._get_size__43();
    switch (tmp0_subject) {
      case 0:
        return emptySet();
      case 1:
        return setOf(_this_.iterator_38().next_13());
      default:return _this_;
    }
  }
  function Delegates() {
    Delegates_instance = this;
  }
  Delegates.$metadata$ = {
    simpleName: 'Delegates',
    kind: 'object',
    interfaces: []
  };
  var Delegates_instance;
  function Delegates_getInstance() {
    if (Delegates_instance == null)
      new Delegates();
    return Delegates_instance;
  }
  function ReadWriteProperty() {
  }
  ReadWriteProperty.$metadata$ = {
    simpleName: 'ReadWriteProperty',
    kind: 'interface',
    interfaces: [ReadOnlyProperty]
  };
  function ReadOnlyProperty() {
  }
  ReadOnlyProperty.$metadata$ = {
    simpleName: 'ReadOnlyProperty',
    kind: 'interface',
    interfaces: []
  };
  function ObservableProperty(initialValue) {
    this._value = initialValue;
  }
  ObservableProperty.prototype.beforeChange = function (property, oldValue, newValue) {
    return true;
  };
  ObservableProperty.prototype.afterChange_0 = function (property, oldValue, newValue) {
  };
  ObservableProperty.prototype.getValue_0 = function (thisRef, property) {
    return this._value;
  };
  ObservableProperty.prototype.getValue_1 = function (thisRef, property) {
    return this.getValue_0((thisRef == null ? true : isObject(thisRef)) ? thisRef : THROW_CCE(), property);
  };
  ObservableProperty.prototype.setValue_0 = function (thisRef, property, value) {
    var oldValue = this._value;
    if (!this.beforeChange(property, oldValue, value)) {
      return Unit_getInstance();
    }this._value = value;
    this.afterChange_0(property, oldValue, value);
  };
  ObservableProperty.prototype.setValue_1 = function (thisRef, property, value) {
    var tmp = (thisRef == null ? true : isObject(thisRef)) ? thisRef : THROW_CCE();
    return this.setValue_0(tmp, property, (value == null ? true : isObject(value)) ? value : THROW_CCE());
  };
  ObservableProperty.$metadata$ = {
    simpleName: 'ObservableProperty',
    kind: 'class',
    interfaces: [ReadWriteProperty]
  };
  function ClosedFloatingPointRange() {
  }
  ClosedFloatingPointRange.prototype.contains_10 = function (value) {
    return this.lessThanOrEquals_1(this._get_start__3(), value) ? this.lessThanOrEquals_1(value, this._get_endInclusive__1()) : false;
  };
  ClosedFloatingPointRange.$metadata$ = {
    simpleName: 'ClosedFloatingPointRange',
    kind: 'interface',
    interfaces: [ClosedRange]
  };
  function rangeTo(_this_, that) {
    return new ClosedDoubleRange(_this_, that);
  }
  function ClosedDoubleRange(start, endInclusive) {
    this.__start = start;
    this.__endInclusive = endInclusive;
  }
  ClosedDoubleRange.prototype._get_start__3 = function () {
    return this.__start;
  };
  ClosedDoubleRange.prototype._get_endInclusive__1 = function () {
    return this.__endInclusive;
  };
  ClosedDoubleRange.prototype.lessThanOrEquals_0 = function (a, b) {
    return a <= b;
  };
  ClosedDoubleRange.prototype.lessThanOrEquals_1 = function (a, b) {
    var tmp = typeof a === 'number' ? a : THROW_CCE();
    return this.lessThanOrEquals_0(tmp, typeof b === 'number' ? b : THROW_CCE());
  };
  ClosedDoubleRange.prototype.contains_9 = function (value) {
    return value >= this.__start ? value <= this.__endInclusive : false;
  };
  ClosedDoubleRange.prototype.contains_10 = function (value) {
    return this.contains_9(typeof value === 'number' ? value : THROW_CCE());
  };
  ClosedDoubleRange.prototype.isEmpty_33 = function () {
    return !(this.__start <= this.__endInclusive);
  };
  ClosedDoubleRange.prototype.equals = function (other) {
    var tmp;
    if (other instanceof ClosedDoubleRange) {
      tmp = (this.isEmpty_33() ? other.isEmpty_33() : false) ? true : this.__start === other.__start ? this.__endInclusive === other.__endInclusive : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  ClosedDoubleRange.prototype.hashCode = function () {
    return this.isEmpty_33() ? -1 : imul(31, getNumberHashCode(this.__start)) + getNumberHashCode(this.__endInclusive) | 0;
  };
  ClosedDoubleRange.prototype.toString = function () {
    return '' + this.__start + '..' + this.__endInclusive;
  };
  ClosedDoubleRange.$metadata$ = {
    simpleName: 'ClosedDoubleRange',
    kind: 'class',
    interfaces: [ClosedFloatingPointRange]
  };
  function KClassifier() {
  }
  KClassifier.$metadata$ = {
    simpleName: 'KClassifier',
    kind: 'interface',
    interfaces: []
  };
  function appendElement(_this_, element, transform) {
    if (!(transform == null)) {
      _this_.append_3(transform(element));
      Unit_getInstance();
    } else {
      if (element == null ? true : isCharSequence(element)) {
        _this_.append_3(element);
        Unit_getInstance();
      } else {
        if (element instanceof Char) {
          _this_.append_2(element);
          Unit_getInstance();
        } else {
          {
            _this_.append_3(toString_1(element));
            Unit_getInstance();
          }
        }
      }
    }
  }
  function equals(_this_, other, ignoreCase) {
    if (_this_.equals(other))
      return true;
    if (!ignoreCase)
      return false;
    if (uppercaseChar(_this_).equals(uppercaseChar(other)))
      return true;
    var tmp0_asDynamic_0 = _this_.toString();
    var tmp1_unsafeCast_0 = tmp0_asDynamic_0.toLowerCase().charCodeAt(0);
    var tmp = numberToChar(tmp1_unsafeCast_0);
    var tmp2_asDynamic_0 = other.toString();
    var tmp3_unsafeCast_0 = tmp2_asDynamic_0.toLowerCase().charCodeAt(0);
    if (tmp.equals(numberToChar(tmp3_unsafeCast_0)))
      return true;
    else {
    }
    return false;
  }
  function toIntOrNull(_this_) {
    return toIntOrNull_0(_this_, 10);
  }
  function toIntOrNull_0(_this_, radix) {
    checkRadix(radix);
    Unit_getInstance();
    var length = _this_.length;
    if (length === 0)
      return null;
    var start;
    var isNegative_1;
    var limit;
    var firstChar = charSequenceGet(_this_, 0);
    if (firstChar.compareTo_1(new Char(48)) < 0) {
      if (length === 1)
        return null;
      start = 1;
      if (firstChar.equals(new Char(45))) {
        isNegative_1 = true;
        limit = IntCompanionObject_getInstance()._MIN_VALUE;
      } else if (firstChar.equals(new Char(43))) {
        isNegative_1 = false;
        limit = -IntCompanionObject_getInstance()._MAX_VALUE | 0;
      } else
        return null;
    } else {
      start = 0;
      isNegative_1 = false;
      limit = -IntCompanionObject_getInstance()._MAX_VALUE | 0;
    }
    var limitForMaxRadix = (-IntCompanionObject_getInstance()._MAX_VALUE | 0) / 36 | 0;
    var limitBeforeMul = limitForMaxRadix;
    var result = 0;
    var inductionVariable = start;
    if (inductionVariable < length)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var digit = digitOf(charSequenceGet(_this_, i), radix);
        if (digit < 0)
          return null;
        if (result < limitBeforeMul) {
          if (limitBeforeMul === limitForMaxRadix) {
            limitBeforeMul = limit / radix | 0;
            if (result < limitBeforeMul) {
              return null;
            }} else {
            return null;
          }
        }result = imul(result, radix);
        if (result < (limit + digit | 0))
          return null;
        result = result - digit | 0;
      }
       while (inductionVariable < length);
    return isNegative_1 ? result : -result | 0;
  }
  function startsWith(_this_, char, ignoreCase) {
    return charSequenceLength(_this_) > 0 ? equals(charSequenceGet(_this_, 0), char, ignoreCase) : false;
  }
  function startsWith$default(_this_, char, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ignoreCase = false;
    return startsWith(_this_, char, ignoreCase);
  }
  function padStart(_this_, length, padChar) {
    return toString_2(padStart_0(isCharSequence(_this_) ? _this_ : THROW_CCE(), length, padChar));
  }
  function indexOf_1(_this_, string, startIndex, ignoreCase) {
    var tmp;
    var tmp_0;
    if (ignoreCase) {
      tmp_0 = true;
    } else {
      tmp_0 = !(typeof _this_ === 'string');
    }
    if (tmp_0) {
      var tmp_1 = charSequenceLength(_this_);
      tmp = indexOf$default_1(_this_, string, startIndex, tmp_1, ignoreCase, false, 16, null);
    } else {
      {
        var tmp0_nativeIndexOf_0 = _this_;
        tmp = tmp0_nativeIndexOf_0.indexOf(string, startIndex);
      }
    }
    return tmp;
  }
  function indexOf$default(_this_, string, startIndex, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    return indexOf_1(_this_, string, startIndex, ignoreCase);
  }
  function indexOf_2(_this_, char, startIndex, ignoreCase) {
    var tmp;
    var tmp_0;
    if (ignoreCase) {
      tmp_0 = true;
    } else {
      tmp_0 = !(typeof _this_ === 'string');
    }
    if (tmp_0) {
      var tmp0_charArrayOf_0 = charArrayOf([char]);
      tmp = indexOfAny(_this_, tmp0_charArrayOf_0, startIndex, ignoreCase);
    } else {
      {
        var tmp2_nativeIndexOf_0 = _this_;
        var tmp1_nativeIndexOf_0 = char.toString();
        tmp = tmp2_nativeIndexOf_0.indexOf(tmp1_nativeIndexOf_0, startIndex);
      }
    }
    return tmp;
  }
  function indexOf$default_0(_this_, char, startIndex, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    return indexOf_2(_this_, char, startIndex, ignoreCase);
  }
  function padStart_0(_this_, length, padChar) {
    if (length < 0)
      throw IllegalArgumentException_init_$Create$('' + 'Desired length ' + length + ' is less than zero.');
    if (length <= charSequenceLength(_this_))
      return charSequenceSubSequence(_this_, 0, charSequenceLength(_this_));
    var sb = StringBuilder_init_$Create$(length);
    var inductionVariable = 1;
    var last_1 = length - charSequenceLength(_this_) | 0;
    if (inductionVariable <= last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        sb.append_2(padChar);
        Unit_getInstance();
      }
       while (!(i === last_1));
    sb.append_3(_this_);
    Unit_getInstance();
    return sb;
  }
  function indexOf_3(_this_, other, startIndex, endIndex, ignoreCase, last_1) {
    var indices = !last_1 ? numberRangeToNumber(coerceAtLeast(startIndex, 0), coerceAtMost(endIndex, charSequenceLength(_this_))) : downTo(coerceAtMost(startIndex, _get_lastIndex__1(_this_)), coerceAtLeast(endIndex, 0));
    var tmp;
    if (typeof _this_ === 'string') {
      tmp = typeof other === 'string';
    } else {
      {
        tmp = false;
      }
    }
    if (tmp) {
      var inductionVariable = indices._first_0;
      var last_2 = indices._last;
      var step = indices._step_0;
      if ((step > 0 ? inductionVariable <= last_2 : false) ? true : step < 0 ? last_2 <= inductionVariable : false)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + step | 0;
          if (regionMatches(other, 0, _this_, index, charSequenceLength(other), ignoreCase))
            return index;
        }
         while (!(index === last_2));
    } else {
      {
        var inductionVariable_0 = indices._first_0;
        var last_3 = indices._last;
        var step_0 = indices._step_0;
        if ((step_0 > 0 ? inductionVariable_0 <= last_3 : false) ? true : step_0 < 0 ? last_3 <= inductionVariable_0 : false)
          do {
            var index_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + step_0 | 0;
            if (regionMatchesImpl(other, 0, _this_, index_0, charSequenceLength(other), ignoreCase))
              return index_0;
          }
           while (!(index_0 === last_3));
      }
    }
    return -1;
  }
  function indexOf$default_1(_this_, other, startIndex, endIndex, ignoreCase, last_1, $mask0, $handler) {
    if (!(($mask0 & 16) === 0))
      last_1 = false;
    return indexOf_3(_this_, other, startIndex, endIndex, ignoreCase, last_1);
  }
  function trim(_this_) {
    var startIndex_1 = 0;
    var endIndex_2 = charSequenceLength(_this_) - 1 | 0;
    var startFound_3 = false;
    $l$break: while (startIndex_1 <= endIndex_2) {
      var index_4 = !startFound_3 ? startIndex_1 : endIndex_2;
      var match_5 = isWhitespace(charSequenceGet(_this_, index_4));
      if (!startFound_3) {
        if (!match_5)
          startFound_3 = true;
        else
          startIndex_1 = startIndex_1 + 1 | 0;
      } else {
        if (!match_5)
          break $l$break;
        else
          endIndex_2 = endIndex_2 - 1 | 0;
      }
    }
    return charSequenceSubSequence(_this_, startIndex_1, endIndex_2 + 1 | 0);
  }
  function indexOfAny(_this_, chars, startIndex, ignoreCase) {
    var tmp;
    if (!ignoreCase ? chars.length === 1 : false) {
      tmp = typeof _this_ === 'string';
    } else {
      tmp = false;
    }
    if (tmp) {
      var char = single(chars);
      var tmp1_nativeIndexOf_0 = _this_;
      var tmp0_nativeIndexOf_0 = char.toString();
      return tmp1_nativeIndexOf_0.indexOf(tmp0_nativeIndexOf_0, startIndex);
    } else {
    }
    var inductionVariable = coerceAtLeast(startIndex, 0);
    var last_1 = _get_lastIndex__1(_this_);
    if (inductionVariable <= last_1)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var charAtIndex = charSequenceGet(_this_, index);
        var tmp$ret$0;
        l$ret$1: do {
          var indexedObject = chars;
          var inductionVariable_0 = 0;
          var last_2 = indexedObject.length;
          while (inductionVariable_0 < last_2) {
            var element_2 = indexedObject[inductionVariable_0];
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            if (equals(element_2, charAtIndex, ignoreCase)) {
              tmp$ret$0 = true;
              break l$ret$1;
            } else {
            }
          }
          tmp$ret$0 = false;
        }
         while (false);
        if (tmp$ret$0)
          return index;
        else {
        }
      }
       while (!(index === last_1));
    return -1;
  }
  function regionMatchesImpl(_this_, thisOffset, other, otherOffset, length, ignoreCase) {
    if (((otherOffset < 0 ? true : thisOffset < 0) ? true : thisOffset > (charSequenceLength(_this_) - length | 0)) ? true : otherOffset > (charSequenceLength(other) - length | 0)) {
      return false;
    }var inductionVariable = 0;
    if (inductionVariable < length)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!equals(charSequenceGet(_this_, thisOffset + index | 0), charSequenceGet(other, otherOffset + index | 0), ignoreCase))
          return false;
      }
       while (inductionVariable < length);
    return true;
  }
  function _get_lastIndex__1(_this_) {
    return charSequenceLength(_this_) - 1 | 0;
  }
  function split(_this_, delimiters, ignoreCase, limit) {
    if (delimiters.length === 1) {
      var delimiter = delimiters[0];
      if (!(charSequenceLength(delimiter) === 0)) {
        return split_0(_this_, delimiter, ignoreCase, limit);
      } else {
      }
    }var tmp0_map_0 = asIterable(rangesDelimitedBy$default(_this_, delimiters, 0, ignoreCase, limit, 2, null));
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      tmp0_mapTo_0_1.add_57(substring(_this_, item_2_3));
      Unit_getInstance();
    }
    return tmp0_mapTo_0_1;
  }
  function split$default(_this_, delimiters, ignoreCase, limit, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ignoreCase = false;
    if (!(($mask0 & 4) === 0))
      limit = 0;
    return split(_this_, delimiters, ignoreCase, limit);
  }
  function contains_0(_this_, char, ignoreCase) {
    return indexOf$default_0(_this_, char, 0, ignoreCase, 2, null) >= 0;
  }
  function contains$default(_this_, char, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ignoreCase = false;
    return contains_0(_this_, char, ignoreCase);
  }
  function split_0(_this_, delimiter, ignoreCase, limit) {
    var tmp0_require_0 = limit >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Limit must be non-negative, but was ' + limit + '.';
      throw IllegalArgumentException_init_$Create$(toString_2(message_2));
    }var currentOffset = 0;
    var nextIndex = indexOf_1(_this_, delimiter, currentOffset, ignoreCase);
    if (nextIndex === -1 ? true : limit === 1) {
      return listOf_0(toString_2(_this_));
    }var isLimited = limit > 0;
    var result = ArrayList_init_$Create$_0(isLimited ? coerceAtMost(limit, 10) : 10);
    $l$break: do {
      var tmp1_substring_0 = currentOffset;
      var tmp2_substring_0 = nextIndex;
      result.add_57(toString_2(charSequenceSubSequence(_this_, tmp1_substring_0, tmp2_substring_0)));
      Unit_getInstance();
      currentOffset = nextIndex + delimiter.length | 0;
      if (isLimited ? result._get_size__43() === (limit - 1 | 0) : false)
        break $l$break;
      nextIndex = indexOf_1(_this_, delimiter, currentOffset, ignoreCase);
    }
     while (!(nextIndex === -1));
    var tmp3_substring_0 = currentOffset;
    var tmp4_substring_0 = charSequenceLength(_this_);
    result.add_57(toString_2(charSequenceSubSequence(_this_, tmp3_substring_0, tmp4_substring_0)));
    Unit_getInstance();
    return result;
  }
  function substring(_this_, range) {
    return toString_2(charSequenceSubSequence(_this_, range._get_start__3(), range._get_endInclusive__1() + 1 | 0));
  }
  function rangesDelimitedBy(_this_, delimiters, startIndex, ignoreCase, limit) {
    var tmp0_require_0 = limit >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Limit must be non-negative, but was ' + limit + '.';
      throw IllegalArgumentException_init_$Create$(toString_2(message_2));
    }var delimitersList = asList(delimiters);
    return new DelimitedRangesSequence(_this_, startIndex, limit, _no_name_provided_$factory_1(delimitersList, ignoreCase));
  }
  function rangesDelimitedBy$default(_this_, delimiters, startIndex, ignoreCase, limit, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    if (!(($mask0 & 8) === 0))
      limit = 0;
    return rangesDelimitedBy(_this_, delimiters, startIndex, ignoreCase, limit);
  }
  function calcNext($this) {
    if ($this._nextSearchIndex < 0) {
      $this._nextState = 0;
      $this._nextItem = null;
    } else {
      var tmp;
      var tmp_0;
      if ($this._this$0_1._limit > 0) {
        var tmp0_this = $this;
        tmp0_this._counter = tmp0_this._counter + 1 | 0;
        tmp_0 = tmp0_this._counter >= $this._this$0_1._limit;
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp = true;
      } else {
        {
          tmp = $this._nextSearchIndex > charSequenceLength($this._this$0_1._input);
        }
      }
      if (tmp) {
        $this._nextItem = numberRangeToNumber($this._currentStartIndex, _get_lastIndex__1($this._this$0_1._input));
        $this._nextSearchIndex = -1;
      } else {
        {
          var match = $this._this$0_1._getNextMatch($this._this$0_1._input, $this._nextSearchIndex);
          if (match == null) {
            $this._nextItem = numberRangeToNumber($this._currentStartIndex, _get_lastIndex__1($this._this$0_1._input));
            $this._nextSearchIndex = -1;
          } else {
            var tmp1_container = match;
            var index = tmp1_container.component1_0();
            var length = tmp1_container.component2_0();
            $this._nextItem = until($this._currentStartIndex, index);
            $this._currentStartIndex = index + length | 0;
            $this._nextSearchIndex = $this._currentStartIndex + (length === 0 ? 1 : 0) | 0;
          }
        }
      }
      $this._nextState = 1;
    }
  }
  function _no_name_provided__2(this$0) {
    this._this$0_1 = this$0;
    this._nextState = -1;
    this._currentStartIndex = coerceIn(this._this$0_1._startIndex, 0, charSequenceLength(this._this$0_1._input));
    this._nextSearchIndex = this._currentStartIndex;
    this._nextItem = null;
    this._counter = 0;
  }
  _no_name_provided__2.prototype.next_13 = function () {
    if (this._nextState === -1)
      calcNext(this);
    if (this._nextState === 0)
      throw NoSuchElementException_init_$Create$();
    var tmp = this._nextItem;
    var result = tmp instanceof IntRange ? tmp : THROW_CCE();
    this._nextItem = null;
    this._nextState = -1;
    return result;
  };
  _no_name_provided__2.prototype.hasNext_11 = function () {
    if (this._nextState === -1)
      calcNext(this);
    return this._nextState === 1;
  };
  _no_name_provided__2.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator]
  };
  function DelimitedRangesSequence(input, startIndex, limit, getNextMatch) {
    this._input = input;
    this._startIndex = startIndex;
    this._limit = limit;
    this._getNextMatch = getNextMatch;
  }
  DelimitedRangesSequence.prototype.iterator_38 = function () {
    return new _no_name_provided__2(this);
  };
  DelimitedRangesSequence.$metadata$ = {
    simpleName: 'DelimitedRangesSequence',
    kind: 'class',
    interfaces: [Sequence]
  };
  function findAnyOf(_this_, strings, startIndex, ignoreCase, last_1) {
    if (!ignoreCase ? strings._get_size__43() === 1 : false) {
      var string = single_0(strings);
      var tmp;
      if (!last_1) {
        tmp = indexOf$default(_this_, string, startIndex, false, 4, null);
      } else {
        tmp = lastIndexOf$default(_this_, string, startIndex, false, 4, null);
      }
      var index = tmp;
      return index < 0 ? null : to(index, string);
    }var indices = !last_1 ? numberRangeToNumber(coerceAtLeast(startIndex, 0), charSequenceLength(_this_)) : downTo(coerceAtMost(startIndex, _get_lastIndex__1(_this_)), 0);
    if (typeof _this_ === 'string') {
      var inductionVariable = indices._first_0;
      var last_2 = indices._last;
      var step = indices._step_0;
      if ((step > 0 ? inductionVariable <= last_2 : false) ? true : step < 0 ? last_2 <= inductionVariable : false)
        do {
          var index_0 = inductionVariable;
          inductionVariable = inductionVariable + step | 0;
          var tmp$ret$0;
          l$ret$1: do {
            var tmp0_iterator_1 = strings.iterator_38();
            while (tmp0_iterator_1.hasNext_11()) {
              var element_2 = tmp0_iterator_1.next_13();
              if (regionMatches(element_2, 0, _this_, index_0, element_2.length, ignoreCase)) {
                tmp$ret$0 = element_2;
                break l$ret$1;
              } else {
              }
            }
            tmp$ret$0 = null;
          }
           while (false);
          var matchingString = tmp$ret$0;
          if (!(matchingString == null))
            return to(index_0, matchingString);
        }
         while (!(index_0 === last_2));
    } else {
      {
        var inductionVariable_0 = indices._first_0;
        var last_3 = indices._last;
        var step_0 = indices._step_0;
        if ((step_0 > 0 ? inductionVariable_0 <= last_3 : false) ? true : step_0 < 0 ? last_3 <= inductionVariable_0 : false)
          do {
            var index_1 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + step_0 | 0;
            var tmp$ret$2;
            l$ret$3: do {
              var tmp0_iterator_1_0 = strings.iterator_38();
              while (tmp0_iterator_1_0.hasNext_11()) {
                var element_2_0 = tmp0_iterator_1_0.next_13();
                if (regionMatchesImpl(element_2_0, 0, _this_, index_1, element_2_0.length, ignoreCase)) {
                  tmp$ret$2 = element_2_0;
                  break l$ret$3;
                } else {
                }
              }
              tmp$ret$2 = null;
            }
             while (false);
            var matchingString_0 = tmp$ret$2;
            if (!(matchingString_0 == null))
              return to(index_1, matchingString_0);
          }
           while (!(index_1 === last_3));
      }
    }
    return null;
  }
  function lastIndexOf(_this_, string, startIndex, ignoreCase) {
    var tmp;
    var tmp_0;
    if (ignoreCase) {
      tmp_0 = true;
    } else {
      tmp_0 = !(typeof _this_ === 'string');
    }
    if (tmp_0) {
      tmp = indexOf_3(_this_, string, startIndex, 0, ignoreCase, true);
    } else {
      {
        var tmp0_nativeLastIndexOf_0 = _this_;
        tmp = tmp0_nativeLastIndexOf_0.lastIndexOf(string, startIndex);
      }
    }
    return tmp;
  }
  function lastIndexOf$default(_this_, string, startIndex, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = _get_lastIndex__1(_this_);
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    return lastIndexOf(_this_, string, startIndex, ignoreCase);
  }
  function _no_name_provided__3($delimitersList, $ignoreCase) {
    this._$delimitersList = $delimitersList;
    this._$ignoreCase = $ignoreCase;
  }
  _no_name_provided__3.prototype.invoke_3 = function (_this__0, currentIndex) {
    var tmp0_safe_receiver = findAnyOf(_this__0, this._$delimitersList, currentIndex, this._$ignoreCase, false);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = to(tmp0_safe_receiver._first, tmp0_safe_receiver._second.length);
    }
    return tmp;
  };
  _no_name_provided__3.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isCharSequence(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_3(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__3.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_1($delimitersList, $ignoreCase) {
    var i = new _no_name_provided__3($delimitersList, $ignoreCase);
    return function (p1, p2) {
      return i.invoke_3(p1, p2);
    };
  }
  function _Duration___init__impl_(value) {
    return value;
  }
  function _Duration___get_value__impl_(this_0) {
    return this_0;
  }
  function Companion_4() {
    Companion_instance_3 = this;
    this._ZERO = _Duration___init__impl_(0.0);
    this._INFINITE = _Duration___init__impl_(Infinity);
  }
  Companion_4.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_3;
  function Companion_getInstance_3() {
    if (Companion_instance_3 == null)
      new Companion_4();
    return Companion_instance_3;
  }
  function Duration__plus_impl(this_0, other) {
    return _Duration___init__impl_(_Duration___get_value__impl_(this_0) + _Duration___get_value__impl_(other));
  }
  function Duration__minus_impl(this_0, other) {
    return _Duration___init__impl_(_Duration___get_value__impl_(this_0) - _Duration___get_value__impl_(other));
  }
  function Duration__toDouble_impl(this_0, unit) {
    var tmp = _Duration___get_value__impl_(this_0);
    return convertDurationUnit(tmp, DurationUnit_NANOSECONDS_getInstance(), unit);
  }
  function _Duration___get_inMilliseconds__impl_(this_0) {
    return Duration__toDouble_impl(this_0, DurationUnit_MILLISECONDS_getInstance());
  }
  function _get_minutes_(_this_) {
    return toDuration(_this_, DurationUnit_MINUTES_getInstance());
  }
  function toDuration(_this_, unit) {
    return toDuration_0(_this_, unit);
  }
  function toDuration_0(_this_, unit) {
    return _Duration___init__impl_(convertDurationUnit(_this_, unit, DurationUnit_NANOSECONDS_getInstance()));
  }
  function _get_nanoseconds_(_this_) {
    return toDuration_0(_this_, DurationUnit_NANOSECONDS_getInstance());
  }
  function _get_seconds_(_this_) {
    return toDuration_0(_this_, DurationUnit_SECONDS_getInstance());
  }
  function Monotonic() {
    Monotonic_instance = this;
    this._$$delegate_0 = MonotonicTimeSource_getInstance();
  }
  Monotonic.prototype.markNow_5 = function () {
    return this._$$delegate_0.markNow_5();
  };
  Monotonic.prototype.toString = function () {
    return toString_2(MonotonicTimeSource_getInstance());
  };
  Monotonic.$metadata$ = {
    simpleName: 'Monotonic',
    kind: 'object',
    interfaces: [TimeSource]
  };
  var Monotonic_instance;
  function Monotonic_getInstance() {
    if (Monotonic_instance == null)
      new Monotonic();
    return Monotonic_instance;
  }
  function TimeSource() {
  }
  TimeSource.$metadata$ = {
    simpleName: 'TimeSource',
    kind: 'interface',
    interfaces: []
  };
  function TimeMark() {
  }
  TimeMark.$metadata$ = {
    simpleName: 'TimeMark',
    kind: 'class',
    interfaces: []
  };
  function DoubleTimeMark(startedAt, timeSource, offset_0) {
    TimeMark.call(this);
    this._startedAt = startedAt;
    this._timeSource = timeSource;
    this._offset = offset_0;
  }
  DoubleTimeMark.prototype.elapsedNow_1 = function () {
    return Duration__minus_impl(toDuration_0(this._timeSource.read_2() - this._startedAt, this._timeSource._unit), this._offset);
  };
  DoubleTimeMark.$metadata$ = {
    simpleName: 'DoubleTimeMark',
    kind: 'class',
    interfaces: []
  };
  function AbstractDoubleTimeSource(unit) {
    this._unit = unit;
  }
  AbstractDoubleTimeSource.prototype.markNow_5 = function () {
    return new DoubleTimeMark(this.read_2(), this, Companion_getInstance_3()._ZERO);
  };
  AbstractDoubleTimeSource.$metadata$ = {
    simpleName: 'AbstractDoubleTimeSource',
    kind: 'class',
    interfaces: [TimeSource]
  };
  function Lazy() {
  }
  Lazy.$metadata$ = {
    simpleName: 'Lazy',
    kind: 'interface',
    interfaces: []
  };
  function UnsafeLazyImpl(initializer) {
    this._initializer = initializer;
    this.__value = UNINITIALIZED_VALUE_getInstance();
  }
  UnsafeLazyImpl.prototype._get_value__18 = function () {
    if (this.__value === UNINITIALIZED_VALUE_getInstance()) {
      this.__value = ensureNotNull(this._initializer)();
      this._initializer = null;
    }var tmp = this.__value;
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  UnsafeLazyImpl.prototype.isInitialized = function () {
    return !(this.__value === UNINITIALIZED_VALUE_getInstance());
  };
  UnsafeLazyImpl.prototype.toString = function () {
    return this.isInitialized() ? toString_1(this._get_value__18()) : 'Lazy value not initialized yet.';
  };
  UnsafeLazyImpl.$metadata$ = {
    simpleName: 'UnsafeLazyImpl',
    kind: 'class',
    interfaces: [Lazy, Serializable]
  };
  function UNINITIALIZED_VALUE() {
    UNINITIALIZED_VALUE_instance = this;
  }
  UNINITIALIZED_VALUE.$metadata$ = {
    simpleName: 'UNINITIALIZED_VALUE',
    kind: 'object',
    interfaces: []
  };
  var UNINITIALIZED_VALUE_instance;
  function UNINITIALIZED_VALUE_getInstance() {
    if (UNINITIALIZED_VALUE_instance == null)
      new UNINITIALIZED_VALUE();
    return UNINITIALIZED_VALUE_instance;
  }
  function apply(_this_, block) {
    block(_this_);
    return _this_;
  }
  function Pair(first_1, second) {
    this._first = first_1;
    this._second = second;
  }
  Pair.prototype.toString = function () {
    return '' + '(' + this._first + ', ' + this._second + ')';
  };
  Pair.prototype.component1_0 = function () {
    return this._first;
  };
  Pair.prototype.component2_0 = function () {
    return this._second;
  };
  Pair.prototype.hashCode = function () {
    var result = this._first == null ? 0 : hashCode(this._first);
    result = imul(result, 31) + (this._second == null ? 0 : hashCode(this._second)) | 0;
    return result;
  };
  Pair.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Pair))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Pair ? other : THROW_CCE();
    if (!equals_0(this._first, tmp0_other_with_cast._first))
      return false;
    if (!equals_0(this._second, tmp0_other_with_cast._second))
      return false;
    return true;
  };
  Pair.$metadata$ = {
    simpleName: 'Pair',
    kind: 'class',
    interfaces: [Serializable]
  };
  function to(_this_, that) {
    return new Pair(_this_, that);
  }
  function _UShort___init__impl_(data) {
    return data;
  }
  function _UShort___get_data__impl_(this_0) {
    return this_0;
  }
  function CharSequence() {
  }
  CharSequence.$metadata$ = {
    simpleName: 'CharSequence',
    kind: 'interface',
    interfaces: []
  };
  function Comparable() {
  }
  Comparable.$metadata$ = {
    simpleName: 'Comparable',
    kind: 'interface',
    interfaces: []
  };
  function Iterator() {
  }
  Iterator.$metadata$ = {
    simpleName: 'Iterator',
    kind: 'interface',
    interfaces: []
  };
  function MutableIterator() {
  }
  MutableIterator.$metadata$ = {
    simpleName: 'MutableIterator',
    kind: 'interface',
    interfaces: [Iterator]
  };
  function ListIterator() {
  }
  ListIterator.$metadata$ = {
    simpleName: 'ListIterator',
    kind: 'interface',
    interfaces: [Iterator]
  };
  function MutableListIterator() {
  }
  MutableListIterator.$metadata$ = {
    simpleName: 'MutableListIterator',
    kind: 'interface',
    interfaces: [ListIterator, MutableIterator]
  };
  function Number_0() {
  }
  Number_0.$metadata$ = {
    simpleName: 'Number',
    kind: 'class',
    interfaces: []
  };
  function IntIterator() {
  }
  IntIterator.prototype.next_13 = function () {
    return this.nextInt_0();
  };
  IntIterator.$metadata$ = {
    simpleName: 'IntIterator',
    kind: 'class',
    interfaces: [Iterator]
  };
  function IntProgressionIterator(first_1, last_1, step) {
    IntIterator.call(this);
    this._step = step;
    this._finalElement = last_1;
    this._hasNext = this._step > 0 ? first_1 <= last_1 : first_1 >= last_1;
    this._next = this._hasNext ? first_1 : this._finalElement;
  }
  IntProgressionIterator.prototype.hasNext_11 = function () {
    return this._hasNext;
  };
  IntProgressionIterator.prototype.nextInt_0 = function () {
    var value = this._next;
    if (value === this._finalElement) {
      if (!this._hasNext)
        throw NoSuchElementException_init_$Create$();
      this._hasNext = false;
    } else {
      var tmp0_this = this;
      tmp0_this._next = tmp0_this._next + this._step | 0;
    }
    return value;
  };
  IntProgressionIterator.$metadata$ = {
    simpleName: 'IntProgressionIterator',
    kind: 'class',
    interfaces: []
  };
  function Companion_5() {
    Companion_instance_4 = this;
  }
  Companion_5.prototype.fromClosedRange = function (rangeStart, rangeEnd, step) {
    return new IntProgression(rangeStart, rangeEnd, step);
  };
  Companion_5.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_4;
  function Companion_getInstance_4() {
    if (Companion_instance_4 == null)
      new Companion_5();
    return Companion_instance_4;
  }
  function IntProgression(start, endInclusive, step) {
    Companion_getInstance_4();
    if (step === 0)
      throw IllegalArgumentException_init_$Create$('Step must be non-zero.');
    if (step === IntCompanionObject_getInstance()._MIN_VALUE)
      throw IllegalArgumentException_init_$Create$('Step must be greater than Int.MIN_VALUE to avoid overflow on negation.');
    this._first_0 = start;
    this._last = getProgressionLastElement(start, endInclusive, step);
    this._step_0 = step;
  }
  IntProgression.prototype._get_first__0 = function () {
    return this._first_0;
  };
  IntProgression.prototype._get_last__2 = function () {
    return this._last;
  };
  IntProgression.prototype.iterator_38 = function () {
    return new IntProgressionIterator(this._first_0, this._last, this._step_0);
  };
  IntProgression.prototype.isEmpty_33 = function () {
    return this._step_0 > 0 ? this._first_0 > this._last : this._first_0 < this._last;
  };
  IntProgression.prototype.equals = function (other) {
    var tmp;
    if (other instanceof IntProgression) {
      tmp = (this.isEmpty_33() ? other.isEmpty_33() : false) ? true : (this._first_0 === other._first_0 ? this._last === other._last : false) ? this._step_0 === other._step_0 : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  IntProgression.prototype.hashCode = function () {
    return this.isEmpty_33() ? -1 : imul(31, imul(31, this._first_0) + this._last | 0) + this._step_0 | 0;
  };
  IntProgression.prototype.toString = function () {
    return this._step_0 > 0 ? '' + this._first_0 + '..' + this._last + ' step ' + this._step_0 : '' + this._first_0 + ' downTo ' + this._last + ' step ' + (-this._step_0 | 0);
  };
  IntProgression.$metadata$ = {
    simpleName: 'IntProgression',
    kind: 'class',
    interfaces: [Iterable]
  };
  function ClosedRange() {
  }
  ClosedRange.$metadata$ = {
    simpleName: 'ClosedRange',
    kind: 'interface',
    interfaces: []
  };
  function Companion_6() {
    Companion_instance_5 = this;
    this._EMPTY = new IntRange(1, 0);
  }
  Companion_6.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_5;
  function Companion_getInstance_5() {
    if (Companion_instance_5 == null)
      new Companion_6();
    return Companion_instance_5;
  }
  function IntRange(start, endInclusive) {
    Companion_getInstance_5();
    IntProgression.call(this, start, endInclusive, 1);
  }
  IntRange.prototype._get_start__3 = function () {
    return this._get_first__0();
  };
  IntRange.prototype._get_endInclusive__1 = function () {
    return this._get_last__2();
  };
  IntRange.prototype.contains_11 = function (value) {
    return this._get_first__0() <= value ? value <= this._get_last__2() : false;
  };
  IntRange.prototype.isEmpty_33 = function () {
    return this._get_first__0() > this._get_last__2();
  };
  IntRange.prototype.equals = function (other) {
    var tmp;
    if (other instanceof IntRange) {
      tmp = (this.isEmpty_33() ? other.isEmpty_33() : false) ? true : this._get_first__0() === other._get_first__0() ? this._get_last__2() === other._get_last__2() : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  IntRange.prototype.hashCode = function () {
    return this.isEmpty_33() ? -1 : imul(31, this._get_first__0()) + this._get_last__2() | 0;
  };
  IntRange.prototype.toString = function () {
    return '' + this._get_first__0() + '..' + this._get_last__2();
  };
  IntRange.$metadata$ = {
    simpleName: 'IntRange',
    kind: 'class',
    interfaces: [ClosedRange]
  };
  function Unit() {
    Unit_instance = this;
  }
  Unit.prototype.toString = function () {
    return 'kotlin.Unit';
  };
  Unit.$metadata$ = {
    simpleName: 'Unit',
    kind: 'object',
    interfaces: []
  };
  var Unit_instance;
  function Unit_getInstance() {
    if (Unit_instance == null)
      new Unit();
    return Unit_instance;
  }
  function getProgressionLastElement(start, end, step) {
    var tmp;
    if (step > 0) {
      tmp = start >= end ? end : end - differenceModulo(end, start, step) | 0;
    } else if (step < 0) {
      tmp = start <= end ? end : end + differenceModulo(start, end, -step | 0) | 0;
    } else {
      throw IllegalArgumentException_init_$Create$('Step is zero.');
    }
    return tmp;
  }
  function differenceModulo(a, b, c) {
    return mod(mod(a, c) - mod(b, c) | 0, c);
  }
  function mod(a, b) {
    var mod_0 = a % b;
    return mod_0 >= 0 ? mod_0 : mod_0 + b | 0;
  }
  function IntCompanionObject_0() {
    IntCompanionObject_instance = this;
    this._MIN_VALUE = -2147483648;
    this._MAX_VALUE = 2147483647;
    this._SIZE_BYTES = 4;
    this._SIZE_BITS = 32;
  }
  IntCompanionObject_0.prototype._get_MIN_VALUE_ = function () {
    return this._MIN_VALUE;
  };
  IntCompanionObject_0.prototype._get_MAX_VALUE_ = function () {
    return this._MAX_VALUE;
  };
  IntCompanionObject_0.prototype._get_SIZE_BYTES_ = function () {
    return this._SIZE_BYTES;
  };
  IntCompanionObject_0.prototype._get_SIZE_BITS_ = function () {
    return this._SIZE_BITS;
  };
  IntCompanionObject_0.$metadata$ = {
    simpleName: 'IntCompanionObject',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(IntCompanionObject_0.prototype, 'MIN_VALUE', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_MIN_VALUE_
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'MAX_VALUE', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_MAX_VALUE_
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'SIZE_BYTES', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_SIZE_BYTES_
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'SIZE_BITS', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_SIZE_BITS_
  });
  var IntCompanionObject_instance;
  function IntCompanionObject_getInstance() {
    if (IntCompanionObject_instance == null)
      new IntCompanionObject_0();
    return IntCompanionObject_instance;
  }
  function Comparator() {
  }
  Comparator.$metadata$ = {
    simpleName: 'Comparator',
    kind: 'interface',
    interfaces: []
  };
  function setOf(element) {
    return hashSetOf([element]);
  }
  function mapCapacity(expectedSize) {
    return expectedSize;
  }
  function mapOf_0(pair) {
    return hashMapOf([pair]);
  }
  function arrayCopy_0(source, destination, destinationOffset, startIndex, endIndex) {
    Companion_getInstance().checkRangeIndexes(startIndex, endIndex, source.length);
    var rangeSize = endIndex - startIndex | 0;
    Companion_getInstance().checkRangeIndexes(destinationOffset, destinationOffset + rangeSize | 0, destination.length);
    if (ArrayBuffer.isView(destination) && ArrayBuffer.isView(source)) {
      var subrange = source.subarray(startIndex, endIndex);
      destination.set(subrange, destinationOffset);
    } else {
      if (!(source === destination) ? true : destinationOffset <= startIndex) {
        var inductionVariable = 0;
        if (inductionVariable < rangeSize)
          do {
            var index = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            destination[destinationOffset + index | 0] = source[startIndex + index | 0];
          }
           while (inductionVariable < rangeSize);
      } else {
        var inductionVariable_0 = rangeSize - 1 | 0;
        if (0 <= inductionVariable_0)
          do {
            var index_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + -1 | 0;
            destination[destinationOffset + index_0 | 0] = source[startIndex + index_0 | 0];
          }
           while (0 <= inductionVariable_0);
      }
    }
  }
  function listOf_0(element) {
    return arrayListOf([element]);
  }
  function checkIndexOverflow(index) {
    if (index < 0) {
      throwIndexOverflow();
    }return index;
  }
  function copyToArray_0(collection) {
    var tmp;
    if (collection.toArray !== undefined) {
      var tmp0_unsafeCast_0 = collection.toArray();
      tmp = tmp0_unsafeCast_0;
    } else {
      {
        var tmp1_unsafeCast_0 = copyToArrayImpl_0(collection);
        tmp = tmp1_unsafeCast_0;
      }
    }
    return tmp;
  }
  function copyToArrayImpl_0(collection) {
    var array = [];
    var iterator_0 = collection.iterator_38();
    while (iterator_0.hasNext_11()) {
      array.push(iterator_0.next_13());
    }
    return array;
  }
  function sortWith(_this_, comparator) {
    collectionsSort(_this_, comparator);
  }
  function collectionsSort(list, comparator) {
    if (list._get_size__43() <= 1)
      return Unit_getInstance();
    var array = copyToArray_0(list);
    sortArrayWith(array, comparator);
    var inductionVariable = 0;
    var last_1 = array.length;
    if (inductionVariable < last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        list.set_3(i, array[i]);
        Unit_getInstance();
      }
       while (inductionVariable < last_1);
  }
  function AbstractMutableCollection() {
    AbstractCollection.call(this);
  }
  AbstractMutableCollection.prototype.remove_59 = function (element) {
    this.checkIsMutable_13();
    var iterator_0 = this.iterator_38();
    while (iterator_0.hasNext_11()) {
      if (equals_0(iterator_0.next_13(), element)) {
        iterator_0.remove_23();
        return true;
      }}
    return false;
  };
  AbstractMutableCollection.prototype.addAll_43 = function (elements) {
    this.checkIsMutable_13();
    var modified = false;
    var tmp0_iterator = elements.iterator_38();
    while (tmp0_iterator.hasNext_11()) {
      var element = tmp0_iterator.next_13();
      if (this.add_57(element))
        modified = true;
    }
    return modified;
  };
  AbstractMutableCollection.prototype.clear_27 = function () {
    this.checkIsMutable_13();
    var iterator_0 = this.iterator_38();
    while (iterator_0.hasNext_11()) {
      iterator_0.next_13();
      Unit_getInstance();
      iterator_0.remove_23();
    }
  };
  AbstractMutableCollection.prototype.toJSON = function () {
    return this.toArray();
  };
  AbstractMutableCollection.prototype.checkIsMutable_13 = function () {
  };
  AbstractMutableCollection.$metadata$ = {
    simpleName: 'AbstractMutableCollection',
    kind: 'class',
    interfaces: [MutableCollection]
  };
  function IteratorImpl($outer) {
    this._$this = $outer;
    this._index = 0;
    this._last_0 = -1;
  }
  IteratorImpl.prototype._set_index__0 = function (_set___) {
    this._index = _set___;
  };
  IteratorImpl.prototype._get_index__0 = function () {
    return this._index;
  };
  IteratorImpl.prototype._set_last__0 = function (_set___) {
    this._last_0 = _set___;
  };
  IteratorImpl.prototype._get_last__2 = function () {
    return this._last_0;
  };
  IteratorImpl.prototype.hasNext_11 = function () {
    return this._index < this._$this._get_size__43();
  };
  IteratorImpl.prototype.next_13 = function () {
    if (!this.hasNext_11())
      throw NoSuchElementException_init_$Create$();
    var tmp = this;
    var tmp0_this = this;
    var tmp1 = tmp0_this._index;
    tmp0_this._index = tmp1 + 1 | 0;
    tmp._last_0 = tmp1;
    return this._$this.get_44(this._last_0);
  };
  IteratorImpl.prototype.remove_23 = function () {
    var tmp0_check_0 = !(this._last_0 === -1);
    if (!tmp0_check_0) {
      var message_1 = 'Call next() or previous() before removing element from the iterator.';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }this._$this.removeAt_2(this._last_0);
    Unit_getInstance();
    this._index = this._last_0;
    this._last_0 = -1;
  };
  IteratorImpl.$metadata$ = {
    simpleName: 'IteratorImpl',
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function ListIteratorImpl($outer, index) {
    this._$this_0 = $outer;
    IteratorImpl.call(this, $outer);
    Companion_getInstance().checkPositionIndex(index, this._$this_0._get_size__43());
    this._set_index__0(index);
  }
  ListIteratorImpl.prototype.hasPrevious_1 = function () {
    return this._get_index__0() > 0;
  };
  ListIteratorImpl.prototype.nextIndex_1 = function () {
    return this._get_index__0();
  };
  ListIteratorImpl.prototype.previous_1 = function () {
    if (!this.hasPrevious_1())
      throw NoSuchElementException_init_$Create$();
    var tmp0_this = this;
    tmp0_this._set_index__0(tmp0_this._get_index__0() - 1 | 0);
    this._set_last__0(tmp0_this._get_index__0());
    return this._$this_0.get_44(this._get_last__2());
  };
  ListIteratorImpl.$metadata$ = {
    simpleName: 'ListIteratorImpl',
    kind: 'class',
    interfaces: [MutableListIterator]
  };
  function _no_name_provided__4($elements) {
    this._$elements = $elements;
  }
  _no_name_provided__4.prototype.invoke_5 = function (it) {
    return this._$elements.contains_31(it);
  };
  _no_name_provided__4.prototype.invoke_1082 = function (p1) {
    return this.invoke_5((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__4.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMutableList() {
    AbstractMutableCollection.call(this);
    this._modCount = 0;
  }
  AbstractMutableList.prototype._set_modCount__0 = function (_set___) {
    this._modCount = _set___;
  };
  AbstractMutableList.prototype._get_modCount__0 = function () {
    return this._modCount;
  };
  AbstractMutableList.prototype.add_57 = function (element) {
    this.checkIsMutable_13();
    this.add_20(this._get_size__43(), element);
    return true;
  };
  AbstractMutableList.prototype.clear_27 = function () {
    this.checkIsMutable_13();
    this.removeRange_1(0, this._get_size__43());
  };
  AbstractMutableList.prototype.removeAll_2 = function (elements) {
    this.checkIsMutable_13();
    return removeAll(this, _no_name_provided_$factory_2(elements));
  };
  AbstractMutableList.prototype.iterator_38 = function () {
    return new IteratorImpl(this);
  };
  AbstractMutableList.prototype.contains_31 = function (element) {
    return this.indexOf_4(element) >= 0;
  };
  AbstractMutableList.prototype.indexOf_4 = function (element) {
    var inductionVariable = 0;
    var last_1 = _get_lastIndex__0(this);
    if (inductionVariable <= last_1)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals_0(this.get_44(index), element)) {
          return index;
        }}
       while (!(index === last_1));
    return -1;
  };
  AbstractMutableList.prototype.listIterator_3 = function (index) {
    return new ListIteratorImpl(this, index);
  };
  AbstractMutableList.prototype.removeRange_1 = function (fromIndex, toIndex) {
    var iterator_0 = this.listIterator_3(fromIndex);
    var tmp0_repeat_0 = toIndex - fromIndex | 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_repeat_0)
      do {
        var index_2 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        iterator_0.next_13();
        Unit_getInstance();
        iterator_0.remove_23();
      }
       while (inductionVariable < tmp0_repeat_0);
  };
  AbstractMutableList.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, List) : false))
      return false;
    else {
    }
    return Companion_getInstance().orderedEquals(this, other);
  };
  AbstractMutableList.prototype.hashCode = function () {
    return Companion_getInstance().orderedHashCode(this);
  };
  AbstractMutableList.$metadata$ = {
    simpleName: 'AbstractMutableList',
    kind: 'class',
    interfaces: [MutableList]
  };
  function _no_name_provided_$factory_2($elements) {
    var i = new _no_name_provided__4($elements);
    return function (p1) {
      return i.invoke_5(p1);
    };
  }
  function _no_name_provided__5($entryIterator) {
    this._$entryIterator = $entryIterator;
  }
  _no_name_provided__5.prototype.hasNext_11 = function () {
    return this._$entryIterator.hasNext_11();
  };
  _no_name_provided__5.prototype.next_13 = function () {
    return this._$entryIterator.next_13()._get_key__2();
  };
  _no_name_provided__5.prototype.remove_23 = function () {
    return this._$entryIterator.remove_23();
  };
  _no_name_provided__5.$metadata$ = {
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function _no_name_provided__6($entryIterator) {
    this._$entryIterator_0 = $entryIterator;
  }
  _no_name_provided__6.prototype.hasNext_11 = function () {
    return this._$entryIterator_0.hasNext_11();
  };
  _no_name_provided__6.prototype.next_13 = function () {
    return this._$entryIterator_0.next_13()._get_value__18();
  };
  _no_name_provided__6.prototype.remove_23 = function () {
    return this._$entryIterator_0.remove_23();
  };
  _no_name_provided__6.$metadata$ = {
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function SimpleEntry(key, value) {
    this._key = key;
    this.__value_0 = value;
  }
  SimpleEntry.prototype._get_key__2 = function () {
    return this._key;
  };
  SimpleEntry.prototype._get_value__18 = function () {
    return this.__value_0;
  };
  SimpleEntry.prototype.setValue_4 = function (newValue) {
    var oldValue = this.__value_0;
    this.__value_0 = newValue;
    return oldValue;
  };
  SimpleEntry.prototype.hashCode = function () {
    return Companion_getInstance_0().entryHashCode(this);
  };
  SimpleEntry.prototype.toString = function () {
    return Companion_getInstance_0().entryToString(this);
  };
  SimpleEntry.prototype.equals = function (other) {
    return Companion_getInstance_0().entryEquals(this, other);
  };
  SimpleEntry.$metadata$ = {
    simpleName: 'SimpleEntry',
    kind: 'class',
    interfaces: [MutableEntry]
  };
  function AbstractEntrySet() {
    AbstractMutableSet.call(this);
  }
  AbstractEntrySet.prototype.contains_31 = function (element) {
    return this.containsEntry_4(element);
  };
  AbstractEntrySet.$metadata$ = {
    simpleName: 'AbstractEntrySet',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__7(this$0) {
    this._this$0_2 = this$0;
    AbstractMutableSet.call(this);
  }
  _no_name_provided__7.prototype.add_6 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on keys');
  };
  _no_name_provided__7.prototype.add_57 = function (element) {
    return this.add_6((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__7.prototype.clear_27 = function () {
    this._this$0_2.clear_27();
  };
  _no_name_provided__7.prototype.contains_24 = function (element) {
    return this._this$0_2.containsKey_6(element);
  };
  _no_name_provided__7.prototype.contains_31 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_24((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__7.prototype.iterator_38 = function () {
    var entryIterator = this._this$0_2._get_entries__5().iterator_38();
    return new _no_name_provided__5(entryIterator);
  };
  _no_name_provided__7.prototype.remove_58 = function (element) {
    this.checkIsMutable_13();
    if (this._this$0_2.containsKey_6(element)) {
      this._this$0_2.remove_58(element);
      Unit_getInstance();
      return true;
    }return false;
  };
  _no_name_provided__7.prototype.remove_59 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.remove_58((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__7.prototype._get_size__43 = function () {
    return this._this$0_2._get_size__43();
  };
  _no_name_provided__7.prototype.checkIsMutable_13 = function () {
    return this._this$0_2.checkIsMutable_13();
  };
  _no_name_provided__7.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__8(this$0) {
    this._this$0_3 = this$0;
    AbstractMutableCollection.call(this);
  }
  _no_name_provided__8.prototype.add_8 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on values');
  };
  _no_name_provided__8.prototype.add_57 = function (element) {
    return this.add_8((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__8.prototype.contains_17 = function (element) {
    return this._this$0_3.containsValue_2(element);
  };
  _no_name_provided__8.prototype.contains_31 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_17((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__8.prototype.iterator_38 = function () {
    var entryIterator = this._this$0_3._get_entries__5().iterator_38();
    return new _no_name_provided__6(entryIterator);
  };
  _no_name_provided__8.prototype._get_size__43 = function () {
    return this._this$0_3._get_size__43();
  };
  _no_name_provided__8.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(!(other == null) ? isInterface(other, Collection) : false))
      return false;
    else {
    }
    return Companion_getInstance().orderedEquals(this, other);
  };
  _no_name_provided__8.prototype.hashCode = function () {
    return Companion_getInstance().orderedHashCode(this);
  };
  _no_name_provided__8.prototype.checkIsMutable_13 = function () {
    return this._this$0_3.checkIsMutable_13();
  };
  _no_name_provided__8.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMutableMap() {
    AbstractMap.call(this);
    this.__keys_0 = null;
    this.__values_0 = null;
  }
  AbstractMutableMap.prototype.clear_27 = function () {
    this._get_entries__5().clear_27();
  };
  AbstractMutableMap.prototype._get_keys__1 = function () {
    if (this.__keys_0 == null) {
      var tmp = this;
      tmp.__keys_0 = new _no_name_provided__7(this);
    }return ensureNotNull(this.__keys_0);
  };
  AbstractMutableMap.prototype.putAll_3 = function (from) {
    this.checkIsMutable_13();
    var tmp0_iterator = from._get_entries__5().iterator_38();
    while (tmp0_iterator.hasNext_11()) {
      var tmp1_loop_parameter = tmp0_iterator.next_13();
      var key = tmp1_loop_parameter._get_key__2();
      var value = tmp1_loop_parameter._get_value__18();
      this.put_6(key, value);
      Unit_getInstance();
    }
  };
  AbstractMutableMap.prototype._get_values__3 = function () {
    if (this.__values_0 == null) {
      var tmp = this;
      tmp.__values_0 = new _no_name_provided__8(this);
    }return ensureNotNull(this.__values_0);
  };
  AbstractMutableMap.prototype.remove_58 = function (key) {
    this.checkIsMutable_13();
    var iter = this._get_entries__5().iterator_38();
    while (iter.hasNext_11()) {
      var entry = iter.next_13();
      var k = entry._get_key__2();
      if (equals_0(key, k)) {
        var value = entry._get_value__18();
        iter.remove_23();
        return value;
      }}
    return null;
  };
  AbstractMutableMap.prototype.checkIsMutable_13 = function () {
  };
  AbstractMutableMap.$metadata$ = {
    simpleName: 'AbstractMutableMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function AbstractMutableSet() {
    AbstractMutableCollection.call(this);
  }
  AbstractMutableSet.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Set) : false))
      return false;
    else {
    }
    return Companion_getInstance_1().setEquals(this, other);
  };
  AbstractMutableSet.prototype.hashCode = function () {
    return Companion_getInstance_1().unorderedHashCode(this);
  };
  AbstractMutableSet.$metadata$ = {
    simpleName: 'AbstractMutableSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function ArrayList_init_$Init$($this) {
    ArrayList.call($this, []);
    return $this;
  }
  function ArrayList_init_$Create$() {
    return ArrayList_init_$Init$(Object.create(ArrayList.prototype));
  }
  function ArrayList_init_$Init$_0(initialCapacity, $this) {
    ArrayList.call($this, []);
    return $this;
  }
  function ArrayList_init_$Create$_0(initialCapacity) {
    return ArrayList_init_$Init$_0(initialCapacity, Object.create(ArrayList.prototype));
  }
  function ArrayList_init_$Init$_1(elements, $this) {
    ArrayList.call($this, copyToArray_0(elements));
    return $this;
  }
  function ArrayList_init_$Create$_1(elements) {
    return ArrayList_init_$Init$_1(elements, Object.create(ArrayList.prototype));
  }
  function rangeCheck($this, index) {
    Companion_getInstance().checkElementIndex(index, $this._get_size__43());
    return index;
  }
  function insertionRangeCheck($this, index) {
    Companion_getInstance().checkPositionIndex(index, $this._get_size__43());
    return index;
  }
  function ArrayList(array) {
    AbstractMutableList.call(this);
    this._array = array;
    this._isReadOnly = false;
  }
  ArrayList.prototype._get_size__43 = function () {
    return this._array.length;
  };
  ArrayList.prototype.get_44 = function (index) {
    var tmp = this._array[rangeCheck(this, index)];
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  ArrayList.prototype.set_3 = function (index, element) {
    this.checkIsMutable_13();
    rangeCheck(this, index);
    Unit_getInstance();
    var tmp0_apply_0 = this._array[index];
    this._array[index] = element;
    var tmp = tmp0_apply_0;
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  ArrayList.prototype.add_57 = function (element) {
    this.checkIsMutable_13();
    var tmp0_asDynamic_0 = this._array;
    tmp0_asDynamic_0.push(element);
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
    return true;
  };
  ArrayList.prototype.add_20 = function (index, element) {
    this.checkIsMutable_13();
    var tmp0_asDynamic_0 = this._array;
    tmp0_asDynamic_0.splice(insertionRangeCheck(this, index), 0, element);
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
  };
  ArrayList.prototype.addAll_43 = function (elements) {
    this.checkIsMutable_13();
    if (elements.isEmpty_33())
      return false;
    var tmp0_this = this;
    var tmp = tmp0_this;
    var tmp0_plus_0 = tmp0_this._array;
    var tmp1_plus_0 = copyToArray_0(elements);
    tmp._array = tmp0_plus_0.concat(tmp1_plus_0);
    var tmp1_this = this;
    var tmp2 = tmp1_this._get_modCount__0();
    tmp1_this._set_modCount__0(tmp2 + 1 | 0);
    Unit_getInstance();
    return true;
  };
  ArrayList.prototype.removeAt_2 = function (index) {
    this.checkIsMutable_13();
    rangeCheck(this, index);
    Unit_getInstance();
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
    var tmp;
    if (index === _get_lastIndex__0(this)) {
      var tmp0_asDynamic_0 = this._array;
      tmp = tmp0_asDynamic_0.pop();
    } else {
      var tmp1_asDynamic_0 = this._array;
      tmp = tmp1_asDynamic_0.splice(index, 1)[0];
    }
    return tmp;
  };
  ArrayList.prototype.remove_59 = function (element) {
    this.checkIsMutable_13();
    var inductionVariable = 0;
    var last_1 = this._array.length - 1 | 0;
    if (inductionVariable <= last_1)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals_0(this._array[index], element)) {
          var tmp0_asDynamic_0 = this._array;
          tmp0_asDynamic_0.splice(index, 1);
          var tmp1_this = this;
          var tmp2 = tmp1_this._get_modCount__0();
          tmp1_this._set_modCount__0(tmp2 + 1 | 0);
          Unit_getInstance();
          return true;
        }}
       while (inductionVariable <= last_1);
    return false;
  };
  ArrayList.prototype.removeRange_1 = function (fromIndex, toIndex) {
    this.checkIsMutable_13();
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
    var tmp0_asDynamic_0 = this._array;
    tmp0_asDynamic_0.splice(fromIndex, toIndex - fromIndex | 0);
  };
  ArrayList.prototype.clear_27 = function () {
    this.checkIsMutable_13();
    var tmp = this;
    tmp._array = [];
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
  };
  ArrayList.prototype.indexOf_4 = function (element) {
    return indexOf(this._array, element);
  };
  ArrayList.prototype.toString = function () {
    return arrayToString(this._array);
  };
  ArrayList.prototype.toArray_0 = function () {
    return [].slice.call(this._array);
  };
  ArrayList.prototype.toArray = function () {
    return this.toArray_0();
  };
  ArrayList.prototype.checkIsMutable_13 = function () {
    if (this._isReadOnly)
      throw UnsupportedOperationException_init_$Create$();
  };
  ArrayList.$metadata$ = {
    simpleName: 'ArrayList',
    kind: 'class',
    interfaces: [MutableList, RandomAccess]
  };
  var _stableSortingIsSupported;
  function sortArrayWith(array, comparator) {
    if (getStableSortingIsSupported()) {
      var comparison = _no_name_provided_$factory_3(comparator);
      array.sort(comparison);
    } else {
      mergeSort(array, 0, _get_lastIndex_(array), comparator);
    }
  }
  function getStableSortingIsSupported() {
    var tmp0_safe_receiver = _stableSortingIsSupported;
    if (tmp0_safe_receiver == null)
      null;
    else {
      return tmp0_safe_receiver;
    }
    Unit_getInstance();
    _stableSortingIsSupported = false;
    var tmp0_unsafeCast_0 = [];
    var array = tmp0_unsafeCast_0;
    var inductionVariable = 0;
    if (inductionVariable < 600)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        array.push(index);
      }
       while (inductionVariable < 600);
    var comparison = _no_name_provided_$factory_4();
    array.sort(comparison);
    var inductionVariable_0 = 1;
    var last_1 = array.length;
    if (inductionVariable_0 < last_1)
      do {
        var index_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var a = array[index_0 - 1 | 0];
        var b = array[index_0];
        if ((a & 3) === (b & 3) ? a >= b : false)
          return false;
      }
       while (inductionVariable_0 < last_1);
    _stableSortingIsSupported = true;
    return true;
  }
  function mergeSort(array, start, endInclusive, comparator) {
    var tmp0_arrayOfNulls_0 = array.length;
    var tmp1_unsafeCast_0 = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
    var buffer = tmp1_unsafeCast_0;
    var result = mergeSort_0(array, buffer, start, endInclusive, comparator);
    if (!(result === array)) {
      var inductionVariable = start;
      if (inductionVariable <= endInclusive)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          array[i] = result[i];
        }
         while (!(i === endInclusive));
    }}
  function mergeSort_0(array, buffer, start, end, comparator) {
    if (start === end) {
      return array;
    }var median = (start + end | 0) / 2 | 0;
    var left = mergeSort_0(array, buffer, start, median, comparator);
    var right = mergeSort_0(array, buffer, median + 1 | 0, end, comparator);
    var target = left === buffer ? array : buffer;
    var leftIndex = start;
    var rightIndex = median + 1 | 0;
    var inductionVariable = start;
    if (inductionVariable <= end)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (leftIndex <= median ? rightIndex <= end : false) {
          var leftValue = left[leftIndex];
          var rightValue = right[rightIndex];
          if (comparator.compare(leftValue, rightValue) <= 0) {
            target[i] = leftValue;
            var tmp1 = leftIndex;
            leftIndex = tmp1 + 1 | 0;
            Unit_getInstance();
          } else {
            target[i] = rightValue;
            var tmp2 = rightIndex;
            rightIndex = tmp2 + 1 | 0;
            Unit_getInstance();
          }
        } else if (leftIndex <= median) {
          target[i] = left[leftIndex];
          var tmp3 = leftIndex;
          leftIndex = tmp3 + 1 | 0;
          Unit_getInstance();
        } else {
          target[i] = right[rightIndex];
          var tmp4 = rightIndex;
          rightIndex = tmp4 + 1 | 0;
          Unit_getInstance();
          Unit_getInstance();
        }
      }
       while (!(i === end));
    return target;
  }
  function _no_name_provided__9($comparator) {
    this._$comparator = $comparator;
  }
  _no_name_provided__9.prototype.invoke_7 = function (a, b) {
    return this._$comparator.compare(a, b);
  };
  _no_name_provided__9.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE();
    return this.invoke_7(tmp, (p2 == null ? true : isObject(p2)) ? p2 : THROW_CCE());
  };
  _no_name_provided__9.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__10() {
  }
  _no_name_provided__10.prototype.invoke_9 = function (a, b) {
    return (a & 3) - (b & 3) | 0;
  };
  _no_name_provided__10.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE();
    return this.invoke_9(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__10.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_3($comparator) {
    var i = new _no_name_provided__9($comparator);
    return function (p1, p2) {
      return i.invoke_7(p1, p2);
    };
  }
  function _no_name_provided_$factory_4() {
    var i = new _no_name_provided__10();
    return function (p1, p2) {
      return i.invoke_9(p1, p2);
    };
  }
  function HashCode() {
    HashCode_instance = this;
  }
  HashCode.prototype.equals_1 = function (value1, value2) {
    return equals_0(value1, value2);
  };
  HashCode.prototype.getHashCode_0 = function (value) {
    var tmp0_safe_receiver = value;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  HashCode.$metadata$ = {
    simpleName: 'HashCode',
    kind: 'object',
    interfaces: [EqualityComparator]
  };
  var HashCode_instance;
  function HashCode_getInstance() {
    if (HashCode_instance == null)
      new HashCode();
    return HashCode_instance;
  }
  function EqualityComparator() {
  }
  EqualityComparator.$metadata$ = {
    simpleName: 'EqualityComparator',
    kind: 'interface',
    interfaces: []
  };
  function EntrySet($outer) {
    this._$this_1 = $outer;
    AbstractEntrySet.call(this);
  }
  EntrySet.prototype.add_16 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on entries');
  };
  EntrySet.prototype.add_57 = function (element) {
    return this.add_16((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet.prototype.clear_27 = function () {
    this._$this_1.clear_27();
  };
  EntrySet.prototype.containsEntry_4 = function (element) {
    return this._$this_1.containsEntry_5(element);
  };
  EntrySet.prototype.iterator_38 = function () {
    return this._$this_1._internalMap.iterator_38();
  };
  EntrySet.prototype.remove_24 = function (element) {
    if (this.contains_31(element)) {
      this._$this_1.remove_58(element._get_key__2());
      Unit_getInstance();
      return true;
    }return false;
  };
  EntrySet.prototype.remove_59 = function (element) {
    if (!(!(element == null) ? isInterface(element, MutableEntry) : false))
      return false;
    else {
    }
    return this.remove_24((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet.prototype._get_size__43 = function () {
    return this._$this_1._get_size__43();
  };
  EntrySet.$metadata$ = {
    simpleName: 'EntrySet',
    kind: 'class',
    interfaces: []
  };
  function HashMap_init_$Init$(internalMap, $this) {
    AbstractMutableMap.call($this);
    HashMap.call($this);
    $this._internalMap = internalMap;
    $this._equality = internalMap._get_equality__0();
    return $this;
  }
  function HashMap_init_$Init$_0($this) {
    HashMap_init_$Init$(new InternalHashCodeMap(HashCode_getInstance()), $this);
    return $this;
  }
  function HashMap_init_$Create$() {
    return HashMap_init_$Init$_0(Object.create(HashMap.prototype));
  }
  function HashMap_init_$Init$_1(initialCapacity, loadFactor, $this) {
    HashMap_init_$Init$_0($this);
    var tmp0_require_0 = initialCapacity >= 0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Negative initial capacity: ' + initialCapacity;
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp1_require_0 = loadFactor >= 0;
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'Non-positive load factor: ' + loadFactor;
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
    }return $this;
  }
  function HashMap_init_$Create$_0(initialCapacity, loadFactor) {
    return HashMap_init_$Init$_1(initialCapacity, loadFactor, Object.create(HashMap.prototype));
  }
  function HashMap_init_$Init$_2(initialCapacity, $this) {
    HashMap_init_$Init$_1(initialCapacity, 0.0, $this);
    return $this;
  }
  function HashMap_init_$Create$_1(initialCapacity) {
    return HashMap_init_$Init$_2(initialCapacity, Object.create(HashMap.prototype));
  }
  HashMap.prototype.clear_27 = function () {
    this._internalMap.clear_27();
  };
  HashMap.prototype.containsKey_6 = function (key) {
    return this._internalMap.contains_24(key);
  };
  HashMap.prototype.containsValue_2 = function (value) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_any_0 = this._internalMap;
      var tmp;
      if (isInterface(tmp0_any_0, Collection)) {
        tmp = tmp0_any_0.isEmpty_33();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_any_0.iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        if (this._equality.equals_1(element_2._get_value__18(), value)) {
          tmp$ret$0 = true;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = false;
    }
     while (false);
    return tmp$ret$0;
  };
  HashMap.prototype._get_entries__5 = function () {
    if (this.__entries == null) {
      this.__entries = this.createEntrySet_0();
    }return ensureNotNull(this.__entries);
  };
  HashMap.prototype.createEntrySet_0 = function () {
    return new EntrySet(this);
  };
  HashMap.prototype.get_20 = function (key) {
    return this._internalMap.get_20(key);
  };
  HashMap.prototype.put_6 = function (key, value) {
    return this._internalMap.put_6(key, value);
  };
  HashMap.prototype.remove_58 = function (key) {
    return this._internalMap.remove_58(key);
  };
  HashMap.prototype._get_size__43 = function () {
    return this._internalMap._get_size__43();
  };
  function HashMap() {
    this.__entries = null;
  }
  HashMap.$metadata$ = {
    simpleName: 'HashMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function HashSet_init_$Init$(initialCapacity, loadFactor, $this) {
    AbstractMutableSet.call($this);
    HashSet.call($this);
    $this._map = HashMap_init_$Create$_0(initialCapacity, loadFactor);
    return $this;
  }
  function HashSet_init_$Init$_0(initialCapacity, $this) {
    HashSet_init_$Init$(initialCapacity, 0.0, $this);
    return $this;
  }
  function HashSet_init_$Create$(initialCapacity) {
    return HashSet_init_$Init$_0(initialCapacity, Object.create(HashSet.prototype));
  }
  function HashSet_init_$Init$_1(map, $this) {
    AbstractMutableSet.call($this);
    HashSet.call($this);
    $this._map = map;
    return $this;
  }
  HashSet.prototype._get_map__0 = function () {
    return this._map;
  };
  HashSet.prototype.add_57 = function (element) {
    var old = this._map.put_6(element, this);
    return old == null;
  };
  HashSet.prototype.clear_27 = function () {
    this._map.clear_27();
  };
  HashSet.prototype.contains_31 = function (element) {
    return this._map.containsKey_6(element);
  };
  HashSet.prototype.isEmpty_33 = function () {
    return this._map.isEmpty_33();
  };
  HashSet.prototype.iterator_38 = function () {
    return this._map._get_keys__1().iterator_38();
  };
  HashSet.prototype.remove_59 = function (element) {
    return !(this._map.remove_58(element) == null);
  };
  HashSet.prototype._get_size__43 = function () {
    return this._map._get_size__43();
  };
  function HashSet() {
  }
  HashSet.$metadata$ = {
    simpleName: 'HashSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function computeNext($this) {
    if ($this._chainOrEntry != null ? $this._isChain : false) {
      var tmp0_unsafeCast_0 = $this._chainOrEntry;
      var chainSize = tmp0_unsafeCast_0.length;
      var tmp0_this = $this;
      tmp0_this._itemIndex = tmp0_this._itemIndex + 1 | 0;
      if (tmp0_this._itemIndex < chainSize)
        return 0;
      else {
      }
    }var tmp1_this = $this;
    tmp1_this._keyIndex = tmp1_this._keyIndex + 1 | 0;
    if (tmp1_this._keyIndex < $this._keys.length) {
      $this._chainOrEntry = $this._this$0_4._backingMap[$this._keys[$this._keyIndex]];
      var tmp = $this;
      var tmp_0 = $this._chainOrEntry;
      tmp._isChain = !(tmp_0 == null) ? isArray(tmp_0) : false;
      $this._itemIndex = 0;
      return 0;
    } else {
      {
        $this._chainOrEntry = null;
        return 1;
      }
    }
  }
  function getEntry($this, key) {
    var tmp0_elvis_lhs = getChainOrEntryOrNull($this, $this._equality_0.getHashCode_0(key));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var chainOrEntry = tmp;
    if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
      var entry = chainOrEntry;
      if ($this._equality_0.equals_1(entry._get_key__2(), key)) {
        return entry;
      } else {
        return null;
      }
    } else {
      {
        var chain = chainOrEntry;
        return findEntryInChain(chain, $this, key);
      }
    }
  }
  function findEntryInChain(_this_, $this, key) {
    var tmp$ret$0;
    l$ret$1: do {
      var indexedObject = _this_;
      var inductionVariable = 0;
      var last_1 = indexedObject.length;
      while (inductionVariable < last_1) {
        var element_2 = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if ($this._equality_0.equals_1(element_2._get_key__2(), key)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    return tmp$ret$0;
  }
  function getChainOrEntryOrNull($this, hashCode_1) {
    var chainOrEntry = $this._backingMap[hashCode_1];
    return chainOrEntry === undefined ? null : chainOrEntry;
  }
  function _no_name_provided__11(this$0) {
    this._this$0_4 = this$0;
    this._state = -1;
    this._keys = Object.keys(this._this$0_4._backingMap);
    this._keyIndex = -1;
    this._chainOrEntry = null;
    this._isChain = false;
    this._itemIndex = -1;
    this._lastEntry = null;
  }
  _no_name_provided__11.prototype.hasNext_11 = function () {
    if (this._state === -1)
      this._state = computeNext(this);
    return this._state === 0;
  };
  _no_name_provided__11.prototype.next_13 = function () {
    if (!this.hasNext_11())
      throw NoSuchElementException_init_$Create$();
    var tmp;
    if (this._isChain) {
      var tmp0_unsafeCast_0 = this._chainOrEntry;
      tmp = tmp0_unsafeCast_0[this._itemIndex];
    } else {
      var tmp1_unsafeCast_0 = this._chainOrEntry;
      tmp = tmp1_unsafeCast_0;
    }
    var lastEntry = tmp;
    this._lastEntry = lastEntry;
    this._state = -1;
    return lastEntry;
  };
  _no_name_provided__11.prototype.remove_23 = function () {
    var tmp0_checkNotNull_0 = this._lastEntry;
    var tmp$ret$0;
    l$ret$1: do {
      if (tmp0_checkNotNull_0 == null) {
        var message_2_1 = 'Required value was null.';
        throw IllegalStateException_init_$Create$_0(toString_2(message_2_1));
      } else {
        tmp$ret$0 = tmp0_checkNotNull_0;
        break l$ret$1;
      }
    }
     while (false);
    Unit_getInstance();
    this._this$0_4.remove_58(ensureNotNull(this._lastEntry)._get_key__2());
    Unit_getInstance();
    this._lastEntry = null;
    var tmp0_this = this;
    var tmp1 = tmp0_this._itemIndex;
    tmp0_this._itemIndex = tmp1 - 1 | 0;
    Unit_getInstance();
  };
  _no_name_provided__11.$metadata$ = {
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function InternalHashCodeMap(equality) {
    this._equality_0 = equality;
    this._backingMap = this.createJsMap_0();
    this._size_0 = 0;
  }
  InternalHashCodeMap.prototype._get_equality__0 = function () {
    return this._equality_0;
  };
  InternalHashCodeMap.prototype._get_size__43 = function () {
    return this._size_0;
  };
  InternalHashCodeMap.prototype.put_6 = function (key, value) {
    var hashCode_1 = this._equality_0.getHashCode_0(key);
    var chainOrEntry = getChainOrEntryOrNull(this, hashCode_1);
    if (chainOrEntry == null) {
      this._backingMap[hashCode_1] = new SimpleEntry(key, value);
    } else {
      if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
        var entry = chainOrEntry;
        if (this._equality_0.equals_1(entry._get_key__2(), key)) {
          return entry.setValue_4(value);
        } else {
          var tmp0_arrayOf_0 = [entry, new SimpleEntry(key, value)];
          this._backingMap[hashCode_1] = tmp0_arrayOf_0;
          var tmp0_this = this;
          var tmp1 = tmp0_this._size_0;
          tmp0_this._size_0 = tmp1 + 1 | 0;
          Unit_getInstance();
          return null;
        }
      } else {
        {
          var chain = chainOrEntry;
          var entry_0 = findEntryInChain(chain, this, key);
          if (!(entry_0 == null)) {
            return entry_0.setValue_4(value);
          }chain.push(new SimpleEntry(key, value));
        }
      }
    }
    var tmp2_this = this;
    var tmp3 = tmp2_this._size_0;
    tmp2_this._size_0 = tmp3 + 1 | 0;
    Unit_getInstance();
    return null;
  };
  InternalHashCodeMap.prototype.remove_58 = function (key) {
    var hashCode_1 = this._equality_0.getHashCode_0(key);
    var tmp0_elvis_lhs = getChainOrEntryOrNull(this, hashCode_1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var chainOrEntry = tmp;
    if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
      var entry = chainOrEntry;
      if (this._equality_0.equals_1(entry._get_key__2(), key)) {
        jsDeleteProperty(this._backingMap, hashCode_1);
        var tmp1_this = this;
        var tmp2 = tmp1_this._size_0;
        tmp1_this._size_0 = tmp2 - 1 | 0;
        Unit_getInstance();
        return entry._get_value__18();
      } else {
        return null;
      }
    } else {
      {
        var chain = chainOrEntry;
        var inductionVariable = 0;
        var last_1 = chain.length - 1 | 0;
        if (inductionVariable <= last_1)
          do {
            var index = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var entry_0 = chain[index];
            if (this._equality_0.equals_1(key, entry_0._get_key__2())) {
              if (chain.length === 1) {
                chain.length = 0;
                jsDeleteProperty(this._backingMap, hashCode_1);
              } else {
                chain.splice(index, 1);
              }
              var tmp4_this = this;
              var tmp5 = tmp4_this._size_0;
              tmp4_this._size_0 = tmp5 - 1 | 0;
              Unit_getInstance();
              return entry_0._get_value__18();
            }}
           while (inductionVariable <= last_1);
      }
    }
    return null;
  };
  InternalHashCodeMap.prototype.clear_27 = function () {
    this._backingMap = this.createJsMap_0();
    this._size_0 = 0;
  };
  InternalHashCodeMap.prototype.contains_24 = function (key) {
    return !(getEntry(this, key) == null);
  };
  InternalHashCodeMap.prototype.get_20 = function (key) {
    var tmp0_safe_receiver = getEntry(this, key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__18();
  };
  InternalHashCodeMap.prototype.iterator_38 = function () {
    return new _no_name_provided__11(this);
  };
  InternalHashCodeMap.$metadata$ = {
    simpleName: 'InternalHashCodeMap',
    kind: 'class',
    interfaces: [InternalMap]
  };
  function InternalMap() {
  }
  InternalMap.prototype.createJsMap_0 = function () {
    var result = Object.create(null);
    result['foo'] = 1;
    jsDeleteProperty(result, 'foo');
    return result;
  };
  InternalMap.$metadata$ = {
    simpleName: 'InternalMap',
    kind: 'interface',
    interfaces: [MutableIterable]
  };
  function EntryIterator($outer) {
    this._$this_2 = $outer;
    this._last_1 = null;
    this._next_0 = null;
    this._next_0 = this._$this_2._$this_4._head_0;
  }
  EntryIterator.prototype.hasNext_11 = function () {
    return !(this._next_0 === null);
  };
  EntryIterator.prototype.next_13 = function () {
    if (!this.hasNext_11())
      throw NoSuchElementException_init_$Create$();
    var current = ensureNotNull(this._next_0);
    this._last_1 = current;
    var tmp = this;
    var tmp0_takeIf_0 = current._next_1;
    var tmp_0;
    if (!(tmp0_takeIf_0 === this._$this_2._$this_4._head_0)) {
      tmp_0 = tmp0_takeIf_0;
    } else {
      {
        tmp_0 = null;
      }
    }
    tmp._next_0 = tmp_0;
    return current;
  };
  EntryIterator.prototype.remove_23 = function () {
    var tmp0_check_0 = !(this._last_1 == null);
    if (!tmp0_check_0) {
      var message_2_1 = 'Check failed.';
      throw IllegalStateException_init_$Create$_0(toString_2(message_2_1));
    }this._$this_2.checkIsMutable_13();
    remove(ensureNotNull(this._last_1), this._$this_2._$this_4);
    this._$this_2._$this_4._map_0.remove_58(ensureNotNull(this._last_1)._get_key__2());
    Unit_getInstance();
    this._last_1 = null;
  };
  EntryIterator.$metadata$ = {
    simpleName: 'EntryIterator',
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function ChainEntry($outer, key, value) {
    this._$this_3 = $outer;
    SimpleEntry.call(this, key, value);
    this._next_1 = null;
    this._prev = null;
  }
  ChainEntry.prototype.setValue_4 = function (newValue) {
    this._$this_3.checkIsMutable_13();
    return SimpleEntry.prototype.setValue_4.call(this, newValue);
  };
  ChainEntry.$metadata$ = {
    simpleName: 'ChainEntry',
    kind: 'class',
    interfaces: []
  };
  function EntrySet_0($outer) {
    this._$this_4 = $outer;
    AbstractEntrySet.call(this);
  }
  EntrySet_0.prototype.add_16 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on entries');
  };
  EntrySet_0.prototype.add_57 = function (element) {
    return this.add_16((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet_0.prototype.clear_27 = function () {
    this._$this_4.clear_27();
  };
  EntrySet_0.prototype.containsEntry_4 = function (element) {
    return this._$this_4.containsEntry_5(element);
  };
  EntrySet_0.prototype.iterator_38 = function () {
    return new EntryIterator(this);
  };
  EntrySet_0.prototype.remove_24 = function (element) {
    this.checkIsMutable_13();
    if (this.contains_31(element)) {
      this._$this_4.remove_58(element._get_key__2());
      Unit_getInstance();
      return true;
    }return false;
  };
  EntrySet_0.prototype.remove_59 = function (element) {
    if (!(!(element == null) ? isInterface(element, MutableEntry) : false))
      return false;
    else {
    }
    return this.remove_24((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet_0.prototype._get_size__43 = function () {
    return this._$this_4._get_size__43();
  };
  EntrySet_0.prototype.checkIsMutable_13 = function () {
    return this._$this_4.checkIsMutable_13();
  };
  EntrySet_0.$metadata$ = {
    simpleName: 'EntrySet',
    kind: 'class',
    interfaces: []
  };
  function addToEnd(_this_, $this) {
    var tmp0_check_0 = _this_._next_1 == null ? _this_._prev == null : false;
    if (!tmp0_check_0) {
      var message_2_1 = 'Check failed.';
      throw IllegalStateException_init_$Create$_0(toString_2(message_2_1));
    }var _head = $this._head_0;
    if (_head == null) {
      $this._head_0 = _this_;
      _this_._next_1 = _this_;
      _this_._prev = _this_;
    } else {
      var tmp1_checkNotNull_0 = _head._prev;
      var tmp$ret$0;
      l$ret$1: do {
        if (tmp1_checkNotNull_0 == null) {
          var message_2_1_0 = 'Required value was null.';
          throw IllegalStateException_init_$Create$_0(toString_2(message_2_1_0));
        } else {
          tmp$ret$0 = tmp1_checkNotNull_0;
          break l$ret$1;
        }
      }
       while (false);
      var _tail = tmp$ret$0;
      _this_._prev = _tail;
      _this_._next_1 = _head;
      _head._prev = _this_;
      _tail._next_1 = _this_;
    }
  }
  function remove(_this_, $this) {
    if (_this_._next_1 === _this_) {
      $this._head_0 = null;
    } else {
      if ($this._head_0 === _this_) {
        $this._head_0 = _this_._next_1;
      }ensureNotNull(_this_._next_1)._prev = _this_._prev;
      ensureNotNull(_this_._prev)._next_1 = _this_._next_1;
    }
    _this_._next_1 = null;
    _this_._prev = null;
  }
  function LinkedHashMap_init_$Init$($this) {
    HashMap_init_$Init$_0($this);
    LinkedHashMap.call($this);
    $this._map_0 = HashMap_init_$Create$();
    return $this;
  }
  function LinkedHashMap_init_$Create$() {
    return LinkedHashMap_init_$Init$(Object.create(LinkedHashMap.prototype));
  }
  function LinkedHashMap_init_$Init$_0(initialCapacity, loadFactor, $this) {
    HashMap_init_$Init$_1(initialCapacity, loadFactor, $this);
    LinkedHashMap.call($this);
    $this._map_0 = HashMap_init_$Create$();
    return $this;
  }
  function LinkedHashMap_init_$Create$_0(initialCapacity, loadFactor) {
    return LinkedHashMap_init_$Init$_0(initialCapacity, loadFactor, Object.create(LinkedHashMap.prototype));
  }
  function LinkedHashMap_init_$Init$_1(initialCapacity, $this) {
    LinkedHashMap_init_$Init$_0(initialCapacity, 0.0, $this);
    return $this;
  }
  function LinkedHashMap_init_$Create$_1(initialCapacity) {
    return LinkedHashMap_init_$Init$_1(initialCapacity, Object.create(LinkedHashMap.prototype));
  }
  function LinkedHashMap_init_$Init$_2(original, $this) {
    HashMap_init_$Init$_0($this);
    LinkedHashMap.call($this);
    $this._map_0 = HashMap_init_$Create$();
    $this.putAll_3(original);
    return $this;
  }
  function LinkedHashMap_init_$Create$_2(original) {
    return LinkedHashMap_init_$Init$_2(original, Object.create(LinkedHashMap.prototype));
  }
  LinkedHashMap.prototype.clear_27 = function () {
    this.checkIsMutable_13();
    this._map_0.clear_27();
    this._head_0 = null;
  };
  LinkedHashMap.prototype.containsKey_6 = function (key) {
    return this._map_0.containsKey_6(key);
  };
  LinkedHashMap.prototype.containsValue_2 = function (value) {
    var tmp0_elvis_lhs = this._head_0;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var node = tmp;
    do {
      if (equals_0(node._get_value__18(), value)) {
        return true;
      }node = ensureNotNull(node._next_1);
    }
     while (!(node === this._head_0));
    return false;
  };
  LinkedHashMap.prototype.createEntrySet_0 = function () {
    return new EntrySet_0(this);
  };
  LinkedHashMap.prototype.get_20 = function (key) {
    var tmp0_safe_receiver = this._map_0.get_20(key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__18();
  };
  LinkedHashMap.prototype.put_6 = function (key, value) {
    this.checkIsMutable_13();
    var old = this._map_0.get_20(key);
    if (old == null) {
      var newEntry = new ChainEntry(this, key, value);
      this._map_0.put_6(key, newEntry);
      Unit_getInstance();
      addToEnd(newEntry, this);
      return null;
    } else {
      return old.setValue_4(value);
    }
  };
  LinkedHashMap.prototype.remove_58 = function (key) {
    this.checkIsMutable_13();
    var entry = this._map_0.remove_58(key);
    if (!(entry == null)) {
      remove(entry, this);
      return entry._get_value__18();
    }return null;
  };
  LinkedHashMap.prototype._get_size__43 = function () {
    return this._map_0._get_size__43();
  };
  LinkedHashMap.prototype.checkIsMutable_13 = function () {
    if (this._isReadOnly_0)
      throw UnsupportedOperationException_init_$Create$();
  };
  function LinkedHashMap() {
    this._head_0 = null;
    this._isReadOnly_0 = false;
  }
  LinkedHashMap.$metadata$ = {
    simpleName: 'LinkedHashMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function LinkedHashSet_init_$Init$($this) {
    HashSet_init_$Init$_1(LinkedHashMap_init_$Create$(), $this);
    LinkedHashSet.call($this);
    return $this;
  }
  function LinkedHashSet_init_$Create$() {
    return LinkedHashSet_init_$Init$(Object.create(LinkedHashSet.prototype));
  }
  function LinkedHashSet_init_$Init$_0(elements, $this) {
    HashSet_init_$Init$_1(LinkedHashMap_init_$Create$(), $this);
    LinkedHashSet.call($this);
    $this.addAll_43(elements);
    Unit_getInstance();
    return $this;
  }
  function LinkedHashSet_init_$Create$_0(elements) {
    return LinkedHashSet_init_$Init$_0(elements, Object.create(LinkedHashSet.prototype));
  }
  function LinkedHashSet_init_$Init$_1(initialCapacity, loadFactor, $this) {
    HashSet_init_$Init$_1(LinkedHashMap_init_$Create$_0(initialCapacity, loadFactor), $this);
    LinkedHashSet.call($this);
    return $this;
  }
  function LinkedHashSet_init_$Init$_2(initialCapacity, $this) {
    LinkedHashSet_init_$Init$_1(initialCapacity, 0.0, $this);
    return $this;
  }
  function LinkedHashSet_init_$Create$_1(initialCapacity) {
    return LinkedHashSet_init_$Init$_2(initialCapacity, Object.create(LinkedHashSet.prototype));
  }
  LinkedHashSet.prototype.checkIsMutable_13 = function () {
    return this._get_map__0().checkIsMutable_13();
  };
  function LinkedHashSet() {
  }
  LinkedHashSet.$metadata$ = {
    simpleName: 'LinkedHashSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function RandomAccess() {
  }
  RandomAccess.$metadata$ = {
    simpleName: 'RandomAccess',
    kind: 'interface',
    interfaces: []
  };
  var output;
  function BaseOutput() {
  }
  BaseOutput.prototype.println_5 = function () {
    this.print_3('\n');
  };
  BaseOutput.prototype.println_6 = function (message) {
    this.print_3(message);
    this.println_5();
  };
  BaseOutput.$metadata$ = {
    simpleName: 'BaseOutput',
    kind: 'class',
    interfaces: []
  };
  function NodeJsOutput_0(outputStream) {
    BaseOutput.call(this);
    this._outputStream = outputStream;
  }
  NodeJsOutput_0.prototype.print_3 = function (message) {
    var messageString = String(message);
    this._outputStream.write(messageString);
  };
  NodeJsOutput_0.$metadata$ = {
    simpleName: 'NodeJsOutput',
    kind: 'class',
    interfaces: []
  };
  function BufferedOutputToConsoleLog_0() {
    BufferedOutput_0.call(this);
  }
  BufferedOutputToConsoleLog_0.prototype.print_3 = function (message) {
    var s = String(message);
    var tmp0_nativeLastIndexOf_0 = s;
    var tmp1_nativeLastIndexOf_0 = '\n';
    var tmp2_nativeLastIndexOf_0 = 0;
    var i = tmp0_nativeLastIndexOf_0.lastIndexOf(tmp1_nativeLastIndexOf_0, tmp2_nativeLastIndexOf_0);
    if (i >= 0) {
      var tmp0_this = this;
      var tmp = tmp0_this._get_buffer__0();
      var tmp3_substring_0 = s;
      var tmp4_substring_0 = 0;
      tmp0_this._set_buffer__0(tmp + tmp3_substring_0.substring(tmp4_substring_0, i));
      this.flush();
      var tmp5_substring_0 = s;
      var tmp6_substring_0 = i + 1 | 0;
      s = tmp5_substring_0.substring(tmp6_substring_0);
    }var tmp1_this = this;
    tmp1_this._set_buffer__0(tmp1_this._get_buffer__0() + s);
  };
  BufferedOutputToConsoleLog_0.prototype.flush = function () {
    (function () {
      var $externalVarargReceiverTmp = console;
      return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].concat([this._get_buffer__0()]));
    }.call(this));
    this._set_buffer__0('');
  };
  BufferedOutputToConsoleLog_0.$metadata$ = {
    simpleName: 'BufferedOutputToConsoleLog',
    kind: 'class',
    interfaces: []
  };
  function BufferedOutput_0() {
    BaseOutput.call(this);
    this._buffer = '';
  }
  BufferedOutput_0.prototype._set_buffer__0 = function (_set___) {
    this._buffer = _set___;
  };
  BufferedOutput_0.prototype._get_buffer__0 = function () {
    return this._buffer;
  };
  BufferedOutput_0.prototype.print_3 = function (message) {
    var tmp0_this = this;
    var tmp = tmp0_this;
    var tmp_0 = tmp0_this._buffer;
    tmp._buffer = tmp_0 + String(message);
  };
  BufferedOutput_0.$metadata$ = {
    simpleName: 'BufferedOutput',
    kind: 'class',
    interfaces: []
  };
  function println(message) {
    output.println_6(message);
  }
  function output$init$() {
    var isNode_2 = typeof process !== 'undefined' && process.versions && !!process.versions.node;
    return isNode_2 ? new NodeJsOutput_0(process.stdout) : new BufferedOutputToConsoleLog_0();
  }
  function iterator(_this_) {
    var r = _this_;
    var tmp;
    if (_this_['iterator'] != null) {
      tmp = _this_['iterator']();
    } else if (isArrayish(r)) {
      tmp = arrayIterator(r);
    } else {
      tmp = ((!(r == null) ? isInterface(r, Iterable) : false) ? r : THROW_CCE()).iterator_38();
    }
    return tmp;
  }
  function Serializable() {
  }
  Serializable.$metadata$ = {
    simpleName: 'Serializable',
    kind: 'interface',
    interfaces: []
  };
  function round(x) {
    if (!(x % 0.5 === 0.0)) {
      var tmp0_unsafeCast_0 = Math.round(x);
      return tmp0_unsafeCast_0;
    }var tmp0_unsafeCast_0_1 = Math.floor(x);
    var floor = tmp0_unsafeCast_0_1;
    var tmp;
    if (floor % 2 === 0.0) {
      tmp = floor;
    } else {
      var tmp1_unsafeCast_0 = Math.ceil(x);
      tmp = tmp1_unsafeCast_0;
    }
    return tmp;
  }
  function roundToInt(_this_) {
    var tmp;
    if (isNaN_0(_this_)) {
      throw IllegalArgumentException_init_$Create$('Cannot round NaN value.');
    } else if (_this_ > IntCompanionObject_getInstance()._MAX_VALUE) {
      tmp = IntCompanionObject_getInstance()._MAX_VALUE;
    } else if (_this_ < IntCompanionObject_getInstance()._MIN_VALUE) {
      tmp = IntCompanionObject_getInstance()._MIN_VALUE;
    } else {
      var tmp0_unsafeCast_0 = Math.round(_this_);
      tmp = numberToInt(tmp0_unsafeCast_0);
    }
    return tmp;
  }
  function isNaN_0(_this_) {
    return !(_this_ === _this_);
  }
  function isInfinite(_this_) {
    return _this_ === Infinity ? true : _this_ === -Infinity;
  }
  function _get_js_(_this_) {
    return (_this_ instanceof KClassImpl ? _this_ : THROW_CCE())._get_jClass__2();
  }
  function KCallable() {
  }
  KCallable.$metadata$ = {
    simpleName: 'KCallable',
    kind: 'interface',
    interfaces: []
  };
  function KClass() {
  }
  KClass.$metadata$ = {
    simpleName: 'KClass',
    kind: 'interface',
    interfaces: [KClassifier]
  };
  function KClassImpl(jClass) {
    this._jClass = jClass;
  }
  KClassImpl.prototype._get_jClass__2 = function () {
    return this._jClass;
  };
  KClassImpl.prototype.equals = function (other) {
    var tmp;
    if (other instanceof KClassImpl) {
      tmp = equals_0(this._get_jClass__2(), other._get_jClass__2());
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  KClassImpl.prototype.hashCode = function () {
    var tmp0_safe_receiver = this._get_simpleName__4();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : getStringHashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  KClassImpl.prototype.toString = function () {
    return '' + 'class ' + this._get_simpleName__4();
  };
  KClassImpl.$metadata$ = {
    simpleName: 'KClassImpl',
    kind: 'class',
    interfaces: [KClass]
  };
  function PrimitiveKClassImpl(jClass, givenSimpleName, isInstanceFunction) {
    KClassImpl.call(this, jClass);
    this._givenSimpleName = givenSimpleName;
    this._isInstanceFunction = isInstanceFunction;
  }
  PrimitiveKClassImpl.prototype.equals = function (other) {
    if (!(other instanceof PrimitiveKClassImpl))
      return false;
    else {
    }
    return KClassImpl.prototype.equals.call(this, other) ? this._givenSimpleName === other._givenSimpleName : false;
  };
  PrimitiveKClassImpl.prototype._get_simpleName__4 = function () {
    return this._givenSimpleName;
  };
  PrimitiveKClassImpl.$metadata$ = {
    simpleName: 'PrimitiveKClassImpl',
    kind: 'class',
    interfaces: []
  };
  function NothingKClassImpl() {
    NothingKClassImpl_instance = this;
    KClassImpl.call(this, Object);
    this._simpleName = 'Nothing';
  }
  NothingKClassImpl.prototype._get_simpleName__4 = function () {
    return this._simpleName;
  };
  NothingKClassImpl.prototype._get_jClass__2 = function () {
    throw UnsupportedOperationException_init_$Create$_0("There's no native JS class for Nothing type");
  };
  NothingKClassImpl.prototype.equals = function (other) {
    return other === this;
  };
  NothingKClassImpl.prototype.hashCode = function () {
    return 0;
  };
  NothingKClassImpl.$metadata$ = {
    simpleName: 'NothingKClassImpl',
    kind: 'object',
    interfaces: []
  };
  var NothingKClassImpl_instance;
  function NothingKClassImpl_getInstance() {
    if (NothingKClassImpl_instance == null)
      new NothingKClassImpl();
    return NothingKClassImpl_instance;
  }
  function ErrorKClass() {
  }
  ErrorKClass.prototype._get_simpleName__4 = function () {
    var tmp0_error_0 = 'Unknown simpleName for ErrorKClass';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  };
  ErrorKClass.prototype.equals = function (other) {
    return other === this;
  };
  ErrorKClass.prototype.hashCode = function () {
    return 0;
  };
  ErrorKClass.$metadata$ = {
    simpleName: 'ErrorKClass',
    kind: 'class',
    interfaces: [KClass]
  };
  function SimpleKClassImpl(jClass) {
    KClassImpl.call(this, jClass);
    var tmp = this;
    var tmp0_safe_receiver = jClass.$metadata$;
    var tmp0_unsafeCast_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.simpleName;
    tmp._simpleName_0 = tmp0_unsafeCast_0;
  }
  SimpleKClassImpl.prototype._get_simpleName__4 = function () {
    return this._simpleName_0;
  };
  SimpleKClassImpl.$metadata$ = {
    simpleName: 'SimpleKClassImpl',
    kind: 'class',
    interfaces: []
  };
  function KFunction() {
  }
  KFunction.$metadata$ = {
    simpleName: 'KFunction',
    kind: 'interface',
    interfaces: [KCallable]
  };
  function KProperty1() {
  }
  KProperty1.$metadata$ = {
    simpleName: 'KProperty1',
    kind: 'interface',
    interfaces: [KProperty]
  };
  function KProperty0() {
  }
  KProperty0.$metadata$ = {
    simpleName: 'KProperty0',
    kind: 'interface',
    interfaces: [KProperty]
  };
  function KMutableProperty1() {
  }
  KMutableProperty1.$metadata$ = {
    simpleName: 'KMutableProperty1',
    kind: 'interface',
    interfaces: [KProperty1, KMutableProperty]
  };
  function KProperty() {
  }
  KProperty.$metadata$ = {
    simpleName: 'KProperty',
    kind: 'interface',
    interfaces: [KCallable]
  };
  function KMutableProperty() {
  }
  KMutableProperty.$metadata$ = {
    simpleName: 'KMutableProperty',
    kind: 'interface',
    interfaces: [KProperty]
  };
  function KMutableProperty0() {
  }
  KMutableProperty0.$metadata$ = {
    simpleName: 'KMutableProperty0',
    kind: 'interface',
    interfaces: [KProperty0, KMutableProperty]
  };
  var functionClasses;
  function _no_name_provided__12() {
  }
  _no_name_provided__12.prototype.invoke_51 = function (it) {
    return isObject(it);
  };
  _no_name_provided__12.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__12.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__13() {
  }
  _no_name_provided__13.prototype.invoke_51 = function (it) {
    return isNumber(it);
  };
  _no_name_provided__13.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__13.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__14() {
  }
  _no_name_provided__14.prototype.invoke_51 = function (it) {
    return !(it == null) ? typeof it === 'boolean' : false;
  };
  _no_name_provided__14.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__14.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__15() {
  }
  _no_name_provided__15.prototype.invoke_51 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__15.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__15.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__16() {
  }
  _no_name_provided__16.prototype.invoke_51 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__16.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__16.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__17() {
  }
  _no_name_provided__17.prototype.invoke_51 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__17.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__17.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__18() {
  }
  _no_name_provided__18.prototype.invoke_51 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__18.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__18.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__19() {
  }
  _no_name_provided__19.prototype.invoke_51 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__19.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__19.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__20() {
  }
  _no_name_provided__20.prototype.invoke_51 = function (it) {
    return !(it == null) ? isArray(it) : false;
  };
  _no_name_provided__20.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__20.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__21() {
  }
  _no_name_provided__21.prototype.invoke_51 = function (it) {
    return !(it == null) ? typeof it === 'string' : false;
  };
  _no_name_provided__21.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__21.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__22() {
  }
  _no_name_provided__22.prototype.invoke_51 = function (it) {
    return it instanceof Error;
  };
  _no_name_provided__22.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__22.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__23() {
  }
  _no_name_provided__23.prototype.invoke_51 = function (it) {
    return !(it == null) ? isBooleanArray(it) : false;
  };
  _no_name_provided__23.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__23.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__24() {
  }
  _no_name_provided__24.prototype.invoke_51 = function (it) {
    return !(it == null) ? isCharArray(it) : false;
  };
  _no_name_provided__24.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__24.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__25() {
  }
  _no_name_provided__25.prototype.invoke_51 = function (it) {
    return !(it == null) ? isByteArray(it) : false;
  };
  _no_name_provided__25.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__25.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__26() {
  }
  _no_name_provided__26.prototype.invoke_51 = function (it) {
    return !(it == null) ? isShortArray(it) : false;
  };
  _no_name_provided__26.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__26.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__27() {
  }
  _no_name_provided__27.prototype.invoke_51 = function (it) {
    return !(it == null) ? isIntArray(it) : false;
  };
  _no_name_provided__27.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__27.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__28() {
  }
  _no_name_provided__28.prototype.invoke_51 = function (it) {
    return !(it == null) ? isLongArray(it) : false;
  };
  _no_name_provided__28.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__28.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__29() {
  }
  _no_name_provided__29.prototype.invoke_51 = function (it) {
    return !(it == null) ? isFloatArray(it) : false;
  };
  _no_name_provided__29.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__29.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__30() {
  }
  _no_name_provided__30.prototype.invoke_51 = function (it) {
    return !(it == null) ? isDoubleArray(it) : false;
  };
  _no_name_provided__30.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__30.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__31($arity) {
    this._$arity = $arity;
  }
  _no_name_provided__31.prototype.invoke_51 = function (it) {
    var tmp;
    if (typeof it === 'function') {
      tmp = it.length === this._$arity;
    } else {
      tmp = false;
    }
    return tmp;
  };
  _no_name_provided__31.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__31.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function PrimitiveClasses_0() {
    PrimitiveClasses_instance = this;
    var tmp = this;
    var tmp0_unsafeCast_0 = Object;
    var tmp_0 = tmp0_unsafeCast_0;
    tmp._anyClass = new PrimitiveKClassImpl(tmp_0, 'Any', _no_name_provided_$factory_5());
    var tmp_1 = this;
    var tmp0_unsafeCast_0_0 = Number;
    var tmp_2 = tmp0_unsafeCast_0_0;
    tmp_1._numberClass = new PrimitiveKClassImpl(tmp_2, 'Number', _no_name_provided_$factory_6());
    this._nothingClass = NothingKClassImpl_getInstance();
    var tmp_3 = this;
    var tmp0_unsafeCast_0_1 = Boolean;
    var tmp_4 = tmp0_unsafeCast_0_1;
    tmp_3._booleanClass = new PrimitiveKClassImpl(tmp_4, 'Boolean', _no_name_provided_$factory_7());
    var tmp_5 = this;
    var tmp0_unsafeCast_0_2 = Number;
    var tmp_6 = tmp0_unsafeCast_0_2;
    tmp_5._byteClass = new PrimitiveKClassImpl(tmp_6, 'Byte', _no_name_provided_$factory_8());
    var tmp_7 = this;
    var tmp0_unsafeCast_0_3 = Number;
    var tmp_8 = tmp0_unsafeCast_0_3;
    tmp_7._shortClass = new PrimitiveKClassImpl(tmp_8, 'Short', _no_name_provided_$factory_9());
    var tmp_9 = this;
    var tmp0_unsafeCast_0_4 = Number;
    var tmp_10 = tmp0_unsafeCast_0_4;
    tmp_9._intClass = new PrimitiveKClassImpl(tmp_10, 'Int', _no_name_provided_$factory_10());
    var tmp_11 = this;
    var tmp0_unsafeCast_0_5 = Number;
    var tmp_12 = tmp0_unsafeCast_0_5;
    tmp_11._floatClass = new PrimitiveKClassImpl(tmp_12, 'Float', _no_name_provided_$factory_11());
    var tmp_13 = this;
    var tmp0_unsafeCast_0_6 = Number;
    var tmp_14 = tmp0_unsafeCast_0_6;
    tmp_13._doubleClass = new PrimitiveKClassImpl(tmp_14, 'Double', _no_name_provided_$factory_12());
    var tmp_15 = this;
    var tmp0_unsafeCast_0_7 = Array;
    var tmp_16 = tmp0_unsafeCast_0_7;
    tmp_15._arrayClass = new PrimitiveKClassImpl(tmp_16, 'Array', _no_name_provided_$factory_13());
    var tmp_17 = this;
    var tmp0_unsafeCast_0_8 = String;
    var tmp_18 = tmp0_unsafeCast_0_8;
    tmp_17._stringClass = new PrimitiveKClassImpl(tmp_18, 'String', _no_name_provided_$factory_14());
    var tmp_19 = this;
    var tmp0_unsafeCast_0_9 = Error;
    var tmp_20 = tmp0_unsafeCast_0_9;
    tmp_19._throwableClass = new PrimitiveKClassImpl(tmp_20, 'Throwable', _no_name_provided_$factory_15());
    var tmp_21 = this;
    var tmp0_unsafeCast_0_10 = Array;
    var tmp_22 = tmp0_unsafeCast_0_10;
    tmp_21._booleanArrayClass = new PrimitiveKClassImpl(tmp_22, 'BooleanArray', _no_name_provided_$factory_16());
    var tmp_23 = this;
    var tmp0_unsafeCast_0_11 = Uint16Array;
    var tmp_24 = tmp0_unsafeCast_0_11;
    tmp_23._charArrayClass = new PrimitiveKClassImpl(tmp_24, 'CharArray', _no_name_provided_$factory_17());
    var tmp_25 = this;
    var tmp0_unsafeCast_0_12 = Int8Array;
    var tmp_26 = tmp0_unsafeCast_0_12;
    tmp_25._byteArrayClass = new PrimitiveKClassImpl(tmp_26, 'ByteArray', _no_name_provided_$factory_18());
    var tmp_27 = this;
    var tmp0_unsafeCast_0_13 = Int16Array;
    var tmp_28 = tmp0_unsafeCast_0_13;
    tmp_27._shortArrayClass = new PrimitiveKClassImpl(tmp_28, 'ShortArray', _no_name_provided_$factory_19());
    var tmp_29 = this;
    var tmp0_unsafeCast_0_14 = Int32Array;
    var tmp_30 = tmp0_unsafeCast_0_14;
    tmp_29._intArrayClass = new PrimitiveKClassImpl(tmp_30, 'IntArray', _no_name_provided_$factory_20());
    var tmp_31 = this;
    var tmp0_unsafeCast_0_15 = Array;
    var tmp_32 = tmp0_unsafeCast_0_15;
    tmp_31._longArrayClass = new PrimitiveKClassImpl(tmp_32, 'LongArray', _no_name_provided_$factory_21());
    var tmp_33 = this;
    var tmp0_unsafeCast_0_16 = Float32Array;
    var tmp_34 = tmp0_unsafeCast_0_16;
    tmp_33._floatArrayClass = new PrimitiveKClassImpl(tmp_34, 'FloatArray', _no_name_provided_$factory_22());
    var tmp_35 = this;
    var tmp0_unsafeCast_0_17 = Float64Array;
    var tmp_36 = tmp0_unsafeCast_0_17;
    tmp_35._doubleArrayClass = new PrimitiveKClassImpl(tmp_36, 'DoubleArray', _no_name_provided_$factory_23());
  }
  PrimitiveClasses_0.prototype._get_anyClass_ = function () {
    return this._anyClass;
  };
  PrimitiveClasses_0.prototype._get_numberClass_ = function () {
    return this._numberClass;
  };
  PrimitiveClasses_0.prototype._get_nothingClass_ = function () {
    return this._nothingClass;
  };
  PrimitiveClasses_0.prototype._get_booleanClass_ = function () {
    return this._booleanClass;
  };
  PrimitiveClasses_0.prototype._get_byteClass_ = function () {
    return this._byteClass;
  };
  PrimitiveClasses_0.prototype._get_shortClass_ = function () {
    return this._shortClass;
  };
  PrimitiveClasses_0.prototype._get_intClass_ = function () {
    return this._intClass;
  };
  PrimitiveClasses_0.prototype._get_floatClass_ = function () {
    return this._floatClass;
  };
  PrimitiveClasses_0.prototype._get_doubleClass_ = function () {
    return this._doubleClass;
  };
  PrimitiveClasses_0.prototype._get_arrayClass_ = function () {
    return this._arrayClass;
  };
  PrimitiveClasses_0.prototype._get_stringClass_ = function () {
    return this._stringClass;
  };
  PrimitiveClasses_0.prototype._get_throwableClass_ = function () {
    return this._throwableClass;
  };
  PrimitiveClasses_0.prototype._get_booleanArrayClass_ = function () {
    return this._booleanArrayClass;
  };
  PrimitiveClasses_0.prototype._get_charArrayClass_ = function () {
    return this._charArrayClass;
  };
  PrimitiveClasses_0.prototype._get_byteArrayClass_ = function () {
    return this._byteArrayClass;
  };
  PrimitiveClasses_0.prototype._get_shortArrayClass_ = function () {
    return this._shortArrayClass;
  };
  PrimitiveClasses_0.prototype._get_intArrayClass_ = function () {
    return this._intArrayClass;
  };
  PrimitiveClasses_0.prototype._get_longArrayClass_ = function () {
    return this._longArrayClass;
  };
  PrimitiveClasses_0.prototype._get_floatArrayClass_ = function () {
    return this._floatArrayClass;
  };
  PrimitiveClasses_0.prototype._get_doubleArrayClass_ = function () {
    return this._doubleArrayClass;
  };
  PrimitiveClasses_0.prototype.functionClass = function (arity) {
    var tmp0_elvis_lhs = functionClasses[arity];
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_unsafeCast_0_3 = Function;
      var tmp_0 = tmp0_unsafeCast_0_3;
      var tmp_1 = '' + 'Function' + arity;
      var result_2 = new PrimitiveKClassImpl(tmp_0, tmp_1, _no_name_provided_$factory_24(arity));
      var tmp1_asDynamic_0_5 = functionClasses;
      tmp1_asDynamic_0_5[arity] = result_2;
      tmp = result_2;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  PrimitiveClasses_0.$metadata$ = {
    simpleName: 'PrimitiveClasses',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(PrimitiveClasses_0.prototype, 'anyClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_anyClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'numberClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_numberClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'nothingClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_nothingClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'booleanClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_booleanClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'byteClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_byteClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'shortClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_shortClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'intClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_intClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'floatClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_floatClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'doubleClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_doubleClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'arrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_arrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'stringClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_stringClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'throwableClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_throwableClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'booleanArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_booleanArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'charArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_charArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'byteArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_byteArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'shortArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_shortArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'intArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_intArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'longArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_longArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'floatArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_floatArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'doubleArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_doubleArrayClass_
  });
  var PrimitiveClasses_instance;
  function PrimitiveClasses_getInstance() {
    if (PrimitiveClasses_instance == null)
      new PrimitiveClasses_0();
    return PrimitiveClasses_instance;
  }
  function _no_name_provided_$factory_5() {
    var i = new _no_name_provided__12();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_6() {
    var i = new _no_name_provided__13();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_7() {
    var i = new _no_name_provided__14();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_8() {
    var i = new _no_name_provided__15();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_9() {
    var i = new _no_name_provided__16();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_10() {
    var i = new _no_name_provided__17();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_11() {
    var i = new _no_name_provided__18();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_12() {
    var i = new _no_name_provided__19();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_13() {
    var i = new _no_name_provided__20();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_14() {
    var i = new _no_name_provided__21();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_15() {
    var i = new _no_name_provided__22();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_16() {
    var i = new _no_name_provided__23();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_17() {
    var i = new _no_name_provided__24();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_18() {
    var i = new _no_name_provided__25();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_19() {
    var i = new _no_name_provided__26();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_20() {
    var i = new _no_name_provided__27();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_21() {
    var i = new _no_name_provided__28();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_22() {
    var i = new _no_name_provided__29();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_23() {
    var i = new _no_name_provided__30();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_24($arity) {
    var i = new _no_name_provided__31($arity);
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function functionClasses$init$() {
    var tmp0_arrayOfNulls_0 = 0;
    return fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
  }
  function getKClass_0(jClass) {
    var tmp;
    if (Array.isArray(jClass)) {
      tmp = getKClassM_0(jClass);
    } else {
      tmp = getKClass1_0(jClass);
    }
    return tmp;
  }
  function getKClassM_0(jClasses) {
    var tmp0_subject = jClasses.length;
    var tmp;
    switch (tmp0_subject) {
      case 1:
        tmp = getKClass1_0(jClasses[0]);
        break;
      case 0:
        var tmp0_unsafeCast_0 = NothingKClassImpl_getInstance();
        tmp = tmp0_unsafeCast_0;
        break;
      default:var tmp1_unsafeCast_0 = new ErrorKClass();
        tmp = tmp1_unsafeCast_0;
        break;
    }
    return tmp;
  }
  function getKClass1_0(jClass) {
    if (jClass === String) {
      var tmp0_unsafeCast_0 = PrimitiveClasses_getInstance()._stringClass;
      return tmp0_unsafeCast_0;
    }var metadata = jClass.$metadata$;
    var tmp;
    if (metadata != null) {
      var tmp_0;
      if (metadata.$kClass$ == null) {
        var kClass = new SimpleKClassImpl(jClass);
        metadata.$kClass$ = kClass;
        tmp_0 = kClass;
      } else {
        tmp_0 = metadata.$kClass$;
      }
      tmp = tmp_0;
    } else {
      tmp = new SimpleKClassImpl(jClass);
    }
    return tmp;
  }
  function getKClassFromExpression_0(e) {
    var tmp0_subject = typeof e;
    var tmp;
    switch (tmp0_subject) {
      case 'string':
        tmp = PrimitiveClasses_getInstance()._stringClass;
        break;
      case 'number':
        var tmp_0;
        var tmp0_asDynamic_0 = jsBitwiseOr(e, 0);
        if (tmp0_asDynamic_0 === e) {
          tmp_0 = PrimitiveClasses_getInstance()._intClass;
        } else {
          {
            tmp_0 = PrimitiveClasses_getInstance()._doubleClass;
          }
        }

        tmp = tmp_0;
        break;
      case 'boolean':
        tmp = PrimitiveClasses_getInstance()._booleanClass;
        break;
      case 'function':
        var tmp_1 = PrimitiveClasses_getInstance();
        tmp = tmp_1.functionClass(e.length);
        break;
      default:var tmp_2;
        if (isBooleanArray(e)) {
          tmp_2 = PrimitiveClasses_getInstance()._booleanArrayClass;
        } else {
          if (isCharArray(e)) {
            tmp_2 = PrimitiveClasses_getInstance()._charArrayClass;
          } else {
            if (isByteArray(e)) {
              tmp_2 = PrimitiveClasses_getInstance()._byteArrayClass;
            } else {
              if (isShortArray(e)) {
                tmp_2 = PrimitiveClasses_getInstance()._shortArrayClass;
              } else {
                if (isIntArray(e)) {
                  tmp_2 = PrimitiveClasses_getInstance()._intArrayClass;
                } else {
                  if (isLongArray(e)) {
                    tmp_2 = PrimitiveClasses_getInstance()._longArrayClass;
                  } else {
                    if (isFloatArray(e)) {
                      tmp_2 = PrimitiveClasses_getInstance()._floatArrayClass;
                    } else {
                      if (isDoubleArray(e)) {
                        tmp_2 = PrimitiveClasses_getInstance()._doubleArrayClass;
                      } else {
                        if (isInterface(e, KClass)) {
                          tmp_2 = getKClass_0(KClass);
                        } else {
                          if (isArray(e)) {
                            tmp_2 = PrimitiveClasses_getInstance()._arrayClass;
                          } else {
                            {
                              var constructor = Object.getPrototypeOf(e).constructor;
                              var tmp_3;
                              if (constructor === Object) {
                                tmp_3 = PrimitiveClasses_getInstance()._anyClass;
                              } else if (constructor === Error) {
                                tmp_3 = PrimitiveClasses_getInstance()._throwableClass;
                              } else {
                                var jsClass_0 = constructor;
                                tmp_3 = getKClass1_0(jsClass_0);
                              }
                              tmp_2 = tmp_3;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        tmp = tmp_2;
        break;
    }
    var tmp1_unsafeCast_0 = tmp;
    return tmp1_unsafeCast_0;
  }
  function Appendable() {
  }
  Appendable.$metadata$ = {
    simpleName: 'Appendable',
    kind: 'interface',
    interfaces: []
  };
  function StringBuilder_init_$Init$(capacity, $this) {
    StringBuilder_init_$Init$_0($this);
    return $this;
  }
  function StringBuilder_init_$Create$(capacity) {
    return StringBuilder_init_$Init$(capacity, Object.create(StringBuilder.prototype));
  }
  function StringBuilder_init_$Init$_0($this) {
    StringBuilder.call($this, '');
    return $this;
  }
  function StringBuilder_init_$Create$_0() {
    return StringBuilder_init_$Init$_0(Object.create(StringBuilder.prototype));
  }
  function StringBuilder(content) {
    this._string = !(content === undefined) ? content : '';
  }
  StringBuilder.prototype._get_length__0 = function () {
    var tmp0_asDynamic_0 = this._string;
    return tmp0_asDynamic_0.length;
  };
  StringBuilder.prototype.get_44 = function (index) {
    var tmp0_getOrElse_0 = this._string;
    var tmp;
    if (index >= 0 ? index <= _get_lastIndex__1(tmp0_getOrElse_0) : false) {
      tmp = charSequenceGet(tmp0_getOrElse_0, index);
    } else {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', length: ' + this._get_length__0() + '}');
    }
    return tmp;
  };
  StringBuilder.prototype.subSequence_1 = function (startIndex, endIndex) {
    var tmp0_substring_0 = this._string;
    return tmp0_substring_0.substring(startIndex, endIndex);
  };
  StringBuilder.prototype.append_2 = function (value) {
    var tmp0_this = this;
    tmp0_this._string = tmp0_this._string + value;
    return this;
  };
  StringBuilder.prototype.append_3 = function (value) {
    var tmp0_this = this;
    tmp0_this._string = tmp0_this._string + toString_1(value);
    return this;
  };
  StringBuilder.prototype.append_4 = function (value) {
    var tmp0_this = this;
    var tmp = tmp0_this;
    var tmp_0 = tmp0_this._string;
    var tmp1_elvis_lhs = value;
    tmp._string = tmp_0 + (tmp1_elvis_lhs == null ? 'null' : tmp1_elvis_lhs);
    return this;
  };
  StringBuilder.prototype.toString = function () {
    return this._string;
  };
  StringBuilder.$metadata$ = {
    simpleName: 'StringBuilder',
    kind: 'class',
    interfaces: [Appendable, CharSequence]
  };
  function isWhitespace(_this_) {
    return matches(_this_.toString(), '[\\s\\xA0]');
  }
  function uppercaseChar(_this_) {
    var tmp0_asDynamic_0 = _this_.toString();
    var tmp = tmp0_asDynamic_0.toUpperCase();
    var uppercase = (!(tmp == null) ? typeof tmp === 'string' : false) ? tmp : THROW_CCE();
    return uppercase.length > 1 ? _this_ : charSequenceGet(uppercase, 0);
  }
  function checkRadix(radix) {
    if (!(2 <= radix ? radix <= 36 : false)) {
      throw IllegalArgumentException_init_$Create$('' + 'radix ' + radix + ' was not in valid range 2..36');
    }return radix;
  }
  function toDoubleOrNull(_this_) {
    var tmp0_unsafeCast_0 = +_this_;
    var tmp1_takeIf_0 = tmp0_unsafeCast_0;
    var tmp;
    if (!((isNaN_0(tmp1_takeIf_0) ? !isNaN_1(_this_) : false) ? true : tmp1_takeIf_0 === 0.0 ? isBlank(_this_) : false)) {
      tmp = tmp1_takeIf_0;
    } else {
      {
        tmp = null;
      }
    }
    return tmp;
  }
  function digitOf(char, radix) {
    var tmp0_let_0 = (char.compareTo_1(new Char(48)) >= 0 ? char.compareTo_1(new Char(57)) <= 0 : false) ? char.minus(new Char(48)) : (char.compareTo_1(new Char(65)) >= 0 ? char.compareTo_1(new Char(90)) <= 0 : false) ? char.minus(new Char(65)) + 10 | 0 : (char.compareTo_1(new Char(97)) >= 0 ? char.compareTo_1(new Char(122)) <= 0 : false) ? char.minus(new Char(97)) + 10 | 0 : -1;
    return tmp0_let_0 >= radix ? -1 : tmp0_let_0;
  }
  function isNaN_1(_this_) {
    var tmp0_subject = _this_.toLowerCase();
    switch (tmp0_subject) {
      case 'nan':
      case '+nan':
      case '-nan':
        return true;
      default:return false;
    }
  }
  function toString_0(_this_, radix) {
    return _this_.toString(checkRadix(radix));
  }
  function Regex_init_$Init$(pattern, $this) {
    Regex.call($this, pattern, emptySet());
    return $this;
  }
  function Regex_init_$Create$(pattern) {
    return Regex_init_$Init$(pattern, Object.create(Regex.prototype));
  }
  function Companion_7() {
    Companion_instance_6 = this;
    this._patternEscape = new RegExp('[-\\\\^$*+?.()|[\\]{}]', 'g');
    this._replacementEscape = new RegExp('\\$', 'g');
  }
  Companion_7.prototype.escape = function (literal) {
    var tmp0_nativeReplace_0 = this._patternEscape;
    var tmp1_nativeReplace_0 = '\\$&';
    return literal.replace(tmp0_nativeReplace_0, tmp1_nativeReplace_0);
  };
  Companion_7.prototype.escapeReplacement = function (literal) {
    var tmp0_nativeReplace_0 = this._replacementEscape;
    var tmp1_nativeReplace_0 = '$$$$';
    return literal.replace(tmp0_nativeReplace_0, tmp1_nativeReplace_0);
  };
  Companion_7.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_6;
  function Companion_getInstance_6() {
    if (Companion_instance_6 == null)
      new Companion_7();
    return Companion_instance_6;
  }
  function Regex(pattern, options) {
    Companion_getInstance_6();
    this._pattern = pattern;
    this._options = toSet_0(options);
    var tmp = this;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(options, 10));
    var tmp0_iterator_1_2 = options.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      tmp0_mapTo_0_1.add_57(item_2_3.value__error);
      Unit_getInstance();
    }
    tmp._nativePattern = new RegExp(pattern, joinToString$default_0(tmp0_mapTo_0_1, '', null, null, 0, null, null, 62, null) + 'g');
  }
  Regex.prototype.toString = function () {
    return this._nativePattern.toString();
  };
  Regex.$metadata$ = {
    simpleName: 'Regex',
    kind: 'class',
    interfaces: []
  };
  function startsWith_0(_this_, prefix, ignoreCase) {
    if (!ignoreCase) {
      var tmp0_nativeStartsWith_0 = 0;
      return _this_.startsWith(prefix, tmp0_nativeStartsWith_0);
    } else
      return regionMatches(_this_, 0, prefix, 0, prefix.length, ignoreCase);
  }
  function startsWith$default_0(_this_, prefix, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ignoreCase = false;
    return startsWith_0(_this_, prefix, ignoreCase);
  }
  function replace(_this_, oldValue, newValue, ignoreCase) {
    var tmp0_nativeReplace_0 = new RegExp(Companion_getInstance_6().escape(oldValue), ignoreCase ? 'gi' : 'g');
    var tmp1_nativeReplace_0 = Companion_getInstance_6().escapeReplacement(newValue);
    return _this_.replace(tmp0_nativeReplace_0, tmp1_nativeReplace_0);
  }
  function replace$default(_this_, oldValue, newValue, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    return replace(_this_, oldValue, newValue, ignoreCase);
  }
  function isBlank(_this_) {
    var tmp;
    if (charSequenceLength(_this_) === 0) {
      tmp = true;
    } else {
      var tmp_0;
      if (typeof _this_ === 'string') {
        tmp_0 = _this_;
      } else {
        {
          tmp_0 = toString_2(_this_);
        }
      }
      tmp = matches(tmp_0, '^[\\s\\xA0]+$');
    }
    return tmp;
  }
  function regionMatches(_this_, thisOffset, other, otherOffset, length, ignoreCase) {
    return regionMatchesImpl(_this_, thisOffset, other, otherOffset, length, ignoreCase);
  }
  function matches(_this_, regex) {
    var result = _this_.match(regex);
    return !(result == null) ? !(result.length === 0) : false;
  }
  var DurationUnit_NANOSECONDS_instance;
  var DurationUnit_MICROSECONDS_instance;
  var DurationUnit_MILLISECONDS_instance;
  var DurationUnit_SECONDS_instance;
  var DurationUnit_MINUTES_instance;
  var DurationUnit_HOURS_instance;
  var DurationUnit_DAYS_instance;
  var DurationUnit_entriesInitialized;
  function DurationUnit_initEntries() {
    if (DurationUnit_entriesInitialized)
      return Unit_getInstance();
    DurationUnit_entriesInitialized = true;
    DurationUnit_NANOSECONDS_instance = new DurationUnit('NANOSECONDS', 0, 1.0);
    DurationUnit_MICROSECONDS_instance = new DurationUnit('MICROSECONDS', 1, 1000.0);
    DurationUnit_MILLISECONDS_instance = new DurationUnit('MILLISECONDS', 2, 1000000.0);
    DurationUnit_SECONDS_instance = new DurationUnit('SECONDS', 3, 1.0E9);
    DurationUnit_MINUTES_instance = new DurationUnit('MINUTES', 4, 6.0E10);
    DurationUnit_HOURS_instance = new DurationUnit('HOURS', 5, 3.6E12);
    DurationUnit_DAYS_instance = new DurationUnit('DAYS', 6, 8.64E13);
  }
  function DurationUnit(name, ordinal, scale) {
    Enum.call(this, name, ordinal);
    this._scale = scale;
  }
  DurationUnit.$metadata$ = {
    simpleName: 'DurationUnit',
    kind: 'class',
    interfaces: []
  };
  function convertDurationUnit(value, sourceUnit, targetUnit) {
    var sourceCompareTarget = compareTo(sourceUnit._scale, targetUnit._scale);
    return sourceCompareTarget > 0 ? value * (sourceUnit._scale / targetUnit._scale) : sourceCompareTarget < 0 ? value / (targetUnit._scale / sourceUnit._scale) : value;
  }
  function DurationUnit_NANOSECONDS_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_NANOSECONDS_instance;
  }
  function DurationUnit_MILLISECONDS_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_MILLISECONDS_instance;
  }
  function DurationUnit_SECONDS_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_SECONDS_instance;
  }
  function DurationUnit_MINUTES_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_MINUTES_instance;
  }
  function MonotonicTimeSource() {
    MonotonicTimeSource_instance = this;
    var tmp = this;
    var isNode_2 = typeof process !== 'undefined' && process.versions && !!process.versions.node;
    var tmp_0;
    if (isNode_2) {
      var tmp0_unsafeCast_0_3 = process;
      tmp_0 = new HrTimeSource(tmp0_unsafeCast_0_3);
    } else {
      var tmp1_unsafeCast_0_7 = self;
      var tmp0_safe_receiver_6 = tmp1_unsafeCast_0_7;
      var tmp1_safe_receiver_5 = tmp0_safe_receiver_6 == null ? null : tmp0_safe_receiver_6.performance;
      var tmp_1;
      if (tmp1_safe_receiver_5 == null) {
        tmp_1 = null;
      } else {
        tmp_1 = new PerformanceTimeSource(tmp1_safe_receiver_5);
      }
      var tmp2_elvis_lhs_4 = tmp_1;
      tmp_0 = tmp2_elvis_lhs_4 == null ? DateNowTimeSource_getInstance() : tmp2_elvis_lhs_4;
    }
    tmp._actualSource = tmp_0;
  }
  MonotonicTimeSource.prototype.markNow_5 = function () {
    return this._actualSource.markNow_5();
  };
  MonotonicTimeSource.$metadata$ = {
    simpleName: 'MonotonicTimeSource',
    kind: 'object',
    interfaces: [TimeSource]
  };
  var MonotonicTimeSource_instance;
  function MonotonicTimeSource_getInstance() {
    if (MonotonicTimeSource_instance == null)
      new MonotonicTimeSource();
    return MonotonicTimeSource_instance;
  }
  function _no_name_provided__32(this$0) {
    this._this$0_5 = this$0;
    TimeMark.call(this);
    this._startedAt_0 = this._this$0_5._process.hrtime();
  }
  _no_name_provided__32.prototype.elapsedNow_1 = function () {
    var tmp0_let_0 = this._this$0_5._process.hrtime(this._startedAt_0);
    var seconds_2 = tmp0_let_0[0];
    var nanos_3 = tmp0_let_0[1];
    return Duration__plus_impl(_get_seconds_(seconds_2), _get_nanoseconds_(nanos_3));
  };
  _no_name_provided__32.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function HrTimeSource(process_0) {
    this._process = process_0;
  }
  HrTimeSource.prototype.markNow_5 = function () {
    return new _no_name_provided__32(this);
  };
  HrTimeSource.prototype.toString = function () {
    return 'TimeSource(process.hrtime())';
  };
  HrTimeSource.$metadata$ = {
    simpleName: 'HrTimeSource',
    kind: 'class',
    interfaces: [TimeSource]
  };
  function PerformanceTimeSource(performance) {
    AbstractDoubleTimeSource.call(this, DurationUnit_MILLISECONDS_getInstance());
    this._performance = performance;
  }
  PerformanceTimeSource.prototype.read_2 = function () {
    return this._performance.now();
  };
  PerformanceTimeSource.prototype.toString = function () {
    return 'TimeSource(self.performance.now())';
  };
  PerformanceTimeSource.$metadata$ = {
    simpleName: 'PerformanceTimeSource',
    kind: 'class',
    interfaces: []
  };
  function DateNowTimeSource() {
    DateNowTimeSource_instance = this;
    AbstractDoubleTimeSource.call(this, DurationUnit_MILLISECONDS_getInstance());
  }
  DateNowTimeSource.prototype.read_2 = function () {
    return Date.now();
  };
  DateNowTimeSource.prototype.toString = function () {
    return 'TimeSource(Date.now())';
  };
  DateNowTimeSource.$metadata$ = {
    simpleName: 'DateNowTimeSource',
    kind: 'object',
    interfaces: []
  };
  var DateNowTimeSource_instance;
  function DateNowTimeSource_getInstance() {
    if (DateNowTimeSource_instance == null)
      new DateNowTimeSource();
    return DateNowTimeSource_instance;
  }
  function clear(_this_) {
    while (_this_.hasChildNodes()) {
      _this_.removeChild(ensureNotNull(_this_.firstChild));
      Unit_getInstance();
    }
  }
  function Companion_8() {
    Companion_instance_7 = this;
    this._MIN_VALUE_0 = new Char(0);
    this._MAX_VALUE_0 = new Char(65535);
    this._MIN_HIGH_SURROGATE = new Char(55296);
    this._MAX_HIGH_SURROGATE = new Char(56319);
    this._MIN_LOW_SURROGATE = new Char(56320);
    this._MAX_LOW_SURROGATE = new Char(57343);
    this._MIN_SURROGATE = new Char(55296);
    this._MAX_SURROGATE = new Char(57343);
    this._SIZE_BYTES_0 = 2;
    this._SIZE_BITS_0 = 16;
  }
  Companion_8.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_7;
  function Companion_getInstance_7() {
    if (Companion_instance_7 == null)
      new Companion_8();
    return Companion_instance_7;
  }
  function Char(code) {
    Companion_getInstance_7();
    var tmp = this;
    tmp._value_0 = _UShort___get_data__impl_(code) & 65535;
  }
  Char.prototype.compareTo_1 = function (other) {
    return this._value_0 - other._value_0 | 0;
  };
  Char.prototype.compareTo_42 = function (other) {
    return this.compareTo_1(other instanceof Char ? other : THROW_CCE());
  };
  Char.prototype.minus = function (other) {
    return this._value_0 - other._value_0 | 0;
  };
  Char.prototype.toInt_5 = function () {
    return this._value_0;
  };
  Char.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(other instanceof Char))
      return false;
    else {
    }
    return this._value_0 === other._value_0;
  };
  Char.prototype.hashCode = function () {
    return this._value_0;
  };
  Char.prototype.toString = function () {
    var tmp0_unsafeCast_0 = String.fromCharCode(this._value_0);
    return tmp0_unsafeCast_0;
  };
  Char.$metadata$ = {
    simpleName: 'Char',
    kind: 'class',
    interfaces: [Comparable]
  };
  function Iterable() {
  }
  Iterable.$metadata$ = {
    simpleName: 'Iterable',
    kind: 'interface',
    interfaces: []
  };
  function Entry() {
  }
  Entry.$metadata$ = {
    simpleName: 'Entry',
    kind: 'interface',
    interfaces: []
  };
  function Map_0() {
  }
  Map_0.$metadata$ = {
    simpleName: 'Map',
    kind: 'interface',
    interfaces: []
  };
  function List() {
  }
  List.$metadata$ = {
    simpleName: 'List',
    kind: 'interface',
    interfaces: [Collection]
  };
  function MutableList() {
  }
  MutableList.$metadata$ = {
    simpleName: 'MutableList',
    kind: 'interface',
    interfaces: [List, MutableCollection]
  };
  function MutableSet() {
  }
  MutableSet.$metadata$ = {
    simpleName: 'MutableSet',
    kind: 'interface',
    interfaces: [Set, MutableCollection]
  };
  function Set() {
  }
  Set.$metadata$ = {
    simpleName: 'Set',
    kind: 'interface',
    interfaces: [Collection]
  };
  function Collection() {
  }
  Collection.$metadata$ = {
    simpleName: 'Collection',
    kind: 'interface',
    interfaces: [Iterable]
  };
  function MutableCollection() {
  }
  MutableCollection.$metadata$ = {
    simpleName: 'MutableCollection',
    kind: 'interface',
    interfaces: [Collection, MutableIterable]
  };
  function MutableEntry() {
  }
  MutableEntry.$metadata$ = {
    simpleName: 'MutableEntry',
    kind: 'interface',
    interfaces: [Entry]
  };
  function MutableMap() {
  }
  MutableMap.$metadata$ = {
    simpleName: 'MutableMap',
    kind: 'interface',
    interfaces: [Map_0]
  };
  function MutableIterable() {
  }
  MutableIterable.$metadata$ = {
    simpleName: 'MutableIterable',
    kind: 'interface',
    interfaces: [Iterable]
  };
  function Companion_9() {
    Companion_instance_8 = this;
  }
  Companion_9.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_8;
  function Companion_getInstance_8() {
    if (Companion_instance_8 == null)
      new Companion_9();
    return Companion_instance_8;
  }
  function Enum(name, ordinal) {
    Companion_getInstance_8();
    this._name = name;
    this._ordinal = ordinal;
  }
  Enum.prototype._get_name__47 = function () {
    return this._name;
  };
  Enum.prototype._get_ordinal__0 = function () {
    return this._ordinal;
  };
  Enum.prototype.compareTo_3 = function (other) {
    return compareTo(this._ordinal, other._ordinal);
  };
  Enum.prototype.compareTo_42 = function (other) {
    return this.compareTo_3(other instanceof Enum ? other : THROW_CCE());
  };
  Enum.prototype.equals = function (other) {
    return this === other;
  };
  Enum.prototype.hashCode = function () {
    return identityHashCode(this);
  };
  Enum.prototype.toString = function () {
    return this._name;
  };
  Enum.$metadata$ = {
    simpleName: 'Enum',
    kind: 'class',
    interfaces: [Comparable]
  };
  function toString_1(_this_) {
    var tmp0_safe_receiver = _this_;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toString_2(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 'null' : tmp1_elvis_lhs;
  }
  function fillArrayVal(array, initValue) {
    var inductionVariable = 0;
    var last_1 = array.length - 1 | 0;
    if (inductionVariable <= last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        array[i] = initValue;
      }
       while (!(i === last_1));
    return array;
  }
  function arrayIterator(array) {
    return new _no_name_provided__33(array);
  }
  function charArrayOf(arr) {
    var tmp0_withType_0 = 'CharArray';
    var tmp1_withType_0 = arr.slice();
    tmp1_withType_0.$type$ = tmp0_withType_0;
    var tmp2_unsafeCast_0 = tmp1_withType_0;
    return tmp2_unsafeCast_0;
  }
  function _no_name_provided__33($array) {
    this._$array = $array;
    this._index_0 = 0;
  }
  _no_name_provided__33.prototype.hasNext_11 = function () {
    return !(this._index_0 === this._$array.length);
  };
  _no_name_provided__33.prototype.next_13 = function () {
    var tmp;
    if (!(this._index_0 === this._$array.length)) {
      var tmp0_this = this;
      var tmp1 = tmp0_this._index_0;
      tmp0_this._index_0 = tmp1 + 1 | 0;
      tmp = this._$array[tmp1];
    } else {
      throw NoSuchElementException_init_$Create$_0('' + this._index_0);
    }
    return tmp;
  };
  _no_name_provided__33.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator]
  };
  var buf;
  var bufFloat64;
  var bufInt32;
  var lowIndex;
  var highIndex;
  function getNumberHashCode(obj) {
    var tmp0_unsafeCast_0 = jsBitwiseOr(obj, 0);
    if (tmp0_unsafeCast_0 === obj) {
      return numberToInt(obj);
    } else {
    }
    bufFloat64[0] = obj;
    return imul(bufInt32[highIndex], 31) + bufInt32[lowIndex] | 0;
  }
  function bufFloat64$init$() {
    var tmp0_unsafeCast_0 = new Float64Array(buf);
    return tmp0_unsafeCast_0;
  }
  function bufInt32$init$() {
    var tmp0_unsafeCast_0 = new Int32Array(buf);
    return tmp0_unsafeCast_0;
  }
  function lowIndex$init$() {
    bufFloat64[0] = -1.0;
    return !(bufInt32[0] === 0) ? 1 : 0;
  }
  function charSequenceGet(a, index) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.charCodeAt(index);
      var tmp1_Char_0 = tmp0_unsafeCast_0;
      var tmp_0;
      var tmp0__get_code__0_1 = new Char(0);
      if (tmp1_Char_0 < tmp0__get_code__0_1.toInt_5()) {
        tmp_0 = true;
      } else {
        {
          var tmp1__get_code__0_2 = new Char(65535);
          tmp_0 = tmp1_Char_0 > tmp1__get_code__0_2.toInt_5();
        }
      }
      if (tmp_0) {
        throw IllegalArgumentException_init_$Create$('' + 'Invalid Char code: ' + tmp1_Char_0);
      } else {
      }
      tmp = new Char(_UShort___init__impl_(toShort(tmp1_Char_0)));
    } else {
      tmp = a.get_44(index);
    }
    return tmp;
  }
  function isString(a) {
    return typeof a === 'string';
  }
  function charSequenceLength(a) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.length;
      tmp = tmp0_unsafeCast_0;
    } else {
      tmp = a._get_length__0();
    }
    return tmp;
  }
  function charSequenceSubSequence(a, startIndex, endIndex) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.substring(startIndex, endIndex);
      tmp = tmp0_unsafeCast_0;
    } else {
      tmp = a.subSequence_1(startIndex, endIndex);
    }
    return tmp;
  }
  function arrayToString(array) {
    return joinToString$default(array, ', ', '[', ']', 0, null, _no_name_provided_$factory_25(), 24, null);
  }
  function _no_name_provided__34() {
  }
  _no_name_provided__34.prototype.invoke_51 = function (it) {
    return toString_2(it);
  };
  _no_name_provided__34.prototype.invoke_1082 = function (p1) {
    return this.invoke_51((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__34.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_25() {
    var i = new _no_name_provided__34();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function compareTo(a, b) {
    var tmp0_subject = typeof a;
    var tmp;
    switch (tmp0_subject) {
      case 'number':
        var tmp_0;
        if (typeof b === 'number') {
          tmp_0 = doubleCompareTo(a, b);
        } else {
          if (b instanceof Long) {
            tmp_0 = doubleCompareTo(a, b.toDouble_4());
          } else {
            {
              tmp_0 = primitiveCompareTo(a, b);
            }
          }
        }

        tmp = tmp_0;
        break;
      case 'string':
      case 'boolean':
        tmp = primitiveCompareTo(a, b);
        break;
      default:tmp = compareToDoNotIntrinsicify(a, b);
        break;
    }
    return tmp;
  }
  function doubleCompareTo(a, b) {
    var tmp;
    if (a < b) {
      tmp = -1;
    } else if (a > b) {
      tmp = 1;
    } else if (a === b) {
      var tmp_0;
      if (a !== 0) {
        tmp_0 = 0;
      } else {
        var tmp0_asDynamic_0 = 1;
        var ia = tmp0_asDynamic_0 / a;
        var tmp_1;
        var tmp1_asDynamic_0 = 1;
        if (ia === tmp1_asDynamic_0 / b) {
          tmp_1 = 0;
        } else {
          if (ia < 0) {
            tmp_1 = -1;
          } else {
            {
              tmp_1 = 1;
            }
          }
        }
        tmp_0 = tmp_1;
      }
      tmp = tmp_0;
    } else if (a !== a) {
      tmp = b !== b ? 0 : 1;
    } else {
      tmp = -1;
    }
    return tmp;
  }
  function primitiveCompareTo(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  function compareToDoNotIntrinsicify(a, b) {
    return a.compareTo_42(b);
  }
  function identityHashCode(obj) {
    return getObjectHashCode(obj);
  }
  function getObjectHashCode(obj) {
    if (!jsIn('kotlinHashCodeValue$', obj)) {
      var hash = jsBitwiseOr(Math.random() * 4.294967296E9, 0);
      var descriptor = new Object();
      descriptor.value = hash;
      descriptor.enumerable = false;
      Object.defineProperty(obj, 'kotlinHashCodeValue$', descriptor);
    }var tmp0_unsafeCast_0 = obj['kotlinHashCodeValue$'];
    return tmp0_unsafeCast_0;
  }
  function equals_0(obj1, obj2) {
    if (obj1 == null) {
      return obj2 == null;
    }if (obj2 == null) {
      return false;
    }if (typeof obj1 === 'object' ? typeof obj1.equals === 'function' : false) {
      return obj1.equals(obj2);
    }if (obj1 !== obj1) {
      return obj2 !== obj2;
    }if (typeof obj1 === 'number' ? typeof obj2 === 'number' : false) {
      var tmp;
      if (obj1 === obj2) {
        var tmp_0;
        if (obj1 !== 0) {
          tmp_0 = true;
        } else {
          var tmp0_asDynamic_0 = 1;
          var tmp_1 = tmp0_asDynamic_0 / obj1;
          var tmp1_asDynamic_0 = 1;
          tmp_0 = tmp_1 === tmp1_asDynamic_0 / obj2;
        }
        tmp = tmp_0;
      } else {
        tmp = false;
      }
      return tmp;
    }return obj1 === obj2;
  }
  function hashCode(obj) {
    if (obj == null)
      return 0;
    var tmp0_subject = typeof obj;
    var tmp;
    switch (tmp0_subject) {
      case 'object':
        tmp = 'function' === typeof obj.hashCode ? obj.hashCode() : getObjectHashCode(obj);
        break;
      case 'function':
        tmp = getObjectHashCode(obj);
        break;
      case 'number':
        tmp = getNumberHashCode(obj);
        break;
      case 'boolean':
        var tmp_0;
        if (obj) {
          tmp_0 = 1;
        } else {
          {
            tmp_0 = 0;
          }
        }

        tmp = tmp_0;
        break;
      default:tmp = getStringHashCode(String(obj));
        break;
    }
    return tmp;
  }
  function toString_2(o) {
    var tmp;
    if (o == null) {
      tmp = 'null';
    } else if (isArrayish(o)) {
      tmp = '[...]';
    } else {
      var tmp0_unsafeCast_0 = o.toString();
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  }
  function getStringHashCode(str) {
    var hash = 0;
    var length = str.length;
    var inductionVariable = 0;
    var last_1 = length - 1 | 0;
    if (inductionVariable <= last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var code = str.charCodeAt(i);
        hash = imul(hash, 31) + code | 0;
      }
       while (!(i === last_1));
    return hash;
  }
  function boxIntrinsic(x) {
    var tmp0_error_0 = 'Should be lowered';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  }
  function unboxIntrinsic(x) {
    var tmp0_error_0 = 'Should be lowered';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  }
  function captureStack(instance, constructorFunction) {
    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(instance, constructorFunction);
    } else {
      instance.stack = (new Error()).stack;
    }
  }
  function extendThrowable(this_, message, cause) {
    Error.call(this_);
    setPropertiesToThrowableInstance(this_, message, cause);
  }
  function setPropertiesToThrowableInstance(this_, message, cause) {
    if (!hasOwnPrototypeProperty(this_, 'message')) {
      var tmp;
      if (message == null) {
        var tmp_0;
        if (!(message === null)) {
          var tmp0_safe_receiver = cause;
          var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.toString();
          tmp_0 = tmp1_elvis_lhs == null ? undefined : tmp1_elvis_lhs;
        } else {
          tmp_0 = undefined;
        }
        tmp = tmp_0;
      } else {
        tmp = message;
      }
      this_.message = tmp;
    }if (!hasOwnPrototypeProperty(this_, 'cause')) {
      this_.cause = cause;
    }this_.name = Object.getPrototypeOf(this_).constructor.name;
  }
  function hasOwnPrototypeProperty(o, name) {
    var tmp0_unsafeCast_0 = Object.getPrototypeOf(o).hasOwnProperty(name);
    return tmp0_unsafeCast_0;
  }
  function ensureNotNull(v) {
    var tmp;
    if (v == null) {
      THROW_NPE();
    } else {
      tmp = v;
    }
    return tmp;
  }
  function THROW_NPE() {
    throw NullPointerException_init_$Create$();
  }
  function noWhenBranchMatchedException() {
    throw NoWhenBranchMatchedException_init_$Create$();
  }
  function THROW_CCE() {
    throw ClassCastException_init_$Create$();
  }
  function throwUninitializedPropertyAccessException(name) {
    throw UninitializedPropertyAccessException_init_$Create$('' + 'lateinit property ' + name + ' has not been initialized');
  }
  function THROW_ISE() {
    throw IllegalStateException_init_$Create$();
  }
  function lazy(initializer) {
    return new UnsafeLazyImpl(initializer);
  }
  function Companion_10() {
    Companion_instance_9 = this;
    this._MIN_VALUE_1 = new Long(0, -2147483648);
    this._MAX_VALUE_1 = new Long(-1, 2147483647);
    this._SIZE_BYTES_1 = 8;
    this._SIZE_BITS_1 = 64;
  }
  Companion_10.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_9;
  function Companion_getInstance_9() {
    if (Companion_instance_9 == null)
      new Companion_10();
    return Companion_instance_9;
  }
  function Long(low, high) {
    Companion_getInstance_9();
    Number_0.call(this);
    this._low = low;
    this._high = high;
  }
  Long.prototype.compareTo_76 = function (other) {
    return compare(this, other);
  };
  Long.prototype.compareTo_42 = function (other) {
    return this.compareTo_76(other instanceof Long ? other : THROW_CCE());
  };
  Long.prototype.plus_29 = function (other) {
    return add(this, other);
  };
  Long.prototype.div_27 = function (other) {
    return divide(this, other);
  };
  Long.prototype.unaryMinus_4 = function () {
    return this.inv_0().plus_29(new Long(1, 0));
  };
  Long.prototype.inv_0 = function () {
    return new Long(~this._low, ~this._high);
  };
  Long.prototype.toInt_5 = function () {
    return this._low;
  };
  Long.prototype.toDouble_4 = function () {
    return toNumber(this);
  };
  Long.prototype.valueOf = function () {
    return this.toDouble_4();
  };
  Long.prototype.equals = function (other) {
    var tmp;
    if (other instanceof Long) {
      tmp = equalsLong(this, other);
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  Long.prototype.hashCode = function () {
    return hashCode_0(this);
  };
  Long.prototype.toString = function () {
    return toStringImpl(this, 10);
  };
  Long.$metadata$ = {
    simpleName: 'Long',
    kind: 'class',
    interfaces: [Comparable]
  };
  var ZERO;
  var ONE;
  var NEG_ONE;
  var MAX_VALUE;
  var MIN_VALUE;
  var TWO_PWR_24_;
  function compare(_this_, other) {
    if (equalsLong(_this_, other)) {
      return 0;
    }var thisNeg = isNegative(_this_);
    var otherNeg = isNegative(other);
    return (thisNeg ? !otherNeg : false) ? -1 : (!thisNeg ? otherNeg : false) ? 1 : isNegative(subtract(_this_, other)) ? -1 : 1;
  }
  function add(_this_, other) {
    var a48 = _this_._high >>> 16;
    var a32 = _this_._high & 65535;
    var a16 = _this_._low >>> 16;
    var a00 = _this_._low & 65535;
    var b48 = other._high >>> 16;
    var b32 = other._high & 65535;
    var b16 = other._low >>> 16;
    var b00 = other._low & 65535;
    var c48 = 0;
    var c32 = 0;
    var c16 = 0;
    var c00 = 0;
    c00 = c00 + (a00 + b00 | 0) | 0;
    c16 = c16 + (c00 >>> 16) | 0;
    c00 = c00 & 65535;
    c16 = c16 + (a16 + b16 | 0) | 0;
    c32 = c32 + (c16 >>> 16) | 0;
    c16 = c16 & 65535;
    c32 = c32 + (a32 + b32 | 0) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c48 = c48 + (a48 + b48 | 0) | 0;
    c48 = c48 & 65535;
    return new Long(c16 << 16 | c00, c48 << 16 | c32);
  }
  function subtract(_this_, other) {
    return add(_this_, other.unaryMinus_4());
  }
  function multiply(_this_, other) {
    if (isZero(_this_)) {
      return ZERO;
    } else if (isZero(other)) {
      return ZERO;
    }if (equalsLong(_this_, MIN_VALUE)) {
      return isOdd(other) ? MIN_VALUE : ZERO;
    } else if (equalsLong(other, MIN_VALUE)) {
      return isOdd(_this_) ? MIN_VALUE : ZERO;
    }if (isNegative(_this_)) {
      var tmp;
      if (isNegative(other)) {
        tmp = multiply(negate(_this_), negate(other));
      } else {
        tmp = negate(multiply(negate(_this_), other));
      }
      return tmp;
    } else if (isNegative(other)) {
      return negate(multiply(_this_, negate(other)));
    }if (lessThan(_this_, TWO_PWR_24_) ? lessThan(other, TWO_PWR_24_) : false) {
      return fromNumber(toNumber(_this_) * toNumber(other));
    }var a48 = _this_._high >>> 16;
    var a32 = _this_._high & 65535;
    var a16 = _this_._low >>> 16;
    var a00 = _this_._low & 65535;
    var b48 = other._high >>> 16;
    var b32 = other._high & 65535;
    var b16 = other._low >>> 16;
    var b00 = other._low & 65535;
    var c48 = 0;
    var c32 = 0;
    var c16 = 0;
    var c00 = 0;
    c00 = c00 + imul(a00, b00) | 0;
    c16 = c16 + (c00 >>> 16) | 0;
    c00 = c00 & 65535;
    c16 = c16 + imul(a16, b00) | 0;
    c32 = c32 + (c16 >>> 16) | 0;
    c16 = c16 & 65535;
    c16 = c16 + imul(a00, b16) | 0;
    c32 = c32 + (c16 >>> 16) | 0;
    c16 = c16 & 65535;
    c32 = c32 + imul(a32, b00) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c32 = c32 + imul(a16, b16) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c32 = c32 + imul(a00, b32) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c48 = c48 + (((imul(a48, b00) + imul(a32, b16) | 0) + imul(a16, b32) | 0) + imul(a00, b48) | 0) | 0;
    c48 = c48 & 65535;
    return new Long(c16 << 16 | c00, c48 << 16 | c32);
  }
  function divide(_this_, other) {
    if (isZero(other)) {
      throw Exception_init_$Create$('division by zero');
    } else if (isZero(_this_)) {
      return ZERO;
    }if (equalsLong(_this_, MIN_VALUE)) {
      if (equalsLong(other, ONE) ? true : equalsLong(other, NEG_ONE)) {
        return MIN_VALUE;
      } else if (equalsLong(other, MIN_VALUE)) {
        return ONE;
      } else {
        var halfThis = shiftRight(_this_, 1);
        var approx = shiftLeft(halfThis.div_27(other), 1);
        if (equalsLong(approx, ZERO)) {
          return isNegative(other) ? ONE : NEG_ONE;
        } else {
          var rem = subtract(_this_, multiply(other, approx));
          return add(approx, rem.div_27(other));
        }
      }
    } else if (equalsLong(other, MIN_VALUE)) {
      return ZERO;
    }if (isNegative(_this_)) {
      var tmp;
      if (isNegative(other)) {
        tmp = negate(_this_).div_27(negate(other));
      } else {
        tmp = negate(negate(_this_).div_27(other));
      }
      return tmp;
    } else if (isNegative(other)) {
      return negate(_this_.div_27(negate(other)));
    }var res = ZERO;
    var rem_0 = _this_;
    while (greaterThanOrEqual(rem_0, other)) {
      var approxDouble = toNumber(rem_0) / toNumber(other);
      var approx2 = Math.max(1.0, Math.floor(approxDouble));
      var log2 = Math.ceil(Math.log(approx2) / Math.LN2);
      var delta = log2 <= 48.0 ? 1.0 : Math.pow(2, log2 - 48);
      var approxRes = fromNumber(approx2);
      var approxRem = multiply(approxRes, other);
      while (isNegative(approxRem) ? true : greaterThan(approxRem, rem_0)) {
        approx2 = approx2 - delta;
        approxRes = fromNumber(approx2);
        approxRem = multiply(approxRes, other);
      }
      if (isZero(approxRes)) {
        approxRes = ONE;
      }res = add(res, approxRes);
      rem_0 = subtract(rem_0, approxRem);
    }
    return res;
  }
  function shiftLeft(_this_, numBits) {
    var numBits_0 = numBits & 63;
    if (numBits_0 === 0) {
      return _this_;
    } else {
      if (numBits_0 < 32) {
        return new Long(_this_._low << numBits_0, _this_._high << numBits_0 | _this_._low >>> (32 - numBits_0 | 0));
      } else {
        return new Long(0, _this_._low << (numBits_0 - 32 | 0));
      }
    }
  }
  function shiftRight(_this_, numBits) {
    var numBits_0 = numBits & 63;
    if (numBits_0 === 0) {
      return _this_;
    } else {
      if (numBits_0 < 32) {
        return new Long(_this_._low >>> numBits_0 | _this_._high << (32 - numBits_0 | 0), _this_._high >> numBits_0);
      } else {
        return new Long(_this_._high >> (numBits_0 - 32 | 0), _this_._high >= 0 ? 0 : -1);
      }
    }
  }
  function toNumber(_this_) {
    return _this_._high * 4.294967296E9 + getLowBitsUnsigned(_this_);
  }
  function equalsLong(_this_, other) {
    return _this_._high === other._high ? _this_._low === other._low : false;
  }
  function hashCode_0(l) {
    return l._low ^ l._high;
  }
  function toStringImpl(_this_, radix) {
    if (radix < 2 ? true : 36 < radix) {
      throw Exception_init_$Create$('' + 'radix out of range: ' + radix);
    }if (isZero(_this_)) {
      return '0';
    }if (isNegative(_this_)) {
      if (equalsLong(_this_, MIN_VALUE)) {
        var radixLong = fromInt(radix);
        var div_1 = _this_.div_27(radixLong);
        var rem = subtract(multiply(div_1, radixLong), _this_).toInt_5();
        var tmp = toStringImpl(div_1, radix);
        var tmp0_unsafeCast_0 = rem.toString(radix);
        return tmp + tmp0_unsafeCast_0;
      } else {
        return '' + '-' + toStringImpl(negate(_this_), radix);
      }
    }var radixToPower = fromNumber(Math.pow(radix, 6.0));
    var rem_0 = _this_;
    var result = '';
    while (true) {
      var remDiv = rem_0.div_27(radixToPower);
      var intval = subtract(rem_0, multiply(remDiv, radixToPower)).toInt_5();
      var tmp1_unsafeCast_0 = intval.toString(radix);
      var digits = tmp1_unsafeCast_0;
      rem_0 = remDiv;
      if (isZero(rem_0)) {
        return digits + result;
      } else {
        while (digits.length < 6) {
          digits = '0' + digits;
        }
        result = digits + result;
      }
    }
  }
  function fromInt(value) {
    return new Long(value, value < 0 ? -1 : 0);
  }
  function isNegative(_this_) {
    return _this_._high < 0;
  }
  function isZero(_this_) {
    return _this_._high === 0 ? _this_._low === 0 : false;
  }
  function isOdd(_this_) {
    return (_this_._low & 1) === 1;
  }
  function negate(_this_) {
    return _this_.unaryMinus_4();
  }
  function lessThan(_this_, other) {
    return compare(_this_, other) < 0;
  }
  function fromNumber(value) {
    if (isNaN_0(value)) {
      return ZERO;
    } else if (value <= -9.223372036854776E18) {
      return MIN_VALUE;
    } else if (value + 1 >= 9.223372036854776E18) {
      return MAX_VALUE;
    } else if (value < 0.0) {
      return negate(fromNumber(-value));
    } else {
      var twoPwr32 = 4.294967296E9;
      return new Long(jsBitwiseOr(value % twoPwr32, 0), jsBitwiseOr(value / twoPwr32, 0));
    }
  }
  function greaterThan(_this_, other) {
    return compare(_this_, other) > 0;
  }
  function greaterThanOrEqual(_this_, other) {
    return compare(_this_, other) >= 0;
  }
  function getLowBitsUnsigned(_this_) {
    return _this_._low >= 0 ? _this_._low : 4.294967296E9 + _this_._low;
  }
  function imul(a_local, b_local) {
    var lhs = jsBitwiseAnd(a_local, 4.29490176E9) * jsBitwiseAnd(b_local, 65535);
    var rhs = jsBitwiseAnd(a_local, 65535) * b_local;
    return jsBitwiseOr(lhs + rhs, 0);
  }
  function numberToInt(a) {
    var tmp;
    if (a instanceof Long) {
      tmp = a.toInt_5();
    } else {
      {
        tmp = doubleToInt(a);
      }
    }
    return tmp;
  }
  function doubleToInt(a) {
    return a > 2.147483647E9 ? 2147483647 : a < -2.147483648E9 ? -2147483648 : jsBitwiseOr(a, 0);
  }
  function numberToDouble(a) {
    var tmp0_unsafeCast_0 = +a;
    return tmp0_unsafeCast_0;
  }
  function toShort(a) {
    var tmp0_unsafeCast_0 = a << 16 >> 16;
    return tmp0_unsafeCast_0;
  }
  function numberToChar(a) {
    var tmp0_Char_0 = numberToInt(a) & 65535;
    var tmp;
    var tmp0__get_code__0_1 = new Char(0);
    if (tmp0_Char_0 < tmp0__get_code__0_1.toInt_5()) {
      tmp = true;
    } else {
      {
        var tmp1__get_code__0_2 = new Char(65535);
        tmp = tmp0_Char_0 > tmp1__get_code__0_2.toInt_5();
      }
    }
    if (tmp) {
      throw IllegalArgumentException_init_$Create$('' + 'Invalid Char code: ' + tmp0_Char_0);
    } else {
    }
    return new Char(_UShort___init__impl_(toShort(tmp0_Char_0)));
  }
  function numberRangeToNumber(start, endInclusive) {
    return new IntRange(start, endInclusive);
  }
  var propertyRefClassMetadataCache;
  function getPropertyCallableRef(name, paramCount, type, getter, setter) {
    getter.get = getter;
    getter.set = setter;
    getter.callableName = name;
    var tmp0_unsafeCast_0 = getPropertyRefClass(getter, getKPropMetadata(paramCount, setter, type));
    return tmp0_unsafeCast_0;
  }
  function getPropertyRefClass(obj, metadata) {
    obj.$metadata$ = metadata;
    obj.constructor = obj;
    return obj;
  }
  function getKPropMetadata(paramCount, setter, type) {
    var mdata = propertyRefClassMetadataCache[paramCount][setter == null ? 0 : 1];
    if (mdata.interfaces.length == 0) {
      mdata.interfaces.push(type);
    }return mdata;
  }
  function propertyRefClassMetadataCache$init$() {
    var tmp = {kind: 'class', interfaces: []};
    var tmp0_arrayOf_0 = [tmp, {kind: 'class', interfaces: []}];
    var tmp_0 = tmp0_arrayOf_0;
    var tmp_1 = {kind: 'class', interfaces: []};
    var tmp1_arrayOf_0 = [tmp_1, {kind: 'class', interfaces: []}];
    var tmp_2 = tmp1_arrayOf_0;
    var tmp_3 = {kind: 'class', interfaces: []};
    var tmp2_arrayOf_0 = [tmp_3, {kind: 'class', interfaces: []}];
    var tmp3_arrayOf_0 = [tmp_0, tmp_2, tmp2_arrayOf_0];
    return tmp3_arrayOf_0;
  }
  function isArrayish(o) {
    var tmp;
    if (isJsArray(o)) {
      tmp = true;
    } else {
      var tmp0_unsafeCast_0 = ArrayBuffer.isView(o);
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  }
  function isJsArray(obj) {
    var tmp0_unsafeCast_0 = Array.isArray(obj);
    return tmp0_unsafeCast_0;
  }
  function isInterface(obj, iface) {
    var tmp0_elvis_lhs = obj.constructor;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var ctor = tmp;
    return isInterfaceImpl(ctor, iface);
  }
  function isInterfaceImpl(ctor, iface) {
    if (ctor === iface)
      return true;
    var metadata = ctor.$metadata$;
    if (!(metadata == null)) {
      var interfaces = metadata.interfaces;
      var indexedObject = interfaces;
      var inductionVariable = 0;
      var last_1 = indexedObject.length;
      while (inductionVariable < last_1) {
        var i = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if (isInterfaceImpl(i, iface)) {
          return true;
        }}
    }var superPrototype = !(ctor.prototype == null) ? Object.getPrototypeOf(ctor.prototype) : null;
    var superConstructor = superPrototype != null ? superPrototype.constructor : null;
    return !(superConstructor == null) ? isInterfaceImpl(superConstructor, iface) : false;
  }
  function isArray(obj) {
    var tmp;
    if (isJsArray(obj)) {
      tmp = !obj.$type$;
    } else {
      tmp = false;
    }
    return tmp;
  }
  function isObject(obj) {
    var objTypeOf = typeof obj;
    var tmp0_subject = objTypeOf;
    switch (tmp0_subject) {
      case 'string':
        return true;
      case 'number':
        return true;
      case 'boolean':
        return true;
      case 'function':
        return true;
      default:return jsInstanceOf(obj, Object);
    }
  }
  function isNumber(a) {
    var tmp;
    if (typeof a === 'number') {
      tmp = true;
    } else {
      tmp = a instanceof Long;
    }
    return tmp;
  }
  function isCharSequence(value) {
    return typeof value === 'string' ? true : isInterface(value, _get_js_(getKClass_0(CharSequence)));
  }
  function isBooleanArray(a) {
    return isJsArray(a) ? a.$type$ === 'BooleanArray' : false;
  }
  function isByteArray(a) {
    return jsInstanceOf(a, Int8Array);
  }
  function isShortArray(a) {
    return jsInstanceOf(a, Int16Array);
  }
  function isCharArray(a) {
    return isJsArray(a) ? a.$type$ === 'CharArray' : false;
  }
  function isIntArray(a) {
    return jsInstanceOf(a, Int32Array);
  }
  function isFloatArray(a) {
    return jsInstanceOf(a, Float32Array);
  }
  function isLongArray(a) {
    return isJsArray(a) ? a.$type$ === 'LongArray' : false;
  }
  function isDoubleArray(a) {
    return jsInstanceOf(a, Float64Array);
  }
  function asList(_this_) {
    return new ArrayList(_this_);
  }
  function sortWith_0(_this_, comparator) {
    if (_this_.length > 1)
      sortArrayWith(_this_, comparator);
  }
  function fill(_this_, element, fromIndex, toIndex) {
    Companion_getInstance().checkRangeIndexes(fromIndex, toIndex, _this_.length);
    _this_.fill(element, fromIndex, toIndex);
  }
  function reverse(_this_) {
    var midPoint = (_this_._get_size__43() / 2 | 0) - 1 | 0;
    if (midPoint < 0)
      return Unit_getInstance();
    var reverseIndex = _get_lastIndex__0(_this_);
    var inductionVariable = 0;
    if (inductionVariable <= midPoint)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = _this_.get_44(index);
        _this_.set_3(index, _this_.get_44(reverseIndex));
        Unit_getInstance();
        _this_.set_3(reverseIndex, tmp);
        Unit_getInstance();
        var tmp1 = reverseIndex;
        reverseIndex = tmp1 - 1 | 0;
        Unit_getInstance();
      }
       while (!(index === midPoint));
  }
  function Exception_init_$Init$($this) {
    extendThrowable($this, void 1, void 1);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Init$_0(message, $this) {
    extendThrowable($this, message, void 1);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Create$(message) {
    var tmp = Exception_init_$Init$_0(message, Object.create(Exception.prototype));
    captureStack(tmp, Exception_init_$Create$);
    return tmp;
  }
  function Exception() {
    captureStack(this, Exception);
  }
  Exception.$metadata$ = {
    simpleName: 'Exception',
    kind: 'class',
    interfaces: []
  };
  function IllegalArgumentException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalArgumentException.call($this);
    return $this;
  }
  function IllegalArgumentException_init_$Create$(message) {
    var tmp = IllegalArgumentException_init_$Init$(message, Object.create(IllegalArgumentException.prototype));
    captureStack(tmp, IllegalArgumentException_init_$Create$);
    return tmp;
  }
  function IllegalArgumentException() {
    captureStack(this, IllegalArgumentException);
  }
  IllegalArgumentException.$metadata$ = {
    simpleName: 'IllegalArgumentException',
    kind: 'class',
    interfaces: []
  };
  function RuntimeException_init_$Init$($this) {
    Exception_init_$Init$($this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException_init_$Init$_0(message, $this) {
    Exception_init_$Init$_0(message, $this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException() {
    captureStack(this, RuntimeException);
  }
  RuntimeException.$metadata$ = {
    simpleName: 'RuntimeException',
    kind: 'class',
    interfaces: []
  };
  function NoSuchElementException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NoSuchElementException.call($this);
    return $this;
  }
  function NoSuchElementException_init_$Create$() {
    var tmp = NoSuchElementException_init_$Init$(Object.create(NoSuchElementException.prototype));
    captureStack(tmp, NoSuchElementException_init_$Create$);
    return tmp;
  }
  function NoSuchElementException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    NoSuchElementException.call($this);
    return $this;
  }
  function NoSuchElementException_init_$Create$_0(message) {
    var tmp = NoSuchElementException_init_$Init$_0(message, Object.create(NoSuchElementException.prototype));
    captureStack(tmp, NoSuchElementException_init_$Create$_0);
    return tmp;
  }
  function NoSuchElementException() {
    captureStack(this, NoSuchElementException);
  }
  NoSuchElementException.$metadata$ = {
    simpleName: 'NoSuchElementException',
    kind: 'class',
    interfaces: []
  };
  function IllegalStateException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    IllegalStateException.call($this);
    return $this;
  }
  function IllegalStateException_init_$Create$() {
    var tmp = IllegalStateException_init_$Init$(Object.create(IllegalStateException.prototype));
    captureStack(tmp, IllegalStateException_init_$Create$);
    return tmp;
  }
  function IllegalStateException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalStateException.call($this);
    return $this;
  }
  function IllegalStateException_init_$Create$_0(message) {
    var tmp = IllegalStateException_init_$Init$_0(message, Object.create(IllegalStateException.prototype));
    captureStack(tmp, IllegalStateException_init_$Create$_0);
    return tmp;
  }
  function IllegalStateException() {
    captureStack(this, IllegalStateException);
  }
  IllegalStateException.$metadata$ = {
    simpleName: 'IllegalStateException',
    kind: 'class',
    interfaces: []
  };
  function IndexOutOfBoundsException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IndexOutOfBoundsException.call($this);
    return $this;
  }
  function IndexOutOfBoundsException_init_$Create$(message) {
    var tmp = IndexOutOfBoundsException_init_$Init$(message, Object.create(IndexOutOfBoundsException.prototype));
    captureStack(tmp, IndexOutOfBoundsException_init_$Create$);
    return tmp;
  }
  function IndexOutOfBoundsException() {
    captureStack(this, IndexOutOfBoundsException);
  }
  IndexOutOfBoundsException.$metadata$ = {
    simpleName: 'IndexOutOfBoundsException',
    kind: 'class',
    interfaces: []
  };
  function UnsupportedOperationException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    UnsupportedOperationException.call($this);
    return $this;
  }
  function UnsupportedOperationException_init_$Create$() {
    var tmp = UnsupportedOperationException_init_$Init$(Object.create(UnsupportedOperationException.prototype));
    captureStack(tmp, UnsupportedOperationException_init_$Create$);
    return tmp;
  }
  function UnsupportedOperationException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    UnsupportedOperationException.call($this);
    return $this;
  }
  function UnsupportedOperationException_init_$Create$_0(message) {
    var tmp = UnsupportedOperationException_init_$Init$_0(message, Object.create(UnsupportedOperationException.prototype));
    captureStack(tmp, UnsupportedOperationException_init_$Create$_0);
    return tmp;
  }
  function UnsupportedOperationException() {
    captureStack(this, UnsupportedOperationException);
  }
  UnsupportedOperationException.$metadata$ = {
    simpleName: 'UnsupportedOperationException',
    kind: 'class',
    interfaces: []
  };
  function ArithmeticException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    ArithmeticException.call($this);
    return $this;
  }
  function ArithmeticException_init_$Create$(message) {
    var tmp = ArithmeticException_init_$Init$(message, Object.create(ArithmeticException.prototype));
    captureStack(tmp, ArithmeticException_init_$Create$);
    return tmp;
  }
  function ArithmeticException() {
    captureStack(this, ArithmeticException);
  }
  ArithmeticException.$metadata$ = {
    simpleName: 'ArithmeticException',
    kind: 'class',
    interfaces: []
  };
  function NullPointerException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NullPointerException.call($this);
    return $this;
  }
  function NullPointerException_init_$Create$() {
    var tmp = NullPointerException_init_$Init$(Object.create(NullPointerException.prototype));
    captureStack(tmp, NullPointerException_init_$Create$);
    return tmp;
  }
  function NullPointerException() {
    captureStack(this, NullPointerException);
  }
  NullPointerException.$metadata$ = {
    simpleName: 'NullPointerException',
    kind: 'class',
    interfaces: []
  };
  function NoWhenBranchMatchedException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NoWhenBranchMatchedException.call($this);
    return $this;
  }
  function NoWhenBranchMatchedException_init_$Create$() {
    var tmp = NoWhenBranchMatchedException_init_$Init$(Object.create(NoWhenBranchMatchedException.prototype));
    captureStack(tmp, NoWhenBranchMatchedException_init_$Create$);
    return tmp;
  }
  function NoWhenBranchMatchedException() {
    captureStack(this, NoWhenBranchMatchedException);
  }
  NoWhenBranchMatchedException.$metadata$ = {
    simpleName: 'NoWhenBranchMatchedException',
    kind: 'class',
    interfaces: []
  };
  function ClassCastException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    ClassCastException.call($this);
    return $this;
  }
  function ClassCastException_init_$Create$() {
    var tmp = ClassCastException_init_$Init$(Object.create(ClassCastException.prototype));
    captureStack(tmp, ClassCastException_init_$Create$);
    return tmp;
  }
  function ClassCastException() {
    captureStack(this, ClassCastException);
  }
  ClassCastException.$metadata$ = {
    simpleName: 'ClassCastException',
    kind: 'class',
    interfaces: []
  };
  function UninitializedPropertyAccessException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    UninitializedPropertyAccessException.call($this);
    return $this;
  }
  function UninitializedPropertyAccessException_init_$Create$(message) {
    var tmp = UninitializedPropertyAccessException_init_$Init$(message, Object.create(UninitializedPropertyAccessException.prototype));
    captureStack(tmp, UninitializedPropertyAccessException_init_$Create$);
    return tmp;
  }
  function UninitializedPropertyAccessException() {
    captureStack(this, UninitializedPropertyAccessException);
  }
  UninitializedPropertyAccessException.$metadata$ = {
    simpleName: 'UninitializedPropertyAccessException',
    kind: 'class',
    interfaces: []
  };
  function jsIn(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = lhs_hack in rhs_hack;
    return tmp0_unsafeCast_0;
  }
  function jsBitwiseOr(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = lhs_hack | rhs_hack;
    return tmp0_unsafeCast_0;
  }
  function jsTypeOf(value_hack) {
    var tmp0_unsafeCast_0 = typeof value_hack;
    return tmp0_unsafeCast_0;
  }
  function jsDeleteProperty(obj_hack, property_hack) {
    delete obj_hack[property_hack];
  }
  function jsInstanceOf(obj_hack, jsClass_hack) {
    var tmp0_unsafeCast_0 = obj_hack instanceof jsClass_hack;
    return tmp0_unsafeCast_0;
  }
  function jsBitwiseAnd(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = lhs_hack & rhs_hack;
    return tmp0_unsafeCast_0;
  }
  function KSerializer() {
  }
  KSerializer.$metadata$ = {
    simpleName: 'KSerializer',
    kind: 'interface',
    interfaces: [SerializationStrategy, DeserializationStrategy]
  };
  function DeserializationStrategy() {
  }
  DeserializationStrategy.$metadata$ = {
    simpleName: 'DeserializationStrategy',
    kind: 'interface',
    interfaces: []
  };
  function SerializationStrategy() {
  }
  SerializationStrategy.$metadata$ = {
    simpleName: 'SerializationStrategy',
    kind: 'interface',
    interfaces: []
  };
  function StringFormat() {
  }
  StringFormat.$metadata$ = {
    simpleName: 'StringFormat',
    kind: 'interface',
    interfaces: [SerialFormat]
  };
  function SerialFormat() {
  }
  SerialFormat.$metadata$ = {
    simpleName: 'SerialFormat',
    kind: 'interface',
    interfaces: []
  };
  function ENUM() {
  }
  ENUM.$metadata$ = {
    simpleName: 'ENUM',
    kind: 'object',
    interfaces: []
  };
  function CONTEXTUAL() {
    CONTEXTUAL_instance = this;
    SerialKind.call(this);
  }
  CONTEXTUAL.$metadata$ = {
    simpleName: 'CONTEXTUAL',
    kind: 'object',
    interfaces: []
  };
  var CONTEXTUAL_instance;
  function CONTEXTUAL_getInstance() {
    if (CONTEXTUAL_instance == null)
      new CONTEXTUAL();
    return CONTEXTUAL_instance;
  }
  function SerialKind() {
  }
  SerialKind.prototype.toString = function () {
    return ensureNotNull(getKClassFromExpression_0(this)._get_simpleName__4());
  };
  SerialKind.prototype.hashCode = function () {
    return getStringHashCode(this.toString());
  };
  SerialKind.$metadata$ = {
    simpleName: 'SerialKind',
    kind: 'class',
    interfaces: []
  };
  function PrimitiveKind() {
  }
  PrimitiveKind.$metadata$ = {
    simpleName: 'PrimitiveKind',
    kind: 'class',
    interfaces: []
  };
  function LIST() {
    LIST_instance = this;
    StructureKind.call(this);
  }
  LIST.$metadata$ = {
    simpleName: 'LIST',
    kind: 'object',
    interfaces: []
  };
  var LIST_instance;
  function LIST_getInstance() {
    if (LIST_instance == null)
      new LIST();
    return LIST_instance;
  }
  function MAP() {
    MAP_instance = this;
    StructureKind.call(this);
  }
  MAP.$metadata$ = {
    simpleName: 'MAP',
    kind: 'object',
    interfaces: []
  };
  var MAP_instance;
  function MAP_getInstance() {
    if (MAP_instance == null)
      new MAP();
    return MAP_instance;
  }
  function StructureKind() {
    SerialKind.call(this);
  }
  StructureKind.$metadata$ = {
    simpleName: 'StructureKind',
    kind: 'class',
    interfaces: []
  };
  function PolymorphicKind() {
  }
  PolymorphicKind.$metadata$ = {
    simpleName: 'PolymorphicKind',
    kind: 'class',
    interfaces: []
  };
  var EmptySerializersModule;
  function SerializersModule() {
  }
  SerializersModule.$metadata$ = {
    simpleName: 'SerializersModule',
    kind: 'class',
    interfaces: []
  };
  function SerialModuleImpl(class2Serializer, polyBase2Serializers, polyBase2NamedSerializers, polyBase2DefaultProvider) {
    SerializersModule.call(this);
    this._class2Serializer = class2Serializer;
    this._polyBase2Serializers = polyBase2Serializers;
    this._polyBase2NamedSerializers = polyBase2NamedSerializers;
    this._polyBase2DefaultProvider = polyBase2DefaultProvider;
  }
  SerialModuleImpl.prototype.dumpTo_0 = function (collector) {
    var tmp0_forEach_0 = this._class2Serializer;
    var tmp0_iterator_1 = tmp0_forEach_0._get_entries__5().iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      var kclass_4 = element_2._get_key__2();
      var serial_5 = element_2._get_value__18();
      var tmp = isInterface(kclass_4, KClass) ? kclass_4 : THROW_CCE();
      collector.contextual_0(tmp, isInterface(serial_5, KSerializer) ? serial_5 : THROW_CCE());
    }
    var tmp1_forEach_0 = this._polyBase2Serializers;
    var tmp0_iterator_1_0 = tmp1_forEach_0._get_entries__5().iterator_38();
    while (tmp0_iterator_1_0.hasNext_11()) {
      var element_2_0 = tmp0_iterator_1_0.next_13();
      var baseClass_4 = element_2_0._get_key__2();
      var classMap_5 = element_2_0._get_value__18();
      var tmp0_iterator_1_6 = classMap_5._get_entries__5().iterator_38();
      while (tmp0_iterator_1_6.hasNext_11()) {
        var element_2_7 = tmp0_iterator_1_6.next_13();
        var actualClass_4_8 = element_2_7._get_key__2();
        var serializer_5_9 = element_2_7._get_value__18();
        var tmp_0 = isInterface(baseClass_4, KClass) ? baseClass_4 : THROW_CCE();
        var tmp_1 = isInterface(actualClass_4_8, KClass) ? actualClass_4_8 : THROW_CCE();
        collector.polymorphic_0(tmp_0, tmp_1, isInterface(serializer_5_9, KSerializer) ? serializer_5_9 : THROW_CCE());
      }
    }
    var tmp2_forEach_0 = this._polyBase2DefaultProvider;
    var tmp0_iterator_1_1 = tmp2_forEach_0._get_entries__5().iterator_38();
    while (tmp0_iterator_1_1.hasNext_11()) {
      var element_2_1 = tmp0_iterator_1_1.next_13();
      var baseClass_4_0 = element_2_1._get_key__2();
      var provider_5 = element_2_1._get_value__18();
      var tmp_2 = isInterface(baseClass_4_0, KClass) ? baseClass_4_0 : THROW_CCE();
      collector.polymorphicDefault_0(tmp_2, typeof provider_5 === 'function' ? provider_5 : THROW_CCE());
    }
  };
  SerialModuleImpl.$metadata$ = {
    simpleName: 'SerialModuleImpl',
    kind: 'class',
    interfaces: []
  };
  function SerializersModuleCollector() {
  }
  SerializersModuleCollector.$metadata$ = {
    simpleName: 'SerializersModuleCollector',
    kind: 'interface',
    interfaces: []
  };
  function Default() {
    Default_instance = this;
    Json_0.call(this, JsonConf_init_$Create$(false, false, false, false, false, null, false, false, null, false, null, 2047, null));
  }
  Default.$metadata$ = {
    simpleName: 'Default',
    kind: 'object',
    interfaces: []
  };
  var Default_instance;
  function Default_getInstance() {
    if (Default_instance == null)
      new Default();
    return Default_instance;
  }
  function Json_0(configuration) {
    Default_getInstance();
    this._configuration = configuration;
  }
  Json_0.prototype._get_configuration__0 = function () {
    return this._configuration;
  };
  Json_0.prototype._get_serializersModule__0 = function () {
    return this._configuration._serializersModule_0;
  };
  Json_0.$metadata$ = {
    simpleName: 'Json',
    kind: 'class',
    interfaces: [StringFormat]
  };
  function Json_1(from, builderAction) {
    var builder = new JsonBuilder(from._configuration);
    builderAction(builder);
    var conf = builder.build_1();
    return new JsonImpl(conf);
  }
  function Json$default(from, builderAction, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      from = Default_getInstance();
    return Json_1(from, builderAction);
  }
  function JsonBuilder(conf) {
    this._encodeDefaults = conf._encodeDefaults_0;
    this._ignoreUnknownKeys = conf._ignoreUnknownKeys_0;
    this._isLenient = conf._isLenient_0;
    this._allowStructuredMapKeys = conf._allowStructuredMapKeys_0;
    this._prettyPrint = conf._prettyPrint_0;
    this._prettyPrintIndent = conf._prettyPrintIndent_0;
    this._coerceInputValues = conf._coerceInputValues_0;
    this._useArrayPolymorphism = conf._useArrayPolymorphism_0;
    this._classDiscriminator = conf._classDiscriminator_0;
    this._allowSpecialFloatingPointValues = conf._allowSpecialFloatingPointValues_0;
    this._serializersModule = conf._serializersModule_0;
  }
  JsonBuilder.prototype.build_1 = function () {
    if (this._useArrayPolymorphism) {
      var tmp0_require_0 = this._classDiscriminator === 'type';
      if (!tmp0_require_0) {
        var message_1 = 'Class discriminator should not be specified when array polymorphism is specified';
        throw IllegalArgumentException_init_$Create$(toString_2(message_1));
      }}if (!this._prettyPrint) {
      var tmp1_require_0 = this._prettyPrintIndent === '    ';
      if (!tmp1_require_0) {
        var message_1_0 = 'Indent should not be specified when default printing mode is used';
        throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
      }} else if (!(this._prettyPrintIndent === '    ')) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp2_all_0 = this._prettyPrintIndent;
        var indexedObject = tmp2_all_0;
        var inductionVariable = 0;
        var last_1 = indexedObject.length;
        while (inductionVariable < last_1) {
          var element_2 = charSequenceGet(indexedObject, inductionVariable);
          inductionVariable = inductionVariable + 1 | 0;
          if (!(((element_2.equals(new Char(32)) ? true : element_2.equals(new Char(9))) ? true : element_2.equals(new Char(13))) ? true : element_2.equals(new Char(10)))) {
            tmp$ret$0 = false;
            break l$ret$1;
          } else {
          }
        }
        tmp$ret$0 = true;
      }
       while (false);
      var allWhitespaces = tmp$ret$0;
      if (!allWhitespaces) {
        var message_1_1 = '' + 'Only whitespace, tab, newline and carriage return are allowed as pretty print symbols. Had ' + this._prettyPrintIndent;
        throw IllegalArgumentException_init_$Create$(toString_2(message_1_1));
      }}return new JsonConf(this._encodeDefaults, this._ignoreUnknownKeys, this._isLenient, this._allowStructuredMapKeys, this._prettyPrint, this._prettyPrintIndent, this._coerceInputValues, this._useArrayPolymorphism, this._classDiscriminator, this._allowSpecialFloatingPointValues, this._serializersModule);
  };
  JsonBuilder.$metadata$ = {
    simpleName: 'JsonBuilder',
    kind: 'class',
    interfaces: []
  };
  function validateConfiguration($this) {
    if (equals_0($this._get_serializersModule__0(), EmptySerializersModule))
      return Unit_getInstance();
    var collector = new PolymorphismValidator($this._get_configuration__0()._useArrayPolymorphism_0, $this._get_configuration__0()._classDiscriminator_0);
    $this._get_serializersModule__0().dumpTo_0(collector);
  }
  function JsonImpl(configuration) {
    Json_0.call(this, configuration);
    validateConfiguration(this);
  }
  JsonImpl.$metadata$ = {
    simpleName: 'JsonImpl',
    kind: 'class',
    interfaces: []
  };
  function JsonConf_init_$Init$(encodeDefaults, ignoreUnknownKeys, isLenient, allowStructuredMapKeys, prettyPrint, prettyPrintIndent, coerceInputValues, useArrayPolymorphism, classDiscriminator, allowSpecialFloatingPointValues, serializersModule, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      encodeDefaults = false;
    if (!(($mask0 & 2) === 0))
      ignoreUnknownKeys = false;
    if (!(($mask0 & 4) === 0))
      isLenient = false;
    if (!(($mask0 & 8) === 0))
      allowStructuredMapKeys = false;
    if (!(($mask0 & 16) === 0))
      prettyPrint = false;
    if (!(($mask0 & 32) === 0))
      prettyPrintIndent = '    ';
    if (!(($mask0 & 64) === 0))
      coerceInputValues = false;
    if (!(($mask0 & 128) === 0))
      useArrayPolymorphism = false;
    if (!(($mask0 & 256) === 0))
      classDiscriminator = 'type';
    if (!(($mask0 & 512) === 0))
      allowSpecialFloatingPointValues = false;
    if (!(($mask0 & 1024) === 0))
      serializersModule = EmptySerializersModule;
    JsonConf.call($this, encodeDefaults, ignoreUnknownKeys, isLenient, allowStructuredMapKeys, prettyPrint, prettyPrintIndent, coerceInputValues, useArrayPolymorphism, classDiscriminator, allowSpecialFloatingPointValues, serializersModule);
    return $this;
  }
  function JsonConf_init_$Create$(encodeDefaults, ignoreUnknownKeys, isLenient, allowStructuredMapKeys, prettyPrint, prettyPrintIndent, coerceInputValues, useArrayPolymorphism, classDiscriminator, allowSpecialFloatingPointValues, serializersModule, $mask0, $marker) {
    return JsonConf_init_$Init$(encodeDefaults, ignoreUnknownKeys, isLenient, allowStructuredMapKeys, prettyPrint, prettyPrintIndent, coerceInputValues, useArrayPolymorphism, classDiscriminator, allowSpecialFloatingPointValues, serializersModule, $mask0, $marker, Object.create(JsonConf.prototype));
  }
  function JsonConf(encodeDefaults, ignoreUnknownKeys, isLenient, allowStructuredMapKeys, prettyPrint, prettyPrintIndent, coerceInputValues, useArrayPolymorphism, classDiscriminator, allowSpecialFloatingPointValues, serializersModule) {
    this._encodeDefaults_0 = encodeDefaults;
    this._ignoreUnknownKeys_0 = ignoreUnknownKeys;
    this._isLenient_0 = isLenient;
    this._allowStructuredMapKeys_0 = allowStructuredMapKeys;
    this._prettyPrint_0 = prettyPrint;
    this._prettyPrintIndent_0 = prettyPrintIndent;
    this._coerceInputValues_0 = coerceInputValues;
    this._useArrayPolymorphism_0 = useArrayPolymorphism;
    this._classDiscriminator_0 = classDiscriminator;
    this._allowSpecialFloatingPointValues_0 = allowSpecialFloatingPointValues;
    this._serializersModule_0 = serializersModule;
  }
  JsonConf.prototype.toString = function () {
    return '' + 'JsonConf(encodeDefaults=' + this._encodeDefaults_0 + ', ignoreUnknownKeys=' + this._ignoreUnknownKeys_0 + ', isLenient=' + this._isLenient_0 + ', allowStructuredMapKeys=' + this._allowStructuredMapKeys_0 + ', prettyPrint=' + this._prettyPrint_0 + ', prettyPrintIndent=' + this._prettyPrintIndent_0 + ', coerceInputValues=' + this._coerceInputValues_0 + ', useArrayPolymorphism=' + this._useArrayPolymorphism_0 + ', classDiscriminator=' + this._classDiscriminator_0 + ', allowSpecialFloatingPointValues=' + this._allowSpecialFloatingPointValues_0 + ', serializersModule=' + this._serializersModule_0 + ')';
  };
  JsonConf.prototype.hashCode = function () {
    var result = this._encodeDefaults_0 | 0;
    result = imul(result, 31) + (this._ignoreUnknownKeys_0 | 0) | 0;
    result = imul(result, 31) + (this._isLenient_0 | 0) | 0;
    result = imul(result, 31) + (this._allowStructuredMapKeys_0 | 0) | 0;
    result = imul(result, 31) + (this._prettyPrint_0 | 0) | 0;
    result = imul(result, 31) + getStringHashCode(this._prettyPrintIndent_0) | 0;
    result = imul(result, 31) + (this._coerceInputValues_0 | 0) | 0;
    result = imul(result, 31) + (this._useArrayPolymorphism_0 | 0) | 0;
    result = imul(result, 31) + getStringHashCode(this._classDiscriminator_0) | 0;
    result = imul(result, 31) + (this._allowSpecialFloatingPointValues_0 | 0) | 0;
    result = imul(result, 31) + hashCode(this._serializersModule_0) | 0;
    return result;
  };
  JsonConf.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof JsonConf))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof JsonConf ? other : THROW_CCE();
    if (!(this._encodeDefaults_0 === tmp0_other_with_cast._encodeDefaults_0))
      return false;
    if (!(this._ignoreUnknownKeys_0 === tmp0_other_with_cast._ignoreUnknownKeys_0))
      return false;
    if (!(this._isLenient_0 === tmp0_other_with_cast._isLenient_0))
      return false;
    if (!(this._allowStructuredMapKeys_0 === tmp0_other_with_cast._allowStructuredMapKeys_0))
      return false;
    if (!(this._prettyPrint_0 === tmp0_other_with_cast._prettyPrint_0))
      return false;
    if (!(this._prettyPrintIndent_0 === tmp0_other_with_cast._prettyPrintIndent_0))
      return false;
    if (!(this._coerceInputValues_0 === tmp0_other_with_cast._coerceInputValues_0))
      return false;
    if (!(this._useArrayPolymorphism_0 === tmp0_other_with_cast._useArrayPolymorphism_0))
      return false;
    if (!(this._classDiscriminator_0 === tmp0_other_with_cast._classDiscriminator_0))
      return false;
    if (!(this._allowSpecialFloatingPointValues_0 === tmp0_other_with_cast._allowSpecialFloatingPointValues_0))
      return false;
    if (!equals_0(this._serializersModule_0, tmp0_other_with_cast._serializersModule_0))
      return false;
    return true;
  };
  JsonConf.$metadata$ = {
    simpleName: 'JsonConf',
    kind: 'class',
    interfaces: []
  };
  function checkKind($this, descriptor, actualClass) {
    var kind = descriptor._get_kind___error();
    var tmp;
    if (kind instanceof PolymorphicKind) {
      tmp = true;
    } else {
      {
        tmp = equals_0(kind, CONTEXTUAL_getInstance());
      }
    }
    if (tmp) {
      throw IllegalArgumentException_init_$Create$('' + 'Serializer for ' + actualClass._get_simpleName__4() + " can't be registered as a subclass for polymorphic serialization " + ('' + 'because its kind ' + kind + ' is not concrete. To work with multiple hierarchies, register it as a base class.'));
    } else {
    }
    if ($this._useArrayPolymorphism_1)
      return Unit_getInstance();
    var tmp_0;
    var tmp_1;
    if (equals_0(kind, LIST_getInstance()) ? true : equals_0(kind, MAP_getInstance())) {
      tmp_1 = true;
    } else {
      tmp_1 = kind instanceof PrimitiveKind;
    }
    if (tmp_1) {
      tmp_0 = true;
    } else {
      {
        tmp_0 = kind instanceof ENUM;
      }
    }
    if (tmp_0) {
      throw IllegalArgumentException_init_$Create$('' + 'Serializer for ' + actualClass._get_simpleName__4() + ' of kind ' + kind + ' cannot be serialized polymorphically with class discriminator.');
    } else {
    }
  }
  function checkDiscriminatorCollisions($this, descriptor, actualClass) {
    var inductionVariable = 0;
    var last_1 = descriptor._get_elementsCount___error();
    if (inductionVariable < last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var name = descriptor.getElementName__error(i);
        if (name === $this._discriminator) {
          throw IllegalArgumentException_init_$Create$('' + 'Polymorphic serializer for ' + actualClass + " has property '" + name + "' that conflicts " + 'with JSON class discriminator. You can either change class discriminator in JsonConfiguration, ' + 'rename property with @SerialName annotation ' + 'or fall back to array polymorphism');
        }}
       while (inductionVariable < last_1);
  }
  function PolymorphismValidator(useArrayPolymorphism, discriminator) {
    this._useArrayPolymorphism_1 = useArrayPolymorphism;
    this._discriminator = discriminator;
  }
  PolymorphismValidator.prototype.contextual_0 = function (kClass, serializer) {
  };
  PolymorphismValidator.prototype.polymorphic_0 = function (baseClass, actualClass, actualSerializer) {
    var descriptor = actualSerializer._get_descriptor_();
    checkKind(this, descriptor, actualClass);
    if (!this._useArrayPolymorphism_1) {
      checkDiscriminatorCollisions(this, descriptor, actualClass);
    }};
  PolymorphismValidator.prototype.polymorphicDefault_0 = function (baseClass, defaultSerializerProvider) {
  };
  PolymorphismValidator.$metadata$ = {
    simpleName: 'PolymorphismValidator',
    kind: 'class',
    interfaces: [SerializersModuleCollector]
  };
  var attributesModule;
  var classModule;
  var datasetModule;
  var eventListenersModule;
  var heroModule;
  var htmlDomApi;
  var propsModule;
  var styleModule;
  function Application() {
  }
  Application.prototype.start_4 = function () {
  };
  Application.prototype.start_3 = function (state) {
    this.start_4();
  };
  Application.prototype.dispose_47 = function () {
    return emptyMap();
  };
  Application.$metadata$ = {
    simpleName: 'Application',
    kind: 'class',
    interfaces: []
  };
  function startApplication(builder, hot) {
    var tmp0_asDynamic_0 = window;
    if (tmp0_asDynamic_0.__karma__)
      return Unit_getInstance();
    else {
    }
    var application = {_v: null};
    var tmp0_safe_receiver = hot;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp0_safe_receiver.accept();
      tmp0_safe_receiver.dispose(_no_name_provided_$factory_26(application));
      tmp = tmp0_safe_receiver.data;
    }
    var state = tmp;
    if (!(document.body == null)) {
      application._v = startApplication$start(builder, state);
    } else {
      application._v = null;
      var tmp_0 = document;
      tmp_0.addEventListener('DOMContentLoaded', _no_name_provided_$factory_27(application, state, builder));
    }
  }
  function startApplication$start($builder, state) {
    var tmp0_safe_receiver = state;
    if ((tmp0_safe_receiver == null ? null : tmp0_safe_receiver.appState) != undefined) {
      Companion_getInstance_17().start_4();
    } else {
    }
    var application = $builder();
    var tmp1_safe_receiver = state;
    var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.appState;
    application.start_3(tmp2_elvis_lhs == null ? emptyMap() : tmp2_elvis_lhs);
    return application;
  }
  function _no_name_provided__35($application) {
    this._$application = $application;
  }
  _no_name_provided__35.prototype.invoke_53 = function (data) {
    Companion_getInstance_16().disposeAllRoots();
    Companion_getInstance_17().shutdown();
    var tmp0_safe_receiver_3 = this._$application._v;
    data.appState = tmp0_safe_receiver_3 == null ? null : tmp0_safe_receiver_3.dispose_47();
    this._$application._v = null;
  };
  _no_name_provided__35.prototype.invoke_1082 = function (p1) {
    this.invoke_53((p1 == null ? true : p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__35.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__36($application, $state, $builder) {
    this._$application_0 = $application;
    this._$state = $state;
    this._$builder = $builder;
  }
  _no_name_provided__36.prototype.invoke_795 = function (it) {
    this._$application_0._v = startApplication$start(this._$builder, this._$state);
  };
  _no_name_provided__36.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__36.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_26($application) {
    var i = new _no_name_provided__35($application);
    return function (p1) {
      i.invoke_53(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_27($application, $state, $builder) {
    var i = new _no_name_provided__36($application, $state, $builder);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function KVManager() {
    KVManager_instance = this;
    try {
      require('kvision-kvision-bootstrap-css-jsLegacy').io.kvision.KVManagerBootstrapCss;
    } catch ($p) {
      if ($p instanceof Error) {
      } else {
        {
          throw $p;
        }
      }
    }
    try {
      require('kvision-kvision-bootstrap-jsLegacy').io.kvision.KVManagerBootstrap;
    } catch ($p_0) {
      if ($p_0 instanceof Error) {
      } else {
        {
          throw $p_0;
        }
      }
    }
    try {
      require('kvision-kvision-fontawesome-jsLegacy').io.kvision.KVManagerFontAwesome;
    } catch ($p_1) {
      if ($p_1 instanceof Error) {
      } else {
        {
          throw $p_1;
        }
      }
    }
    try {
      require('kvision-kvision-onsenui-css-jsLegacy').io.kvision.KVManagerOnsenuiCss;
    } catch ($p_2) {
      if ($p_2 instanceof Error) {
      } else {
        {
          throw $p_2;
        }
      }
    }
    require('kvision-assets/css/style.css');
    require('jquery-resizable-dom');
    this._fecha = require('fecha').default;
    var tmp = this;
    var tmp_0 = Snabbdom;
    var tmp0_arrayOf_0 = [classModule, attributesModule, propsModule, styleModule, eventListenersModule, datasetModule];
    tmp._sdPatch = tmp_0.init(tmp0_arrayOf_0);
    this._sdVirtualize = require('snabbdom-virtualize/strings').default;
  }
  KVManager.prototype.patch = function (id, vnode) {
    var container = document.getElementById(id);
    var tmp0_safe_receiver = container;
    if (tmp0_safe_receiver == null)
      null;
    else {
      clear(tmp0_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._sdPatch(container, vnode);
  };
  KVManager.prototype.patch_0 = function (oldVNode, newVNode) {
    return this._sdPatch(oldVNode, newVNode);
  };
  KVManager.prototype.virtualize = function (html) {
    return this._sdVirtualize(html);
  };
  KVManager.$metadata$ = {
    simpleName: 'KVManager',
    kind: 'object',
    interfaces: []
  };
  var KVManager_instance;
  function KVManager_getInstance() {
    if (KVManager_instance == null)
      new KVManager();
    return KVManager_instance;
  }
  function buildAttributeSet(delegate_0) {
    var tmp0_also_0 = new AttributeSetBuilderImpl();
    delegate_0(tmp0_also_0);
    return tmp0_also_0._get_attributes__10();
  }
  function AttributeSetBuilder() {
  }
  AttributeSetBuilder.prototype.add$default = function (name, value, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      value = name;
    return $handler == null ? this.add_24(name, value) : $handler(name, value);
  };
  AttributeSetBuilder.prototype.addAll_15 = function (attributes) {
    var tmp0_iterator_1 = attributes._get_entries__5().iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      var key_4 = element_2._get_key__2();
      var value_5 = element_2._get_value__18();
      this.add_24(key_4, value_5);
    }
  };
  AttributeSetBuilder.$metadata$ = {
    simpleName: 'AttributeSetBuilder',
    kind: 'interface',
    interfaces: []
  };
  function AttributeSetBuilderImpl() {
    this.__attributes = {};
  }
  AttributeSetBuilderImpl.prototype._get_attributes__10 = function () {
    return Object.assign({}, this.__attributes);
  };
  AttributeSetBuilderImpl.prototype.add_24 = function (name, value) {
    this.__attributes[name] = value;
  };
  AttributeSetBuilderImpl.$metadata$ = {
    simpleName: 'AttributeSetBuilderImpl',
    kind: 'class',
    interfaces: [AttributeSetBuilder]
  };
  function ClassSetBuilder() {
  }
  ClassSetBuilder.prototype.add_28 = function (value) {
    if (!(value == null)) {
      this.add_27(value._get_className__3());
    }};
  ClassSetBuilder.$metadata$ = {
    simpleName: 'ClassSetBuilder',
    kind: 'interface',
    interfaces: []
  };
  function buildClassSet(delegate_0) {
    var tmp0_also_0 = new ClassSetBuilderImpl();
    delegate_0(tmp0_also_0);
    return tmp0_also_0._get_classes_();
  }
  function ClassSetBuilderImpl() {
    this.__classes = {};
  }
  ClassSetBuilderImpl.prototype._get_classes_ = function () {
    return Object.assign({}, this.__classes);
  };
  ClassSetBuilderImpl.prototype.add_27 = function (value) {
    if (charSequenceLength(value) > 0) {
      this.__classes[value] = true;
    } else {
    }
  };
  ClassSetBuilderImpl.prototype.addAll_17 = function (values_3) {
    var tmp0_iterator_1 = values_3.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      this.add_27(element_2);
    }
  };
  ClassSetBuilderImpl.$metadata$ = {
    simpleName: 'ClassSetBuilderImpl',
    kind: 'class',
    interfaces: [ClassSetBuilder]
  };
  function Component() {
  }
  Component.prototype.focus_32 = function () {
    var tmp0_safe_receiver = this.getElementJQuery_31();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.focus();
    Unit_getInstance();
  };
  Component.$metadata$ = {
    simpleName: 'Component',
    kind: 'interface',
    interfaces: []
  };
  function Container() {
  }
  Container.$metadata$ = {
    simpleName: 'Container',
    kind: 'interface',
    interfaces: [Component]
  };
  var UNIT_px_instance;
  var UNIT_pt_instance;
  var UNIT_em_instance;
  var UNIT_cm_instance;
  var UNIT_mm_instance;
  var UNIT_in_instance;
  var UNIT_pc_instance;
  var UNIT_ch_instance;
  var UNIT_rem_instance;
  var UNIT_vw_instance;
  var UNIT_vh_instance;
  var UNIT_vmin_instance;
  var UNIT_vmax_instance;
  var UNIT_perc_instance;
  var UNIT_auto_instance;
  var UNIT_normal_instance;
  var UNIT_entriesInitialized;
  function UNIT_initEntries() {
    if (UNIT_entriesInitialized)
      return Unit_getInstance();
    UNIT_entriesInitialized = true;
    UNIT_px_instance = new UNIT('px', 0, 'px');
    UNIT_pt_instance = new UNIT('pt', 1, 'pt');
    UNIT_em_instance = new UNIT('em', 2, 'em');
    UNIT_cm_instance = new UNIT('cm', 3, 'cm');
    UNIT_mm_instance = new UNIT('mm', 4, 'mm');
    UNIT_in_instance = new UNIT('in', 5, 'in');
    UNIT_pc_instance = new UNIT('pc', 6, 'pc');
    UNIT_ch_instance = new UNIT('ch', 7, 'ch');
    UNIT_rem_instance = new UNIT('rem', 8, 'rem');
    UNIT_vw_instance = new UNIT('vw', 9, 'vw');
    UNIT_vh_instance = new UNIT('vh', 10, 'vh');
    UNIT_vmin_instance = new UNIT('vmin', 11, 'vmin');
    UNIT_vmax_instance = new UNIT('vmax', 12, 'vmax');
    UNIT_perc_instance = new UNIT('perc', 13, '%');
    UNIT_auto_instance = new UNIT('auto', 14, 'auto');
    UNIT_normal_instance = new UNIT('normal', 15, 'normal');
  }
  function UNIT(name, ordinal, unit) {
    Enum.call(this, name, ordinal);
    this._unit_0 = unit;
  }
  UNIT.$metadata$ = {
    simpleName: 'UNIT',
    kind: 'class',
    interfaces: []
  };
  var JustifyContent_FLEXSTART_instance;
  var JustifyContent_FLEXEND_instance;
  var JustifyContent_CENTER_instance;
  var JustifyContent_SPACEBETWEEN_instance;
  var JustifyContent_SPACEAROUND_instance;
  var JustifyContent_SPACEEVENLY_instance;
  var JustifyContent_START_instance;
  var JustifyContent_END_instance;
  var JustifyContent_STRETCH_instance;
  var JustifyContent_entriesInitialized;
  function JustifyContent_initEntries() {
    if (JustifyContent_entriesInitialized)
      return Unit_getInstance();
    JustifyContent_entriesInitialized = true;
    JustifyContent_FLEXSTART_instance = new JustifyContent('FLEXSTART', 0, 'flex-start');
    JustifyContent_FLEXEND_instance = new JustifyContent('FLEXEND', 1, 'flex-end');
    JustifyContent_CENTER_instance = new JustifyContent('CENTER', 2, 'center');
    JustifyContent_SPACEBETWEEN_instance = new JustifyContent('SPACEBETWEEN', 3, 'space-between');
    JustifyContent_SPACEAROUND_instance = new JustifyContent('SPACEAROUND', 4, 'space-around');
    JustifyContent_SPACEEVENLY_instance = new JustifyContent('SPACEEVENLY', 5, 'space-evenly');
    JustifyContent_START_instance = new JustifyContent('START', 6, 'start');
    JustifyContent_END_instance = new JustifyContent('END', 7, 'end');
    JustifyContent_STRETCH_instance = new JustifyContent('STRETCH', 8, 'stretch');
  }
  function JustifyContent(name, ordinal, justifyContent) {
    Enum.call(this, name, ordinal);
    this._justifyContent = justifyContent;
  }
  JustifyContent.$metadata$ = {
    simpleName: 'JustifyContent',
    kind: 'class',
    interfaces: []
  };
  var AlignItems_FLEXSTART_instance;
  var AlignItems_FLEXEND_instance;
  var AlignItems_CENTER_instance;
  var AlignItems_BASELINE_instance;
  var AlignItems_STRETCH_instance;
  var AlignItems_START_instance;
  var AlignItems_END_instance;
  var AlignItems_entriesInitialized;
  function AlignItems_initEntries() {
    if (AlignItems_entriesInitialized)
      return Unit_getInstance();
    AlignItems_entriesInitialized = true;
    AlignItems_FLEXSTART_instance = new AlignItems('FLEXSTART', 0, 'flex-start');
    AlignItems_FLEXEND_instance = new AlignItems('FLEXEND', 1, 'flex-end');
    AlignItems_CENTER_instance = new AlignItems('CENTER', 2, 'center');
    AlignItems_BASELINE_instance = new AlignItems('BASELINE', 3, 'baseline');
    AlignItems_STRETCH_instance = new AlignItems('STRETCH', 4, 'stretch');
    AlignItems_START_instance = new AlignItems('START', 5, 'start');
    AlignItems_END_instance = new AlignItems('END', 6, 'end');
  }
  function AlignItems(name, ordinal, alignItems) {
    Enum.call(this, name, ordinal);
    this._alignItems = alignItems;
  }
  AlignItems.$metadata$ = {
    simpleName: 'AlignItems',
    kind: 'class',
    interfaces: []
  };
  var Cursor_DEFAULT_instance;
  var Cursor_AUTO_instance;
  var Cursor_NONE_instance;
  var Cursor_ALIAS_instance;
  var Cursor_ALLSCROLL_instance;
  var Cursor_CELL_instance;
  var Cursor_CONTEXTMENU_instance;
  var Cursor_COLRESIZE_instance;
  var Cursor_COPY_instance;
  var Cursor_CROSSHAIR_instance;
  var Cursor_ERESIZE_instance;
  var Cursor_EWRESIZE_instance;
  var Cursor_GRAB_instance;
  var Cursor_GRABBING_instance;
  var Cursor_HELP_instance;
  var Cursor_MOVE_instance;
  var Cursor_NRESIZE_instance;
  var Cursor_NERESIZE_instance;
  var Cursor_NESWRESIZE_instance;
  var Cursor_NSRESIZE_instance;
  var Cursor_NWRESIZE_instance;
  var Cursor_NWSERESIZE_instance;
  var Cursor_NODROP_instance;
  var Cursor_NOTALLOWED_instance;
  var Cursor_POINTER_instance;
  var Cursor_PROGRESS_instance;
  var Cursor_ROWRESIZE_instance;
  var Cursor_SRESIZE_instance;
  var Cursor_SERESIZE_instance;
  var Cursor_SWRESIZE_instance;
  var Cursor_TEXT_instance;
  var Cursor_VERTICALTEXT_instance;
  var Cursor_WRESIZE_instance;
  var Cursor_WAIT_instance;
  var Cursor_ZOOMIN_instance;
  var Cursor_ZOOMOUT_instance;
  var Cursor_INITIAL_instance;
  var Cursor_INHERIT_instance;
  var Cursor_entriesInitialized;
  function Cursor_initEntries() {
    if (Cursor_entriesInitialized)
      return Unit_getInstance();
    Cursor_entriesInitialized = true;
    Cursor_DEFAULT_instance = new Cursor('DEFAULT', 0, 'default');
    Cursor_AUTO_instance = new Cursor('AUTO', 1, 'auto');
    Cursor_NONE_instance = new Cursor('NONE', 2, 'none');
    Cursor_ALIAS_instance = new Cursor('ALIAS', 3, 'alias');
    Cursor_ALLSCROLL_instance = new Cursor('ALLSCROLL', 4, 'all-scroll');
    Cursor_CELL_instance = new Cursor('CELL', 5, 'cell');
    Cursor_CONTEXTMENU_instance = new Cursor('CONTEXTMENU', 6, 'context-menu');
    Cursor_COLRESIZE_instance = new Cursor('COLRESIZE', 7, 'col-resize');
    Cursor_COPY_instance = new Cursor('COPY', 8, 'copy');
    Cursor_CROSSHAIR_instance = new Cursor('CROSSHAIR', 9, 'crosshair');
    Cursor_ERESIZE_instance = new Cursor('ERESIZE', 10, 'e-resize');
    Cursor_EWRESIZE_instance = new Cursor('EWRESIZE', 11, 'ew-resize');
    Cursor_GRAB_instance = new Cursor('GRAB', 12, 'grab');
    Cursor_GRABBING_instance = new Cursor('GRABBING', 13, 'grabbing');
    Cursor_HELP_instance = new Cursor('HELP', 14, 'help');
    Cursor_MOVE_instance = new Cursor('MOVE', 15, 'move');
    Cursor_NRESIZE_instance = new Cursor('NRESIZE', 16, 'n-resize');
    Cursor_NERESIZE_instance = new Cursor('NERESIZE', 17, 'ne-resize');
    Cursor_NESWRESIZE_instance = new Cursor('NESWRESIZE', 18, 'nesw-resize');
    Cursor_NSRESIZE_instance = new Cursor('NSRESIZE', 19, 'ns-resize');
    Cursor_NWRESIZE_instance = new Cursor('NWRESIZE', 20, 'nw-resize');
    Cursor_NWSERESIZE_instance = new Cursor('NWSERESIZE', 21, 'nwse-resize');
    Cursor_NODROP_instance = new Cursor('NODROP', 22, 'no-drop');
    Cursor_NOTALLOWED_instance = new Cursor('NOTALLOWED', 23, 'not-allowed');
    Cursor_POINTER_instance = new Cursor('POINTER', 24, 'pointer');
    Cursor_PROGRESS_instance = new Cursor('PROGRESS', 25, 'progress');
    Cursor_ROWRESIZE_instance = new Cursor('ROWRESIZE', 26, 'row-resize');
    Cursor_SRESIZE_instance = new Cursor('SRESIZE', 27, 's-resize');
    Cursor_SERESIZE_instance = new Cursor('SERESIZE', 28, 'se-resize');
    Cursor_SWRESIZE_instance = new Cursor('SWRESIZE', 29, 'sw-resize');
    Cursor_TEXT_instance = new Cursor('TEXT', 30, 'text');
    Cursor_VERTICALTEXT_instance = new Cursor('VERTICALTEXT', 31, 'vertical-text');
    Cursor_WRESIZE_instance = new Cursor('WRESIZE', 32, 'w-resize');
    Cursor_WAIT_instance = new Cursor('WAIT', 33, 'wait');
    Cursor_ZOOMIN_instance = new Cursor('ZOOMIN', 34, 'zoom-in');
    Cursor_ZOOMOUT_instance = new Cursor('ZOOMOUT', 35, 'zoom-out');
    Cursor_INITIAL_instance = new Cursor('INITIAL', 36, 'initial');
    Cursor_INHERIT_instance = new Cursor('INHERIT', 37, 'inherit');
  }
  function Cursor(name, ordinal, cursor) {
    Enum.call(this, name, ordinal);
    this._cursor = cursor;
  }
  Cursor.$metadata$ = {
    simpleName: 'Cursor',
    kind: 'class',
    interfaces: []
  };
  var Display_INLINE_instance;
  var Display_BLOCK_instance;
  var Display_FLEX_instance;
  var Display_GRID_instance;
  var Display_INLINEBLOCK_instance;
  var Display_INLINEFLEX_instance;
  var Display_INLINEGRID_instance;
  var Display_INLINETABLE_instance;
  var Display_LISTITEM_instance;
  var Display_RUNIN_instance;
  var Display_TABLE_instance;
  var Display_TABLECAPTION_instance;
  var Display_TABLECOLUMNGROUP_instance;
  var Display_TABLEHEADERGROUP_instance;
  var Display_TABLEFOOTERGROUP_instance;
  var Display_TABLEROWGROUP_instance;
  var Display_TABLECELL_instance;
  var Display_TABLECOLUMN_instance;
  var Display_TABLEROW_instance;
  var Display_CONTENTS_instance;
  var Display_NONE_instance;
  var Display_INITIAL_instance;
  var Display_INHERIT_instance;
  var Display_entriesInitialized;
  function Display_initEntries() {
    if (Display_entriesInitialized)
      return Unit_getInstance();
    Display_entriesInitialized = true;
    Display_INLINE_instance = new Display('INLINE', 0, 'inline');
    Display_BLOCK_instance = new Display('BLOCK', 1, 'block');
    Display_FLEX_instance = new Display('FLEX', 2, 'flex');
    Display_GRID_instance = new Display('GRID', 3, 'grid');
    Display_INLINEBLOCK_instance = new Display('INLINEBLOCK', 4, 'inline-block');
    Display_INLINEFLEX_instance = new Display('INLINEFLEX', 5, 'inline-flex');
    Display_INLINEGRID_instance = new Display('INLINEGRID', 6, 'inline-grid');
    Display_INLINETABLE_instance = new Display('INLINETABLE', 7, 'inline-table');
    Display_LISTITEM_instance = new Display('LISTITEM', 8, 'list-item');
    Display_RUNIN_instance = new Display('RUNIN', 9, 'run-in');
    Display_TABLE_instance = new Display('TABLE', 10, 'table');
    Display_TABLECAPTION_instance = new Display('TABLECAPTION', 11, 'table-caption');
    Display_TABLECOLUMNGROUP_instance = new Display('TABLECOLUMNGROUP', 12, 'table-column-group');
    Display_TABLEHEADERGROUP_instance = new Display('TABLEHEADERGROUP', 13, 'table-header-group');
    Display_TABLEFOOTERGROUP_instance = new Display('TABLEFOOTERGROUP', 14, 'table-footer-group');
    Display_TABLEROWGROUP_instance = new Display('TABLEROWGROUP', 15, 'table-row-group');
    Display_TABLECELL_instance = new Display('TABLECELL', 16, 'table-cell');
    Display_TABLECOLUMN_instance = new Display('TABLECOLUMN', 17, 'table-column');
    Display_TABLEROW_instance = new Display('TABLEROW', 18, 'table-row');
    Display_CONTENTS_instance = new Display('CONTENTS', 19, 'contents');
    Display_NONE_instance = new Display('NONE', 20, 'none');
    Display_INITIAL_instance = new Display('INITIAL', 21, 'initial');
    Display_INHERIT_instance = new Display('INHERIT', 22, 'inherit');
  }
  function Display(name, ordinal, display) {
    Enum.call(this, name, ordinal);
    this._display = display;
  }
  Display.$metadata$ = {
    simpleName: 'Display',
    kind: 'class',
    interfaces: []
  };
  function FlexWrap() {
  }
  FlexWrap.$metadata$ = {
    simpleName: 'FlexWrap',
    kind: 'class',
    interfaces: []
  };
  function Position() {
  }
  Position.$metadata$ = {
    simpleName: 'Position',
    kind: 'class',
    interfaces: []
  };
  function Overflow() {
  }
  Overflow.$metadata$ = {
    simpleName: 'Overflow',
    kind: 'class',
    interfaces: []
  };
  function OverflowWrap() {
  }
  OverflowWrap.$metadata$ = {
    simpleName: 'OverflowWrap',
    kind: 'class',
    interfaces: []
  };
  function Resize() {
  }
  Resize.$metadata$ = {
    simpleName: 'Resize',
    kind: 'class',
    interfaces: []
  };
  function Border() {
  }
  Border.prototype.asString_3 = function () {
    var tmp0_safe_receiver = this._width;
    var w = tmp0_safe_receiver == null ? null : asString(tmp0_safe_receiver);
    var tmp0_elvis_lhs_1 = w;
    var tmp = (tmp0_elvis_lhs_1 == null ? '' : tmp0_elvis_lhs_1) + ' ';
    var tmp2_safe_receiver = this._style;
    var tmp0_orEmpty_0 = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.borderStyle__error;
    var tmp0_elvis_lhs_1_0 = tmp0_orEmpty_0;
    var tmp_0 = tmp + (tmp0_elvis_lhs_1_0 == null ? '' : tmp0_elvis_lhs_1_0) + ' ';
    var tmp1_safe_receiver = this._color;
    var tmp1_orEmpty_0 = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.asString_3();
    var tmp0_elvis_lhs_1_1 = tmp1_orEmpty_0;
    return tmp_0 + (tmp0_elvis_lhs_1_1 == null ? '' : tmp0_elvis_lhs_1_1);
  };
  Border.$metadata$ = {
    simpleName: 'Border',
    kind: 'class',
    interfaces: []
  };
  function Color() {
  }
  Color.prototype.asString_3 = function () {
    var tmp0_orEmpty_0 = this._color_0;
    var tmp0_elvis_lhs_1 = tmp0_orEmpty_0;
    return tmp0_elvis_lhs_1 == null ? '' : tmp0_elvis_lhs_1;
  };
  Color.$metadata$ = {
    simpleName: 'Color',
    kind: 'class',
    interfaces: []
  };
  function Background() {
  }
  Background.prototype.asString_3 = function () {
    var tmp0_safe_receiver = this._image;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = '' + 'url(' + this._image + ')';
    }
    var img = tmp;
    var tmp1_safe_receiver = this._positionX;
    var posX = tmp1_safe_receiver == null ? null : asString(tmp1_safe_receiver);
    var tmp2_safe_receiver = this._positionY;
    var posY = tmp2_safe_receiver == null ? null : asString(tmp2_safe_receiver);
    var tmp3_safe_receiver = this._sizeX;
    var sX = tmp3_safe_receiver == null ? null : asString(tmp3_safe_receiver);
    var tmp4_safe_receiver = this._sizeY;
    var sY = tmp4_safe_receiver == null ? null : asString(tmp4_safe_receiver);
    var tmp10_safe_receiver = this._color_1;
    var tmp0_orEmpty_0 = tmp10_safe_receiver == null ? null : tmp10_safe_receiver.asString_3();
    var tmp0_elvis_lhs_1 = tmp0_orEmpty_0;
    var tmp_0 = (tmp0_elvis_lhs_1 == null ? '' : tmp0_elvis_lhs_1) + ' ';
    var tmp0_elvis_lhs_1_0 = img;
    var tmp_1 = tmp_0 + (tmp0_elvis_lhs_1_0 == null ? '' : tmp0_elvis_lhs_1_0) + ' ';
    var tmp0_elvis_lhs_1_1 = posX;
    var tmp_2 = tmp_1 + (tmp0_elvis_lhs_1_1 == null ? '' : tmp0_elvis_lhs_1_1) + ' ';
    var tmp0_elvis_lhs_1_2 = posY;
    var tmp_3 = tmp_2 + (tmp0_elvis_lhs_1_2 == null ? '' : tmp0_elvis_lhs_1_2);
    var tmp_4;
    if ((!(sX == null) ? true : !(sY == null)) ? true : !(this._size_1 == null)) {
      var tmp_5 = (!(posX == null) ? true : !(posY == null)) ? ' / ' : ' 0px 0px / ';
      var tmp0_elvis_lhs_1_3 = sX;
      var tmp_6 = tmp_5 + (tmp0_elvis_lhs_1_3 == null ? '' : tmp0_elvis_lhs_1_3) + ' ';
      var tmp0_elvis_lhs_1_4 = sY;
      var tmp_7 = tmp_6 + (tmp0_elvis_lhs_1_4 == null ? '' : tmp0_elvis_lhs_1_4) + ' ';
      var tmp9_safe_receiver = this._size_1;
      var tmp1_orEmpty_0 = tmp9_safe_receiver == null ? null : tmp9_safe_receiver.size__error;
      var tmp0_elvis_lhs_1_5 = tmp1_orEmpty_0;
      tmp_4 = tmp_7 + (tmp0_elvis_lhs_1_5 == null ? '' : tmp0_elvis_lhs_1_5);
    } else {
      tmp_4 = '';
    }
    var tmp_8 = tmp_3 + tmp_4 + ' ';
    var tmp8_safe_receiver = this._repeat;
    var tmp2_orEmpty_0 = tmp8_safe_receiver == null ? null : tmp8_safe_receiver.repeat__error;
    var tmp0_elvis_lhs_1_6 = tmp2_orEmpty_0;
    var tmp_9 = tmp_8 + (tmp0_elvis_lhs_1_6 == null ? '' : tmp0_elvis_lhs_1_6) + ' ';
    var tmp7_safe_receiver = this._origin;
    var tmp3_orEmpty_0 = tmp7_safe_receiver == null ? null : tmp7_safe_receiver.origin__error;
    var tmp0_elvis_lhs_1_7 = tmp3_orEmpty_0;
    var tmp_10 = tmp_9 + (tmp0_elvis_lhs_1_7 == null ? '' : tmp0_elvis_lhs_1_7) + ' ';
    var tmp6_safe_receiver = this._clip;
    var tmp4_orEmpty_0 = tmp6_safe_receiver == null ? null : tmp6_safe_receiver.clip__error;
    var tmp0_elvis_lhs_1_8 = tmp4_orEmpty_0;
    var tmp_11 = tmp_10 + (tmp0_elvis_lhs_1_8 == null ? '' : tmp0_elvis_lhs_1_8) + ' ';
    var tmp5_safe_receiver = this._attachment;
    var tmp5_orEmpty_0 = tmp5_safe_receiver == null ? null : tmp5_safe_receiver.attachment__error;
    var tmp0_elvis_lhs_1_9 = tmp5_orEmpty_0;
    return tmp_11 + (tmp0_elvis_lhs_1_9 == null ? '' : tmp0_elvis_lhs_1_9);
  };
  Background.$metadata$ = {
    simpleName: 'Background',
    kind: 'class',
    interfaces: []
  };
  function Direction() {
  }
  Direction.$metadata$ = {
    simpleName: 'Direction',
    kind: 'class',
    interfaces: []
  };
  function TextAlign() {
  }
  TextAlign.$metadata$ = {
    simpleName: 'TextAlign',
    kind: 'class',
    interfaces: []
  };
  function TextDecoration() {
  }
  TextDecoration.prototype.asString_3 = function () {
    var tmp2_safe_receiver = this._line;
    var tmp0_orEmpty_0 = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.textDecorationLine__error;
    var tmp0_elvis_lhs_1 = tmp0_orEmpty_0;
    var tmp = (tmp0_elvis_lhs_1 == null ? '' : tmp0_elvis_lhs_1) + ' ';
    var tmp1_safe_receiver = this._style_0;
    var tmp1_orEmpty_0 = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.textDecorationStyle__error;
    var tmp0_elvis_lhs_1_0 = tmp1_orEmpty_0;
    var tmp_0 = tmp + (tmp0_elvis_lhs_1_0 == null ? '' : tmp0_elvis_lhs_1_0) + ' ';
    var tmp0_safe_receiver = this._color_2;
    var tmp2_orEmpty_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.asString_3();
    var tmp0_elvis_lhs_1_1 = tmp2_orEmpty_0;
    return tmp_0 + (tmp0_elvis_lhs_1_1 == null ? '' : tmp0_elvis_lhs_1_1);
  };
  TextDecoration.$metadata$ = {
    simpleName: 'TextDecoration',
    kind: 'class',
    interfaces: []
  };
  function TextShadow() {
  }
  TextShadow.prototype.asString_3 = function () {
    var tmp3_safe_receiver = this._hShadow;
    var tmp0_orEmpty_0 = tmp3_safe_receiver == null ? null : asString(tmp3_safe_receiver);
    var tmp0_elvis_lhs_1 = tmp0_orEmpty_0;
    var tmp = (tmp0_elvis_lhs_1 == null ? '' : tmp0_elvis_lhs_1) + ' ';
    var tmp2_safe_receiver = this._vShadow;
    var tmp1_orEmpty_0 = tmp2_safe_receiver == null ? null : asString(tmp2_safe_receiver);
    var tmp0_elvis_lhs_1_0 = tmp1_orEmpty_0;
    var tmp_0 = tmp + (tmp0_elvis_lhs_1_0 == null ? '' : tmp0_elvis_lhs_1_0) + ' ';
    var tmp1_safe_receiver = this._blurRadius;
    var tmp2_orEmpty_0 = tmp1_safe_receiver == null ? null : asString(tmp1_safe_receiver);
    var tmp0_elvis_lhs_1_1 = tmp2_orEmpty_0;
    var tmp_1 = tmp_0 + (tmp0_elvis_lhs_1_1 == null ? '' : tmp0_elvis_lhs_1_1) + ' ';
    var tmp0_safe_receiver = this._color_3;
    var tmp3_orEmpty_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.asString_3();
    var tmp0_elvis_lhs_1_2 = tmp3_orEmpty_0;
    return tmp_1 + (tmp0_elvis_lhs_1_2 == null ? '' : tmp0_elvis_lhs_1_2);
  };
  TextShadow.$metadata$ = {
    simpleName: 'TextShadow',
    kind: 'class',
    interfaces: []
  };
  function TextTransform() {
  }
  TextTransform.$metadata$ = {
    simpleName: 'TextTransform',
    kind: 'class',
    interfaces: []
  };
  function TextOverflow() {
  }
  TextOverflow.$metadata$ = {
    simpleName: 'TextOverflow',
    kind: 'class',
    interfaces: []
  };
  function UnicodeBidi() {
  }
  UnicodeBidi.$metadata$ = {
    simpleName: 'UnicodeBidi',
    kind: 'class',
    interfaces: []
  };
  function VerticalAlign() {
  }
  VerticalAlign.$metadata$ = {
    simpleName: 'VerticalAlign',
    kind: 'class',
    interfaces: []
  };
  function WhiteSpace() {
  }
  WhiteSpace.$metadata$ = {
    simpleName: 'WhiteSpace',
    kind: 'class',
    interfaces: []
  };
  function FontStyle() {
  }
  FontStyle.$metadata$ = {
    simpleName: 'FontStyle',
    kind: 'class',
    interfaces: []
  };
  function FontWeight() {
  }
  FontWeight.$metadata$ = {
    simpleName: 'FontWeight',
    kind: 'class',
    interfaces: []
  };
  function FontVariant() {
  }
  FontVariant.$metadata$ = {
    simpleName: 'FontVariant',
    kind: 'class',
    interfaces: []
  };
  function PosFloat() {
  }
  PosFloat.$metadata$ = {
    simpleName: 'PosFloat',
    kind: 'class',
    interfaces: []
  };
  function Clear() {
  }
  Clear.$metadata$ = {
    simpleName: 'Clear',
    kind: 'class',
    interfaces: []
  };
  function WordBreak() {
  }
  WordBreak.$metadata$ = {
    simpleName: 'WordBreak',
    kind: 'class',
    interfaces: []
  };
  function LineBreak() {
  }
  LineBreak.$metadata$ = {
    simpleName: 'LineBreak',
    kind: 'class',
    interfaces: []
  };
  var FlexDirection_ROW_instance;
  var FlexDirection_ROWREV_instance;
  var FlexDirection_COLUMN_instance;
  var FlexDirection_COLUMNREV_instance;
  var FlexDirection_entriesInitialized;
  function FlexDirection_initEntries() {
    if (FlexDirection_entriesInitialized)
      return Unit_getInstance();
    FlexDirection_entriesInitialized = true;
    FlexDirection_ROW_instance = new FlexDirection('ROW', 0, 'row');
    FlexDirection_ROWREV_instance = new FlexDirection('ROWREV', 1, 'row-reverse');
    FlexDirection_COLUMN_instance = new FlexDirection('COLUMN', 2, 'column');
    FlexDirection_COLUMNREV_instance = new FlexDirection('COLUMNREV', 3, 'column-reverse');
  }
  function FlexDirection(name, ordinal, dir) {
    Enum.call(this, name, ordinal);
    this._dir = dir;
  }
  FlexDirection.$metadata$ = {
    simpleName: 'FlexDirection',
    kind: 'class',
    interfaces: []
  };
  function JustifyItems() {
  }
  JustifyItems.$metadata$ = {
    simpleName: 'JustifyItems',
    kind: 'class',
    interfaces: []
  };
  function AlignContent() {
  }
  AlignContent.$metadata$ = {
    simpleName: 'AlignContent',
    kind: 'class',
    interfaces: []
  };
  function GridAutoFlow() {
  }
  GridAutoFlow.$metadata$ = {
    simpleName: 'GridAutoFlow',
    kind: 'class',
    interfaces: []
  };
  function UNIT_px_getInstance() {
    UNIT_initEntries();
    return UNIT_px_instance;
  }
  function UNIT_auto_getInstance() {
    UNIT_initEntries();
    return UNIT_auto_instance;
  }
  function UNIT_normal_getInstance() {
    UNIT_initEntries();
    return UNIT_normal_instance;
  }
  function JustifyContent_FLEXEND_getInstance() {
    JustifyContent_initEntries();
    return JustifyContent_FLEXEND_instance;
  }
  function JustifyContent_SPACEBETWEEN_getInstance() {
    JustifyContent_initEntries();
    return JustifyContent_SPACEBETWEEN_instance;
  }
  function AlignItems_CENTER_getInstance() {
    AlignItems_initEntries();
    return AlignItems_CENTER_instance;
  }
  function Cursor_POINTER_getInstance() {
    Cursor_initEntries();
    return Cursor_POINTER_instance;
  }
  function Display_FLEX_getInstance() {
    Display_initEntries();
    return Display_FLEX_instance;
  }
  function Display_NONE_getInstance() {
    Display_initEntries();
    return Display_NONE_instance;
  }
  function FlexDirection_ROWREV_getInstance() {
    FlexDirection_initEntries();
    return FlexDirection_ROWREV_instance;
  }
  function FlexDirection_COLUMN_getInstance() {
    FlexDirection_initEntries();
    return FlexDirection_COLUMN_instance;
  }
  function FlexDirection_COLUMNREV_getInstance() {
    FlexDirection_initEntries();
    return FlexDirection_COLUMNREV_instance;
  }
  function CssClass() {
  }
  CssClass.$metadata$ = {
    simpleName: 'CssClass',
    kind: 'interface',
    interfaces: []
  };
  function TooltipOptions_init_$Init$(title, rich, animation, delay, hideDelay, placement, triggers, sanitize, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      title = null;
    if (!(($mask0 & 2) === 0))
      rich = null;
    if (!(($mask0 & 4) === 0))
      animation = null;
    if (!(($mask0 & 8) === 0))
      delay = null;
    if (!(($mask0 & 16) === 0))
      hideDelay = null;
    if (!(($mask0 & 32) === 0))
      placement = null;
    if (!(($mask0 & 64) === 0))
      triggers = null;
    if (!(($mask0 & 128) === 0))
      sanitize = null;
    TooltipOptions.call($this, title, rich, animation, delay, hideDelay, placement, triggers, sanitize);
    return $this;
  }
  function TooltipOptions_init_$Create$(title, rich, animation, delay, hideDelay, placement, triggers, sanitize, $mask0, $marker) {
    return TooltipOptions_init_$Init$(title, rich, animation, delay, hideDelay, placement, triggers, sanitize, $mask0, $marker, Object.create(TooltipOptions.prototype));
  }
  function TooltipOptions(title, rich, animation, delay, hideDelay, placement, triggers, sanitize) {
    this._title = title;
    this._rich = rich;
    this._animation = animation;
    this._delay = delay;
    this._hideDelay = hideDelay;
    this._placement = placement;
    this._triggers = triggers;
    this._sanitize = sanitize;
  }
  TooltipOptions.prototype.copy_0 = function (title, rich, animation, delay, hideDelay, placement, triggers, sanitize) {
    return new TooltipOptions(title, rich, animation, delay, hideDelay, placement, triggers, sanitize);
  };
  TooltipOptions.prototype.copy$default = function (title, rich, animation, delay, hideDelay, placement, triggers, sanitize, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      title = this._title;
    if (!(($mask0 & 2) === 0))
      rich = this._rich;
    if (!(($mask0 & 4) === 0))
      animation = this._animation;
    if (!(($mask0 & 8) === 0))
      delay = this._delay;
    if (!(($mask0 & 16) === 0))
      hideDelay = this._hideDelay;
    if (!(($mask0 & 32) === 0))
      placement = this._placement;
    if (!(($mask0 & 64) === 0))
      triggers = this._triggers;
    if (!(($mask0 & 128) === 0))
      sanitize = this._sanitize;
    return this.copy_0(title, rich, animation, delay, hideDelay, placement, triggers, sanitize);
  };
  TooltipOptions.prototype.toString = function () {
    return '' + 'TooltipOptions(title=' + this._title + ', rich=' + this._rich + ', animation=' + this._animation + ', delay=' + this._delay + ', hideDelay=' + this._hideDelay + ', placement=' + this._placement + ', triggers=' + this._triggers + ', sanitize=' + this._sanitize + ')';
  };
  TooltipOptions.prototype.hashCode = function () {
    var result = this._title == null ? 0 : getStringHashCode(this._title);
    result = imul(result, 31) + (this._rich == null ? 0 : this._rich | 0) | 0;
    result = imul(result, 31) + (this._animation == null ? 0 : this._animation | 0) | 0;
    result = imul(result, 31) + (this._delay == null ? 0 : this._delay) | 0;
    result = imul(result, 31) + (this._hideDelay == null ? 0 : this._hideDelay) | 0;
    result = imul(result, 31) + (this._placement == null ? 0 : this._placement.hashCode()) | 0;
    result = imul(result, 31) + (this._triggers == null ? 0 : hashCode(this._triggers)) | 0;
    result = imul(result, 31) + (this._sanitize == null ? 0 : this._sanitize | 0) | 0;
    return result;
  };
  TooltipOptions.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TooltipOptions))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof TooltipOptions ? other : THROW_CCE();
    if (!(this._title == tmp0_other_with_cast._title))
      return false;
    if (!(this._rich == tmp0_other_with_cast._rich))
      return false;
    if (!(this._animation == tmp0_other_with_cast._animation))
      return false;
    if (!(this._delay == tmp0_other_with_cast._delay))
      return false;
    if (!(this._hideDelay == tmp0_other_with_cast._hideDelay))
      return false;
    if (!equals_0(this._placement, tmp0_other_with_cast._placement))
      return false;
    if (!equals_0(this._triggers, tmp0_other_with_cast._triggers))
      return false;
    if (!(this._sanitize == tmp0_other_with_cast._sanitize))
      return false;
    return true;
  };
  TooltipOptions.$metadata$ = {
    simpleName: 'TooltipOptions',
    kind: 'class',
    interfaces: []
  };
  function PopoverOptions(content, title, rich, animation, delay, hideDelay, placement, triggers, sanitize) {
    this._content = content;
    this._title_0 = title;
    this._rich_0 = rich;
    this._animation_0 = animation;
    this._delay_0 = delay;
    this._hideDelay_0 = hideDelay;
    this._placement_0 = placement;
    this._triggers_0 = triggers;
    this._sanitize_0 = sanitize;
  }
  PopoverOptions.prototype.copy_1 = function (content, title, rich, animation, delay, hideDelay, placement, triggers, sanitize) {
    return new PopoverOptions(content, title, rich, animation, delay, hideDelay, placement, triggers, sanitize);
  };
  PopoverOptions.prototype.copy$default_0 = function (content, title, rich, animation, delay, hideDelay, placement, triggers, sanitize, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      content = this._content;
    if (!(($mask0 & 2) === 0))
      title = this._title_0;
    if (!(($mask0 & 4) === 0))
      rich = this._rich_0;
    if (!(($mask0 & 8) === 0))
      animation = this._animation_0;
    if (!(($mask0 & 16) === 0))
      delay = this._delay_0;
    if (!(($mask0 & 32) === 0))
      hideDelay = this._hideDelay_0;
    if (!(($mask0 & 64) === 0))
      placement = this._placement_0;
    if (!(($mask0 & 128) === 0))
      triggers = this._triggers_0;
    if (!(($mask0 & 256) === 0))
      sanitize = this._sanitize_0;
    return this.copy_1(content, title, rich, animation, delay, hideDelay, placement, triggers, sanitize);
  };
  PopoverOptions.prototype.toString = function () {
    return '' + 'PopoverOptions(content=' + this._content + ', title=' + this._title_0 + ', rich=' + this._rich_0 + ', animation=' + this._animation_0 + ', delay=' + this._delay_0 + ', hideDelay=' + this._hideDelay_0 + ', placement=' + this._placement_0 + ', triggers=' + this._triggers_0 + ', sanitize=' + this._sanitize_0 + ')';
  };
  PopoverOptions.prototype.hashCode = function () {
    var result = this._content == null ? 0 : getStringHashCode(this._content);
    result = imul(result, 31) + (this._title_0 == null ? 0 : getStringHashCode(this._title_0)) | 0;
    result = imul(result, 31) + (this._rich_0 == null ? 0 : this._rich_0 | 0) | 0;
    result = imul(result, 31) + (this._animation_0 == null ? 0 : this._animation_0 | 0) | 0;
    result = imul(result, 31) + (this._delay_0 == null ? 0 : this._delay_0) | 0;
    result = imul(result, 31) + (this._hideDelay_0 == null ? 0 : this._hideDelay_0) | 0;
    result = imul(result, 31) + (this._placement_0 == null ? 0 : this._placement_0.hashCode()) | 0;
    result = imul(result, 31) + (this._triggers_0 == null ? 0 : hashCode(this._triggers_0)) | 0;
    result = imul(result, 31) + (this._sanitize_0 == null ? 0 : this._sanitize_0 | 0) | 0;
    return result;
  };
  PopoverOptions.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PopoverOptions))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof PopoverOptions ? other : THROW_CCE();
    if (!(this._content == tmp0_other_with_cast._content))
      return false;
    if (!(this._title_0 == tmp0_other_with_cast._title_0))
      return false;
    if (!(this._rich_0 == tmp0_other_with_cast._rich_0))
      return false;
    if (!(this._animation_0 == tmp0_other_with_cast._animation_0))
      return false;
    if (!(this._delay_0 == tmp0_other_with_cast._delay_0))
      return false;
    if (!(this._hideDelay_0 == tmp0_other_with_cast._hideDelay_0))
      return false;
    if (!equals_0(this._placement_0, tmp0_other_with_cast._placement_0))
      return false;
    if (!equals_0(this._triggers_0, tmp0_other_with_cast._triggers_0))
      return false;
    if (!(this._sanitize_0 == tmp0_other_with_cast._sanitize_0))
      return false;
    return true;
  };
  PopoverOptions.$metadata$ = {
    simpleName: 'PopoverOptions',
    kind: 'class',
    interfaces: []
  };
  function toJs(_this_) {
    var tmp0_safe_receiver = _this_._triggers;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = joinToString$default_0(tmp0_safe_receiver, ' ', null, null, 0, null, _no_name_provided_$factory_28(), 30, null);
    }
    var trigger = tmp;
    var tmp0_apply_0 = new Object();
    if (!(_this_._title == null))
      tmp0_apply_0.title = _this_._title;
    if (!(_this_._rich == null))
      tmp0_apply_0.html = _this_._rich;
    if (!(_this_._animation == null))
      tmp0_apply_0.animation = _this_._animation;
    if (!(_this_._delay == null) ? !(_this_._hideDelay == null) : false) {
      var tmp0_apply_0_2 = new Object();
      tmp0_apply_0_2.show = _this_._delay;
      tmp0_apply_0_2.hide = _this_._hideDelay;
      tmp0_apply_0.delay = tmp0_apply_0_2;
    } else if (!(_this_._delay == null)) {
      tmp0_apply_0.delay = _this_._delay;
    } else if (!(_this_._hideDelay == null)) {
      var tmp1_apply_0_3 = new Object();
      tmp1_apply_0_3.show = 0;
      tmp1_apply_0_3.hide = _this_._hideDelay;
      tmp0_apply_0.delay = tmp1_apply_0_3;
    }if (!(_this_._placement == null))
      tmp0_apply_0.placement = _this_._placement.placement__error;
    if (!(trigger == null))
      tmp0_apply_0.trigger = trigger;
    if (!(_this_._sanitize == null))
      tmp0_apply_0.sanitize = _this_._sanitize;
    return tmp0_apply_0;
  }
  function toJs_0(_this_) {
    var tmp0_safe_receiver = _this_._triggers_0;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = joinToString$default_0(tmp0_safe_receiver, ' ', null, null, 0, null, _no_name_provided_$factory_29(), 30, null);
    }
    var trigger = tmp;
    var tmp0_apply_0 = new Object();
    if (!(_this_._content == null))
      tmp0_apply_0.content = _this_._content;
    if (!(_this_._title_0 == null))
      tmp0_apply_0.title = _this_._title_0;
    if (!(_this_._rich_0 == null))
      tmp0_apply_0.html = _this_._rich_0;
    if (!(_this_._animation_0 == null))
      tmp0_apply_0.animation = _this_._animation_0;
    if (!(_this_._delay_0 == null) ? !(_this_._hideDelay_0 == null) : false) {
      var tmp0_apply_0_2 = new Object();
      tmp0_apply_0_2.show = _this_._delay_0;
      tmp0_apply_0_2.hide = _this_._hideDelay_0;
      tmp0_apply_0.delay = tmp0_apply_0_2;
    } else if (!(_this_._delay_0 == null)) {
      tmp0_apply_0.delay = _this_._delay_0;
    } else if (!(_this_._hideDelay_0 == null)) {
      var tmp1_apply_0_3 = new Object();
      tmp1_apply_0_3.show = 0;
      tmp1_apply_0_3.hide = _this_._hideDelay_0;
      tmp0_apply_0.delay = tmp1_apply_0_3;
    }if (!(_this_._placement_0 == null))
      tmp0_apply_0.placement = _this_._placement_0.placement__error;
    if (!(trigger == null))
      tmp0_apply_0.trigger = trigger;
    if (!(_this_._sanitize_0 == null))
      tmp0_apply_0.sanitize = _this_._sanitize_0;
    return tmp0_apply_0;
  }
  function Trigger() {
  }
  Trigger.$metadata$ = {
    simpleName: 'Trigger',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__37() {
  }
  _no_name_provided__37.prototype.invoke_59 = function (it) {
    return it._trigger;
  };
  _no_name_provided__37.prototype.invoke_1082 = function (p1) {
    return this.invoke_59(p1 instanceof Trigger ? p1 : THROW_CCE());
  };
  _no_name_provided__37.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__38() {
  }
  _no_name_provided__38.prototype.invoke_59 = function (it) {
    return it._trigger;
  };
  _no_name_provided__38.prototype.invoke_1082 = function (p1) {
    return this.invoke_59(p1 instanceof Trigger ? p1 : THROW_CCE());
  };
  _no_name_provided__38.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_28() {
    var i = new _no_name_provided__37();
    return function (p1) {
      return i.invoke_59(p1);
    };
  }
  function _no_name_provided_$factory_29() {
    var i = new _no_name_provided__38();
    return function (p1) {
      return i.invoke_59(p1);
    };
  }
  function SingleObjectCache() {
  }
  SingleObjectCache.prototype.clearOn_0 = function (shouldClear) {
    return new AutoClearCache(this, shouldClear);
  };
  SingleObjectCache.$metadata$ = {
    simpleName: 'SingleObjectCache',
    kind: 'interface',
    interfaces: []
  };
  function LazyCache(initializer) {
    this._initializer_0 = initializer;
    this.__value_1 = null;
  }
  LazyCache.prototype._get_value__18 = function () {
    var tmp0_elvis_lhs = this.__value_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_also_0 = this._initializer_0();
      this.__value_1 = tmp0_also_0;
      tmp = tmp0_also_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  LazyCache.prototype.clear_27 = function () {
    this.__value_1 = null;
  };
  LazyCache.$metadata$ = {
    simpleName: 'LazyCache',
    kind: 'class',
    interfaces: [SingleObjectCache]
  };
  function AutoClearCache(delegate_0, shouldClear) {
    this._delegate = delegate_0;
    this._shouldClear = shouldClear;
  }
  AutoClearCache.prototype.clear_27 = function () {
    this._delegate.clear_27();
  };
  AutoClearCache.prototype._get_value__18 = function () {
    if (this._shouldClear()) {
      this._delegate.clear_27();
    }return this._delegate._get_value__18();
  };
  AutoClearCache.$metadata$ = {
    simpleName: 'AutoClearCache',
    kind: 'class',
    interfaces: [SingleObjectCache]
  };
  function Companion_11() {
    Companion_instance_10 = this;
    this._counter_0 = 0;
    var tmp = this;
    tmp._styles = ArrayList_init_$Create$();
  }
  Companion_11.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_10;
  function Companion_getInstance_10() {
    if (Companion_instance_10 == null)
      new Companion_11();
    return Companion_instance_10;
  }
  function _no_name_provided__39() {
  }
  _no_name_provided__39.prototype.invoke_61 = function (it) {
    return '' + it._first + ': ' + it._second + ';';
  };
  _no_name_provided__39.prototype.invoke_1082 = function (p1) {
    return this.invoke_61(p1 instanceof Pair ? p1 : THROW_CCE());
  };
  _no_name_provided__39.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Style() {
  }
  Style.prototype._set_className_ = function (_set___) {
    return this._className$delegate.setValue_6(this, className$factory(), _set___);
  };
  Style.prototype._get_className__3 = function () {
    return this._className$delegate.getValue_3(this, className$factory_0());
  };
  Style.prototype.generateStyle = function () {
    var styles = this.getSnStyleInternal_32();
    var tmp = '' + '.' + this._get_className__3() + ' {\n';
    return tmp + joinToString$default_0(styles, '\n', null, null, 0, null, _no_name_provided_$factory_30(), 30, null) + '\n}';
  };
  Style.$metadata$ = {
    simpleName: 'Style',
    kind: 'class',
    interfaces: []
  };
  function className$factory() {
    return getPropertyCallableRef('className', 1, KMutableProperty1, function (receiver) {
      return receiver._get_className__3();
    }, function (receiver_0, value) {
      return receiver_0._set_className_(value);
    });
  }
  function className$factory_0() {
    return getPropertyCallableRef('className', 1, KMutableProperty1, function (receiver) {
      return receiver._get_className__3();
    }, function (receiver_0, value) {
      return receiver_0._set_className_(value);
    });
  }
  function _no_name_provided_$factory_30() {
    var i = new _no_name_provided__39();
    return function (p1) {
      return i.invoke_61(p1);
    };
  }
  function RefreshDelegateProvider($outer, initialValue, refreshFunction) {
    this._$this_5 = $outer;
    this._initialValue = initialValue;
    this._refreshFunction = refreshFunction;
  }
  RefreshDelegateProvider.prototype.provideDelegate_0 = function (thisRef, prop) {
    if (!(this._initialValue == null))
      this._$this_5._propertyValues_0[prop.callableName] = this._initialValue;
    return new RefreshDelegate(this._$this_5, this._refreshFunction);
  };
  RefreshDelegateProvider.$metadata$ = {
    simpleName: 'RefreshDelegateProvider',
    kind: 'class',
    interfaces: []
  };
  function RefreshDelegate($outer, refreshFunction) {
    this._$this_6 = $outer;
    this._refreshFunction_0 = refreshFunction;
  }
  RefreshDelegate.prototype.getValue_3 = function (thisRef, property) {
    var value = this._$this_6._propertyValues_0[property.callableName];
    var tmp;
    if (value != null) {
      tmp = value;
    } else {
      var tmp0_unsafeCast_0 = null;
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  };
  RefreshDelegate.prototype.setValue_6 = function (thisRef, property, value) {
    this._$this_6._propertyValues_0[property.callableName] = value;
    this._refreshFunction_0(value);
  };
  RefreshDelegate.$metadata$ = {
    simpleName: 'RefreshDelegate',
    kind: 'class',
    interfaces: []
  };
  function Companion_12() {
    Companion_instance_11 = this;
    var tmp = this;
    tmp._globalStyleCache = Cache_init_$Create$(0, 1, null);
  }
  Companion_12.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_11;
  function Companion_getInstance_11() {
    if (Companion_instance_11 == null)
      new Companion_12();
    return Companion_instance_11;
  }
  function _no_name_provided__40(this$0) {
    this._this$0_6 = this$0;
  }
  _no_name_provided__40.prototype.invoke_195 = function (it) {
    this._this$0_6.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__40.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__40.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__41(this$0) {
    this._this$0_7 = this$0;
  }
  _no_name_provided__41.prototype.invoke_195 = function (it) {
    this._this$0_7.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__41.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__41.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__42(this$0) {
    this._this$0_8 = this$0;
  }
  _no_name_provided__42.prototype.invoke_195 = function (it) {
    this._this$0_8.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__42.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__42.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__43(this$0) {
    this._this$0_9 = this$0;
  }
  _no_name_provided__43.prototype.invoke_195 = function (it) {
    this._this$0_9.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__43.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__43.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__44(this$0) {
    this._this$0_10 = this$0;
  }
  _no_name_provided__44.prototype.invoke_195 = function (it) {
    this._this$0_10.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__44.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__44.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__45(this$0) {
    this._this$0_11 = this$0;
  }
  _no_name_provided__45.prototype.invoke_195 = function (it) {
    this._this$0_11.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__45.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__45.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__46(this$0) {
    this._this$0_12 = this$0;
  }
  _no_name_provided__46.prototype.invoke_75 = function (it) {
    this._this$0_12.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__46.prototype.invoke_1082 = function (p1) {
    this.invoke_75((p1 == null ? true : p1 instanceof Display) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__46.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__47(this$0) {
    this._this$0_13 = this$0;
  }
  _no_name_provided__47.prototype.invoke_77 = function (it) {
    this._this$0_13.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__47.prototype.invoke_1082 = function (p1) {
    this.invoke_77((p1 == null ? true : p1 instanceof Position) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__47.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__48(this$0) {
    this._this$0_14 = this$0;
  }
  _no_name_provided__48.prototype.invoke_195 = function (it) {
    this._this$0_14.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__48.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__48.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__49(this$0) {
    this._this$0_15 = this$0;
  }
  _no_name_provided__49.prototype.invoke_195 = function (it) {
    this._this$0_15.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__49.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__49.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__50(this$0) {
    this._this$0_16 = this$0;
  }
  _no_name_provided__50.prototype.invoke_195 = function (it) {
    this._this$0_16.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__50.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__50.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__51(this$0) {
    this._this$0_17 = this$0;
  }
  _no_name_provided__51.prototype.invoke_195 = function (it) {
    this._this$0_17.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__51.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__51.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__52(this$0) {
    this._this$0_18 = this$0;
  }
  _no_name_provided__52.prototype.invoke_396 = function (it) {
    this._this$0_18.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__52.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__52.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__53(this$0) {
    this._this$0_19 = this$0;
  }
  _no_name_provided__53.prototype.invoke_89 = function (it) {
    this._this$0_19.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__53.prototype.invoke_1082 = function (p1) {
    this.invoke_89((p1 == null ? true : p1 instanceof Overflow) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__53.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__54(this$0) {
    this._this$0_20 = this$0;
  }
  _no_name_provided__54.prototype.invoke_91 = function (it) {
    this._this$0_20.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__54.prototype.invoke_1082 = function (p1) {
    this.invoke_91((p1 == null ? true : p1 instanceof OverflowWrap) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__54.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__55(this$0) {
    this._this$0_21 = this$0;
  }
  _no_name_provided__55.prototype.invoke_93 = function (it) {
    this._this$0_21.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__55.prototype.invoke_1082 = function (p1) {
    this.invoke_93((p1 == null ? true : p1 instanceof Resize) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__55.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__56(this$0) {
    this._this$0_22 = this$0;
  }
  _no_name_provided__56.prototype.invoke_103 = function (it) {
    this._this$0_22.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__56.prototype.invoke_1082 = function (p1) {
    this.invoke_103((p1 == null ? true : p1 instanceof Border) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__56.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__57(this$0) {
    this._this$0_23 = this$0;
  }
  _no_name_provided__57.prototype.invoke_103 = function (it) {
    this._this$0_23.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__57.prototype.invoke_1082 = function (p1) {
    this.invoke_103((p1 == null ? true : p1 instanceof Border) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__57.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__58(this$0) {
    this._this$0_24 = this$0;
  }
  _no_name_provided__58.prototype.invoke_103 = function (it) {
    this._this$0_24.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__58.prototype.invoke_1082 = function (p1) {
    this.invoke_103((p1 == null ? true : p1 instanceof Border) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__58.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__59(this$0) {
    this._this$0_25 = this$0;
  }
  _no_name_provided__59.prototype.invoke_103 = function (it) {
    this._this$0_25.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__59.prototype.invoke_1082 = function (p1) {
    this.invoke_103((p1 == null ? true : p1 instanceof Border) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__59.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__60(this$0) {
    this._this$0_26 = this$0;
  }
  _no_name_provided__60.prototype.invoke_103 = function (it) {
    this._this$0_26.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__60.prototype.invoke_1082 = function (p1) {
    this.invoke_103((p1 == null ? true : p1 instanceof Border) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__60.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__61(this$0) {
    this._this$0_27 = this$0;
  }
  _no_name_provided__61.prototype.invoke_195 = function (it) {
    this._this$0_27.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__61.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__61.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__62(this$0) {
    this._this$0_28 = this$0;
  }
  _no_name_provided__62.prototype.invoke_195 = function (it) {
    this._this$0_28.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__62.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__62.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__63(this$0) {
    this._this$0_29 = this$0;
  }
  _no_name_provided__63.prototype.invoke_195 = function (it) {
    this._this$0_29.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__63.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__63.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__64(this$0) {
    this._this$0_30 = this$0;
  }
  _no_name_provided__64.prototype.invoke_195 = function (it) {
    this._this$0_30.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__64.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__64.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__65(this$0) {
    this._this$0_31 = this$0;
  }
  _no_name_provided__65.prototype.invoke_195 = function (it) {
    this._this$0_31.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__65.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__65.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__66(this$0) {
    this._this$0_32 = this$0;
  }
  _no_name_provided__66.prototype.invoke_195 = function (it) {
    this._this$0_32.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__66.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__66.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__67(this$0) {
    this._this$0_33 = this$0;
  }
  _no_name_provided__67.prototype.invoke_195 = function (it) {
    this._this$0_33.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__67.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__67.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__68(this$0) {
    this._this$0_34 = this$0;
  }
  _no_name_provided__68.prototype.invoke_195 = function (it) {
    this._this$0_34.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__68.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__68.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__69(this$0) {
    this._this$0_35 = this$0;
  }
  _no_name_provided__69.prototype.invoke_195 = function (it) {
    this._this$0_35.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__69.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__69.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__70(this$0) {
    this._this$0_36 = this$0;
  }
  _no_name_provided__70.prototype.invoke_195 = function (it) {
    this._this$0_36.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__70.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__70.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__71(this$0) {
    this._this$0_37 = this$0;
  }
  _no_name_provided__71.prototype.invoke_125 = function (it) {
    this._this$0_37.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__71.prototype.invoke_1082 = function (p1) {
    this.invoke_125((p1 == null ? true : p1 instanceof Color) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__71.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__72(this$0) {
    this._this$0_38 = this$0;
  }
  _no_name_provided__72.prototype.invoke_127 = function (it) {
    this._this$0_38.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__72.prototype.invoke_1082 = function (p1) {
    this.invoke_127((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__72.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__73(this$0) {
    this._this$0_39 = this$0;
  }
  _no_name_provided__73.prototype.invoke_129 = function (it) {
    this._this$0_39.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__73.prototype.invoke_1082 = function (p1) {
    this.invoke_129((p1 == null ? true : p1 instanceof Background) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__73.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__74(this$0) {
    this._this$0_40 = this$0;
  }
  _no_name_provided__74.prototype.invoke_131 = function (it) {
    this._this$0_40.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__74.prototype.invoke_1082 = function (p1) {
    this.invoke_131((p1 == null ? true : p1 instanceof Direction) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__74.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__75(this$0) {
    this._this$0_41 = this$0;
  }
  _no_name_provided__75.prototype.invoke_195 = function (it) {
    this._this$0_41.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__75.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__75.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__76(this$0) {
    this._this$0_42 = this$0;
  }
  _no_name_provided__76.prototype.invoke_195 = function (it) {
    this._this$0_42.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__76.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__76.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__77(this$0) {
    this._this$0_43 = this$0;
  }
  _no_name_provided__77.prototype.invoke_137 = function (it) {
    this._this$0_43.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__77.prototype.invoke_1082 = function (p1) {
    this.invoke_137((p1 == null ? true : p1 instanceof TextAlign) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__77.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__78(this$0) {
    this._this$0_44 = this$0;
  }
  _no_name_provided__78.prototype.invoke_139 = function (it) {
    this._this$0_44.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__78.prototype.invoke_1082 = function (p1) {
    this.invoke_139((p1 == null ? true : p1 instanceof TextDecoration) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__78.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__79(this$0) {
    this._this$0_45 = this$0;
  }
  _no_name_provided__79.prototype.invoke_195 = function (it) {
    this._this$0_45.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__79.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__79.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__80(this$0) {
    this._this$0_46 = this$0;
  }
  _no_name_provided__80.prototype.invoke_143 = function (it) {
    this._this$0_46.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__80.prototype.invoke_1082 = function (p1) {
    this.invoke_143((p1 == null ? true : p1 instanceof TextShadow) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__80.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__81(this$0) {
    this._this$0_47 = this$0;
  }
  _no_name_provided__81.prototype.invoke_145 = function (it) {
    this._this$0_47.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__81.prototype.invoke_1082 = function (p1) {
    this.invoke_145((p1 == null ? true : p1 instanceof TextTransform) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__81.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__82(this$0) {
    this._this$0_48 = this$0;
  }
  _no_name_provided__82.prototype.invoke_147 = function (it) {
    this._this$0_48.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__82.prototype.invoke_1082 = function (p1) {
    this.invoke_147((p1 == null ? true : p1 instanceof TextOverflow) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__82.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__83(this$0) {
    this._this$0_49 = this$0;
  }
  _no_name_provided__83.prototype.invoke_149 = function (it) {
    this._this$0_49.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__83.prototype.invoke_1082 = function (p1) {
    this.invoke_149((p1 == null ? true : p1 instanceof UnicodeBidi) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__83.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__84(this$0) {
    this._this$0_50 = this$0;
  }
  _no_name_provided__84.prototype.invoke_151 = function (it) {
    this._this$0_50.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__84.prototype.invoke_1082 = function (p1) {
    this.invoke_151((p1 == null ? true : p1 instanceof VerticalAlign) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__84.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__85(this$0) {
    this._this$0_51 = this$0;
  }
  _no_name_provided__85.prototype.invoke_153 = function (it) {
    this._this$0_51.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__85.prototype.invoke_1082 = function (p1) {
    this.invoke_153((p1 == null ? true : p1 instanceof WhiteSpace) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__85.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__86(this$0) {
    this._this$0_52 = this$0;
  }
  _no_name_provided__86.prototype.invoke_195 = function (it) {
    this._this$0_52.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__86.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__86.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__87(this$0) {
    this._this$0_53 = this$0;
  }
  _no_name_provided__87.prototype.invoke_1079 = function (it) {
    this._this$0_53.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__87.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__87.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__88(this$0) {
    this._this$0_54 = this$0;
  }
  _no_name_provided__88.prototype.invoke_195 = function (it) {
    this._this$0_54.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__88.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__88.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__89(this$0) {
    this._this$0_55 = this$0;
  }
  _no_name_provided__89.prototype.invoke_161 = function (it) {
    this._this$0_55.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__89.prototype.invoke_1082 = function (p1) {
    this.invoke_161((p1 == null ? true : p1 instanceof FontStyle) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__89.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__90(this$0) {
    this._this$0_56 = this$0;
  }
  _no_name_provided__90.prototype.invoke_163 = function (it) {
    this._this$0_56.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__90.prototype.invoke_1082 = function (p1) {
    this.invoke_163((p1 == null ? true : p1 instanceof FontWeight) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__90.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__91(this$0) {
    this._this$0_57 = this$0;
  }
  _no_name_provided__91.prototype.invoke_165 = function (it) {
    this._this$0_57.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__91.prototype.invoke_1082 = function (p1) {
    this.invoke_165((p1 == null ? true : p1 instanceof FontVariant) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__91.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__92(this$0) {
    this._this$0_58 = this$0;
  }
  _no_name_provided__92.prototype.invoke_167 = function (it) {
    this._this$0_58.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__92.prototype.invoke_1082 = function (p1) {
    this.invoke_167((p1 == null ? true : p1 instanceof PosFloat) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__92.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__93(this$0) {
    this._this$0_59 = this$0;
  }
  _no_name_provided__93.prototype.invoke_169 = function (it) {
    this._this$0_59.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__93.prototype.invoke_1082 = function (p1) {
    this.invoke_169((p1 == null ? true : p1 instanceof Clear) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__93.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__94(this$0) {
    this._this$0_60 = this$0;
  }
  _no_name_provided__94.prototype.invoke_171 = function (it) {
    this._this$0_60.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__94.prototype.invoke_1082 = function (p1) {
    this.invoke_171((p1 == null ? true : p1 instanceof WordBreak) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__94.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__95(this$0) {
    this._this$0_61 = this$0;
  }
  _no_name_provided__95.prototype.invoke_173 = function (it) {
    this._this$0_61.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__95.prototype.invoke_1082 = function (p1) {
    this.invoke_173((p1 == null ? true : p1 instanceof LineBreak) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__95.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__96(this$0) {
    this._this$0_62 = this$0;
  }
  _no_name_provided__96.prototype.invoke_175 = function (it) {
    this._this$0_62.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__96.prototype.invoke_1082 = function (p1) {
    this.invoke_175((p1 == null ? true : p1 instanceof Cursor) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__96.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__97(this$0) {
    this._this$0_63 = this$0;
  }
  _no_name_provided__97.prototype.invoke_177 = function (it) {
    this._this$0_63.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__97.prototype.invoke_1082 = function (p1) {
    this.invoke_177((p1 == null ? true : p1 instanceof FlexDirection) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__97.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__98(this$0) {
    this._this$0_64 = this$0;
  }
  _no_name_provided__98.prototype.invoke_179 = function (it) {
    this._this$0_64.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__98.prototype.invoke_1082 = function (p1) {
    this.invoke_179((p1 == null ? true : p1 instanceof FlexWrap) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__98.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__99(this$0) {
    this._this$0_65 = this$0;
  }
  _no_name_provided__99.prototype.invoke_199 = function (it) {
    this._this$0_65.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__99.prototype.invoke_1082 = function (p1) {
    this.invoke_199((p1 == null ? true : p1 instanceof JustifyItems) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__99.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__100(this$0) {
    this._this$0_66 = this$0;
  }
  _no_name_provided__100.prototype.invoke_183 = function (it) {
    this._this$0_66.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__100.prototype.invoke_1082 = function (p1) {
    this.invoke_183((p1 == null ? true : p1 instanceof JustifyContent) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__100.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__101(this$0) {
    this._this$0_67 = this$0;
  }
  _no_name_provided__101.prototype.invoke_197 = function (it) {
    this._this$0_67.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__101.prototype.invoke_1082 = function (p1) {
    this.invoke_197((p1 == null ? true : p1 instanceof AlignItems) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__101.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__102(this$0) {
    this._this$0_68 = this$0;
  }
  _no_name_provided__102.prototype.invoke_187 = function (it) {
    this._this$0_68.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__102.prototype.invoke_1082 = function (p1) {
    this.invoke_187((p1 == null ? true : p1 instanceof AlignContent) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__102.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__103(this$0) {
    this._this$0_69 = this$0;
  }
  _no_name_provided__103.prototype.invoke_396 = function (it) {
    this._this$0_69.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__103.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__103.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__104(this$0) {
    this._this$0_70 = this$0;
  }
  _no_name_provided__104.prototype.invoke_396 = function (it) {
    this._this$0_70.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__104.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__104.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__105(this$0) {
    this._this$0_71 = this$0;
  }
  _no_name_provided__105.prototype.invoke_396 = function (it) {
    this._this$0_71.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__105.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__105.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__106(this$0) {
    this._this$0_72 = this$0;
  }
  _no_name_provided__106.prototype.invoke_195 = function (it) {
    this._this$0_72.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__106.prototype.invoke_1082 = function (p1) {
    this.invoke_195((p1 == null ? true : p1 instanceof Pair) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__106.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__107(this$0) {
    this._this$0_73 = this$0;
  }
  _no_name_provided__107.prototype.invoke_197 = function (it) {
    this._this$0_73.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__107.prototype.invoke_1082 = function (p1) {
    this.invoke_197((p1 == null ? true : p1 instanceof AlignItems) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__107.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__108(this$0) {
    this._this$0_74 = this$0;
  }
  _no_name_provided__108.prototype.invoke_199 = function (it) {
    this._this$0_74.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__108.prototype.invoke_1082 = function (p1) {
    this.invoke_199((p1 == null ? true : p1 instanceof JustifyItems) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__108.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__109(this$0) {
    this._this$0_75 = this$0;
  }
  _no_name_provided__109.prototype.invoke_1079 = function (it) {
    this._this$0_75.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__109.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__109.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__110(this$0) {
    this._this$0_76 = this$0;
  }
  _no_name_provided__110.prototype.invoke_1079 = function (it) {
    this._this$0_76.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__110.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__110.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__111(this$0) {
    this._this$0_77 = this$0;
  }
  _no_name_provided__111.prototype.invoke_205 = function (it) {
    this._this$0_77.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__111.prototype.invoke_1082 = function (p1) {
    this.invoke_205((p1 == null ? true : p1 instanceof GridAutoFlow) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__111.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__112(this$0) {
    this._this$0_78 = this$0;
  }
  _no_name_provided__112.prototype.invoke_1079 = function (it) {
    this._this$0_78.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__112.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__112.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__113(this$0) {
    this._this$0_79 = this$0;
  }
  _no_name_provided__113.prototype.invoke_1079 = function (it) {
    this._this$0_79.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__113.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__113.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__114(this$0) {
    this._this$0_80 = this$0;
  }
  _no_name_provided__114.prototype.invoke_211 = function (it) {
    this._this$0_80.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__114.prototype.invoke_1082 = function (p1) {
    this.invoke_211((p1 == null ? true : isInterface(p1, List)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__114.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__115(this$0) {
    this._this$0_81 = this$0;
  }
  _no_name_provided__115.prototype.invoke_396 = function (it) {
    this._this$0_81.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__115.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__115.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__116(this$0) {
    this._this$0_82 = this$0;
  }
  _no_name_provided__116.prototype.invoke_396 = function (it) {
    this._this$0_82.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__116.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__116.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__117(this$0) {
    this._this$0_83 = this$0;
  }
  _no_name_provided__117.prototype.invoke_396 = function (it) {
    this._this$0_83.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__117.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__117.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__118(this$0) {
    this._this$0_84 = this$0;
  }
  _no_name_provided__118.prototype.invoke_396 = function (it) {
    this._this$0_84.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__118.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__118.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__119(this$0) {
    this._this$0_85 = this$0;
  }
  _no_name_provided__119.prototype.invoke_1079 = function (it) {
    this._this$0_85.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__119.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__119.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__120(this$0) {
    this._this$0_86 = this$0;
  }
  _no_name_provided__120.prototype.invoke_1079 = function (it) {
    this._this$0_86.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__120.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__120.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__121(this$0) {
    this._this$0_87 = this$0;
  }
  _no_name_provided__121.prototype.invoke_1079 = function (it) {
    this._this$0_87.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__121.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__121.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function StyledComponent() {
    Companion_getInstance_11();
    this._propertyValues_0 = {};
    this._customStyles = {};
    var tmp = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_31(this);
    tmp._width$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, width$factory());
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_32(this);
    tmp_0._minWidth$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, minWidth$factory());
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0_1 = _no_name_provided_$factory_33(this);
    tmp_1._maxWidth$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_1)).provideDelegate_0(this, maxWidth$factory());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_2 = _no_name_provided_$factory_34(this);
    tmp_2._height$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_2)).provideDelegate_0(this, height$factory());
    var tmp_3 = this;
    var tmp0_refreshOnUpdate_0_3 = _no_name_provided_$factory_35(this);
    tmp_3._minHeight$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_3)).provideDelegate_0(this, minHeight$factory());
    var tmp_4 = this;
    var tmp0_refreshOnUpdate_0_4 = _no_name_provided_$factory_36(this);
    tmp_4._maxHeight$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_4)).provideDelegate_0(this, maxHeight$factory());
    var tmp_5 = this;
    var tmp0_refreshOnUpdate_0_5 = _no_name_provided_$factory_37(this);
    tmp_5._display$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_5)).provideDelegate_0(this, display$factory());
    var tmp_6 = this;
    var tmp0_refreshOnUpdate_0_6 = _no_name_provided_$factory_38(this);
    tmp_6._position$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_6)).provideDelegate_0(this, position$factory());
    var tmp_7 = this;
    var tmp0_refreshOnUpdate_0_7 = _no_name_provided_$factory_39(this);
    tmp_7._top$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_7)).provideDelegate_0(this, top$factory());
    var tmp_8 = this;
    var tmp0_refreshOnUpdate_0_8 = _no_name_provided_$factory_40(this);
    tmp_8._left$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_8)).provideDelegate_0(this, left$factory());
    var tmp_9 = this;
    var tmp0_refreshOnUpdate_0_9 = _no_name_provided_$factory_41(this);
    tmp_9._right$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_9)).provideDelegate_0(this, right$factory());
    var tmp_10 = this;
    var tmp0_refreshOnUpdate_0_10 = _no_name_provided_$factory_42(this);
    tmp_10._bottom$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_10)).provideDelegate_0(this, bottom$factory());
    var tmp_11 = this;
    var tmp0_refreshOnUpdate_0_11 = _no_name_provided_$factory_43(this);
    tmp_11._zIndex$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_11)).provideDelegate_0(this, zIndex$factory());
    var tmp_12 = this;
    var tmp0_refreshOnUpdate_0_12 = _no_name_provided_$factory_44(this);
    tmp_12._overflow$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_12)).provideDelegate_0(this, overflow$factory());
    var tmp_13 = this;
    var tmp0_refreshOnUpdate_0_13 = _no_name_provided_$factory_45(this);
    tmp_13._overflowWrap$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_13)).provideDelegate_0(this, overflowWrap$factory());
    var tmp_14 = this;
    var tmp0_refreshOnUpdate_0_14 = _no_name_provided_$factory_46(this);
    tmp_14._resize$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_14)).provideDelegate_0(this, resize$factory());
    var tmp_15 = this;
    var tmp0_refreshOnUpdate_0_15 = _no_name_provided_$factory_47(this);
    tmp_15._border$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_15)).provideDelegate_0(this, border$factory());
    var tmp_16 = this;
    var tmp0_refreshOnUpdate_0_16 = _no_name_provided_$factory_48(this);
    tmp_16._borderTop$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_16)).provideDelegate_0(this, borderTop$factory());
    var tmp_17 = this;
    var tmp0_refreshOnUpdate_0_17 = _no_name_provided_$factory_49(this);
    tmp_17._borderRight$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_17)).provideDelegate_0(this, borderRight$factory());
    var tmp_18 = this;
    var tmp0_refreshOnUpdate_0_18 = _no_name_provided_$factory_50(this);
    tmp_18._borderBottom$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_18)).provideDelegate_0(this, borderBottom$factory());
    var tmp_19 = this;
    var tmp0_refreshOnUpdate_0_19 = _no_name_provided_$factory_51(this);
    tmp_19._borderLeft$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_19)).provideDelegate_0(this, borderLeft$factory());
    var tmp_20 = this;
    var tmp0_refreshOnUpdate_0_20 = _no_name_provided_$factory_52(this);
    tmp_20._margin$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_20)).provideDelegate_0(this, margin$factory());
    var tmp_21 = this;
    var tmp0_refreshOnUpdate_0_21 = _no_name_provided_$factory_53(this);
    tmp_21._marginTop$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_21)).provideDelegate_0(this, marginTop$factory());
    var tmp_22 = this;
    var tmp0_refreshOnUpdate_0_22 = _no_name_provided_$factory_54(this);
    tmp_22._marginRight$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_22)).provideDelegate_0(this, marginRight$factory());
    var tmp_23 = this;
    var tmp0_refreshOnUpdate_0_23 = _no_name_provided_$factory_55(this);
    tmp_23._marginBottom$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_23)).provideDelegate_0(this, marginBottom$factory());
    var tmp_24 = this;
    var tmp0_refreshOnUpdate_0_24 = _no_name_provided_$factory_56(this);
    tmp_24._marginLeft$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_24)).provideDelegate_0(this, marginLeft$factory());
    var tmp_25 = this;
    var tmp0_refreshOnUpdate_0_25 = _no_name_provided_$factory_57(this);
    tmp_25._padding$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_25)).provideDelegate_0(this, padding$factory());
    var tmp_26 = this;
    var tmp0_refreshOnUpdate_0_26 = _no_name_provided_$factory_58(this);
    tmp_26._paddingTop$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_26)).provideDelegate_0(this, paddingTop$factory());
    var tmp_27 = this;
    var tmp0_refreshOnUpdate_0_27 = _no_name_provided_$factory_59(this);
    tmp_27._paddingRight$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_27)).provideDelegate_0(this, paddingRight$factory());
    var tmp_28 = this;
    var tmp0_refreshOnUpdate_0_28 = _no_name_provided_$factory_60(this);
    tmp_28._paddingBottom$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_28)).provideDelegate_0(this, paddingBottom$factory());
    var tmp_29 = this;
    var tmp0_refreshOnUpdate_0_29 = _no_name_provided_$factory_61(this);
    tmp_29._paddingLeft$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_29)).provideDelegate_0(this, paddingLeft$factory());
    var tmp_30 = this;
    var tmp0_refreshOnUpdate_0_30 = _no_name_provided_$factory_62(this);
    tmp_30._color$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_30)).provideDelegate_0(this, color$factory());
    var tmp_31 = this;
    var tmp0_refreshOnUpdate_0_31 = _no_name_provided_$factory_63(this);
    tmp_31._opacity$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_31)).provideDelegate_0(this, opacity$factory());
    var tmp_32 = this;
    var tmp0_refreshOnUpdate_0_32 = _no_name_provided_$factory_64(this);
    tmp_32._background$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_32)).provideDelegate_0(this, background$factory());
    var tmp_33 = this;
    var tmp0_refreshOnUpdate_0_33 = _no_name_provided_$factory_65(this);
    tmp_33._textDirection$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_33)).provideDelegate_0(this, textDirection$factory());
    var tmp_34 = this;
    var tmp0_refreshOnUpdate_0_34 = _no_name_provided_$factory_66(this);
    tmp_34._letterSpacing$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_34)).provideDelegate_0(this, letterSpacing$factory());
    var tmp_35 = this;
    var tmp0_refreshOnUpdate_0_35 = _no_name_provided_$factory_67(this);
    tmp_35._lineHeight$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_35)).provideDelegate_0(this, lineHeight$factory());
    var tmp_36 = this;
    var tmp0_refreshOnUpdate_0_36 = _no_name_provided_$factory_68(this);
    tmp_36._textAlign$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_36)).provideDelegate_0(this, textAlign$factory());
    var tmp_37 = this;
    var tmp0_refreshOnUpdate_0_37 = _no_name_provided_$factory_69(this);
    tmp_37._textDecoration$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_37)).provideDelegate_0(this, textDecoration$factory());
    var tmp_38 = this;
    var tmp0_refreshOnUpdate_0_38 = _no_name_provided_$factory_70(this);
    tmp_38._textIndent$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_38)).provideDelegate_0(this, textIndent$factory());
    var tmp_39 = this;
    var tmp0_refreshOnUpdate_0_39 = _no_name_provided_$factory_71(this);
    tmp_39._textShadow$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_39)).provideDelegate_0(this, textShadow$factory());
    var tmp_40 = this;
    var tmp0_refreshOnUpdate_0_40 = _no_name_provided_$factory_72(this);
    tmp_40._textTransform$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_40)).provideDelegate_0(this, textTransform$factory());
    var tmp_41 = this;
    var tmp0_refreshOnUpdate_0_41 = _no_name_provided_$factory_73(this);
    tmp_41._textOverflow$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_41)).provideDelegate_0(this, textOverflow$factory());
    var tmp_42 = this;
    var tmp0_refreshOnUpdate_0_42 = _no_name_provided_$factory_74(this);
    tmp_42._unicodeBidi$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_42)).provideDelegate_0(this, unicodeBidi$factory());
    var tmp_43 = this;
    var tmp0_refreshOnUpdate_0_43 = _no_name_provided_$factory_75(this);
    tmp_43._verticalAlign$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_43)).provideDelegate_0(this, verticalAlign$factory());
    var tmp_44 = this;
    var tmp0_refreshOnUpdate_0_44 = _no_name_provided_$factory_76(this);
    tmp_44._whiteSpace$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_44)).provideDelegate_0(this, whiteSpace$factory());
    var tmp_45 = this;
    var tmp0_refreshOnUpdate_0_45 = _no_name_provided_$factory_77(this);
    tmp_45._wordSpacing$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_45)).provideDelegate_0(this, wordSpacing$factory());
    var tmp_46 = this;
    var tmp0_refreshOnUpdate_0_46 = _no_name_provided_$factory_78(this);
    tmp_46._fontFamily$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_46)).provideDelegate_0(this, fontFamily$factory());
    var tmp_47 = this;
    var tmp0_refreshOnUpdate_0_47 = _no_name_provided_$factory_79(this);
    tmp_47._fontSize$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_47)).provideDelegate_0(this, fontSize$factory());
    var tmp_48 = this;
    var tmp0_refreshOnUpdate_0_48 = _no_name_provided_$factory_80(this);
    tmp_48._fontStyle$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_48)).provideDelegate_0(this, fontStyle$factory());
    var tmp_49 = this;
    var tmp0_refreshOnUpdate_0_49 = _no_name_provided_$factory_81(this);
    tmp_49._fontWeight$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_49)).provideDelegate_0(this, fontWeight$factory());
    var tmp_50 = this;
    var tmp0_refreshOnUpdate_0_50 = _no_name_provided_$factory_82(this);
    tmp_50._fontVariant$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_50)).provideDelegate_0(this, fontVariant$factory());
    var tmp_51 = this;
    var tmp0_refreshOnUpdate_0_51 = _no_name_provided_$factory_83(this);
    tmp_51._float$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_51)).provideDelegate_0(this, float$factory());
    var tmp_52 = this;
    var tmp0_refreshOnUpdate_0_52 = _no_name_provided_$factory_84(this);
    tmp_52._clear$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_52)).provideDelegate_0(this, clear$factory());
    var tmp_53 = this;
    var tmp0_refreshOnUpdate_0_53 = _no_name_provided_$factory_85(this);
    tmp_53._wordBreak$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_53)).provideDelegate_0(this, wordBreak$factory());
    var tmp_54 = this;
    var tmp0_refreshOnUpdate_0_54 = _no_name_provided_$factory_86(this);
    tmp_54._lineBreak$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_54)).provideDelegate_0(this, lineBreak$factory());
    var tmp_55 = this;
    var tmp0_refreshOnUpdate_0_55 = _no_name_provided_$factory_87(this);
    tmp_55._cursor$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_55)).provideDelegate_0(this, cursor$factory());
    var tmp_56 = this;
    var tmp0_refreshOnUpdate_0_56 = _no_name_provided_$factory_88(this);
    tmp_56._flexDirection$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_56)).provideDelegate_0(this, flexDirection$factory());
    var tmp_57 = this;
    var tmp0_refreshOnUpdate_0_57 = _no_name_provided_$factory_89(this);
    tmp_57._flexWrap$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_57)).provideDelegate_0(this, flexWrap$factory());
    var tmp_58 = this;
    var tmp0_refreshOnUpdate_0_58 = _no_name_provided_$factory_90(this);
    tmp_58._justifyItems$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_58)).provideDelegate_0(this, justifyItems$factory());
    var tmp_59 = this;
    var tmp0_refreshOnUpdate_0_59 = _no_name_provided_$factory_91(this);
    tmp_59._justifyContent$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_59)).provideDelegate_0(this, justifyContent$factory());
    var tmp_60 = this;
    var tmp0_refreshOnUpdate_0_60 = _no_name_provided_$factory_92(this);
    tmp_60._alignItems$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_60)).provideDelegate_0(this, alignItems$factory());
    var tmp_61 = this;
    var tmp0_refreshOnUpdate_0_61 = _no_name_provided_$factory_93(this);
    tmp_61._alignContent$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_61)).provideDelegate_0(this, alignContent$factory());
    var tmp_62 = this;
    var tmp0_refreshOnUpdate_0_62 = _no_name_provided_$factory_94(this);
    tmp_62._order$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_62)).provideDelegate_0(this, order$factory());
    var tmp_63 = this;
    var tmp0_refreshOnUpdate_0_63 = _no_name_provided_$factory_95(this);
    tmp_63._flexGrow$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_63)).provideDelegate_0(this, flexGrow$factory());
    var tmp_64 = this;
    var tmp0_refreshOnUpdate_0_64 = _no_name_provided_$factory_96(this);
    tmp_64._flexShrink$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_64)).provideDelegate_0(this, flexShrink$factory());
    var tmp_65 = this;
    var tmp0_refreshOnUpdate_0_65 = _no_name_provided_$factory_97(this);
    tmp_65._flexBasis$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_65)).provideDelegate_0(this, flexBasis$factory());
    var tmp_66 = this;
    var tmp0_refreshOnUpdate_0_66 = _no_name_provided_$factory_98(this);
    tmp_66._alignSelf$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_66)).provideDelegate_0(this, alignSelf$factory());
    var tmp_67 = this;
    var tmp0_refreshOnUpdate_0_67 = _no_name_provided_$factory_99(this);
    tmp_67._justifySelf$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_67)).provideDelegate_0(this, justifySelf$factory());
    var tmp_68 = this;
    var tmp0_refreshOnUpdate_0_68 = _no_name_provided_$factory_100(this);
    tmp_68._gridAutoColumns$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_68)).provideDelegate_0(this, gridAutoColumns$factory());
    var tmp_69 = this;
    var tmp0_refreshOnUpdate_0_69 = _no_name_provided_$factory_101(this);
    tmp_69._gridAutoRows$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_69)).provideDelegate_0(this, gridAutoRows$factory());
    var tmp_70 = this;
    var tmp0_refreshOnUpdate_0_70 = _no_name_provided_$factory_102(this);
    tmp_70._gridAutoFlow$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_70)).provideDelegate_0(this, gridAutoFlow$factory());
    var tmp_71 = this;
    var tmp0_refreshOnUpdate_0_71 = _no_name_provided_$factory_103(this);
    tmp_71._gridTemplateColumns$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_71)).provideDelegate_0(this, gridTemplateColumns$factory());
    var tmp_72 = this;
    var tmp0_refreshOnUpdate_0_72 = _no_name_provided_$factory_104(this);
    tmp_72._gridTemplateRows$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_72)).provideDelegate_0(this, gridTemplateRows$factory());
    var tmp_73 = this;
    var tmp0_refreshOnUpdate_0_73 = _no_name_provided_$factory_105(this);
    tmp_73._gridTemplateAreas$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_73)).provideDelegate_0(this, gridTemplateAreas$factory());
    var tmp_74 = this;
    var tmp0_refreshOnUpdate_0_74 = _no_name_provided_$factory_106(this);
    tmp_74._gridColumnGap$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_74)).provideDelegate_0(this, gridColumnGap$factory());
    var tmp_75 = this;
    var tmp0_refreshOnUpdate_0_75 = _no_name_provided_$factory_107(this);
    tmp_75._gridRowGap$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_75)).provideDelegate_0(this, gridRowGap$factory());
    var tmp_76 = this;
    var tmp0_refreshOnUpdate_0_76 = _no_name_provided_$factory_108(this);
    tmp_76._gridColumnStart$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_76)).provideDelegate_0(this, gridColumnStart$factory());
    var tmp_77 = this;
    var tmp0_refreshOnUpdate_0_77 = _no_name_provided_$factory_109(this);
    tmp_77._gridRowStart$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_77)).provideDelegate_0(this, gridRowStart$factory());
    var tmp_78 = this;
    var tmp0_refreshOnUpdate_0_78 = _no_name_provided_$factory_110(this);
    tmp_78._gridColumnEnd$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_78)).provideDelegate_0(this, gridColumnEnd$factory());
    var tmp_79 = this;
    var tmp0_refreshOnUpdate_0_79 = _no_name_provided_$factory_111(this);
    tmp_79._gridRowEnd$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_79)).provideDelegate_0(this, gridRowEnd$factory());
    var tmp_80 = this;
    var tmp0_refreshOnUpdate_0_80 = _no_name_provided_$factory_112(this);
    tmp_80._gridArea$delegate = (new RefreshDelegateProvider(this, null, tmp0_refreshOnUpdate_0_80)).provideDelegate_0(this, gridArea$factory());
    this._snStyleCache = null;
  }
  StyledComponent.prototype._set_width__31 = function (_set___) {
    return this._width$delegate.setValue_6(this, width$factory_0(), _set___);
  };
  StyledComponent.prototype._get_width__41 = function () {
    return this._width$delegate.getValue_3(this, width$factory_1());
  };
  StyledComponent.prototype._set_minWidth__31 = function (_set___) {
    return this._minWidth$delegate.setValue_6(this, minWidth$factory_0(), _set___);
  };
  StyledComponent.prototype._get_minWidth__31 = function () {
    return this._minWidth$delegate.getValue_3(this, minWidth$factory_1());
  };
  StyledComponent.prototype._set_maxWidth__31 = function (_set___) {
    return this._maxWidth$delegate.setValue_6(this, maxWidth$factory_0(), _set___);
  };
  StyledComponent.prototype._get_maxWidth__31 = function () {
    return this._maxWidth$delegate.getValue_3(this, maxWidth$factory_1());
  };
  StyledComponent.prototype._set_height__31 = function (_set___) {
    return this._height$delegate.setValue_6(this, height$factory_0(), _set___);
  };
  StyledComponent.prototype._get_height__41 = function () {
    return this._height$delegate.getValue_3(this, height$factory_1());
  };
  StyledComponent.prototype._set_minHeight__31 = function (_set___) {
    return this._minHeight$delegate.setValue_6(this, minHeight$factory_0(), _set___);
  };
  StyledComponent.prototype._get_minHeight__31 = function () {
    return this._minHeight$delegate.getValue_3(this, minHeight$factory_1());
  };
  StyledComponent.prototype._set_maxHeight__31 = function (_set___) {
    return this._maxHeight$delegate.setValue_6(this, maxHeight$factory_0(), _set___);
  };
  StyledComponent.prototype._get_maxHeight__31 = function () {
    return this._maxHeight$delegate.getValue_3(this, maxHeight$factory_1());
  };
  StyledComponent.prototype._set_display__31 = function (_set___) {
    return this._display$delegate.setValue_6(this, display$factory_0(), _set___);
  };
  StyledComponent.prototype._get_display__31 = function () {
    return this._display$delegate.getValue_3(this, display$factory_1());
  };
  StyledComponent.prototype._set_position__31 = function (_set___) {
    return this._position$delegate.setValue_6(this, position$factory_0(), _set___);
  };
  StyledComponent.prototype._get_position__31 = function () {
    return this._position$delegate.getValue_3(this, position$factory_1());
  };
  StyledComponent.prototype._set_top__31 = function (_set___) {
    return this._top$delegate.setValue_6(this, top$factory_0(), _set___);
  };
  StyledComponent.prototype._get_top__32 = function () {
    return this._top$delegate.getValue_3(this, top$factory_1());
  };
  StyledComponent.prototype._set_left__31 = function (_set___) {
    return this._left$delegate.setValue_6(this, left$factory_0(), _set___);
  };
  StyledComponent.prototype._get_left__32 = function () {
    return this._left$delegate.getValue_3(this, left$factory_1());
  };
  StyledComponent.prototype._set_right__31 = function (_set___) {
    return this._right$delegate.setValue_6(this, right$factory_0(), _set___);
  };
  StyledComponent.prototype._get_right__32 = function () {
    return this._right$delegate.getValue_3(this, right$factory_1());
  };
  StyledComponent.prototype._set_bottom__31 = function (_set___) {
    return this._bottom$delegate.setValue_6(this, bottom$factory_0(), _set___);
  };
  StyledComponent.prototype._get_bottom__32 = function () {
    return this._bottom$delegate.getValue_3(this, bottom$factory_1());
  };
  StyledComponent.prototype._set_zIndex__31 = function (_set___) {
    return this._zIndex$delegate.setValue_6(this, zIndex$factory_0(), _set___);
  };
  StyledComponent.prototype._get_zIndex__31 = function () {
    return this._zIndex$delegate.getValue_3(this, zIndex$factory_1());
  };
  StyledComponent.prototype._set_overflow__31 = function (_set___) {
    return this._overflow$delegate.setValue_6(this, overflow$factory_0(), _set___);
  };
  StyledComponent.prototype._get_overflow__31 = function () {
    return this._overflow$delegate.getValue_3(this, overflow$factory_1());
  };
  StyledComponent.prototype._set_overflowWrap__31 = function (_set___) {
    return this._overflowWrap$delegate.setValue_6(this, overflowWrap$factory_0(), _set___);
  };
  StyledComponent.prototype._get_overflowWrap__31 = function () {
    return this._overflowWrap$delegate.getValue_3(this, overflowWrap$factory_1());
  };
  StyledComponent.prototype._set_resize__31 = function (_set___) {
    return this._resize$delegate.setValue_6(this, resize$factory_0(), _set___);
  };
  StyledComponent.prototype._get_resize__31 = function () {
    return this._resize$delegate.getValue_3(this, resize$factory_1());
  };
  StyledComponent.prototype._set_border__31 = function (_set___) {
    return this._border$delegate.setValue_6(this, border$factory_0(), _set___);
  };
  StyledComponent.prototype._get_border__31 = function () {
    return this._border$delegate.getValue_3(this, border$factory_1());
  };
  StyledComponent.prototype._set_borderTop__31 = function (_set___) {
    return this._borderTop$delegate.setValue_6(this, borderTop$factory_0(), _set___);
  };
  StyledComponent.prototype._get_borderTop__31 = function () {
    return this._borderTop$delegate.getValue_3(this, borderTop$factory_1());
  };
  StyledComponent.prototype._set_borderRight__31 = function (_set___) {
    return this._borderRight$delegate.setValue_6(this, borderRight$factory_0(), _set___);
  };
  StyledComponent.prototype._get_borderRight__31 = function () {
    return this._borderRight$delegate.getValue_3(this, borderRight$factory_1());
  };
  StyledComponent.prototype._set_borderBottom__31 = function (_set___) {
    return this._borderBottom$delegate.setValue_6(this, borderBottom$factory_0(), _set___);
  };
  StyledComponent.prototype._get_borderBottom__31 = function () {
    return this._borderBottom$delegate.getValue_3(this, borderBottom$factory_1());
  };
  StyledComponent.prototype._set_borderLeft__31 = function (_set___) {
    return this._borderLeft$delegate.setValue_6(this, borderLeft$factory_0(), _set___);
  };
  StyledComponent.prototype._get_borderLeft__31 = function () {
    return this._borderLeft$delegate.getValue_3(this, borderLeft$factory_1());
  };
  StyledComponent.prototype._set_margin__31 = function (_set___) {
    return this._margin$delegate.setValue_6(this, margin$factory_0(), _set___);
  };
  StyledComponent.prototype._get_margin__31 = function () {
    return this._margin$delegate.getValue_3(this, margin$factory_1());
  };
  StyledComponent.prototype._set_marginTop__31 = function (_set___) {
    return this._marginTop$delegate.setValue_6(this, marginTop$factory_0(), _set___);
  };
  StyledComponent.prototype._get_marginTop__31 = function () {
    return this._marginTop$delegate.getValue_3(this, marginTop$factory_1());
  };
  StyledComponent.prototype._set_marginRight__31 = function (_set___) {
    return this._marginRight$delegate.setValue_6(this, marginRight$factory_0(), _set___);
  };
  StyledComponent.prototype._get_marginRight__31 = function () {
    return this._marginRight$delegate.getValue_3(this, marginRight$factory_1());
  };
  StyledComponent.prototype._set_marginBottom__31 = function (_set___) {
    return this._marginBottom$delegate.setValue_6(this, marginBottom$factory_0(), _set___);
  };
  StyledComponent.prototype._get_marginBottom__31 = function () {
    return this._marginBottom$delegate.getValue_3(this, marginBottom$factory_1());
  };
  StyledComponent.prototype._set_marginLeft__31 = function (_set___) {
    return this._marginLeft$delegate.setValue_6(this, marginLeft$factory_0(), _set___);
  };
  StyledComponent.prototype._get_marginLeft__31 = function () {
    return this._marginLeft$delegate.getValue_3(this, marginLeft$factory_1());
  };
  StyledComponent.prototype._set_padding__31 = function (_set___) {
    return this._padding$delegate.setValue_6(this, padding$factory_0(), _set___);
  };
  StyledComponent.prototype._get_padding__31 = function () {
    return this._padding$delegate.getValue_3(this, padding$factory_1());
  };
  StyledComponent.prototype._set_paddingTop__31 = function (_set___) {
    return this._paddingTop$delegate.setValue_6(this, paddingTop$factory_0(), _set___);
  };
  StyledComponent.prototype._get_paddingTop__31 = function () {
    return this._paddingTop$delegate.getValue_3(this, paddingTop$factory_1());
  };
  StyledComponent.prototype._set_paddingRight__31 = function (_set___) {
    return this._paddingRight$delegate.setValue_6(this, paddingRight$factory_0(), _set___);
  };
  StyledComponent.prototype._get_paddingRight__31 = function () {
    return this._paddingRight$delegate.getValue_3(this, paddingRight$factory_1());
  };
  StyledComponent.prototype._set_paddingBottom__31 = function (_set___) {
    return this._paddingBottom$delegate.setValue_6(this, paddingBottom$factory_0(), _set___);
  };
  StyledComponent.prototype._get_paddingBottom__31 = function () {
    return this._paddingBottom$delegate.getValue_3(this, paddingBottom$factory_1());
  };
  StyledComponent.prototype._set_paddingLeft__31 = function (_set___) {
    return this._paddingLeft$delegate.setValue_6(this, paddingLeft$factory_0(), _set___);
  };
  StyledComponent.prototype._get_paddingLeft__31 = function () {
    return this._paddingLeft$delegate.getValue_3(this, paddingLeft$factory_1());
  };
  StyledComponent.prototype._set_color__31 = function (_set___) {
    return this._color$delegate.setValue_6(this, color$factory_0(), _set___);
  };
  StyledComponent.prototype._get_color__31 = function () {
    return this._color$delegate.getValue_3(this, color$factory_1());
  };
  StyledComponent.prototype._set_opacity__31 = function (_set___) {
    return this._opacity$delegate.setValue_6(this, opacity$factory_0(), _set___);
  };
  StyledComponent.prototype._get_opacity__31 = function () {
    return this._opacity$delegate.getValue_3(this, opacity$factory_1());
  };
  StyledComponent.prototype._set_background__31 = function (_set___) {
    return this._background$delegate.setValue_6(this, background$factory_0(), _set___);
  };
  StyledComponent.prototype._get_background__31 = function () {
    return this._background$delegate.getValue_3(this, background$factory_1());
  };
  StyledComponent.prototype._set_textDirection__31 = function (_set___) {
    return this._textDirection$delegate.setValue_6(this, textDirection$factory_0(), _set___);
  };
  StyledComponent.prototype._get_textDirection__31 = function () {
    return this._textDirection$delegate.getValue_3(this, textDirection$factory_1());
  };
  StyledComponent.prototype._set_letterSpacing__31 = function (_set___) {
    return this._letterSpacing$delegate.setValue_6(this, letterSpacing$factory_0(), _set___);
  };
  StyledComponent.prototype._get_letterSpacing__31 = function () {
    return this._letterSpacing$delegate.getValue_3(this, letterSpacing$factory_1());
  };
  StyledComponent.prototype._set_lineHeight__31 = function (_set___) {
    return this._lineHeight$delegate.setValue_6(this, lineHeight$factory_0(), _set___);
  };
  StyledComponent.prototype._get_lineHeight__31 = function () {
    return this._lineHeight$delegate.getValue_3(this, lineHeight$factory_1());
  };
  StyledComponent.prototype._set_textAlign__31 = function (_set___) {
    return this._textAlign$delegate.setValue_6(this, textAlign$factory_0(), _set___);
  };
  StyledComponent.prototype._get_textAlign__31 = function () {
    return this._textAlign$delegate.getValue_3(this, textAlign$factory_1());
  };
  StyledComponent.prototype._set_textDecoration__31 = function (_set___) {
    return this._textDecoration$delegate.setValue_6(this, textDecoration$factory_0(), _set___);
  };
  StyledComponent.prototype._get_textDecoration__31 = function () {
    return this._textDecoration$delegate.getValue_3(this, textDecoration$factory_1());
  };
  StyledComponent.prototype._set_textIndent__31 = function (_set___) {
    return this._textIndent$delegate.setValue_6(this, textIndent$factory_0(), _set___);
  };
  StyledComponent.prototype._get_textIndent__31 = function () {
    return this._textIndent$delegate.getValue_3(this, textIndent$factory_1());
  };
  StyledComponent.prototype._set_textShadow__31 = function (_set___) {
    return this._textShadow$delegate.setValue_6(this, textShadow$factory_0(), _set___);
  };
  StyledComponent.prototype._get_textShadow__31 = function () {
    return this._textShadow$delegate.getValue_3(this, textShadow$factory_1());
  };
  StyledComponent.prototype._set_textTransform__31 = function (_set___) {
    return this._textTransform$delegate.setValue_6(this, textTransform$factory_0(), _set___);
  };
  StyledComponent.prototype._get_textTransform__31 = function () {
    return this._textTransform$delegate.getValue_3(this, textTransform$factory_1());
  };
  StyledComponent.prototype._set_textOverflow__31 = function (_set___) {
    return this._textOverflow$delegate.setValue_6(this, textOverflow$factory_0(), _set___);
  };
  StyledComponent.prototype._get_textOverflow__31 = function () {
    return this._textOverflow$delegate.getValue_3(this, textOverflow$factory_1());
  };
  StyledComponent.prototype._set_unicodeBidi__31 = function (_set___) {
    return this._unicodeBidi$delegate.setValue_6(this, unicodeBidi$factory_0(), _set___);
  };
  StyledComponent.prototype._get_unicodeBidi__31 = function () {
    return this._unicodeBidi$delegate.getValue_3(this, unicodeBidi$factory_1());
  };
  StyledComponent.prototype._set_verticalAlign__31 = function (_set___) {
    return this._verticalAlign$delegate.setValue_6(this, verticalAlign$factory_0(), _set___);
  };
  StyledComponent.prototype._get_verticalAlign__31 = function () {
    return this._verticalAlign$delegate.getValue_3(this, verticalAlign$factory_1());
  };
  StyledComponent.prototype._set_whiteSpace__31 = function (_set___) {
    return this._whiteSpace$delegate.setValue_6(this, whiteSpace$factory_0(), _set___);
  };
  StyledComponent.prototype._get_whiteSpace__31 = function () {
    return this._whiteSpace$delegate.getValue_3(this, whiteSpace$factory_1());
  };
  StyledComponent.prototype._set_wordSpacing__31 = function (_set___) {
    return this._wordSpacing$delegate.setValue_6(this, wordSpacing$factory_0(), _set___);
  };
  StyledComponent.prototype._get_wordSpacing__31 = function () {
    return this._wordSpacing$delegate.getValue_3(this, wordSpacing$factory_1());
  };
  StyledComponent.prototype._set_fontFamily__31 = function (_set___) {
    return this._fontFamily$delegate.setValue_6(this, fontFamily$factory_0(), _set___);
  };
  StyledComponent.prototype._get_fontFamily__31 = function () {
    return this._fontFamily$delegate.getValue_3(this, fontFamily$factory_1());
  };
  StyledComponent.prototype._set_fontSize__31 = function (_set___) {
    return this._fontSize$delegate.setValue_6(this, fontSize$factory_0(), _set___);
  };
  StyledComponent.prototype._get_fontSize__31 = function () {
    return this._fontSize$delegate.getValue_3(this, fontSize$factory_1());
  };
  StyledComponent.prototype._set_fontStyle__31 = function (_set___) {
    return this._fontStyle$delegate.setValue_6(this, fontStyle$factory_0(), _set___);
  };
  StyledComponent.prototype._get_fontStyle__31 = function () {
    return this._fontStyle$delegate.getValue_3(this, fontStyle$factory_1());
  };
  StyledComponent.prototype._set_fontWeight__31 = function (_set___) {
    return this._fontWeight$delegate.setValue_6(this, fontWeight$factory_0(), _set___);
  };
  StyledComponent.prototype._get_fontWeight__31 = function () {
    return this._fontWeight$delegate.getValue_3(this, fontWeight$factory_1());
  };
  StyledComponent.prototype._set_fontVariant__31 = function (_set___) {
    return this._fontVariant$delegate.setValue_6(this, fontVariant$factory_0(), _set___);
  };
  StyledComponent.prototype._get_fontVariant__31 = function () {
    return this._fontVariant$delegate.getValue_3(this, fontVariant$factory_1());
  };
  StyledComponent.prototype._set_float__31 = function (_set___) {
    return this._float$delegate.setValue_6(this, float$factory_0(), _set___);
  };
  StyledComponent.prototype._get_float__31 = function () {
    return this._float$delegate.getValue_3(this, float$factory_1());
  };
  StyledComponent.prototype._set_clear__31 = function (_set___) {
    return this._clear$delegate.setValue_6(this, clear$factory_0(), _set___);
  };
  StyledComponent.prototype._get_clear__31 = function () {
    return this._clear$delegate.getValue_3(this, clear$factory_1());
  };
  StyledComponent.prototype._set_wordBreak__31 = function (_set___) {
    return this._wordBreak$delegate.setValue_6(this, wordBreak$factory_0(), _set___);
  };
  StyledComponent.prototype._get_wordBreak__31 = function () {
    return this._wordBreak$delegate.getValue_3(this, wordBreak$factory_1());
  };
  StyledComponent.prototype._set_lineBreak__31 = function (_set___) {
    return this._lineBreak$delegate.setValue_6(this, lineBreak$factory_0(), _set___);
  };
  StyledComponent.prototype._get_lineBreak__31 = function () {
    return this._lineBreak$delegate.getValue_3(this, lineBreak$factory_1());
  };
  StyledComponent.prototype._set_cursor__31 = function (_set___) {
    return this._cursor$delegate.setValue_6(this, cursor$factory_0(), _set___);
  };
  StyledComponent.prototype._get_cursor__31 = function () {
    return this._cursor$delegate.getValue_3(this, cursor$factory_1());
  };
  StyledComponent.prototype._set_flexDirection__31 = function (_set___) {
    return this._flexDirection$delegate.setValue_6(this, flexDirection$factory_0(), _set___);
  };
  StyledComponent.prototype._get_flexDirection__31 = function () {
    return this._flexDirection$delegate.getValue_3(this, flexDirection$factory_1());
  };
  StyledComponent.prototype._set_flexWrap__31 = function (_set___) {
    return this._flexWrap$delegate.setValue_6(this, flexWrap$factory_0(), _set___);
  };
  StyledComponent.prototype._get_flexWrap__31 = function () {
    return this._flexWrap$delegate.getValue_3(this, flexWrap$factory_1());
  };
  StyledComponent.prototype._set_justifyItems__31 = function (_set___) {
    return this._justifyItems$delegate.setValue_6(this, justifyItems$factory_0(), _set___);
  };
  StyledComponent.prototype._get_justifyItems__31 = function () {
    return this._justifyItems$delegate.getValue_3(this, justifyItems$factory_1());
  };
  StyledComponent.prototype._set_justifyContent__31 = function (_set___) {
    return this._justifyContent$delegate.setValue_6(this, justifyContent$factory_0(), _set___);
  };
  StyledComponent.prototype._get_justifyContent__31 = function () {
    return this._justifyContent$delegate.getValue_3(this, justifyContent$factory_1());
  };
  StyledComponent.prototype._set_alignItems__31 = function (_set___) {
    return this._alignItems$delegate.setValue_6(this, alignItems$factory_0(), _set___);
  };
  StyledComponent.prototype._get_alignItems__31 = function () {
    return this._alignItems$delegate.getValue_3(this, alignItems$factory_1());
  };
  StyledComponent.prototype._set_alignContent__31 = function (_set___) {
    return this._alignContent$delegate.setValue_6(this, alignContent$factory_0(), _set___);
  };
  StyledComponent.prototype._get_alignContent__31 = function () {
    return this._alignContent$delegate.getValue_3(this, alignContent$factory_1());
  };
  StyledComponent.prototype._set_order__31 = function (_set___) {
    return this._order$delegate.setValue_6(this, order$factory_0(), _set___);
  };
  StyledComponent.prototype._get_order__31 = function () {
    return this._order$delegate.getValue_3(this, order$factory_1());
  };
  StyledComponent.prototype._set_flexGrow__31 = function (_set___) {
    return this._flexGrow$delegate.setValue_6(this, flexGrow$factory_0(), _set___);
  };
  StyledComponent.prototype._get_flexGrow__31 = function () {
    return this._flexGrow$delegate.getValue_3(this, flexGrow$factory_1());
  };
  StyledComponent.prototype._set_flexShrink__31 = function (_set___) {
    return this._flexShrink$delegate.setValue_6(this, flexShrink$factory_0(), _set___);
  };
  StyledComponent.prototype._get_flexShrink__31 = function () {
    return this._flexShrink$delegate.getValue_3(this, flexShrink$factory_1());
  };
  StyledComponent.prototype._set_flexBasis__31 = function (_set___) {
    return this._flexBasis$delegate.setValue_6(this, flexBasis$factory_0(), _set___);
  };
  StyledComponent.prototype._get_flexBasis__31 = function () {
    return this._flexBasis$delegate.getValue_3(this, flexBasis$factory_1());
  };
  StyledComponent.prototype._set_alignSelf__31 = function (_set___) {
    return this._alignSelf$delegate.setValue_6(this, alignSelf$factory_0(), _set___);
  };
  StyledComponent.prototype._get_alignSelf__31 = function () {
    return this._alignSelf$delegate.getValue_3(this, alignSelf$factory_1());
  };
  StyledComponent.prototype._set_justifySelf__31 = function (_set___) {
    return this._justifySelf$delegate.setValue_6(this, justifySelf$factory_0(), _set___);
  };
  StyledComponent.prototype._get_justifySelf__31 = function () {
    return this._justifySelf$delegate.getValue_3(this, justifySelf$factory_1());
  };
  StyledComponent.prototype._set_gridAutoColumns__31 = function (_set___) {
    return this._gridAutoColumns$delegate.setValue_6(this, gridAutoColumns$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridAutoColumns__31 = function () {
    return this._gridAutoColumns$delegate.getValue_3(this, gridAutoColumns$factory_1());
  };
  StyledComponent.prototype._set_gridAutoRows__31 = function (_set___) {
    return this._gridAutoRows$delegate.setValue_6(this, gridAutoRows$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridAutoRows__31 = function () {
    return this._gridAutoRows$delegate.getValue_3(this, gridAutoRows$factory_1());
  };
  StyledComponent.prototype._set_gridAutoFlow__31 = function (_set___) {
    return this._gridAutoFlow$delegate.setValue_6(this, gridAutoFlow$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridAutoFlow__31 = function () {
    return this._gridAutoFlow$delegate.getValue_3(this, gridAutoFlow$factory_1());
  };
  StyledComponent.prototype._set_gridTemplateColumns__31 = function (_set___) {
    return this._gridTemplateColumns$delegate.setValue_6(this, gridTemplateColumns$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridTemplateColumns__31 = function () {
    return this._gridTemplateColumns$delegate.getValue_3(this, gridTemplateColumns$factory_1());
  };
  StyledComponent.prototype._set_gridTemplateRows__31 = function (_set___) {
    return this._gridTemplateRows$delegate.setValue_6(this, gridTemplateRows$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridTemplateRows__31 = function () {
    return this._gridTemplateRows$delegate.getValue_3(this, gridTemplateRows$factory_1());
  };
  StyledComponent.prototype._set_gridTemplateAreas__31 = function (_set___) {
    return this._gridTemplateAreas$delegate.setValue_6(this, gridTemplateAreas$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridTemplateAreas__31 = function () {
    return this._gridTemplateAreas$delegate.getValue_3(this, gridTemplateAreas$factory_1());
  };
  StyledComponent.prototype._set_gridColumnGap__31 = function (_set___) {
    return this._gridColumnGap$delegate.setValue_6(this, gridColumnGap$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridColumnGap__31 = function () {
    return this._gridColumnGap$delegate.getValue_3(this, gridColumnGap$factory_1());
  };
  StyledComponent.prototype._set_gridRowGap__31 = function (_set___) {
    return this._gridRowGap$delegate.setValue_6(this, gridRowGap$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridRowGap__31 = function () {
    return this._gridRowGap$delegate.getValue_3(this, gridRowGap$factory_1());
  };
  StyledComponent.prototype._set_gridColumnStart__31 = function (_set___) {
    return this._gridColumnStart$delegate.setValue_6(this, gridColumnStart$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridColumnStart__31 = function () {
    return this._gridColumnStart$delegate.getValue_3(this, gridColumnStart$factory_1());
  };
  StyledComponent.prototype._set_gridRowStart__31 = function (_set___) {
    return this._gridRowStart$delegate.setValue_6(this, gridRowStart$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridRowStart__31 = function () {
    return this._gridRowStart$delegate.getValue_3(this, gridRowStart$factory_1());
  };
  StyledComponent.prototype._set_gridColumnEnd__31 = function (_set___) {
    return this._gridColumnEnd$delegate.setValue_6(this, gridColumnEnd$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridColumnEnd__31 = function () {
    return this._gridColumnEnd$delegate.getValue_3(this, gridColumnEnd$factory_1());
  };
  StyledComponent.prototype._set_gridRowEnd__31 = function (_set___) {
    return this._gridRowEnd$delegate.setValue_6(this, gridRowEnd$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridRowEnd__31 = function () {
    return this._gridRowEnd$delegate.getValue_3(this, gridRowEnd$factory_1());
  };
  StyledComponent.prototype._set_gridArea__31 = function (_set___) {
    return this._gridArea$delegate.setValue_6(this, gridArea$factory_0(), _set___);
  };
  StyledComponent.prototype._get_gridArea__31 = function () {
    return this._gridArea$delegate.getValue_3(this, gridArea$factory_1());
  };
  StyledComponent.prototype.refresh_33 = function () {
    this._snStyleCache = null;
    return this;
  };
  StyledComponent.prototype.getSnStyleInternal_32 = function () {
    var tmp0_elvis_lhs = this._snStyleCache;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var s_2 = this.getSnStyle_31();
      this._snStyleCache = s_2;
      tmp = s_2;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  StyledComponent.prototype.getSnStyle_31 = function () {
    var cacheKey = this.getCacheKey_31();
    var tmp0_elvis_lhs = Companion_getInstance_11()._globalStyleCache.get_20(cacheKey);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var snstyle_2 = ArrayList_init_$Create$();
      var tmp0_safe_receiver_3 = this._get_width__41();
      if (tmp0_safe_receiver_3 == null)
        null;
      else {
        snstyle_2.add_57(to('width', asString(tmp0_safe_receiver_3)));
      }
      Unit_getInstance();
      var tmp1_safe_receiver_4 = this._get_minWidth__31();
      if (tmp1_safe_receiver_4 == null)
        null;
      else {
        snstyle_2.add_57(to('min-width', asString(tmp1_safe_receiver_4)));
      }
      Unit_getInstance();
      var tmp2_safe_receiver_5 = this._get_maxWidth__31();
      if (tmp2_safe_receiver_5 == null)
        null;
      else {
        snstyle_2.add_57(to('max-width', asString(tmp2_safe_receiver_5)));
      }
      Unit_getInstance();
      var tmp3_safe_receiver_6 = this._get_height__41();
      if (tmp3_safe_receiver_6 == null)
        null;
      else {
        snstyle_2.add_57(to('height', asString(tmp3_safe_receiver_6)));
      }
      Unit_getInstance();
      var tmp4_safe_receiver_7 = this._get_minHeight__31();
      if (tmp4_safe_receiver_7 == null)
        null;
      else {
        snstyle_2.add_57(to('min-height', asString(tmp4_safe_receiver_7)));
      }
      Unit_getInstance();
      var tmp5_safe_receiver_8 = this._get_maxHeight__31();
      if (tmp5_safe_receiver_8 == null)
        null;
      else {
        snstyle_2.add_57(to('max-height', asString(tmp5_safe_receiver_8)));
      }
      Unit_getInstance();
      var tmp6_safe_receiver_9 = this._get_display__31();
      if (tmp6_safe_receiver_9 == null)
        null;
      else {
        snstyle_2.add_57(to('display', tmp6_safe_receiver_9._display));
      }
      Unit_getInstance();
      var tmp7_safe_receiver_10 = this._get_position__31();
      if (tmp7_safe_receiver_10 == null)
        null;
      else {
        snstyle_2.add_57(to('position', tmp7_safe_receiver_10._position));
      }
      Unit_getInstance();
      var tmp8_safe_receiver_11 = this._get_top__32();
      if (tmp8_safe_receiver_11 == null)
        null;
      else {
        snstyle_2.add_57(to('top', asString(tmp8_safe_receiver_11)));
      }
      Unit_getInstance();
      var tmp9_safe_receiver_12 = this._get_left__32();
      if (tmp9_safe_receiver_12 == null)
        null;
      else {
        snstyle_2.add_57(to('left', asString(tmp9_safe_receiver_12)));
      }
      Unit_getInstance();
      var tmp10_safe_receiver_13 = this._get_right__32();
      if (tmp10_safe_receiver_13 == null)
        null;
      else {
        snstyle_2.add_57(to('right', asString(tmp10_safe_receiver_13)));
      }
      Unit_getInstance();
      var tmp11_safe_receiver_14 = this._get_bottom__32();
      if (tmp11_safe_receiver_14 == null)
        null;
      else {
        snstyle_2.add_57(to('bottom', asString(tmp11_safe_receiver_14)));
      }
      Unit_getInstance();
      var tmp12_safe_receiver_15 = this._get_zIndex__31();
      if (tmp12_safe_receiver_15 == null)
        null;
      else {
        snstyle_2.add_57(to('z-index', '' + tmp12_safe_receiver_15));
      }
      Unit_getInstance();
      var tmp13_safe_receiver_16 = this._get_overflow__31();
      if (tmp13_safe_receiver_16 == null)
        null;
      else {
        snstyle_2.add_57(to('overflow', tmp13_safe_receiver_16._overflow));
      }
      Unit_getInstance();
      var tmp14_safe_receiver_17 = this._get_overflowWrap__31();
      if (tmp14_safe_receiver_17 == null)
        null;
      else {
        snstyle_2.add_57(to('overflow-wrap', tmp14_safe_receiver_17._overflowWrap));
      }
      Unit_getInstance();
      var tmp15_safe_receiver_18 = this._get_resize__31();
      if (tmp15_safe_receiver_18 == null)
        null;
      else {
        snstyle_2.add_57(to('resize', tmp15_safe_receiver_18._resize));
      }
      Unit_getInstance();
      var tmp16_safe_receiver_19 = this._get_border__31();
      if (tmp16_safe_receiver_19 == null)
        null;
      else {
        snstyle_2.add_57(to('border', tmp16_safe_receiver_19.asString_3()));
      }
      Unit_getInstance();
      var tmp17_safe_receiver_20 = this._get_borderTop__31();
      if (tmp17_safe_receiver_20 == null)
        null;
      else {
        snstyle_2.add_57(to('border-top', tmp17_safe_receiver_20.asString_3()));
      }
      Unit_getInstance();
      var tmp18_safe_receiver_21 = this._get_borderRight__31();
      if (tmp18_safe_receiver_21 == null)
        null;
      else {
        snstyle_2.add_57(to('border-right', tmp18_safe_receiver_21.asString_3()));
      }
      Unit_getInstance();
      var tmp19_safe_receiver_22 = this._get_borderBottom__31();
      if (tmp19_safe_receiver_22 == null)
        null;
      else {
        snstyle_2.add_57(to('border-bottom', tmp19_safe_receiver_22.asString_3()));
      }
      Unit_getInstance();
      var tmp20_safe_receiver_23 = this._get_borderLeft__31();
      if (tmp20_safe_receiver_23 == null)
        null;
      else {
        snstyle_2.add_57(to('border-left', tmp20_safe_receiver_23.asString_3()));
      }
      Unit_getInstance();
      var tmp21_safe_receiver_24 = this._get_margin__31();
      if (tmp21_safe_receiver_24 == null)
        null;
      else {
        snstyle_2.add_57(to('margin', asString(tmp21_safe_receiver_24)));
      }
      Unit_getInstance();
      var tmp22_safe_receiver_25 = this._get_marginTop__31();
      if (tmp22_safe_receiver_25 == null)
        null;
      else {
        snstyle_2.add_57(to('margin-top', asString(tmp22_safe_receiver_25)));
      }
      Unit_getInstance();
      var tmp23_safe_receiver_26 = this._get_marginRight__31();
      if (tmp23_safe_receiver_26 == null)
        null;
      else {
        snstyle_2.add_57(to('margin-right', asString(tmp23_safe_receiver_26)));
      }
      Unit_getInstance();
      var tmp24_safe_receiver_27 = this._get_marginBottom__31();
      if (tmp24_safe_receiver_27 == null)
        null;
      else {
        snstyle_2.add_57(to('margin-bottom', asString(tmp24_safe_receiver_27)));
      }
      Unit_getInstance();
      var tmp25_safe_receiver_28 = this._get_marginLeft__31();
      if (tmp25_safe_receiver_28 == null)
        null;
      else {
        snstyle_2.add_57(to('margin-left', asString(tmp25_safe_receiver_28)));
      }
      Unit_getInstance();
      var tmp26_safe_receiver_29 = this._get_padding__31();
      if (tmp26_safe_receiver_29 == null)
        null;
      else {
        snstyle_2.add_57(to('padding', asString(tmp26_safe_receiver_29)));
      }
      Unit_getInstance();
      var tmp27_safe_receiver_30 = this._get_paddingTop__31();
      if (tmp27_safe_receiver_30 == null)
        null;
      else {
        snstyle_2.add_57(to('padding-top', asString(tmp27_safe_receiver_30)));
      }
      Unit_getInstance();
      var tmp28_safe_receiver_31 = this._get_paddingRight__31();
      if (tmp28_safe_receiver_31 == null)
        null;
      else {
        snstyle_2.add_57(to('padding-right', asString(tmp28_safe_receiver_31)));
      }
      Unit_getInstance();
      var tmp29_safe_receiver_32 = this._get_paddingBottom__31();
      if (tmp29_safe_receiver_32 == null)
        null;
      else {
        snstyle_2.add_57(to('padding-bottom', asString(tmp29_safe_receiver_32)));
      }
      Unit_getInstance();
      var tmp30_safe_receiver_33 = this._get_paddingLeft__31();
      if (tmp30_safe_receiver_33 == null)
        null;
      else {
        snstyle_2.add_57(to('padding-left', asString(tmp30_safe_receiver_33)));
      }
      Unit_getInstance();
      var tmp31_safe_receiver_34 = this._get_color__31();
      if (tmp31_safe_receiver_34 == null)
        null;
      else {
        snstyle_2.add_57(to('color', tmp31_safe_receiver_34.asString_3()));
      }
      Unit_getInstance();
      var tmp32_safe_receiver_35 = this._get_opacity__31();
      if (tmp32_safe_receiver_35 == null)
        null;
      else {
        snstyle_2.add_57(to('opacity', '' + tmp32_safe_receiver_35));
      }
      Unit_getInstance();
      var tmp33_safe_receiver_36 = this._get_background__31();
      if (tmp33_safe_receiver_36 == null)
        null;
      else {
        snstyle_2.add_57(to('background', tmp33_safe_receiver_36.asString_3()));
      }
      Unit_getInstance();
      var tmp34_safe_receiver_37 = this._get_textDirection__31();
      if (tmp34_safe_receiver_37 == null)
        null;
      else {
        snstyle_2.add_57(to('direction', tmp34_safe_receiver_37._direction));
      }
      Unit_getInstance();
      var tmp35_safe_receiver_38 = this._get_letterSpacing__31();
      if (tmp35_safe_receiver_38 == null)
        null;
      else {
        snstyle_2.add_57(to('letter-spacing', asString(tmp35_safe_receiver_38)));
      }
      Unit_getInstance();
      var tmp36_safe_receiver_39 = this._get_lineHeight__31();
      if (tmp36_safe_receiver_39 == null)
        null;
      else {
        snstyle_2.add_57(to('line-height', asString(tmp36_safe_receiver_39)));
      }
      Unit_getInstance();
      var tmp37_safe_receiver_40 = this._get_textAlign__31();
      if (tmp37_safe_receiver_40 == null)
        null;
      else {
        snstyle_2.add_57(to('text-align', tmp37_safe_receiver_40._textAlign));
      }
      Unit_getInstance();
      var tmp38_safe_receiver_41 = this._get_textDecoration__31();
      if (tmp38_safe_receiver_41 == null)
        null;
      else {
        snstyle_2.add_57(to('text-decoration', tmp38_safe_receiver_41.asString_3()));
      }
      Unit_getInstance();
      var tmp39_safe_receiver_42 = this._get_textIndent__31();
      if (tmp39_safe_receiver_42 == null)
        null;
      else {
        snstyle_2.add_57(to('text-indent', asString(tmp39_safe_receiver_42)));
      }
      Unit_getInstance();
      var tmp40_safe_receiver_43 = this._get_textShadow__31();
      if (tmp40_safe_receiver_43 == null)
        null;
      else {
        snstyle_2.add_57(to('text-shadow', tmp40_safe_receiver_43.asString_3()));
      }
      Unit_getInstance();
      var tmp41_safe_receiver_44 = this._get_textTransform__31();
      if (tmp41_safe_receiver_44 == null)
        null;
      else {
        snstyle_2.add_57(to('text-transform', tmp41_safe_receiver_44._textTransform));
      }
      Unit_getInstance();
      var tmp42_safe_receiver_45 = this._get_textOverflow__31();
      if (tmp42_safe_receiver_45 == null)
        null;
      else {
        snstyle_2.add_57(to('text-overflow', tmp42_safe_receiver_45._textOverflow));
      }
      Unit_getInstance();
      var tmp43_safe_receiver_46 = this._get_unicodeBidi__31();
      if (tmp43_safe_receiver_46 == null)
        null;
      else {
        snstyle_2.add_57(to('unicode-bidi', tmp43_safe_receiver_46._unicodeBidi));
      }
      Unit_getInstance();
      var tmp44_safe_receiver_47 = this._get_verticalAlign__31();
      if (tmp44_safe_receiver_47 == null)
        null;
      else {
        snstyle_2.add_57(to('vertical-align', tmp44_safe_receiver_47._verticalAlign));
      }
      Unit_getInstance();
      var tmp45_safe_receiver_48 = this._get_whiteSpace__31();
      if (tmp45_safe_receiver_48 == null)
        null;
      else {
        snstyle_2.add_57(to('white-space', tmp45_safe_receiver_48._whiteSpace));
      }
      Unit_getInstance();
      var tmp46_safe_receiver_49 = this._get_wordSpacing__31();
      if (tmp46_safe_receiver_49 == null)
        null;
      else {
        snstyle_2.add_57(to('word-spacing', asString(tmp46_safe_receiver_49)));
      }
      Unit_getInstance();
      var tmp47_safe_receiver_50 = this._get_fontFamily__31();
      if (tmp47_safe_receiver_50 == null)
        null;
      else {
        snstyle_2.add_57(to('font-family', tmp47_safe_receiver_50));
      }
      Unit_getInstance();
      var tmp48_safe_receiver_51 = this._get_fontSize__31();
      if (tmp48_safe_receiver_51 == null)
        null;
      else {
        snstyle_2.add_57(to('font-size', asString(tmp48_safe_receiver_51)));
      }
      Unit_getInstance();
      var tmp49_safe_receiver_52 = this._get_fontStyle__31();
      if (tmp49_safe_receiver_52 == null)
        null;
      else {
        snstyle_2.add_57(to('font-style', tmp49_safe_receiver_52._fontStyle));
      }
      Unit_getInstance();
      var tmp50_safe_receiver_53 = this._get_fontWeight__31();
      if (tmp50_safe_receiver_53 == null)
        null;
      else {
        snstyle_2.add_57(to('font-weight', tmp50_safe_receiver_53._fontWeight));
      }
      Unit_getInstance();
      var tmp51_safe_receiver_54 = this._get_fontVariant__31();
      if (tmp51_safe_receiver_54 == null)
        null;
      else {
        snstyle_2.add_57(to('font-variant', tmp51_safe_receiver_54._fontVariant));
      }
      Unit_getInstance();
      var tmp52_safe_receiver_55 = this._get_float__31();
      if (tmp52_safe_receiver_55 == null)
        null;
      else {
        snstyle_2.add_57(to('float', tmp52_safe_receiver_55._posFloat));
      }
      Unit_getInstance();
      var tmp53_safe_receiver_56 = this._get_clear__31();
      if (tmp53_safe_receiver_56 == null)
        null;
      else {
        snstyle_2.add_57(to('clear', tmp53_safe_receiver_56._clear));
      }
      Unit_getInstance();
      var tmp54_safe_receiver_57 = this._get_wordBreak__31();
      if (tmp54_safe_receiver_57 == null)
        null;
      else {
        snstyle_2.add_57(to('word-break', tmp54_safe_receiver_57._wordBreak));
      }
      Unit_getInstance();
      var tmp55_safe_receiver_58 = this._get_lineBreak__31();
      if (tmp55_safe_receiver_58 == null)
        null;
      else {
        snstyle_2.add_57(to('line-break', tmp55_safe_receiver_58._lineBreak));
      }
      Unit_getInstance();
      var tmp56_safe_receiver_59 = this._get_cursor__31();
      if (tmp56_safe_receiver_59 == null)
        null;
      else {
        snstyle_2.add_57(to('cursor', tmp56_safe_receiver_59._cursor));
      }
      Unit_getInstance();
      var tmp57_safe_receiver_60 = this._get_flexDirection__31();
      if (tmp57_safe_receiver_60 == null)
        null;
      else {
        snstyle_2.add_57(to('flex-direction', tmp57_safe_receiver_60._dir));
      }
      Unit_getInstance();
      var tmp58_safe_receiver_61 = this._get_flexWrap__31();
      if (tmp58_safe_receiver_61 == null)
        null;
      else {
        snstyle_2.add_57(to('flex-wrap', tmp58_safe_receiver_61._wrap));
      }
      Unit_getInstance();
      var tmp59_safe_receiver_62 = this._get_justifyItems__31();
      if (tmp59_safe_receiver_62 == null)
        null;
      else {
        snstyle_2.add_57(to('justify-items', tmp59_safe_receiver_62._justify));
      }
      Unit_getInstance();
      var tmp60_safe_receiver_63 = this._get_justifyContent__31();
      if (tmp60_safe_receiver_63 == null)
        null;
      else {
        snstyle_2.add_57(to('justify-content', tmp60_safe_receiver_63._justifyContent));
      }
      Unit_getInstance();
      var tmp61_safe_receiver_64 = this._get_alignItems__31();
      if (tmp61_safe_receiver_64 == null)
        null;
      else {
        snstyle_2.add_57(to('align-items', tmp61_safe_receiver_64._alignItems));
      }
      Unit_getInstance();
      var tmp62_safe_receiver_65 = this._get_alignContent__31();
      if (tmp62_safe_receiver_65 == null)
        null;
      else {
        snstyle_2.add_57(to('align-content', tmp62_safe_receiver_65._alignContent));
      }
      Unit_getInstance();
      var tmp63_safe_receiver_66 = this._get_order__31();
      if (tmp63_safe_receiver_66 == null)
        null;
      else {
        snstyle_2.add_57(to('order', '' + tmp63_safe_receiver_66));
      }
      Unit_getInstance();
      var tmp64_safe_receiver_67 = this._get_flexGrow__31();
      if (tmp64_safe_receiver_67 == null)
        null;
      else {
        snstyle_2.add_57(to('flex-grow', '' + tmp64_safe_receiver_67));
      }
      Unit_getInstance();
      var tmp65_safe_receiver_68 = this._get_flexShrink__31();
      if (tmp65_safe_receiver_68 == null)
        null;
      else {
        snstyle_2.add_57(to('flex-shrink', '' + tmp65_safe_receiver_68));
      }
      Unit_getInstance();
      var tmp66_safe_receiver_69 = this._get_flexBasis__31();
      if (tmp66_safe_receiver_69 == null)
        null;
      else {
        snstyle_2.add_57(to('flex-basis', asString(tmp66_safe_receiver_69)));
      }
      Unit_getInstance();
      var tmp67_safe_receiver_70 = this._get_alignSelf__31();
      if (tmp67_safe_receiver_70 == null)
        null;
      else {
        snstyle_2.add_57(to('align-self', tmp67_safe_receiver_70._alignItems));
      }
      Unit_getInstance();
      var tmp68_safe_receiver_71 = this._get_justifySelf__31();
      if (tmp68_safe_receiver_71 == null)
        null;
      else {
        snstyle_2.add_57(to('justify-self', tmp68_safe_receiver_71._justify));
      }
      Unit_getInstance();
      var tmp69_safe_receiver_72 = this._get_gridAutoColumns__31();
      if (tmp69_safe_receiver_72 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-auto-columns', tmp69_safe_receiver_72));
      }
      Unit_getInstance();
      var tmp70_safe_receiver_73 = this._get_gridAutoRows__31();
      if (tmp70_safe_receiver_73 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-auto-rows', tmp70_safe_receiver_73));
      }
      Unit_getInstance();
      var tmp71_safe_receiver_74 = this._get_gridAutoFlow__31();
      if (tmp71_safe_receiver_74 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-auto-flow', tmp71_safe_receiver_74._flow));
      }
      Unit_getInstance();
      var tmp72_safe_receiver_75 = this._get_gridTemplateColumns__31();
      if (tmp72_safe_receiver_75 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-template-columns', tmp72_safe_receiver_75));
      }
      Unit_getInstance();
      var tmp73_safe_receiver_76 = this._get_gridTemplateRows__31();
      if (tmp73_safe_receiver_76 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-template-rows', tmp73_safe_receiver_76));
      }
      Unit_getInstance();
      var tmp74_safe_receiver_77 = this._get_gridTemplateAreas__31();
      if (tmp74_safe_receiver_77 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-template-areas', joinToString$default_0(tmp74_safe_receiver_77, '\n', null, null, 0, null, null, 62, null)));
      }
      Unit_getInstance();
      var tmp75_safe_receiver_78 = this._get_gridColumnGap__31();
      if (tmp75_safe_receiver_78 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-column-gap', '' + tmp75_safe_receiver_78 + 'px'));
      }
      Unit_getInstance();
      var tmp76_safe_receiver_79 = this._get_gridRowGap__31();
      if (tmp76_safe_receiver_79 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-row-gap', '' + tmp76_safe_receiver_79 + 'px'));
      }
      Unit_getInstance();
      var tmp77_safe_receiver_80 = this._get_gridColumnStart__31();
      if (tmp77_safe_receiver_80 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-column-start', '' + tmp77_safe_receiver_80));
      }
      Unit_getInstance();
      var tmp78_safe_receiver_81 = this._get_gridRowStart__31();
      if (tmp78_safe_receiver_81 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-row-start', '' + tmp78_safe_receiver_81));
      }
      Unit_getInstance();
      var tmp79_safe_receiver_82 = this._get_gridColumnEnd__31();
      if (tmp79_safe_receiver_82 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-column-end', tmp79_safe_receiver_82));
      }
      Unit_getInstance();
      var tmp80_safe_receiver_83 = this._get_gridRowEnd__31();
      if (tmp80_safe_receiver_83 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-row-end', tmp80_safe_receiver_83));
      }
      Unit_getInstance();
      var tmp81_safe_receiver_84 = this._get_gridArea__31();
      if (tmp81_safe_receiver_84 == null)
        null;
      else {
        snstyle_2.add_57(to('grid-area', tmp81_safe_receiver_84));
      }
      Unit_getInstance();
      var tmp0_unsafeCast_0_86 = Object.keys(this._customStyles);
      var indexedObject = tmp0_unsafeCast_0_86;
      var inductionVariable = 0;
      var last_1 = indexedObject.length;
      while (inductionVariable < last_1) {
        var key_87 = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        snstyle_2.add_57(new Pair(key_87, this._customStyles[key_87]));
        Unit_getInstance();
      }
      Companion_getInstance_11()._globalStyleCache.set_4(cacheKey, snstyle_2);
      Unit_getInstance();
      return snstyle_2;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  StyledComponent.prototype.getCacheKey_31 = function () {
    return JSON.stringify(this._propertyValues_0) + JSON.stringify(this._customStyles);
  };
  StyledComponent.$metadata$ = {
    simpleName: 'StyledComponent',
    kind: 'class',
    interfaces: []
  };
  function width$factory() {
    return getPropertyCallableRef('width', 1, KMutableProperty1, function (receiver) {
      return receiver._get_width__41();
    }, function (receiver_0, value) {
      return receiver_0._set_width__31(value);
    });
  }
  function minWidth$factory() {
    return getPropertyCallableRef('minWidth', 1, KMutableProperty1, function (receiver) {
      return receiver._get_minWidth__31();
    }, function (receiver_0, value) {
      return receiver_0._set_minWidth__31(value);
    });
  }
  function maxWidth$factory() {
    return getPropertyCallableRef('maxWidth', 1, KMutableProperty1, function (receiver) {
      return receiver._get_maxWidth__31();
    }, function (receiver_0, value) {
      return receiver_0._set_maxWidth__31(value);
    });
  }
  function height$factory() {
    return getPropertyCallableRef('height', 1, KMutableProperty1, function (receiver) {
      return receiver._get_height__41();
    }, function (receiver_0, value) {
      return receiver_0._set_height__31(value);
    });
  }
  function minHeight$factory() {
    return getPropertyCallableRef('minHeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_minHeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_minHeight__31(value);
    });
  }
  function maxHeight$factory() {
    return getPropertyCallableRef('maxHeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_maxHeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_maxHeight__31(value);
    });
  }
  function display$factory() {
    return getPropertyCallableRef('display', 1, KMutableProperty1, function (receiver) {
      return receiver._get_display__31();
    }, function (receiver_0, value) {
      return receiver_0._set_display__31(value);
    });
  }
  function position$factory() {
    return getPropertyCallableRef('position', 1, KMutableProperty1, function (receiver) {
      return receiver._get_position__31();
    }, function (receiver_0, value) {
      return receiver_0._set_position__31(value);
    });
  }
  function top$factory() {
    return getPropertyCallableRef('top', 1, KMutableProperty1, function (receiver) {
      return receiver._get_top__32();
    }, function (receiver_0, value) {
      return receiver_0._set_top__31(value);
    });
  }
  function left$factory() {
    return getPropertyCallableRef('left', 1, KMutableProperty1, function (receiver) {
      return receiver._get_left__32();
    }, function (receiver_0, value) {
      return receiver_0._set_left__31(value);
    });
  }
  function right$factory() {
    return getPropertyCallableRef('right', 1, KMutableProperty1, function (receiver) {
      return receiver._get_right__32();
    }, function (receiver_0, value) {
      return receiver_0._set_right__31(value);
    });
  }
  function bottom$factory() {
    return getPropertyCallableRef('bottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_bottom__32();
    }, function (receiver_0, value) {
      return receiver_0._set_bottom__31(value);
    });
  }
  function zIndex$factory() {
    return getPropertyCallableRef('zIndex', 1, KMutableProperty1, function (receiver) {
      return receiver._get_zIndex__31();
    }, function (receiver_0, value) {
      return receiver_0._set_zIndex__31(value);
    });
  }
  function overflow$factory() {
    return getPropertyCallableRef('overflow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_overflow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_overflow__31(value);
    });
  }
  function overflowWrap$factory() {
    return getPropertyCallableRef('overflowWrap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_overflowWrap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_overflowWrap__31(value);
    });
  }
  function resize$factory() {
    return getPropertyCallableRef('resize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_resize__31();
    }, function (receiver_0, value) {
      return receiver_0._set_resize__31(value);
    });
  }
  function border$factory() {
    return getPropertyCallableRef('border', 1, KMutableProperty1, function (receiver) {
      return receiver._get_border__31();
    }, function (receiver_0, value) {
      return receiver_0._set_border__31(value);
    });
  }
  function borderTop$factory() {
    return getPropertyCallableRef('borderTop', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderTop__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderTop__31(value);
    });
  }
  function borderRight$factory() {
    return getPropertyCallableRef('borderRight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderRight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderRight__31(value);
    });
  }
  function borderBottom$factory() {
    return getPropertyCallableRef('borderBottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderBottom__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderBottom__31(value);
    });
  }
  function borderLeft$factory() {
    return getPropertyCallableRef('borderLeft', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderLeft__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderLeft__31(value);
    });
  }
  function margin$factory() {
    return getPropertyCallableRef('margin', 1, KMutableProperty1, function (receiver) {
      return receiver._get_margin__31();
    }, function (receiver_0, value) {
      return receiver_0._set_margin__31(value);
    });
  }
  function marginTop$factory() {
    return getPropertyCallableRef('marginTop', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginTop__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginTop__31(value);
    });
  }
  function marginRight$factory() {
    return getPropertyCallableRef('marginRight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginRight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginRight__31(value);
    });
  }
  function marginBottom$factory() {
    return getPropertyCallableRef('marginBottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginBottom__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginBottom__31(value);
    });
  }
  function marginLeft$factory() {
    return getPropertyCallableRef('marginLeft', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginLeft__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginLeft__31(value);
    });
  }
  function padding$factory() {
    return getPropertyCallableRef('padding', 1, KMutableProperty1, function (receiver) {
      return receiver._get_padding__31();
    }, function (receiver_0, value) {
      return receiver_0._set_padding__31(value);
    });
  }
  function paddingTop$factory() {
    return getPropertyCallableRef('paddingTop', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingTop__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingTop__31(value);
    });
  }
  function paddingRight$factory() {
    return getPropertyCallableRef('paddingRight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingRight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingRight__31(value);
    });
  }
  function paddingBottom$factory() {
    return getPropertyCallableRef('paddingBottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingBottom__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingBottom__31(value);
    });
  }
  function paddingLeft$factory() {
    return getPropertyCallableRef('paddingLeft', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingLeft__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingLeft__31(value);
    });
  }
  function color$factory() {
    return getPropertyCallableRef('color', 1, KMutableProperty1, function (receiver) {
      return receiver._get_color__31();
    }, function (receiver_0, value) {
      return receiver_0._set_color__31(value);
    });
  }
  function opacity$factory() {
    return getPropertyCallableRef('opacity', 1, KMutableProperty1, function (receiver) {
      return receiver._get_opacity__31();
    }, function (receiver_0, value) {
      return receiver_0._set_opacity__31(value);
    });
  }
  function background$factory() {
    return getPropertyCallableRef('background', 1, KMutableProperty1, function (receiver) {
      return receiver._get_background__31();
    }, function (receiver_0, value) {
      return receiver_0._set_background__31(value);
    });
  }
  function textDirection$factory() {
    return getPropertyCallableRef('textDirection', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textDirection__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textDirection__31(value);
    });
  }
  function letterSpacing$factory() {
    return getPropertyCallableRef('letterSpacing', 1, KMutableProperty1, function (receiver) {
      return receiver._get_letterSpacing__31();
    }, function (receiver_0, value) {
      return receiver_0._set_letterSpacing__31(value);
    });
  }
  function lineHeight$factory() {
    return getPropertyCallableRef('lineHeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_lineHeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_lineHeight__31(value);
    });
  }
  function textAlign$factory() {
    return getPropertyCallableRef('textAlign', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textAlign__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textAlign__31(value);
    });
  }
  function textDecoration$factory() {
    return getPropertyCallableRef('textDecoration', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textDecoration__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textDecoration__31(value);
    });
  }
  function textIndent$factory() {
    return getPropertyCallableRef('textIndent', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textIndent__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textIndent__31(value);
    });
  }
  function textShadow$factory() {
    return getPropertyCallableRef('textShadow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textShadow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textShadow__31(value);
    });
  }
  function textTransform$factory() {
    return getPropertyCallableRef('textTransform', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textTransform__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textTransform__31(value);
    });
  }
  function textOverflow$factory() {
    return getPropertyCallableRef('textOverflow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textOverflow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textOverflow__31(value);
    });
  }
  function unicodeBidi$factory() {
    return getPropertyCallableRef('unicodeBidi', 1, KMutableProperty1, function (receiver) {
      return receiver._get_unicodeBidi__31();
    }, function (receiver_0, value) {
      return receiver_0._set_unicodeBidi__31(value);
    });
  }
  function verticalAlign$factory() {
    return getPropertyCallableRef('verticalAlign', 1, KMutableProperty1, function (receiver) {
      return receiver._get_verticalAlign__31();
    }, function (receiver_0, value) {
      return receiver_0._set_verticalAlign__31(value);
    });
  }
  function whiteSpace$factory() {
    return getPropertyCallableRef('whiteSpace', 1, KMutableProperty1, function (receiver) {
      return receiver._get_whiteSpace__31();
    }, function (receiver_0, value) {
      return receiver_0._set_whiteSpace__31(value);
    });
  }
  function wordSpacing$factory() {
    return getPropertyCallableRef('wordSpacing', 1, KMutableProperty1, function (receiver) {
      return receiver._get_wordSpacing__31();
    }, function (receiver_0, value) {
      return receiver_0._set_wordSpacing__31(value);
    });
  }
  function fontFamily$factory() {
    return getPropertyCallableRef('fontFamily', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontFamily__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontFamily__31(value);
    });
  }
  function fontSize$factory() {
    return getPropertyCallableRef('fontSize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontSize__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontSize__31(value);
    });
  }
  function fontStyle$factory() {
    return getPropertyCallableRef('fontStyle', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontStyle__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontStyle__31(value);
    });
  }
  function fontWeight$factory() {
    return getPropertyCallableRef('fontWeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontWeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontWeight__31(value);
    });
  }
  function fontVariant$factory() {
    return getPropertyCallableRef('fontVariant', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontVariant__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontVariant__31(value);
    });
  }
  function float$factory() {
    return getPropertyCallableRef('float', 1, KMutableProperty1, function (receiver) {
      return receiver._get_float__31();
    }, function (receiver_0, value) {
      return receiver_0._set_float__31(value);
    });
  }
  function clear$factory() {
    return getPropertyCallableRef('clear', 1, KMutableProperty1, function (receiver) {
      return receiver._get_clear__31();
    }, function (receiver_0, value) {
      return receiver_0._set_clear__31(value);
    });
  }
  function wordBreak$factory() {
    return getPropertyCallableRef('wordBreak', 1, KMutableProperty1, function (receiver) {
      return receiver._get_wordBreak__31();
    }, function (receiver_0, value) {
      return receiver_0._set_wordBreak__31(value);
    });
  }
  function lineBreak$factory() {
    return getPropertyCallableRef('lineBreak', 1, KMutableProperty1, function (receiver) {
      return receiver._get_lineBreak__31();
    }, function (receiver_0, value) {
      return receiver_0._set_lineBreak__31(value);
    });
  }
  function cursor$factory() {
    return getPropertyCallableRef('cursor', 1, KMutableProperty1, function (receiver) {
      return receiver._get_cursor__31();
    }, function (receiver_0, value) {
      return receiver_0._set_cursor__31(value);
    });
  }
  function flexDirection$factory() {
    return getPropertyCallableRef('flexDirection', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexDirection__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexDirection__31(value);
    });
  }
  function flexWrap$factory() {
    return getPropertyCallableRef('flexWrap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexWrap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexWrap__31(value);
    });
  }
  function justifyItems$factory() {
    return getPropertyCallableRef('justifyItems', 1, KMutableProperty1, function (receiver) {
      return receiver._get_justifyItems__31();
    }, function (receiver_0, value) {
      return receiver_0._set_justifyItems__31(value);
    });
  }
  function justifyContent$factory() {
    return getPropertyCallableRef('justifyContent', 1, KMutableProperty1, function (receiver) {
      return receiver._get_justifyContent__31();
    }, function (receiver_0, value) {
      return receiver_0._set_justifyContent__31(value);
    });
  }
  function alignItems$factory() {
    return getPropertyCallableRef('alignItems', 1, KMutableProperty1, function (receiver) {
      return receiver._get_alignItems__31();
    }, function (receiver_0, value) {
      return receiver_0._set_alignItems__31(value);
    });
  }
  function alignContent$factory() {
    return getPropertyCallableRef('alignContent', 1, KMutableProperty1, function (receiver) {
      return receiver._get_alignContent__31();
    }, function (receiver_0, value) {
      return receiver_0._set_alignContent__31(value);
    });
  }
  function order$factory() {
    return getPropertyCallableRef('order', 1, KMutableProperty1, function (receiver) {
      return receiver._get_order__31();
    }, function (receiver_0, value) {
      return receiver_0._set_order__31(value);
    });
  }
  function flexGrow$factory() {
    return getPropertyCallableRef('flexGrow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexGrow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexGrow__31(value);
    });
  }
  function flexShrink$factory() {
    return getPropertyCallableRef('flexShrink', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexShrink__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexShrink__31(value);
    });
  }
  function flexBasis$factory() {
    return getPropertyCallableRef('flexBasis', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexBasis__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexBasis__31(value);
    });
  }
  function alignSelf$factory() {
    return getPropertyCallableRef('alignSelf', 1, KMutableProperty1, function (receiver) {
      return receiver._get_alignSelf__31();
    }, function (receiver_0, value) {
      return receiver_0._set_alignSelf__31(value);
    });
  }
  function justifySelf$factory() {
    return getPropertyCallableRef('justifySelf', 1, KMutableProperty1, function (receiver) {
      return receiver._get_justifySelf__31();
    }, function (receiver_0, value) {
      return receiver_0._set_justifySelf__31(value);
    });
  }
  function gridAutoColumns$factory() {
    return getPropertyCallableRef('gridAutoColumns', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridAutoColumns__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridAutoColumns__31(value);
    });
  }
  function gridAutoRows$factory() {
    return getPropertyCallableRef('gridAutoRows', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridAutoRows__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridAutoRows__31(value);
    });
  }
  function gridAutoFlow$factory() {
    return getPropertyCallableRef('gridAutoFlow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridAutoFlow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridAutoFlow__31(value);
    });
  }
  function gridTemplateColumns$factory() {
    return getPropertyCallableRef('gridTemplateColumns', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridTemplateColumns__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridTemplateColumns__31(value);
    });
  }
  function gridTemplateRows$factory() {
    return getPropertyCallableRef('gridTemplateRows', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridTemplateRows__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridTemplateRows__31(value);
    });
  }
  function gridTemplateAreas$factory() {
    return getPropertyCallableRef('gridTemplateAreas', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridTemplateAreas__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridTemplateAreas__31(value);
    });
  }
  function gridColumnGap$factory() {
    return getPropertyCallableRef('gridColumnGap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridColumnGap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridColumnGap__31(value);
    });
  }
  function gridRowGap$factory() {
    return getPropertyCallableRef('gridRowGap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridRowGap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridRowGap__31(value);
    });
  }
  function gridColumnStart$factory() {
    return getPropertyCallableRef('gridColumnStart', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridColumnStart__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridColumnStart__31(value);
    });
  }
  function gridRowStart$factory() {
    return getPropertyCallableRef('gridRowStart', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridRowStart__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridRowStart__31(value);
    });
  }
  function gridColumnEnd$factory() {
    return getPropertyCallableRef('gridColumnEnd', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridColumnEnd__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridColumnEnd__31(value);
    });
  }
  function gridRowEnd$factory() {
    return getPropertyCallableRef('gridRowEnd', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridRowEnd__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridRowEnd__31(value);
    });
  }
  function gridArea$factory() {
    return getPropertyCallableRef('gridArea', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridArea__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridArea__31(value);
    });
  }
  function width$factory_0() {
    return getPropertyCallableRef('width', 1, KMutableProperty1, function (receiver) {
      return receiver._get_width__41();
    }, function (receiver_0, value) {
      return receiver_0._set_width__31(value);
    });
  }
  function width$factory_1() {
    return getPropertyCallableRef('width', 1, KMutableProperty1, function (receiver) {
      return receiver._get_width__41();
    }, function (receiver_0, value) {
      return receiver_0._set_width__31(value);
    });
  }
  function minWidth$factory_0() {
    return getPropertyCallableRef('minWidth', 1, KMutableProperty1, function (receiver) {
      return receiver._get_minWidth__31();
    }, function (receiver_0, value) {
      return receiver_0._set_minWidth__31(value);
    });
  }
  function minWidth$factory_1() {
    return getPropertyCallableRef('minWidth', 1, KMutableProperty1, function (receiver) {
      return receiver._get_minWidth__31();
    }, function (receiver_0, value) {
      return receiver_0._set_minWidth__31(value);
    });
  }
  function maxWidth$factory_0() {
    return getPropertyCallableRef('maxWidth', 1, KMutableProperty1, function (receiver) {
      return receiver._get_maxWidth__31();
    }, function (receiver_0, value) {
      return receiver_0._set_maxWidth__31(value);
    });
  }
  function maxWidth$factory_1() {
    return getPropertyCallableRef('maxWidth', 1, KMutableProperty1, function (receiver) {
      return receiver._get_maxWidth__31();
    }, function (receiver_0, value) {
      return receiver_0._set_maxWidth__31(value);
    });
  }
  function height$factory_0() {
    return getPropertyCallableRef('height', 1, KMutableProperty1, function (receiver) {
      return receiver._get_height__41();
    }, function (receiver_0, value) {
      return receiver_0._set_height__31(value);
    });
  }
  function height$factory_1() {
    return getPropertyCallableRef('height', 1, KMutableProperty1, function (receiver) {
      return receiver._get_height__41();
    }, function (receiver_0, value) {
      return receiver_0._set_height__31(value);
    });
  }
  function minHeight$factory_0() {
    return getPropertyCallableRef('minHeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_minHeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_minHeight__31(value);
    });
  }
  function minHeight$factory_1() {
    return getPropertyCallableRef('minHeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_minHeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_minHeight__31(value);
    });
  }
  function maxHeight$factory_0() {
    return getPropertyCallableRef('maxHeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_maxHeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_maxHeight__31(value);
    });
  }
  function maxHeight$factory_1() {
    return getPropertyCallableRef('maxHeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_maxHeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_maxHeight__31(value);
    });
  }
  function display$factory_0() {
    return getPropertyCallableRef('display', 1, KMutableProperty1, function (receiver) {
      return receiver._get_display__31();
    }, function (receiver_0, value) {
      return receiver_0._set_display__31(value);
    });
  }
  function display$factory_1() {
    return getPropertyCallableRef('display', 1, KMutableProperty1, function (receiver) {
      return receiver._get_display__31();
    }, function (receiver_0, value) {
      return receiver_0._set_display__31(value);
    });
  }
  function position$factory_0() {
    return getPropertyCallableRef('position', 1, KMutableProperty1, function (receiver) {
      return receiver._get_position__31();
    }, function (receiver_0, value) {
      return receiver_0._set_position__31(value);
    });
  }
  function position$factory_1() {
    return getPropertyCallableRef('position', 1, KMutableProperty1, function (receiver) {
      return receiver._get_position__31();
    }, function (receiver_0, value) {
      return receiver_0._set_position__31(value);
    });
  }
  function top$factory_0() {
    return getPropertyCallableRef('top', 1, KMutableProperty1, function (receiver) {
      return receiver._get_top__32();
    }, function (receiver_0, value) {
      return receiver_0._set_top__31(value);
    });
  }
  function top$factory_1() {
    return getPropertyCallableRef('top', 1, KMutableProperty1, function (receiver) {
      return receiver._get_top__32();
    }, function (receiver_0, value) {
      return receiver_0._set_top__31(value);
    });
  }
  function left$factory_0() {
    return getPropertyCallableRef('left', 1, KMutableProperty1, function (receiver) {
      return receiver._get_left__32();
    }, function (receiver_0, value) {
      return receiver_0._set_left__31(value);
    });
  }
  function left$factory_1() {
    return getPropertyCallableRef('left', 1, KMutableProperty1, function (receiver) {
      return receiver._get_left__32();
    }, function (receiver_0, value) {
      return receiver_0._set_left__31(value);
    });
  }
  function right$factory_0() {
    return getPropertyCallableRef('right', 1, KMutableProperty1, function (receiver) {
      return receiver._get_right__32();
    }, function (receiver_0, value) {
      return receiver_0._set_right__31(value);
    });
  }
  function right$factory_1() {
    return getPropertyCallableRef('right', 1, KMutableProperty1, function (receiver) {
      return receiver._get_right__32();
    }, function (receiver_0, value) {
      return receiver_0._set_right__31(value);
    });
  }
  function bottom$factory_0() {
    return getPropertyCallableRef('bottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_bottom__32();
    }, function (receiver_0, value) {
      return receiver_0._set_bottom__31(value);
    });
  }
  function bottom$factory_1() {
    return getPropertyCallableRef('bottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_bottom__32();
    }, function (receiver_0, value) {
      return receiver_0._set_bottom__31(value);
    });
  }
  function zIndex$factory_0() {
    return getPropertyCallableRef('zIndex', 1, KMutableProperty1, function (receiver) {
      return receiver._get_zIndex__31();
    }, function (receiver_0, value) {
      return receiver_0._set_zIndex__31(value);
    });
  }
  function zIndex$factory_1() {
    return getPropertyCallableRef('zIndex', 1, KMutableProperty1, function (receiver) {
      return receiver._get_zIndex__31();
    }, function (receiver_0, value) {
      return receiver_0._set_zIndex__31(value);
    });
  }
  function overflow$factory_0() {
    return getPropertyCallableRef('overflow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_overflow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_overflow__31(value);
    });
  }
  function overflow$factory_1() {
    return getPropertyCallableRef('overflow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_overflow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_overflow__31(value);
    });
  }
  function overflowWrap$factory_0() {
    return getPropertyCallableRef('overflowWrap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_overflowWrap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_overflowWrap__31(value);
    });
  }
  function overflowWrap$factory_1() {
    return getPropertyCallableRef('overflowWrap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_overflowWrap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_overflowWrap__31(value);
    });
  }
  function resize$factory_0() {
    return getPropertyCallableRef('resize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_resize__31();
    }, function (receiver_0, value) {
      return receiver_0._set_resize__31(value);
    });
  }
  function resize$factory_1() {
    return getPropertyCallableRef('resize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_resize__31();
    }, function (receiver_0, value) {
      return receiver_0._set_resize__31(value);
    });
  }
  function border$factory_0() {
    return getPropertyCallableRef('border', 1, KMutableProperty1, function (receiver) {
      return receiver._get_border__31();
    }, function (receiver_0, value) {
      return receiver_0._set_border__31(value);
    });
  }
  function border$factory_1() {
    return getPropertyCallableRef('border', 1, KMutableProperty1, function (receiver) {
      return receiver._get_border__31();
    }, function (receiver_0, value) {
      return receiver_0._set_border__31(value);
    });
  }
  function borderTop$factory_0() {
    return getPropertyCallableRef('borderTop', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderTop__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderTop__31(value);
    });
  }
  function borderTop$factory_1() {
    return getPropertyCallableRef('borderTop', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderTop__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderTop__31(value);
    });
  }
  function borderRight$factory_0() {
    return getPropertyCallableRef('borderRight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderRight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderRight__31(value);
    });
  }
  function borderRight$factory_1() {
    return getPropertyCallableRef('borderRight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderRight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderRight__31(value);
    });
  }
  function borderBottom$factory_0() {
    return getPropertyCallableRef('borderBottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderBottom__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderBottom__31(value);
    });
  }
  function borderBottom$factory_1() {
    return getPropertyCallableRef('borderBottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderBottom__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderBottom__31(value);
    });
  }
  function borderLeft$factory_0() {
    return getPropertyCallableRef('borderLeft', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderLeft__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderLeft__31(value);
    });
  }
  function borderLeft$factory_1() {
    return getPropertyCallableRef('borderLeft', 1, KMutableProperty1, function (receiver) {
      return receiver._get_borderLeft__31();
    }, function (receiver_0, value) {
      return receiver_0._set_borderLeft__31(value);
    });
  }
  function margin$factory_0() {
    return getPropertyCallableRef('margin', 1, KMutableProperty1, function (receiver) {
      return receiver._get_margin__31();
    }, function (receiver_0, value) {
      return receiver_0._set_margin__31(value);
    });
  }
  function margin$factory_1() {
    return getPropertyCallableRef('margin', 1, KMutableProperty1, function (receiver) {
      return receiver._get_margin__31();
    }, function (receiver_0, value) {
      return receiver_0._set_margin__31(value);
    });
  }
  function marginTop$factory_0() {
    return getPropertyCallableRef('marginTop', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginTop__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginTop__31(value);
    });
  }
  function marginTop$factory_1() {
    return getPropertyCallableRef('marginTop', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginTop__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginTop__31(value);
    });
  }
  function marginRight$factory_0() {
    return getPropertyCallableRef('marginRight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginRight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginRight__31(value);
    });
  }
  function marginRight$factory_1() {
    return getPropertyCallableRef('marginRight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginRight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginRight__31(value);
    });
  }
  function marginBottom$factory_0() {
    return getPropertyCallableRef('marginBottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginBottom__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginBottom__31(value);
    });
  }
  function marginBottom$factory_1() {
    return getPropertyCallableRef('marginBottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginBottom__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginBottom__31(value);
    });
  }
  function marginLeft$factory_0() {
    return getPropertyCallableRef('marginLeft', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginLeft__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginLeft__31(value);
    });
  }
  function marginLeft$factory_1() {
    return getPropertyCallableRef('marginLeft', 1, KMutableProperty1, function (receiver) {
      return receiver._get_marginLeft__31();
    }, function (receiver_0, value) {
      return receiver_0._set_marginLeft__31(value);
    });
  }
  function padding$factory_0() {
    return getPropertyCallableRef('padding', 1, KMutableProperty1, function (receiver) {
      return receiver._get_padding__31();
    }, function (receiver_0, value) {
      return receiver_0._set_padding__31(value);
    });
  }
  function padding$factory_1() {
    return getPropertyCallableRef('padding', 1, KMutableProperty1, function (receiver) {
      return receiver._get_padding__31();
    }, function (receiver_0, value) {
      return receiver_0._set_padding__31(value);
    });
  }
  function paddingTop$factory_0() {
    return getPropertyCallableRef('paddingTop', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingTop__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingTop__31(value);
    });
  }
  function paddingTop$factory_1() {
    return getPropertyCallableRef('paddingTop', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingTop__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingTop__31(value);
    });
  }
  function paddingRight$factory_0() {
    return getPropertyCallableRef('paddingRight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingRight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingRight__31(value);
    });
  }
  function paddingRight$factory_1() {
    return getPropertyCallableRef('paddingRight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingRight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingRight__31(value);
    });
  }
  function paddingBottom$factory_0() {
    return getPropertyCallableRef('paddingBottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingBottom__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingBottom__31(value);
    });
  }
  function paddingBottom$factory_1() {
    return getPropertyCallableRef('paddingBottom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingBottom__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingBottom__31(value);
    });
  }
  function paddingLeft$factory_0() {
    return getPropertyCallableRef('paddingLeft', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingLeft__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingLeft__31(value);
    });
  }
  function paddingLeft$factory_1() {
    return getPropertyCallableRef('paddingLeft', 1, KMutableProperty1, function (receiver) {
      return receiver._get_paddingLeft__31();
    }, function (receiver_0, value) {
      return receiver_0._set_paddingLeft__31(value);
    });
  }
  function color$factory_0() {
    return getPropertyCallableRef('color', 1, KMutableProperty1, function (receiver) {
      return receiver._get_color__31();
    }, function (receiver_0, value) {
      return receiver_0._set_color__31(value);
    });
  }
  function color$factory_1() {
    return getPropertyCallableRef('color', 1, KMutableProperty1, function (receiver) {
      return receiver._get_color__31();
    }, function (receiver_0, value) {
      return receiver_0._set_color__31(value);
    });
  }
  function opacity$factory_0() {
    return getPropertyCallableRef('opacity', 1, KMutableProperty1, function (receiver) {
      return receiver._get_opacity__31();
    }, function (receiver_0, value) {
      return receiver_0._set_opacity__31(value);
    });
  }
  function opacity$factory_1() {
    return getPropertyCallableRef('opacity', 1, KMutableProperty1, function (receiver) {
      return receiver._get_opacity__31();
    }, function (receiver_0, value) {
      return receiver_0._set_opacity__31(value);
    });
  }
  function background$factory_0() {
    return getPropertyCallableRef('background', 1, KMutableProperty1, function (receiver) {
      return receiver._get_background__31();
    }, function (receiver_0, value) {
      return receiver_0._set_background__31(value);
    });
  }
  function background$factory_1() {
    return getPropertyCallableRef('background', 1, KMutableProperty1, function (receiver) {
      return receiver._get_background__31();
    }, function (receiver_0, value) {
      return receiver_0._set_background__31(value);
    });
  }
  function textDirection$factory_0() {
    return getPropertyCallableRef('textDirection', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textDirection__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textDirection__31(value);
    });
  }
  function textDirection$factory_1() {
    return getPropertyCallableRef('textDirection', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textDirection__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textDirection__31(value);
    });
  }
  function letterSpacing$factory_0() {
    return getPropertyCallableRef('letterSpacing', 1, KMutableProperty1, function (receiver) {
      return receiver._get_letterSpacing__31();
    }, function (receiver_0, value) {
      return receiver_0._set_letterSpacing__31(value);
    });
  }
  function letterSpacing$factory_1() {
    return getPropertyCallableRef('letterSpacing', 1, KMutableProperty1, function (receiver) {
      return receiver._get_letterSpacing__31();
    }, function (receiver_0, value) {
      return receiver_0._set_letterSpacing__31(value);
    });
  }
  function lineHeight$factory_0() {
    return getPropertyCallableRef('lineHeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_lineHeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_lineHeight__31(value);
    });
  }
  function lineHeight$factory_1() {
    return getPropertyCallableRef('lineHeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_lineHeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_lineHeight__31(value);
    });
  }
  function textAlign$factory_0() {
    return getPropertyCallableRef('textAlign', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textAlign__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textAlign__31(value);
    });
  }
  function textAlign$factory_1() {
    return getPropertyCallableRef('textAlign', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textAlign__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textAlign__31(value);
    });
  }
  function textDecoration$factory_0() {
    return getPropertyCallableRef('textDecoration', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textDecoration__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textDecoration__31(value);
    });
  }
  function textDecoration$factory_1() {
    return getPropertyCallableRef('textDecoration', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textDecoration__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textDecoration__31(value);
    });
  }
  function textIndent$factory_0() {
    return getPropertyCallableRef('textIndent', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textIndent__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textIndent__31(value);
    });
  }
  function textIndent$factory_1() {
    return getPropertyCallableRef('textIndent', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textIndent__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textIndent__31(value);
    });
  }
  function textShadow$factory_0() {
    return getPropertyCallableRef('textShadow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textShadow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textShadow__31(value);
    });
  }
  function textShadow$factory_1() {
    return getPropertyCallableRef('textShadow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textShadow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textShadow__31(value);
    });
  }
  function textTransform$factory_0() {
    return getPropertyCallableRef('textTransform', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textTransform__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textTransform__31(value);
    });
  }
  function textTransform$factory_1() {
    return getPropertyCallableRef('textTransform', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textTransform__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textTransform__31(value);
    });
  }
  function textOverflow$factory_0() {
    return getPropertyCallableRef('textOverflow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textOverflow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textOverflow__31(value);
    });
  }
  function textOverflow$factory_1() {
    return getPropertyCallableRef('textOverflow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_textOverflow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_textOverflow__31(value);
    });
  }
  function unicodeBidi$factory_0() {
    return getPropertyCallableRef('unicodeBidi', 1, KMutableProperty1, function (receiver) {
      return receiver._get_unicodeBidi__31();
    }, function (receiver_0, value) {
      return receiver_0._set_unicodeBidi__31(value);
    });
  }
  function unicodeBidi$factory_1() {
    return getPropertyCallableRef('unicodeBidi', 1, KMutableProperty1, function (receiver) {
      return receiver._get_unicodeBidi__31();
    }, function (receiver_0, value) {
      return receiver_0._set_unicodeBidi__31(value);
    });
  }
  function verticalAlign$factory_0() {
    return getPropertyCallableRef('verticalAlign', 1, KMutableProperty1, function (receiver) {
      return receiver._get_verticalAlign__31();
    }, function (receiver_0, value) {
      return receiver_0._set_verticalAlign__31(value);
    });
  }
  function verticalAlign$factory_1() {
    return getPropertyCallableRef('verticalAlign', 1, KMutableProperty1, function (receiver) {
      return receiver._get_verticalAlign__31();
    }, function (receiver_0, value) {
      return receiver_0._set_verticalAlign__31(value);
    });
  }
  function whiteSpace$factory_0() {
    return getPropertyCallableRef('whiteSpace', 1, KMutableProperty1, function (receiver) {
      return receiver._get_whiteSpace__31();
    }, function (receiver_0, value) {
      return receiver_0._set_whiteSpace__31(value);
    });
  }
  function whiteSpace$factory_1() {
    return getPropertyCallableRef('whiteSpace', 1, KMutableProperty1, function (receiver) {
      return receiver._get_whiteSpace__31();
    }, function (receiver_0, value) {
      return receiver_0._set_whiteSpace__31(value);
    });
  }
  function wordSpacing$factory_0() {
    return getPropertyCallableRef('wordSpacing', 1, KMutableProperty1, function (receiver) {
      return receiver._get_wordSpacing__31();
    }, function (receiver_0, value) {
      return receiver_0._set_wordSpacing__31(value);
    });
  }
  function wordSpacing$factory_1() {
    return getPropertyCallableRef('wordSpacing', 1, KMutableProperty1, function (receiver) {
      return receiver._get_wordSpacing__31();
    }, function (receiver_0, value) {
      return receiver_0._set_wordSpacing__31(value);
    });
  }
  function fontFamily$factory_0() {
    return getPropertyCallableRef('fontFamily', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontFamily__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontFamily__31(value);
    });
  }
  function fontFamily$factory_1() {
    return getPropertyCallableRef('fontFamily', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontFamily__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontFamily__31(value);
    });
  }
  function fontSize$factory_0() {
    return getPropertyCallableRef('fontSize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontSize__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontSize__31(value);
    });
  }
  function fontSize$factory_1() {
    return getPropertyCallableRef('fontSize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontSize__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontSize__31(value);
    });
  }
  function fontStyle$factory_0() {
    return getPropertyCallableRef('fontStyle', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontStyle__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontStyle__31(value);
    });
  }
  function fontStyle$factory_1() {
    return getPropertyCallableRef('fontStyle', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontStyle__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontStyle__31(value);
    });
  }
  function fontWeight$factory_0() {
    return getPropertyCallableRef('fontWeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontWeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontWeight__31(value);
    });
  }
  function fontWeight$factory_1() {
    return getPropertyCallableRef('fontWeight', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontWeight__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontWeight__31(value);
    });
  }
  function fontVariant$factory_0() {
    return getPropertyCallableRef('fontVariant', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontVariant__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontVariant__31(value);
    });
  }
  function fontVariant$factory_1() {
    return getPropertyCallableRef('fontVariant', 1, KMutableProperty1, function (receiver) {
      return receiver._get_fontVariant__31();
    }, function (receiver_0, value) {
      return receiver_0._set_fontVariant__31(value);
    });
  }
  function float$factory_0() {
    return getPropertyCallableRef('float', 1, KMutableProperty1, function (receiver) {
      return receiver._get_float__31();
    }, function (receiver_0, value) {
      return receiver_0._set_float__31(value);
    });
  }
  function float$factory_1() {
    return getPropertyCallableRef('float', 1, KMutableProperty1, function (receiver) {
      return receiver._get_float__31();
    }, function (receiver_0, value) {
      return receiver_0._set_float__31(value);
    });
  }
  function clear$factory_0() {
    return getPropertyCallableRef('clear', 1, KMutableProperty1, function (receiver) {
      return receiver._get_clear__31();
    }, function (receiver_0, value) {
      return receiver_0._set_clear__31(value);
    });
  }
  function clear$factory_1() {
    return getPropertyCallableRef('clear', 1, KMutableProperty1, function (receiver) {
      return receiver._get_clear__31();
    }, function (receiver_0, value) {
      return receiver_0._set_clear__31(value);
    });
  }
  function wordBreak$factory_0() {
    return getPropertyCallableRef('wordBreak', 1, KMutableProperty1, function (receiver) {
      return receiver._get_wordBreak__31();
    }, function (receiver_0, value) {
      return receiver_0._set_wordBreak__31(value);
    });
  }
  function wordBreak$factory_1() {
    return getPropertyCallableRef('wordBreak', 1, KMutableProperty1, function (receiver) {
      return receiver._get_wordBreak__31();
    }, function (receiver_0, value) {
      return receiver_0._set_wordBreak__31(value);
    });
  }
  function lineBreak$factory_0() {
    return getPropertyCallableRef('lineBreak', 1, KMutableProperty1, function (receiver) {
      return receiver._get_lineBreak__31();
    }, function (receiver_0, value) {
      return receiver_0._set_lineBreak__31(value);
    });
  }
  function lineBreak$factory_1() {
    return getPropertyCallableRef('lineBreak', 1, KMutableProperty1, function (receiver) {
      return receiver._get_lineBreak__31();
    }, function (receiver_0, value) {
      return receiver_0._set_lineBreak__31(value);
    });
  }
  function cursor$factory_0() {
    return getPropertyCallableRef('cursor', 1, KMutableProperty1, function (receiver) {
      return receiver._get_cursor__31();
    }, function (receiver_0, value) {
      return receiver_0._set_cursor__31(value);
    });
  }
  function cursor$factory_1() {
    return getPropertyCallableRef('cursor', 1, KMutableProperty1, function (receiver) {
      return receiver._get_cursor__31();
    }, function (receiver_0, value) {
      return receiver_0._set_cursor__31(value);
    });
  }
  function flexDirection$factory_0() {
    return getPropertyCallableRef('flexDirection', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexDirection__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexDirection__31(value);
    });
  }
  function flexDirection$factory_1() {
    return getPropertyCallableRef('flexDirection', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexDirection__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexDirection__31(value);
    });
  }
  function flexWrap$factory_0() {
    return getPropertyCallableRef('flexWrap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexWrap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexWrap__31(value);
    });
  }
  function flexWrap$factory_1() {
    return getPropertyCallableRef('flexWrap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexWrap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexWrap__31(value);
    });
  }
  function justifyItems$factory_0() {
    return getPropertyCallableRef('justifyItems', 1, KMutableProperty1, function (receiver) {
      return receiver._get_justifyItems__31();
    }, function (receiver_0, value) {
      return receiver_0._set_justifyItems__31(value);
    });
  }
  function justifyItems$factory_1() {
    return getPropertyCallableRef('justifyItems', 1, KMutableProperty1, function (receiver) {
      return receiver._get_justifyItems__31();
    }, function (receiver_0, value) {
      return receiver_0._set_justifyItems__31(value);
    });
  }
  function justifyContent$factory_0() {
    return getPropertyCallableRef('justifyContent', 1, KMutableProperty1, function (receiver) {
      return receiver._get_justifyContent__31();
    }, function (receiver_0, value) {
      return receiver_0._set_justifyContent__31(value);
    });
  }
  function justifyContent$factory_1() {
    return getPropertyCallableRef('justifyContent', 1, KMutableProperty1, function (receiver) {
      return receiver._get_justifyContent__31();
    }, function (receiver_0, value) {
      return receiver_0._set_justifyContent__31(value);
    });
  }
  function alignItems$factory_0() {
    return getPropertyCallableRef('alignItems', 1, KMutableProperty1, function (receiver) {
      return receiver._get_alignItems__31();
    }, function (receiver_0, value) {
      return receiver_0._set_alignItems__31(value);
    });
  }
  function alignItems$factory_1() {
    return getPropertyCallableRef('alignItems', 1, KMutableProperty1, function (receiver) {
      return receiver._get_alignItems__31();
    }, function (receiver_0, value) {
      return receiver_0._set_alignItems__31(value);
    });
  }
  function alignContent$factory_0() {
    return getPropertyCallableRef('alignContent', 1, KMutableProperty1, function (receiver) {
      return receiver._get_alignContent__31();
    }, function (receiver_0, value) {
      return receiver_0._set_alignContent__31(value);
    });
  }
  function alignContent$factory_1() {
    return getPropertyCallableRef('alignContent', 1, KMutableProperty1, function (receiver) {
      return receiver._get_alignContent__31();
    }, function (receiver_0, value) {
      return receiver_0._set_alignContent__31(value);
    });
  }
  function order$factory_0() {
    return getPropertyCallableRef('order', 1, KMutableProperty1, function (receiver) {
      return receiver._get_order__31();
    }, function (receiver_0, value) {
      return receiver_0._set_order__31(value);
    });
  }
  function order$factory_1() {
    return getPropertyCallableRef('order', 1, KMutableProperty1, function (receiver) {
      return receiver._get_order__31();
    }, function (receiver_0, value) {
      return receiver_0._set_order__31(value);
    });
  }
  function flexGrow$factory_0() {
    return getPropertyCallableRef('flexGrow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexGrow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexGrow__31(value);
    });
  }
  function flexGrow$factory_1() {
    return getPropertyCallableRef('flexGrow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexGrow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexGrow__31(value);
    });
  }
  function flexShrink$factory_0() {
    return getPropertyCallableRef('flexShrink', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexShrink__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexShrink__31(value);
    });
  }
  function flexShrink$factory_1() {
    return getPropertyCallableRef('flexShrink', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexShrink__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexShrink__31(value);
    });
  }
  function flexBasis$factory_0() {
    return getPropertyCallableRef('flexBasis', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexBasis__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexBasis__31(value);
    });
  }
  function flexBasis$factory_1() {
    return getPropertyCallableRef('flexBasis', 1, KMutableProperty1, function (receiver) {
      return receiver._get_flexBasis__31();
    }, function (receiver_0, value) {
      return receiver_0._set_flexBasis__31(value);
    });
  }
  function alignSelf$factory_0() {
    return getPropertyCallableRef('alignSelf', 1, KMutableProperty1, function (receiver) {
      return receiver._get_alignSelf__31();
    }, function (receiver_0, value) {
      return receiver_0._set_alignSelf__31(value);
    });
  }
  function alignSelf$factory_1() {
    return getPropertyCallableRef('alignSelf', 1, KMutableProperty1, function (receiver) {
      return receiver._get_alignSelf__31();
    }, function (receiver_0, value) {
      return receiver_0._set_alignSelf__31(value);
    });
  }
  function justifySelf$factory_0() {
    return getPropertyCallableRef('justifySelf', 1, KMutableProperty1, function (receiver) {
      return receiver._get_justifySelf__31();
    }, function (receiver_0, value) {
      return receiver_0._set_justifySelf__31(value);
    });
  }
  function justifySelf$factory_1() {
    return getPropertyCallableRef('justifySelf', 1, KMutableProperty1, function (receiver) {
      return receiver._get_justifySelf__31();
    }, function (receiver_0, value) {
      return receiver_0._set_justifySelf__31(value);
    });
  }
  function gridAutoColumns$factory_0() {
    return getPropertyCallableRef('gridAutoColumns', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridAutoColumns__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridAutoColumns__31(value);
    });
  }
  function gridAutoColumns$factory_1() {
    return getPropertyCallableRef('gridAutoColumns', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridAutoColumns__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridAutoColumns__31(value);
    });
  }
  function gridAutoRows$factory_0() {
    return getPropertyCallableRef('gridAutoRows', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridAutoRows__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridAutoRows__31(value);
    });
  }
  function gridAutoRows$factory_1() {
    return getPropertyCallableRef('gridAutoRows', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridAutoRows__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridAutoRows__31(value);
    });
  }
  function gridAutoFlow$factory_0() {
    return getPropertyCallableRef('gridAutoFlow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridAutoFlow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridAutoFlow__31(value);
    });
  }
  function gridAutoFlow$factory_1() {
    return getPropertyCallableRef('gridAutoFlow', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridAutoFlow__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridAutoFlow__31(value);
    });
  }
  function gridTemplateColumns$factory_0() {
    return getPropertyCallableRef('gridTemplateColumns', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridTemplateColumns__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridTemplateColumns__31(value);
    });
  }
  function gridTemplateColumns$factory_1() {
    return getPropertyCallableRef('gridTemplateColumns', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridTemplateColumns__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridTemplateColumns__31(value);
    });
  }
  function gridTemplateRows$factory_0() {
    return getPropertyCallableRef('gridTemplateRows', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridTemplateRows__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridTemplateRows__31(value);
    });
  }
  function gridTemplateRows$factory_1() {
    return getPropertyCallableRef('gridTemplateRows', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridTemplateRows__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridTemplateRows__31(value);
    });
  }
  function gridTemplateAreas$factory_0() {
    return getPropertyCallableRef('gridTemplateAreas', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridTemplateAreas__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridTemplateAreas__31(value);
    });
  }
  function gridTemplateAreas$factory_1() {
    return getPropertyCallableRef('gridTemplateAreas', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridTemplateAreas__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridTemplateAreas__31(value);
    });
  }
  function gridColumnGap$factory_0() {
    return getPropertyCallableRef('gridColumnGap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridColumnGap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridColumnGap__31(value);
    });
  }
  function gridColumnGap$factory_1() {
    return getPropertyCallableRef('gridColumnGap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridColumnGap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridColumnGap__31(value);
    });
  }
  function gridRowGap$factory_0() {
    return getPropertyCallableRef('gridRowGap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridRowGap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridRowGap__31(value);
    });
  }
  function gridRowGap$factory_1() {
    return getPropertyCallableRef('gridRowGap', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridRowGap__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridRowGap__31(value);
    });
  }
  function gridColumnStart$factory_0() {
    return getPropertyCallableRef('gridColumnStart', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridColumnStart__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridColumnStart__31(value);
    });
  }
  function gridColumnStart$factory_1() {
    return getPropertyCallableRef('gridColumnStart', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridColumnStart__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridColumnStart__31(value);
    });
  }
  function gridRowStart$factory_0() {
    return getPropertyCallableRef('gridRowStart', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridRowStart__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridRowStart__31(value);
    });
  }
  function gridRowStart$factory_1() {
    return getPropertyCallableRef('gridRowStart', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridRowStart__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridRowStart__31(value);
    });
  }
  function gridColumnEnd$factory_0() {
    return getPropertyCallableRef('gridColumnEnd', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridColumnEnd__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridColumnEnd__31(value);
    });
  }
  function gridColumnEnd$factory_1() {
    return getPropertyCallableRef('gridColumnEnd', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridColumnEnd__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridColumnEnd__31(value);
    });
  }
  function gridRowEnd$factory_0() {
    return getPropertyCallableRef('gridRowEnd', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridRowEnd__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridRowEnd__31(value);
    });
  }
  function gridRowEnd$factory_1() {
    return getPropertyCallableRef('gridRowEnd', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridRowEnd__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridRowEnd__31(value);
    });
  }
  function gridArea$factory_0() {
    return getPropertyCallableRef('gridArea', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridArea__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridArea__31(value);
    });
  }
  function gridArea$factory_1() {
    return getPropertyCallableRef('gridArea', 1, KMutableProperty1, function (receiver) {
      return receiver._get_gridArea__31();
    }, function (receiver_0, value) {
      return receiver_0._set_gridArea__31(value);
    });
  }
  function _no_name_provided_$factory_31(this$0) {
    var i = new _no_name_provided__40(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_32(this$0) {
    var i = new _no_name_provided__41(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_33(this$0) {
    var i = new _no_name_provided__42(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_34(this$0) {
    var i = new _no_name_provided__43(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_35(this$0) {
    var i = new _no_name_provided__44(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_36(this$0) {
    var i = new _no_name_provided__45(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_37(this$0) {
    var i = new _no_name_provided__46(this$0);
    return function (p1) {
      i.invoke_75(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_38(this$0) {
    var i = new _no_name_provided__47(this$0);
    return function (p1) {
      i.invoke_77(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_39(this$0) {
    var i = new _no_name_provided__48(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_40(this$0) {
    var i = new _no_name_provided__49(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_41(this$0) {
    var i = new _no_name_provided__50(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_42(this$0) {
    var i = new _no_name_provided__51(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_43(this$0) {
    var i = new _no_name_provided__52(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_44(this$0) {
    var i = new _no_name_provided__53(this$0);
    return function (p1) {
      i.invoke_89(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_45(this$0) {
    var i = new _no_name_provided__54(this$0);
    return function (p1) {
      i.invoke_91(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_46(this$0) {
    var i = new _no_name_provided__55(this$0);
    return function (p1) {
      i.invoke_93(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_47(this$0) {
    var i = new _no_name_provided__56(this$0);
    return function (p1) {
      i.invoke_103(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_48(this$0) {
    var i = new _no_name_provided__57(this$0);
    return function (p1) {
      i.invoke_103(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_49(this$0) {
    var i = new _no_name_provided__58(this$0);
    return function (p1) {
      i.invoke_103(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_50(this$0) {
    var i = new _no_name_provided__59(this$0);
    return function (p1) {
      i.invoke_103(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_51(this$0) {
    var i = new _no_name_provided__60(this$0);
    return function (p1) {
      i.invoke_103(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_52(this$0) {
    var i = new _no_name_provided__61(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_53(this$0) {
    var i = new _no_name_provided__62(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_54(this$0) {
    var i = new _no_name_provided__63(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_55(this$0) {
    var i = new _no_name_provided__64(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_56(this$0) {
    var i = new _no_name_provided__65(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_57(this$0) {
    var i = new _no_name_provided__66(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_58(this$0) {
    var i = new _no_name_provided__67(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_59(this$0) {
    var i = new _no_name_provided__68(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_60(this$0) {
    var i = new _no_name_provided__69(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_61(this$0) {
    var i = new _no_name_provided__70(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_62(this$0) {
    var i = new _no_name_provided__71(this$0);
    return function (p1) {
      i.invoke_125(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_63(this$0) {
    var i = new _no_name_provided__72(this$0);
    return function (p1) {
      i.invoke_127(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_64(this$0) {
    var i = new _no_name_provided__73(this$0);
    return function (p1) {
      i.invoke_129(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_65(this$0) {
    var i = new _no_name_provided__74(this$0);
    return function (p1) {
      i.invoke_131(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_66(this$0) {
    var i = new _no_name_provided__75(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_67(this$0) {
    var i = new _no_name_provided__76(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_68(this$0) {
    var i = new _no_name_provided__77(this$0);
    return function (p1) {
      i.invoke_137(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_69(this$0) {
    var i = new _no_name_provided__78(this$0);
    return function (p1) {
      i.invoke_139(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_70(this$0) {
    var i = new _no_name_provided__79(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_71(this$0) {
    var i = new _no_name_provided__80(this$0);
    return function (p1) {
      i.invoke_143(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_72(this$0) {
    var i = new _no_name_provided__81(this$0);
    return function (p1) {
      i.invoke_145(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_73(this$0) {
    var i = new _no_name_provided__82(this$0);
    return function (p1) {
      i.invoke_147(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_74(this$0) {
    var i = new _no_name_provided__83(this$0);
    return function (p1) {
      i.invoke_149(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_75(this$0) {
    var i = new _no_name_provided__84(this$0);
    return function (p1) {
      i.invoke_151(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_76(this$0) {
    var i = new _no_name_provided__85(this$0);
    return function (p1) {
      i.invoke_153(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_77(this$0) {
    var i = new _no_name_provided__86(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_78(this$0) {
    var i = new _no_name_provided__87(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_79(this$0) {
    var i = new _no_name_provided__88(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_80(this$0) {
    var i = new _no_name_provided__89(this$0);
    return function (p1) {
      i.invoke_161(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_81(this$0) {
    var i = new _no_name_provided__90(this$0);
    return function (p1) {
      i.invoke_163(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_82(this$0) {
    var i = new _no_name_provided__91(this$0);
    return function (p1) {
      i.invoke_165(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_83(this$0) {
    var i = new _no_name_provided__92(this$0);
    return function (p1) {
      i.invoke_167(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_84(this$0) {
    var i = new _no_name_provided__93(this$0);
    return function (p1) {
      i.invoke_169(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_85(this$0) {
    var i = new _no_name_provided__94(this$0);
    return function (p1) {
      i.invoke_171(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_86(this$0) {
    var i = new _no_name_provided__95(this$0);
    return function (p1) {
      i.invoke_173(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_87(this$0) {
    var i = new _no_name_provided__96(this$0);
    return function (p1) {
      i.invoke_175(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_88(this$0) {
    var i = new _no_name_provided__97(this$0);
    return function (p1) {
      i.invoke_177(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_89(this$0) {
    var i = new _no_name_provided__98(this$0);
    return function (p1) {
      i.invoke_179(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_90(this$0) {
    var i = new _no_name_provided__99(this$0);
    return function (p1) {
      i.invoke_199(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_91(this$0) {
    var i = new _no_name_provided__100(this$0);
    return function (p1) {
      i.invoke_183(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_92(this$0) {
    var i = new _no_name_provided__101(this$0);
    return function (p1) {
      i.invoke_197(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_93(this$0) {
    var i = new _no_name_provided__102(this$0);
    return function (p1) {
      i.invoke_187(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_94(this$0) {
    var i = new _no_name_provided__103(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_95(this$0) {
    var i = new _no_name_provided__104(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_96(this$0) {
    var i = new _no_name_provided__105(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_97(this$0) {
    var i = new _no_name_provided__106(this$0);
    return function (p1) {
      i.invoke_195(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_98(this$0) {
    var i = new _no_name_provided__107(this$0);
    return function (p1) {
      i.invoke_197(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_99(this$0) {
    var i = new _no_name_provided__108(this$0);
    return function (p1) {
      i.invoke_199(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_100(this$0) {
    var i = new _no_name_provided__109(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_101(this$0) {
    var i = new _no_name_provided__110(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_102(this$0) {
    var i = new _no_name_provided__111(this$0);
    return function (p1) {
      i.invoke_205(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_103(this$0) {
    var i = new _no_name_provided__112(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_104(this$0) {
    var i = new _no_name_provided__113(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_105(this$0) {
    var i = new _no_name_provided__114(this$0);
    return function (p1) {
      i.invoke_211(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_106(this$0) {
    var i = new _no_name_provided__115(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_107(this$0) {
    var i = new _no_name_provided__116(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_108(this$0) {
    var i = new _no_name_provided__117(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_109(this$0) {
    var i = new _no_name_provided__118(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_110(this$0) {
    var i = new _no_name_provided__119(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_111(this$0) {
    var i = new _no_name_provided__120(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_112(this$0) {
    var i = new _no_name_provided__121(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__122($removeChildren, $this_bindState, $factory, $it) {
    this._$removeChildren = $removeChildren;
    this._$this_bindState = $this_bindState;
    this._$factory = $factory;
    this._$it = $it;
  }
  _no_name_provided__122.prototype.invoke_1037 = function () {
    if (this._$removeChildren) {
      var tmp = this._$this_bindState;
      var tmp0_safe_receiver = isInterface(tmp, Container) ? tmp : null;
      if (tmp0_safe_receiver == null)
        null;
      else
        tmp0_safe_receiver.disposeAll_24();
      Unit_getInstance();
    }this._$factory(this._$this_bindState, this._$it);
  };
  _no_name_provided__122.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__122.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__123($this_bindState, $removeChildren, $factory) {
    this._$this_bindState_0 = $this_bindState;
    this._$removeChildren_0 = $removeChildren;
    this._$factory_0 = $factory;
  }
  _no_name_provided__123.prototype.invoke_229 = function (it) {
    this._$this_bindState_0.singleRender_30(_no_name_provided_$factory_138(this._$removeChildren_0, this._$this_bindState_0, this._$factory_0, it));
  };
  _no_name_provided__123.prototype.invoke_1082 = function (p1) {
    this.invoke_229((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__123.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__124($boundThis) {
    this._$boundThis = $boundThis;
  }
  _no_name_provided__124.prototype.invoke_231 = function (p0) {
    return this._$boundThis.buildAttributeSet_30(p0);
  };
  _no_name_provided__124.prototype.invoke_1082 = function (p1) {
    this.invoke_231((!(p1 == null) ? isInterface(p1, AttributeSetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__124.prototype._get_name__47 = function () {
    return 'buildAttributeSet';
  };
  _no_name_provided__124.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__125($boundThis) {
    this._$boundThis_0 = $boundThis;
  }
  _no_name_provided__125.prototype.invoke_233 = function (p0) {
    return this._$boundThis_0.buildClassSet_30(p0);
  };
  _no_name_provided__125.prototype.invoke_1082 = function (p1) {
    this.invoke_233((!(p1 == null) ? isInterface(p1, ClassSetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__125.prototype._get_name__47 = function () {
    return 'buildClassSet';
  };
  _no_name_provided__125.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Widget_init_$Init$(intClasses, init, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      intClasses = null;
    if (!(($mask0 & 2) === 0))
      init = null;
    Widget.call($this, intClasses, init);
    return $this;
  }
  function getSnOpt($this) {
    var tmp0_snOpt_0 = _no_name_provided_$factory_124($this);
    return _no_name_provided_$factory_125({})(tmp0_snOpt_0);
  }
  function getSnHooksInternal($this) {
    var tmp0_elvis_lhs = $this._snHooksCache;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_also_0 = $this.getSnHooks_30();
      $this._snHooksCache = tmp0_also_0;
      tmp = tmp0_also_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function RefreshDelegateProvider_0($outer, initialValue, refreshFunction) {
    this._$this_7 = $outer;
    this._initialValue_0 = initialValue;
    this._refreshFunction_1 = refreshFunction;
  }
  RefreshDelegateProvider_0.prototype.provideDelegate_0 = function (thisRef, prop) {
    if (!(this._initialValue_0 == null))
      this._$this_7._propertyValues_1[prop.callableName] = this._initialValue_0;
    return new RefreshDelegate_0(this._$this_7, this._refreshFunction_1);
  };
  RefreshDelegateProvider_0.$metadata$ = {
    simpleName: 'RefreshDelegateProvider',
    kind: 'class',
    interfaces: []
  };
  function RefreshDelegate_0($outer, refreshFunction) {
    this._$this_8 = $outer;
    this._refreshFunction_2 = refreshFunction;
  }
  RefreshDelegate_0.prototype.getValue_3 = function (thisRef, property) {
    var value = this._$this_8._propertyValues_1[property.callableName];
    var tmp;
    if (value != null) {
      tmp = value;
    } else {
      var tmp0_unsafeCast_0 = null;
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  };
  RefreshDelegate_0.prototype.setValue_6 = function (thisRef, property, value) {
    this._$this_8._propertyValues_1[property.callableName] = value;
    this._refreshFunction_2(value);
  };
  RefreshDelegate_0.$metadata$ = {
    simpleName: 'RefreshDelegate',
    kind: 'class',
    interfaces: []
  };
  function Companion_13() {
    Companion_instance_12 = this;
    this._counter_1 = 0;
  }
  Companion_13.prototype.bindState = function (_this__0, observableState, removeChildren, factory) {
    _this__0.addBeforeDisposeHook_30(observableState.subscribe_21(_no_name_provided_$factory_137(_this__0, removeChildren, factory)));
    Unit_getInstance();
    return _this__0;
  };
  Companion_13.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_12;
  function Companion_getInstance_12() {
    if (Companion_instance_12 == null)
      new Companion_13();
    return Companion_instance_12;
  }
  function _no_name_provided__126(this$0) {
    this._this$0_88 = this$0;
  }
  _no_name_provided__126.prototype.invoke_1079 = function (it) {
    this._this$0_88.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__126.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__126.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__127(this$0) {
    this._this$0_89 = this$0;
  }
  _no_name_provided__127.prototype.invoke_1079 = function (it) {
    this._this$0_89.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__127.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__127.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__128(this$0) {
    this._this$0_90 = this$0;
  }
  _no_name_provided__128.prototype.invoke_1079 = function (it) {
    this._this$0_90.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__128.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__128.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__129(this$0) {
    this._this$0_91 = this$0;
  }
  _no_name_provided__129.prototype.invoke_396 = function (it) {
    this._this$0_91.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__129.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__129.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__130(this$0) {
    this._this$0_92 = this$0;
  }
  _no_name_provided__130.prototype.invoke_476 = function (it) {
    this._this$0_92.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__130.prototype.invoke_1082 = function (p1) {
    this.invoke_476((p1 == null ? true : typeof p1 === 'boolean') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__130.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__131(this$0) {
    this._this$0_93 = this$0;
  }
  _no_name_provided__131.prototype.invoke_771 = function (it) {
    this._this$0_93.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__131.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__131.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__132(this$0) {
    this._this$0_94 = this$0;
  }
  _no_name_provided__132.prototype.invoke_1038 = function () {
    return buildAttributeSet(_no_name_provided_$factory_139(this._this$0_94));
  };
  _no_name_provided__132.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__133(this$0) {
    this._this$0_95 = this$0;
  }
  _no_name_provided__133.prototype.invoke_1038 = function () {
    return !(this._this$0_95._lastLanguage == null) ? !(this._this$0_95._lastLanguage === I18n_getInstance()._language) : false;
  };
  _no_name_provided__133.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__134(this$0) {
    this._this$0_96 = this$0;
  }
  _no_name_provided__134.prototype.invoke_1038 = function () {
    return buildClassSet(_no_name_provided_$factory_140(this._this$0_96));
  };
  _no_name_provided__134.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__135(this$0) {
    this._this$0_97 = this$0;
  }
  _no_name_provided__135.prototype.invoke_398 = function ($this$snOpt) {
    var tmp0_map_0 = ensureNotNull(this._this$0_97._surroundingClasses);
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      tmp0_mapTo_0_1.add_57(to(item_2_3, true));
      Unit_getInstance();
    }
    var tmp2_snClasses_0 = tmp0_mapTo_0_1;
    var tmp1_apply_0 = new Object();
    var tmp0_iterator_1_2_0 = tmp2_snClasses_0.iterator_38();
    while (tmp0_iterator_1_2_0.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2_0.next_13();
      var key_3_4_4 = element_2_3.component1_0();
      var value_4_5_5 = element_2_3.component2_0();
      tmp1_apply_0[key_3_4_4] = value_4_5_5;
    }
    $this$snOpt.class = tmp1_apply_0;
  };
  _no_name_provided__135.prototype.invoke_1082 = function (p1) {
    this.invoke_398((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__135.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__136($boundThis) {
    this._$boundThis_1 = $boundThis;
  }
  _no_name_provided__136.prototype.invoke_400 = function (p0) {
    return apply(this._$boundThis_1, p0);
  };
  _no_name_provided__136.prototype.invoke_1082 = function (p1) {
    return this.invoke_400((!(p1 == null) ? typeof p1 === 'function' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__136.prototype._get_name__47 = function () {
    return 'apply';
  };
  _no_name_provided__136.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__137(this$0) {
    this._this$0_98 = this$0;
  }
  _no_name_provided__137.prototype.invoke_398 = function ($this$snOpt) {
    $this$snOpt.key = this._this$0_98._vnkey;
    $this$snOpt.attrs = this._this$0_98._snAttrsCache._get_value__18();
    var tmp1_snStyle_0 = this._this$0_98.getSnStyleInternal_32();
    var tmp0_apply_0 = new Object();
    var tmp0_iterator_1_2 = tmp1_snStyle_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      var key_3_4_4 = element_2_3.component1_0();
      var value_4_5_5 = element_2_3.component2_0();
      tmp0_apply_0[key_3_4_4] = value_4_5_5;
    }
    $this$snOpt.style = tmp0_apply_0;
    $this$snOpt.class = this._this$0_98._snClassCache._get_value__18();
    $this$snOpt.on = this._this$0_98.getSnOn_30();
    $this$snOpt.hook = getSnHooksInternal(this._this$0_98);
  };
  _no_name_provided__137.prototype.invoke_1082 = function (p1) {
    this.invoke_398((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__137.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__138($boundThis) {
    this._$boundThis_2 = $boundThis;
  }
  _no_name_provided__138.prototype.invoke_400 = function (p0) {
    return apply(this._$boundThis_2, p0);
  };
  _no_name_provided__138.prototype.invoke_1082 = function (p1) {
    return this.invoke_400((!(p1 == null) ? typeof p1 === 'function' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__138.prototype._get_name__47 = function () {
    return 'apply';
  };
  _no_name_provided__138.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__139(this$0) {
    this._this$0_99 = this$0;
  }
  _no_name_provided__139.prototype.invoke_258 = function (_anonymous_parameter_0_, v) {
    this._this$0_99._vnode = v;
    this._this$0_99.afterCreate_30(v);
  };
  _no_name_provided__139.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE();
    this.invoke_258(tmp, (!(p2 == null) ? isObject(p2) : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__139.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__140(this$0) {
    this._this$0_100 = this$0;
  }
  _no_name_provided__140.prototype.invoke_262 = function (v) {
    this._this$0_100._vnode = v;
    this._this$0_100.afterInsertInternal_30(v);
    this._this$0_100.afterInsert_30(v);
    var tmp0_safe_receiver_4 = this._this$0_100._afterInsertHooks;
    var tmp;
    if (tmp0_safe_receiver_4 == null) {
      tmp = null;
    } else {
      var tmp0_iterator_1_5 = tmp0_safe_receiver_4.iterator_38();
      while (tmp0_iterator_1_5.hasNext_11()) {
        var element_2_6 = tmp0_iterator_1_5.next_13();
        element_2_6(v);
      }
      tmp = Unit_getInstance();
    }
    return tmp;
  };
  _no_name_provided__140.prototype.invoke_1082 = function (p1) {
    return this.invoke_262((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__140.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__141(this$0) {
    this._this$0_101 = this$0;
  }
  _no_name_provided__141.prototype.invoke_262 = function (it) {
    this._this$0_101.afterDestroyInternal_30();
    this._this$0_101.afterDestroy_30();
    var tmp0_safe_receiver_8 = this._this$0_101._afterDestroyHooks;
    if (tmp0_safe_receiver_8 == null)
      null;
    else {
      var tmp0_iterator_1_9 = tmp0_safe_receiver_8.iterator_38();
      while (tmp0_iterator_1_9.hasNext_11()) {
        var element_2_10 = tmp0_iterator_1_9.next_13();
        element_2_10();
      }
      Unit_getInstance();
    }
    Unit_getInstance();
    this._this$0_101._vnode = null;
    return this._this$0_101._vnode;
  };
  _no_name_provided__141.prototype.invoke_1082 = function (p1) {
    return this.invoke_262((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__141.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__142($boundThis) {
    this._$boundThis_3 = $boundThis;
  }
  _no_name_provided__142.prototype.invoke_266 = function (p0) {
    return apply(this._$boundThis_3, p0);
  };
  _no_name_provided__142.prototype.invoke_1082 = function (p1) {
    return this.invoke_266((!(p1 == null) ? typeof p1 === 'function' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__142.prototype._get_name__47 = function () {
    return 'apply';
  };
  _no_name_provided__142.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__143($boundThis) {
    this._$boundThis_4 = $boundThis;
  }
  _no_name_provided__143.prototype.invoke_266 = function (p0) {
    return apply(this._$boundThis_4, p0);
  };
  _no_name_provided__143.prototype.invoke_1082 = function (p1) {
    return this.invoke_266((!(p1 == null) ? typeof p1 === 'function' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__143.prototype._get_name__47 = function () {
    return 'apply';
  };
  _no_name_provided__143.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__144(this$0, $complete) {
    this._this$0_102 = this$0;
    this._$complete = $complete;
  }
  _no_name_provided__144.prototype.invoke_1038 = function () {
    this._this$0_102._set_display__31(null);
    var tmp0_safe_receiver = this._$complete;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver();
  };
  _no_name_provided__144.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__145(this$0, $complete) {
    this._this$0_103 = this$0;
    this._$complete_0 = $complete;
  }
  _no_name_provided__145.prototype.invoke_1038 = function () {
    this._this$0_103._set_visible__31(false);
    var tmp0_safe_receiver = this._$complete_0;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver();
  };
  _no_name_provided__145.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__146($iconClasses) {
    this._$iconClasses = $iconClasses;
  }
  _no_name_provided__146.prototype.invoke_398 = function ($this$snOpt) {
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(this._$iconClasses, 10));
    var tmp0_iterator_1_2 = this._$iconClasses.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      tmp0_mapTo_0_1.add_57(to(item_2_3, true));
      Unit_getInstance();
    }
    var tmp1_snClasses_0 = tmp0_mapTo_0_1;
    var tmp0_apply_0 = new Object();
    var tmp0_iterator_1_2_0 = tmp1_snClasses_0.iterator_38();
    while (tmp0_iterator_1_2_0.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2_0.next_13();
      var key_3_4_4 = element_2_3.component1_0();
      var value_4_5_5 = element_2_3.component2_0();
      tmp0_apply_0[key_3_4_4] = value_4_5_5;
    }
    $this$snOpt.class = tmp0_apply_0;
  };
  _no_name_provided__146.prototype.invoke_1082 = function (p1) {
    this.invoke_398((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__146.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__147($boundThis) {
    this._$boundThis_5 = $boundThis;
  }
  _no_name_provided__147.prototype.invoke_400 = function (p0) {
    return apply(this._$boundThis_5, p0);
  };
  _no_name_provided__147.prototype.invoke_1082 = function (p1) {
    return this.invoke_400((!(p1 == null) ? typeof p1 === 'function' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__147.prototype._get_name__47 = function () {
    return 'apply';
  };
  _no_name_provided__147.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__148($image) {
    this._$image = $image;
  }
  _no_name_provided__148.prototype.invoke_398 = function ($this$snOpt) {
    var tmp1_snAttrs_0 = mapOf([to('src', this._$image), to('alt', '')]);
    var tmp0_apply_0 = new Object();
    var tmp0_iterator_1_2 = tmp1_snAttrs_0._get_entries__5().iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      var key_3_4_4 = element_2_3._get_key__2();
      var value_4_5_5 = element_2_3._get_value__18();
      tmp0_apply_0[key_3_4_4] = value_4_5_5;
    }
    $this$snOpt.attrs = tmp0_apply_0;
  };
  _no_name_provided__148.prototype.invoke_1082 = function (p1) {
    this.invoke_398((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__148.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__149($boundThis) {
    this._$boundThis_6 = $boundThis;
  }
  _no_name_provided__149.prototype.invoke_400 = function (p0) {
    return apply(this._$boundThis_6, p0);
  };
  _no_name_provided__149.prototype.invoke_1082 = function (p1) {
    return this.invoke_400((!(p1 == null) ? typeof p1 === 'function' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__149.prototype._get_name__47 = function () {
    return 'apply';
  };
  _no_name_provided__149.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Widget(intClasses, init) {
    Companion_getInstance_12();
    StyledComponent.call(this);
    this._intClasses = intClasses;
    this._propertyValues_1 = {};
    this._classes = null;
    this._surroundingClasses = null;
    this._attributes = null;
    this._internalListenersMap = null;
    this._listenersMap = null;
    this._listenerCounter = 0;
    this._parent = null;
    this._visible = true;
    var tmp = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_113(this);
    tmp._title$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, title$factory());
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_114(this);
    tmp_0._id$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, id$factory());
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0_1 = _no_name_provided_$factory_115(this);
    tmp_1._role$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_1)).provideDelegate_0(this, role$factory());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_2 = _no_name_provided_$factory_116(this);
    tmp_2._tabindex$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_2)).provideDelegate_0(this, tabindex$factory());
    var tmp_3 = this;
    var tmp0_refreshOnUpdate_0_3 = _no_name_provided_$factory_117(this);
    tmp_3._draggable$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_3)).provideDelegate_0(this, draggable$factory());
    var tmp_4 = this;
    var tmp0_refreshOnUpdate_0_4 = false;
    var tmp1_refreshOnUpdate_0 = _no_name_provided_$factory_118(this);
    tmp_4._surroundingSpan$delegate = (new RefreshDelegateProvider_0(this, tmp0_refreshOnUpdate_0_4, tmp1_refreshOnUpdate_0)).provideDelegate_0(this, surroundingSpan$factory());
    this._tooltipSiblings = null;
    this._popoverSiblings = null;
    this._tooltipOptions = null;
    this._popoverOptions = null;
    this._eventTarget = null;
    var tmp_5 = this;
    var tmp0_this = Companion_getInstance_12();
    var tmp1 = tmp0_this._counter_1;
    tmp0_this._counter_1 = tmp1 + 1 | 0;
    tmp_5._vnkey = '' + 'kv_widget_' + tmp1;
    this._vnode = null;
    var tmp_6 = this;
    var tmp_7 = new LazyCache(_no_name_provided_$factory_119(this));
    tmp_6._snAttrsCache = tmp_7.clearOn_0(_no_name_provided_$factory_120(this));
    var tmp_8 = this;
    tmp_8._snClassCache = new LazyCache(_no_name_provided_$factory_121(this));
    this._snOnCache = null;
    this._snHooksCache = null;
    this._lastLanguage = null;
    this._afterInsertHooks = null;
    this._afterDestroyHooks = null;
    this._beforeDisposeHooks = null;
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  Widget.prototype._set_attributes__9 = function (_set___) {
    this._attributes = _set___;
  };
  Widget.prototype._get_attributes__10 = function () {
    return this._attributes;
  };
  Widget.prototype._set_parent__31 = function (_set___) {
    this._parent = _set___;
  };
  Widget.prototype._get_parent__31 = function () {
    return this._parent;
  };
  Widget.prototype._set_visible__31 = function (value) {
    var oldField = this._visible;
    this._visible = value;
    if (!(oldField === this._visible)) {
      this.refresh_33();
      Unit_getInstance();
    }};
  Widget.prototype._get_visible__31 = function () {
    return this._visible;
  };
  Widget.prototype._set_title__30 = function (_set___) {
    return this._title$delegate.setValue_6(this, title$factory_0(), _set___);
  };
  Widget.prototype._get_title__30 = function () {
    return this._title$delegate.getValue_3(this, title$factory_1());
  };
  Widget.prototype._set_id__30 = function (_set___) {
    return this._id$delegate.setValue_6(this, id$factory_0(), _set___);
  };
  Widget.prototype._get_id__30 = function () {
    return this._id$delegate.getValue_3(this, id$factory_1());
  };
  Widget.prototype._set_role__30 = function (_set___) {
    return this._role$delegate.setValue_6(this, role$factory_0(), _set___);
  };
  Widget.prototype._get_role__30 = function () {
    return this._role$delegate.getValue_3(this, role$factory_1());
  };
  Widget.prototype._set_tabindex__30 = function (_set___) {
    return this._tabindex$delegate.setValue_6(this, tabindex$factory_0(), _set___);
  };
  Widget.prototype._get_tabindex__30 = function () {
    return this._tabindex$delegate.getValue_3(this, tabindex$factory_1());
  };
  Widget.prototype._set_draggable__30 = function (_set___) {
    return this._draggable$delegate.setValue_6(this, draggable$factory_0(), _set___);
  };
  Widget.prototype._get_draggable__30 = function () {
    return this._draggable$delegate.getValue_3(this, draggable$factory_1());
  };
  Widget.prototype._set_surroundingSpan__30 = function (_set___) {
    return this._surroundingSpan$delegate.setValue_6(this, surroundingSpan$factory_0(), _set___);
  };
  Widget.prototype._get_surroundingSpan__30 = function () {
    return this._surroundingSpan$delegate.getValue_3(this, surroundingSpan$factory_1());
  };
  Widget.prototype._set_eventTarget__3 = function (_set___) {
    this._eventTarget = _set___;
  };
  Widget.prototype._get_eventTarget__0 = function () {
    return this._eventTarget;
  };
  Widget.prototype._get_lastLanguage__9 = function () {
    return this._lastLanguage;
  };
  Widget.prototype.addBeforeDisposeHook_30 = function (hook) {
    var tmp0_elvis_lhs = this._beforeDisposeHooks;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp_0 = this;
      tmp_0._beforeDisposeHooks = ArrayList_init_$Create$();
      tmp = ensureNotNull(this._beforeDisposeHooks);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp.add_57(hook);
  };
  Widget.prototype.addAfterInsertHook_0 = function (hook) {
    var tmp0_elvis_lhs = this._afterInsertHooks;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp_0 = this;
      tmp_0._afterInsertHooks = ArrayList_init_$Create$();
      tmp = ensureNotNull(this._afterInsertHooks);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp.add_57(hook);
  };
  Widget.prototype.singleRender_30 = function (block) {
    var tmp0_safe_receiver = this.getRoot_31();
    if (tmp0_safe_receiver == null) {
      Unit_getInstance();
    } else
      tmp0_safe_receiver._renderDisabled = true;
    var t = block();
    var tmp1_safe_receiver = this.getRoot_31();
    if (tmp1_safe_receiver == null) {
      Unit_getInstance();
    } else
      tmp1_safe_receiver._renderDisabled = false;
    var tmp2_safe_receiver = this.getRoot_31();
    if (tmp2_safe_receiver == null)
      null;
    else
      tmp2_safe_receiver.reRender();
    Unit_getInstance();
    return t;
  };
  Widget.prototype.renderVNode_31 = function () {
    var tmp;
    if (this._surroundingClasses == null) {
      var tmp_0;
      if (this._get_surroundingSpan__30()) {
        var tmp0_arrayOf_0 = [this.render_88()];
        tmp_0 = h('span', tmp0_arrayOf_0);
      } else {
        tmp_0 = this.render_88();
      }
      tmp = tmp_0;
    } else {
      var tmp1_snOpt_0 = _no_name_provided_$factory_122(this);
      var opt = _no_name_provided_$factory_123({})(tmp1_snOpt_0);
      var tmp_1;
      if (this._get_surroundingSpan__30()) {
        var tmp2_arrayOf_0 = [this.render_88()];
        var tmp3_arrayOf_0 = [h('span', tmp2_arrayOf_0)];
        tmp_1 = h('div', opt, tmp3_arrayOf_0);
      } else {
        var tmp4_arrayOf_0 = [this.render_88()];
        tmp_1 = h('div', opt, tmp4_arrayOf_0);
      }
      tmp = tmp_1;
    }
    return tmp;
  };
  Widget.prototype.translate_31 = function (text_0) {
    this._lastLanguage = I18n_getInstance()._language;
    return I18n_getInstance().trans_0(text_0);
  };
  Widget.prototype.render_88 = function () {
    return this.render_89('div');
  };
  Widget.prototype.render_89 = function (elementName) {
    return h(elementName, getSnOpt(this));
  };
  Widget.prototype.render_87 = function (elementName, children) {
    return h(elementName, getSnOpt(this), children);
  };
  Widget.prototype.buildClassSet_30 = function (classSetBuilder) {
    if (this._classes == null ? !(this._intClasses == null) : false) {
      classSetBuilder.addAll_17(this._intClasses);
    } else if (!(this._classes == null)) {
      classSetBuilder.addAll_17(ensureNotNull(this._classes));
    }if (!this._get_visible__31()) {
      classSetBuilder.add_27('hidden');
    }};
  Widget.prototype.buildAttributeSet_30 = function (attributeSetBuilder) {
    if (this._attributes == null) {
      var tmp = this;
      tmp._attributes = LinkedHashMap_init_$Create$();
    }attributeSetBuilder.addAll_15(ensureNotNull(this._attributes));
    var tmp0_safe_receiver = this._get_id__30();
    if (tmp0_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('id', tmp0_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp1_safe_receiver = this._get_title__30();
    if (tmp1_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('title', this.translate_31(tmp1_safe_receiver));
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp2_safe_receiver = this._get_role__30();
    if (tmp2_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('role', tmp2_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp3_safe_receiver = this._get_tabindex__30();
    if (tmp3_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('tabindex', tmp3_safe_receiver.toString());
      Unit_getInstance();
    }
    Unit_getInstance();
    if (this._get_draggable__30() === true) {
      attributeSetBuilder.add_24('draggable', 'true');
    }};
  Widget.prototype.getSnOn_30 = function () {
    if (this._internalListenersMap == null ? this._listenersMap == null : false)
      return null;
    var tmp0_safe_receiver = this._internalListenersMap;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp0_filterTo_0_1 = LinkedHashMap_init_$Create$();
      var tmp0_iterator_1_2 = tmp0_safe_receiver._get_entries__5().iterator_38();
      while (tmp0_iterator_1_2.hasNext_11()) {
        var element_2_3 = tmp0_iterator_1_2.next_13();
        var tmp_0;
        if (!(element_2_3._get_key__2() === 'self')) {
          var tmp0_isNotEmpty_0_5 = element_2_3._get_value__18();
          tmp_0 = !tmp0_isNotEmpty_0_5.isEmpty_33();
        } else {
          tmp_0 = false;
        }
        if (tmp_0) {
          tmp0_filterTo_0_1.put_6(element_2_3._get_key__2(), element_2_3._get_value__18());
          Unit_getInstance();
        } else {
        }
      }
      tmp = tmp0_filterTo_0_1;
    }
    var tmp1_safe_receiver = tmp;
    var tmp_1;
    if (tmp1_safe_receiver == null) {
      tmp_1 = null;
    } else {
      var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(tmp1_safe_receiver._get_size__43());
      var tmp0_iterator_1_2_0 = tmp1_safe_receiver._get_entries__5().iterator_38();
      while (tmp0_iterator_1_2_0.hasNext_11()) {
        var item_2_3 = tmp0_iterator_1_2_0.next_13();
        var internalListeners_5 = LinkedHashMap_init_$Create$();
        internalListeners_5.putAll_3(item_2_3._get_value__18());
        tmp0_mapTo_0_1.add_57(to(item_2_3._get_key__2(), internalListeners_5));
        Unit_getInstance();
      }
      tmp_1 = tmp0_mapTo_0_1;
    }
    var tmp2_safe_receiver = tmp_1;
    var tmp3_safe_receiver = tmp2_safe_receiver == null ? null : toMap(tmp2_safe_receiver);
    var tmp4_elvis_lhs = tmp3_safe_receiver == null ? null : toMutableMap(tmp3_safe_receiver);
    var tmp_2;
    if (tmp4_elvis_lhs == null) {
      tmp_2 = LinkedHashMap_init_$Create$();
    } else {
      tmp_2 = tmp4_elvis_lhs;
    }
    var map = tmp_2;
    var tmp5_safe_receiver = this._listenersMap;
    var tmp_3;
    if (tmp5_safe_receiver == null) {
      tmp_3 = null;
    } else {
      var tmp0_filterTo_0_1_0 = LinkedHashMap_init_$Create$();
      var tmp0_iterator_1_2_1 = tmp5_safe_receiver._get_entries__5().iterator_38();
      while (tmp0_iterator_1_2_1.hasNext_11()) {
        var element_2_3_0 = tmp0_iterator_1_2_1.next_13();
        var tmp_4;
        if (!(element_2_3_0._get_key__2() === 'self')) {
          var tmp0_isNotEmpty_0_5_0 = element_2_3_0._get_value__18();
          tmp_4 = !tmp0_isNotEmpty_0_5_0.isEmpty_33();
        } else {
          tmp_4 = false;
        }
        if (tmp_4) {
          tmp0_filterTo_0_1_0.put_6(element_2_3_0._get_key__2(), element_2_3_0._get_value__18());
          Unit_getInstance();
        } else {
        }
      }
      tmp_3 = tmp0_filterTo_0_1_0;
    }
    var tmp6_safe_receiver = tmp_3;
    if (tmp6_safe_receiver == null)
      null;
    else {
      var tmp0_iterator_1 = tmp6_safe_receiver._get_entries__5().iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        var event_4 = element_2._get_key__2();
        var listeners_5 = element_2._get_value__18();
        var internalListeners_6 = map.get_20(event_4);
        if (!(internalListeners_6 == null)) {
          internalListeners_6.putAll_3(listeners_5);
        } else {
          map.put_6(event_4, listeners_5);
          Unit_getInstance();
        }
      }
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp_5;
    if (!map.isEmpty_33()) {
      var handlers = emptyOn();
      var tmp0_iterator_1_0 = map._get_entries__5().iterator_38();
      while (tmp0_iterator_1_0.hasNext_11()) {
        var element_2_0 = tmp0_iterator_1_0.next_13();
        var event_4_0 = element_2_0._get_key__2();
        var listeners_5_0 = element_2_0._get_value__18();
        var tmp_6;
        if (listeners_5_0._get_size__43() === 1) {
          tmp_6 = first(listeners_5_0._get_values__3());
        } else {
          var tmp0_mapTo_0_1_7 = ArrayList_init_$Create$_0(listeners_5_0._get_size__43());
          var tmp0_iterator_1_2_8 = listeners_5_0._get_entries__5().iterator_38();
          while (tmp0_iterator_1_2_8.hasNext_11()) {
            var item_2_3_9 = tmp0_iterator_1_2_8.next_13();
            var tmp0_arrayOf_0_5_10 = [item_2_3_9._get_value__18()];
            tmp0_mapTo_0_1_7.add_57(tmp0_arrayOf_0_5_10);
            Unit_getInstance();
          }
          var tmp0_toTypedArray_0_6 = tmp0_mapTo_0_1_7;
          tmp_6 = copyToArray_0(tmp0_toTypedArray_0_6);
        }
        handlers[event_4_0] = tmp_6;
      }
      tmp_5 = handlers;
    } else {
      {
        tmp_5 = null;
      }
    }
    return tmp_5;
  };
  Widget.prototype.getSnHooks_30 = function () {
    var hooks = {};
    hooks.create = _no_name_provided_$factory_126(this);
    hooks.insert = _no_name_provided_$factory_127(this);
    hooks.destroy = _no_name_provided_$factory_128(this);
    Unit_getInstance();
    return hooks;
  };
  Widget.prototype.setInternalEventListener_4 = function (block) {
    if (this._internalListenersMap == null) {
      var tmp = this;
      tmp._internalListenersMap = LinkedHashMap_init_$Create$();
    }var tmp0_this = this;
    var tmp1 = tmp0_this._listenerCounter;
    tmp0_this._listenerCounter = tmp1 + 1 | 0;
    var handlerCounter = tmp1;
    var blockAsWidget = typeof block === 'function' ? block : THROW_CCE();
    var handlers = on(this);
    _no_name_provided_$factory_129(handlers)(blockAsWidget);
    Unit_getInstance();
    var tmp2_iterator = iterator(Object.keys(handlers));
    while (tmp2_iterator.hasNext_11()) {
      var key = tmp2_iterator.next_13();
      if (!(key === 'self')) {
        var handler = handlers[key];
        var map = ensureNotNull(this._internalListenersMap).get_20(key);
        if (!(map == null)) {
          var tmp0_set_0 = handler;
          map.put_6(handlerCounter, tmp0_set_0);
          Unit_getInstance();
        } else {
          var tmp1_set_0 = ensureNotNull(this._internalListenersMap);
          var tmp2_set_0 = mutableMapOf([to(handlerCounter, handler)]);
          tmp1_set_0.put_6(key, tmp2_set_0);
          Unit_getInstance();
        }
      }}
    this.refresh_33();
    Unit_getInstance();
    return handlerCounter;
  };
  Widget.prototype.setEventListener_30 = function (block) {
    if (this._listenersMap == null) {
      var tmp = this;
      tmp._listenersMap = LinkedHashMap_init_$Create$();
    }var tmp0_this = this;
    var tmp1 = tmp0_this._listenerCounter;
    tmp0_this._listenerCounter = tmp1 + 1 | 0;
    var handlerCounter = tmp1;
    var blockAsWidget = typeof block === 'function' ? block : THROW_CCE();
    var tmp2_elvis_lhs = this._eventTarget;
    var handlers = on(tmp2_elvis_lhs == null ? this : tmp2_elvis_lhs);
    _no_name_provided_$factory_130(handlers)(blockAsWidget);
    Unit_getInstance();
    var tmp3_iterator = iterator(Object.keys(handlers));
    while (tmp3_iterator.hasNext_11()) {
      var key = tmp3_iterator.next_13();
      if (!(key === 'self')) {
        var handler = handlers[key];
        var map = ensureNotNull(this._listenersMap).get_20(key);
        if (!(map == null)) {
          var tmp0_set_0 = handler;
          map.put_6(handlerCounter, tmp0_set_0);
          Unit_getInstance();
        } else {
          var tmp1_set_0 = ensureNotNull(this._listenersMap);
          var tmp2_set_0 = mutableMapOf([to(handlerCounter, handler)]);
          tmp1_set_0.put_6(key, tmp2_set_0);
          Unit_getInstance();
        }
      }}
    this.refresh_33();
    Unit_getInstance();
    return handlerCounter;
  };
  Widget.prototype.show_27 = function () {
    this._set_visible__31(true);
    return this;
  };
  Widget.prototype.hide_26 = function () {
    this._set_visible__31(false);
    return this;
  };
  Widget.prototype.slideDown_31 = function (duration, easing, complete) {
    this._set_display__31(Display_NONE_getInstance());
    this._set_visible__31(true);
    var jq = this.getElementJQuery_31();
    if (!(jq == null)) {
      jq.slideDown(duration, easing._easing, _no_name_provided_$factory_131(this, complete));
      Unit_getInstance();
    } else {
      this._set_display__31(null);
      var tmp0_safe_receiver = complete;
      if (tmp0_safe_receiver == null)
        null;
      else
        tmp0_safe_receiver();
      Unit_getInstance();
    }
    return this;
  };
  Widget.prototype.slideDown$default = function (duration, easing, complete, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      duration = 400;
    if (!(($mask0 & 2) === 0))
      easing = Easing_SWING_getInstance();
    if (!(($mask0 & 4) === 0))
      complete = null;
    return $handler == null ? this.slideDown_31(duration, easing, complete) : $handler(duration, easing, complete);
  };
  Widget.prototype.slideUp_31 = function (duration, easing, complete) {
    var jq = this.getElementJQuery_31();
    if (!(jq == null)) {
      jq.slideUp(duration, easing._easing, _no_name_provided_$factory_132(this, complete));
      Unit_getInstance();
    } else {
      this._set_visible__31(false);
      var tmp0_safe_receiver = complete;
      if (tmp0_safe_receiver == null)
        null;
      else
        tmp0_safe_receiver();
      Unit_getInstance();
    }
    return this;
  };
  Widget.prototype.slideUp$default = function (duration, easing, complete, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      duration = 400;
    if (!(($mask0 & 2) === 0))
      easing = Easing_SWING_getInstance();
    if (!(($mask0 & 4) === 0))
      complete = null;
    return $handler == null ? this.slideUp_31(duration, easing, complete) : $handler(duration, easing, complete);
  };
  Widget.prototype.enableTooltip_30 = function (options) {
    this._tooltipOptions = options;
    var tmp0_safe_receiver = this.getElementJQueryD_30();
    var tooltipFun = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.tooltip;
    if (tooltipFun != undefined) {
      var tmp2_safe_receiver = this.getElementJQueryD_30();
      if (tmp2_safe_receiver == null)
        null;
      else {
        var tmp1_safe_receiver = options._title;
        var tmp;
        if (tmp1_safe_receiver == null) {
          tmp = null;
        } else {
          tmp = this.translate_31(tmp1_safe_receiver);
        }
        var tmp_0 = tmp;
        tmp2_safe_receiver.tooltip(toJs(options.copy$default(tmp_0, null, null, null, null, null, null, null, 254, null)));
      }
    }return this;
  };
  Widget.prototype.enableTooltip$default = function (options, $mask0, $handler) {
    if (!(($mask0 & 1) === 0)) {
      options = TooltipOptions_init_$Create$(null, null, null, null, null, null, null, null, 255, null);
    }return $handler == null ? this.enableTooltip_30(options) : $handler(options);
  };
  Widget.prototype.addCssClass_31 = function (css) {
    if (this._classes == null) {
      var tmp = this;
      var tmp0_safe_receiver = this._intClasses;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toMutableSet(tmp0_safe_receiver);
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        tmp_0 = LinkedHashSet_init_$Create$();
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      tmp._classes = tmp_0;
    }ensureNotNull(this._classes).add_57(css);
    Unit_getInstance();
    this.refresh_33();
    Unit_getInstance();
    return this;
  };
  Widget.prototype.removeCssClass_31 = function (css) {
    if (this._classes == null) {
      var tmp = this;
      var tmp0_safe_receiver = this._intClasses;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toMutableSet(tmp0_safe_receiver);
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        tmp_0 = LinkedHashSet_init_$Create$();
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      tmp._classes = tmp_0;
    }ensureNotNull(this._classes).remove_59(css);
    Unit_getInstance();
    this.refresh_33();
    Unit_getInstance();
    return this;
  };
  Widget.prototype.addSurroundingCssClass_0 = function (css) {
    if (this._surroundingClasses == null) {
      var tmp = this;
      tmp._surroundingClasses = LinkedHashSet_init_$Create$();
    }ensureNotNull(this._surroundingClasses).add_57(css);
    Unit_getInstance();
    this.refresh_33();
    Unit_getInstance();
    return this;
  };
  Widget.prototype.removeSurroundingCssClass_0 = function (css) {
    if (this._surroundingClasses == null) {
      var tmp = this;
      tmp._surroundingClasses = LinkedHashSet_init_$Create$();
    }ensureNotNull(this._surroundingClasses).remove_59(css);
    Unit_getInstance();
    this.refresh_33();
    Unit_getInstance();
    return this;
  };
  Widget.prototype.getElement_30 = function () {
    var tmp0_safe_receiver = this._vnode;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.elm;
  };
  Widget.prototype.getElementJQuery_31 = function () {
    var tmp0_safe_receiver = this.getElement_30();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp0_invoke_0_2 = jQuery;
      tmp = tmp0_invoke_0_2(tmp0_safe_receiver);
    }
    return tmp;
  };
  Widget.prototype.getElementJQueryD_30 = function () {
    var tmp0_safe_receiver = this.getElement_30();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp0_invoke_0_3 = jQuery;
      var tmp1_asDynamic_0_2 = tmp0_invoke_0_3(tmp0_safe_receiver);
      tmp = tmp1_asDynamic_0_2;
    }
    return tmp;
  };
  Widget.prototype.clearParent_31 = function () {
    this._set_parent__31(null);
    return this;
  };
  Widget.prototype.refresh_33 = function () {
    StyledComponent.prototype.refresh_33.call(this);
    Unit_getInstance();
    this._snAttrsCache.clear_27();
    this._snClassCache.clear_27();
    this._snOnCache = null;
    this._snHooksCache = null;
    var tmp0_safe_receiver = this.getRoot_31();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.reRender();
    Unit_getInstance();
    return this;
  };
  Widget.prototype.afterCreate_30 = function (node) {
  };
  Widget.prototype.afterInsertInternal_30 = function (node) {
    var tmp0_safe_receiver = this._tooltipOptions;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp0_safe_receiver_3 = this.getElementJQueryD_30();
      var tooltipFun_2 = tmp0_safe_receiver_3 == null ? null : tmp0_safe_receiver_3.tooltip;
      if (tooltipFun_2 != undefined) {
        var tmp2_safe_receiver_4 = this.getElementJQueryD_30();
        if (tmp2_safe_receiver_4 == null)
          null;
        else {
          var tmp1_safe_receiver_5 = tmp0_safe_receiver._title;
          var tmp;
          if (tmp1_safe_receiver_5 == null) {
            tmp = null;
          } else {
            tmp = this.translate_31(tmp1_safe_receiver_5);
          }
          var tmp_0 = tmp;
          tmp2_safe_receiver_4.tooltip(toJs(tmp0_safe_receiver.copy$default(tmp_0, null, null, null, null, null, null, null, 254, null)));
        }
      }Unit_getInstance();
    }
    Unit_getInstance();
    var tmp1_safe_receiver = this._popoverOptions;
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp0_safe_receiver_3_0 = this.getElementJQueryD_30();
      var popoverFun_2 = tmp0_safe_receiver_3_0 == null ? null : tmp0_safe_receiver_3_0.popover;
      if (popoverFun_2 != undefined) {
        var tmp5_safe_receiver_4 = this.getElementJQueryD_30();
        if (tmp5_safe_receiver_4 == null)
          null;
        else {
          var tmp2_safe_receiver_6 = tmp1_safe_receiver._title_0;
          var tmp_1;
          if (tmp2_safe_receiver_6 == null) {
            tmp_1 = null;
          } else {
            tmp_1 = this.translate_31(tmp2_safe_receiver_6);
          }
          var tmp3_title_5 = tmp_1;
          var tmp1_safe_receiver_8 = tmp1_safe_receiver._content;
          var tmp_2;
          if (tmp1_safe_receiver_8 == null) {
            tmp_2 = null;
          } else {
            tmp_2 = this.translate_31(tmp1_safe_receiver_8);
          }
          var tmp4_content_7 = tmp_2;
          tmp5_safe_receiver_4.popover(toJs_0(tmp1_safe_receiver.copy$default_0(tmp4_content_7, tmp3_title_5, null, null, null, null, null, null, null, 508, null)));
        }
      }Unit_getInstance();
    }
    Unit_getInstance();
  };
  Widget.prototype.afterInsert_30 = function (node) {
  };
  Widget.prototype.afterDestroyInternal_30 = function () {
    var tmp0_safe_receiver = this._tooltipOptions;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp0_safe_receiver_3 = this.getElementJQueryD_30();
      var tooltipFun_2 = tmp0_safe_receiver_3 == null ? null : tmp0_safe_receiver_3.tooltip;
      if (tooltipFun_2 != undefined) {
        var tmp1_safe_receiver_4 = this.getElementJQueryD_30();
        if (tmp1_safe_receiver_4 == null)
          null;
        else
          tmp1_safe_receiver_4.tooltip('dispose');
      }Unit_getInstance();
    }
    Unit_getInstance();
    var tmp1_safe_receiver = this._popoverOptions;
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp0_safe_receiver_3_0 = this.getElementJQueryD_30();
      var popoverFun_2 = tmp0_safe_receiver_3_0 == null ? null : tmp0_safe_receiver_3_0.popover;
      if (popoverFun_2 != undefined) {
        var tmp1_safe_receiver_4_0 = this.getElementJQueryD_30();
        if (tmp1_safe_receiver_4_0 == null)
          null;
        else
          tmp1_safe_receiver_4_0.popover('dispose');
      }Unit_getInstance();
    }
    Unit_getInstance();
  };
  Widget.prototype.afterDestroy_30 = function () {
  };
  Widget.prototype.getRoot_31 = function () {
    var tmp0_safe_receiver = this._get_parent__31();
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.getRoot_31();
  };
  Widget.prototype.createLabelWithIcon_0 = function (label, icon, image, separator) {
    var translatedLabel = this.translate_31(label);
    var tmp;
    if (!(icon == null)) {
      var tmp_0 = [' '];
      var iconClasses = toSet_0(split$default(icon, tmp_0, false, 0, 6, null));
      var tmp0_snOpt_0 = _no_name_provided_$factory_133(iconClasses);
      var iconOpt = _no_name_provided_$factory_134({})(tmp0_snOpt_0);
      var tmp_1;
      if (separator == null) {
        var tmp1_arrayOf_0 = [h('i', iconOpt), '' + ' ' + translatedLabel];
        tmp_1 = tmp1_arrayOf_0;
      } else {
        var tmp2_arrayOf_0 = [h('i', iconOpt), KVManager_getInstance().virtualize(separator), translatedLabel];
        tmp_1 = tmp2_arrayOf_0;
      }
      tmp = tmp_1;
    } else if (!(image == null)) {
      var tmp3_snOpt_0 = _no_name_provided_$factory_135(image);
      var imageOpt = _no_name_provided_$factory_136({})(tmp3_snOpt_0);
      var tmp_2;
      if (separator == null) {
        var tmp4_arrayOf_0 = [h('img', imageOpt), '' + ' ' + translatedLabel];
        tmp_2 = tmp4_arrayOf_0;
      } else {
        var tmp5_arrayOf_0 = [h('img', imageOpt), KVManager_getInstance().virtualize(separator), translatedLabel];
        tmp_2 = tmp5_arrayOf_0;
      }
      tmp = tmp_2;
    } else {
      var tmp6_arrayOf_0 = [translatedLabel];
      tmp = tmp6_arrayOf_0;
    }
    return tmp;
  };
  Widget.prototype.dispatchEvent_4 = function (type, eventInitDict) {
    var event = new CustomEvent(type, eventInitDict);
    var tmp0_safe_receiver = this.getElement_30();
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.dispatchEvent(event);
  };
  Widget.prototype.dispose_46 = function () {
    var tmp0_safe_receiver = this._beforeDisposeHooks;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp0_iterator_1 = tmp0_safe_receiver.iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        element_2();
      }
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  Widget.$metadata$ = {
    simpleName: 'Widget',
    kind: 'class',
    interfaces: [Component]
  };
  var Easing_SWING_instance;
  var Easing_LINEAR_instance;
  var Easing_entriesInitialized;
  function Easing_initEntries() {
    if (Easing_entriesInitialized)
      return Unit_getInstance();
    Easing_entriesInitialized = true;
    Easing_SWING_instance = new Easing('SWING', 0, 'swing');
    Easing_LINEAR_instance = new Easing('LINEAR', 1, 'linear');
  }
  function Easing(name, ordinal, easing) {
    Enum.call(this, name, ordinal);
    this._easing = easing;
  }
  Easing.$metadata$ = {
    simpleName: 'Easing',
    kind: 'class',
    interfaces: []
  };
  function Easing_SWING_getInstance() {
    Easing_initEntries();
    return Easing_SWING_instance;
  }
  function title$factory() {
    return getPropertyCallableRef('title', 1, KMutableProperty1, function (receiver) {
      return receiver._get_title__30();
    }, function (receiver_0, value) {
      return receiver_0._set_title__30(value);
    });
  }
  function id$factory() {
    return getPropertyCallableRef('id', 1, KMutableProperty1, function (receiver) {
      return receiver._get_id__30();
    }, function (receiver_0, value) {
      return receiver_0._set_id__30(value);
    });
  }
  function role$factory() {
    return getPropertyCallableRef('role', 1, KMutableProperty1, function (receiver) {
      return receiver._get_role__30();
    }, function (receiver_0, value) {
      return receiver_0._set_role__30(value);
    });
  }
  function tabindex$factory() {
    return getPropertyCallableRef('tabindex', 1, KMutableProperty1, function (receiver) {
      return receiver._get_tabindex__30();
    }, function (receiver_0, value) {
      return receiver_0._set_tabindex__30(value);
    });
  }
  function draggable$factory() {
    return getPropertyCallableRef('draggable', 1, KMutableProperty1, function (receiver) {
      return receiver._get_draggable__30();
    }, function (receiver_0, value) {
      return receiver_0._set_draggable__30(value);
    });
  }
  function surroundingSpan$factory() {
    return getPropertyCallableRef('surroundingSpan', 1, KMutableProperty1, function (receiver) {
      return receiver._get_surroundingSpan__30();
    }, function (receiver_0, value) {
      return receiver_0._set_surroundingSpan__30(value);
    });
  }
  function title$factory_0() {
    return getPropertyCallableRef('title', 1, KMutableProperty1, function (receiver) {
      return receiver._get_title__30();
    }, function (receiver_0, value) {
      return receiver_0._set_title__30(value);
    });
  }
  function title$factory_1() {
    return getPropertyCallableRef('title', 1, KMutableProperty1, function (receiver) {
      return receiver._get_title__30();
    }, function (receiver_0, value) {
      return receiver_0._set_title__30(value);
    });
  }
  function id$factory_0() {
    return getPropertyCallableRef('id', 1, KMutableProperty1, function (receiver) {
      return receiver._get_id__30();
    }, function (receiver_0, value) {
      return receiver_0._set_id__30(value);
    });
  }
  function id$factory_1() {
    return getPropertyCallableRef('id', 1, KMutableProperty1, function (receiver) {
      return receiver._get_id__30();
    }, function (receiver_0, value) {
      return receiver_0._set_id__30(value);
    });
  }
  function role$factory_0() {
    return getPropertyCallableRef('role', 1, KMutableProperty1, function (receiver) {
      return receiver._get_role__30();
    }, function (receiver_0, value) {
      return receiver_0._set_role__30(value);
    });
  }
  function role$factory_1() {
    return getPropertyCallableRef('role', 1, KMutableProperty1, function (receiver) {
      return receiver._get_role__30();
    }, function (receiver_0, value) {
      return receiver_0._set_role__30(value);
    });
  }
  function tabindex$factory_0() {
    return getPropertyCallableRef('tabindex', 1, KMutableProperty1, function (receiver) {
      return receiver._get_tabindex__30();
    }, function (receiver_0, value) {
      return receiver_0._set_tabindex__30(value);
    });
  }
  function tabindex$factory_1() {
    return getPropertyCallableRef('tabindex', 1, KMutableProperty1, function (receiver) {
      return receiver._get_tabindex__30();
    }, function (receiver_0, value) {
      return receiver_0._set_tabindex__30(value);
    });
  }
  function draggable$factory_0() {
    return getPropertyCallableRef('draggable', 1, KMutableProperty1, function (receiver) {
      return receiver._get_draggable__30();
    }, function (receiver_0, value) {
      return receiver_0._set_draggable__30(value);
    });
  }
  function draggable$factory_1() {
    return getPropertyCallableRef('draggable', 1, KMutableProperty1, function (receiver) {
      return receiver._get_draggable__30();
    }, function (receiver_0, value) {
      return receiver_0._set_draggable__30(value);
    });
  }
  function surroundingSpan$factory_0() {
    return getPropertyCallableRef('surroundingSpan', 1, KMutableProperty1, function (receiver) {
      return receiver._get_surroundingSpan__30();
    }, function (receiver_0, value) {
      return receiver_0._set_surroundingSpan__30(value);
    });
  }
  function surroundingSpan$factory_1() {
    return getPropertyCallableRef('surroundingSpan', 1, KMutableProperty1, function (receiver) {
      return receiver._get_surroundingSpan__30();
    }, function (receiver_0, value) {
      return receiver_0._set_surroundingSpan__30(value);
    });
  }
  function _no_name_provided_$factory_113(this$0) {
    var i = new _no_name_provided__126(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_114(this$0) {
    var i = new _no_name_provided__127(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_115(this$0) {
    var i = new _no_name_provided__128(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_116(this$0) {
    var i = new _no_name_provided__129(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_117(this$0) {
    var i = new _no_name_provided__130(this$0);
    return function (p1) {
      i.invoke_476(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_118(this$0) {
    var i = new _no_name_provided__131(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_119(this$0) {
    var i = new _no_name_provided__132(this$0);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_120(this$0) {
    var i = new _no_name_provided__133(this$0);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_121(this$0) {
    var i = new _no_name_provided__134(this$0);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_122(this$0) {
    var i = new _no_name_provided__135(this$0);
    return function (p1) {
      i.invoke_398(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_123($boundThis) {
    var i = new _no_name_provided__136($boundThis);
    var l = function (p1) {
      return i.invoke_400(p1);
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_124(this$0) {
    var i = new _no_name_provided__137(this$0);
    return function (p1) {
      i.invoke_398(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_125($boundThis) {
    var i = new _no_name_provided__138($boundThis);
    var l = function (p1) {
      return i.invoke_400(p1);
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_126(this$0) {
    var i = new _no_name_provided__139(this$0);
    return function (p1, p2) {
      i.invoke_258(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_127(this$0) {
    var i = new _no_name_provided__140(this$0);
    return function (p1) {
      return i.invoke_262(p1);
    };
  }
  function _no_name_provided_$factory_128(this$0) {
    var i = new _no_name_provided__141(this$0);
    return function (p1) {
      return i.invoke_262(p1);
    };
  }
  function _no_name_provided_$factory_129($boundThis) {
    var i = new _no_name_provided__142($boundThis);
    var l = function (p1) {
      return i.invoke_266(p1);
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_130($boundThis) {
    var i = new _no_name_provided__143($boundThis);
    var l = function (p1) {
      return i.invoke_266(p1);
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_131(this$0, $complete) {
    var i = new _no_name_provided__144(this$0, $complete);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_132(this$0, $complete) {
    var i = new _no_name_provided__145(this$0, $complete);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_133($iconClasses) {
    var i = new _no_name_provided__146($iconClasses);
    return function (p1) {
      i.invoke_398(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_134($boundThis) {
    var i = new _no_name_provided__147($boundThis);
    var l = function (p1) {
      return i.invoke_400(p1);
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_135($image) {
    var i = new _no_name_provided__148($image);
    return function (p1) {
      i.invoke_398(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_136($boundThis) {
    var i = new _no_name_provided__149($boundThis);
    var l = function (p1) {
      return i.invoke_400(p1);
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_137($this_bindState, $removeChildren, $factory) {
    var i = new _no_name_provided__123($this_bindState, $removeChildren, $factory);
    return function (p1) {
      i.invoke_229(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_138($removeChildren, $this_bindState, $factory, $it) {
    var i = new _no_name_provided__122($removeChildren, $this_bindState, $factory, $it);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_139($boundThis) {
    var i = new _no_name_provided__124($boundThis);
    var l = function (p1) {
      i.invoke_231(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_140($boundThis) {
    var i = new _no_name_provided__125($boundThis);
    var l = function (p1) {
      i.invoke_233(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function WidgetWrapper(wrapped, classes) {
    SimplePanel_init_$Init$(classes, null, 2, null, this);
    this._wrapped = wrapped;
    var tmp0_safe_receiver = this._wrapped;
    if (tmp0_safe_receiver == null) {
      Unit_getInstance();
    } else
      tmp0_safe_receiver._set_parent__31(this);
  }
  WidgetWrapper.prototype._set_visible__31 = function (value) {
    var tmp0_safe_receiver = this._wrapped;
    if (tmp0_safe_receiver == null) {
      Unit_getInstance();
    } else
      tmp0_safe_receiver._set_visible__31(value);
  };
  WidgetWrapper.prototype._get_visible__31 = function () {
    var tmp0_safe_receiver = this._wrapped;
    return (tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_visible__31()) === true;
  };
  WidgetWrapper.prototype.render_88 = function () {
    var tmp0_safe_receiver = this._wrapped;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp0_arrayOf_0_2 = [tmp0_safe_receiver.renderVNode_31()];
      tmp = this.render_87('div', tmp0_arrayOf_0_2);
    }
    var tmp1_elvis_lhs = tmp;
    return tmp1_elvis_lhs == null ? this.render_89('div') : tmp1_elvis_lhs;
  };
  WidgetWrapper.prototype.dispose_46 = function () {
    SimplePanel.prototype.dispose_46.call(this);
    var tmp0_safe_receiver = this._wrapped;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.clearParent_31();
    Unit_getInstance();
    this._wrapped = null;
  };
  WidgetWrapper.$metadata$ = {
    simpleName: 'WidgetWrapper',
    kind: 'class',
    interfaces: []
  };
  function FieldLabel_init_$Init$(forId, content, rich, classes, init, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      content = null;
    if (!(($mask0 & 4) === 0))
      rich = false;
    if (!(($mask0 & 8) === 0)) {
      classes = emptySet();
    }if (!(($mask0 & 16) === 0))
      init = null;
    FieldLabel.call($this, forId, content, rich, classes, init);
    return $this;
  }
  function FieldLabel_init_$Create$(forId, content, rich, classes, init, $mask0, $marker) {
    return FieldLabel_init_$Init$(forId, content, rich, classes, init, $mask0, $marker, Object.create(FieldLabel.prototype));
  }
  function FieldLabel(forId, content, rich, classes, init) {
    var tmp = TAG_LABEL_getInstance();
    Tag_init_$Init$(tmp, content, rich, null, classes, null, null, 104, null, this);
    this._forId = forId;
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  FieldLabel.prototype.buildAttributeSet_30 = function (attributeSetBuilder) {
    Tag.prototype.buildAttributeSet_30.call(this, attributeSetBuilder);
    attributeSetBuilder.add_24('for', this._forId);
  };
  FieldLabel.$metadata$ = {
    simpleName: 'FieldLabel',
    kind: 'class',
    interfaces: []
  };
  function fieldLabel(_this_, forId, content, rich, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var fieldLabel_0 = new FieldLabel(forId, content, rich, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(fieldLabel_0);
    Unit_getInstance();
    return fieldLabel_0;
  }
  function fieldLabel$default(_this_, forId, content, rich, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      content = null;
    if (!(($mask0 & 4) === 0))
      rich = false;
    if (!(($mask0 & 8) === 0))
      classes = null;
    if (!(($mask0 & 16) === 0))
      className = null;
    if (!(($mask0 & 32) === 0))
      init = null;
    return fieldLabel(_this_, forId, content, rich, classes, className, init);
  }
  function FormControl() {
  }
  FormControl.prototype._set_name__6 = function (value) {
    this._get_input__4()._set_name__6(value);
  };
  FormControl.prototype._get_name__47 = function () {
    return this._get_input__4()._get_name__47();
  };
  FormControl.prototype._set_validationStatus__10 = function (value) {
    this._get_input__4()._set_validationStatus__10(value);
  };
  FormControl.prototype._set_validatorError__6 = function (value) {
    this._get_invalidFeedback__4()._set_content__9(value);
    this._get_invalidFeedback__4()._set_visible__31(!(value == null));
    this._get_input__4()._set_validationStatus__10(!(value == null) ? ValidationStatus_INVALID_getInstance() : null);
    this.refresh_33();
    Unit_getInstance();
  };
  FormControl.prototype._get_validatorError__6 = function () {
    return this._get_invalidFeedback__4()._get_content__8();
  };
  FormControl.$metadata$ = {
    simpleName: 'FormControl',
    kind: 'interface',
    interfaces: [Component]
  };
  var ValidationStatus_VALID_instance;
  var ValidationStatus_INVALID_instance;
  var ValidationStatus_entriesInitialized;
  function ValidationStatus_initEntries() {
    if (ValidationStatus_entriesInitialized)
      return Unit_getInstance();
    ValidationStatus_entriesInitialized = true;
    ValidationStatus_VALID_instance = new ValidationStatus('VALID', 0, 'is-valid');
    ValidationStatus_INVALID_instance = new ValidationStatus('INVALID', 1, 'is-invalid');
  }
  function ValidationStatus(name, ordinal, className) {
    Enum.call(this, name, ordinal);
    this._className = className;
  }
  ValidationStatus.prototype._get_className__3 = function () {
    return this._className;
  };
  ValidationStatus.$metadata$ = {
    simpleName: 'ValidationStatus',
    kind: 'class',
    interfaces: [CssClass]
  };
  function FormInput() {
  }
  FormInput.$metadata$ = {
    simpleName: 'FormInput',
    kind: 'interface',
    interfaces: [Component]
  };
  function InputSize() {
  }
  InputSize.$metadata$ = {
    simpleName: 'InputSize',
    kind: 'class',
    interfaces: [CssClass]
  };
  function GenericFormControl() {
  }
  GenericFormControl.$metadata$ = {
    simpleName: 'GenericFormControl',
    kind: 'interface',
    interfaces: [FormControl]
  };
  function BoolFormControl() {
  }
  BoolFormControl.$metadata$ = {
    simpleName: 'BoolFormControl',
    kind: 'interface',
    interfaces: [FormControl]
  };
  function StringFormControl() {
  }
  StringFormControl.$metadata$ = {
    simpleName: 'StringFormControl',
    kind: 'interface',
    interfaces: [FormControl]
  };
  function NumberFormControl() {
  }
  NumberFormControl.$metadata$ = {
    simpleName: 'NumberFormControl',
    kind: 'interface',
    interfaces: [FormControl]
  };
  function ValidationStatus_VALID_getInstance() {
    ValidationStatus_initEntries();
    return ValidationStatus_VALID_instance;
  }
  function ValidationStatus_INVALID_getInstance() {
    ValidationStatus_initEntries();
    return ValidationStatus_INVALID_instance;
  }
  function InvalidFeedback_init_$Init$(content, rich, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      content = null;
    if (!(($mask0 & 2) === 0))
      rich = false;
    InvalidFeedback.call($this, content, rich);
    return $this;
  }
  function InvalidFeedback_init_$Create$(content, rich, $mask0, $marker) {
    return InvalidFeedback_init_$Init$(content, rich, $mask0, $marker, Object.create(InvalidFeedback.prototype));
  }
  function InvalidFeedback(content, rich) {
    var tmp = TAG_DIV_getInstance();
    var tmp_0 = setOf('invalid-feedback');
    Tag_init_$Init$(tmp, content, rich, null, tmp_0, null, null, 104, null, this);
  }
  InvalidFeedback.$metadata$ = {
    simpleName: 'InvalidFeedback',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__150(this$0, $this_setInternalEventListener) {
    this._this$0_104 = this$0;
    this._$this_setInternalEventListener = $this_setInternalEventListener;
  }
  _no_name_provided__150.prototype.invoke_992 = function (it) {
    var tmp0_safe_receiver = this._this$0_104.getElementJQuery_31();
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.prop('checked');
    var v = (tmp == null ? true : typeof tmp === 'boolean') ? tmp : THROW_CCE();
    this._$this_setInternalEventListener.self._set_value__10(v === true);
  };
  _no_name_provided__150.prototype.invoke_1082 = function (p1) {
    this.invoke_992(p1 instanceof MouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__150.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__151(this$0, $this_setInternalEventListener) {
    this._this$0_105 = this$0;
    this._$this_setInternalEventListener_0 = $this_setInternalEventListener;
  }
  _no_name_provided__151.prototype.invoke_795 = function (it) {
    var tmp0_safe_receiver = this._this$0_105.getElementJQuery_31();
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.prop('checked');
    var v = (tmp == null ? true : typeof tmp === 'boolean') ? tmp : THROW_CCE();
    this._$this_setInternalEventListener_0.self._set_value__10(v === true);
  };
  _no_name_provided__151.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__151.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__152(this$0) {
    this._this$0_106 = this$0;
  }
  _no_name_provided__152.prototype.invoke_282 = function ($this$setInternalEventListener) {
    $this$setInternalEventListener.click = _no_name_provided_$factory_151(this._this$0_106, $this$setInternalEventListener);
    $this$setInternalEventListener.change = _no_name_provided_$factory_152(this._this$0_106, $this$setInternalEventListener);
  };
  _no_name_provided__152.prototype.invoke_1082 = function (p1) {
    this.invoke_282((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__152.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__153(this$0) {
    this._this$0_107 = this$0;
  }
  _no_name_provided__153.prototype.invoke_771 = function (it) {
    this._this$0_107.refreshState_2();
    var tmp0_forEach_0 = this._this$0_107._observers;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(it);
    }
  };
  _no_name_provided__153.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__153.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__154(this$0) {
    this._this$0_108 = this$0;
  }
  _no_name_provided__154.prototype.invoke_771 = function (it) {
    this._this$0_108._set_value__10(it);
    this._this$0_108.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__154.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__154.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__155(this$0) {
    this._this$0_109 = this$0;
  }
  _no_name_provided__155.prototype.invoke_288 = function (it) {
    this._this$0_109.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__155.prototype.invoke_1082 = function (p1) {
    this.invoke_288(p1 instanceof CheckInputType ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__155.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__156(this$0) {
    this._this$0_110 = this$0;
  }
  _no_name_provided__156.prototype.invoke_1079 = function (it) {
    this._this$0_110.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__156.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__156.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__157(this$0) {
    this._this$0_111 = this$0;
  }
  _no_name_provided__157.prototype.invoke_771 = function (it) {
    this._this$0_111.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__157.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__157.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__158(this$0) {
    this._this$0_112 = this$0;
  }
  _no_name_provided__158.prototype.invoke_1079 = function (it) {
    this._this$0_112.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__158.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__158.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__159(this$0) {
    this._this$0_113 = this$0;
  }
  _no_name_provided__159.prototype.invoke_478 = function (it) {
    this._this$0_113.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__159.prototype.invoke_1082 = function (p1) {
    this.invoke_478((p1 == null ? true : p1 instanceof InputSize) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__159.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__160(this$0) {
    this._this$0_114 = this$0;
  }
  _no_name_provided__160.prototype.invoke_480 = function (it) {
    this._this$0_114.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__160.prototype.invoke_1082 = function (p1) {
    this.invoke_480((p1 == null ? true : p1 instanceof ValidationStatus) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__160.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__161(this$0, $observer) {
    this._this$0_115 = this$0;
    this._$observer = $observer;
  }
  _no_name_provided__161.prototype.invoke_1037 = function () {
    var tmp0_this = this._this$0_115;
    var tmp0_minusAssign_0 = tmp0_this._observers;
    tmp0_minusAssign_0.remove_59(this._$observer);
    Unit_getInstance();
  };
  _no_name_provided__161.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__161.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function CheckInput(type, value, classes) {
    Widget_init_$Init$(classes, null, 2, null, this);
    var tmp = this;
    tmp._observers = ArrayList_init_$Create$();
    this.setInternalEventListener_4(_no_name_provided_$factory_141(this));
    Unit_getInstance();
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_142(this);
    tmp_0._value$delegate = (new RefreshDelegateProvider_0(this, value, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, value$factory());
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_143(this);
    tmp_1._startValue$delegate = (new RefreshDelegateProvider_0(this, value, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, startValue$factory());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_1 = _no_name_provided_$factory_144(this);
    tmp_2._type$delegate = (new RefreshDelegateProvider_0(this, type, tmp0_refreshOnUpdate_0_1)).provideDelegate_0(this, type$factory());
    var tmp_3 = this;
    var tmp0_refreshOnUpdate_0_2 = _no_name_provided_$factory_145(this);
    tmp_3._name$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_2)).provideDelegate_0(this, name$factory());
    var tmp_4 = this;
    var tmp0_refreshOnUpdate_0_3 = false;
    var tmp1_refreshOnUpdate_0 = _no_name_provided_$factory_146(this);
    tmp_4._disabled$delegate = (new RefreshDelegateProvider_0(this, tmp0_refreshOnUpdate_0_3, tmp1_refreshOnUpdate_0)).provideDelegate_0(this, disabled$factory());
    var tmp_5 = this;
    var tmp0_refreshOnUpdate_0_4 = _no_name_provided_$factory_147(this);
    tmp_5._extraValue$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_4)).provideDelegate_0(this, extraValue$factory());
    var tmp_6 = this;
    var tmp0_refreshOnUpdate_0_5 = _no_name_provided_$factory_148(this);
    tmp_6._size$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_5)).provideDelegate_0(this, size$factory());
    var tmp_7 = this;
    var tmp0_refreshOnUpdate_0_6 = _no_name_provided_$factory_149(this);
    tmp_7._validationStatus$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_6)).provideDelegate_0(this, validationStatus$factory());
  }
  CheckInput.prototype._set_value__10 = function (_set___) {
    return this._value$delegate.setValue_6(this, value$factory_0(), _set___);
  };
  CheckInput.prototype._get_value__18 = function () {
    return this._value$delegate.getValue_3(this, value$factory_1());
  };
  CheckInput.prototype._set_startValue__0 = function (_set___) {
    return this._startValue$delegate.setValue_6(this, startValue$factory_0(), _set___);
  };
  CheckInput.prototype._get_startValue__3 = function () {
    return this._startValue$delegate.getValue_3(this, startValue$factory_1());
  };
  CheckInput.prototype._set_type__2 = function (_set___) {
    return this._type$delegate.setValue_6(this, type$factory_0(), _set___);
  };
  CheckInput.prototype._get_type__23 = function () {
    return this._type$delegate.getValue_3(this, type$factory_1());
  };
  CheckInput.prototype._set_name__6 = function (_set___) {
    return this._name$delegate.setValue_6(this, name$factory_0(), _set___);
  };
  CheckInput.prototype._get_name__47 = function () {
    return this._name$delegate.getValue_3(this, name$factory_1());
  };
  CheckInput.prototype._set_disabled__5 = function (_set___) {
    return this._disabled$delegate.setValue_6(this, disabled$factory_0(), _set___);
  };
  CheckInput.prototype._get_disabled__5 = function () {
    return this._disabled$delegate.getValue_3(this, disabled$factory_1());
  };
  CheckInput.prototype._set_extraValue__0 = function (_set___) {
    return this._extraValue$delegate.setValue_6(this, extraValue$factory_0(), _set___);
  };
  CheckInput.prototype._get_extraValue__1 = function () {
    return this._extraValue$delegate.getValue_3(this, extraValue$factory_1());
  };
  CheckInput.prototype._set_size__6 = function (_set___) {
    return this._size$delegate.setValue_6(this, size$factory_0(), _set___);
  };
  CheckInput.prototype._get_size__43 = function () {
    return this._size$delegate.getValue_3(this, size$factory_1());
  };
  CheckInput.prototype._set_validationStatus__10 = function (_set___) {
    return this._validationStatus$delegate.setValue_6(this, validationStatus$factory_0(), _set___);
  };
  CheckInput.prototype._get_validationStatus__3 = function () {
    return this._validationStatus$delegate.getValue_3(this, validationStatus$factory_1());
  };
  CheckInput.prototype.render_88 = function () {
    return this.render_89('input');
  };
  CheckInput.prototype.buildClassSet_30 = function (classSetBuilder) {
    Widget.prototype.buildClassSet_30.call(this, classSetBuilder);
    classSetBuilder.add_28(this._get_validationStatus__3());
    classSetBuilder.add_28(this._get_size__43());
  };
  CheckInput.prototype.buildAttributeSet_30 = function (attributeSetBuilder) {
    Widget.prototype.buildAttributeSet_30.call(this, attributeSetBuilder);
    attributeSetBuilder.add_24('type', this._get_type__23()._type);
    if (this._get_startValue__3()) {
      attributeSetBuilder.add$default('checked', null, 2, null);
    }var tmp0_safe_receiver = this._get_name__47();
    if (tmp0_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('name', tmp0_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    if (this._get_disabled__5()) {
      attributeSetBuilder.add$default('disabled', null, 2, null);
    }var tmp1_safe_receiver = this._get_extraValue__1();
    if (tmp1_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('value', tmp1_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  CheckInput.prototype.afterInsert_30 = function (node) {
    this.refreshState_2();
  };
  CheckInput.prototype.refreshState_2 = function () {
    var tmp0_safe_receiver = this.getElementJQuery_31();
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.prop('checked');
    var v = (tmp == null ? true : typeof tmp === 'boolean') ? tmp : THROW_CCE();
    if (!(this._get_value__18() === v)) {
      var tmp1_safe_receiver = this.getElementJQuery_31();
      if (tmp1_safe_receiver == null)
        null;
      else
        tmp1_safe_receiver.prop('checked', this._get_value__18());
      Unit_getInstance();
    }};
  CheckInput.prototype.focus_32 = function () {
    var tmp0_safe_receiver = this.getElementJQuery_31();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.focus();
    Unit_getInstance();
  };
  CheckInput.prototype.subscribe_6 = function (observer) {
    var tmp0_this = this;
    var tmp0_plusAssign_0 = tmp0_this._observers;
    tmp0_plusAssign_0.add_57(observer);
    Unit_getInstance();
    observer(this._get_value__18());
    return _no_name_provided_$factory_150(this, observer);
  };
  CheckInput.prototype.subscribe_21 = function (observer) {
    return this.subscribe_6(observer);
  };
  CheckInput.$metadata$ = {
    simpleName: 'CheckInput',
    kind: 'class',
    interfaces: [FormInput, MutableState]
  };
  var CheckInputType_CHECKBOX_instance;
  var CheckInputType_RADIO_instance;
  var CheckInputType_entriesInitialized;
  function CheckInputType_initEntries() {
    if (CheckInputType_entriesInitialized)
      return Unit_getInstance();
    CheckInputType_entriesInitialized = true;
    CheckInputType_CHECKBOX_instance = new CheckInputType('CHECKBOX', 0, 'checkbox');
    CheckInputType_RADIO_instance = new CheckInputType('RADIO', 1, 'radio');
  }
  function CheckInputType(name, ordinal, type) {
    Enum.call(this, name, ordinal);
    this._type = type;
  }
  CheckInputType.$metadata$ = {
    simpleName: 'CheckInputType',
    kind: 'class',
    interfaces: []
  };
  function CheckInputType_RADIO_getInstance() {
    CheckInputType_initEntries();
    return CheckInputType_RADIO_instance;
  }
  function value$factory() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__10(value);
    });
  }
  function startValue$factory() {
    return getPropertyCallableRef('startValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_startValue__3();
    }, function (receiver_0, value) {
      return receiver_0._set_startValue__0(value);
    });
  }
  function type$factory() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__2(value);
    });
  }
  function name$factory() {
    return getPropertyCallableRef('name', 1, KMutableProperty1, function (receiver) {
      return receiver._get_name__47();
    }, function (receiver_0, value) {
      return receiver_0._set_name__6(value);
    });
  }
  function disabled$factory() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function extraValue$factory() {
    return getPropertyCallableRef('extraValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_extraValue__1();
    }, function (receiver_0, value) {
      return receiver_0._set_extraValue__0(value);
    });
  }
  function size$factory() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__6(value);
    });
  }
  function validationStatus$factory() {
    return getPropertyCallableRef('validationStatus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_validationStatus__3();
    }, function (receiver_0, value) {
      return receiver_0._set_validationStatus__10(value);
    });
  }
  function value$factory_0() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__10(value);
    });
  }
  function value$factory_1() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__10(value);
    });
  }
  function startValue$factory_0() {
    return getPropertyCallableRef('startValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_startValue__3();
    }, function (receiver_0, value) {
      return receiver_0._set_startValue__0(value);
    });
  }
  function startValue$factory_1() {
    return getPropertyCallableRef('startValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_startValue__3();
    }, function (receiver_0, value) {
      return receiver_0._set_startValue__0(value);
    });
  }
  function type$factory_0() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__2(value);
    });
  }
  function type$factory_1() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__2(value);
    });
  }
  function name$factory_0() {
    return getPropertyCallableRef('name', 1, KMutableProperty1, function (receiver) {
      return receiver._get_name__47();
    }, function (receiver_0, value) {
      return receiver_0._set_name__6(value);
    });
  }
  function name$factory_1() {
    return getPropertyCallableRef('name', 1, KMutableProperty1, function (receiver) {
      return receiver._get_name__47();
    }, function (receiver_0, value) {
      return receiver_0._set_name__6(value);
    });
  }
  function disabled$factory_0() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function disabled$factory_1() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function extraValue$factory_0() {
    return getPropertyCallableRef('extraValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_extraValue__1();
    }, function (receiver_0, value) {
      return receiver_0._set_extraValue__0(value);
    });
  }
  function extraValue$factory_1() {
    return getPropertyCallableRef('extraValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_extraValue__1();
    }, function (receiver_0, value) {
      return receiver_0._set_extraValue__0(value);
    });
  }
  function size$factory_0() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__6(value);
    });
  }
  function size$factory_1() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__6(value);
    });
  }
  function validationStatus$factory_0() {
    return getPropertyCallableRef('validationStatus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_validationStatus__3();
    }, function (receiver_0, value) {
      return receiver_0._set_validationStatus__10(value);
    });
  }
  function validationStatus$factory_1() {
    return getPropertyCallableRef('validationStatus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_validationStatus__3();
    }, function (receiver_0, value) {
      return receiver_0._set_validationStatus__10(value);
    });
  }
  function _no_name_provided_$factory_141(this$0) {
    var i = new _no_name_provided__152(this$0);
    return function (p1) {
      i.invoke_282(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_142(this$0) {
    var i = new _no_name_provided__153(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_143(this$0) {
    var i = new _no_name_provided__154(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_144(this$0) {
    var i = new _no_name_provided__155(this$0);
    return function (p1) {
      i.invoke_288(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_145(this$0) {
    var i = new _no_name_provided__156(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_146(this$0) {
    var i = new _no_name_provided__157(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_147(this$0) {
    var i = new _no_name_provided__158(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_148(this$0) {
    var i = new _no_name_provided__159(this$0);
    return function (p1) {
      i.invoke_478(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_149(this$0) {
    var i = new _no_name_provided__160(this$0);
    return function (p1) {
      i.invoke_480(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_150(this$0, $observer) {
    var i = new _no_name_provided__161(this$0, $observer);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_151(this$0, $this_setInternalEventListener) {
    var i = new _no_name_provided__150(this$0, $this_setInternalEventListener);
    return function (p1) {
      i.invoke_992(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_152(this$0, $this_setInternalEventListener) {
    var i = new _no_name_provided__151(this$0, $this_setInternalEventListener);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__162(this$0, $this_setEventListener) {
    this._this$0_116 = this$0;
    this._$this_setEventListener = $this_setEventListener;
  }
  _no_name_provided__162.prototype.invoke_795 = function (ev) {
    var tmp0_safe_receiver_4_8_12 = this._$this_setEventListener.self._get_extraValue__1();
    var tmp;
    if (tmp0_safe_receiver_4_8_12 == null) {
      tmp = null;
    } else {
      tmp = this._this$0_116._fromStr(tmp0_safe_receiver_4_8_12);
    }
    this._this$0_116._set_value__0(tmp);
    ev.stopPropagation();
  };
  _no_name_provided__162.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__162.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__163(this$0, $this_setEventListener) {
    this._this$0_117 = this$0;
    this._$this_setEventListener_0 = $this_setEventListener;
  }
  _no_name_provided__163.prototype.invoke_795 = function (ev) {
    var tmp0_safe_receiver = this._$this_setEventListener_0.self._get_extraValue__1();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = this._this$0_117._fromStr(tmp0_safe_receiver);
    }
    this._this$0_117._set_value__0(tmp);
    ev.stopPropagation();
  };
  _no_name_provided__163.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__163.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function setValueToChildren($this, value) {
    var tmp0_filterIsInstance_0 = $this._container.getChildren_23();
    var tmp0_filterIsInstanceTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filterIsInstance_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      if (element_2_3 instanceof Radio) {
        tmp0_filterIsInstanceTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var radios = tmp0_filterIsInstanceTo_0_1;
    var tmp0_iterator_1 = radios.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2._set_value__10(false);
    }
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_iterator_1_1 = radios.iterator_38();
      while (tmp0_iterator_1_1.hasNext_11()) {
        var element_2_2 = tmp0_iterator_1_1.next_13();
        var tmp = element_2_2._get_extraValue__1();
        var tmp0_safe_receiver_4 = value;
        var tmp_0;
        if (tmp0_safe_receiver_4 == null) {
          tmp_0 = null;
        } else {
          tmp_0 = $this._toStr(tmp0_safe_receiver_4);
        }
        if (tmp == tmp_0) {
          tmp$ret$0 = element_2_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    var tmp0_safe_receiver = tmp$ret$0;
    if (tmp0_safe_receiver == null) {
      Unit_getInstance();
    } else
      tmp0_safe_receiver._set_value__10(true);
  }
  function getNameFromChildren($this) {
    var tmp0_filterIsInstance_0 = $this._container.getChildren_23();
    var tmp0_filterIsInstanceTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filterIsInstance_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      if (element_2_3 instanceof Radio) {
        tmp0_filterIsInstanceTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var tmp0_safe_receiver = firstOrNull(tmp0_filterIsInstanceTo_0_1);
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_name__47();
    return tmp1_elvis_lhs == null ? $this._idc : tmp1_elvis_lhs;
  }
  function setNameToChildren($this, name) {
    var tmp0_elvis_lhs = name;
    var tname = tmp0_elvis_lhs == null ? $this._idc : tmp0_elvis_lhs;
    var tmp0_filterIsInstance_0 = $this._container.getChildren_23();
    var tmp0_filterIsInstanceTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filterIsInstance_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      if (element_2_3 instanceof Radio) {
        tmp0_filterIsInstanceTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var tmp1_forEach_0 = tmp0_filterIsInstanceTo_0_1;
    var tmp0_iterator_1 = tmp1_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2._set_name__6(tname);
    }
  }
  function setValidationStatusToChildren($this, validationStatus) {
    var tmp0_filterIsInstance_0 = $this._container.getChildren_23();
    var tmp0_filterIsInstanceTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filterIsInstance_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      if (element_2_3 instanceof Radio) {
        tmp0_filterIsInstanceTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var tmp1_forEach_0 = tmp0_filterIsInstanceTo_0_1;
    var tmp0_iterator_1 = tmp1_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2._set_validationStatus__10(validationStatus);
    }
  }
  function setChildrenFromOptions($this) {
    var currentName = $this._get_name__47();
    $this._container.removeAll_27();
    Unit_getInstance();
    var tmp0_safe_receiver = $this._get_options_();
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp0_elvis_lhs_3 = currentName;
      var tname_2 = tmp0_elvis_lhs_3 == null ? $this._idc : tmp0_elvis_lhs_3;
      var tinline_4 = $this._get_inline__0();
      var tmp0_mapTo_0_1_6 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_safe_receiver, 10));
      var tmp0_iterator_1_2_7 = tmp0_safe_receiver.iterator_38();
      while (tmp0_iterator_1_2_7.hasNext_11()) {
        var item_2_3_8 = tmp0_iterator_1_2_7.next_13();
        var tmp = $this._toStr(item_2_3_8._first);
        var tmp0_apply_0_5_9 = Radio_init_$Create$(false, tmp, null, item_2_3_8._second, false, null, 52, null);
        tmp0_apply_0_5_9._set_inline__0(tinline_4);
        tmp0_apply_0_5_9._set_name__6(tname_2);
        tmp0_apply_0_5_9._set_eventTarget__3($this);
        tmp0_apply_0_5_9.setEventListener_30(_no_name_provided_$factory_157($this));
        Unit_getInstance();
        tmp0_mapTo_0_1_6.add_57(tmp0_apply_0_5_9);
        Unit_getInstance();
      }
      var c_5 = tmp0_mapTo_0_1_6;
      $this._container.addAll_42(c_5);
    }
    Unit_getInstance();
  }
  function Companion_14() {
    Companion_instance_13 = this;
    this._counter_2 = 0;
  }
  Companion_14.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_13;
  function Companion_getInstance_13() {
    if (Companion_instance_13 == null)
      new Companion_14();
    return Companion_instance_13;
  }
  function _no_name_provided__164(this$0) {
    this._this$0_118 = this$0;
  }
  _no_name_provided__164.prototype.invoke_306 = function (it) {
    setChildrenFromOptions(this._this$0_118);
  };
  _no_name_provided__164.prototype.invoke_1082 = function (p1) {
    this.invoke_306((p1 == null ? true : isInterface(p1, List)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__164.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__165(this$0) {
    this._this$0_119 = this$0;
  }
  _no_name_provided__165.prototype.invoke_308 = function (it) {
    setValueToChildren(this._this$0_119, it);
    var tmp0_forEach_0 = this._this$0_119._observers_0;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(it);
    }
    var tmp1_apply_0 = new Object();
    var tmp0_apply_0_2 = new Object();
    tmp0_apply_0_2.data = it;
    tmp1_apply_0.detail = tmp0_apply_0_2;
    this._this$0_119.dispatchEvent_4('change', tmp1_apply_0);
    Unit_getInstance();
  };
  _no_name_provided__165.prototype.invoke_1082 = function (p1) {
    this.invoke_308((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__165.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__166(this$0) {
    this._this$0_120 = this$0;
  }
  _no_name_provided__166.prototype.invoke_771 = function (it) {
    this._this$0_120.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__166.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__166.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__167(this$0) {
    this._this$0_121 = this$0;
  }
  _no_name_provided__167.prototype.invoke_312 = function ($this$$receiver) {
    $this$$receiver._set_id__30(this._this$0_121._idc);
  };
  _no_name_provided__167.prototype.invoke_1082 = function (p1) {
    this.invoke_312(p1 instanceof SimplePanel ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__167.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__168(this$0) {
    this._this$0_122 = this$0;
  }
  _no_name_provided__168.prototype.invoke_316 = function ($this$setEventListener) {
    $this$setEventListener.change = _no_name_provided_$factory_160(this._this$0_122, $this$setEventListener);
  };
  _no_name_provided__168.prototype.invoke_1082 = function (p1) {
    this.invoke_316((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__168.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__169(this$0) {
    this._this$0_123 = this$0;
  }
  _no_name_provided__169.prototype.invoke_316 = function ($this$setEventListener) {
    $this$setEventListener.change = _no_name_provided_$factory_161(this._this$0_123, $this$setEventListener);
  };
  _no_name_provided__169.prototype.invoke_1082 = function (p1) {
    this.invoke_316((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__169.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__170(this$0, $observer) {
    this._this$0_124 = this$0;
    this._$observer_0 = $observer;
  }
  _no_name_provided__170.prototype.invoke_1037 = function () {
    var tmp0_this = this._this$0_124;
    var tmp0_minusAssign_0 = tmp0_this._observers_0;
    tmp0_minusAssign_0.remove_59(this._$observer_0);
    Unit_getInstance();
  };
  _no_name_provided__170.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__170.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function GenericRadioGroup(options, value, name, inline, label, rich, toStr, fromStr, init) {
    Companion_getInstance_13();
    var tmp = setOf('form-group');
    SimplePanel_init_$Init$(tmp, null, 2, null, this);
    this._toStr = toStr;
    this._fromStr = fromStr;
    var tmp_0 = this;
    tmp_0._observers_0 = ArrayList_init_$Create$();
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_153(this);
    tmp_1._options$delegate = (new RefreshDelegateProvider_0(this, options, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, options$factory());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_154(this);
    tmp_2._value$delegate_0 = (new RefreshDelegateProvider_0(this, value, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, value$factory_2());
    var tmp_3 = this;
    var tmp0_refreshOnUpdate_0_1 = _no_name_provided_$factory_155(this);
    tmp_3._inline$delegate = (new RefreshDelegateProvider_0(this, inline, tmp0_refreshOnUpdate_0_1)).provideDelegate_0(this, inline$factory());
    this._idc = '' + 'kv_form_radiogroup_' + Companion_getInstance_13()._counter_2;
    var tmp_4 = this;
    tmp_4._input_0 = RadioInput_init_$Create$(false, null, null, 7, null);
    var tmp_5 = this;
    var tmp_6 = setOf('control-label');
    tmp_5._flabel = FieldLabel_init_$Create$(this._idc, label, rich, tmp_6, null, 16, null);
    var tmp_7 = this;
    var tmp0_apply_0 = InvalidFeedback_init_$Create$(null, false, 3, null);
    tmp0_apply_0._set_visible__31(false);
    tmp_7._invalidFeedback = tmp0_apply_0;
    var tmp_8 = this;
    var tmp_9 = setOf('kv-radiogroup-container');
    tmp_8._container = new SimplePanel(tmp_9, _no_name_provided_$factory_156(this));
    this.addPrivate_6(this._flabel);
    Unit_getInstance();
    this.addPrivate_6(this._container);
    Unit_getInstance();
    this.addPrivate_6(this._invalidFeedback);
    Unit_getInstance();
    setChildrenFromOptions(this);
    setValueToChildren(this, value);
    setNameToChildren(this, name);
    var tmp0_this = Companion_getInstance_13();
    var tmp1 = tmp0_this._counter_2;
    tmp0_this._counter_2 = tmp1 + 1 | 0;
    Unit_getInstance();
    var tmp2_safe_receiver = init;
    if (tmp2_safe_receiver == null)
      null;
    else
      tmp2_safe_receiver(this);
    Unit_getInstance();
  }
  GenericRadioGroup.prototype._set_options_ = function (_set___) {
    return this._options$delegate.setValue_6(this, options$factory_0(), _set___);
  };
  GenericRadioGroup.prototype._get_options_ = function () {
    return this._options$delegate.getValue_3(this, options$factory_1());
  };
  GenericRadioGroup.prototype._set_value__0 = function (_set___) {
    return this._value$delegate_0.setValue_6(this, value$factory_3(), _set___);
  };
  GenericRadioGroup.prototype._get_value__18 = function () {
    return this._value$delegate_0.getValue_3(this, value$factory_4());
  };
  GenericRadioGroup.prototype._set_inline__0 = function (_set___) {
    return this._inline$delegate.setValue_6(this, inline$factory_0(), _set___);
  };
  GenericRadioGroup.prototype._get_inline__0 = function () {
    return this._inline$delegate.getValue_3(this, inline$factory_1());
  };
  GenericRadioGroup.prototype._set_label__2 = function (value) {
    this._flabel._set_content__9(value);
  };
  GenericRadioGroup.prototype._get_name__47 = function () {
    return getNameFromChildren(this);
  };
  GenericRadioGroup.prototype._set_validationStatus__10 = function (value) {
    setValidationStatusToChildren(this, value);
  };
  GenericRadioGroup.prototype._set_validatorError__6 = function (value) {
    FormControl.prototype._set_validatorError__6.call(this, value);
    if (!(value == null)) {
      this._container.addCssClass_31('is-invalid');
      Unit_getInstance();
    } else {
      this._container.removeCssClass_31('is-invalid');
      Unit_getInstance();
    }
  };
  GenericRadioGroup.prototype._get_validatorError__6 = function () {
    return FormControl.prototype._get_validatorError__6.call(this);
  };
  GenericRadioGroup.prototype._get_input__4 = function () {
    return this._input_0;
  };
  GenericRadioGroup.prototype._get_invalidFeedback__4 = function () {
    return this._invalidFeedback;
  };
  GenericRadioGroup.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    if (!(this._get_validatorError__6() == null)) {
      classSetBuilder.add_27('text-danger');
    }if (this._get_inline__0()) {
      classSetBuilder.add_27('kv-radiogroup-inline');
    } else {
      classSetBuilder.add_27('kv-radiogroup');
    }
  };
  GenericRadioGroup.prototype.add_56 = function (child) {
    if (child instanceof Radio) {
      child._set_eventTarget__3(this);
      child._set_name__6(this._get_name__47());
      var tmp = child;
      tmp.setEventListener_30(_no_name_provided_$factory_158(this));
      Unit_getInstance();
    } else {
    }
    this._container.add_56(child);
    Unit_getInstance();
    return this;
  };
  GenericRadioGroup.prototype.addAll_42 = function (children) {
    var tmp0_iterator_1 = children.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      this.add_56(element_2);
      Unit_getInstance();
    }
    return this;
  };
  GenericRadioGroup.prototype.remove_57 = function (child) {
    this._container.remove_57(child);
    Unit_getInstance();
    return this;
  };
  GenericRadioGroup.prototype.removeAll_27 = function () {
    this._container.removeAll_27();
    Unit_getInstance();
    return this;
  };
  GenericRadioGroup.prototype.disposeAll_24 = function () {
    this._container.disposeAll_24();
    Unit_getInstance();
    return this;
  };
  GenericRadioGroup.prototype.getChildren_23 = function () {
    return this._container.getChildren_23();
  };
  GenericRadioGroup.prototype.focus_32 = function () {
    var tmp0_filterIsInstance_0 = this._container.getChildren_23();
    var tmp0_filterIsInstanceTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filterIsInstance_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      if (element_2_3 instanceof Radio) {
        tmp0_filterIsInstanceTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var tmp0_safe_receiver = firstOrNull(tmp0_filterIsInstanceTo_0_1);
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.focus_32();
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  GenericRadioGroup.prototype.subscribe_2 = function (observer) {
    var tmp0_this = this;
    var tmp0_plusAssign_0 = tmp0_this._observers_0;
    tmp0_plusAssign_0.add_57(observer);
    Unit_getInstance();
    observer(this._get_value__18());
    return _no_name_provided_$factory_159(this, observer);
  };
  GenericRadioGroup.prototype.subscribe_21 = function (observer) {
    return this.subscribe_2(observer);
  };
  GenericRadioGroup.$metadata$ = {
    simpleName: 'GenericRadioGroup',
    kind: 'class',
    interfaces: [GenericFormControl, MutableState]
  };
  function genericRadioGroup(_this_, options, value, name, inline, label, rich, toStr, fromStr, init) {
    var genericRadioGroup_0 = new GenericRadioGroup(options, value, name, inline, label, rich, toStr, fromStr, init);
    _this_.add_56(genericRadioGroup_0);
    Unit_getInstance();
    return genericRadioGroup_0;
  }
  function genericRadioGroup$default(_this_, options, value, name, inline, label, rich, toStr, fromStr, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      options = null;
    if (!(($mask0 & 2) === 0))
      value = null;
    if (!(($mask0 & 4) === 0))
      name = null;
    if (!(($mask0 & 8) === 0))
      inline = false;
    if (!(($mask0 & 16) === 0))
      label = null;
    if (!(($mask0 & 32) === 0))
      rich = false;
    if (!(($mask0 & 64) === 0)) {
      toStr = _no_name_provided_$factory_162();
    }if (!(($mask0 & 128) === 0)) {
      fromStr = _no_name_provided_$factory_163();
    }if (!(($mask0 & 256) === 0))
      init = null;
    return genericRadioGroup(_this_, options, value, name, inline, label, rich, toStr, fromStr, init);
  }
  function _no_name_provided__171() {
  }
  _no_name_provided__171.prototype.invoke_320 = function (it) {
    return toString_1(it);
  };
  _no_name_provided__171.prototype.invoke_1082 = function (p1) {
    return this.invoke_320((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__171.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__172() {
  }
  _no_name_provided__172.prototype.invoke_1057 = function (it) {
    return isObject(it) ? it : null;
  };
  _no_name_provided__172.prototype.invoke_1082 = function (p1) {
    return this.invoke_1057((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__172.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function options$factory() {
    return getPropertyCallableRef('options', 1, KMutableProperty1, function (receiver) {
      return receiver._get_options_();
    }, function (receiver_0, value) {
      return receiver_0._set_options_(value);
    });
  }
  function value$factory_2() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__0(value);
    });
  }
  function inline$factory() {
    return getPropertyCallableRef('inline', 1, KMutableProperty1, function (receiver) {
      return receiver._get_inline__0();
    }, function (receiver_0, value) {
      return receiver_0._set_inline__0(value);
    });
  }
  function options$factory_0() {
    return getPropertyCallableRef('options', 1, KMutableProperty1, function (receiver) {
      return receiver._get_options_();
    }, function (receiver_0, value) {
      return receiver_0._set_options_(value);
    });
  }
  function options$factory_1() {
    return getPropertyCallableRef('options', 1, KMutableProperty1, function (receiver) {
      return receiver._get_options_();
    }, function (receiver_0, value) {
      return receiver_0._set_options_(value);
    });
  }
  function value$factory_3() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__0(value);
    });
  }
  function value$factory_4() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__0(value);
    });
  }
  function inline$factory_0() {
    return getPropertyCallableRef('inline', 1, KMutableProperty1, function (receiver) {
      return receiver._get_inline__0();
    }, function (receiver_0, value) {
      return receiver_0._set_inline__0(value);
    });
  }
  function inline$factory_1() {
    return getPropertyCallableRef('inline', 1, KMutableProperty1, function (receiver) {
      return receiver._get_inline__0();
    }, function (receiver_0, value) {
      return receiver_0._set_inline__0(value);
    });
  }
  function _no_name_provided_$factory_153(this$0) {
    var i = new _no_name_provided__164(this$0);
    return function (p1) {
      i.invoke_306(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_154(this$0) {
    var i = new _no_name_provided__165(this$0);
    return function (p1) {
      i.invoke_308(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_155(this$0) {
    var i = new _no_name_provided__166(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_156(this$0) {
    var i = new _no_name_provided__167(this$0);
    return function (p1) {
      i.invoke_312(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_157(this$0) {
    var i = new _no_name_provided__168(this$0);
    return function (p1) {
      i.invoke_316(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_158(this$0) {
    var i = new _no_name_provided__169(this$0);
    return function (p1) {
      i.invoke_316(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_159(this$0, $observer) {
    var i = new _no_name_provided__170(this$0, $observer);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_160(this$0, $this_setEventListener) {
    var i = new _no_name_provided__162(this$0, $this_setEventListener);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_161(this$0, $this_setEventListener) {
    var i = new _no_name_provided__163(this$0, $this_setEventListener);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_162() {
    var i = new _no_name_provided__171();
    return function (p1) {
      return i.invoke_320(p1);
    };
  }
  function _no_name_provided_$factory_163() {
    var i = new _no_name_provided__172();
    return function (p1) {
      return i.invoke_1057(p1);
    };
  }
  function Radio_init_$Init$(value, extraValue, name, label, rich, init, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      value = false;
    if (!(($mask0 & 2) === 0))
      extraValue = null;
    if (!(($mask0 & 4) === 0))
      name = null;
    if (!(($mask0 & 8) === 0))
      label = null;
    if (!(($mask0 & 16) === 0))
      rich = false;
    if (!(($mask0 & 32) === 0))
      init = null;
    Radio.call($this, value, extraValue, name, label, rich, init);
    return $this;
  }
  function Radio_init_$Create$(value, extraValue, name, label, rich, init, $mask0, $marker) {
    return Radio_init_$Init$(value, extraValue, name, label, rich, init, $mask0, $marker, Object.create(Radio.prototype));
  }
  function Companion_15() {
    Companion_instance_14 = this;
    this._counter_3 = 0;
  }
  Companion_15.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_14;
  function Companion_getInstance_14() {
    if (Companion_instance_14 == null)
      new Companion_15();
    return Companion_instance_14;
  }
  function _no_name_provided__173(this$0) {
    this._this$0_125 = this$0;
  }
  _no_name_provided__173.prototype.invoke_324 = function (it) {
    this._this$0_125.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__173.prototype.invoke_1082 = function (p1) {
    this.invoke_324((p1 == null ? true : p1 instanceof RadioStyle) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__173.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__174(this$0) {
    this._this$0_126 = this$0;
  }
  _no_name_provided__174.prototype.invoke_771 = function (it) {
    this._this$0_126.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__174.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__174.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__175(this$0) {
    this._this$0_127 = this$0;
  }
  _no_name_provided__175.prototype.invoke_771 = function (it) {
    this._this$0_127.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__175.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__175.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Radio(value, extraValue, name, label, rich, init) {
    Companion_getInstance_14();
    var tmp = setOf('form-check');
    SimplePanel_init_$Init$(tmp, null, 2, null, this);
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_164(this);
    tmp_0._style$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, style$factory());
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0_0 = false;
    var tmp1_refreshOnUpdate_0 = _no_name_provided_$factory_165(this);
    tmp_1._squared$delegate = (new RefreshDelegateProvider_0(this, tmp0_refreshOnUpdate_0_0, tmp1_refreshOnUpdate_0)).provideDelegate_0(this, squared$factory());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_1 = false;
    var tmp1_refreshOnUpdate_0_0 = _no_name_provided_$factory_166(this);
    tmp_2._inline$delegate_0 = (new RefreshDelegateProvider_0(this, tmp0_refreshOnUpdate_0_1, tmp1_refreshOnUpdate_0_0)).provideDelegate_0(this, inline$factory_2());
    this._idc_0 = '' + 'kv_form_radio_' + Companion_getInstance_14()._counter_3;
    var tmp_3 = this;
    var tmp_4 = setOf('form-check-input');
    var tmp0_apply_0 = RadioInput_init_$Create$(value, tmp_4, null, 4, null);
    tmp0_apply_0._set_id__30(this._idc_0);
    tmp0_apply_0._set_extraValue__0(extraValue);
    tmp0_apply_0._set_name__6(name);
    tmp_3._input_1 = tmp0_apply_0;
    var tmp_5 = this;
    var tmp_6 = setOf('form-check-label');
    tmp_5._flabel_0 = FieldLabel_init_$Create$(this._idc_0, label, rich, tmp_6, null, 16, null);
    var tmp_7 = this;
    var tmp0_apply_0_0 = InvalidFeedback_init_$Create$(null, false, 3, null);
    tmp0_apply_0_0._set_visible__31(false);
    tmp_7._invalidFeedback_0 = tmp0_apply_0_0;
    var tmp0_elvis_lhs = this._get_eventTarget__0();
    this._input_1._set_eventTarget__3(tmp0_elvis_lhs == null ? this : tmp0_elvis_lhs);
    this.addPrivate_6(this._input_1);
    Unit_getInstance();
    this.addPrivate_6(this._flabel_0);
    Unit_getInstance();
    this.addPrivate_6(this._invalidFeedback_0);
    Unit_getInstance();
    var tmp1_this = Companion_getInstance_14();
    var tmp2 = tmp1_this._counter_3;
    tmp1_this._counter_3 = tmp2 + 1 | 0;
    Unit_getInstance();
    var tmp3_safe_receiver = init;
    if (tmp3_safe_receiver == null)
      null;
    else
      tmp3_safe_receiver(this);
    Unit_getInstance();
  }
  Radio.prototype._set_value__10 = function (value) {
    this._input_1._set_value__10(value);
  };
  Radio.prototype._get_extraValue__1 = function () {
    return this._input_1._get_extraValue__1();
  };
  Radio.prototype._set_style_ = function (_set___) {
    return this._style$delegate.setValue_6(this, style$factory_0(), _set___);
  };
  Radio.prototype._get_style__2 = function () {
    return this._style$delegate.getValue_3(this, style$factory_1());
  };
  Radio.prototype._set_squared_ = function (_set___) {
    return this._squared$delegate.setValue_6(this, squared$factory_0(), _set___);
  };
  Radio.prototype._get_squared_ = function () {
    return this._squared$delegate.getValue_3(this, squared$factory_1());
  };
  Radio.prototype._set_inline__0 = function (_set___) {
    return this._inline$delegate_0.setValue_6(this, inline$factory_3(), _set___);
  };
  Radio.prototype._get_inline__0 = function () {
    return this._inline$delegate_0.getValue_3(this, inline$factory_4());
  };
  Radio.prototype._get_input__4 = function () {
    return this._input_1;
  };
  Radio.prototype._get_invalidFeedback__4 = function () {
    return this._invalidFeedback_0;
  };
  Radio.prototype.setEventListener_30 = function (block) {
    return this._input_1.setEventListener_30(block);
  };
  Radio.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    if (!this._get_squared_()) {
      classSetBuilder.add_27('abc-radio');
      classSetBuilder.add_28(this._get_style__2());
    } else {
      classSetBuilder.add_27('abc-checkbox');
      var tmp0_safe_receiver = this._get_style__2();
      if (tmp0_safe_receiver == null)
        null;
      else {
        classSetBuilder.add_27(replace$default(tmp0_safe_receiver._className_0, 'radio', 'checkbox', false, 4, null));
        Unit_getInstance();
      }
      Unit_getInstance();
    }
    if (this._get_inline__0()) {
      classSetBuilder.add_27('form-check-inline');
    }if (!(this._get_validatorError__6() == null)) {
      classSetBuilder.add_27('text-danger');
    }};
  Radio.prototype.focus_32 = function () {
    this._input_1.focus_32();
  };
  Radio.prototype.subscribe_6 = function (observer) {
    return this._input_1.subscribe_6(observer);
  };
  Radio.prototype.subscribe_21 = function (observer) {
    return this.subscribe_6(observer);
  };
  Radio.$metadata$ = {
    simpleName: 'Radio',
    kind: 'class',
    interfaces: [BoolFormControl, MutableState]
  };
  function RadioStyle() {
  }
  RadioStyle.$metadata$ = {
    simpleName: 'RadioStyle',
    kind: 'class',
    interfaces: [CssClass]
  };
  function style$factory() {
    return getPropertyCallableRef('style', 1, KMutableProperty1, function (receiver) {
      return receiver._get_style__2();
    }, function (receiver_0, value) {
      return receiver_0._set_style_(value);
    });
  }
  function squared$factory() {
    return getPropertyCallableRef('squared', 1, KMutableProperty1, function (receiver) {
      return receiver._get_squared_();
    }, function (receiver_0, value) {
      return receiver_0._set_squared_(value);
    });
  }
  function inline$factory_2() {
    return getPropertyCallableRef('inline', 1, KMutableProperty1, function (receiver) {
      return receiver._get_inline__0();
    }, function (receiver_0, value) {
      return receiver_0._set_inline__0(value);
    });
  }
  function style$factory_0() {
    return getPropertyCallableRef('style', 1, KMutableProperty1, function (receiver) {
      return receiver._get_style__2();
    }, function (receiver_0, value) {
      return receiver_0._set_style_(value);
    });
  }
  function style$factory_1() {
    return getPropertyCallableRef('style', 1, KMutableProperty1, function (receiver) {
      return receiver._get_style__2();
    }, function (receiver_0, value) {
      return receiver_0._set_style_(value);
    });
  }
  function squared$factory_0() {
    return getPropertyCallableRef('squared', 1, KMutableProperty1, function (receiver) {
      return receiver._get_squared_();
    }, function (receiver_0, value) {
      return receiver_0._set_squared_(value);
    });
  }
  function squared$factory_1() {
    return getPropertyCallableRef('squared', 1, KMutableProperty1, function (receiver) {
      return receiver._get_squared_();
    }, function (receiver_0, value) {
      return receiver_0._set_squared_(value);
    });
  }
  function inline$factory_3() {
    return getPropertyCallableRef('inline', 1, KMutableProperty1, function (receiver) {
      return receiver._get_inline__0();
    }, function (receiver_0, value) {
      return receiver_0._set_inline__0(value);
    });
  }
  function inline$factory_4() {
    return getPropertyCallableRef('inline', 1, KMutableProperty1, function (receiver) {
      return receiver._get_inline__0();
    }, function (receiver_0, value) {
      return receiver_0._set_inline__0(value);
    });
  }
  function _no_name_provided_$factory_164(this$0) {
    var i = new _no_name_provided__173(this$0);
    return function (p1) {
      i.invoke_324(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_165(this$0) {
    var i = new _no_name_provided__174(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_166(this$0) {
    var i = new _no_name_provided__175(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function RadioInput_init_$Init$(value, classes, init, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      value = false;
    if (!(($mask0 & 2) === 0)) {
      classes = emptySet();
    }if (!(($mask0 & 4) === 0))
      init = null;
    RadioInput.call($this, value, classes, init);
    return $this;
  }
  function RadioInput_init_$Create$(value, classes, init, $mask0, $marker) {
    return RadioInput_init_$Init$(value, classes, init, $mask0, $marker, Object.create(RadioInput.prototype));
  }
  function RadioInput(value, classes, init) {
    CheckInput.call(this, CheckInputType_RADIO_getInstance(), value, classes);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  RadioInput.$metadata$ = {
    simpleName: 'RadioInput',
    kind: 'class',
    interfaces: []
  };
  function AbstractText_init_$Init$(label, rich, classes, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      label = null;
    if (!(($mask0 & 2) === 0))
      rich = false;
    if (!(($mask0 & 4) === 0)) {
      classes = emptySet();
    }AbstractText.call($this, label, rich, classes);
    return $this;
  }
  function Companion_16() {
    Companion_instance_15 = this;
    this._counter_4 = 0;
  }
  Companion_16.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_15;
  function Companion_getInstance_15() {
    if (Companion_instance_15 == null)
      new Companion_16();
    return Companion_instance_15;
  }
  function AbstractText(label, rich, classes) {
    Companion_getInstance_15();
    var tmp = plus_1(classes, 'form-group');
    SimplePanel_init_$Init$(tmp, null, 2, null, this);
    this._idc_1 = '' + 'kv_form_text_' + Companion_getInstance_15()._counter_4;
    var tmp_0 = this;
    var tmp_1 = setOf('control-label');
    var tmp0_apply_0 = FieldLabel_init_$Create$(this._idc_1, label, rich, tmp_1, null, 16, null);
    tmp0_apply_0._set_visible__31(!(label == null));
    tmp_0._flabel_1 = tmp0_apply_0;
    var tmp_2 = this;
    var tmp0_apply_0_0 = InvalidFeedback_init_$Create$(null, false, 3, null);
    tmp0_apply_0_0._set_visible__31(false);
    tmp_2._invalidFeedback_1 = tmp0_apply_0_0;
    this.addPrivate_6(this._flabel_1);
    Unit_getInstance();
    var tmp0_this = Companion_getInstance_15();
    var tmp1 = tmp0_this._counter_4;
    tmp0_this._counter_4 = tmp1 + 1 | 0;
    Unit_getInstance();
  }
  AbstractText.prototype._set_value__6 = function (value) {
    this._get_input__4()._set_value__6(value);
  };
  AbstractText.prototype._set_label__2 = function (value) {
    this._flabel_1._set_content__9(value);
    this._flabel_1._set_visible__31(!(value == null));
  };
  AbstractText.prototype._get_idc__0 = function () {
    return this._idc_1;
  };
  AbstractText.prototype._get_invalidFeedback__4 = function () {
    return this._invalidFeedback_1;
  };
  AbstractText.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    if (!(this._get_validatorError__6() == null)) {
      classSetBuilder.add_27('text-danger');
    }};
  AbstractText.prototype.setEventListener_30 = function (block) {
    return this._get_input__4().setEventListener_30(block);
  };
  AbstractText.prototype.focus_32 = function () {
    this._get_input__4().focus_32();
  };
  AbstractText.prototype.subscribe_12 = function (observer) {
    return this._get_input__4().subscribe_12(observer);
  };
  AbstractText.prototype.subscribe_21 = function (observer) {
    return this.subscribe_12(observer);
  };
  AbstractText.$metadata$ = {
    simpleName: 'AbstractText',
    kind: 'class',
    interfaces: [StringFormControl, MutableState]
  };
  function _no_name_provided__176($this_setInternalEventListener) {
    this._$this_setInternalEventListener_1 = $this_setInternalEventListener;
  }
  _no_name_provided__176.prototype.invoke_795 = function (it) {
    this._$this_setInternalEventListener_1.self.changeValue_1();
  };
  _no_name_provided__176.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__176.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__177() {
  }
  _no_name_provided__177.prototype.invoke_332 = function ($this$setInternalEventListener) {
    $this$setInternalEventListener.input = _no_name_provided_$factory_179($this$setInternalEventListener);
  };
  _no_name_provided__177.prototype.invoke_1082 = function (p1) {
    this.invoke_332((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__177.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__178(this$0) {
    this._this$0_128 = this$0;
  }
  _no_name_provided__178.prototype.invoke_1079 = function (it) {
    this._this$0_128.refreshState_2();
    var tmp0_forEach_0 = this._this$0_128._observers_1;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(it);
    }
  };
  _no_name_provided__178.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__178.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__179(this$0) {
    this._this$0_129 = this$0;
  }
  _no_name_provided__179.prototype.invoke_1079 = function (it) {
    this._this$0_129._set_value__6(it);
    this._this$0_129.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__179.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__179.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__180(this$0) {
    this._this$0_130 = this$0;
  }
  _no_name_provided__180.prototype.invoke_1079 = function (it) {
    this._this$0_130.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__180.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__180.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__181(this$0) {
    this._this$0_131 = this$0;
  }
  _no_name_provided__181.prototype.invoke_1079 = function (it) {
    this._this$0_131.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__181.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__181.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__182(this$0) {
    this._this$0_132 = this$0;
  }
  _no_name_provided__182.prototype.invoke_396 = function (it) {
    this._this$0_132.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__182.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__182.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__183(this$0) {
    this._this$0_133 = this$0;
  }
  _no_name_provided__183.prototype.invoke_771 = function (it) {
    this._this$0_133.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__183.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__183.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__184(this$0) {
    this._this$0_134 = this$0;
  }
  _no_name_provided__184.prototype.invoke_476 = function (it) {
    this._this$0_134.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__184.prototype.invoke_1082 = function (p1) {
    this.invoke_476((p1 == null ? true : typeof p1 === 'boolean') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__184.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__185(this$0) {
    this._this$0_135 = this$0;
  }
  _no_name_provided__185.prototype.invoke_476 = function (it) {
    this._this$0_135.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__185.prototype.invoke_1082 = function (p1) {
    this.invoke_476((p1 == null ? true : typeof p1 === 'boolean') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__185.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__186(this$0) {
    this._this$0_136 = this$0;
  }
  _no_name_provided__186.prototype.invoke_478 = function (it) {
    this._this$0_136.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__186.prototype.invoke_1082 = function (p1) {
    this.invoke_478((p1 == null ? true : p1 instanceof InputSize) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__186.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__187(this$0) {
    this._this$0_137 = this$0;
  }
  _no_name_provided__187.prototype.invoke_480 = function (it) {
    this._this$0_137.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__187.prototype.invoke_1082 = function (p1) {
    this.invoke_480((p1 == null ? true : p1 instanceof ValidationStatus) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__187.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__188(this$0, $observer) {
    this._this$0_138 = this$0;
    this._$observer_1 = $observer;
  }
  _no_name_provided__188.prototype.invoke_1037 = function () {
    var tmp0_this = this._this$0_138;
    var tmp0_minusAssign_0 = tmp0_this._observers_1;
    tmp0_minusAssign_0.remove_59(this._$observer_1);
    Unit_getInstance();
  };
  _no_name_provided__188.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__188.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractTextInput(value, classes) {
    Widget_init_$Init$(classes, null, 2, null, this);
    var tmp = this;
    tmp._observers_1 = ArrayList_init_$Create$();
    this.setInternalEventListener_4(_no_name_provided_$factory_167());
    Unit_getInstance();
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_168(this);
    tmp_0._value$delegate_1 = (new RefreshDelegateProvider_0(this, value, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, value$factory_5());
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_169(this);
    tmp_1._startValue$delegate_0 = (new RefreshDelegateProvider_0(this, value, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, startValue$factory_2());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_1 = _no_name_provided_$factory_170(this);
    tmp_2._placeholder$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_1)).provideDelegate_0(this, placeholder$factory());
    var tmp_3 = this;
    var tmp0_refreshOnUpdate_0_2 = _no_name_provided_$factory_171(this);
    tmp_3._name$delegate_0 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_2)).provideDelegate_0(this, name$factory_2());
    var tmp_4 = this;
    var tmp0_refreshOnUpdate_0_3 = _no_name_provided_$factory_172(this);
    tmp_4._maxlength$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_3)).provideDelegate_0(this, maxlength$factory());
    var tmp_5 = this;
    var tmp0_refreshOnUpdate_0_4 = false;
    var tmp1_refreshOnUpdate_0 = _no_name_provided_$factory_173(this);
    tmp_5._disabled$delegate_0 = (new RefreshDelegateProvider_0(this, tmp0_refreshOnUpdate_0_4, tmp1_refreshOnUpdate_0)).provideDelegate_0(this, disabled$factory_2());
    var tmp_6 = this;
    var tmp0_refreshOnUpdate_0_5 = _no_name_provided_$factory_174(this);
    tmp_6._autofocus$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_5)).provideDelegate_0(this, autofocus$factory());
    var tmp_7 = this;
    var tmp0_refreshOnUpdate_0_6 = _no_name_provided_$factory_175(this);
    tmp_7._readonly$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_6)).provideDelegate_0(this, readonly$factory());
    var tmp_8 = this;
    var tmp0_refreshOnUpdate_0_7 = _no_name_provided_$factory_176(this);
    tmp_8._size$delegate_0 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_7)).provideDelegate_0(this, size$factory_2());
    var tmp_9 = this;
    var tmp0_refreshOnUpdate_0_8 = _no_name_provided_$factory_177(this);
    tmp_9._validationStatus$delegate_0 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_8)).provideDelegate_0(this, validationStatus$factory_2());
  }
  AbstractTextInput.prototype._set_value__6 = function (_set___) {
    return this._value$delegate_1.setValue_6(this, value$factory_6(), _set___);
  };
  AbstractTextInput.prototype._get_value__18 = function () {
    return this._value$delegate_1.getValue_3(this, value$factory_7());
  };
  AbstractTextInput.prototype._set_startValue__2 = function (_set___) {
    return this._startValue$delegate_0.setValue_6(this, startValue$factory_3(), _set___);
  };
  AbstractTextInput.prototype._get_startValue__3 = function () {
    return this._startValue$delegate_0.getValue_3(this, startValue$factory_4());
  };
  AbstractTextInput.prototype._set_placeholder__1 = function (_set___) {
    return this._placeholder$delegate.setValue_6(this, placeholder$factory_0(), _set___);
  };
  AbstractTextInput.prototype._get_placeholder__1 = function () {
    return this._placeholder$delegate.getValue_3(this, placeholder$factory_1());
  };
  AbstractTextInput.prototype._set_name__6 = function (_set___) {
    return this._name$delegate_0.setValue_6(this, name$factory_3(), _set___);
  };
  AbstractTextInput.prototype._get_name__47 = function () {
    return this._name$delegate_0.getValue_3(this, name$factory_4());
  };
  AbstractTextInput.prototype._set_maxlength__0 = function (_set___) {
    return this._maxlength$delegate.setValue_6(this, maxlength$factory_0(), _set___);
  };
  AbstractTextInput.prototype._get_maxlength__0 = function () {
    return this._maxlength$delegate.getValue_3(this, maxlength$factory_1());
  };
  AbstractTextInput.prototype._set_disabled__5 = function (_set___) {
    return this._disabled$delegate_0.setValue_6(this, disabled$factory_3(), _set___);
  };
  AbstractTextInput.prototype._get_disabled__5 = function () {
    return this._disabled$delegate_0.getValue_3(this, disabled$factory_4());
  };
  AbstractTextInput.prototype._set_autofocus__1 = function (_set___) {
    return this._autofocus$delegate.setValue_6(this, autofocus$factory_0(), _set___);
  };
  AbstractTextInput.prototype._get_autofocus__1 = function () {
    return this._autofocus$delegate.getValue_3(this, autofocus$factory_1());
  };
  AbstractTextInput.prototype._set_readonly__1 = function (_set___) {
    return this._readonly$delegate.setValue_6(this, readonly$factory_0(), _set___);
  };
  AbstractTextInput.prototype._get_readonly__1 = function () {
    return this._readonly$delegate.getValue_3(this, readonly$factory_1());
  };
  AbstractTextInput.prototype._set_size__6 = function (_set___) {
    return this._size$delegate_0.setValue_6(this, size$factory_3(), _set___);
  };
  AbstractTextInput.prototype._get_size__43 = function () {
    return this._size$delegate_0.getValue_3(this, size$factory_4());
  };
  AbstractTextInput.prototype._set_validationStatus__10 = function (_set___) {
    return this._validationStatus$delegate_0.setValue_6(this, validationStatus$factory_3(), _set___);
  };
  AbstractTextInput.prototype._get_validationStatus__3 = function () {
    return this._validationStatus$delegate_0.getValue_3(this, validationStatus$factory_4());
  };
  AbstractTextInput.prototype.buildClassSet_30 = function (classSetBuilder) {
    Widget.prototype.buildClassSet_30.call(this, classSetBuilder);
    classSetBuilder.add_28(this._get_validationStatus__3());
    classSetBuilder.add_28(this._get_size__43());
  };
  AbstractTextInput.prototype.buildAttributeSet_30 = function (attributeSetBuilder) {
    Widget.prototype.buildAttributeSet_30.call(this, attributeSetBuilder);
    var tmp0_safe_receiver = this._get_placeholder__1();
    if (tmp0_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('placeholder', this.translate_31(tmp0_safe_receiver));
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp1_safe_receiver = this._get_name__47();
    if (tmp1_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('name', tmp1_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp2_safe_receiver = this._get_autofocus__1();
    if (tmp2_safe_receiver == null)
      null;
    else {
      if (tmp2_safe_receiver) {
        attributeSetBuilder.add$default('autofocus', null, 2, null);
      }Unit_getInstance();
    }
    Unit_getInstance();
    var tmp3_safe_receiver = this._get_maxlength__0();
    if (tmp3_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('maxlength', '' + tmp3_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp4_safe_receiver = this._get_readonly__1();
    if (tmp4_safe_receiver == null)
      null;
    else {
      if (tmp4_safe_receiver) {
        attributeSetBuilder.add$default('readonly', null, 2, null);
      }Unit_getInstance();
    }
    Unit_getInstance();
    if (this._get_disabled__5()) {
      attributeSetBuilder.add$default('disabled', null, 2, null);
    }};
  AbstractTextInput.prototype.afterInsert_30 = function (node) {
    this.refreshState_2();
  };
  AbstractTextInput.prototype.refreshState_2 = function () {
    var tmp0_safe_receiver = this.getElementJQuery_31();
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.val();
    var v = (!(tmp == null) ? typeof tmp === 'string' : false) ? tmp : null;
    var tmp_0;
    if (!(v == this._get_value__18())) {
      var tmp_1;
      if (v == null ? true : charSequenceLength(v) === 0) {
        tmp_1 = this._get_value__18() == null;
      } else {
        {
          tmp_1 = false;
        }
      }
      tmp_0 = !tmp_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      var tmp1_safe_receiver = this._get_value__18();
      var tmp_2;
      if (tmp1_safe_receiver == null) {
        tmp_2 = null;
      } else {
        var tmp0_safe_receiver_2 = this.getElementJQuery_31();
        tmp_2 = tmp0_safe_receiver_2 == null ? null : tmp0_safe_receiver_2.val(tmp1_safe_receiver);
      }
      var tmp3_elvis_lhs = tmp_2;
      if (tmp3_elvis_lhs == null) {
        var tmp2_safe_receiver = this.getElementJQueryD_30();
        if (tmp2_safe_receiver == null)
          null;
        else
          tmp2_safe_receiver.val(null);
      } else
        tmp3_elvis_lhs;
    } else {
    }
  };
  AbstractTextInput.prototype.changeValue_1 = function () {
    var tmp0_safe_receiver = this.getElementJQuery_31();
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.val();
    var v = (tmp == null ? true : typeof tmp === 'string') ? tmp : THROW_CCE();
    if (!(v == null) ? !(v === '') : false) {
      this._set_value__6(v);
    } else {
      this._set_value__6(null);
    }
  };
  AbstractTextInput.prototype.focus_32 = function () {
    var tmp0_safe_receiver = this.getElementJQuery_31();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.focus();
    Unit_getInstance();
  };
  AbstractTextInput.prototype.subscribe_12 = function (observer) {
    var tmp0_this = this;
    var tmp0_plusAssign_0 = tmp0_this._observers_1;
    tmp0_plusAssign_0.add_57(observer);
    Unit_getInstance();
    observer(this._get_value__18());
    return _no_name_provided_$factory_178(this, observer);
  };
  AbstractTextInput.prototype.subscribe_21 = function (observer) {
    return this.subscribe_12(observer);
  };
  AbstractTextInput.$metadata$ = {
    simpleName: 'AbstractTextInput',
    kind: 'class',
    interfaces: [FormInput, MutableState]
  };
  function value$factory_5() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__6(value);
    });
  }
  function startValue$factory_2() {
    return getPropertyCallableRef('startValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_startValue__3();
    }, function (receiver_0, value) {
      return receiver_0._set_startValue__2(value);
    });
  }
  function placeholder$factory() {
    return getPropertyCallableRef('placeholder', 1, KMutableProperty1, function (receiver) {
      return receiver._get_placeholder__1();
    }, function (receiver_0, value) {
      return receiver_0._set_placeholder__1(value);
    });
  }
  function name$factory_2() {
    return getPropertyCallableRef('name', 1, KMutableProperty1, function (receiver) {
      return receiver._get_name__47();
    }, function (receiver_0, value) {
      return receiver_0._set_name__6(value);
    });
  }
  function maxlength$factory() {
    return getPropertyCallableRef('maxlength', 1, KMutableProperty1, function (receiver) {
      return receiver._get_maxlength__0();
    }, function (receiver_0, value) {
      return receiver_0._set_maxlength__0(value);
    });
  }
  function disabled$factory_2() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function autofocus$factory() {
    return getPropertyCallableRef('autofocus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_autofocus__1();
    }, function (receiver_0, value) {
      return receiver_0._set_autofocus__1(value);
    });
  }
  function readonly$factory() {
    return getPropertyCallableRef('readonly', 1, KMutableProperty1, function (receiver) {
      return receiver._get_readonly__1();
    }, function (receiver_0, value) {
      return receiver_0._set_readonly__1(value);
    });
  }
  function size$factory_2() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__6(value);
    });
  }
  function validationStatus$factory_2() {
    return getPropertyCallableRef('validationStatus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_validationStatus__3();
    }, function (receiver_0, value) {
      return receiver_0._set_validationStatus__10(value);
    });
  }
  function value$factory_6() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__6(value);
    });
  }
  function value$factory_7() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__6(value);
    });
  }
  function startValue$factory_3() {
    return getPropertyCallableRef('startValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_startValue__3();
    }, function (receiver_0, value) {
      return receiver_0._set_startValue__2(value);
    });
  }
  function startValue$factory_4() {
    return getPropertyCallableRef('startValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_startValue__3();
    }, function (receiver_0, value) {
      return receiver_0._set_startValue__2(value);
    });
  }
  function placeholder$factory_0() {
    return getPropertyCallableRef('placeholder', 1, KMutableProperty1, function (receiver) {
      return receiver._get_placeholder__1();
    }, function (receiver_0, value) {
      return receiver_0._set_placeholder__1(value);
    });
  }
  function placeholder$factory_1() {
    return getPropertyCallableRef('placeholder', 1, KMutableProperty1, function (receiver) {
      return receiver._get_placeholder__1();
    }, function (receiver_0, value) {
      return receiver_0._set_placeholder__1(value);
    });
  }
  function name$factory_3() {
    return getPropertyCallableRef('name', 1, KMutableProperty1, function (receiver) {
      return receiver._get_name__47();
    }, function (receiver_0, value) {
      return receiver_0._set_name__6(value);
    });
  }
  function name$factory_4() {
    return getPropertyCallableRef('name', 1, KMutableProperty1, function (receiver) {
      return receiver._get_name__47();
    }, function (receiver_0, value) {
      return receiver_0._set_name__6(value);
    });
  }
  function maxlength$factory_0() {
    return getPropertyCallableRef('maxlength', 1, KMutableProperty1, function (receiver) {
      return receiver._get_maxlength__0();
    }, function (receiver_0, value) {
      return receiver_0._set_maxlength__0(value);
    });
  }
  function maxlength$factory_1() {
    return getPropertyCallableRef('maxlength', 1, KMutableProperty1, function (receiver) {
      return receiver._get_maxlength__0();
    }, function (receiver_0, value) {
      return receiver_0._set_maxlength__0(value);
    });
  }
  function disabled$factory_3() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function disabled$factory_4() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function autofocus$factory_0() {
    return getPropertyCallableRef('autofocus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_autofocus__1();
    }, function (receiver_0, value) {
      return receiver_0._set_autofocus__1(value);
    });
  }
  function autofocus$factory_1() {
    return getPropertyCallableRef('autofocus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_autofocus__1();
    }, function (receiver_0, value) {
      return receiver_0._set_autofocus__1(value);
    });
  }
  function readonly$factory_0() {
    return getPropertyCallableRef('readonly', 1, KMutableProperty1, function (receiver) {
      return receiver._get_readonly__1();
    }, function (receiver_0, value) {
      return receiver_0._set_readonly__1(value);
    });
  }
  function readonly$factory_1() {
    return getPropertyCallableRef('readonly', 1, KMutableProperty1, function (receiver) {
      return receiver._get_readonly__1();
    }, function (receiver_0, value) {
      return receiver_0._set_readonly__1(value);
    });
  }
  function size$factory_3() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__6(value);
    });
  }
  function size$factory_4() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__6(value);
    });
  }
  function validationStatus$factory_3() {
    return getPropertyCallableRef('validationStatus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_validationStatus__3();
    }, function (receiver_0, value) {
      return receiver_0._set_validationStatus__10(value);
    });
  }
  function validationStatus$factory_4() {
    return getPropertyCallableRef('validationStatus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_validationStatus__3();
    }, function (receiver_0, value) {
      return receiver_0._set_validationStatus__10(value);
    });
  }
  function _no_name_provided_$factory_167() {
    var i = new _no_name_provided__177();
    return function (p1) {
      i.invoke_332(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_168(this$0) {
    var i = new _no_name_provided__178(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_169(this$0) {
    var i = new _no_name_provided__179(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_170(this$0) {
    var i = new _no_name_provided__180(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_171(this$0) {
    var i = new _no_name_provided__181(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_172(this$0) {
    var i = new _no_name_provided__182(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_173(this$0) {
    var i = new _no_name_provided__183(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_174(this$0) {
    var i = new _no_name_provided__184(this$0);
    return function (p1) {
      i.invoke_476(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_175(this$0) {
    var i = new _no_name_provided__185(this$0);
    return function (p1) {
      i.invoke_476(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_176(this$0) {
    var i = new _no_name_provided__186(this$0);
    return function (p1) {
      i.invoke_478(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_177(this$0) {
    var i = new _no_name_provided__187(this$0);
    return function (p1) {
      i.invoke_480(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_178(this$0, $observer) {
    var i = new _no_name_provided__188(this$0, $observer);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_179($this_setInternalEventListener) {
    var i = new _no_name_provided__176($this_setInternalEventListener);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function Text_0(type, value, name, label, rich, init) {
    AbstractText_init_$Init$(label, rich, null, 4, null, this);
    var tmp = this;
    var tmp0_apply_0 = TextInput_init_$Create$(type, value, null, null, 12, null);
    tmp0_apply_0._set_id__30(this._get_idc__0());
    tmp0_apply_0._set_name__6(name);
    tmp._input_2 = tmp0_apply_0;
    this._input_2._set_eventTarget__3(this);
    this.addPrivate_6(this._input_2);
    Unit_getInstance();
    this.addPrivate_6(this._get_invalidFeedback__4());
    Unit_getInstance();
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  Text_0.prototype._get_input__4 = function () {
    return this._input_2;
  };
  Text_0.$metadata$ = {
    simpleName: 'Text',
    kind: 'class',
    interfaces: []
  };
  function text(_this_, type, value, name, label, rich, init) {
    var text_0 = new Text_0(type, value, name, label, rich, init);
    _this_.add_56(text_0);
    Unit_getInstance();
    return text_0;
  }
  function text$default(_this_, type, value, name, label, rich, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      type = TextInputType_TEXT_getInstance();
    if (!(($mask0 & 2) === 0))
      value = null;
    if (!(($mask0 & 4) === 0))
      name = null;
    if (!(($mask0 & 8) === 0))
      label = null;
    if (!(($mask0 & 16) === 0))
      rich = false;
    if (!(($mask0 & 32) === 0))
      init = null;
    return text(_this_, type, value, name, label, rich, init);
  }
  function TextInput_init_$Init$(type, value, classes, init, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      type = TextInputType_TEXT_getInstance();
    if (!(($mask0 & 2) === 0))
      value = null;
    if (!(($mask0 & 4) === 0)) {
      classes = emptySet();
    }if (!(($mask0 & 8) === 0))
      init = null;
    TextInput.call($this, type, value, classes, init);
    return $this;
  }
  function TextInput_init_$Create$(type, value, classes, init, $mask0, $marker) {
    return TextInput_init_$Init$(type, value, classes, init, $mask0, $marker, Object.create(TextInput.prototype));
  }
  function _no_name_provided__189(this$0) {
    this._this$0_139 = this$0;
  }
  _no_name_provided__189.prototype.invoke_356 = function (it) {
    this._this$0_139.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__189.prototype.invoke_1082 = function (p1) {
    this.invoke_356(p1 instanceof TextInputType ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__189.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__190(this$0) {
    this._this$0_140 = this$0;
  }
  _no_name_provided__190.prototype.invoke_476 = function (it) {
    this._this$0_140.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__190.prototype.invoke_1082 = function (p1) {
    this.invoke_476((p1 == null ? true : typeof p1 === 'boolean') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__190.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function TextInput(type, value, classes, init) {
    AbstractTextInput.call(this, value, plus_1(classes, 'form-control'));
    var tmp = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_180(this);
    tmp._type$delegate_0 = (new RefreshDelegateProvider_0(this, type, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, type$factory_2());
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_181(this);
    tmp_0._autocomplete$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, autocomplete$factory());
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  TextInput.prototype._set_type__3 = function (_set___) {
    return this._type$delegate_0.setValue_6(this, type$factory_3(), _set___);
  };
  TextInput.prototype._get_type__23 = function () {
    return this._type$delegate_0.getValue_3(this, type$factory_4());
  };
  TextInput.prototype._set_autocomplete_ = function (_set___) {
    return this._autocomplete$delegate.setValue_6(this, autocomplete$factory_0(), _set___);
  };
  TextInput.prototype._get_autocomplete_ = function () {
    return this._autocomplete$delegate.getValue_3(this, autocomplete$factory_1());
  };
  TextInput.prototype.render_88 = function () {
    return this.render_89('input');
  };
  TextInput.prototype.buildAttributeSet_30 = function (attributeSetBuilder) {
    AbstractTextInput.prototype.buildAttributeSet_30.call(this, attributeSetBuilder);
    attributeSetBuilder.add_24('type', this._get_type__23()._type_0);
    var tmp0_safe_receiver = this._get_startValue__3();
    if (tmp0_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('value', tmp0_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp1_safe_receiver = this._get_autocomplete_();
    if (tmp1_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('autocomplete', tmp1_safe_receiver ? 'on' : 'off');
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  TextInput.$metadata$ = {
    simpleName: 'TextInput',
    kind: 'class',
    interfaces: []
  };
  function textInput(_this_, state, type, value, classes, className, init) {
    return bind(textInput$default_0(_this_, type, value, classes, className, null, 16, null), state, true, init);
  }
  function textInput$default(_this_, state, type, value, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      type = TextInputType_TEXT_getInstance();
    if (!(($mask0 & 4) === 0))
      value = null;
    if (!(($mask0 & 8) === 0))
      classes = null;
    if (!(($mask0 & 16) === 0))
      className = null;
    return textInput(_this_, state, type, value, classes, className, init);
  }
  var TextInputType_TEXT_instance;
  var TextInputType_PASSWORD_instance;
  var TextInputType_EMAIL_instance;
  var TextInputType_TEL_instance;
  var TextInputType_COLOR_instance;
  var TextInputType_SEARCH_instance;
  var TextInputType_URL_instance;
  var TextInputType_entriesInitialized;
  function TextInputType_initEntries() {
    if (TextInputType_entriesInitialized)
      return Unit_getInstance();
    TextInputType_entriesInitialized = true;
    TextInputType_TEXT_instance = new TextInputType('TEXT', 0, 'text');
    TextInputType_PASSWORD_instance = new TextInputType('PASSWORD', 1, 'password');
    TextInputType_EMAIL_instance = new TextInputType('EMAIL', 2, 'email');
    TextInputType_TEL_instance = new TextInputType('TEL', 3, 'tel');
    TextInputType_COLOR_instance = new TextInputType('COLOR', 4, 'color');
    TextInputType_SEARCH_instance = new TextInputType('SEARCH', 5, 'search');
    TextInputType_URL_instance = new TextInputType('URL', 6, 'url');
  }
  function TextInputType(name, ordinal, type) {
    Enum.call(this, name, ordinal);
    this._type_0 = type;
  }
  TextInputType.$metadata$ = {
    simpleName: 'TextInputType',
    kind: 'class',
    interfaces: []
  };
  function textInput_0(_this_, type, value, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var textInput_1 = new TextInput(type, value, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(textInput_1);
    Unit_getInstance();
    return textInput_1;
  }
  function textInput$default_0(_this_, type, value, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      type = TextInputType_TEXT_getInstance();
    if (!(($mask0 & 2) === 0))
      value = null;
    if (!(($mask0 & 4) === 0))
      classes = null;
    if (!(($mask0 & 8) === 0))
      className = null;
    if (!(($mask0 & 16) === 0))
      init = null;
    return textInput_0(_this_, type, value, classes, className, init);
  }
  function TextInputType_TEXT_getInstance() {
    TextInputType_initEntries();
    return TextInputType_TEXT_instance;
  }
  function type$factory_2() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__3(value);
    });
  }
  function autocomplete$factory() {
    return getPropertyCallableRef('autocomplete', 1, KMutableProperty1, function (receiver) {
      return receiver._get_autocomplete_();
    }, function (receiver_0, value) {
      return receiver_0._set_autocomplete_(value);
    });
  }
  function type$factory_3() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__3(value);
    });
  }
  function type$factory_4() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__3(value);
    });
  }
  function autocomplete$factory_0() {
    return getPropertyCallableRef('autocomplete', 1, KMutableProperty1, function (receiver) {
      return receiver._get_autocomplete_();
    }, function (receiver_0, value) {
      return receiver_0._set_autocomplete_(value);
    });
  }
  function autocomplete$factory_1() {
    return getPropertyCallableRef('autocomplete', 1, KMutableProperty1, function (receiver) {
      return receiver._get_autocomplete_();
    }, function (receiver_0, value) {
      return receiver_0._set_autocomplete_(value);
    });
  }
  function _no_name_provided_$factory_180(this$0) {
    var i = new _no_name_provided__189(this$0);
    return function (p1) {
      i.invoke_356(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_181(this$0) {
    var i = new _no_name_provided__190(this$0);
    return function (p1) {
      i.invoke_476(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__191($handler, $this_setEventListener) {
    this._$handler = $handler;
    this._$this_setEventListener_1 = $this_setEventListener;
  }
  _no_name_provided__191.prototype.invoke_992 = function (e) {
    this._$handler(this._$this_setEventListener_1.self, e);
  };
  _no_name_provided__191.prototype.invoke_1082 = function (p1) {
    this.invoke_992(p1 instanceof MouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__191.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Button_init_$Init$(text_0, icon, style, type, disabled, separator, labelFirst, classes, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      icon = null;
    if (!(($mask0 & 4) === 0))
      style = ButtonStyle_PRIMARY_getInstance();
    if (!(($mask0 & 8) === 0))
      type = ButtonType_BUTTON_getInstance();
    if (!(($mask0 & 16) === 0))
      disabled = false;
    if (!(($mask0 & 32) === 0))
      separator = null;
    if (!(($mask0 & 64) === 0))
      labelFirst = true;
    if (!(($mask0 & 128) === 0)) {
      classes = emptySet();
    }Button.call($this, text_0, icon, style, type, disabled, separator, labelFirst, classes);
    return $this;
  }
  function Button_init_$Create$(text_0, icon, style, type, disabled, separator, labelFirst, classes, $mask0, $marker) {
    return Button_init_$Init$(text_0, icon, style, type, disabled, separator, labelFirst, classes, $mask0, $marker, Object.create(Button.prototype));
  }
  function _no_name_provided__192(this$0) {
    this._this$0_141 = this$0;
  }
  _no_name_provided__192.prototype.invoke_767 = function (it) {
    this._this$0_141.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__192.prototype.invoke_1082 = function (p1) {
    this.invoke_767((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__192.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__193(this$0) {
    this._this$0_142 = this$0;
  }
  _no_name_provided__193.prototype.invoke_1079 = function (it) {
    this._this$0_142.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__193.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__193.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__194(this$0) {
    this._this$0_143 = this$0;
  }
  _no_name_provided__194.prototype.invoke_366 = function (it) {
    this._this$0_143.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__194.prototype.invoke_1082 = function (p1) {
    this.invoke_366(p1 instanceof ButtonStyle ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__194.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__195(this$0) {
    this._this$0_144 = this$0;
  }
  _no_name_provided__195.prototype.invoke_368 = function (it) {
    this._this$0_144.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__195.prototype.invoke_1082 = function (p1) {
    this.invoke_368(p1 instanceof ButtonType ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__195.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__196(this$0) {
    this._this$0_145 = this$0;
  }
  _no_name_provided__196.prototype.invoke_771 = function (it) {
    this._this$0_145.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__196.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__196.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__197(this$0) {
    this._this$0_146 = this$0;
  }
  _no_name_provided__197.prototype.invoke_1079 = function (it) {
    this._this$0_146.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__197.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__197.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__198(this$0) {
    this._this$0_147 = this$0;
  }
  _no_name_provided__198.prototype.invoke_374 = function (it) {
    this._this$0_147.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__198.prototype.invoke_1082 = function (p1) {
    this.invoke_374((p1 == null ? true : p1 instanceof ButtonSize) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__198.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__199(this$0) {
    this._this$0_148 = this$0;
  }
  _no_name_provided__199.prototype.invoke_771 = function (it) {
    this._this$0_148.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__199.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__199.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__200(this$0) {
    this._this$0_149 = this$0;
  }
  _no_name_provided__200.prototype.invoke_1079 = function (it) {
    this._this$0_149.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__200.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__200.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__201(this$0) {
    this._this$0_150 = this$0;
  }
  _no_name_provided__201.prototype.invoke_771 = function (it) {
    this._this$0_150.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__201.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__201.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__202($handler) {
    this._$handler_0 = $handler;
  }
  _no_name_provided__202.prototype.invoke_422 = function ($this$setEventListener) {
    $this$setEventListener.click = _no_name_provided_$factory_193(this._$handler_0, $this$setEventListener);
  };
  _no_name_provided__202.prototype.invoke_1082 = function (p1) {
    this.invoke_422((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__202.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Button(text_0, icon, style, type, disabled, separator, labelFirst, classes) {
    SimplePanel_init_$Init$(classes, null, 2, null, this);
    var tmp = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_182(this);
    tmp._text$delegate = (new RefreshDelegateProvider_0(this, text_0, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, text$factory());
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_183(this);
    tmp_0._icon$delegate = (new RefreshDelegateProvider_0(this, icon, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, icon$factory());
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0_1 = _no_name_provided_$factory_184(this);
    tmp_1._style$delegate_0 = (new RefreshDelegateProvider_0(this, style, tmp0_refreshOnUpdate_0_1)).provideDelegate_0(this, style$factory_2());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_2 = _no_name_provided_$factory_185(this);
    tmp_2._type$delegate_1 = (new RefreshDelegateProvider_0(this, type, tmp0_refreshOnUpdate_0_2)).provideDelegate_0(this, type$factory_5());
    var tmp_3 = this;
    var tmp0_refreshOnUpdate_0_3 = _no_name_provided_$factory_186(this);
    tmp_3._disabled$delegate_1 = (new RefreshDelegateProvider_0(this, disabled, tmp0_refreshOnUpdate_0_3)).provideDelegate_0(this, disabled$factory_5());
    var tmp_4 = this;
    var tmp0_refreshOnUpdate_0_4 = _no_name_provided_$factory_187(this);
    tmp_4._image$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_4)).provideDelegate_0(this, image$factory());
    var tmp_5 = this;
    var tmp0_refreshOnUpdate_0_5 = _no_name_provided_$factory_188(this);
    tmp_5._size$delegate_1 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_5)).provideDelegate_0(this, size$factory_5());
    var tmp_6 = this;
    var tmp0_refreshOnUpdate_0_6 = false;
    var tmp1_refreshOnUpdate_0 = _no_name_provided_$factory_189(this);
    tmp_6._block$delegate = (new RefreshDelegateProvider_0(this, tmp0_refreshOnUpdate_0_6, tmp1_refreshOnUpdate_0)).provideDelegate_0(this, block$factory());
    var tmp_7 = this;
    var tmp0_refreshOnUpdate_0_7 = _no_name_provided_$factory_190(this);
    tmp_7._separator$delegate = (new RefreshDelegateProvider_0(this, separator, tmp0_refreshOnUpdate_0_7)).provideDelegate_0(this, separator$factory());
    var tmp_8 = this;
    var tmp0_refreshOnUpdate_0_8 = _no_name_provided_$factory_191(this);
    tmp_8._labelFirst$delegate = (new RefreshDelegateProvider_0(this, labelFirst, tmp0_refreshOnUpdate_0_8)).provideDelegate_0(this, labelFirst$factory());
  }
  Button.prototype._set_text_ = function (_set___) {
    return this._text$delegate.setValue_6(this, text$factory_0(), _set___);
  };
  Button.prototype._get_text_ = function () {
    return this._text$delegate.getValue_3(this, text$factory_1());
  };
  Button.prototype._set_icon_ = function (_set___) {
    return this._icon$delegate.setValue_6(this, icon$factory_0(), _set___);
  };
  Button.prototype._get_icon_ = function () {
    return this._icon$delegate.getValue_3(this, icon$factory_1());
  };
  Button.prototype._set_style__0 = function (_set___) {
    return this._style$delegate_0.setValue_6(this, style$factory_3(), _set___);
  };
  Button.prototype._get_style__2 = function () {
    return this._style$delegate_0.getValue_3(this, style$factory_4());
  };
  Button.prototype._set_type__4 = function (_set___) {
    return this._type$delegate_1.setValue_6(this, type$factory_6(), _set___);
  };
  Button.prototype._get_type__23 = function () {
    return this._type$delegate_1.getValue_3(this, type$factory_7());
  };
  Button.prototype._set_disabled__5 = function (_set___) {
    return this._disabled$delegate_1.setValue_6(this, disabled$factory_6(), _set___);
  };
  Button.prototype._get_disabled__5 = function () {
    return this._disabled$delegate_1.getValue_3(this, disabled$factory_7());
  };
  Button.prototype._set_image_ = function (_set___) {
    return this._image$delegate.setValue_6(this, image$factory_0(), _set___);
  };
  Button.prototype._get_image_ = function () {
    return this._image$delegate.getValue_3(this, image$factory_1());
  };
  Button.prototype._set_size__3 = function (_set___) {
    return this._size$delegate_1.setValue_6(this, size$factory_6(), _set___);
  };
  Button.prototype._get_size__43 = function () {
    return this._size$delegate_1.getValue_3(this, size$factory_7());
  };
  Button.prototype._set_block_ = function (_set___) {
    return this._block$delegate.setValue_6(this, block$factory_0(), _set___);
  };
  Button.prototype._get_block_ = function () {
    return this._block$delegate.getValue_3(this, block$factory_1());
  };
  Button.prototype._set_separator_ = function (_set___) {
    return this._separator$delegate.setValue_6(this, separator$factory_0(), _set___);
  };
  Button.prototype._get_separator_ = function () {
    return this._separator$delegate.getValue_3(this, separator$factory_1());
  };
  Button.prototype._set_labelFirst_ = function (_set___) {
    return this._labelFirst$delegate.setValue_6(this, labelFirst$factory_0(), _set___);
  };
  Button.prototype._get_labelFirst_ = function () {
    return this._labelFirst$delegate.getValue_3(this, labelFirst$factory_1());
  };
  Button.prototype.render_88 = function () {
    var t = this.createLabelWithIcon_0(this._get_text_(), this._get_icon_(), this._get_image_(), this._get_separator_());
    var tmp;
    if (this._get_labelFirst_()) {
      var tmp0_plus_0 = this.childrenVNodes_23();
      tmp = this.render_87('button', t.concat(tmp0_plus_0));
    } else {
      var tmp1_plus_0 = this.childrenVNodes_23();
      tmp = this.render_87('button', tmp1_plus_0.concat(t));
    }
    return tmp;
  };
  Button.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    classSetBuilder.add_27('btn');
    classSetBuilder.add_28(this._get_style__2());
    classSetBuilder.add_28(this._get_size__43());
    if (this._get_block_()) {
      classSetBuilder.add_27('btn-block');
    }};
  Button.prototype.buildAttributeSet_30 = function (attributeSetBuilder) {
    SimplePanel.prototype.buildAttributeSet_30.call(this, attributeSetBuilder);
    attributeSetBuilder.add_24('type', this._get_type__23()._buttonType);
    if (this._get_disabled__5()) {
      attributeSetBuilder.add$default('disabled', null, 2, null);
    }};
  Button.prototype.onClick = function (handler) {
    this.setEventListener_30(_no_name_provided_$factory_192(handler));
    Unit_getInstance();
    return this;
  };
  Button.$metadata$ = {
    simpleName: 'Button',
    kind: 'class',
    interfaces: []
  };
  function button(_this_, text_0, icon, style, type, disabled, separator, labelFirst, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var tmp0_apply_0 = new Button(text_0, icon, style, type, disabled, separator, labelFirst, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs);
    var tmp0_safe_receiver_2 = init;
    if (tmp0_safe_receiver_2 == null)
      null;
    else
      tmp0_safe_receiver_2(tmp0_apply_0);
    Unit_getInstance();
    var button_0 = tmp0_apply_0;
    _this_.add_56(button_0);
    Unit_getInstance();
    return button_0;
  }
  function button$default(_this_, text_0, icon, style, type, disabled, separator, labelFirst, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      icon = null;
    if (!(($mask0 & 4) === 0))
      style = ButtonStyle_PRIMARY_getInstance();
    if (!(($mask0 & 8) === 0))
      type = ButtonType_BUTTON_getInstance();
    if (!(($mask0 & 16) === 0))
      disabled = false;
    if (!(($mask0 & 32) === 0))
      separator = null;
    if (!(($mask0 & 64) === 0))
      labelFirst = true;
    if (!(($mask0 & 128) === 0))
      classes = null;
    if (!(($mask0 & 256) === 0))
      className = null;
    if (!(($mask0 & 512) === 0))
      init = null;
    return button(_this_, text_0, icon, style, type, disabled, separator, labelFirst, classes, className, init);
  }
  var ButtonStyle_PRIMARY_instance;
  var ButtonStyle_SECONDARY_instance;
  var ButtonStyle_SUCCESS_instance;
  var ButtonStyle_DANGER_instance;
  var ButtonStyle_WARNING_instance;
  var ButtonStyle_INFO_instance;
  var ButtonStyle_LIGHT_instance;
  var ButtonStyle_DARK_instance;
  var ButtonStyle_LINK_instance;
  var ButtonStyle_OUTLINEPRIMARY_instance;
  var ButtonStyle_OUTLINESECONDARY_instance;
  var ButtonStyle_OUTLINESUCCESS_instance;
  var ButtonStyle_OUTLINEDANGER_instance;
  var ButtonStyle_OUTLINEWARNING_instance;
  var ButtonStyle_OUTLINEINFO_instance;
  var ButtonStyle_OUTLINELIGHT_instance;
  var ButtonStyle_OUTLINEDARK_instance;
  var ButtonStyle_entriesInitialized;
  function ButtonStyle_initEntries() {
    if (ButtonStyle_entriesInitialized)
      return Unit_getInstance();
    ButtonStyle_entriesInitialized = true;
    ButtonStyle_PRIMARY_instance = new ButtonStyle('PRIMARY', 0, 'btn-primary');
    ButtonStyle_SECONDARY_instance = new ButtonStyle('SECONDARY', 1, 'btn-secondary');
    ButtonStyle_SUCCESS_instance = new ButtonStyle('SUCCESS', 2, 'btn-success');
    ButtonStyle_DANGER_instance = new ButtonStyle('DANGER', 3, 'btn-danger');
    ButtonStyle_WARNING_instance = new ButtonStyle('WARNING', 4, 'btn-warning');
    ButtonStyle_INFO_instance = new ButtonStyle('INFO', 5, 'btn-info');
    ButtonStyle_LIGHT_instance = new ButtonStyle('LIGHT', 6, 'btn-light');
    ButtonStyle_DARK_instance = new ButtonStyle('DARK', 7, 'btn-dark');
    ButtonStyle_LINK_instance = new ButtonStyle('LINK', 8, 'btn-link');
    ButtonStyle_OUTLINEPRIMARY_instance = new ButtonStyle('OUTLINEPRIMARY', 9, 'btn-outline-primary');
    ButtonStyle_OUTLINESECONDARY_instance = new ButtonStyle('OUTLINESECONDARY', 10, 'btn-outline-secondary');
    ButtonStyle_OUTLINESUCCESS_instance = new ButtonStyle('OUTLINESUCCESS', 11, 'btn-outline-success');
    ButtonStyle_OUTLINEDANGER_instance = new ButtonStyle('OUTLINEDANGER', 12, 'btn-outline-danger');
    ButtonStyle_OUTLINEWARNING_instance = new ButtonStyle('OUTLINEWARNING', 13, 'btn-outline-warning');
    ButtonStyle_OUTLINEINFO_instance = new ButtonStyle('OUTLINEINFO', 14, 'btn-outline-info');
    ButtonStyle_OUTLINELIGHT_instance = new ButtonStyle('OUTLINELIGHT', 15, 'btn-outline-light');
    ButtonStyle_OUTLINEDARK_instance = new ButtonStyle('OUTLINEDARK', 16, 'btn-outline-dark');
  }
  function ButtonStyle(name, ordinal, className) {
    Enum.call(this, name, ordinal);
    this._className_1 = className;
  }
  ButtonStyle.prototype._get_className__3 = function () {
    return this._className_1;
  };
  ButtonStyle.$metadata$ = {
    simpleName: 'ButtonStyle',
    kind: 'class',
    interfaces: [CssClass]
  };
  var ButtonType_BUTTON_instance;
  var ButtonType_SUBMIT_instance;
  var ButtonType_RESET_instance;
  var ButtonType_entriesInitialized;
  function ButtonType_initEntries() {
    if (ButtonType_entriesInitialized)
      return Unit_getInstance();
    ButtonType_entriesInitialized = true;
    ButtonType_BUTTON_instance = new ButtonType('BUTTON', 0, 'button');
    ButtonType_SUBMIT_instance = new ButtonType('SUBMIT', 1, 'submit');
    ButtonType_RESET_instance = new ButtonType('RESET', 2, 'reset');
  }
  function ButtonType(name, ordinal, buttonType) {
    Enum.call(this, name, ordinal);
    this._buttonType = buttonType;
  }
  ButtonType.$metadata$ = {
    simpleName: 'ButtonType',
    kind: 'class',
    interfaces: []
  };
  function ButtonSize() {
  }
  ButtonSize.$metadata$ = {
    simpleName: 'ButtonSize',
    kind: 'class',
    interfaces: [CssClass]
  };
  function ButtonStyle_PRIMARY_getInstance() {
    ButtonStyle_initEntries();
    return ButtonStyle_PRIMARY_instance;
  }
  function ButtonStyle_SECONDARY_getInstance() {
    ButtonStyle_initEntries();
    return ButtonStyle_SECONDARY_instance;
  }
  function ButtonStyle_WARNING_getInstance() {
    ButtonStyle_initEntries();
    return ButtonStyle_WARNING_instance;
  }
  function ButtonStyle_LINK_getInstance() {
    ButtonStyle_initEntries();
    return ButtonStyle_LINK_instance;
  }
  function ButtonType_BUTTON_getInstance() {
    ButtonType_initEntries();
    return ButtonType_BUTTON_instance;
  }
  function text$factory() {
    return getPropertyCallableRef('text', 1, KMutableProperty1, function (receiver) {
      return receiver._get_text_();
    }, function (receiver_0, value) {
      return receiver_0._set_text_(value);
    });
  }
  function icon$factory() {
    return getPropertyCallableRef('icon', 1, KMutableProperty1, function (receiver) {
      return receiver._get_icon_();
    }, function (receiver_0, value) {
      return receiver_0._set_icon_(value);
    });
  }
  function style$factory_2() {
    return getPropertyCallableRef('style', 1, KMutableProperty1, function (receiver) {
      return receiver._get_style__2();
    }, function (receiver_0, value) {
      return receiver_0._set_style__0(value);
    });
  }
  function type$factory_5() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__4(value);
    });
  }
  function disabled$factory_5() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function image$factory() {
    return getPropertyCallableRef('image', 1, KMutableProperty1, function (receiver) {
      return receiver._get_image_();
    }, function (receiver_0, value) {
      return receiver_0._set_image_(value);
    });
  }
  function size$factory_5() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__3(value);
    });
  }
  function block$factory() {
    return getPropertyCallableRef('block', 1, KMutableProperty1, function (receiver) {
      return receiver._get_block_();
    }, function (receiver_0, value) {
      return receiver_0._set_block_(value);
    });
  }
  function separator$factory() {
    return getPropertyCallableRef('separator', 1, KMutableProperty1, function (receiver) {
      return receiver._get_separator_();
    }, function (receiver_0, value) {
      return receiver_0._set_separator_(value);
    });
  }
  function labelFirst$factory() {
    return getPropertyCallableRef('labelFirst', 1, KMutableProperty1, function (receiver) {
      return receiver._get_labelFirst_();
    }, function (receiver_0, value) {
      return receiver_0._set_labelFirst_(value);
    });
  }
  function text$factory_0() {
    return getPropertyCallableRef('text', 1, KMutableProperty1, function (receiver) {
      return receiver._get_text_();
    }, function (receiver_0, value) {
      return receiver_0._set_text_(value);
    });
  }
  function text$factory_1() {
    return getPropertyCallableRef('text', 1, KMutableProperty1, function (receiver) {
      return receiver._get_text_();
    }, function (receiver_0, value) {
      return receiver_0._set_text_(value);
    });
  }
  function icon$factory_0() {
    return getPropertyCallableRef('icon', 1, KMutableProperty1, function (receiver) {
      return receiver._get_icon_();
    }, function (receiver_0, value) {
      return receiver_0._set_icon_(value);
    });
  }
  function icon$factory_1() {
    return getPropertyCallableRef('icon', 1, KMutableProperty1, function (receiver) {
      return receiver._get_icon_();
    }, function (receiver_0, value) {
      return receiver_0._set_icon_(value);
    });
  }
  function style$factory_3() {
    return getPropertyCallableRef('style', 1, KMutableProperty1, function (receiver) {
      return receiver._get_style__2();
    }, function (receiver_0, value) {
      return receiver_0._set_style__0(value);
    });
  }
  function style$factory_4() {
    return getPropertyCallableRef('style', 1, KMutableProperty1, function (receiver) {
      return receiver._get_style__2();
    }, function (receiver_0, value) {
      return receiver_0._set_style__0(value);
    });
  }
  function type$factory_6() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__4(value);
    });
  }
  function type$factory_7() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__4(value);
    });
  }
  function disabled$factory_6() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function disabled$factory_7() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function image$factory_0() {
    return getPropertyCallableRef('image', 1, KMutableProperty1, function (receiver) {
      return receiver._get_image_();
    }, function (receiver_0, value) {
      return receiver_0._set_image_(value);
    });
  }
  function image$factory_1() {
    return getPropertyCallableRef('image', 1, KMutableProperty1, function (receiver) {
      return receiver._get_image_();
    }, function (receiver_0, value) {
      return receiver_0._set_image_(value);
    });
  }
  function size$factory_6() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__3(value);
    });
  }
  function size$factory_7() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__3(value);
    });
  }
  function block$factory_0() {
    return getPropertyCallableRef('block', 1, KMutableProperty1, function (receiver) {
      return receiver._get_block_();
    }, function (receiver_0, value) {
      return receiver_0._set_block_(value);
    });
  }
  function block$factory_1() {
    return getPropertyCallableRef('block', 1, KMutableProperty1, function (receiver) {
      return receiver._get_block_();
    }, function (receiver_0, value) {
      return receiver_0._set_block_(value);
    });
  }
  function separator$factory_0() {
    return getPropertyCallableRef('separator', 1, KMutableProperty1, function (receiver) {
      return receiver._get_separator_();
    }, function (receiver_0, value) {
      return receiver_0._set_separator_(value);
    });
  }
  function separator$factory_1() {
    return getPropertyCallableRef('separator', 1, KMutableProperty1, function (receiver) {
      return receiver._get_separator_();
    }, function (receiver_0, value) {
      return receiver_0._set_separator_(value);
    });
  }
  function labelFirst$factory_0() {
    return getPropertyCallableRef('labelFirst', 1, KMutableProperty1, function (receiver) {
      return receiver._get_labelFirst_();
    }, function (receiver_0, value) {
      return receiver_0._set_labelFirst_(value);
    });
  }
  function labelFirst$factory_1() {
    return getPropertyCallableRef('labelFirst', 1, KMutableProperty1, function (receiver) {
      return receiver._get_labelFirst_();
    }, function (receiver_0, value) {
      return receiver_0._set_labelFirst_(value);
    });
  }
  function _no_name_provided_$factory_182(this$0) {
    var i = new _no_name_provided__192(this$0);
    return function (p1) {
      i.invoke_767(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_183(this$0) {
    var i = new _no_name_provided__193(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_184(this$0) {
    var i = new _no_name_provided__194(this$0);
    return function (p1) {
      i.invoke_366(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_185(this$0) {
    var i = new _no_name_provided__195(this$0);
    return function (p1) {
      i.invoke_368(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_186(this$0) {
    var i = new _no_name_provided__196(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_187(this$0) {
    var i = new _no_name_provided__197(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_188(this$0) {
    var i = new _no_name_provided__198(this$0);
    return function (p1) {
      i.invoke_374(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_189(this$0) {
    var i = new _no_name_provided__199(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_190(this$0) {
    var i = new _no_name_provided__200(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_191(this$0) {
    var i = new _no_name_provided__201(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_192($handler) {
    var i = new _no_name_provided__202($handler);
    return function (p1) {
      i.invoke_422(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_193($handler, $this_setEventListener) {
    var i = new _no_name_provided__191($handler, $this_setEventListener);
    return function (p1) {
      i.invoke_992(p1);
      return Unit_getInstance();
    };
  }
  function Div(content, rich, align, classes, init) {
    var tmp = TAG_DIV_getInstance();
    Tag_init_$Init$(tmp, content, rich, align, classes, null, null, 96, null, this);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  Div.$metadata$ = {
    simpleName: 'Div',
    kind: 'class',
    interfaces: []
  };
  function div(_this_, content, rich, align, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var div_1 = new Div(content, rich, align, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(div_1);
    Unit_getInstance();
    return div_1;
  }
  function div$default(_this_, content, rich, align, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      content = null;
    if (!(($mask0 & 2) === 0))
      rich = false;
    if (!(($mask0 & 4) === 0))
      align = null;
    if (!(($mask0 & 8) === 0))
      classes = null;
    if (!(($mask0 & 16) === 0))
      className = null;
    if (!(($mask0 & 32) === 0))
      init = null;
    return div(_this_, content, rich, align, classes, className, init);
  }
  function div_0(_this_, state, content, rich, align, classes, className, init) {
    return bind(div$default(_this_, content, rich, align, classes, className, null, 32, null), state, true, init);
  }
  function div$default_0(_this_, state, content, rich, align, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      content = null;
    if (!(($mask0 & 4) === 0))
      rich = false;
    if (!(($mask0 & 8) === 0))
      align = null;
    if (!(($mask0 & 16) === 0))
      classes = null;
    if (!(($mask0 & 32) === 0))
      className = null;
    return div_0(_this_, state, content, rich, align, classes, className, init);
  }
  function Footer(content, rich, align, classes, init) {
    var tmp = TAG_FOOTER_getInstance();
    Tag_init_$Init$(tmp, content, rich, align, classes, null, null, 96, null, this);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  Footer.$metadata$ = {
    simpleName: 'Footer',
    kind: 'class',
    interfaces: []
  };
  function footer(_this_, content, rich, align, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var footer_0 = new Footer(content, rich, align, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(footer_0);
    Unit_getInstance();
    return footer_0;
  }
  function footer$default(_this_, content, rich, align, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      content = null;
    if (!(($mask0 & 2) === 0))
      rich = false;
    if (!(($mask0 & 4) === 0))
      align = null;
    if (!(($mask0 & 8) === 0))
      classes = null;
    if (!(($mask0 & 16) === 0))
      className = null;
    if (!(($mask0 & 32) === 0))
      init = null;
    return footer(_this_, content, rich, align, classes, className, init);
  }
  function H1(content, rich, align, classes, init) {
    var tmp = TAG_H1_getInstance();
    Tag_init_$Init$(tmp, content, rich, align, classes, null, null, 96, null, this);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  H1.$metadata$ = {
    simpleName: 'H1',
    kind: 'class',
    interfaces: []
  };
  function h1(_this_, content, rich, align, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var h1_0 = new H1(content, rich, align, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(h1_0);
    Unit_getInstance();
    return h1_0;
  }
  function h1$default(_this_, content, rich, align, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      content = null;
    if (!(($mask0 & 2) === 0))
      rich = false;
    if (!(($mask0 & 4) === 0))
      align = null;
    if (!(($mask0 & 8) === 0))
      classes = null;
    if (!(($mask0 & 16) === 0))
      className = null;
    if (!(($mask0 & 32) === 0))
      init = null;
    return h1(_this_, content, rich, align, classes, className, init);
  }
  function H6(content, rich, align, classes, init) {
    var tmp = TAG_H6_getInstance();
    Tag_init_$Init$(tmp, content, rich, align, classes, null, null, 96, null, this);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  H6.$metadata$ = {
    simpleName: 'H6',
    kind: 'class',
    interfaces: []
  };
  function h6(_this_, content, rich, align, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var h6_0 = new H6(content, rich, align, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(h6_0);
    Unit_getInstance();
    return h6_0;
  }
  function h6$default(_this_, content, rich, align, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      content = null;
    if (!(($mask0 & 2) === 0))
      rich = false;
    if (!(($mask0 & 4) === 0))
      align = null;
    if (!(($mask0 & 8) === 0))
      classes = null;
    if (!(($mask0 & 16) === 0))
      className = null;
    if (!(($mask0 & 32) === 0))
      init = null;
    return h6(_this_, content, rich, align, classes, className, init);
  }
  function Header(content, rich, align, classes, init) {
    var tmp = TAG_HEADER_getInstance();
    Tag_init_$Init$(tmp, content, rich, align, classes, null, null, 96, null, this);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  Header.$metadata$ = {
    simpleName: 'Header',
    kind: 'class',
    interfaces: []
  };
  function header(_this_, content, rich, align, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var header_0 = new Header(content, rich, align, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(header_0);
    Unit_getInstance();
    return header_0;
  }
  function header$default(_this_, content, rich, align, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      content = null;
    if (!(($mask0 & 2) === 0))
      rich = false;
    if (!(($mask0 & 4) === 0))
      align = null;
    if (!(($mask0 & 8) === 0))
      classes = null;
    if (!(($mask0 & 16) === 0))
      className = null;
    if (!(($mask0 & 32) === 0))
      init = null;
    return header(_this_, content, rich, align, classes, className, init);
  }
  function Main(content, rich, align, classes, init) {
    var tmp = TAG_MAIN_getInstance();
    Tag_init_$Init$(tmp, content, rich, align, classes, null, null, 96, null, this);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  Main.$metadata$ = {
    simpleName: 'Main',
    kind: 'class',
    interfaces: []
  };
  function main(_this_, content, rich, align, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var main_1 = new Main(content, rich, align, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(main_1);
    Unit_getInstance();
    return main_1;
  }
  function main$default(_this_, content, rich, align, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      content = null;
    if (!(($mask0 & 2) === 0))
      rich = false;
    if (!(($mask0 & 4) === 0))
      align = null;
    if (!(($mask0 & 8) === 0))
      classes = null;
    if (!(($mask0 & 16) === 0))
      className = null;
    if (!(($mask0 & 32) === 0))
      init = null;
    return main(_this_, content, rich, align, classes, className, init);
  }
  function Span(content, rich, align, classes, init) {
    var tmp = TAG_SPAN_getInstance();
    Tag_init_$Init$(tmp, content, rich, align, classes, null, null, 96, null, this);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  Span.$metadata$ = {
    simpleName: 'Span',
    kind: 'class',
    interfaces: []
  };
  function span(_this_, content, rich, align, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var span_1 = new Span(content, rich, align, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(span_1);
    Unit_getInstance();
    return span_1;
  }
  function span$default(_this_, content, rich, align, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      content = null;
    if (!(($mask0 & 2) === 0))
      rich = false;
    if (!(($mask0 & 4) === 0))
      align = null;
    if (!(($mask0 & 8) === 0))
      classes = null;
    if (!(($mask0 & 16) === 0))
      className = null;
    if (!(($mask0 & 32) === 0))
      init = null;
    return span(_this_, content, rich, align, classes, className, init);
  }
  function span_0(_this_, state, content, rich, align, classes, className, init) {
    return bind(span$default(_this_, content, rich, align, classes, className, null, 32, null), state, true, init);
  }
  function span$default_0(_this_, state, content, rich, align, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      content = null;
    if (!(($mask0 & 4) === 0))
      rich = false;
    if (!(($mask0 & 8) === 0))
      align = null;
    if (!(($mask0 & 16) === 0))
      classes = null;
    if (!(($mask0 & 32) === 0))
      className = null;
    return span_0(_this_, state, content, rich, align, classes, className, init);
  }
  function Tag_init_$Init$(type, content, rich, align, classes, attributes, init, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      content = null;
    if (!(($mask0 & 4) === 0))
      rich = false;
    if (!(($mask0 & 8) === 0))
      align = null;
    if (!(($mask0 & 16) === 0)) {
      classes = emptySet();
    }if (!(($mask0 & 32) === 0))
      attributes = null;
    if (!(($mask0 & 64) === 0))
      init = null;
    Tag.call($this, type, content, rich, align, classes, attributes, init);
    return $this;
  }
  function Tag_init_$Create$(type, content, rich, align, classes, attributes, init, $mask0, $marker) {
    return Tag_init_$Init$(type, content, rich, align, classes, attributes, init, $mask0, $marker, Object.create(Tag.prototype));
  }
  function _no_name_provided__203(this$0) {
    this._this$0_151 = this$0;
  }
  _no_name_provided__203.prototype.invoke_384 = function (it) {
    this._this$0_151.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__203.prototype.invoke_1082 = function (p1) {
    this.invoke_384(p1 instanceof TAG ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__203.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__204(this$0) {
    this._this$0_152 = this$0;
  }
  _no_name_provided__204.prototype.invoke_1079 = function (it) {
    this._this$0_152.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__204.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__204.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__205(this$0) {
    this._this$0_153 = this$0;
  }
  _no_name_provided__205.prototype.invoke_771 = function (it) {
    this._this$0_153.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__205.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__205.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__206(this$0) {
    this._this$0_154 = this$0;
  }
  _no_name_provided__206.prototype.invoke_390 = function (it) {
    this._this$0_154.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__206.prototype.invoke_1082 = function (p1) {
    this.invoke_390((p1 == null ? true : p1 instanceof Align) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__206.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__207(this$0) {
    this._this$0_155 = this$0;
  }
  _no_name_provided__207.prototype.invoke_392 = function (it) {
    this._this$0_155.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__207.prototype.invoke_1082 = function (p1) {
    this.invoke_392((p1 == null ? true : typeof p1 === 'function') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__207.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__208(this$0) {
    this._this$0_156 = this$0;
  }
  _no_name_provided__208.prototype.invoke_394 = function (it) {
    this._this$0_156.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__208.prototype.invoke_1082 = function (p1) {
    this.invoke_394((!(p1 == null) ? isInterface(p1, Map_0) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__208.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Tag(type, content, rich, align, classes, attributes, init) {
    SimplePanel_init_$Init$(classes, null, 2, null, this);
    var tmp = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_194(this);
    tmp._type$delegate_2 = (new RefreshDelegateProvider_0(this, type, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, type$factory_8());
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_195(this);
    tmp_0._content$delegate = (new RefreshDelegateProvider_0(this, content, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, content$factory());
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0_1 = _no_name_provided_$factory_196(this);
    tmp_1._rich$delegate = (new RefreshDelegateProvider_0(this, rich, tmp0_refreshOnUpdate_0_1)).provideDelegate_0(this, rich$factory());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_2 = _no_name_provided_$factory_197(this);
    tmp_2._align$delegate = (new RefreshDelegateProvider_0(this, align, tmp0_refreshOnUpdate_0_2)).provideDelegate_0(this, align$factory());
    this._templateDataObj = null;
    var tmp_3 = this;
    var tmp0_refreshOnUpdate_0_3 = _no_name_provided_$factory_198(this);
    tmp_3._template$delegate = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_3)).provideDelegate_0(this, template$factory());
    var tmp_4 = this;
    var tmp0_refreshOnUpdate_0_4 = emptyMap();
    var tmp1_refreshOnUpdate_0 = _no_name_provided_$factory_199(this);
    tmp_4._templates$delegate = (new RefreshDelegateProvider_0(this, tmp0_refreshOnUpdate_0_4, tmp1_refreshOnUpdate_0)).provideDelegate_0(this, templates$factory());
    if (!(attributes == null)) {
      if (this._get_attributes__10() == null) {
        this._set_attributes__9(LinkedHashMap_init_$Create$());
      }var tmp0_plusAssign_0 = ensureNotNull(this._get_attributes__10());
      tmp0_plusAssign_0.putAll_3(attributes);
    }var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  Tag.prototype._set_type__12 = function (_set___) {
    return this._type$delegate_2.setValue_6(this, type$factory_9(), _set___);
  };
  Tag.prototype._get_type__23 = function () {
    return this._type$delegate_2.getValue_3(this, type$factory_10());
  };
  Tag.prototype._set_content__9 = function (_set___) {
    return this._content$delegate.setValue_6(this, content$factory_0(), _set___);
  };
  Tag.prototype._get_content__8 = function () {
    return this._content$delegate.getValue_3(this, content$factory_1());
  };
  Tag.prototype._set_rich__9 = function (_set___) {
    return this._rich$delegate.setValue_6(this, rich$factory_0(), _set___);
  };
  Tag.prototype._get_rich__9 = function () {
    return this._rich$delegate.getValue_3(this, rich$factory_1());
  };
  Tag.prototype._set_align__8 = function (_set___) {
    return this._align$delegate.setValue_6(this, align$factory_0(), _set___);
  };
  Tag.prototype._get_align__8 = function () {
    return this._align$delegate.getValue_3(this, align$factory_1());
  };
  Tag.prototype._set_templateDataObj__9 = function (_set___) {
    this._templateDataObj = _set___;
  };
  Tag.prototype._get_templateDataObj__8 = function () {
    return this._templateDataObj;
  };
  Tag.prototype._set_template__8 = function (_set___) {
    return this._template$delegate.setValue_6(this, template$factory_0(), _set___);
  };
  Tag.prototype._get_template__9 = function () {
    return this._template$delegate.getValue_3(this, template$factory_1());
  };
  Tag.prototype._set_templates__8 = function (_set___) {
    return this._templates$delegate.setValue_6(this, templates$factory_0(), _set___);
  };
  Tag.prototype._get_templates__9 = function () {
    return this._templates$delegate.getValue_3(this, templates$factory_1());
  };
  Tag.prototype.render_88 = function () {
    if ((!(this._get_templateDataObj__8() == null) ? !(this._get_lastLanguage__9() == null) : false) ? !(this._get_lastLanguage__9() === I18n_getInstance()._language) : false) {
      var tmp0_safe_receiver = this.getRoot_31();
      if (tmp0_safe_receiver == null) {
        Unit_getInstance();
      } else
        tmp0_safe_receiver._renderDisabled = true;
      this._set_templateData__9(this._get_templateDataObj__8());
      var tmp1_safe_receiver = this.getRoot_31();
      if (tmp1_safe_receiver == null) {
        Unit_getInstance();
      } else
        tmp1_safe_receiver._renderDisabled = false;
    }var tmp;
    if (!(this._get_content__8() == null)) {
      var tmp2_safe_receiver = this._get_content__8();
      var tmp_0;
      if (tmp2_safe_receiver == null) {
        tmp_0 = null;
      } else {
        tmp_0 = this.translate_31(tmp2_safe_receiver);
      }
      var translatedContent = tmp_0;
      var tmp_1;
      if (this._get_rich__9()) {
        var tmp_2 = this._get_type__23()._tagName;
        var tmp0_arrayOf_0 = [KVManager_getInstance().virtualize('' + '<span style="display: contents;">' + translatedContent + '<\/span>')];
        var tmp1_plus_0 = tmp0_arrayOf_0;
        var tmp2_plus_0 = this.childrenVNodes_23();
        tmp_1 = this.render_87(tmp_2, tmp1_plus_0.concat(tmp2_plus_0));
      } else {
        var tmp_3 = this._get_type__23()._tagName;
        var tmp4_plus_0 = this.childrenVNodes_23();
        var tmp3_arrayOf_0 = [translatedContent];
        var tmp5_plus_0 = tmp3_arrayOf_0;
        tmp_1 = this.render_87(tmp_3, tmp4_plus_0.concat(tmp5_plus_0));
      }
      tmp = tmp_1;
    } else {
      tmp = this.render_87(this._get_type__23()._tagName, this.childrenVNodes_23());
    }
    return tmp;
  };
  Tag.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    classSetBuilder.add_28(this._get_align__8());
  };
  Tag.$metadata$ = {
    simpleName: 'Tag',
    kind: 'class',
    interfaces: [Template]
  };
  function Align() {
  }
  Align.$metadata$ = {
    simpleName: 'Align',
    kind: 'class',
    interfaces: [CssClass]
  };
  var TAG_H1_instance;
  var TAG_H2_instance;
  var TAG_H3_instance;
  var TAG_H4_instance;
  var TAG_H5_instance;
  var TAG_H6_instance;
  var TAG_P_instance;
  var TAG_ABBR_instance;
  var TAG_ADDRESS_instance;
  var TAG_BLOCKQUOTE_instance;
  var TAG_MAIN_instance;
  var TAG_SECTION_instance;
  var TAG_HEADER_instance;
  var TAG_FOOTER_instance;
  var TAG_ARTICLE_instance;
  var TAG_NAV_instance;
  var TAG_PRE_instance;
  var TAG_UL_instance;
  var TAG_OL_instance;
  var TAG_DIV_instance;
  var TAG_LABEL_instance;
  var TAG_A_instance;
  var TAG_IMG_instance;
  var TAG_I_instance;
  var TAG_B_instance;
  var TAG_MARK_instance;
  var TAG_DEL_instance;
  var TAG_S_instance;
  var TAG_INS_instance;
  var TAG_U_instance;
  var TAG_SMALL_instance;
  var TAG_STRONG_instance;
  var TAG_EM_instance;
  var TAG_CITE_instance;
  var TAG_CODE_instance;
  var TAG_KBD_instance;
  var TAG_VAR_instance;
  var TAG_SAMP_instance;
  var TAG_SPAN_instance;
  var TAG_LI_instance;
  var TAG_HR_instance;
  var TAG_BR_instance;
  var TAG_CAPTION_instance;
  var TAG_FIGURE_instance;
  var TAG_FIGCAPTION_instance;
  var TAG_PICTURE_instance;
  var TAG_SOURCE_instance;
  var TAG_TABLE_instance;
  var TAG_THEAD_instance;
  var TAG_TH_instance;
  var TAG_TBODY_instance;
  var TAG_TR_instance;
  var TAG_TD_instance;
  var TAG_FIELDSET_instance;
  var TAG_LEGEND_instance;
  var TAG_FORM_instance;
  var TAG_INPUT_instance;
  var TAG_SELECT_instance;
  var TAG_OPTION_instance;
  var TAG_BUTTON_instance;
  var TAG_entriesInitialized;
  function TAG_initEntries() {
    if (TAG_entriesInitialized)
      return Unit_getInstance();
    TAG_entriesInitialized = true;
    TAG_H1_instance = new TAG('H1', 0, 'h1');
    TAG_H2_instance = new TAG('H2', 1, 'h2');
    TAG_H3_instance = new TAG('H3', 2, 'h3');
    TAG_H4_instance = new TAG('H4', 3, 'h4');
    TAG_H5_instance = new TAG('H5', 4, 'h5');
    TAG_H6_instance = new TAG('H6', 5, 'h6');
    TAG_P_instance = new TAG('P', 6, 'p');
    TAG_ABBR_instance = new TAG('ABBR', 7, 'abbr');
    TAG_ADDRESS_instance = new TAG('ADDRESS', 8, 'address');
    TAG_BLOCKQUOTE_instance = new TAG('BLOCKQUOTE', 9, 'blockquote');
    TAG_MAIN_instance = new TAG('MAIN', 10, 'main');
    TAG_SECTION_instance = new TAG('SECTION', 11, 'section');
    TAG_HEADER_instance = new TAG('HEADER', 12, 'header');
    TAG_FOOTER_instance = new TAG('FOOTER', 13, 'footer');
    TAG_ARTICLE_instance = new TAG('ARTICLE', 14, 'article');
    TAG_NAV_instance = new TAG('NAV', 15, 'nav');
    TAG_PRE_instance = new TAG('PRE', 16, 'pre');
    TAG_UL_instance = new TAG('UL', 17, 'ul');
    TAG_OL_instance = new TAG('OL', 18, 'ol');
    TAG_DIV_instance = new TAG('DIV', 19, 'div');
    TAG_LABEL_instance = new TAG('LABEL', 20, 'label');
    TAG_A_instance = new TAG('A', 21, 'a');
    TAG_IMG_instance = new TAG('IMG', 22, 'img');
    TAG_I_instance = new TAG('I', 23, 'i');
    TAG_B_instance = new TAG('B', 24, 'b');
    TAG_MARK_instance = new TAG('MARK', 25, 'mark');
    TAG_DEL_instance = new TAG('DEL', 26, 'del');
    TAG_S_instance = new TAG('S', 27, 's');
    TAG_INS_instance = new TAG('INS', 28, 'ins');
    TAG_U_instance = new TAG('U', 29, 'u');
    TAG_SMALL_instance = new TAG('SMALL', 30, 'small');
    TAG_STRONG_instance = new TAG('STRONG', 31, 'strong');
    TAG_EM_instance = new TAG('EM', 32, 'em');
    TAG_CITE_instance = new TAG('CITE', 33, 'cite');
    TAG_CODE_instance = new TAG('CODE', 34, 'code');
    TAG_KBD_instance = new TAG('KBD', 35, 'kbd');
    TAG_VAR_instance = new TAG('VAR', 36, 'var');
    TAG_SAMP_instance = new TAG('SAMP', 37, 'samp');
    TAG_SPAN_instance = new TAG('SPAN', 38, 'span');
    TAG_LI_instance = new TAG('LI', 39, 'li');
    TAG_HR_instance = new TAG('HR', 40, 'hr');
    TAG_BR_instance = new TAG('BR', 41, 'br');
    TAG_CAPTION_instance = new TAG('CAPTION', 42, 'caption');
    TAG_FIGURE_instance = new TAG('FIGURE', 43, 'figure');
    TAG_FIGCAPTION_instance = new TAG('FIGCAPTION', 44, 'figcaption');
    TAG_PICTURE_instance = new TAG('PICTURE', 45, 'figcaption');
    TAG_SOURCE_instance = new TAG('SOURCE', 46, 'figcaption');
    TAG_TABLE_instance = new TAG('TABLE', 47, 'table');
    TAG_THEAD_instance = new TAG('THEAD', 48, 'thead');
    TAG_TH_instance = new TAG('TH', 49, 'th');
    TAG_TBODY_instance = new TAG('TBODY', 50, 'tbody');
    TAG_TR_instance = new TAG('TR', 51, 'tr');
    TAG_TD_instance = new TAG('TD', 52, 'td');
    TAG_FIELDSET_instance = new TAG('FIELDSET', 53, 'fieldset');
    TAG_LEGEND_instance = new TAG('LEGEND', 54, 'legend');
    TAG_FORM_instance = new TAG('FORM', 55, 'form');
    TAG_INPUT_instance = new TAG('INPUT', 56, 'input');
    TAG_SELECT_instance = new TAG('SELECT', 57, 'select');
    TAG_OPTION_instance = new TAG('OPTION', 58, 'option');
    TAG_BUTTON_instance = new TAG('BUTTON', 59, 'button');
  }
  function TAG(name, ordinal, tagName) {
    Enum.call(this, name, ordinal);
    this._tagName = tagName;
  }
  TAG.$metadata$ = {
    simpleName: 'TAG',
    kind: 'class',
    interfaces: []
  };
  function TAG_H1_getInstance() {
    TAG_initEntries();
    return TAG_H1_instance;
  }
  function TAG_H5_getInstance() {
    TAG_initEntries();
    return TAG_H5_instance;
  }
  function TAG_H6_getInstance() {
    TAG_initEntries();
    return TAG_H6_instance;
  }
  function TAG_MAIN_getInstance() {
    TAG_initEntries();
    return TAG_MAIN_instance;
  }
  function TAG_HEADER_getInstance() {
    TAG_initEntries();
    return TAG_HEADER_instance;
  }
  function TAG_FOOTER_getInstance() {
    TAG_initEntries();
    return TAG_FOOTER_instance;
  }
  function TAG_DIV_getInstance() {
    TAG_initEntries();
    return TAG_DIV_instance;
  }
  function TAG_LABEL_getInstance() {
    TAG_initEntries();
    return TAG_LABEL_instance;
  }
  function TAG_SPAN_getInstance() {
    TAG_initEntries();
    return TAG_SPAN_instance;
  }
  function type$factory_8() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__12(value);
    });
  }
  function content$factory() {
    return getPropertyCallableRef('content', 1, KMutableProperty1, function (receiver) {
      return receiver._get_content__8();
    }, function (receiver_0, value) {
      return receiver_0._set_content__9(value);
    });
  }
  function rich$factory() {
    return getPropertyCallableRef('rich', 1, KMutableProperty1, function (receiver) {
      return receiver._get_rich__9();
    }, function (receiver_0, value) {
      return receiver_0._set_rich__9(value);
    });
  }
  function align$factory() {
    return getPropertyCallableRef('align', 1, KMutableProperty1, function (receiver) {
      return receiver._get_align__8();
    }, function (receiver_0, value) {
      return receiver_0._set_align__8(value);
    });
  }
  function template$factory() {
    return getPropertyCallableRef('template', 1, KMutableProperty1, function (receiver) {
      return receiver._get_template__9();
    }, function (receiver_0, value) {
      return receiver_0._set_template__8(value);
    });
  }
  function templates$factory() {
    return getPropertyCallableRef('templates', 1, KMutableProperty1, function (receiver) {
      return receiver._get_templates__9();
    }, function (receiver_0, value) {
      return receiver_0._set_templates__8(value);
    });
  }
  function type$factory_9() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__12(value);
    });
  }
  function type$factory_10() {
    return getPropertyCallableRef('type', 1, KMutableProperty1, function (receiver) {
      return receiver._get_type__23();
    }, function (receiver_0, value) {
      return receiver_0._set_type__12(value);
    });
  }
  function content$factory_0() {
    return getPropertyCallableRef('content', 1, KMutableProperty1, function (receiver) {
      return receiver._get_content__8();
    }, function (receiver_0, value) {
      return receiver_0._set_content__9(value);
    });
  }
  function content$factory_1() {
    return getPropertyCallableRef('content', 1, KMutableProperty1, function (receiver) {
      return receiver._get_content__8();
    }, function (receiver_0, value) {
      return receiver_0._set_content__9(value);
    });
  }
  function rich$factory_0() {
    return getPropertyCallableRef('rich', 1, KMutableProperty1, function (receiver) {
      return receiver._get_rich__9();
    }, function (receiver_0, value) {
      return receiver_0._set_rich__9(value);
    });
  }
  function rich$factory_1() {
    return getPropertyCallableRef('rich', 1, KMutableProperty1, function (receiver) {
      return receiver._get_rich__9();
    }, function (receiver_0, value) {
      return receiver_0._set_rich__9(value);
    });
  }
  function align$factory_0() {
    return getPropertyCallableRef('align', 1, KMutableProperty1, function (receiver) {
      return receiver._get_align__8();
    }, function (receiver_0, value) {
      return receiver_0._set_align__8(value);
    });
  }
  function align$factory_1() {
    return getPropertyCallableRef('align', 1, KMutableProperty1, function (receiver) {
      return receiver._get_align__8();
    }, function (receiver_0, value) {
      return receiver_0._set_align__8(value);
    });
  }
  function template$factory_0() {
    return getPropertyCallableRef('template', 1, KMutableProperty1, function (receiver) {
      return receiver._get_template__9();
    }, function (receiver_0, value) {
      return receiver_0._set_template__8(value);
    });
  }
  function template$factory_1() {
    return getPropertyCallableRef('template', 1, KMutableProperty1, function (receiver) {
      return receiver._get_template__9();
    }, function (receiver_0, value) {
      return receiver_0._set_template__8(value);
    });
  }
  function templates$factory_0() {
    return getPropertyCallableRef('templates', 1, KMutableProperty1, function (receiver) {
      return receiver._get_templates__9();
    }, function (receiver_0, value) {
      return receiver_0._set_templates__8(value);
    });
  }
  function templates$factory_1() {
    return getPropertyCallableRef('templates', 1, KMutableProperty1, function (receiver) {
      return receiver._get_templates__9();
    }, function (receiver_0, value) {
      return receiver_0._set_templates__8(value);
    });
  }
  function _no_name_provided_$factory_194(this$0) {
    var i = new _no_name_provided__203(this$0);
    return function (p1) {
      i.invoke_384(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_195(this$0) {
    var i = new _no_name_provided__204(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_196(this$0) {
    var i = new _no_name_provided__205(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_197(this$0) {
    var i = new _no_name_provided__206(this$0);
    return function (p1) {
      i.invoke_390(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_198(this$0) {
    var i = new _no_name_provided__207(this$0);
    return function (p1) {
      i.invoke_392(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_199(this$0) {
    var i = new _no_name_provided__208(this$0);
    return function (p1) {
      i.invoke_394(p1);
      return Unit_getInstance();
    };
  }
  function Template() {
  }
  Template.prototype._set_templateData__9 = function (value) {
    if (!this._get_rich__9())
      this._set_rich__9(true);
    this._set_templateDataObj__9(value);
    var tmp0_safe_receiver = this._get_template__9();
    var tmp2_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver(value);
    var tmp;
    if (tmp2_elvis_lhs == null) {
      var tmp1_safe_receiver = this._get_templates__9().get_20(I18n_getInstance()._language);
      tmp = tmp1_safe_receiver == null ? null : tmp1_safe_receiver(value);
    } else {
      tmp = tmp2_elvis_lhs;
    }
    this._set_content__9(tmp);
  };
  Template.$metadata$ = {
    simpleName: 'Template',
    kind: 'interface',
    interfaces: []
  };
  function I18n() {
    I18n_instance = this;
    this._manager = new SimpleI18nManager();
    var tmp = this;
    var tmp_0 = window.navigator.language;
    var tmp_1 = ['-'];
    tmp._defaultLanguage = split$default(tmp_0, tmp_1, false, 0, 6, null).get_44(0);
    this._language = this._defaultLanguage;
  }
  I18n.prototype._set_language_ = function (value) {
    this._language = value;
    var tmp0_forEach_0 = Companion_getInstance_16()._roots;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2.restart();
    }
  };
  I18n.prototype.gettext_2 = function (key) {
    return this._manager.gettext_2(key);
  };
  I18n.prototype.ngettext_2 = function (singularKey, pluralKey, value) {
    return this._manager.ngettext_2(singularKey, pluralKey, value);
  };
  I18n.$metadata$ = {
    simpleName: 'I18n',
    kind: 'object',
    interfaces: [I18nManager]
  };
  var I18n_instance;
  function I18n_getInstance() {
    if (I18n_instance == null)
      new I18n();
    return I18n_instance;
  }
  function I18nManager() {
  }
  I18nManager.prototype.trans_0 = function (text_0) {
    var tmp;
    if (startsWith$default_0(text_0, '###KvI18nS###', false, 2, null)) {
      var tmp0_substring_0 = '###KvI18nS###'.length;
      tmp = this.gettext_2(text_0.substring(tmp0_substring_0));
    } else {
      if (startsWith$default_0(text_0, '###KvI18nP###', false, 2, null)) {
        var tmp1_substring_0 = '###KvI18nP###'.length;
        var tmp_0 = text_0.substring(tmp1_substring_0);
        var tmp_1 = ['###KvI18nP###'];
        var tab = split$default(tmp_0, tmp_1, false, 0, 6, null);
        var tmp_2;
        if (tab._get_size__43() === 3) {
          var tmp_3 = tab.get_44(0);
          var tmp_4 = tab.get_44(1);
          var tmp0_elvis_lhs = toIntOrNull(tab.get_44(2));
          tmp_2 = this.ngettext_2(tmp_3, tmp_4, tmp0_elvis_lhs == null ? 1 : tmp0_elvis_lhs);
        } else {
          tmp_2 = text_0;
        }
        tmp = tmp_2;
      } else {
        {
          tmp = text_0;
        }
      }
    }
    return tmp;
  };
  I18nManager.$metadata$ = {
    simpleName: 'I18nManager',
    kind: 'interface',
    interfaces: []
  };
  function SimpleI18nManager() {
  }
  SimpleI18nManager.prototype.gettext_2 = function (key) {
    return key;
  };
  SimpleI18nManager.prototype.ngettext_2 = function (singularKey, pluralKey, value) {
    return singularKey;
  };
  SimpleI18nManager.$metadata$ = {
    simpleName: 'SimpleI18nManager',
    kind: 'class',
    interfaces: [I18nManager]
  };
  function FlexPanel_init_$Init$(direction, wrap, justify, alignItems, alignContent, spacing, noWrappers, classes, init, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      direction = null;
    if (!(($mask0 & 2) === 0))
      wrap = null;
    if (!(($mask0 & 4) === 0))
      justify = null;
    if (!(($mask0 & 8) === 0))
      alignItems = null;
    if (!(($mask0 & 16) === 0))
      alignContent = null;
    if (!(($mask0 & 32) === 0))
      spacing = null;
    if (!(($mask0 & 64) === 0))
      noWrappers = false;
    if (!(($mask0 & 128) === 0)) {
      classes = emptySet();
    }if (!(($mask0 & 256) === 0))
      init = null;
    FlexPanel.call($this, direction, wrap, justify, alignItems, alignContent, spacing, noWrappers, classes, init);
    return $this;
  }
  function refreshSpacing($this) {
    var tmp0_filterIsInstance_0 = $this.getChildren_23();
    var tmp0_filterIsInstanceTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filterIsInstance_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      if (element_2_3 instanceof Widget) {
        tmp0_filterIsInstanceTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var tmp1_map_0 = tmp0_filterIsInstanceTo_0_1;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map_0, 10));
    var tmp0_iterator_1_2_0 = tmp1_map_0.iterator_38();
    while (tmp0_iterator_1_2_0.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2_0.next_13();
      tmp0_mapTo_0_1.add_57(applySpacing($this, item_2_3));
      Unit_getInstance();
    }
    Unit_getInstance();
  }
  function applySpacing($this, wrapper) {
    if (!$this._noWrappers) {
      wrapper._set_marginTop__31(null);
      wrapper._set_marginRight__31(null);
      wrapper._set_marginBottom__31(null);
      wrapper._set_marginLeft__31(null);
    }var tmp0_safe_receiver = $this._get_spacing__0();
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp0_subject_2 = $this._get_flexDirection__31();
      if (equals_0(tmp0_subject_2, FlexDirection_COLUMN_getInstance()))
        wrapper._set_marginBottom__31(_get_px_(tmp0_safe_receiver));
      else if (equals_0(tmp0_subject_2, FlexDirection_ROWREV_getInstance())) {
        if (equals_0($this._get_justifyContent__31(), JustifyContent_FLEXEND_getInstance()))
          wrapper._set_marginRight__31(_get_px_(tmp0_safe_receiver));
        else
          wrapper._set_marginLeft__31(_get_px_(tmp0_safe_receiver));
      } else if (equals_0(tmp0_subject_2, FlexDirection_COLUMNREV_getInstance()))
        wrapper._set_marginTop__31(_get_px_(tmp0_safe_receiver));
      else {
        if (equals_0($this._get_justifyContent__31(), JustifyContent_FLEXEND_getInstance()))
          wrapper._set_marginLeft__31(_get_px_(tmp0_safe_receiver));
        else
          wrapper._set_marginRight__31(_get_px_(tmp0_safe_receiver));
      }
      Unit_getInstance();
    }
    Unit_getInstance();
    return wrapper;
  }
  function _no_name_provided__209(this$0) {
    this._this$0_157 = this$0;
  }
  _no_name_provided__209.prototype.invoke_396 = function (it) {
    refreshSpacing(this._this$0_157);
    this._this$0_157.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__209.prototype.invoke_1082 = function (p1) {
    this.invoke_396((p1 == null ? true : typeof p1 === 'number') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__209.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function FlexPanel(direction, wrap, justify, alignItems, alignContent, spacing, noWrappers, classes, init) {
    SimplePanel_init_$Init$(classes, null, 2, null, this);
    this._noWrappers = noWrappers;
    var tmp = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_200(this);
    tmp._spacing$delegate = (new RefreshDelegateProvider_0(this, spacing, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, spacing$factory());
    this._set_display__31(Display_FLEX_getInstance());
    this._set_flexDirection__31(direction);
    this._set_flexWrap__31(wrap);
    this._set_justifyContent__31(justify);
    this._set_alignItems__31(alignItems);
    this._set_alignContent__31(alignContent);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  FlexPanel.prototype._set_spacing__0 = function (_set___) {
    return this._spacing$delegate.setValue_6(this, spacing$factory_0(), _set___);
  };
  FlexPanel.prototype._get_spacing__0 = function () {
    return this._spacing$delegate.getValue_3(this, spacing$factory_1());
  };
  FlexPanel.prototype.add_48 = function (child, order, grow, shrink, basis, alignSelf, classes) {
    var tmp;
    if (this._noWrappers) {
      tmp = child;
    } else {
      tmp = new WidgetWrapper(child, classes);
    }
    var wrapper = tmp;
    var tmp0_safe_receiver = wrapper instanceof Widget ? wrapper : null;
    if (tmp0_safe_receiver == null)
      null;
    else {
      applySpacing(this, tmp0_safe_receiver);
      Unit_getInstance();
      tmp0_safe_receiver._set_order__31(order);
      tmp0_safe_receiver._set_flexGrow__31(grow);
      tmp0_safe_receiver._set_flexShrink__31(shrink);
      tmp0_safe_receiver._set_flexBasis__31(basis);
      tmp0_safe_receiver._set_alignSelf__31(alignSelf);
      Unit_getInstance();
    }
    Unit_getInstance();
    this.addInternal_23(wrapper);
    Unit_getInstance();
    return this;
  };
  FlexPanel.prototype.add$default_1 = function (child, order, grow, shrink, basis, alignSelf, classes, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      order = null;
    if (!(($mask0 & 4) === 0))
      grow = null;
    if (!(($mask0 & 8) === 0))
      shrink = null;
    if (!(($mask0 & 16) === 0))
      basis = null;
    if (!(($mask0 & 32) === 0))
      alignSelf = null;
    if (!(($mask0 & 64) === 0)) {
      classes = emptySet();
    }return this.add_48(child, order, grow, shrink, basis, alignSelf, classes);
  };
  FlexPanel.prototype.add_56 = function (child) {
    return this.add$default_1(child, null, null, null, null, null, null, 124, null);
  };
  FlexPanel.prototype.addAll_42 = function (children) {
    var tmp0_iterator_1 = children.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      this.add$default_1(element_2, null, null, null, null, null, null, 124, null);
      Unit_getInstance();
    }
    return this;
  };
  FlexPanel.prototype.remove_57 = function (child) {
    if (this._get_children__1().contains_31(child)) {
      SimplePanel.prototype.remove_57.call(this, child);
      Unit_getInstance();
    } else {
      var tmp0_find_0 = this._get_children__1();
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0_iterator_1_1 = tmp0_find_0.iterator_38();
        while (tmp0_iterator_1_1.hasNext_11()) {
          var element_2_2 = tmp0_iterator_1_1.next_13();
          var tmp0_safe_receiver_4 = element_2_2 instanceof WidgetWrapper ? element_2_2 : null;
          if (equals_0(tmp0_safe_receiver_4 == null ? null : tmp0_safe_receiver_4._wrapped, child)) {
            tmp$ret$0 = element_2_2;
            break l$ret$1;
          } else {
          }
        }
        tmp$ret$0 = null;
      }
       while (false);
      var tmp0_safe_receiver = tmp$ret$0;
      if (tmp0_safe_receiver == null)
        null;
      else {
        SimplePanel.prototype.remove_57.call(this, tmp0_safe_receiver);
        Unit_getInstance();
        tmp0_safe_receiver.dispose_46();
        Unit_getInstance();
      }
      Unit_getInstance();
    }
    return this;
  };
  FlexPanel.prototype.removeAll_27 = function () {
    var tmp0_map_0 = this._get_children__1();
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      item_2_3.clearParent_31();
      Unit_getInstance();
      var tmp0_safe_receiver_5 = item_2_3 instanceof WidgetWrapper ? item_2_3 : null;
      var tmp;
      if (tmp0_safe_receiver_5 == null) {
        tmp = null;
      } else {
        tmp0_safe_receiver_5.dispose_46();
        tmp = Unit_getInstance();
      }
      tmp0_mapTo_0_1.add_57(tmp);
      Unit_getInstance();
    }
    Unit_getInstance();
    this._get_children__1().clear_27();
    this.refresh_33();
    Unit_getInstance();
    return this;
  };
  FlexPanel.prototype.disposeAll_24 = function () {
    var tmp0_map_0 = this._get_children__1();
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      var tmp0_safe_receiver_5 = item_2_3 instanceof WidgetWrapper ? item_2_3 : null;
      var tmp;
      if (tmp0_safe_receiver_5 == null) {
        tmp = null;
      } else {
        var tmp0_safe_receiver_2_6 = tmp0_safe_receiver_5._wrapped;
        var tmp_0;
        if (tmp0_safe_receiver_2_6 == null) {
          tmp_0 = null;
        } else {
          tmp0_safe_receiver_2_6.dispose_46();
          tmp_0 = Unit_getInstance();
        }
        tmp = tmp_0;
      }
      tmp0_mapTo_0_1.add_57(tmp);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this.removeAll_27();
  };
  FlexPanel.prototype.dispose_46 = function () {
    var tmp0_map_0 = this._get_children__1();
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      var tmp0_safe_receiver_5 = item_2_3 instanceof WidgetWrapper ? item_2_3 : null;
      var tmp;
      if (tmp0_safe_receiver_5 == null) {
        tmp = null;
      } else {
        var tmp0_safe_receiver_2_6 = tmp0_safe_receiver_5._wrapped;
        var tmp_0;
        if (tmp0_safe_receiver_2_6 == null) {
          tmp_0 = null;
        } else {
          tmp0_safe_receiver_2_6.dispose_46();
          tmp_0 = Unit_getInstance();
        }
        tmp = tmp_0;
      }
      tmp0_mapTo_0_1.add_57(tmp);
      Unit_getInstance();
    }
    Unit_getInstance();
    SimplePanel.prototype.dispose_46.call(this);
  };
  FlexPanel.$metadata$ = {
    simpleName: 'FlexPanel',
    kind: 'class',
    interfaces: []
  };
  function spacing$factory() {
    return getPropertyCallableRef('spacing', 1, KMutableProperty1, function (receiver) {
      return receiver._get_spacing__0();
    }, function (receiver_0, value) {
      return receiver_0._set_spacing__0(value);
    });
  }
  function spacing$factory_0() {
    return getPropertyCallableRef('spacing', 1, KMutableProperty1, function (receiver) {
      return receiver._get_spacing__0();
    }, function (receiver_0, value) {
      return receiver_0._set_spacing__0(value);
    });
  }
  function spacing$factory_1() {
    return getPropertyCallableRef('spacing', 1, KMutableProperty1, function (receiver) {
      return receiver._get_spacing__0();
    }, function (receiver_0, value) {
      return receiver_0._set_spacing__0(value);
    });
  }
  function _no_name_provided_$factory_200(this$0) {
    var i = new _no_name_provided__209(this$0);
    return function (p1) {
      i.invoke_396(p1);
      return Unit_getInstance();
    };
  }
  function HPanel(wrap, justify, alignItems, spacing, noWrappers, classes, init) {
    FlexPanel_init_$Init$(null, wrap, justify, alignItems, null, spacing, noWrappers, classes, null, 256, null, this);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  HPanel.$metadata$ = {
    simpleName: 'HPanel',
    kind: 'class',
    interfaces: []
  };
  function hPanel(_this_, wrap, justify, alignItems, spacing, noWrappers, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var hpanel = new HPanel(wrap, justify, alignItems, spacing, noWrappers, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(hpanel);
    Unit_getInstance();
    return hpanel;
  }
  function hPanel$default(_this_, wrap, justify, alignItems, spacing, noWrappers, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      wrap = null;
    if (!(($mask0 & 2) === 0))
      justify = null;
    if (!(($mask0 & 4) === 0))
      alignItems = null;
    if (!(($mask0 & 8) === 0))
      spacing = null;
    if (!(($mask0 & 16) === 0))
      noWrappers = false;
    if (!(($mask0 & 32) === 0))
      classes = null;
    if (!(($mask0 & 64) === 0))
      className = null;
    if (!(($mask0 & 128) === 0))
      init = null;
    return hPanel(_this_, wrap, justify, alignItems, spacing, noWrappers, classes, className, init);
  }
  function Root_init_$Init$(id, containerType, addRow, init, $this) {
    SimplePanel_init_$Init$(null, null, 3, null, $this);
    Root.call($this);
    $this._containerType = containerType;
    $this._addRow = addRow;
    if (!(document.getElementById(id) == null)) {
      $this._rootVnode = KVManager_getInstance().patch(id, $this.renderVNode_31());
    }$this._set_id__30(id);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver($this);
    Unit_getInstance();
    return $this;
  }
  function Root_init_$Create$(id, containerType, addRow, init) {
    return Root_init_$Init$(id, containerType, addRow, init, Object.create(Root.prototype));
  }
  function stylesVNodes($this) {
    var tmp;
    if ($this._isFirstRoot) {
      var tmp_0;
      var tmp0_isNotEmpty_0 = Companion_getInstance_10()._styles;
      if (!tmp0_isNotEmpty_0.isEmpty_33()) {
        var tmp_1 = Companion_getInstance_10()._styles;
        var stylesDesc = joinToString$default_0(tmp_1, '\n', null, null, 0, null, _no_name_provided_$factory_203(), 30, null);
        var tmp1_arrayOf_0 = [stylesDesc];
        var tmp2_arrayOf_0 = [h('style', tmp1_arrayOf_0)];
        tmp_0 = tmp2_arrayOf_0;
      } else {
        {
          var tmp3_arrayOf_0 = [];
          tmp_0 = tmp3_arrayOf_0;
        }
      }
      tmp = tmp_0;
    } else {
      var tmp4_arrayOf_0 = [];
      tmp = tmp4_arrayOf_0;
    }
    return tmp;
  }
  function modalsVNodes($this) {
    var tmp;
    if ($this._isFirstRoot) {
      var tmp0_filter_0 = Companion_getInstance_16()._modals;
      var tmp0_filterTo_0_1 = ArrayList_init_$Create$();
      var tmp0_iterator_1_2 = tmp0_filter_0.iterator_38();
      while (tmp0_iterator_1_2.hasNext_11()) {
        var element_2_3 = tmp0_iterator_1_2.next_13();
        if (element_2_3._get_visible__31()) {
          tmp0_filterTo_0_1.add_57(element_2_3);
          Unit_getInstance();
        } else {
        }
      }
      var tmp1_map_0 = tmp0_filterTo_0_1;
      var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map_0, 10));
      var tmp0_iterator_1_2_0 = tmp1_map_0.iterator_38();
      while (tmp0_iterator_1_2_0.hasNext_11()) {
        var item_2_3 = tmp0_iterator_1_2_0.next_13();
        tmp0_mapTo_0_1.add_57(item_2_3.renderVNode_31());
        Unit_getInstance();
      }
      var tmp2_toTypedArray_0 = tmp0_mapTo_0_1;
      tmp = copyToArray_0(tmp2_toTypedArray_0);
    } else {
      var tmp3_arrayOf_0 = [];
      tmp = tmp3_arrayOf_0;
    }
    return tmp;
  }
  function contextMenusVNodes($this) {
    var tmp0_filter_0 = $this._contextMenus;
    var tmp0_filterTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filter_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      if (element_2_3._get_visible__31()) {
        tmp0_filterTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var tmp1_map_0 = tmp0_filterTo_0_1;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map_0, 10));
    var tmp0_iterator_1_2_0 = tmp1_map_0.iterator_38();
    while (tmp0_iterator_1_2_0.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2_0.next_13();
      tmp0_mapTo_0_1.add_57(item_2_3.renderVNode_31());
      Unit_getInstance();
    }
    var tmp2_toTypedArray_0 = tmp0_mapTo_0_1;
    return copyToArray_0(tmp2_toTypedArray_0);
  }
  function Companion_17() {
    Companion_instance_16 = this;
    this._counter_5 = 0;
    var tmp = this;
    tmp._modals = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._roots = ArrayList_init_$Create$();
  }
  Companion_17.prototype.disposeAllRoots = function () {
    var tmp0_forEach_0 = this._roots;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2.dispose_46();
    }
    this._roots.clear_27();
  };
  Companion_17.prototype.getFirstRoot = function () {
    var tmp;
    var tmp0_isNotEmpty_0 = this._roots;
    if (!tmp0_isNotEmpty_0.isEmpty_33()) {
      tmp = this._roots.get_44(0);
    } else {
      {
        tmp = null;
      }
    }
    return tmp;
  };
  Companion_17.prototype.addModal = function (modal) {
    this._modals.add_57(modal);
    Unit_getInstance();
  };
  Companion_17.prototype.removeModal = function (modal) {
    this._modals.remove_59(modal);
    Unit_getInstance();
  };
  Companion_17.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_16;
  function Companion_getInstance_16() {
    if (Companion_instance_16 == null)
      new Companion_17();
    return Companion_instance_16;
  }
  function _no_name_provided__210() {
  }
  _no_name_provided__210.prototype.invoke_398 = function ($this$snOpt) {
    var tmp1_snClasses_0 = listOf_0(to('row', true));
    var tmp0_apply_0 = new Object();
    var tmp0_iterator_1_2 = tmp1_snClasses_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      var key_3_4_4 = element_2_3.component1_0();
      var value_4_5_5 = element_2_3.component2_0();
      tmp0_apply_0[key_3_4_4] = value_4_5_5;
    }
    $this$snOpt.class = tmp0_apply_0;
  };
  _no_name_provided__210.prototype.invoke_1082 = function (p1) {
    this.invoke_398((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__210.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__211($boundThis) {
    this._$boundThis_7 = $boundThis;
  }
  _no_name_provided__211.prototype.invoke_400 = function (p0) {
    return apply(this._$boundThis_7, p0);
  };
  _no_name_provided__211.prototype.invoke_1082 = function (p1) {
    return this.invoke_400((!(p1 == null) ? typeof p1 === 'function' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__211.prototype._get_name__47 = function () {
    return 'apply';
  };
  _no_name_provided__211.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__212() {
  }
  _no_name_provided__212.prototype.invoke_402 = function (it) {
    return it.generateStyle();
  };
  _no_name_provided__212.prototype.invoke_1082 = function (p1) {
    return this.invoke_402(p1 instanceof Style ? p1 : THROW_CCE());
  };
  _no_name_provided__212.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  Root.prototype.render_88 = function () {
    var tmp;
    if (this._addRow) {
      var tmp_0 = '' + 'div#' + this._get_id__30();
      var tmp0_snOpt_0 = _no_name_provided_$factory_201();
      var tmp_1 = _no_name_provided_$factory_202({})(tmp0_snOpt_0);
      var tmp1_plus_0 = stylesVNodes(this);
      var tmp2_plus_0 = this.childrenVNodes_23();
      var tmp3_plus_0 = tmp1_plus_0.concat(tmp2_plus_0);
      var tmp4_plus_0 = modalsVNodes(this);
      var tmp5_plus_0 = tmp3_plus_0.concat(tmp4_plus_0);
      var tmp6_plus_0 = contextMenusVNodes(this);
      var tmp7_arrayOf_0 = [h('div', tmp_1, tmp5_plus_0.concat(tmp6_plus_0))];
      tmp = this.render_87(tmp_0, tmp7_arrayOf_0);
    } else {
      var tmp_2 = '' + 'div#' + this._get_id__30();
      var tmp8_plus_0 = stylesVNodes(this);
      var tmp9_plus_0 = this.childrenVNodes_23();
      var tmp10_plus_0 = tmp8_plus_0.concat(tmp9_plus_0);
      var tmp11_plus_0 = modalsVNodes(this);
      var tmp12_plus_0 = tmp10_plus_0.concat(tmp11_plus_0);
      var tmp13_plus_0 = contextMenusVNodes(this);
      tmp = this.render_87(tmp_2, tmp12_plus_0.concat(tmp13_plus_0));
    }
    return tmp;
  };
  Root.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    if (!this._containerType.equals(ContainerType_NONE_getInstance())) {
      classSetBuilder.add_27(this._containerType._type_1);
    }};
  Root.prototype.reRender = function () {
    if (!this._renderDisabled ? !(this._rootVnode == null) : false) {
      this._rootVnode = KVManager_getInstance().patch_0(ensureNotNull(this._rootVnode), this.renderVNode_31());
    }return this;
  };
  Root.prototype.restart = function () {
    if (!(this._rootVnode == null)) {
      this._rootVnode = KVManager_getInstance().patch_0(ensureNotNull(this._rootVnode), h('div'));
      this._rootVnode = KVManager_getInstance().patch_0(ensureNotNull(this._rootVnode), this.renderVNode_31());
    }};
  Root.prototype.getRoot_31 = function () {
    return this;
  };
  Root.prototype.dispose_46 = function () {
    SimplePanel.prototype.dispose_46.call(this);
    Companion_getInstance_16()._roots.remove_59(this);
    Unit_getInstance();
    if (this._isFirstRoot) {
      Companion_getInstance_10()._styles.clear_27();
      Companion_getInstance_16()._modals.clear_27();
    }};
  function Root() {
    Companion_getInstance_16();
    var tmp = this;
    tmp._contextMenus = ArrayList_init_$Create$();
    this._rootVnode = null;
    this._renderDisabled = false;
    this._isFirstRoot = Companion_getInstance_16()._roots.isEmpty_33();
    Companion_getInstance_16()._roots.add_57(this);
    Unit_getInstance();
    if (this._isFirstRoot) {
      var tmp0_forEach_0 = Companion_getInstance_16()._modals;
      var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
      while (tmp0_iterator_1.hasNext_11()) {
        var element_2 = tmp0_iterator_1.next_13();
        element_2._set_parent__31(this);
      }
    }}
  Root.$metadata$ = {
    simpleName: 'Root',
    kind: 'class',
    interfaces: []
  };
  function root(_this_, id, containerType, addRow, init) {
    return Root_init_$Create$(id, containerType, addRow, init);
  }
  var ContainerType_NONE_instance;
  var ContainerType_FIXED_instance;
  var ContainerType_FLUID_instance;
  var ContainerType_SM_instance;
  var ContainerType_MD_instance;
  var ContainerType_LG_instance;
  var ContainerType_XL_instance;
  var ContainerType_entriesInitialized;
  function ContainerType_initEntries() {
    if (ContainerType_entriesInitialized)
      return Unit_getInstance();
    ContainerType_entriesInitialized = true;
    ContainerType_NONE_instance = new ContainerType('NONE', 0, '');
    ContainerType_FIXED_instance = new ContainerType('FIXED', 1, 'container');
    ContainerType_FLUID_instance = new ContainerType('FLUID', 2, 'container-fluid');
    ContainerType_SM_instance = new ContainerType('SM', 3, 'container-sm');
    ContainerType_MD_instance = new ContainerType('MD', 4, 'container-md');
    ContainerType_LG_instance = new ContainerType('LG', 5, 'container-lg');
    ContainerType_XL_instance = new ContainerType('XL', 6, 'container-xl');
  }
  function ContainerType(name, ordinal, type) {
    Enum.call(this, name, ordinal);
    this._type_1 = type;
  }
  ContainerType.$metadata$ = {
    simpleName: 'ContainerType',
    kind: 'class',
    interfaces: []
  };
  function ContainerType_NONE_getInstance() {
    ContainerType_initEntries();
    return ContainerType_NONE_instance;
  }
  function _no_name_provided_$factory_201() {
    var i = new _no_name_provided__210();
    return function (p1) {
      i.invoke_398(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_202($boundThis) {
    var i = new _no_name_provided__211($boundThis);
    var l = function (p1) {
      return i.invoke_400(p1);
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_203() {
    var i = new _no_name_provided__212();
    return function (p1) {
      return i.invoke_402(p1);
    };
  }
  function SimplePanel_init_$Init$(classes, init, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0)) {
      classes = emptySet();
    }if (!(($mask0 & 2) === 0))
      init = null;
    SimplePanel.call($this, classes, init);
    return $this;
  }
  function SimplePanel_init_$Create$(classes, init, $mask0, $marker) {
    return SimplePanel_init_$Init$(classes, init, $mask0, $marker, Object.create(SimplePanel.prototype));
  }
  function SimplePanel(classes, init) {
    Widget_init_$Init$(classes, null, 2, null, this);
    var tmp = this;
    tmp._privateChildren = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._children = ArrayList_init_$Create$();
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  SimplePanel.prototype._get_children__1 = function () {
    return this._children;
  };
  SimplePanel.prototype.render_88 = function () {
    return this.render_87('div', this.childrenVNodes_23());
  };
  SimplePanel.prototype.childrenVNodes_23 = function () {
    var tmp0_filter_0 = plus(this._privateChildren, this._children);
    var tmp0_filterTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filter_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      if (element_2_3._get_visible__31()) {
        tmp0_filterTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var tmp1_map_0 = tmp0_filterTo_0_1;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map_0, 10));
    var tmp0_iterator_1_2_0 = tmp1_map_0.iterator_38();
    while (tmp0_iterator_1_2_0.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2_0.next_13();
      tmp0_mapTo_0_1.add_57(item_2_3.renderVNode_31());
      Unit_getInstance();
    }
    var tmp2_toTypedArray_0 = tmp0_mapTo_0_1;
    return copyToArray_0(tmp2_toTypedArray_0);
  };
  SimplePanel.prototype.addPrivate_6 = function (child) {
    this._privateChildren.add_57(child);
    Unit_getInstance();
    var tmp0_safe_receiver = child._get_parent__31();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.remove_57(child);
    Unit_getInstance();
    child._set_parent__31(this);
    this.refresh_33();
    Unit_getInstance();
    return this;
  };
  SimplePanel.prototype.addInternal_23 = function (child) {
    this._children.add_57(child);
    Unit_getInstance();
    var tmp0_safe_receiver = child._get_parent__31();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.remove_57(child);
    Unit_getInstance();
    child._set_parent__31(this);
    this.refresh_33();
    Unit_getInstance();
    return this;
  };
  SimplePanel.prototype.add_56 = function (child) {
    return this.addInternal_23(child);
  };
  SimplePanel.prototype.addAll_42 = function (children) {
    this._children.addAll_43(children);
    Unit_getInstance();
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(children, 10));
    var tmp0_iterator_1_2 = children.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      item_2_3._set_parent__31(this);
      tmp0_mapTo_0_1.add_57(Unit_getInstance());
      Unit_getInstance();
    }
    Unit_getInstance();
    this.refresh_33();
    Unit_getInstance();
    return this;
  };
  SimplePanel.prototype.remove_57 = function (child) {
    if (this._children.remove_59(child)) {
      child.clearParent_31();
      Unit_getInstance();
      this.refresh_33();
      Unit_getInstance();
    }return this;
  };
  SimplePanel.prototype.removeAll_27 = function () {
    var tmp0_map_0 = this._children;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      tmp0_mapTo_0_1.add_57(item_2_3.clearParent_31());
      Unit_getInstance();
    }
    Unit_getInstance();
    this._children.clear_27();
    this.refresh_33();
    Unit_getInstance();
    return this;
  };
  SimplePanel.prototype.disposeAll_24 = function () {
    var tmp0_forEach_0 = this._children;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2.dispose_46();
    }
    return this.removeAll_27();
  };
  SimplePanel.prototype.getChildren_23 = function () {
    return this._children;
  };
  SimplePanel.prototype.dispose_46 = function () {
    Widget.prototype.dispose_46.call(this);
    var tmp0_forEach_0 = this._children;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2.dispose_46();
    }
    var tmp1_forEach_0 = this._privateChildren;
    var tmp0_iterator_1_0 = tmp1_forEach_0.iterator_38();
    while (tmp0_iterator_1_0.hasNext_11()) {
      var element_2_0 = tmp0_iterator_1_0.next_13();
      element_2_0.dispose_46();
    }
    var tmp2_map_0 = this._children;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp2_map_0, 10));
    var tmp0_iterator_1_2 = tmp2_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      tmp0_mapTo_0_1.add_57(item_2_3.clearParent_31());
      Unit_getInstance();
    }
    Unit_getInstance();
    this._children.clear_27();
    var tmp3_map_0 = this._privateChildren;
    var tmp0_mapTo_0_1_0 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp3_map_0, 10));
    var tmp0_iterator_1_2_0 = tmp3_map_0.iterator_38();
    while (tmp0_iterator_1_2_0.hasNext_11()) {
      var item_2_3_0 = tmp0_iterator_1_2_0.next_13();
      tmp0_mapTo_0_1_0.add_57(item_2_3_0.clearParent_31());
      Unit_getInstance();
    }
    Unit_getInstance();
    this._privateChildren.clear_27();
  };
  SimplePanel.$metadata$ = {
    simpleName: 'SimplePanel',
    kind: 'class',
    interfaces: [Container]
  };
  var routing;
  function Companion_18() {
    Companion_instance_17 = this;
  }
  Companion_18.prototype.start_4 = function () {
    routing = new Routing();
  };
  Companion_18.prototype.shutdown = function () {
    routing.destroy();
  };
  Companion_18.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_17;
  function Companion_getInstance_17() {
    if (Companion_instance_17 == null)
      new Companion_18();
    return Companion_instance_17;
  }
  function Routing() {
    Companion_getInstance_17();
    Navigo.call(this, null, true, '#!');
  }
  Routing.$metadata$ = {
    simpleName: 'Routing',
    kind: 'class',
    interfaces: []
  };
  function routing$init$() {
    var tmp0_also_0 = new Routing();
    tmp0_also_0.resolve();
    Unit_getInstance();
    return tmp0_also_0;
  }
  function ObservableState() {
  }
  ObservableState.$metadata$ = {
    simpleName: 'ObservableState',
    kind: 'interface',
    interfaces: []
  };
  function MutableState() {
  }
  MutableState.$metadata$ = {
    simpleName: 'MutableState',
    kind: 'interface',
    interfaces: [ObservableState]
  };
  function _no_name_provided__1_0(this$0, $value) {
    this._this$0_158 = this$0;
    this._$value = $value;
    ObservableProperty.call(this, $value);
  }
  _no_name_provided__1_0.prototype.afterChange_2 = function (property, oldValue, newValue) {
    var tmp0_forEach_0_10 = this._this$0_158._observers_2;
    var tmp0_iterator_1_11 = tmp0_forEach_0_10.iterator_38();
    while (tmp0_iterator_1_11.hasNext_11()) {
      var element_2_12 = tmp0_iterator_1_11.next_13();
      element_2_12(newValue);
    }
    return Unit_getInstance();
  };
  _no_name_provided__1_0.prototype.afterChange_0 = function (property, oldValue, newValue) {
    var tmp = (oldValue == null ? true : isObject(oldValue)) ? oldValue : THROW_CCE();
    return this.afterChange_2(property, tmp, (newValue == null ? true : isObject(newValue)) ? newValue : THROW_CCE());
  };
  _no_name_provided__1_0.$metadata$ = {
    simpleName: '<no name provided>_1',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__213(this$0, $observer) {
    this._this$0_159 = this$0;
    this._$observer_2 = $observer;
  }
  _no_name_provided__213.prototype.invoke_1037 = function () {
    var tmp0_this = this._this$0_159;
    var tmp0_minusAssign_0 = tmp0_this._observers_2;
    tmp0_minusAssign_0.remove_59(this._$observer_2);
    Unit_getInstance();
  };
  _no_name_provided__213.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__213.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ObservableValue(value) {
    var tmp = this;
    tmp._observers_2 = ArrayList_init_$Create$();
    var tmp_0 = this;
    var tmp0_observable_0 = Delegates_getInstance();
    tmp_0._value$delegate_2 = new _no_name_provided__1_0(this, value);
  }
  ObservableValue.prototype._set_value__12 = function (_set___) {
    return this._value$delegate_2.setValue_1(this, value$factory_8(), _set___);
  };
  ObservableValue.prototype._get_value__18 = function () {
    return this._value$delegate_2.getValue_1(this, value$factory_9());
  };
  ObservableValue.prototype.subscribe_20 = function (observer) {
    var tmp0_this = this;
    var tmp0_plusAssign_0 = tmp0_this._observers_2;
    tmp0_plusAssign_0.add_57(observer);
    Unit_getInstance();
    observer(this._get_value__18());
    return _no_name_provided_$factory_204(this, observer);
  };
  ObservableValue.prototype.subscribe_21 = function (observer) {
    return this.subscribe_20(observer);
  };
  ObservableValue.$metadata$ = {
    simpleName: 'ObservableValue',
    kind: 'class',
    interfaces: [MutableState]
  };
  function value$factory_8() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__12(value);
    });
  }
  function value$factory_9() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__12(value);
    });
  }
  function _no_name_provided_$factory_204(this$0, $observer) {
    var i = new _no_name_provided__213(this$0, $observer);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function bind(_this_, observableState, removeChildren, factory) {
    return Companion_getInstance_12().bindState(_this_, observableState, removeChildren, factory);
  }
  function Cache_init_$Init$(capacity, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      capacity = 50;
    Cache_0.call($this, capacity);
    return $this;
  }
  function Cache_init_$Create$(capacity, $mask0, $marker) {
    return Cache_init_$Init$(capacity, $mask0, $marker, Object.create(Cache_0.prototype));
  }
  function getKeyToEvict($this) {
    return $this._insertionOrder.removeAtIndex(0);
  }
  function Cache_0(capacity) {
    this._capacity = capacity;
    this._cache = HashMap_init_$Create$();
    this._insertionOrder = new LinkedList();
  }
  Cache_0.prototype.set_4 = function (key, value) {
    var evictedKey = null;
    if (this._cache._get_size__43() >= this._capacity) {
      evictedKey = getKeyToEvict(this);
      var tmp0_remove_0 = this._cache;
      var tmp1_remove_0 = evictedKey;
      (isInterface(tmp0_remove_0, MutableMap) ? tmp0_remove_0 : THROW_CCE()).remove_58(tmp1_remove_0);
      Unit_getInstance();
    }var tmp2_set_0 = this._cache;
    tmp2_set_0.put_6(key, value);
    Unit_getInstance();
    this._insertionOrder.append_5(key);
    return evictedKey;
  };
  Cache_0.prototype.get_20 = function (key) {
    return this._cache.get_20(key);
  };
  Cache_0.prototype.toString = function () {
    return this._cache.toString();
  };
  Cache_0.$metadata$ = {
    simpleName: 'Cache',
    kind: 'class',
    interfaces: []
  };
  function LinkedList() {
    this._head_1 = null;
    this._isEmpty = this._head_1 == null;
  }
  LinkedList.prototype.last_0 = function () {
    var node = this._head_1;
    var tmp;
    if (!(node == null)) {
      $l$break: while (true) {
        var tmp0_safe_receiver = node;
        if (!!((tmp0_safe_receiver == null ? null : tmp0_safe_receiver._next_2) == null)) {
          break $l$break;
        }node = node._next_2;
      }
      tmp = node;
    } else {
      tmp = null;
    }
    return tmp;
  };
  LinkedList.prototype.nodeAtIndex = function (index) {
    if (index >= 0) {
      var node = this._head_1;
      var i = index;
      while (!(node == null)) {
        if (i === 0)
          return node;
        i = i - 1 | 0;
        node = node._next_2;
      }
    }return null;
  };
  LinkedList.prototype.append_5 = function (value) {
    var newNode = new Node_0(value);
    var lastNode = this.last_0();
    if (!(lastNode == null)) {
      newNode._previous = lastNode;
      lastNode._next_2 = newNode;
    } else {
      this._head_1 = newNode;
    }
  };
  LinkedList.prototype.removeNode = function (node) {
    var prev = node._previous;
    var next = node._next_2;
    if (!(prev == null)) {
      prev._next_2 = next;
    } else {
      this._head_1 = next;
    }
    var tmp0_safe_receiver = next;
    if (tmp0_safe_receiver == null) {
      Unit_getInstance();
    } else
      tmp0_safe_receiver._previous = prev;
    node._previous = null;
    node._next_2 = null;
    return node._value_1;
  };
  LinkedList.prototype.removeAtIndex = function (index) {
    var node = this.nodeAtIndex(index);
    var tmp;
    if (!(node == null)) {
      tmp = this.removeNode(node);
    } else {
      tmp = null;
    }
    return tmp;
  };
  LinkedList.prototype.toString = function () {
    var s = '[';
    var node = this._head_1;
    while (!(node == null)) {
      s = s + ('' + node._value_1);
      node = node._next_2;
      if (!(node == null)) {
        s = s + ', ';
      }}
    return '' + s + ']';
  };
  LinkedList.$metadata$ = {
    simpleName: 'LinkedList',
    kind: 'class',
    interfaces: []
  };
  function Node_0(value) {
    this._value_1 = value;
    this._next_2 = null;
    this._previous = null;
  }
  Node_0.$metadata$ = {
    simpleName: 'Node',
    kind: 'class',
    interfaces: []
  };
  function emptyOn() {
    return {};
  }
  function on(target) {
    var obj = {};
    obj['self'] = target;
    return obj;
  }
  var auto;
  var normal;
  var hex;
  function _get_set_(_this_) {
    var tmp0_safe_receiver = _this_;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp_0 = [' '];
      tmp = split$default(tmp0_safe_receiver, tmp_0, false, 0, 6, null);
    }
    var tmp1_safe_receiver = tmp;
    var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : toSet_0(tmp1_safe_receiver);
    var tmp_1;
    if (tmp2_elvis_lhs == null) {
      tmp_1 = emptySet();
    } else {
      tmp_1 = tmp2_elvis_lhs;
    }
    return tmp_1;
  }
  function asString(_this_) {
    var tmp0_subject = _this_._second;
    return tmp0_subject.equals(UNIT_auto_getInstance()) ? 'auto' : tmp0_subject.equals(UNIT_normal_getInstance()) ? 'normal' : toString_2(_this_._first) + _this_._second._unit_0;
  }
  function _get_px_(_this_) {
    return new Pair(_this_, UNIT_px_getInstance());
  }
  function hex$init$() {
    var tmp0_arrayOf_0 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
    return tmp0_arrayOf_0;
  }
  var defaultJson;
  function _no_name_provided__214() {
  }
  _no_name_provided__214.prototype.invoke_406 = function ($this$Json) {
    $this$Json._isLenient = true;
    $this$Json._ignoreUnknownKeys = true;
    $this$Json._allowSpecialFloatingPointValues = true;
    $this$Json._useArrayPolymorphism = true;
  };
  _no_name_provided__214.prototype.invoke_1082 = function (p1) {
    this.invoke_406(p1 instanceof JsonBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__214.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_205() {
    var i = new _no_name_provided__214();
    return function (p1) {
      i.invoke_406(p1);
      return Unit_getInstance();
    };
  }
  var kVManagerReduxInit;
  function KVManagerRedux() {
    KVManagerRedux_instance = this;
    this._redux = require('redux/dist/redux.min.js');
    this._reduxThunk = require('redux-thunk/dist/redux-thunk.min.js').default;
  }
  KVManagerRedux.prototype.init_16 = function () {
  };
  KVManagerRedux.$metadata$ = {
    simpleName: 'KVManagerRedux',
    kind: 'object',
    interfaces: []
  };
  var KVManagerRedux_instance;
  function KVManagerRedux_getInstance() {
    if (KVManagerRedux_instance == null)
      new KVManagerRedux();
    return KVManagerRedux_instance;
  }
  var kVManagerBootstrapInit;
  function KVManagerBootstrap() {
    KVManagerBootstrap_instance = this;
    require('bootstrap/dist/js/bootstrap.bundle.min.js');
    require('awesome-bootstrap-checkbox');
    this._elementResizeEvent = require('element-resize-event');
  }
  KVManagerBootstrap.prototype.init_16 = function () {
  };
  KVManagerBootstrap.$metadata$ = {
    simpleName: 'KVManagerBootstrap',
    kind: 'object',
    interfaces: []
  };
  var KVManagerBootstrap_instance;
  function KVManagerBootstrap_getInstance() {
    if (KVManagerBootstrap_instance == null)
      new KVManagerBootstrap();
    return KVManagerBootstrap_instance;
  }
  function CloseIcon() {
    var tmp = emptySet();
    Widget_init_$Init$(tmp, null, 2, null, this);
  }
  CloseIcon.prototype.render_88 = function () {
    var tmp0_arrayOf_0 = [KVManager_getInstance().virtualize("<span aria-hidden='true'>&times;<\/span>")];
    return this.render_87('button', tmp0_arrayOf_0);
  };
  CloseIcon.prototype.buildClassSet_30 = function (classSetBuilder) {
    Widget.prototype.buildClassSet_30.call(this, classSetBuilder);
    classSetBuilder.add_27('close');
  };
  CloseIcon.prototype.buildAttributeSet_30 = function (attributeSetBuilder) {
    Widget.prototype.buildAttributeSet_30.call(this, attributeSetBuilder);
    attributeSetBuilder.add_24('type', 'button');
    attributeSetBuilder.add_24('aria-label', 'Close');
  };
  CloseIcon.$metadata$ = {
    simpleName: 'CloseIcon',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__215(this$0) {
    this._this$0_160 = this$0;
  }
  _no_name_provided__215.prototype.invoke_992 = function (it) {
    this._this$0_160.hide_26();
    Unit_getInstance();
  };
  _no_name_provided__215.prototype.invoke_1082 = function (p1) {
    this.invoke_992(p1 instanceof MouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__215.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__216(this$0) {
    this._this$0_161 = this$0;
  }
  _no_name_provided__216.prototype.invoke_992 = function (it) {
    this._this$0_161.hide_26();
    Unit_getInstance();
    var tmp0_safe_receiver = this._this$0_161._noCallback;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver();
    Unit_getInstance();
  };
  _no_name_provided__216.prototype.invoke_1082 = function (p1) {
    this.invoke_992(p1 instanceof MouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__216.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__217(this$0) {
    this._this$0_162 = this$0;
  }
  _no_name_provided__217.prototype.invoke_992 = function (it) {
    this._this$0_162.hide_26();
    Unit_getInstance();
    var tmp0_safe_receiver = this._this$0_162._yesCallback;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver();
    Unit_getInstance();
  };
  _no_name_provided__217.prototype.invoke_1082 = function (p1) {
    this.invoke_992(p1 instanceof MouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__217.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__218(this$0) {
    this._this$0_163 = this$0;
  }
  _no_name_provided__218.prototype.invoke_1045 = function (it) {
    this._this$0_163._yesButton.focus_32();
  };
  _no_name_provided__218.prototype.invoke_1082 = function (p1) {
    this.invoke_1045(p1 instanceof KvEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__218.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function refreshCancelButton($this) {
    if ($this._get_cancelVisible_()) {
      $this._cancelButton.show_27();
      Unit_getInstance();
      $this._get_closeIcon__0().show_27();
      Unit_getInstance();
    } else {
      $this._cancelButton.hide_26();
      Unit_getInstance();
      $this._get_closeIcon__0().hide_26();
      Unit_getInstance();
    }
  }
  function Companion_19() {
    Companion_instance_18 = this;
  }
  Companion_19.prototype.show_22 = function (caption, text_0, rich, align, size, animation, centered, cancelVisible, yesTitle, noTitle, cancelTitle, noCallback, yesCallback) {
    (new Confirm(caption, text_0, rich, align, size, animation, centered, cancelVisible, yesTitle, noTitle, cancelTitle, noCallback, yesCallback)).show_27();
    Unit_getInstance();
  };
  Companion_19.prototype.show$default = function (caption, text_0, rich, align, size, animation, centered, cancelVisible, yesTitle, noTitle, cancelTitle, noCallback, yesCallback, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      caption = null;
    if (!(($mask0 & 2) === 0))
      text_0 = null;
    if (!(($mask0 & 4) === 0))
      rich = false;
    if (!(($mask0 & 8) === 0))
      align = null;
    if (!(($mask0 & 16) === 0))
      size = null;
    if (!(($mask0 & 32) === 0))
      animation = true;
    if (!(($mask0 & 64) === 0))
      centered = false;
    if (!(($mask0 & 128) === 0))
      cancelVisible = false;
    if (!(($mask0 & 256) === 0))
      yesTitle = 'Yes';
    if (!(($mask0 & 512) === 0))
      noTitle = 'No';
    if (!(($mask0 & 1024) === 0))
      cancelTitle = 'Cancel';
    if (!(($mask0 & 2048) === 0))
      noCallback = null;
    if (!(($mask0 & 4096) === 0))
      yesCallback = null;
    return this.show_22(caption, text_0, rich, align, size, animation, centered, cancelVisible, yesTitle, noTitle, cancelTitle, noCallback, yesCallback);
  };
  Companion_19.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_18;
  function Companion_getInstance_18() {
    if (Companion_instance_18 == null)
      new Companion_19();
    return Companion_instance_18;
  }
  function _no_name_provided__219(this$0) {
    this._this$0_164 = this$0;
  }
  _no_name_provided__219.prototype.invoke_771 = function (it) {
    refreshCancelButton(this._this$0_164);
  };
  _no_name_provided__219.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__219.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__220(this$0) {
    this._this$0_165 = this$0;
  }
  _no_name_provided__220.prototype.invoke_422 = function ($this$setEventListener) {
    $this$setEventListener.click = _no_name_provided_$factory_211(this._this$0_165);
  };
  _no_name_provided__220.prototype.invoke_1082 = function (p1) {
    this.invoke_422((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__220.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__221(this$0) {
    this._this$0_166 = this$0;
  }
  _no_name_provided__221.prototype.invoke_422 = function ($this$setEventListener) {
    $this$setEventListener.click = _no_name_provided_$factory_212(this._this$0_166);
  };
  _no_name_provided__221.prototype.invoke_1082 = function (p1) {
    this.invoke_422((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__221.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__222(this$0) {
    this._this$0_167 = this$0;
  }
  _no_name_provided__222.prototype.invoke_422 = function ($this$setEventListener) {
    $this$setEventListener.click = _no_name_provided_$factory_213(this._this$0_167);
  };
  _no_name_provided__222.prototype.invoke_1082 = function (p1) {
    this.invoke_422((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__222.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__223(this$0) {
    this._this$0_168 = this$0;
  }
  _no_name_provided__223.prototype.invoke_424 = function ($this$onEvent) {
    $this$onEvent.shownBsModal = _no_name_provided_$factory_214(this._this$0_168);
  };
  _no_name_provided__223.prototype.invoke_1082 = function (p1) {
    this.invoke_424((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__223.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Confirm(caption, text_0, rich, align, size, animation, centered, cancelVisible, yesTitle, noTitle, cancelTitle, noCallback, yesCallback) {
    Companion_getInstance_18();
    Modal_init_$Init$(caption, false, size, animation, centered, false, false, null, null, 448, null, this);
    this._noCallback = noCallback;
    this._yesCallback = yesCallback;
    var tmp = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_206(this);
    tmp._cancelVisible$delegate = (new RefreshDelegateProvider_0(this, cancelVisible, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, cancelVisible$factory());
    var tmp_0 = this;
    var tmp_1 = TAG_DIV_getInstance();
    tmp_0._contentTag = Tag_init_$Create$(tmp_1, text_0, rich, align, null, null, null, 112, null);
    var tmp_2 = this;
    tmp_2._cancelButton = Button_init_$Create$(cancelTitle, 'fas fa-times', null, null, false, null, false, null, 252, null);
    var tmp_3 = this;
    tmp_3._noButton = Button_init_$Create$(noTitle, 'fas fa-ban', null, null, false, null, false, null, 252, null);
    var tmp_4 = this;
    var tmp_5 = ButtonStyle_PRIMARY_getInstance();
    tmp_4._yesButton = Button_init_$Create$(yesTitle, 'fas fa-check', tmp_5, null, false, null, false, null, 248, null);
    this._get_body__0().add_56(this._contentTag);
    Unit_getInstance();
    this._cancelButton.setEventListener_30(_no_name_provided_$factory_207(this));
    Unit_getInstance();
    this.addButton_0(this._cancelButton);
    Unit_getInstance();
    this._noButton.setEventListener_30(_no_name_provided_$factory_208(this));
    Unit_getInstance();
    this.addButton_0(this._noButton);
    Unit_getInstance();
    this._yesButton.setEventListener_30(_no_name_provided_$factory_209(this));
    Unit_getInstance();
    this.addButton_0(this._yesButton);
    Unit_getInstance();
    refreshCancelButton(this);
    var tmp0_onEvent_0 = _no_name_provided_$factory_210(this);
    this.setEventListener_30(tmp0_onEvent_0);
    Unit_getInstance();
  }
  Confirm.prototype._set_cancelVisible_ = function (_set___) {
    return this._cancelVisible$delegate.setValue_6(this, cancelVisible$factory_0(), _set___);
  };
  Confirm.prototype._get_cancelVisible_ = function () {
    return this._cancelVisible$delegate.getValue_3(this, cancelVisible$factory_1());
  };
  Confirm.$metadata$ = {
    simpleName: 'Confirm',
    kind: 'class',
    interfaces: []
  };
  function cancelVisible$factory() {
    return getPropertyCallableRef('cancelVisible', 1, KMutableProperty1, function (receiver) {
      return receiver._get_cancelVisible_();
    }, function (receiver_0, value) {
      return receiver_0._set_cancelVisible_(value);
    });
  }
  function cancelVisible$factory_0() {
    return getPropertyCallableRef('cancelVisible', 1, KMutableProperty1, function (receiver) {
      return receiver._get_cancelVisible_();
    }, function (receiver_0, value) {
      return receiver_0._set_cancelVisible_(value);
    });
  }
  function cancelVisible$factory_1() {
    return getPropertyCallableRef('cancelVisible', 1, KMutableProperty1, function (receiver) {
      return receiver._get_cancelVisible_();
    }, function (receiver_0, value) {
      return receiver_0._set_cancelVisible_(value);
    });
  }
  function _no_name_provided_$factory_206(this$0) {
    var i = new _no_name_provided__219(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_207(this$0) {
    var i = new _no_name_provided__220(this$0);
    return function (p1) {
      i.invoke_422(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_208(this$0) {
    var i = new _no_name_provided__221(this$0);
    return function (p1) {
      i.invoke_422(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_209(this$0) {
    var i = new _no_name_provided__222(this$0);
    return function (p1) {
      i.invoke_422(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_210(this$0) {
    var i = new _no_name_provided__223(this$0);
    return function (p1) {
      i.invoke_424(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_211(this$0) {
    var i = new _no_name_provided__215(this$0);
    return function (p1) {
      i.invoke_992(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_212(this$0) {
    var i = new _no_name_provided__216(this$0);
    return function (p1) {
      i.invoke_992(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_213(this$0) {
    var i = new _no_name_provided__217(this$0);
    return function (p1) {
      i.invoke_992(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_214(this$0) {
    var i = new _no_name_provided__218(this$0);
    return function (p1) {
      i.invoke_1045(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__224(this$0) {
    this._this$0_169 = this$0;
  }
  _no_name_provided__224.prototype.invoke_992 = function (it) {
    this._this$0_169.hide_26();
    Unit_getInstance();
  };
  _no_name_provided__224.prototype.invoke_1082 = function (p1) {
    this.invoke_992(p1 instanceof MouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__224.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Modal_init_$Init$(caption, closeButton, size, animation, centered, scrollable, escape, classes, init, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      caption = null;
    if (!(($mask0 & 2) === 0))
      closeButton = true;
    if (!(($mask0 & 4) === 0))
      size = null;
    if (!(($mask0 & 8) === 0))
      animation = true;
    if (!(($mask0 & 16) === 0))
      centered = false;
    if (!(($mask0 & 32) === 0))
      scrollable = false;
    if (!(($mask0 & 64) === 0))
      escape = true;
    if (!(($mask0 & 128) === 0)) {
      classes = emptySet();
    }if (!(($mask0 & 256) === 0))
      init = null;
    Modal.call($this, caption, closeButton, size, animation, centered, scrollable, escape, classes, init);
    return $this;
  }
  function Modal_init_$Create$(caption, closeButton, size, animation, centered, scrollable, escape, classes, init, $mask0, $marker) {
    return Modal_init_$Init$(caption, closeButton, size, animation, centered, scrollable, escape, classes, init, $mask0, $marker, Object.create(Modal.prototype));
  }
  function checkHeaderVisibility($this) {
    if (!$this._get_closeButton__0() ? $this._get_caption__0() == null : false) {
      $this._header.hide_26();
      Unit_getInstance();
    } else {
      $this._header.show_27();
      Unit_getInstance();
    }
  }
  function hideInternal($this) {
    var tmp0_safe_receiver = $this.getElementJQueryD_30();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.modal('hide');
  }
  function _no_name_provided__225(this$0) {
    this._this$0_170 = this$0;
  }
  _no_name_provided__225.prototype.invoke_771 = function (it) {
    this._this$0_170.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__225.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__225.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__226(this$0) {
    this._this$0_171 = this$0;
  }
  _no_name_provided__226.prototype.invoke_430 = function ($this$setEventListener) {
    $this$setEventListener.click = _no_name_provided_$factory_221(this._this$0_171);
  };
  _no_name_provided__226.prototype.invoke_1082 = function (p1) {
    this.invoke_430((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__226.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__227(this$0) {
    this._this$0_172 = this$0;
  }
  _no_name_provided__227.prototype.invoke_488 = function (e, _anonymous_parameter_1_) {
    var tmp0_apply_0_1 = new Object();
    tmp0_apply_0_1.detail = e;
    return this._this$0_172.dispatchEvent_4('showBsModal', tmp0_apply_0_1);
  };
  _no_name_provided__227.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_488(tmp, isObject(p2) ? p2 : THROW_CCE());
  };
  _no_name_provided__227.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__228(this$0) {
    this._this$0_173 = this$0;
  }
  _no_name_provided__228.prototype.invoke_488 = function (e, _anonymous_parameter_1_) {
    var tmp0_apply_0_1 = new Object();
    tmp0_apply_0_1.detail = e;
    return this._this$0_173.dispatchEvent_4('shownBsModal', tmp0_apply_0_1);
  };
  _no_name_provided__228.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_488(tmp, isObject(p2) ? p2 : THROW_CCE());
  };
  _no_name_provided__228.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__229(this$0) {
    this._this$0_174 = this$0;
  }
  _no_name_provided__229.prototype.invoke_488 = function (e, _anonymous_parameter_1_) {
    var tmp0_apply_0_1 = new Object();
    tmp0_apply_0_1.detail = e;
    return this._this$0_174.dispatchEvent_4('hideBsModal', tmp0_apply_0_1);
  };
  _no_name_provided__229.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_488(tmp, isObject(p2) ? p2 : THROW_CCE());
  };
  _no_name_provided__229.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__230(this$0) {
    this._this$0_175 = this$0;
  }
  _no_name_provided__230.prototype.invoke_488 = function (e, _anonymous_parameter_1_) {
    this._this$0_175._set_visible__31(false);
    this._this$0_175.hide_26();
    Unit_getInstance();
    var tmp0_apply_0_1 = new Object();
    tmp0_apply_0_1.detail = e;
    return this._this$0_175.dispatchEvent_4('hiddenBsModal', tmp0_apply_0_1);
  };
  _no_name_provided__230.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_488(tmp, isObject(p2) ? p2 : THROW_CCE());
  };
  _no_name_provided__230.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Modal(caption, closeButton, size, animation, centered, scrollable, escape, classes, init) {
    SimplePanel_init_$Init$(classes, null, 2, null, this);
    this._escape = escape;
    this._parent_0 = Companion_getInstance_16().getFirstRoot();
    var tmp = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_215(this);
    tmp._animation$delegate = (new RefreshDelegateProvider_0(this, animation, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, animation$factory());
    this._dialog = new ModalDialog(size, centered, scrollable);
    var tmp_0 = this;
    var tmp_1 = setOf('modal-header');
    tmp_0._header = SimplePanel_init_$Create$(tmp_1, null, 2, null);
    this._closeIcon = new CloseIcon();
    var tmp_2 = this;
    var tmp_3 = TAG_H5_getInstance();
    var tmp_4 = setOf('modal-title');
    tmp_2._captionTag = Tag_init_$Create$(tmp_3, caption, false, null, tmp_4, null, null, 108, null);
    var tmp_5 = this;
    var tmp_6 = setOf('modal-body');
    tmp_5._body = SimplePanel_init_$Create$(tmp_6, null, 2, null);
    var tmp_7 = this;
    var tmp_8 = setOf('modal-footer');
    tmp_7._footer = SimplePanel_init_$Create$(tmp_8, null, 2, null);
    this.hide_26();
    Unit_getInstance();
    this._set_role__30('dialog');
    this._set_tabindex__30(-1);
    this.addPrivate_6(this._dialog);
    Unit_getInstance();
    var tmp_9 = setOf('modal-content');
    var content = SimplePanel_init_$Create$(tmp_9, null, 2, null);
    this._dialog._set_role__30('document');
    this._dialog.add_56(content);
    Unit_getInstance();
    this._closeIcon._set_visible__31(closeButton);
    this._closeIcon.setEventListener_30(_no_name_provided_$factory_216(this));
    Unit_getInstance();
    this._header.add_56(this._captionTag);
    Unit_getInstance();
    this._header.add_56(this._closeIcon);
    Unit_getInstance();
    checkHeaderVisibility(this);
    content.add_56(this._header);
    Unit_getInstance();
    content.add_56(this._body);
    Unit_getInstance();
    content.add_56(this._footer);
    Unit_getInstance();
    Companion_getInstance_16().addModal(this);
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  Modal.prototype._set_parent__31 = function (_set___) {
    this._parent_0 = _set___;
  };
  Modal.prototype._get_parent__31 = function () {
    return this._parent_0;
  };
  Modal.prototype._get_caption__0 = function () {
    return this._captionTag._get_content__8();
  };
  Modal.prototype._get_closeButton__0 = function () {
    return this._closeIcon._get_visible__31();
  };
  Modal.prototype._set_animation__0 = function (_set___) {
    return this._animation$delegate.setValue_6(this, animation$factory_0(), _set___);
  };
  Modal.prototype._get_animation__0 = function () {
    return this._animation$delegate.getValue_3(this, animation$factory_1());
  };
  Modal.prototype._get_closeIcon__0 = function () {
    return this._closeIcon;
  };
  Modal.prototype._get_body__0 = function () {
    return this._body;
  };
  Modal.prototype.add_56 = function (child) {
    this._body.add_56(child);
    Unit_getInstance();
    return this;
  };
  Modal.prototype.addAll_42 = function (children) {
    this._body.addAll_42(children);
    Unit_getInstance();
    return this;
  };
  Modal.prototype.remove_57 = function (child) {
    this._body.remove_57(child);
    Unit_getInstance();
    return this;
  };
  Modal.prototype.removeAll_27 = function () {
    this._body.removeAll_27();
    Unit_getInstance();
    return this;
  };
  Modal.prototype.disposeAll_24 = function () {
    this._body.disposeAll_24();
    Unit_getInstance();
    return this;
  };
  Modal.prototype.getChildren_23 = function () {
    return this._body.getChildren_23();
  };
  Modal.prototype.addButton_0 = function (button_0) {
    this._footer.add_56(button_0);
    Unit_getInstance();
    return this;
  };
  Modal.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    classSetBuilder.add_27('modal');
    if (this._get_animation__0()) {
      classSetBuilder.add_27('fade');
    }};
  Modal.prototype.afterInsert_30 = function (node) {
    var tmp0_safe_receiver = this.getElementJQueryD_30();
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp0_apply_0_1 = new Object();
      tmp0_apply_0_1.keyboard = this._escape;
      tmp0_apply_0_1.backdrop = this._escape ? 'true' : 'static';
      tmp0_safe_receiver.modal(tmp0_apply_0_1);
    }
    var tmp1_safe_receiver = this.getElementJQuery_31();
    if (tmp1_safe_receiver == null)
      null;
    else {
      tmp1_safe_receiver.on('show.bs.modal', _no_name_provided_$factory_217(this));
    }
    Unit_getInstance();
    var tmp2_safe_receiver = this.getElementJQuery_31();
    if (tmp2_safe_receiver == null)
      null;
    else {
      tmp2_safe_receiver.on('shown.bs.modal', _no_name_provided_$factory_218(this));
    }
    Unit_getInstance();
    var tmp3_safe_receiver = this.getElementJQuery_31();
    if (tmp3_safe_receiver == null)
      null;
    else {
      tmp3_safe_receiver.on('hide.bs.modal', _no_name_provided_$factory_219(this));
    }
    Unit_getInstance();
    var tmp4_safe_receiver = this.getElementJQuery_31();
    if (tmp4_safe_receiver == null)
      null;
    else {
      tmp4_safe_receiver.on('hidden.bs.modal', _no_name_provided_$factory_220(this));
    }
    Unit_getInstance();
  };
  Modal.prototype.hide_26 = function () {
    if (this._get_visible__31())
      hideInternal(this);
    return SimplePanel.prototype.hide_26.call(this);
  };
  Modal.prototype.clearParent_31 = function () {
    this._set_parent__31(null);
    return this;
  };
  Modal.prototype.getRoot_31 = function () {
    var tmp0_safe_receiver = this._get_parent__31();
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.getRoot_31();
  };
  Modal.prototype.dispose_46 = function () {
    SimplePanel.prototype.dispose_46.call(this);
    Companion_getInstance_16().removeModal(this);
  };
  Modal.$metadata$ = {
    simpleName: 'Modal',
    kind: 'class',
    interfaces: []
  };
  var ModalSize_XLARGE_instance;
  var ModalSize_LARGE_instance;
  var ModalSize_SMALL_instance;
  var ModalSize_entriesInitialized;
  function ModalSize_initEntries() {
    if (ModalSize_entriesInitialized)
      return Unit_getInstance();
    ModalSize_entriesInitialized = true;
    ModalSize_XLARGE_instance = new ModalSize('XLARGE', 0, 'modal-xl');
    ModalSize_LARGE_instance = new ModalSize('LARGE', 1, 'modal-lg');
    ModalSize_SMALL_instance = new ModalSize('SMALL', 2, 'modal-sm');
  }
  function ModalSize(name, ordinal, className) {
    Enum.call(this, name, ordinal);
    this._className_2 = className;
  }
  ModalSize.prototype._get_className__3 = function () {
    return this._className_2;
  };
  ModalSize.$metadata$ = {
    simpleName: 'ModalSize',
    kind: 'class',
    interfaces: [CssClass]
  };
  function _no_name_provided__231(this$0) {
    this._this$0_176 = this$0;
  }
  _no_name_provided__231.prototype.invoke_440 = function (it) {
    this._this$0_176.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__231.prototype.invoke_1082 = function (p1) {
    this.invoke_440((p1 == null ? true : p1 instanceof ModalSize) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__231.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__232(this$0) {
    this._this$0_177 = this$0;
  }
  _no_name_provided__232.prototype.invoke_771 = function (it) {
    this._this$0_177.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__232.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__232.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__233(this$0) {
    this._this$0_178 = this$0;
  }
  _no_name_provided__233.prototype.invoke_771 = function (it) {
    this._this$0_178.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__233.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__233.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ModalDialog(size, centered, scrollable) {
    var tmp = setOf('modal-dialog');
    SimplePanel_init_$Init$(tmp, null, 2, null, this);
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_222(this);
    tmp_0._size$delegate_2 = (new RefreshDelegateProvider_0(this, size, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, size$factory_8());
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_223(this);
    tmp_1._centered$delegate = (new RefreshDelegateProvider_0(this, centered, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, centered$factory());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_1 = _no_name_provided_$factory_224(this);
    tmp_2._scrollable$delegate = (new RefreshDelegateProvider_0(this, scrollable, tmp0_refreshOnUpdate_0_1)).provideDelegate_0(this, scrollable$factory());
  }
  ModalDialog.prototype._set_size__4 = function (_set___) {
    return this._size$delegate_2.setValue_6(this, size$factory_9(), _set___);
  };
  ModalDialog.prototype._get_size__43 = function () {
    return this._size$delegate_2.getValue_3(this, size$factory_10());
  };
  ModalDialog.prototype._set_centered_ = function (_set___) {
    return this._centered$delegate.setValue_6(this, centered$factory_0(), _set___);
  };
  ModalDialog.prototype._get_centered_ = function () {
    return this._centered$delegate.getValue_3(this, centered$factory_1());
  };
  ModalDialog.prototype._set_scrollable_ = function (_set___) {
    return this._scrollable$delegate.setValue_6(this, scrollable$factory_0(), _set___);
  };
  ModalDialog.prototype._get_scrollable_ = function () {
    return this._scrollable$delegate.getValue_3(this, scrollable$factory_1());
  };
  ModalDialog.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    classSetBuilder.add_28(this._get_size__43());
    if (this._get_centered_()) {
      classSetBuilder.add_27('modal-dialog-centered');
    }if (this._get_scrollable_()) {
      classSetBuilder.add_27('modal-dialog-scrollable');
    }};
  ModalDialog.$metadata$ = {
    simpleName: 'ModalDialog',
    kind: 'class',
    interfaces: []
  };
  function ModalSize_SMALL_getInstance() {
    ModalSize_initEntries();
    return ModalSize_SMALL_instance;
  }
  function animation$factory() {
    return getPropertyCallableRef('animation', 1, KMutableProperty1, function (receiver) {
      return receiver._get_animation__0();
    }, function (receiver_0, value) {
      return receiver_0._set_animation__0(value);
    });
  }
  function animation$factory_0() {
    return getPropertyCallableRef('animation', 1, KMutableProperty1, function (receiver) {
      return receiver._get_animation__0();
    }, function (receiver_0, value) {
      return receiver_0._set_animation__0(value);
    });
  }
  function animation$factory_1() {
    return getPropertyCallableRef('animation', 1, KMutableProperty1, function (receiver) {
      return receiver._get_animation__0();
    }, function (receiver_0, value) {
      return receiver_0._set_animation__0(value);
    });
  }
  function size$factory_8() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__4(value);
    });
  }
  function centered$factory() {
    return getPropertyCallableRef('centered', 1, KMutableProperty1, function (receiver) {
      return receiver._get_centered_();
    }, function (receiver_0, value) {
      return receiver_0._set_centered_(value);
    });
  }
  function scrollable$factory() {
    return getPropertyCallableRef('scrollable', 1, KMutableProperty1, function (receiver) {
      return receiver._get_scrollable_();
    }, function (receiver_0, value) {
      return receiver_0._set_scrollable_(value);
    });
  }
  function size$factory_9() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__4(value);
    });
  }
  function size$factory_10() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__4(value);
    });
  }
  function centered$factory_0() {
    return getPropertyCallableRef('centered', 1, KMutableProperty1, function (receiver) {
      return receiver._get_centered_();
    }, function (receiver_0, value) {
      return receiver_0._set_centered_(value);
    });
  }
  function centered$factory_1() {
    return getPropertyCallableRef('centered', 1, KMutableProperty1, function (receiver) {
      return receiver._get_centered_();
    }, function (receiver_0, value) {
      return receiver_0._set_centered_(value);
    });
  }
  function scrollable$factory_0() {
    return getPropertyCallableRef('scrollable', 1, KMutableProperty1, function (receiver) {
      return receiver._get_scrollable_();
    }, function (receiver_0, value) {
      return receiver_0._set_scrollable_(value);
    });
  }
  function scrollable$factory_1() {
    return getPropertyCallableRef('scrollable', 1, KMutableProperty1, function (receiver) {
      return receiver._get_scrollable_();
    }, function (receiver_0, value) {
      return receiver_0._set_scrollable_(value);
    });
  }
  function _no_name_provided_$factory_215(this$0) {
    var i = new _no_name_provided__225(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_216(this$0) {
    var i = new _no_name_provided__226(this$0);
    return function (p1) {
      i.invoke_430(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_217(this$0) {
    var i = new _no_name_provided__227(this$0);
    return function (p1, p2) {
      return i.invoke_488(p1, p2);
    };
  }
  function _no_name_provided_$factory_218(this$0) {
    var i = new _no_name_provided__228(this$0);
    return function (p1, p2) {
      return i.invoke_488(p1, p2);
    };
  }
  function _no_name_provided_$factory_219(this$0) {
    var i = new _no_name_provided__229(this$0);
    return function (p1, p2) {
      return i.invoke_488(p1, p2);
    };
  }
  function _no_name_provided_$factory_220(this$0) {
    var i = new _no_name_provided__230(this$0);
    return function (p1, p2) {
      return i.invoke_488(p1, p2);
    };
  }
  function _no_name_provided_$factory_221(this$0) {
    var i = new _no_name_provided__224(this$0);
    return function (p1) {
      i.invoke_992(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_222(this$0) {
    var i = new _no_name_provided__231(this$0);
    return function (p1) {
      i.invoke_440(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_223(this$0) {
    var i = new _no_name_provided__232(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_224(this$0) {
    var i = new _no_name_provided__233(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__234(this$0) {
    this._this$0_179 = this$0;
  }
  _no_name_provided__234.prototype.invoke_446 = function (it) {
    this._this$0_179.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__234.prototype.invoke_1082 = function (p1) {
    this.invoke_446((p1 == null ? true : p1 instanceof ButtonGroupSize) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__234.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__235(this$0) {
    this._this$0_180 = this$0;
  }
  _no_name_provided__235.prototype.invoke_771 = function (it) {
    this._this$0_180.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__235.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__235.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ButtonGroup(size, vertical, classes, init) {
    SimplePanel_init_$Init$(classes, null, 2, null, this);
    var tmp = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_225(this);
    tmp._size$delegate_3 = (new RefreshDelegateProvider_0(this, size, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, size$factory_11());
    var tmp_0 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_226(this);
    tmp_0._vertical$delegate = (new RefreshDelegateProvider_0(this, vertical, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, vertical$factory());
    this._set_role__30('group');
    var tmp0_safe_receiver = init;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this);
    Unit_getInstance();
  }
  ButtonGroup.prototype._set_size__5 = function (_set___) {
    return this._size$delegate_3.setValue_6(this, size$factory_12(), _set___);
  };
  ButtonGroup.prototype._get_size__43 = function () {
    return this._size$delegate_3.getValue_3(this, size$factory_13());
  };
  ButtonGroup.prototype._set_vertical_ = function (_set___) {
    return this._vertical$delegate.setValue_6(this, vertical$factory_0(), _set___);
  };
  ButtonGroup.prototype._get_vertical_ = function () {
    return this._vertical$delegate.getValue_3(this, vertical$factory_1());
  };
  ButtonGroup.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    classSetBuilder.add_27(this._get_vertical_() ? 'btn-group-vertical' : 'btn-group');
    classSetBuilder.add_28(this._get_size__43());
  };
  ButtonGroup.$metadata$ = {
    simpleName: 'ButtonGroup',
    kind: 'class',
    interfaces: []
  };
  function buttonGroup(_this_, size, vertical, classes, className, init) {
    var tmp0_elvis_lhs = classes;
    var group = new ButtonGroup(size, vertical, tmp0_elvis_lhs == null ? _get_set_(className) : tmp0_elvis_lhs, init);
    _this_.add_56(group);
    Unit_getInstance();
    return group;
  }
  function buttonGroup$default(_this_, size, vertical, classes, className, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      size = null;
    if (!(($mask0 & 2) === 0))
      vertical = false;
    if (!(($mask0 & 4) === 0))
      classes = null;
    if (!(($mask0 & 8) === 0))
      className = null;
    if (!(($mask0 & 16) === 0))
      init = null;
    return buttonGroup(_this_, size, vertical, classes, className, init);
  }
  function ButtonGroupSize() {
  }
  ButtonGroupSize.$metadata$ = {
    simpleName: 'ButtonGroupSize',
    kind: 'class',
    interfaces: [CssClass]
  };
  function size$factory_11() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__5(value);
    });
  }
  function vertical$factory() {
    return getPropertyCallableRef('vertical', 1, KMutableProperty1, function (receiver) {
      return receiver._get_vertical_();
    }, function (receiver_0, value) {
      return receiver_0._set_vertical_(value);
    });
  }
  function size$factory_12() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__5(value);
    });
  }
  function size$factory_13() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__5(value);
    });
  }
  function vertical$factory_0() {
    return getPropertyCallableRef('vertical', 1, KMutableProperty1, function (receiver) {
      return receiver._get_vertical_();
    }, function (receiver_0, value) {
      return receiver_0._set_vertical_(value);
    });
  }
  function vertical$factory_1() {
    return getPropertyCallableRef('vertical', 1, KMutableProperty1, function (receiver) {
      return receiver._get_vertical_();
    }, function (receiver_0, value) {
      return receiver_0._set_vertical_(value);
    });
  }
  function _no_name_provided_$factory_225(this$0) {
    var i = new _no_name_provided__234(this$0);
    return function (p1) {
      i.invoke_446(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_226(this$0) {
    var i = new _no_name_provided__235(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  var kVManagerBootstrapCssInit;
  function KVManagerBootstrapCss() {
    KVManagerBootstrapCss_instance = this;
    require('bootstrap/dist/css/bootstrap.min.css');
  }
  KVManagerBootstrapCss.prototype.init_16 = function () {
  };
  KVManagerBootstrapCss.$metadata$ = {
    simpleName: 'KVManagerBootstrapCss',
    kind: 'object',
    interfaces: []
  };
  var KVManagerBootstrapCss_instance;
  function KVManagerBootstrapCss_getInstance() {
    if (KVManagerBootstrapCss_instance == null)
      new KVManagerBootstrapCss();
    return KVManagerBootstrapCss_instance;
  }
  var kVManagerDatetimeInit;
  function KVManagerDatetime() {
    KVManagerDatetime_instance = this;
    require('pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.min.css');
    require('pc-bootstrap4-datetimepicker/build/js/bootstrap-datetimepicker.min.js');
  }
  KVManagerDatetime.prototype.init_16 = function () {
  };
  KVManagerDatetime.$metadata$ = {
    simpleName: 'KVManagerDatetime',
    kind: 'object',
    interfaces: []
  };
  var KVManagerDatetime_instance;
  function KVManagerDatetime_getInstance() {
    if (KVManagerDatetime_instance == null)
      new KVManagerDatetime();
    return KVManagerDatetime_instance;
  }
  var kVManagerSelectInit;
  function KVManagerSelect() {
    KVManagerSelect_instance = this;
    this._AJAX_REQUEST_DELAY = 300;
    this._KVNULL = '#kvnull';
    if ($.fn.dropdown === undefined) {
      $.fn.dropdown = {Constructor: {VERSION: '4.0.0'}};
    }require('bootstrap-select/dist/css/bootstrap-select.min.css');
    require('bootstrap-select/dist/js/bootstrap-select.min.js');
    require('kvision-assets/js/locales/bootstrap-select/bootstrap-select-i18n.min.js');
    require('ajax-bootstrap-select/dist/css/ajax-bootstrap-select.min.css');
    require('ajax-bootstrap-select/dist/js/ajax-bootstrap-select.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.cs-CZ.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.de-DE.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.es-ES.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.fr-FR.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.it-IT.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.ja-JP.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.ko-KR.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.nl-NL.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.pl-PL.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.pt-BR.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.ru-RU.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.sr-SP.min.js');
    require('kvision-assets/js/locales/ajax-bootstrap-select/ajax-bootstrap-select.tr-TR.min.js');
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';
    $.fn.selectpicker.Constructor.DEFAULTS.styleBase = 'form-control';
    $.fn.selectpicker.Constructor.DEFAULTS.style = '';
  }
  KVManagerSelect.prototype.init_16 = function () {
  };
  KVManagerSelect.$metadata$ = {
    simpleName: 'KVManagerSelect',
    kind: 'object',
    interfaces: []
  };
  var KVManagerSelect_instance;
  function KVManagerSelect_getInstance() {
    if (KVManagerSelect_instance == null)
      new KVManagerSelect();
    return KVManagerSelect_instance;
  }
  var kVManagerSpinnerInit;
  function KVManagerSpinner() {
    KVManagerSpinner_instance = this;
    require('bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css');
    require('bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.js');
  }
  KVManagerSpinner.prototype.init_16 = function () {
  };
  KVManagerSpinner.$metadata$ = {
    simpleName: 'KVManagerSpinner',
    kind: 'object',
    interfaces: []
  };
  var KVManagerSpinner_instance;
  function KVManagerSpinner_getInstance() {
    if (KVManagerSpinner_instance == null)
      new KVManagerSpinner();
    return KVManagerSpinner_instance;
  }
  function Companion_20() {
    Companion_instance_19 = this;
    this._counter_6 = 0;
  }
  Companion_20.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_19;
  function Companion_getInstance_19() {
    if (Companion_instance_19 == null)
      new Companion_20();
    return Companion_instance_19;
  }
  function Spinner(value, name, min, max, step, decimals, buttonsType, forceType, buttonStyle, label, rich, init) {
    Companion_getInstance_19();
    var tmp = setOf('form-group');
    SimplePanel_init_$Init$(tmp, null, 2, null, this);
    this._buttonsType = buttonsType;
    this._idc_2 = '' + 'kv_form_spinner_' + Companion_getInstance_19()._counter_6;
    var tmp_0 = this;
    var tmp0_apply_0 = SpinnerInput_init_$Create$(value, min, max, step, decimals, this._buttonsType, forceType, buttonStyle, null, null, 768, null);
    tmp0_apply_0._set_id__30(this._idc_2);
    tmp0_apply_0._set_name__6(name);
    tmp_0._input_3 = tmp0_apply_0;
    var tmp_1 = this;
    var tmp_2 = setOf('control-label');
    tmp_1._flabel_2 = FieldLabel_init_$Create$(this._idc_2, label, rich, tmp_2, null, 16, null);
    var tmp_3 = this;
    var tmp0_apply_0_0 = InvalidFeedback_init_$Create$(null, false, 3, null);
    tmp0_apply_0_0._set_visible__31(false);
    tmp_3._invalidFeedback_2 = tmp0_apply_0_0;
    this._input_3._set_eventTarget__3(this);
    this.addPrivate_6(this._flabel_2);
    Unit_getInstance();
    this.addPrivate_6(this._input_3);
    Unit_getInstance();
    this.addPrivate_6(this._invalidFeedback_2);
    Unit_getInstance();
    var tmp0_this = Companion_getInstance_19();
    var tmp1 = tmp0_this._counter_6;
    tmp0_this._counter_6 = tmp1 + 1 | 0;
    Unit_getInstance();
    var tmp2_safe_receiver = init;
    if (tmp2_safe_receiver == null)
      null;
    else
      tmp2_safe_receiver(this);
    Unit_getInstance();
  }
  Spinner.prototype._set_value__9 = function (value) {
    this._input_3._set_value__9(value);
  };
  Spinner.prototype._set_min__0 = function (value) {
    this._input_3._set_min__0(value);
  };
  Spinner.prototype._set_max__0 = function (value) {
    this._input_3._set_max__0(value);
  };
  Spinner.prototype._set_step__0 = function (value) {
    this._input_3._set_step__0(value);
  };
  Spinner.prototype._set_label__2 = function (value) {
    this._flabel_2._set_content__9(value);
  };
  Spinner.prototype._set_validatorError__6 = function (value) {
    FormControl.prototype._set_validatorError__6.call(this, value);
    if (!(value == null)) {
      this._input_3.addSurroundingCssClass_0('is-invalid');
      Unit_getInstance();
    } else {
      this._input_3.removeSurroundingCssClass_0('is-invalid');
      Unit_getInstance();
    }
  };
  Spinner.prototype._get_validatorError__6 = function () {
    return FormControl.prototype._get_validatorError__6.call(this);
  };
  Spinner.prototype._get_input__4 = function () {
    return this._input_3;
  };
  Spinner.prototype._get_invalidFeedback__4 = function () {
    return this._invalidFeedback_2;
  };
  Spinner.prototype.buildClassSet_30 = function (classSetBuilder) {
    SimplePanel.prototype.buildClassSet_30.call(this, classSetBuilder);
    if (!(this._get_validatorError__6() == null)) {
      classSetBuilder.add_27('text-danger');
    }};
  Spinner.prototype.setEventListener_30 = function (block) {
    return this._input_3.setEventListener_30(block);
  };
  Spinner.prototype.focus_32 = function () {
    this._input_3.focus_32();
  };
  Spinner.prototype.subscribe_18 = function (observer) {
    return this._input_3.subscribe_18(observer);
  };
  Spinner.prototype.subscribe_21 = function (observer) {
    return this.subscribe_18(observer);
  };
  Spinner.$metadata$ = {
    simpleName: 'Spinner',
    kind: 'class',
    interfaces: [NumberFormControl, MutableState]
  };
  function spinner(_this_, state, value, name, min, max, step, decimals, buttonsType, forceType, buttonStyle, label, rich, init) {
    return bind(spinner$default_0(_this_, value, name, min, max, step, decimals, buttonsType, forceType, buttonStyle, label, rich, null, 2048, null), state, true, init);
  }
  function spinner$default(_this_, state, value, name, min, max, step, decimals, buttonsType, forceType, buttonStyle, label, rich, init, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      value = null;
    if (!(($mask0 & 4) === 0))
      name = null;
    if (!(($mask0 & 8) === 0))
      min = null;
    if (!(($mask0 & 16) === 0))
      max = null;
    if (!(($mask0 & 32) === 0))
      step = 1;
    if (!(($mask0 & 64) === 0))
      decimals = 0;
    if (!(($mask0 & 128) === 0))
      buttonsType = ButtonsType_VERTICAL_getInstance();
    if (!(($mask0 & 256) === 0))
      forceType = ForceType_NONE_getInstance();
    if (!(($mask0 & 512) === 0))
      buttonStyle = null;
    if (!(($mask0 & 1024) === 0))
      label = null;
    if (!(($mask0 & 2048) === 0))
      rich = false;
    return spinner(_this_, state, value, name, min, max, step, decimals, buttonsType, forceType, buttonStyle, label, rich, init);
  }
  function spinner_0(_this_, value, name, min, max, step, decimals, buttonsType, forceType, buttonStyle, label, rich, init) {
    var spinner_1 = new Spinner(value, name, min, max, step, decimals, buttonsType, forceType, buttonStyle, label, rich, init);
    _this_.add_56(spinner_1);
    Unit_getInstance();
    return spinner_1;
  }
  function spinner$default_0(_this_, value, name, min, max, step, decimals, buttonsType, forceType, buttonStyle, label, rich, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      value = null;
    if (!(($mask0 & 2) === 0))
      name = null;
    if (!(($mask0 & 4) === 0))
      min = null;
    if (!(($mask0 & 8) === 0))
      max = null;
    if (!(($mask0 & 16) === 0))
      step = 1;
    if (!(($mask0 & 32) === 0))
      decimals = 0;
    if (!(($mask0 & 64) === 0))
      buttonsType = ButtonsType_VERTICAL_getInstance();
    if (!(($mask0 & 128) === 0))
      forceType = ForceType_NONE_getInstance();
    if (!(($mask0 & 256) === 0))
      buttonStyle = null;
    if (!(($mask0 & 512) === 0))
      label = null;
    if (!(($mask0 & 1024) === 0))
      rich = false;
    if (!(($mask0 & 2048) === 0))
      init = null;
    return spinner_0(_this_, value, name, min, max, step, decimals, buttonsType, forceType, buttonStyle, label, rich, init);
  }
  var ButtonsType_NONE_instance;
  var ButtonsType_HORIZONTAL_instance;
  var ButtonsType_VERTICAL_instance;
  var ButtonsType_entriesInitialized;
  function ButtonsType_initEntries() {
    if (ButtonsType_entriesInitialized)
      return Unit_getInstance();
    ButtonsType_entriesInitialized = true;
    ButtonsType_NONE_instance = new ButtonsType('NONE', 0);
    ButtonsType_HORIZONTAL_instance = new ButtonsType('HORIZONTAL', 1);
    ButtonsType_VERTICAL_instance = new ButtonsType('VERTICAL', 2);
  }
  function ButtonsType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  ButtonsType.$metadata$ = {
    simpleName: 'ButtonsType',
    kind: 'class',
    interfaces: []
  };
  var ForceType_NONE_instance;
  var ForceType_ROUND_instance;
  var ForceType_FLOOR_instance;
  var ForceType_CEIL_instance;
  var ForceType_entriesInitialized;
  function ForceType_initEntries() {
    if (ForceType_entriesInitialized)
      return Unit_getInstance();
    ForceType_entriesInitialized = true;
    ForceType_NONE_instance = new ForceType('NONE', 0, 'none');
    ForceType_ROUND_instance = new ForceType('ROUND', 1, 'round');
    ForceType_FLOOR_instance = new ForceType('FLOOR', 2, 'floor');
    ForceType_CEIL_instance = new ForceType('CEIL', 3, 'ceil');
  }
  function ForceType(name, ordinal, value) {
    Enum.call(this, name, ordinal);
    this._value_2 = value;
  }
  ForceType.$metadata$ = {
    simpleName: 'ForceType',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__236($this_setInternalEventListener) {
    this._$this_setInternalEventListener_2 = $this_setInternalEventListener;
  }
  _no_name_provided__236.prototype.invoke_795 = function (it) {
    this._$this_setInternalEventListener_2.self.changeValue_1();
  };
  _no_name_provided__236.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__236.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function SpinnerInput_init_$Init$(value, min, max, step, decimals, buttonsType, forceType, buttonStyle, classes, init, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      value = null;
    if (!(($mask0 & 2) === 0))
      min = null;
    if (!(($mask0 & 4) === 0))
      max = null;
    if (!(($mask0 & 8) === 0))
      step = 1;
    if (!(($mask0 & 16) === 0))
      decimals = 0;
    if (!(($mask0 & 32) === 0))
      buttonsType = ButtonsType_VERTICAL_getInstance();
    if (!(($mask0 & 64) === 0))
      forceType = ForceType_NONE_getInstance();
    if (!(($mask0 & 128) === 0))
      buttonStyle = null;
    if (!(($mask0 & 256) === 0)) {
      classes = emptySet();
    }if (!(($mask0 & 512) === 0))
      init = null;
    SpinnerInput.call($this, value, min, max, step, decimals, buttonsType, forceType, buttonStyle, classes, init);
    return $this;
  }
  function SpinnerInput_init_$Create$(value, min, max, step, decimals, buttonsType, forceType, buttonStyle, classes, init, $mask0, $marker) {
    return SpinnerInput_init_$Init$(value, min, max, step, decimals, buttonsType, forceType, buttonStyle, classes, init, $mask0, $marker, Object.create(SpinnerInput.prototype));
  }
  function refreshState($this) {
    var tmp0_safe_receiver = $this._get_value__18();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp0_safe_receiver_2 = $this.getElementJQuery_31();
      tmp = tmp0_safe_receiver_2 == null ? null : tmp0_safe_receiver_2.val('' + tmp0_safe_receiver);
    }
    var tmp2_elvis_lhs = tmp;
    if (tmp2_elvis_lhs == null) {
      var tmp1_safe_receiver = $this.getElementJQueryD_30();
      if (tmp1_safe_receiver == null)
        null;
      else
        tmp1_safe_receiver.val(null);
    } else
      tmp2_elvis_lhs;
  }
  function refreshSpinner($this) {
    var tmp0_safe_receiver = $this.getElementJQueryD_30();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.trigger('touchspin.updatesettings', getSettingsObj($this));
  }
  function getSettingsObj($this) {
    var verticalbuttons = $this._buttonsType_0.equals(ButtonsType_VERTICAL_getInstance()) ? true : $this._buttonsType_0.equals(ButtonsType_NONE_getInstance());
    var style = $this._get_buttonStyle_();
    var tmp0_apply_0_1 = new Object();
    tmp0_apply_0_1.min = $this._get_min_();
    tmp0_apply_0_1.max = $this._get_max_();
    tmp0_apply_0_1.step = $this._get_step_();
    tmp0_apply_0_1.decimals = $this._get_decimals_();
    tmp0_apply_0_1.verticalbuttons = verticalbuttons;
    tmp0_apply_0_1.forcestepdivisibility = $this._get_forceType_()._value_2;
    if (!(style == null)) {
      tmp0_apply_0_1.buttonup_class = '' + 'btn ' + style._className_1;
      tmp0_apply_0_1.buttondown_class = '' + 'btn ' + style._className_1;
    } else {
      tmp0_apply_0_1.buttonup_class = 'btn btn-secondary';
      tmp0_apply_0_1.buttondown_class = 'btn btn-secondary';
    }
    if (verticalbuttons) {
      tmp0_apply_0_1.verticalup = '\u25B2';
      tmp0_apply_0_1.verticaldown = '\u25BC';
    }return tmp0_apply_0_1;
  }
  function _no_name_provided__237(this$0) {
    this._this$0_181 = this$0;
  }
  _no_name_provided__237.prototype.invoke_1077 = function (it) {
    refreshState(this._this$0_181);
    var tmp0_forEach_0 = this._this$0_181._observers_3;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(it);
    }
  };
  _no_name_provided__237.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__237.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__238(this$0) {
    this._this$0_182 = this$0;
  }
  _no_name_provided__238.prototype.invoke_1077 = function (it) {
    this._this$0_182._set_value__9(it);
    this._this$0_182.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__238.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__238.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__239(this$0) {
    this._this$0_183 = this$0;
  }
  _no_name_provided__239.prototype.invoke_1077 = function (it) {
    refreshSpinner(this._this$0_183);
  };
  _no_name_provided__239.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__239.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__240(this$0) {
    this._this$0_184 = this$0;
  }
  _no_name_provided__240.prototype.invoke_1077 = function (it) {
    refreshSpinner(this._this$0_184);
  };
  _no_name_provided__240.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__240.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__241(this$0) {
    this._this$0_185 = this$0;
  }
  _no_name_provided__241.prototype.invoke_460 = function (it) {
    refreshSpinner(this._this$0_185);
  };
  _no_name_provided__241.prototype.invoke_1082 = function (p1) {
    this.invoke_460(isNumber(p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__241.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__242(this$0) {
    this._this$0_186 = this$0;
  }
  _no_name_provided__242.prototype.invoke_462 = function (it) {
    refreshSpinner(this._this$0_186);
  };
  _no_name_provided__242.prototype.invoke_1082 = function (p1) {
    this.invoke_462((!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__242.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__243(this$0) {
    this._this$0_187 = this$0;
  }
  _no_name_provided__243.prototype.invoke_464 = function (it) {
    refreshSpinner(this._this$0_187);
  };
  _no_name_provided__243.prototype.invoke_1082 = function (p1) {
    this.invoke_464(p1 instanceof ForceType ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__243.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__244(this$0) {
    this._this$0_188 = this$0;
  }
  _no_name_provided__244.prototype.invoke_466 = function (it) {
    refreshSpinner(this._this$0_188);
  };
  _no_name_provided__244.prototype.invoke_1082 = function (p1) {
    this.invoke_466((p1 == null ? true : p1 instanceof ButtonStyle) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__244.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__245(this$0) {
    this._this$0_189 = this$0;
  }
  _no_name_provided__245.prototype.invoke_1079 = function (it) {
    this._this$0_189.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__245.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__245.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__246(this$0) {
    this._this$0_190 = this$0;
  }
  _no_name_provided__246.prototype.invoke_1079 = function (it) {
    this._this$0_190.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__246.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__246.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__247(this$0) {
    this._this$0_191 = this$0;
  }
  _no_name_provided__247.prototype.invoke_771 = function (it) {
    this._this$0_191.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__247.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__247.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__248(this$0) {
    this._this$0_192 = this$0;
  }
  _no_name_provided__248.prototype.invoke_476 = function (it) {
    this._this$0_192.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__248.prototype.invoke_1082 = function (p1) {
    this.invoke_476((p1 == null ? true : typeof p1 === 'boolean') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__248.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__249(this$0) {
    this._this$0_193 = this$0;
  }
  _no_name_provided__249.prototype.invoke_476 = function (it) {
    this._this$0_193.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__249.prototype.invoke_1082 = function (p1) {
    this.invoke_476((p1 == null ? true : typeof p1 === 'boolean') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__249.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__250(this$0) {
    this._this$0_194 = this$0;
  }
  _no_name_provided__250.prototype.invoke_478 = function (it) {
    this._this$0_194.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__250.prototype.invoke_1082 = function (p1) {
    this.invoke_478((p1 == null ? true : p1 instanceof InputSize) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__250.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__251(this$0) {
    this._this$0_195 = this$0;
  }
  _no_name_provided__251.prototype.invoke_480 = function (it) {
    this._this$0_195.refresh_33();
    Unit_getInstance();
  };
  _no_name_provided__251.prototype.invoke_1082 = function (p1) {
    this.invoke_480((p1 == null ? true : p1 instanceof ValidationStatus) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__251.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__252() {
  }
  _no_name_provided__252.prototype.invoke_482 = function ($this$setInternalEventListener) {
    $this$setInternalEventListener.change = _no_name_provided_$factory_247($this$setInternalEventListener);
  };
  _no_name_provided__252.prototype.invoke_1082 = function (p1) {
    this.invoke_482((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__252.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__253(this$0) {
    this._this$0_196 = this$0;
  }
  _no_name_provided__253.prototype.invoke_484 = function (e, _anonymous_parameter_1_) {
    if (e.isTrigger != null) {
      var event = new Event('change');
      var tmp0_safe_receiver = this._this$0_196.getElement_30();
      if (tmp0_safe_receiver == null)
        null;
      else
        tmp0_safe_receiver.dispatchEvent(event);
      Unit_getInstance();
    } else {
    }
  };
  _no_name_provided__253.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE();
    this.invoke_484(tmp, isObject(p2) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__253.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__254(this$0) {
    this._this$0_197 = this$0;
  }
  _no_name_provided__254.prototype.invoke_488 = function (e, _anonymous_parameter_1_) {
    var tmp0_apply_0_1 = new Object();
    tmp0_apply_0_1.detail = e;
    return this._this$0_197.dispatchEvent_4('onMinBsSpinner', tmp0_apply_0_1);
  };
  _no_name_provided__254.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_488(tmp, isObject(p2) ? p2 : THROW_CCE());
  };
  _no_name_provided__254.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__255(this$0) {
    this._this$0_198 = this$0;
  }
  _no_name_provided__255.prototype.invoke_488 = function (e, _anonymous_parameter_1_) {
    var tmp0_apply_0_1 = new Object();
    tmp0_apply_0_1.detail = e;
    return this._this$0_198.dispatchEvent_4('onMaxBsSpinner', tmp0_apply_0_1);
  };
  _no_name_provided__255.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_488(tmp, isObject(p2) ? p2 : THROW_CCE());
  };
  _no_name_provided__255.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__256(this$0, $observer) {
    this._this$0_199 = this$0;
    this._$observer_3 = $observer;
  }
  _no_name_provided__256.prototype.invoke_1037 = function () {
    var tmp0_this = this._this$0_199;
    var tmp0_minusAssign_0 = tmp0_this._observers_3;
    tmp0_minusAssign_0.remove_59(this._$observer_3);
    Unit_getInstance();
  };
  _no_name_provided__256.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__256.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function SpinnerInput(value, min, max, step, decimals, buttonsType, forceType, buttonStyle, classes, init) {
    var tmp = plus_1(classes, 'form-control');
    Widget_init_$Init$(tmp, null, 2, null, this);
    this._buttonsType_0 = buttonsType;
    var tmp_0 = this;
    tmp_0._observers_3 = ArrayList_init_$Create$();
    var tmp_1 = this;
    var tmp0_refreshOnUpdate_0 = _no_name_provided_$factory_227(this);
    tmp_1._value$delegate_3 = (new RefreshDelegateProvider_0(this, value, tmp0_refreshOnUpdate_0)).provideDelegate_0(this, value$factory_10());
    var tmp_2 = this;
    var tmp0_refreshOnUpdate_0_0 = _no_name_provided_$factory_228(this);
    tmp_2._startValue$delegate_1 = (new RefreshDelegateProvider_0(this, value, tmp0_refreshOnUpdate_0_0)).provideDelegate_0(this, startValue$factory_5());
    var tmp_3 = this;
    var tmp0_refreshOnUpdate_0_1 = _no_name_provided_$factory_229(this);
    tmp_3._min$delegate = (new RefreshDelegateProvider_0(this, min, tmp0_refreshOnUpdate_0_1)).provideDelegate_0(this, min$factory());
    var tmp_4 = this;
    var tmp0_refreshOnUpdate_0_2 = _no_name_provided_$factory_230(this);
    tmp_4._max$delegate = (new RefreshDelegateProvider_0(this, max, tmp0_refreshOnUpdate_0_2)).provideDelegate_0(this, max$factory());
    var tmp_5 = this;
    var tmp0_refreshOnUpdate_0_3 = _no_name_provided_$factory_231(this);
    tmp_5._step$delegate = (new RefreshDelegateProvider_0(this, step, tmp0_refreshOnUpdate_0_3)).provideDelegate_0(this, step$factory());
    var tmp_6 = this;
    var tmp0_refreshOnUpdate_0_4 = _no_name_provided_$factory_232(this);
    tmp_6._decimals$delegate = (new RefreshDelegateProvider_0(this, decimals, tmp0_refreshOnUpdate_0_4)).provideDelegate_0(this, decimals$factory());
    var tmp_7 = this;
    var tmp0_refreshOnUpdate_0_5 = _no_name_provided_$factory_233(this);
    tmp_7._forceType$delegate = (new RefreshDelegateProvider_0(this, forceType, tmp0_refreshOnUpdate_0_5)).provideDelegate_0(this, forceType$factory());
    var tmp_8 = this;
    var tmp0_refreshOnUpdate_0_6 = _no_name_provided_$factory_234(this);
    tmp_8._buttonStyle$delegate = (new RefreshDelegateProvider_0(this, buttonStyle, tmp0_refreshOnUpdate_0_6)).provideDelegate_0(this, buttonStyle$factory());
    var tmp_9 = this;
    var tmp0_refreshOnUpdate_0_7 = _no_name_provided_$factory_235(this);
    tmp_9._placeholder$delegate_0 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_7)).provideDelegate_0(this, placeholder$factory_2());
    var tmp_10 = this;
    var tmp0_refreshOnUpdate_0_8 = _no_name_provided_$factory_236(this);
    tmp_10._name$delegate_1 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_8)).provideDelegate_0(this, name$factory_5());
    var tmp_11 = this;
    var tmp0_refreshOnUpdate_0_9 = false;
    var tmp1_refreshOnUpdate_0 = _no_name_provided_$factory_237(this);
    tmp_11._disabled$delegate_2 = (new RefreshDelegateProvider_0(this, tmp0_refreshOnUpdate_0_9, tmp1_refreshOnUpdate_0)).provideDelegate_0(this, disabled$factory_8());
    var tmp_12 = this;
    var tmp0_refreshOnUpdate_0_10 = _no_name_provided_$factory_238(this);
    tmp_12._autofocus$delegate_0 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_10)).provideDelegate_0(this, autofocus$factory_2());
    var tmp_13 = this;
    var tmp0_refreshOnUpdate_0_11 = _no_name_provided_$factory_239(this);
    tmp_13._readonly$delegate_0 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_11)).provideDelegate_0(this, readonly$factory_2());
    var tmp_14 = this;
    var tmp0_refreshOnUpdate_0_12 = _no_name_provided_$factory_240(this);
    tmp_14._size$delegate_4 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_12)).provideDelegate_0(this, size$factory_14());
    var tmp_15 = this;
    var tmp0_refreshOnUpdate_0_13 = _no_name_provided_$factory_241(this);
    tmp_15._validationStatus$delegate_1 = (new RefreshDelegateProvider_0(this, null, tmp0_refreshOnUpdate_0_13)).provideDelegate_0(this, validationStatus$factory_5());
    this._siblings = null;
    this.addSurroundingCssClass_0('input-group');
    Unit_getInstance();
    this.addSurroundingCssClass_0('kv-spinner');
    Unit_getInstance();
    var tmp0_subject = this._buttonsType_0;
    if (tmp0_subject.equals(ButtonsType_NONE_getInstance())) {
      this.addSurroundingCssClass_0('kv-spinner-btn-none');
      Unit_getInstance();
    } else if (tmp0_subject.equals(ButtonsType_VERTICAL_getInstance())) {
      this.addSurroundingCssClass_0('kv-spinner-btn-vertical');
      Unit_getInstance();
    } else if (tmp0_subject.equals(ButtonsType_HORIZONTAL_getInstance())) {
      this.addSurroundingCssClass_0('kv-spinner-btn-horizontal');
      Unit_getInstance();
    }this._set_surroundingSpan__30(true);
    this.setInternalEventListener_4(_no_name_provided_$factory_242());
    Unit_getInstance();
    var tmp1_safe_receiver = init;
    if (tmp1_safe_receiver == null)
      null;
    else
      tmp1_safe_receiver(this);
    Unit_getInstance();
  }
  SpinnerInput.prototype._set_value__9 = function (_set___) {
    return this._value$delegate_3.setValue_6(this, value$factory_11(), _set___);
  };
  SpinnerInput.prototype._get_value__18 = function () {
    return this._value$delegate_3.getValue_3(this, value$factory_12());
  };
  SpinnerInput.prototype._set_startValue__3 = function (_set___) {
    return this._startValue$delegate_1.setValue_6(this, startValue$factory_6(), _set___);
  };
  SpinnerInput.prototype._get_startValue__3 = function () {
    return this._startValue$delegate_1.getValue_3(this, startValue$factory_7());
  };
  SpinnerInput.prototype._set_min__0 = function (_set___) {
    return this._min$delegate.setValue_6(this, min$factory_0(), _set___);
  };
  SpinnerInput.prototype._get_min_ = function () {
    return this._min$delegate.getValue_3(this, min$factory_1());
  };
  SpinnerInput.prototype._set_max__0 = function (_set___) {
    return this._max$delegate.setValue_6(this, max$factory_0(), _set___);
  };
  SpinnerInput.prototype._get_max_ = function () {
    return this._max$delegate.getValue_3(this, max$factory_1());
  };
  SpinnerInput.prototype._set_step__0 = function (_set___) {
    return this._step$delegate.setValue_6(this, step$factory_0(), _set___);
  };
  SpinnerInput.prototype._get_step_ = function () {
    return this._step$delegate.getValue_3(this, step$factory_1());
  };
  SpinnerInput.prototype._set_decimals_ = function (_set___) {
    return this._decimals$delegate.setValue_6(this, decimals$factory_0(), _set___);
  };
  SpinnerInput.prototype._get_decimals_ = function () {
    return this._decimals$delegate.getValue_3(this, decimals$factory_1());
  };
  SpinnerInput.prototype._set_forceType_ = function (_set___) {
    return this._forceType$delegate.setValue_6(this, forceType$factory_0(), _set___);
  };
  SpinnerInput.prototype._get_forceType_ = function () {
    return this._forceType$delegate.getValue_3(this, forceType$factory_1());
  };
  SpinnerInput.prototype._set_buttonStyle_ = function (_set___) {
    return this._buttonStyle$delegate.setValue_6(this, buttonStyle$factory_0(), _set___);
  };
  SpinnerInput.prototype._get_buttonStyle_ = function () {
    return this._buttonStyle$delegate.getValue_3(this, buttonStyle$factory_1());
  };
  SpinnerInput.prototype._set_placeholder__1 = function (_set___) {
    return this._placeholder$delegate_0.setValue_6(this, placeholder$factory_3(), _set___);
  };
  SpinnerInput.prototype._get_placeholder__1 = function () {
    return this._placeholder$delegate_0.getValue_3(this, placeholder$factory_4());
  };
  SpinnerInput.prototype._set_name__6 = function (_set___) {
    return this._name$delegate_1.setValue_6(this, name$factory_6(), _set___);
  };
  SpinnerInput.prototype._get_name__47 = function () {
    return this._name$delegate_1.getValue_3(this, name$factory_7());
  };
  SpinnerInput.prototype._set_disabled__5 = function (_set___) {
    return this._disabled$delegate_2.setValue_6(this, disabled$factory_9(), _set___);
  };
  SpinnerInput.prototype._get_disabled__5 = function () {
    return this._disabled$delegate_2.getValue_3(this, disabled$factory_10());
  };
  SpinnerInput.prototype._set_autofocus__1 = function (_set___) {
    return this._autofocus$delegate_0.setValue_6(this, autofocus$factory_3(), _set___);
  };
  SpinnerInput.prototype._get_autofocus__1 = function () {
    return this._autofocus$delegate_0.getValue_3(this, autofocus$factory_4());
  };
  SpinnerInput.prototype._set_readonly__1 = function (_set___) {
    return this._readonly$delegate_0.setValue_6(this, readonly$factory_3(), _set___);
  };
  SpinnerInput.prototype._get_readonly__1 = function () {
    return this._readonly$delegate_0.getValue_3(this, readonly$factory_4());
  };
  SpinnerInput.prototype._set_size__6 = function (_set___) {
    return this._size$delegate_4.setValue_6(this, size$factory_15(), _set___);
  };
  SpinnerInput.prototype._get_size__43 = function () {
    return this._size$delegate_4.getValue_3(this, size$factory_16());
  };
  SpinnerInput.prototype._set_validationStatus__10 = function (_set___) {
    return this._validationStatus$delegate_1.setValue_6(this, validationStatus$factory_6(), _set___);
  };
  SpinnerInput.prototype._get_validationStatus__3 = function () {
    return this._validationStatus$delegate_1.getValue_3(this, validationStatus$factory_7());
  };
  SpinnerInput.prototype.render_88 = function () {
    return this.render_89('input');
  };
  SpinnerInput.prototype.buildClassSet_30 = function (classSetBuilder) {
    Widget.prototype.buildClassSet_30.call(this, classSetBuilder);
    classSetBuilder.add_28(this._get_validationStatus__3());
    classSetBuilder.add_28(this._get_size__43());
  };
  SpinnerInput.prototype.buildAttributeSet_30 = function (attributeSetBuilder) {
    Widget.prototype.buildAttributeSet_30.call(this, attributeSetBuilder);
    attributeSetBuilder.add_24('type', 'text');
    var tmp0_safe_receiver = this._get_startValue__3();
    if (tmp0_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('value', '' + tmp0_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp1_safe_receiver = this._get_placeholder__1();
    if (tmp1_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('placeholder', this.translate_31(tmp1_safe_receiver));
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp2_safe_receiver = this._get_name__47();
    if (tmp2_safe_receiver == null)
      null;
    else {
      attributeSetBuilder.add_24('name', tmp2_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp3_safe_receiver = this._get_autofocus__1();
    if (tmp3_safe_receiver == null)
      null;
    else {
      if (tmp3_safe_receiver) {
        attributeSetBuilder.add$default('autofocus', null, 2, null);
      }Unit_getInstance();
    }
    Unit_getInstance();
    var tmp4_safe_receiver = this._get_readonly__1();
    if (tmp4_safe_receiver == null)
      null;
    else {
      if (tmp4_safe_receiver) {
        attributeSetBuilder.add$default('readonly', null, 2, null);
      }Unit_getInstance();
    }
    Unit_getInstance();
    if (this._get_disabled__5()) {
      attributeSetBuilder.add$default('disabled', null, 2, null);
      var tmp5_safe_receiver = this._get_value__18();
      if (tmp5_safe_receiver == null)
        null;
      else {
        attributeSetBuilder.add_24('value', '' + tmp5_safe_receiver);
        Unit_getInstance();
      }
      Unit_getInstance();
    }};
  SpinnerInput.prototype.changeValue_1 = function () {
    var tmp0_safe_receiver = this.getElementJQuery_31();
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.val();
    var v = (tmp == null ? true : typeof tmp === 'string') ? tmp : THROW_CCE();
    if (!(v == null) ? !(v === '') : false) {
      this._set_value__9(toDoubleOrNull(v));
      var tmp1_safe_receiver = this._get_value__18();
      if (tmp1_safe_receiver == null)
        null;
      else {
        var tmp_0;
        if (!(this._get_min_() == null)) {
          var tmp_1 = numberToDouble(tmp1_safe_receiver);
          var tmp0_safe_receiver_3 = this._get_min_();
          var tmp1_elvis_lhs_2 = tmp0_safe_receiver_3 == null ? null : numberToDouble(tmp0_safe_receiver_3);
          tmp_0 = tmp_1 < (tmp1_elvis_lhs_2 == null ? 0.0 : tmp1_elvis_lhs_2);
        } else {
          tmp_0 = false;
        }
        if (tmp_0)
          this._set_value__9(this._get_min_());
        else {
        }
        var tmp_2;
        if (!(this._get_max_() == null)) {
          var tmp_3 = numberToDouble(tmp1_safe_receiver);
          var tmp2_safe_receiver_5 = this._get_max_();
          var tmp3_elvis_lhs_4 = tmp2_safe_receiver_5 == null ? null : numberToDouble(tmp2_safe_receiver_5);
          tmp_2 = tmp_3 > (tmp3_elvis_lhs_4 == null ? 0.0 : tmp3_elvis_lhs_4);
        } else {
          tmp_2 = false;
        }
        if (tmp_2)
          this._set_value__9(this._get_max_());
        else {
        }
        Unit_getInstance();
      }
      Unit_getInstance();
    } else {
      this._set_value__9(null);
    }
  };
  SpinnerInput.prototype.afterInsert_30 = function (node) {
    var tmp0_safe_receiver = this.getElementJQueryD_30();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.TouchSpin(getSettingsObj(this));
    var tmp = this;
    var tmp1_safe_receiver = this.getElementJQuery_31();
    var tmp2_safe_receiver = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.parent('.bootstrap-touchspin');
    tmp._siblings = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.children('span');
    var tmp3_safe_receiver = this.getElementJQuery_31();
    if (tmp3_safe_receiver == null)
      null;
    else {
      tmp3_safe_receiver.on('change', _no_name_provided_$factory_243(this));
    }
    Unit_getInstance();
    var tmp4_safe_receiver = this.getElementJQuery_31();
    if (tmp4_safe_receiver == null)
      null;
    else {
      tmp4_safe_receiver.on('touchspin.on.min', _no_name_provided_$factory_244(this));
    }
    Unit_getInstance();
    var tmp5_safe_receiver = this.getElementJQuery_31();
    if (tmp5_safe_receiver == null)
      null;
    else {
      tmp5_safe_receiver.on('touchspin.on.max', _no_name_provided_$factory_245(this));
    }
    Unit_getInstance();
    refreshState(this);
  };
  SpinnerInput.prototype.afterDestroy_30 = function () {
    var tmp0_safe_receiver = this._siblings;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.remove();
    Unit_getInstance();
    this._siblings = null;
  };
  SpinnerInput.prototype.subscribe_18 = function (observer) {
    var tmp0_this = this;
    var tmp0_plusAssign_0 = tmp0_this._observers_3;
    tmp0_plusAssign_0.add_57(observer);
    Unit_getInstance();
    observer(this._get_value__18());
    return _no_name_provided_$factory_246(this, observer);
  };
  SpinnerInput.prototype.subscribe_21 = function (observer) {
    return this.subscribe_18(observer);
  };
  SpinnerInput.$metadata$ = {
    simpleName: 'SpinnerInput',
    kind: 'class',
    interfaces: [FormInput, MutableState]
  };
  function ButtonsType_NONE_getInstance() {
    ButtonsType_initEntries();
    return ButtonsType_NONE_instance;
  }
  function ButtonsType_HORIZONTAL_getInstance() {
    ButtonsType_initEntries();
    return ButtonsType_HORIZONTAL_instance;
  }
  function ButtonsType_VERTICAL_getInstance() {
    ButtonsType_initEntries();
    return ButtonsType_VERTICAL_instance;
  }
  function ForceType_NONE_getInstance() {
    ForceType_initEntries();
    return ForceType_NONE_instance;
  }
  function value$factory_10() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__9(value);
    });
  }
  function startValue$factory_5() {
    return getPropertyCallableRef('startValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_startValue__3();
    }, function (receiver_0, value) {
      return receiver_0._set_startValue__3(value);
    });
  }
  function min$factory() {
    return getPropertyCallableRef('min', 1, KMutableProperty1, function (receiver) {
      return receiver._get_min_();
    }, function (receiver_0, value) {
      return receiver_0._set_min__0(value);
    });
  }
  function max$factory() {
    return getPropertyCallableRef('max', 1, KMutableProperty1, function (receiver) {
      return receiver._get_max_();
    }, function (receiver_0, value) {
      return receiver_0._set_max__0(value);
    });
  }
  function step$factory() {
    return getPropertyCallableRef('step', 1, KMutableProperty1, function (receiver) {
      return receiver._get_step_();
    }, function (receiver_0, value) {
      return receiver_0._set_step__0(value);
    });
  }
  function decimals$factory() {
    return getPropertyCallableRef('decimals', 1, KMutableProperty1, function (receiver) {
      return receiver._get_decimals_();
    }, function (receiver_0, value) {
      return receiver_0._set_decimals_(value);
    });
  }
  function forceType$factory() {
    return getPropertyCallableRef('forceType', 1, KMutableProperty1, function (receiver) {
      return receiver._get_forceType_();
    }, function (receiver_0, value) {
      return receiver_0._set_forceType_(value);
    });
  }
  function buttonStyle$factory() {
    return getPropertyCallableRef('buttonStyle', 1, KMutableProperty1, function (receiver) {
      return receiver._get_buttonStyle_();
    }, function (receiver_0, value) {
      return receiver_0._set_buttonStyle_(value);
    });
  }
  function placeholder$factory_2() {
    return getPropertyCallableRef('placeholder', 1, KMutableProperty1, function (receiver) {
      return receiver._get_placeholder__1();
    }, function (receiver_0, value) {
      return receiver_0._set_placeholder__1(value);
    });
  }
  function name$factory_5() {
    return getPropertyCallableRef('name', 1, KMutableProperty1, function (receiver) {
      return receiver._get_name__47();
    }, function (receiver_0, value) {
      return receiver_0._set_name__6(value);
    });
  }
  function disabled$factory_8() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function autofocus$factory_2() {
    return getPropertyCallableRef('autofocus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_autofocus__1();
    }, function (receiver_0, value) {
      return receiver_0._set_autofocus__1(value);
    });
  }
  function readonly$factory_2() {
    return getPropertyCallableRef('readonly', 1, KMutableProperty1, function (receiver) {
      return receiver._get_readonly__1();
    }, function (receiver_0, value) {
      return receiver_0._set_readonly__1(value);
    });
  }
  function size$factory_14() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__6(value);
    });
  }
  function validationStatus$factory_5() {
    return getPropertyCallableRef('validationStatus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_validationStatus__3();
    }, function (receiver_0, value) {
      return receiver_0._set_validationStatus__10(value);
    });
  }
  function value$factory_11() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__9(value);
    });
  }
  function value$factory_12() {
    return getPropertyCallableRef('value', 1, KMutableProperty1, function (receiver) {
      return receiver._get_value__18();
    }, function (receiver_0, value) {
      return receiver_0._set_value__9(value);
    });
  }
  function startValue$factory_6() {
    return getPropertyCallableRef('startValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_startValue__3();
    }, function (receiver_0, value) {
      return receiver_0._set_startValue__3(value);
    });
  }
  function startValue$factory_7() {
    return getPropertyCallableRef('startValue', 1, KMutableProperty1, function (receiver) {
      return receiver._get_startValue__3();
    }, function (receiver_0, value) {
      return receiver_0._set_startValue__3(value);
    });
  }
  function min$factory_0() {
    return getPropertyCallableRef('min', 1, KMutableProperty1, function (receiver) {
      return receiver._get_min_();
    }, function (receiver_0, value) {
      return receiver_0._set_min__0(value);
    });
  }
  function min$factory_1() {
    return getPropertyCallableRef('min', 1, KMutableProperty1, function (receiver) {
      return receiver._get_min_();
    }, function (receiver_0, value) {
      return receiver_0._set_min__0(value);
    });
  }
  function max$factory_0() {
    return getPropertyCallableRef('max', 1, KMutableProperty1, function (receiver) {
      return receiver._get_max_();
    }, function (receiver_0, value) {
      return receiver_0._set_max__0(value);
    });
  }
  function max$factory_1() {
    return getPropertyCallableRef('max', 1, KMutableProperty1, function (receiver) {
      return receiver._get_max_();
    }, function (receiver_0, value) {
      return receiver_0._set_max__0(value);
    });
  }
  function step$factory_0() {
    return getPropertyCallableRef('step', 1, KMutableProperty1, function (receiver) {
      return receiver._get_step_();
    }, function (receiver_0, value) {
      return receiver_0._set_step__0(value);
    });
  }
  function step$factory_1() {
    return getPropertyCallableRef('step', 1, KMutableProperty1, function (receiver) {
      return receiver._get_step_();
    }, function (receiver_0, value) {
      return receiver_0._set_step__0(value);
    });
  }
  function decimals$factory_0() {
    return getPropertyCallableRef('decimals', 1, KMutableProperty1, function (receiver) {
      return receiver._get_decimals_();
    }, function (receiver_0, value) {
      return receiver_0._set_decimals_(value);
    });
  }
  function decimals$factory_1() {
    return getPropertyCallableRef('decimals', 1, KMutableProperty1, function (receiver) {
      return receiver._get_decimals_();
    }, function (receiver_0, value) {
      return receiver_0._set_decimals_(value);
    });
  }
  function forceType$factory_0() {
    return getPropertyCallableRef('forceType', 1, KMutableProperty1, function (receiver) {
      return receiver._get_forceType_();
    }, function (receiver_0, value) {
      return receiver_0._set_forceType_(value);
    });
  }
  function forceType$factory_1() {
    return getPropertyCallableRef('forceType', 1, KMutableProperty1, function (receiver) {
      return receiver._get_forceType_();
    }, function (receiver_0, value) {
      return receiver_0._set_forceType_(value);
    });
  }
  function buttonStyle$factory_0() {
    return getPropertyCallableRef('buttonStyle', 1, KMutableProperty1, function (receiver) {
      return receiver._get_buttonStyle_();
    }, function (receiver_0, value) {
      return receiver_0._set_buttonStyle_(value);
    });
  }
  function buttonStyle$factory_1() {
    return getPropertyCallableRef('buttonStyle', 1, KMutableProperty1, function (receiver) {
      return receiver._get_buttonStyle_();
    }, function (receiver_0, value) {
      return receiver_0._set_buttonStyle_(value);
    });
  }
  function placeholder$factory_3() {
    return getPropertyCallableRef('placeholder', 1, KMutableProperty1, function (receiver) {
      return receiver._get_placeholder__1();
    }, function (receiver_0, value) {
      return receiver_0._set_placeholder__1(value);
    });
  }
  function placeholder$factory_4() {
    return getPropertyCallableRef('placeholder', 1, KMutableProperty1, function (receiver) {
      return receiver._get_placeholder__1();
    }, function (receiver_0, value) {
      return receiver_0._set_placeholder__1(value);
    });
  }
  function name$factory_6() {
    return getPropertyCallableRef('name', 1, KMutableProperty1, function (receiver) {
      return receiver._get_name__47();
    }, function (receiver_0, value) {
      return receiver_0._set_name__6(value);
    });
  }
  function name$factory_7() {
    return getPropertyCallableRef('name', 1, KMutableProperty1, function (receiver) {
      return receiver._get_name__47();
    }, function (receiver_0, value) {
      return receiver_0._set_name__6(value);
    });
  }
  function disabled$factory_9() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function disabled$factory_10() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function autofocus$factory_3() {
    return getPropertyCallableRef('autofocus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_autofocus__1();
    }, function (receiver_0, value) {
      return receiver_0._set_autofocus__1(value);
    });
  }
  function autofocus$factory_4() {
    return getPropertyCallableRef('autofocus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_autofocus__1();
    }, function (receiver_0, value) {
      return receiver_0._set_autofocus__1(value);
    });
  }
  function readonly$factory_3() {
    return getPropertyCallableRef('readonly', 1, KMutableProperty1, function (receiver) {
      return receiver._get_readonly__1();
    }, function (receiver_0, value) {
      return receiver_0._set_readonly__1(value);
    });
  }
  function readonly$factory_4() {
    return getPropertyCallableRef('readonly', 1, KMutableProperty1, function (receiver) {
      return receiver._get_readonly__1();
    }, function (receiver_0, value) {
      return receiver_0._set_readonly__1(value);
    });
  }
  function size$factory_15() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__6(value);
    });
  }
  function size$factory_16() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__6(value);
    });
  }
  function validationStatus$factory_6() {
    return getPropertyCallableRef('validationStatus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_validationStatus__3();
    }, function (receiver_0, value) {
      return receiver_0._set_validationStatus__10(value);
    });
  }
  function validationStatus$factory_7() {
    return getPropertyCallableRef('validationStatus', 1, KMutableProperty1, function (receiver) {
      return receiver._get_validationStatus__3();
    }, function (receiver_0, value) {
      return receiver_0._set_validationStatus__10(value);
    });
  }
  function _no_name_provided_$factory_227(this$0) {
    var i = new _no_name_provided__237(this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_228(this$0) {
    var i = new _no_name_provided__238(this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_229(this$0) {
    var i = new _no_name_provided__239(this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_230(this$0) {
    var i = new _no_name_provided__240(this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_231(this$0) {
    var i = new _no_name_provided__241(this$0);
    return function (p1) {
      i.invoke_460(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_232(this$0) {
    var i = new _no_name_provided__242(this$0);
    return function (p1) {
      i.invoke_462(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_233(this$0) {
    var i = new _no_name_provided__243(this$0);
    return function (p1) {
      i.invoke_464(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_234(this$0) {
    var i = new _no_name_provided__244(this$0);
    return function (p1) {
      i.invoke_466(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_235(this$0) {
    var i = new _no_name_provided__245(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_236(this$0) {
    var i = new _no_name_provided__246(this$0);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_237(this$0) {
    var i = new _no_name_provided__247(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_238(this$0) {
    var i = new _no_name_provided__248(this$0);
    return function (p1) {
      i.invoke_476(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_239(this$0) {
    var i = new _no_name_provided__249(this$0);
    return function (p1) {
      i.invoke_476(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_240(this$0) {
    var i = new _no_name_provided__250(this$0);
    return function (p1) {
      i.invoke_478(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_241(this$0) {
    var i = new _no_name_provided__251(this$0);
    return function (p1) {
      i.invoke_480(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_242() {
    var i = new _no_name_provided__252();
    return function (p1) {
      i.invoke_482(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_243(this$0) {
    var i = new _no_name_provided__253(this$0);
    return function (p1, p2) {
      i.invoke_484(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_244(this$0) {
    var i = new _no_name_provided__254(this$0);
    return function (p1, p2) {
      return i.invoke_488(p1, p2);
    };
  }
  function _no_name_provided_$factory_245(this$0) {
    var i = new _no_name_provided__255(this$0);
    return function (p1, p2) {
      return i.invoke_488(p1, p2);
    };
  }
  function _no_name_provided_$factory_246(this$0, $observer) {
    var i = new _no_name_provided__256(this$0, $observer);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_247($this_setInternalEventListener) {
    var i = new _no_name_provided__236($this_setInternalEventListener);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  var kVManagerUploadInit;
  function KVManagerUpload() {
    KVManagerUpload_instance = this;
    require('bootstrap-fileinput/css/fileinput.min.css');
    require('bootstrap-fileinput/themes/explorer-fas/theme.min.css');
    require('bootstrap-fileinput');
    require('kvision-assets/js/locales/bootstrap-fileinput/ar.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/az.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/bg.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/ca.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/cr.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/cs.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/da.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/de.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/el.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/es.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/et.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/fa.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/fi.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/fr.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/gl.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/id.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/it.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/ja.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/ka.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/kr.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/kz.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/lt.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/nl.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/no.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/pl.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/pt.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/ro.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/ru.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/sk.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/sl.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/sv.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/th.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/tr.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/uk.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/vi.js');
    require('kvision-assets/js/locales/bootstrap-fileinput/zh.js');
    require('bootstrap-fileinput/themes/explorer-fas/theme.min.js');
    require('bootstrap-fileinput/themes/fas/theme.min.js');
  }
  KVManagerUpload.prototype.init_16 = function () {
  };
  KVManagerUpload.$metadata$ = {
    simpleName: 'KVManagerUpload',
    kind: 'object',
    interfaces: []
  };
  var KVManagerUpload_instance;
  function KVManagerUpload_getInstance() {
    if (KVManagerUpload_instance == null)
      new KVManagerUpload();
    return KVManagerUpload_instance;
  }
  var kVManagerFontAwesomeInit;
  function KVManagerFontAwesome() {
    KVManagerFontAwesome_instance = this;
    require('@fortawesome/fontawesome-free/css/all.min.css');
  }
  KVManagerFontAwesome.prototype.init_16 = function () {
  };
  KVManagerFontAwesome.$metadata$ = {
    simpleName: 'KVManagerFontAwesome',
    kind: 'object',
    interfaces: []
  };
  var KVManagerFontAwesome_instance;
  function KVManagerFontAwesome_getInstance() {
    if (KVManagerFontAwesome_instance == null)
      new KVManagerFontAwesome();
    return KVManagerFontAwesome_instance;
  }
  var kVManagerI18nInit;
  function KVManagerI18n() {
    KVManagerI18n_instance = this;
  }
  KVManagerI18n.prototype.init_16 = function () {
  };
  KVManagerI18n.$metadata$ = {
    simpleName: 'KVManagerI18n',
    kind: 'object',
    interfaces: []
  };
  var KVManagerI18n_instance;
  function KVManagerI18n_getInstance() {
    if (KVManagerI18n_instance == null)
      new KVManagerI18n();
    return KVManagerI18n_instance;
  }
  function DefaultI18nManager(translations) {
    this._i18n = require('gettext.js').default();
    var tmp0_iterator_1 = translations._get_entries__5().iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      var json_4 = JSON.parse(JSON.stringify(element_2._get_value__18()));
      json_4[''].language = element_2._get_key__2();
      this._i18n.loadJSON(json_4, 'messages');
    }
  }
  DefaultI18nManager.prototype.gettext_2 = function (key) {
    this._i18n.setLocale(I18n_getInstance()._language);
    var tmp0_elvis_lhs = this._i18n.gettext(key);
    return tmp0_elvis_lhs == null ? key : tmp0_elvis_lhs;
  };
  DefaultI18nManager.prototype.ngettext_2 = function (singularKey, pluralKey, value) {
    this._i18n.setLocale(I18n_getInstance()._language);
    var tmp0_elvis_lhs = this._i18n.ngettext(singularKey, pluralKey, value);
    return tmp0_elvis_lhs == null ? value === 1 ? singularKey : pluralKey : tmp0_elvis_lhs;
  };
  DefaultI18nManager.$metadata$ = {
    simpleName: 'DefaultI18nManager',
    kind: 'class',
    interfaces: [I18nManager]
  };
  var kVManagerRichTextInit;
  function _no_name_provided__257($trix, $orig) {
    this._$trix = $trix;
    this._$orig = $orig;
  }
  _no_name_provided__257.prototype.invoke_1038 = function () {
    var tmp;
    if (this._$trix.config.languages[I18n_getInstance()._language] != undefined) {
      tmp = this._$trix.config.languages[I18n_getInstance()._language];
    } else {
      tmp = this._$trix.config.languages['en'];
    }
    var config = tmp;
    var tmp0_iterator = iterator(Object.keys(this._$trix.config.lang));
    while (tmp0_iterator.hasNext_11()) {
      var key = tmp0_iterator.next_13();
      this._$trix.config.lang[key] = config[key];
    }
    return this._$orig();
  };
  _no_name_provided__257.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function KVManagerRichText() {
    KVManagerRichText_instance = this;
    require('trix/dist/trix.css');
    var trix = require('trix');
    var tmp0_asDynamic_0 = window;
    tmp0_asDynamic_0.Trix = trix;
    var tmp0_apply_0_1 = new Object();
    Unit_getInstance();
    trix.config.languages = tmp0_apply_0_1;
    var tmp0_apply_0_1_0 = new Object();
    Unit_getInstance();
    trix.config.languages['en'] = tmp0_apply_0_1_0;
    var tmp0_iterator = iterator(Object.keys(trix.config.lang));
    while (tmp0_iterator.hasNext_11()) {
      var key = tmp0_iterator.next_13();
      trix.config.languages['en'][key] = trix.config.lang[key];
    }
    var orig = trix.config.toolbar.getDefaultHTML;
    trix.config.toolbar.getDefaultHTML = _no_name_provided_$factory_248(trix, orig);
    require('kvision-assets/js/locales/trix/trix.pl.js');
  }
  KVManagerRichText.prototype.init_16 = function () {
  };
  KVManagerRichText.$metadata$ = {
    simpleName: 'KVManagerRichText',
    kind: 'object',
    interfaces: []
  };
  var KVManagerRichText_instance;
  function KVManagerRichText_getInstance() {
    if (KVManagerRichText_instance == null)
      new KVManagerRichText();
    return KVManagerRichText_instance;
  }
  function _no_name_provided_$factory_248($trix, $orig) {
    var i = new _no_name_provided__257($trix, $orig);
    return function () {
      return i.invoke_1038();
    };
  }
  var kVManagerHandlebarsInit;
  function KVManagerHandlebars() {
    KVManagerHandlebars_instance = this;
    require('handlebars/dist/handlebars.runtime.min.js');
  }
  KVManagerHandlebars.prototype.init_16 = function () {
  };
  KVManagerHandlebars.$metadata$ = {
    simpleName: 'KVManagerHandlebars',
    kind: 'object',
    interfaces: []
  };
  var KVManagerHandlebars_instance;
  function KVManagerHandlebars_getInstance() {
    if (KVManagerHandlebars_instance == null)
      new KVManagerHandlebars();
    return KVManagerHandlebars_instance;
  }
  var kVManagerChartInit;
  function KVManagerChart() {
    KVManagerChart_instance = this;
    require('chart.js/dist/Chart.bundle.min.js');
  }
  KVManagerChart.prototype.init_16 = function () {
  };
  KVManagerChart.$metadata$ = {
    simpleName: 'KVManagerChart',
    kind: 'object',
    interfaces: []
  };
  var KVManagerChart_instance;
  function KVManagerChart_getInstance() {
    if (KVManagerChart_instance == null)
      new KVManagerChart();
    return KVManagerChart_instance;
  }
  var kVManagerTabulatorInit;
  function KVManagerTabulator() {
    KVManagerTabulator_instance = this;
    require('tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css');
    this._tabulator = require('tabulator-tables/dist/js/tabulator.min.js');
  }
  KVManagerTabulator.prototype.init_16 = function () {
  };
  KVManagerTabulator.$metadata$ = {
    simpleName: 'KVManagerTabulator',
    kind: 'object',
    interfaces: []
  };
  var KVManagerTabulator_instance;
  function KVManagerTabulator_getInstance() {
    if (KVManagerTabulator_instance == null)
      new KVManagerTabulator();
    return KVManagerTabulator_instance;
  }
  var kVManagerPaceInit;
  function KVManagerPace() {
    KVManagerPace_instance = this;
    require('pace-progressbar').default;
  }
  KVManagerPace.prototype.init_16 = function () {
  };
  KVManagerPace.$metadata$ = {
    simpleName: 'KVManagerPace',
    kind: 'object',
    interfaces: []
  };
  var KVManagerPace_instance;
  function KVManagerPace_getInstance() {
    if (KVManagerPace_instance == null)
      new KVManagerPace();
    return KVManagerPace_instance;
  }
  var kVManagerMomentInit;
  function KVManagerMoment() {
    KVManagerMoment_instance = this;
    require('moment');
  }
  KVManagerMoment.prototype.init_16 = function () {
  };
  KVManagerMoment.$metadata$ = {
    simpleName: 'KVManagerMoment',
    kind: 'object',
    interfaces: []
  };
  var KVManagerMoment_instance;
  function KVManagerMoment_getInstance() {
    if (KVManagerMoment_instance == null)
      new KVManagerMoment();
    return KVManagerMoment_instance;
  }
  var kVManagerToastInit;
  function KVManagerToast() {
    KVManagerToast_instance = this;
    require('toastr/build/toastr.min.css');
    this._toastr = require('toastr/build/toastr.min.js');
  }
  KVManagerToast.prototype.init_16 = function () {
  };
  KVManagerToast.$metadata$ = {
    simpleName: 'KVManagerToast',
    kind: 'object',
    interfaces: []
  };
  var KVManagerToast_instance;
  function KVManagerToast_getInstance() {
    if (KVManagerToast_instance == null)
      new KVManagerToast();
    return KVManagerToast_instance;
  }
  var kVManagerPrintInit;
  function KVManagerPrint() {
    KVManagerPrint_instance = this;
    require('print-js/dist/print.css');
    this._printjs = require('print-js');
    this._counter_7 = 0;
  }
  KVManagerPrint.prototype.init_16 = function () {
  };
  KVManagerPrint.$metadata$ = {
    simpleName: 'KVManagerPrint',
    kind: 'object',
    interfaces: []
  };
  var KVManagerPrint_instance;
  function KVManagerPrint_getInstance() {
    if (KVManagerPrint_instance == null)
      new KVManagerPrint();
    return KVManagerPrint_instance;
  }
  function consumeUntil(_this_, consumer) {
    var iter = _this_.iterator_38();
    while (iter.hasNext_11()) {
      if (consumer(iter.next_13())) {
        return Unit_getInstance();
      }}
  }
  function toDegrees(_this_) {
    return _this_ * 180.0 / 3.141592653589793;
  }
  function round_0(_this_) {
    return round(_this_);
  }
  function abs(_this_) {
    return Math.abs(_this_);
  }
  function toIntFloor(_this_) {
    var tmp0_unsafeCast_0_1 = Math.floor(_this_);
    return numberToInt(tmp0_unsafeCast_0_1);
  }
  var formatRegex;
  var replaceNonPrintableCharactersRegex$delegate;
  function _no_name_provided__258() {
  }
  _no_name_provided__258.prototype.invoke_1038 = function () {
    return Regex_init_$Create$('[^ -~]');
  };
  _no_name_provided__258.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_249() {
    var i = new _no_name_provided__258();
    return function () {
      return i.invoke_1038();
    };
  }
  var emptyArrayOfBytes;
  var emptyArrayOfInts;
  var emptyArrayOfDoubles;
  var emptyArrayOfFloats;
  var cacheStatsHandler;
  function Cache_init_$Init$_0(maxSize, free, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      maxSize = 16;
    if (!(($mask0 & 2) === 0)) {
      free = _no_name_provided_$factory_250();
    }Cache_1.call($this, maxSize, free);
    return $this;
  }
  function Cache_init_$Create$_0(maxSize, free, $mask0, $marker) {
    return Cache_init_$Init$_0(maxSize, free, $mask0, $marker, Object.create(Cache_1.prototype));
  }
  function _no_name_provided__259() {
  }
  _no_name_provided__259.prototype.invoke_496 = function (_anonymous_parameter_0_, _anonymous_parameter_1_) {
    return Unit_getInstance();
  };
  _no_name_provided__259.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE();
    this.invoke_496(tmp, (p2 == null ? true : isObject(p2)) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__259.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Cache_1(maxSize, free) {
    this._map_1 = CacheMap_init_$Create$(maxSize, free);
    this._cacheHitCounter = 0;
    this._cacheMissCounter = 0;
  }
  Cache_1.prototype.get_20 = function (key) {
    var result = this._map_1.get_20(key);
    if (!(result == null)) {
      var tmp0_this = this;
      var tmp1 = tmp0_this._cacheHitCounter;
      tmp0_this._cacheHitCounter = tmp1 + 1 | 0;
      Unit_getInstance();
    }return result;
  };
  Cache_1.prototype.set_5 = function (key, value) {
    var tmp0_set_0 = this._map_1;
    tmp0_set_0.put_6(key, value);
    Unit_getInstance();
  };
  Cache_1.prototype.store = function (key, value) {
    return this._map_1.put_6(key, value);
  };
  Cache_1.prototype.clear_27 = function () {
    this._map_1.clear_27();
  };
  Cache_1.prototype.toString = function () {
    return this._map_1.toString();
  };
  Cache_1.$metadata$ = {
    simpleName: 'Cache',
    kind: 'class',
    interfaces: []
  };
  function cache(description, maxSize) {
    var tmp0_let_0 = cacheStatsHandler;
    var tmp;
    if (!(tmp0_let_0 == null)) {
      var tmp0_also_0_2 = new Cache_1(maxSize, _no_name_provided_$factory_251(tmp0_let_0, description));
      tmp0_let_0.cacheCreated__error(description, tmp0_also_0_2);
      tmp = tmp0_also_0_2;
    } else {
      tmp = Cache_init_$Create$_0(maxSize, null, 2, null);
    }
    return tmp;
  }
  function _no_name_provided__260($tmp0_let_0, $description) {
    this._$tmp0_let_0 = $tmp0_let_0;
    this._$description = $description;
  }
  _no_name_provided__260.prototype.invoke_496 = function (k, v) {
    this._$tmp0_let_0.freed__error(this._$description, k, v);
  };
  _no_name_provided__260.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE();
    this.invoke_496(tmp, (p2 == null ? true : isObject(p2)) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__260.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_250() {
    var i = new _no_name_provided__259();
    return function (p1, p2) {
      i.invoke_496(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_251($tmp0_let_0, $description) {
    var i = new _no_name_provided__260($tmp0_let_0, $description);
    return function (p1, p2) {
      i.invoke_496(p1, p2);
      return Unit_getInstance();
    };
  }
  function CacheMap_init_$Init$(maxSize, free, $this) {
    CacheMap.call($this, LinkedHashMap_init_$Create$(), maxSize, free);
    return $this;
  }
  function CacheMap_init_$Create$(maxSize, free) {
    return CacheMap_init_$Init$(maxSize, free, Object.create(CacheMap.prototype));
  }
  function CacheMap(map, maxSize, free) {
    this._map_2 = map;
    this._maxSize = maxSize;
    this._free = free;
  }
  CacheMap.prototype.containsKey_6 = function (key) {
    return this._map_2.containsKey_6(key);
  };
  CacheMap.prototype.get_20 = function (key) {
    return this._map_2.get_20(key);
  };
  CacheMap.prototype.isEmpty_33 = function () {
    return this._map_2.isEmpty_33();
  };
  CacheMap.prototype._get_entries__5 = function () {
    return this._map_2._get_entries__5();
  };
  CacheMap.prototype._get_values__3 = function () {
    return this._map_2._get_values__3();
  };
  CacheMap.prototype._get_size__43 = function () {
    return this._map_2._get_size__43();
  };
  CacheMap.prototype.remove_58 = function (key) {
    var value = this._map_2.remove_58(key);
    if (!(value == null))
      this._free(key, value);
    return value;
  };
  CacheMap.prototype.putAll_3 = function (from) {
    var tmp0_iterator_2 = from._get_entries__5().iterator_38();
    while (tmp0_iterator_2.hasNext_11()) {
      var tmp1_loop_parameter_3 = tmp0_iterator_2.next_13();
      var k_4 = tmp1_loop_parameter_3._get_key__2();
      var v_5 = tmp1_loop_parameter_3._get_value__18();
      this.put_6(k_4, v_5);
      Unit_getInstance();
    }
    return Unit_getInstance();
  };
  CacheMap.prototype.put_6 = function (key, value) {
    if (this._get_size__43() >= this._maxSize ? !this._map_2.containsKey_6(key) : false) {
      this.remove_58(first(this._map_2._get_keys__1()));
      Unit_getInstance();
    }var oldValue = this._map_2.get_20(key);
    if (!equals_0(oldValue, value)) {
      this.remove_58(key);
      Unit_getInstance();
      var tmp0_set_0 = this._map_2;
      tmp0_set_0.put_6(key, value);
      Unit_getInstance();
    }return oldValue;
  };
  CacheMap.prototype.clear_27 = function () {
    var keys = toList(this._map_2._get_keys__1());
    var tmp0_iterator = keys.iterator_38();
    while (tmp0_iterator.hasNext_11()) {
      var key = tmp0_iterator.next_13();
      this.remove_58(key);
      Unit_getInstance();
    }
  };
  CacheMap.prototype.toString = function () {
    return this._map_2.toString();
  };
  CacheMap.prototype.equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof CacheMap) {
      tmp_0 = this._map_2.equals(other._map_2);
    } else {
      {
        tmp_0 = false;
      }
    }
    if (tmp_0) {
      tmp = equals_0(this._free, other._free);
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  CacheMap.prototype.hashCode = function () {
    return this._map_2.hashCode() + this._maxSize | 0;
  };
  CacheMap.$metadata$ = {
    simpleName: 'CacheMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function EvictingQueue(maxSize) {
    this._maxSize_0 = maxSize;
    this._delegate_0 = ArrayDeque_init_$Create$();
    var tmp0_require_0 = this._maxSize_0 > 0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Max size must be > 0 but was <' + this._maxSize_0 + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }}
  EvictingQueue.prototype._get_size__43 = function () {
    return this._delegate_0._size;
  };
  EvictingQueue.prototype.contains_31 = function (element) {
    return this._delegate_0.contains_31(element);
  };
  EvictingQueue.prototype.containsAll_19 = function (elements) {
    return this._delegate_0.containsAll_19(elements);
  };
  EvictingQueue.prototype.isEmpty_33 = function () {
    return this._delegate_0.isEmpty_33();
  };
  EvictingQueue.prototype.iterator_38 = function () {
    return this._delegate_0.iterator_38();
  };
  EvictingQueue.prototype.add_57 = function (element) {
    while (this._get_size__43() >= this._maxSize_0) {
      this._delegate_0.removeFirst();
      Unit_getInstance();
    }
    return this._delegate_0.add_57(element);
  };
  EvictingQueue.prototype.addAll_43 = function (elements) {
    if (elements.isEmpty_33()) {
      return false;
    }if (elements._get_size__43() >= this._maxSize_0) {
      this.clear_27();
      return this._delegate_0.addAll_43(drop(elements, elements._get_size__43() - this._maxSize_0 | 0));
    }while (this._get_size__43() > (this._maxSize_0 - elements._get_size__43() | 0)) {
      this._delegate_0.removeFirst();
      Unit_getInstance();
    }
    return this._delegate_0.addAll_43(elements);
  };
  EvictingQueue.prototype.clear_27 = function () {
    return this._delegate_0.clear_27();
  };
  EvictingQueue.prototype.remove_59 = function (element) {
    return this._delegate_0.remove_59(element);
  };
  EvictingQueue.$metadata$ = {
    simpleName: 'EvictingQueue',
    kind: 'class',
    interfaces: [MutableCollection]
  };
  function DateFormat() {
  }
  DateFormat.$metadata$ = {
    simpleName: 'DateFormat',
    kind: 'interface',
    interfaces: []
  };
  var DayOfWeek_Sunday_instance;
  var DayOfWeek_Monday_instance;
  var DayOfWeek_Tuesday_instance;
  var DayOfWeek_Wednesday_instance;
  var DayOfWeek_Thursday_instance;
  var DayOfWeek_Friday_instance;
  var DayOfWeek_Saturday_instance;
  function Companion_21() {
    Companion_instance_20 = this;
    this._serialVersionUID_2 = new Long(1, 0);
    this._Count = 7;
    this._BY_INDEX0 = values();
  }
  Companion_21.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_20;
  function Companion_getInstance_20() {
    if (Companion_instance_20 == null)
      new Companion_21();
    return Companion_instance_20;
  }
  function values() {
    return [DayOfWeek_Sunday_getInstance(), DayOfWeek_Monday_getInstance(), DayOfWeek_Tuesday_getInstance(), DayOfWeek_Wednesday_getInstance(), DayOfWeek_Thursday_getInstance(), DayOfWeek_Friday_getInstance(), DayOfWeek_Saturday_getInstance()];
  }
  var DayOfWeek_entriesInitialized;
  function DayOfWeek_initEntries() {
    if (DayOfWeek_entriesInitialized)
      return Unit_getInstance();
    DayOfWeek_entriesInitialized = true;
    DayOfWeek_Sunday_instance = new DayOfWeek('Sunday', 0, 0);
    DayOfWeek_Monday_instance = new DayOfWeek('Monday', 1, 1);
    DayOfWeek_Tuesday_instance = new DayOfWeek('Tuesday', 2, 2);
    DayOfWeek_Wednesday_instance = new DayOfWeek('Wednesday', 3, 3);
    DayOfWeek_Thursday_instance = new DayOfWeek('Thursday', 4, 4);
    DayOfWeek_Friday_instance = new DayOfWeek('Friday', 5, 5);
    DayOfWeek_Saturday_instance = new DayOfWeek('Saturday', 6, 6);
    Companion_getInstance_20();
  }
  function DayOfWeek(name, ordinal, index0) {
    Enum.call(this, name, ordinal);
    this._index0 = index0;
  }
  DayOfWeek.$metadata$ = {
    simpleName: 'DayOfWeek',
    kind: 'class',
    interfaces: [Serializable_0]
  };
  function DayOfWeek_Sunday_getInstance() {
    DayOfWeek_initEntries();
    return DayOfWeek_Sunday_instance;
  }
  function DayOfWeek_Monday_getInstance() {
    DayOfWeek_initEntries();
    return DayOfWeek_Monday_instance;
  }
  function DayOfWeek_Tuesday_getInstance() {
    DayOfWeek_initEntries();
    return DayOfWeek_Tuesday_instance;
  }
  function DayOfWeek_Wednesday_getInstance() {
    DayOfWeek_initEntries();
    return DayOfWeek_Wednesday_instance;
  }
  function DayOfWeek_Thursday_getInstance() {
    DayOfWeek_initEntries();
    return DayOfWeek_Thursday_instance;
  }
  function DayOfWeek_Friday_getInstance() {
    DayOfWeek_initEntries();
    return DayOfWeek_Friday_instance;
  }
  function DayOfWeek_Saturday_getInstance() {
    DayOfWeek_initEntries();
    return DayOfWeek_Saturday_instance;
  }
  var KlockLocale_default;
  function Companion_22() {
    Companion_instance_21 = this;
    English.call(this);
  }
  Companion_22.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_21;
  function Companion_getInstance_21() {
    if (Companion_instance_21 == null)
      new Companion_22();
    return Companion_instance_21;
  }
  function Companion_23() {
    Companion_instance_22 = this;
  }
  Companion_23.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_22;
  function Companion_getInstance_22() {
    if (Companion_instance_22 == null)
      new Companion_23();
    return Companion_instance_22;
  }
  function English() {
    Companion_getInstance_21();
    KlockLocale.call(this);
    this._ISO639_1 = 'en';
    this._firstDayOfWeek = DayOfWeek_Sunday_getInstance();
    this._daysOfWeek = listOf(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
    this._months = listOf(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
    this._formatTimeMedium = this.format_1('h:mm:ss a');
    this._formatTimeShort = this.format_1('h:mm a');
  }
  English.prototype._get_daysOfWeek__1 = function () {
    return this._daysOfWeek;
  };
  English.prototype._get_months__1 = function () {
    return this._months;
  };
  English.$metadata$ = {
    simpleName: 'English',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__261(this$0) {
    this._this$0_200 = this$0;
  }
  _no_name_provided__261.prototype.invoke_1038 = function () {
    var tmp0_map_0 = this._this$0_200._get_months__1();
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      tmp0_mapTo_0_1.add_57(substr(item_2_3, 0, 3));
      Unit_getInstance();
    }
    return tmp0_mapTo_0_1;
  };
  _no_name_provided__261.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__262(this$0) {
    this._this$0_201 = this$0;
  }
  _no_name_provided__262.prototype.invoke_1038 = function () {
    var tmp0_map_0 = this._this$0_201._get_daysOfWeek__1();
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      tmp0_mapTo_0_1.add_57(substr(item_2_3, 0, 3));
      Unit_getInstance();
    }
    return tmp0_mapTo_0_1;
  };
  _no_name_provided__262.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function KlockLocale() {
    Companion_getInstance_22();
    var tmp = this;
    tmp._monthsShort$delegate = klockLazyOrGet(_no_name_provided_$factory_252(this));
    var tmp_0 = this;
    tmp_0._daysOfWeekShort$delegate = klockLazyOrGet(_no_name_provided_$factory_253(this));
    var tmp_1 = this;
    var tmp_2 = 0;
    var tmp_3 = 32;
    var tmp_4 = fillArrayVal(Array(tmp_3), null);
    while (tmp_2 < tmp_3) {
      var tmp_5 = tmp_2;
      var tmp_6;
      if (11 <= tmp_5 ? tmp_5 <= 13 : false) {
        tmp_6 = '' + tmp_5 + 'th';
      } else {
        var tmp0_subject = tmp_5 % 10;
        switch (tmp0_subject) {
          case 1:
            tmp_6 = '' + tmp_5 + 'st';
            break;
          case 2:
            tmp_6 = '' + tmp_5 + 'nd';
            break;
          case 3:
            tmp_6 = '' + tmp_5 + 'rd';
            break;
          default:tmp_6 = '' + tmp_5 + 'th';
            break;
        }
      }
      tmp_4[tmp_5] = tmp_6;
      tmp_2 = tmp_2 + 1 | 0;
    }
    tmp_1._ordinals = tmp_4;
    this._h12Marker = listOf(['AM', 'OM']);
    this._formatDateTimeMedium = this.format_1('MMM d, y h:mm:ss a');
    this._formatDateTimeShort = this.format_1('M/d/yy h:mm a');
    this._formatDateFull = this.format_1('EEEE, MMMM d, y');
    this._formatDateLong = this.format_1('MMMM d, y');
    this._formatDateMedium = this.format_1('MMM d, y');
    this._formatDateShort = this.format_1('M/d/yy');
    this._formatTimeMedium_0 = this.format_1('HH:mm:ss');
    this._formatTimeShort_0 = this.format_1('HH:mm');
  }
  KlockLocale.prototype.format_1 = function (str) {
    return PatternDateFormat_init_$Create$(str, this, null, null, 12, null);
  };
  KlockLocale.$metadata$ = {
    simpleName: 'KlockLocale',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_252(this$0) {
    var i = new _no_name_provided__261(this$0);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_253(this$0) {
    var i = new _no_name_provided__262(this$0);
    return function () {
      return i.invoke_1038();
    };
  }
  function Companion_24() {
    Companion_instance_23 = this;
    this._serialVersionUID_3 = new Long(1, 0);
    this._DEFAULT = new Options(false);
    this._WITH_OPTIONAL = new Options(true);
  }
  Companion_24.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_23;
  function Companion_getInstance_23() {
    if (Companion_instance_23 == null)
      new Companion_24();
    return Companion_instance_23;
  }
  function PatternDateFormat_init_$Init$(format, locale, tzNames, options, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      locale = null;
    if (!(($mask0 & 4) === 0))
      tzNames = Companion_getInstance_26()._DEFAULT_0;
    if (!(($mask0 & 8) === 0))
      options = Companion_getInstance_23()._DEFAULT;
    PatternDateFormat.call($this, format, locale, tzNames, options);
    return $this;
  }
  function PatternDateFormat_init_$Create$(format, locale, tzNames, options, $mask0, $marker) {
    return PatternDateFormat_init_$Init$(format, locale, tzNames, options, $mask0, $marker, Object.create(PatternDateFormat.prototype));
  }
  function Companion_25() {
    Companion_instance_24 = this;
    this._serialVersionUID_4 = new Long(1, 0);
  }
  Companion_25.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_24;
  function Companion_getInstance_24() {
    if (Companion_instance_24 == null)
      new Companion_25();
    return Companion_instance_24;
  }
  function Options(optionalSupport) {
    Companion_getInstance_23();
    this._optionalSupport = optionalSupport;
  }
  Options.prototype.toString = function () {
    return '' + 'Options(optionalSupport=' + this._optionalSupport + ')';
  };
  Options.prototype.hashCode = function () {
    return this._optionalSupport | 0;
  };
  Options.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Options))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Options ? other : THROW_CCE();
    if (!(this._optionalSupport === tmp0_other_with_cast._optionalSupport))
      return false;
    return true;
  };
  Options.$metadata$ = {
    simpleName: 'Options',
    kind: 'class',
    interfaces: [Serializable_0]
  };
  function PatternDateFormat(format, locale, tzNames, options) {
    Companion_getInstance_24();
    this._format = format;
    this._locale = locale;
    this._tzNames = tzNames;
    this._options_0 = options;
    this._openOffsets = LinkedHashMap_init_$Create$();
    this._closeOffsets = LinkedHashMap_init_$Create$();
    var tmp = this;
    var tmp0_also_0 = ArrayList_init_$Create$();
    var s_2 = MicroStrReader_init_$Create$(this._format, 0, 2, null);
    while (s_2._get_hasMore_()) {
      if (s_2.peekChar().equals(new Char(39))) {
        var start_1_4 = s_2._offset_0;
        s_2.tryRead(new Char(39));
        Unit_getInstance();
        while (s_2._get_hasMore_() ? !s_2.readChar().equals(new Char(39)) : false)
          Unit_getInstance();
        var end_2_5 = s_2._offset_0;
        var tmp0_substring_0_6 = s_2._str;
        var escapedChunk_3 = tmp0_substring_0_6.substring(start_1_4, end_2_5);
        tmp0_also_0.add_57(escapedChunk_3);
        Unit_getInstance();
        continue;
      }if (this._options_0._optionalSupport) {
        var offset_7 = tmp0_also_0._get_size__43();
        if (s_2.tryRead(new Char(91))) {
          increment(this._openOffsets, offset_7);
          continue;
        }if (s_2.tryRead(new Char(93))) {
          increment(this._closeOffsets, offset_7 - 1 | 0);
          continue;
        }}var tmp0_elvis_lhs_8 = s_2.tryReadOrNull('do');
      tmp0_also_0.add_57(tmp0_elvis_lhs_8 == null ? readRepeatedChar(s_2) : tmp0_elvis_lhs_8);
      Unit_getInstance();
    }
    tmp._chunks = toList(tmp0_also_0);
    var tmp_0 = this;
    var tmp0_map_0 = this._chunks;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      var tmp0_subject_5 = item_2_3;
      var tmp_1;
      switch (tmp0_subject_5) {
        case 'E':
        case 'EE':
        case 'EEE':
        case 'EEEE':
        case 'EEEEE':
        case 'EEEEEE':
          tmp_1 = '(\\w+)';
          break;
        case 'z':
        case 'zzz':
          tmp_1 = '([\\w\\s\\-\\+\\:]+)';
          break;
        case 'do':
          tmp_1 = '(\\d{1,2}\\w+)';
          break;
        case 'd':
          tmp_1 = '(\\d{1,2})';
          break;
        case 'dd':
          tmp_1 = '(\\d{2})';
          break;
        case 'M':
          tmp_1 = '(\\d{1,5})';
          break;
        case 'MM':
          tmp_1 = '(\\d{2})';
          break;
        case 'MMM':
        case 'MMMM':
        case 'MMMMM':
          tmp_1 = '(\\w+)';
          break;
        case 'y':
          tmp_1 = '(\\d{1,5})';
          break;
        case 'yy':
          tmp_1 = '(\\d{2})';
          break;
        case 'yyy':
          tmp_1 = '(\\d{3})';
          break;
        case 'yyyy':
          tmp_1 = '(\\d{4})';
          break;
        case 'YYYY':
          tmp_1 = '(\\d{4})';
          break;
        case 'H':
        case 'k':
          tmp_1 = '(\\d{1,2})';
          break;
        case 'HH':
        case 'kk':
          tmp_1 = '(\\d{2})';
          break;
        case 'h':
        case 'K':
          tmp_1 = '(\\d{1,2})';
          break;
        case 'hh':
        case 'KK':
          tmp_1 = '(\\d{2})';
          break;
        case 'm':
          tmp_1 = '(\\d{1,2})';
          break;
        case 'mm':
          tmp_1 = '(\\d{2})';
          break;
        case 's':
          tmp_1 = '(\\d{1,2})';
          break;
        case 'ss':
          tmp_1 = '(\\d{2})';
          break;
        case 'S':
          tmp_1 = '(\\d{1,6})';
          break;
        case 'SS':
          tmp_1 = '(\\d{2})';
          break;
        case 'SSS':
          tmp_1 = '(\\d{3})';
          break;
        case 'SSSS':
          tmp_1 = '(\\d{4})';
          break;
        case 'SSSSS':
          tmp_1 = '(\\d{5})';
          break;
        case 'SSSSSS':
          tmp_1 = '(\\d{6})';
          break;
        case 'SSSSSSS':
          tmp_1 = '(\\d{7})';
          break;
        case 'SSSSSSSS':
          tmp_1 = '(\\d{8})';
          break;
        case 'SSSSSSSSS':
          tmp_1 = '(\\d{9})';
          break;
        case 'X':
        case 'XX':
        case 'XXX':
        case 'x':
        case 'xx':
        case 'xxx':
          tmp_1 = '([\\w:\\+\\-]+)';
          break;
        case 'a':
          tmp_1 = '(\\w+)';
          break;
        case ' ':
          tmp_1 = '(\\s+)';
          break;
        default:var tmp_2;
          var tmp_3 = new Char(39);
          if (startsWith$default(item_2_3, tmp_3, false, 2, null)) {
            tmp_2 = '(' + Companion_getInstance_6().escapeReplacement(substr(item_2_3, 1, item_2_3.length - 2 | 0)) + ')';
          } else {
            {
              tmp_2 = '(' + Companion_getInstance_6().escapeReplacement(item_2_3) + ')';
            }
          }

          tmp_1 = tmp_2;
          break;
      }
      tmp0_mapTo_0_1.add_57(tmp_1);
      Unit_getInstance();
    }
    tmp_0._regexChunks = tmp0_mapTo_0_1;
    var tmp_4 = this;
    var tmp0_mapIndexed_0 = this._regexChunks;
    var tmp0_mapIndexedTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_mapIndexed_0, 10));
    var index_1_2 = 0;
    var tmp0_iterator_2_3 = tmp0_mapIndexed_0.iterator_38();
    while (tmp0_iterator_2_3.hasNext_11()) {
      var item_3_4 = tmp0_iterator_2_3.next_13();
      var tmp1_4_5 = index_1_2;
      index_1_2 = tmp1_4_5 + 1 | 0;
      var tmp1__anonymous__6 = checkIndexOverflow(tmp1_4_5);
      var tmp_5;
      if (this._options_0._optionalSupport) {
        var tmp0_getOrElse_0_8 = this._openOffsets;
        var tmp0_elvis_lhs_1_9 = tmp0_getOrElse_0_8.get_20(tmp1__anonymous__6);
        var tmp_6;
        if (tmp0_elvis_lhs_1_9 == null) {
          tmp_6 = 0;
        } else {
          tmp_6 = tmp0_elvis_lhs_1_9;
        }
        var opens_7 = tmp_6;
        var tmp1_getOrElse_0_11 = this._closeOffsets;
        var tmp0_elvis_lhs_1_12 = tmp1_getOrElse_0_11.get_20(tmp1__anonymous__6);
        var tmp_7;
        if (tmp0_elvis_lhs_1_12 == null) {
          tmp_7 = 0;
        } else {
          tmp_7 = tmp0_elvis_lhs_1_12;
        }
        var closes_10 = tmp_7;
        var tmp0_apply_0_1_13 = StringBuilder_init_$Create$_0();
        var inductionVariable = 0;
        if (inductionVariable < opens_7)
          do {
            var index_2_4_15 = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            tmp0_apply_0_1_13.append_4('(?:');
            Unit_getInstance();
          }
           while (inductionVariable < opens_7);
        tmp0_apply_0_1_13.append_4(item_3_4);
        Unit_getInstance();
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < closes_10)
          do {
            var index_2_6_17 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            tmp0_apply_0_1_13.append_4(')?');
            Unit_getInstance();
          }
           while (inductionVariable_0 < closes_10);
        tmp_5 = tmp0_apply_0_1_13.toString();
      } else {
        tmp_5 = item_3_4;
      }
      tmp0_mapIndexedTo_0_1.add_57(tmp_5);
      Unit_getInstance();
    }
    tmp_4._rx2 = Regex_init_$Create$('^' + joinToString$default_0(tmp0_mapIndexedTo_0_1, '', null, null, 0, null, null, 62, null) + '$');
  }
  PatternDateFormat.prototype.toString = function () {
    return this._format;
  };
  PatternDateFormat.prototype.hashCode = function () {
    var result = getStringHashCode(this._format);
    result = imul(result, 31) + (this._locale == null ? 0 : hashCode(this._locale)) | 0;
    result = imul(result, 31) + this._tzNames.hashCode() | 0;
    result = imul(result, 31) + this._options_0.hashCode() | 0;
    return result;
  };
  PatternDateFormat.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PatternDateFormat))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof PatternDateFormat ? other : THROW_CCE();
    if (!(this._format === tmp0_other_with_cast._format))
      return false;
    if (!equals_0(this._locale, tmp0_other_with_cast._locale))
      return false;
    if (!this._tzNames.equals(tmp0_other_with_cast._tzNames))
      return false;
    if (!this._options_0.equals(tmp0_other_with_cast._options_0))
      return false;
    return true;
  };
  PatternDateFormat.$metadata$ = {
    simpleName: 'PatternDateFormat',
    kind: 'class',
    interfaces: [DateFormat, Serializable_0]
  };
  function readRepeatedChar(_this_) {
    var start_1 = _this_._offset_0;
    var c_4 = _this_.readChar();
    while (_this_._get_hasMore_() ? _this_.tryRead(c_4) : false)
      Unit_getInstance();
    var end_2 = _this_._offset_0;
    var tmp0_substring_0 = _this_._str;
    return tmp0_substring_0.substring(start_1, end_2);
  }
  function _TimeSpan___init__impl_(milliseconds) {
    return milliseconds;
  }
  function _TimeSpan___get_milliseconds__impl_(this_0) {
    return this_0;
  }
  function TimeSpan__compareTo_impl(this_0, other) {
    return compareTo(_TimeSpan___get_milliseconds__impl_(this_0), _TimeSpan___get_milliseconds__impl_(other));
  }
  function TimeSpan__compareTo_impl_0(this_0, other) {
    var tmp = this_0._milliseconds;
    return TimeSpan__compareTo_impl(tmp, other instanceof TimeSpan ? other._milliseconds : THROW_CCE());
  }
  function TimeSpan__plus_impl(this_0, other) {
    return _TimeSpan___init__impl_(_TimeSpan___get_milliseconds__impl_(this_0) + _TimeSpan___get_milliseconds__impl_(other));
  }
  function Companion_26() {
    Companion_instance_25 = this;
    this._serialVersionUID_5 = new Long(1, 0);
    this._MILLIS_PER_MICROSECOND = 0.001;
    this._MILLIS_PER_NANOSECOND = 1.0E-6;
    this._ZERO_0 = _TimeSpan___init__impl_(0.0);
    this._NIL = _TimeSpan___init__impl_(NaN);
    this._timeSteps = listOf([60, 60, 24]);
  }
  Companion_26.prototype.fromMilliseconds = function (ms) {
    var tmp0_subject = ms;
    return tmp0_subject === 0.0 ? this._ZERO_0 : _TimeSpan___init__impl_(ms);
  };
  Companion_26.prototype.fromMinutes = function (s) {
    return this.fromMilliseconds(s * 60000);
  };
  Companion_26.prototype.fromHours = function (s) {
    return this.fromMilliseconds(s * 3600000);
  };
  Companion_26.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_25;
  function Companion_getInstance_25() {
    if (Companion_instance_25 == null)
      new Companion_26();
    return Companion_instance_25;
  }
  function TimeSpan__toString_impl(this_0) {
    return '' + _get_niceStr_(_TimeSpan___get_milliseconds__impl_(this_0)) + 'ms';
  }
  function TimeSpan__hashCode_impl(this_0) {
    return getNumberHashCode(this_0);
  }
  function TimeSpan__equals_impl(this_0, other) {
    if (!(other instanceof TimeSpan))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof TimeSpan ? other._milliseconds : THROW_CCE();
    if (!equals_0(this_0, tmp0_other_with_cast))
      return false;
    return true;
  }
  function TimeSpan(milliseconds) {
    Companion_getInstance_25();
    this._milliseconds = milliseconds;
  }
  TimeSpan.prototype.compareTo_25 = function (other) {
    return TimeSpan__compareTo_impl(this._milliseconds, other);
  };
  TimeSpan.prototype.compareTo_42 = function (other) {
    return TimeSpan__compareTo_impl_0(this, other);
  };
  TimeSpan.prototype.toString = function () {
    return TimeSpan__toString_impl(this._milliseconds);
  };
  TimeSpan.prototype.hashCode = function () {
    return TimeSpan__hashCode_impl(this._milliseconds);
  };
  TimeSpan.prototype.equals = function (other) {
    return TimeSpan__equals_impl(this._milliseconds, other);
  };
  TimeSpan.$metadata$ = {
    simpleName: 'TimeSpan',
    kind: 'class',
    interfaces: [Comparable, Serializable_0]
  };
  function TimezoneNames_init_$Init$(tz, $this) {
    TimezoneNames.call($this, toMap_0(tz));
    return $this;
  }
  function TimezoneNames_init_$Create$(tz) {
    return TimezoneNames_init_$Init$(tz, Object.create(TimezoneNames.prototype));
  }
  function Companion_27() {
    Companion_instance_26 = this;
    this._serialVersionUID_6 = new Long(1, 0);
    var tmp = this;
    var tmp0__get_hours__0 = -7;
    var tmp_0 = to('PDT', new TimeSpan(Companion_getInstance_25().fromHours(tmp0__get_hours__0)));
    var tmp1__get_hours__0 = -8;
    var tmp_1 = to('PST', new TimeSpan(Companion_getInstance_25().fromHours(tmp1__get_hours__0)));
    var tmp2__get_hours__0 = 0;
    var tmp_2 = to('GMT', new TimeSpan(Companion_getInstance_25().fromHours(tmp2__get_hours__0)));
    var tmp3__get_hours__0 = 0;
    tmp._DEFAULT_0 = TimezoneNames_init_$Create$([tmp_0, tmp_1, tmp_2, to('UTC', new TimeSpan(Companion_getInstance_25().fromHours(tmp3__get_hours__0)))]);
  }
  Companion_27.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_26;
  function Companion_getInstance_26() {
    if (Companion_instance_26 == null)
      new Companion_27();
    return Companion_instance_26;
  }
  function TimezoneNames(namesToOffsets) {
    Companion_getInstance_26();
    this._namesToOffsets = namesToOffsets;
  }
  TimezoneNames.prototype.toString = function () {
    return '' + 'TimezoneNames(namesToOffsets=' + this._namesToOffsets + ')';
  };
  TimezoneNames.prototype.hashCode = function () {
    return hashCode(this._namesToOffsets);
  };
  TimezoneNames.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TimezoneNames))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof TimezoneNames ? other : THROW_CCE();
    if (!equals_0(this._namesToOffsets, tmp0_other_with_cast._namesToOffsets))
      return false;
    return true;
  };
  TimezoneNames.$metadata$ = {
    simpleName: 'TimezoneNames',
    kind: 'class',
    interfaces: [Serializable_0]
  };
  function increment(_this_, key) {
    var value_1 = _this_.get_20(key);
    if (value_1 == null) {
      var answer_2 = 0;
      _this_.put_6(key, answer_2);
      Unit_getInstance();
    } else {
    }
    Unit_getInstance();
    var tmp0_set_0 = ensureNotNull(_this_.get_20(key)) + 1 | 0;
    _this_.put_6(key, tmp0_set_0);
    Unit_getInstance();
  }
  function MicroStrReader_init_$Init$(str, offset_0, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      offset_0 = 0;
    MicroStrReader.call($this, str, offset_0);
    return $this;
  }
  function MicroStrReader_init_$Create$(str, offset_0, $mask0, $marker) {
    return MicroStrReader_init_$Init$(str, offset_0, $mask0, $marker, Object.create(MicroStrReader.prototype));
  }
  function MicroStrReader(str, offset_0) {
    this._str = str;
    this._offset_0 = offset_0;
  }
  MicroStrReader.prototype._get_available_ = function () {
    return this._str.length - this._offset_0 | 0;
  };
  MicroStrReader.prototype._get_hasMore_ = function () {
    return this._offset_0 < this._str.length;
  };
  MicroStrReader.prototype._get_eof_ = function () {
    return !this._get_hasMore_();
  };
  MicroStrReader.prototype.peekChar = function () {
    return charSequenceGet(this._str, this._offset_0);
  };
  MicroStrReader.prototype.readChar = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._offset_0;
    tmp0_this._offset_0 = tmp1 + 1 | 0;
    return charSequenceGet(this._str, tmp1);
  };
  MicroStrReader.prototype.tryRead = function (expected) {
    if (this._get_eof_() ? true : !this.peekChar().equals(expected))
      return false;
    this.readChar();
    Unit_getInstance();
    return true;
  };
  MicroStrReader.prototype.tryReadOrNull = function (expected) {
    return this.tryRead_0(expected) ? expected : null;
  };
  MicroStrReader.prototype.tryRead_0 = function (expected) {
    if (expected.length > this._get_available_())
      return false;
    var inductionVariable = 0;
    var last_1 = charSequenceLength(expected) - 1 | 0;
    if (inductionVariable <= last_1)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!charSequenceGet(this._str, this._offset_0 + n | 0).equals(charSequenceGet(expected, n)))
          return false;
      }
       while (inductionVariable <= last_1);
    var tmp1_this = this;
    tmp1_this._offset_0 = tmp1_this._offset_0 + expected.length | 0;
    return true;
  };
  MicroStrReader.$metadata$ = {
    simpleName: 'MicroStrReader',
    kind: 'class',
    interfaces: []
  };
  function _get_niceStr_(_this_) {
    var tmp;
    var tmp0_unsafeCast_0_1 = Math.floor(_this_);
    if (tmp0_unsafeCast_0_1 === _this_) {
      tmp = '' + numberToInt(_this_);
    } else {
      {
        tmp = '' + _this_;
      }
    }
    return tmp;
  }
  function substr(_this_, start, length) {
    var low = clamp(start >= 0 ? start : _this_.length + start | 0, 0, _this_.length);
    var high = clamp(length >= 0 ? low + length | 0 : _this_.length + length | 0, 0, _this_.length);
    var tmp;
    if (high < low) {
      tmp = '';
    } else {
      tmp = _this_.substring(low, high);
    }
    return tmp;
  }
  function clamp(_this_, min, max) {
    return _this_ < min ? min : _this_ > max ? max : _this_;
  }
  var ExtendedTimezoneNames;
  function utc(hours, minutes) {
    var tmp = Companion_getInstance_25().fromHours(hours);
    return TimeSpan__plus_impl(tmp, Companion_getInstance_25().fromMinutes(minutes));
  }
  function utc$default(hours, minutes, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      minutes = 0;
    return utc(hours, minutes);
  }
  function ExtendedTimezoneNames$init$() {
    var tmp = to('ACDT', new TimeSpan(utc(10, 30)));
    var tmp_0 = to('ACST', new TimeSpan(utc(9, 30)));
    var tmp_1 = to('ACT', new TimeSpan(utc$default(-5, 0, 2, null)));
    var tmp_2 = to('ACWST', new TimeSpan(utc(8, 45)));
    var tmp_3 = to('ADT', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_4 = to('AEDT', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_5 = to('AEST', new TimeSpan(utc$default(10, 0, 2, null)));
    var tmp_6 = to('AFT', new TimeSpan(utc(4, 30)));
    var tmp_7 = to('AKDT', new TimeSpan(utc$default(-8, 0, 2, null)));
    var tmp_8 = to('AKST', new TimeSpan(utc$default(-9, 0, 2, null)));
    var tmp_9 = to('ALMT', new TimeSpan(utc$default(6, 0, 2, null)));
    var tmp_10 = to('AMST', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_11 = to('AMT', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_12 = to('AMT', new TimeSpan(utc$default(4, 0, 2, null)));
    var tmp_13 = to('ANAT', new TimeSpan(utc$default(12, 0, 2, null)));
    var tmp_14 = to('AQTT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_15 = to('ART', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_16 = to('AST', new TimeSpan(utc$default(3, 0, 2, null)));
    var tmp_17 = to('AST', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_18 = to('AWST', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_19 = to('AZOST', new TimeSpan(utc$default(0, 0, 2, null)));
    var tmp_20 = to('AZOT', new TimeSpan(utc$default(-1, 0, 2, null)));
    var tmp_21 = to('AZT', new TimeSpan(utc$default(4, 0, 2, null)));
    var tmp_22 = to('BDT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_23 = to('BIOT', new TimeSpan(utc$default(6, 0, 2, null)));
    var tmp_24 = to('BIT', new TimeSpan(utc$default(-12, 0, 2, null)));
    var tmp_25 = to('BOT', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_26 = to('BRST', new TimeSpan(utc$default(-2, 0, 2, null)));
    var tmp_27 = to('BRT', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_28 = to('BST', new TimeSpan(utc$default(6, 0, 2, null)));
    var tmp_29 = to('BST', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_30 = to('BST', new TimeSpan(utc$default(1, 0, 2, null)));
    var tmp_31 = to('BTT', new TimeSpan(utc$default(6, 0, 2, null)));
    var tmp_32 = to('CAT', new TimeSpan(utc$default(2, 0, 2, null)));
    var tmp_33 = to('CCT', new TimeSpan(utc(6, 30)));
    var tmp_34 = to('CDT', new TimeSpan(utc$default(-5, 0, 2, null)));
    var tmp_35 = to('CDT', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_36 = to('CEST', new TimeSpan(utc$default(2, 0, 2, null)));
    var tmp_37 = to('CET', new TimeSpan(utc$default(1, 0, 2, null)));
    var tmp_38 = to('CHADT', new TimeSpan(utc(13, 45)));
    var tmp_39 = to('CHAST', new TimeSpan(utc(12, 45)));
    var tmp_40 = to('CHOT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_41 = to('CHOST', new TimeSpan(utc$default(9, 0, 2, null)));
    var tmp_42 = to('CHST', new TimeSpan(utc$default(10, 0, 2, null)));
    var tmp_43 = to('CHUT', new TimeSpan(utc$default(10, 0, 2, null)));
    var tmp_44 = to('CIST', new TimeSpan(utc$default(-8, 0, 2, null)));
    var tmp_45 = to('CIT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_46 = to('CKT', new TimeSpan(utc$default(-10, 0, 2, null)));
    var tmp_47 = to('CLST', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_48 = to('CLT', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_49 = to('COST', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_50 = to('COT', new TimeSpan(utc$default(-5, 0, 2, null)));
    var tmp_51 = to('CST', new TimeSpan(utc$default(-6, 0, 2, null)));
    var tmp_52 = to('CST', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_53 = to('CST', new TimeSpan(utc$default(-5, 0, 2, null)));
    var tmp_54 = to('CT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_55 = to('CVT', new TimeSpan(utc$default(-1, 0, 2, null)));
    var tmp_56 = to('CWST', new TimeSpan(utc(8, 45)));
    var tmp_57 = to('CXT', new TimeSpan(utc$default(7, 0, 2, null)));
    var tmp_58 = to('DAVT', new TimeSpan(utc$default(7, 0, 2, null)));
    var tmp_59 = to('DDUT', new TimeSpan(utc$default(10, 0, 2, null)));
    var tmp_60 = to('DFT', new TimeSpan(utc$default(1, 0, 2, null)));
    var tmp_61 = to('EASST', new TimeSpan(utc$default(-5, 0, 2, null)));
    var tmp_62 = to('EAST', new TimeSpan(utc$default(-6, 0, 2, null)));
    var tmp_63 = to('EAT', new TimeSpan(utc$default(3, 0, 2, null)));
    var tmp_64 = to('ECT', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_65 = to('ECT', new TimeSpan(utc$default(-5, 0, 2, null)));
    var tmp_66 = to('EDT', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_67 = to('EEST', new TimeSpan(utc$default(3, 0, 2, null)));
    var tmp_68 = to('EET', new TimeSpan(utc$default(2, 0, 2, null)));
    var tmp_69 = to('EGST', new TimeSpan(utc$default(0, 0, 2, null)));
    var tmp_70 = to('EGT', new TimeSpan(utc$default(-1, 0, 2, null)));
    var tmp_71 = to('EIT', new TimeSpan(utc$default(9, 0, 2, null)));
    var tmp_72 = to('EST', new TimeSpan(utc$default(-5, 0, 2, null)));
    var tmp_73 = to('FET', new TimeSpan(utc$default(3, 0, 2, null)));
    var tmp_74 = to('FJT', new TimeSpan(utc$default(12, 0, 2, null)));
    var tmp_75 = to('FKST', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_76 = to('FKT', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_77 = to('FNT', new TimeSpan(utc$default(-2, 0, 2, null)));
    var tmp_78 = to('GALT', new TimeSpan(utc$default(-6, 0, 2, null)));
    var tmp_79 = to('GAMT', new TimeSpan(utc$default(-9, 0, 2, null)));
    var tmp_80 = to('GET', new TimeSpan(utc$default(4, 0, 2, null)));
    var tmp_81 = to('GFT', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_82 = to('GILT', new TimeSpan(utc$default(12, 0, 2, null)));
    var tmp_83 = to('GIT', new TimeSpan(utc$default(-9, 0, 2, null)));
    var tmp_84 = to('GMT', new TimeSpan(utc$default(0, 0, 2, null)));
    var tmp_85 = to('GST', new TimeSpan(utc$default(-2, 0, 2, null)));
    var tmp_86 = to('GST', new TimeSpan(utc$default(4, 0, 2, null)));
    var tmp_87 = to('GYT', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_88 = to('HDT', new TimeSpan(utc$default(-9, 0, 2, null)));
    var tmp_89 = to('HAEC', new TimeSpan(utc$default(2, 0, 2, null)));
    var tmp_90 = to('HST', new TimeSpan(utc$default(-10, 0, 2, null)));
    var tmp_91 = to('HKT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_92 = to('HMT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_93 = to('HOVST', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_94 = to('HOVT', new TimeSpan(utc$default(7, 0, 2, null)));
    var tmp_95 = to('ICT', new TimeSpan(utc$default(7, 0, 2, null)));
    var tmp_96 = to('IDLW', new TimeSpan(utc$default(-12, 0, 2, null)));
    var tmp_97 = to('IDT', new TimeSpan(utc$default(3, 0, 2, null)));
    var tmp_98 = to('IOT', new TimeSpan(utc$default(3, 0, 2, null)));
    var tmp_99 = to('IRDT', new TimeSpan(utc(4, 30)));
    var tmp_100 = to('IRKT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_101 = to('IRST', new TimeSpan(utc(3, 30)));
    var tmp_102 = to('IST', new TimeSpan(utc(5, 30)));
    var tmp_103 = to('IST', new TimeSpan(utc$default(1, 0, 2, null)));
    var tmp_104 = to('IST', new TimeSpan(utc$default(2, 0, 2, null)));
    var tmp_105 = to('JST', new TimeSpan(utc$default(9, 0, 2, null)));
    var tmp_106 = to('KALT', new TimeSpan(utc$default(2, 0, 2, null)));
    var tmp_107 = to('KGT', new TimeSpan(utc$default(6, 0, 2, null)));
    var tmp_108 = to('KOST', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_109 = to('KRAT', new TimeSpan(utc$default(7, 0, 2, null)));
    var tmp_110 = to('KST', new TimeSpan(utc$default(9, 0, 2, null)));
    var tmp_111 = to('LHST', new TimeSpan(utc(10, 30)));
    var tmp_112 = to('LHST', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_113 = to('LINT', new TimeSpan(utc$default(14, 0, 2, null)));
    var tmp_114 = to('MAGT', new TimeSpan(utc$default(12, 0, 2, null)));
    var tmp_115 = to('MART', new TimeSpan(utc(-9, 30)));
    var tmp_116 = to('MAWT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_117 = to('MDT', new TimeSpan(utc$default(-6, 0, 2, null)));
    var tmp_118 = to('MET', new TimeSpan(utc$default(1, 0, 2, null)));
    var tmp_119 = to('MEST', new TimeSpan(utc$default(2, 0, 2, null)));
    var tmp_120 = to('MHT', new TimeSpan(utc$default(12, 0, 2, null)));
    var tmp_121 = to('MIST', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_122 = to('MIT', new TimeSpan(utc(-9, 30)));
    var tmp_123 = to('MMT', new TimeSpan(utc(6, 30)));
    var tmp_124 = to('MSK', new TimeSpan(utc$default(3, 0, 2, null)));
    var tmp_125 = to('MST', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_126 = to('MST', new TimeSpan(utc$default(-7, 0, 2, null)));
    var tmp_127 = to('MUT', new TimeSpan(utc$default(4, 0, 2, null)));
    var tmp_128 = to('MVT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_129 = to('MYT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_130 = to('NCT', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_131 = to('NDT', new TimeSpan(utc(-2, 30)));
    var tmp_132 = to('NFT', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_133 = to('NOVT', new TimeSpan(utc$default(7, 0, 2, null)));
    var tmp_134 = to('NPT', new TimeSpan(utc(5, 45)));
    var tmp_135 = to('NST', new TimeSpan(utc(-3, 30)));
    var tmp_136 = to('NT', new TimeSpan(utc(-3, 30)));
    var tmp_137 = to('NUT', new TimeSpan(utc$default(-11, 0, 2, null)));
    var tmp_138 = to('NZDT', new TimeSpan(utc$default(13, 0, 2, null)));
    var tmp_139 = to('NZST', new TimeSpan(utc$default(12, 0, 2, null)));
    var tmp_140 = to('OMST', new TimeSpan(utc$default(6, 0, 2, null)));
    var tmp_141 = to('ORAT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_142 = to('PDT', new TimeSpan(utc$default(-7, 0, 2, null)));
    var tmp_143 = to('PET', new TimeSpan(utc$default(-5, 0, 2, null)));
    var tmp_144 = to('PETT', new TimeSpan(utc$default(12, 0, 2, null)));
    var tmp_145 = to('PGT', new TimeSpan(utc$default(10, 0, 2, null)));
    var tmp_146 = to('PHOT', new TimeSpan(utc$default(13, 0, 2, null)));
    var tmp_147 = to('PHT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_148 = to('PKT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_149 = to('PMDT', new TimeSpan(utc$default(-2, 0, 2, null)));
    var tmp_150 = to('PMST', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_151 = to('PONT', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_152 = to('PST', new TimeSpan(utc$default(-8, 0, 2, null)));
    var tmp_153 = to('PST', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_154 = to('PYST', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_155 = to('PYT', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_156 = to('RET', new TimeSpan(utc$default(4, 0, 2, null)));
    var tmp_157 = to('ROTT', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_158 = to('SAKT', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_159 = to('SAMT', new TimeSpan(utc$default(4, 0, 2, null)));
    var tmp_160 = to('SAST', new TimeSpan(utc$default(2, 0, 2, null)));
    var tmp_161 = to('SBT', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_162 = to('SCT', new TimeSpan(utc$default(4, 0, 2, null)));
    var tmp_163 = to('SDT', new TimeSpan(utc$default(-10, 0, 2, null)));
    var tmp_164 = to('SGT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_165 = to('SLST', new TimeSpan(utc(5, 30)));
    var tmp_166 = to('SRET', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_167 = to('SRT', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_168 = to('SST', new TimeSpan(utc$default(-11, 0, 2, null)));
    var tmp_169 = to('SST', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_170 = to('SYOT', new TimeSpan(utc$default(3, 0, 2, null)));
    var tmp_171 = to('TAHT', new TimeSpan(utc$default(-10, 0, 2, null)));
    var tmp_172 = to('THA', new TimeSpan(utc$default(7, 0, 2, null)));
    var tmp_173 = to('TFT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_174 = to('TJT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_175 = to('TKT', new TimeSpan(utc$default(13, 0, 2, null)));
    var tmp_176 = to('TLT', new TimeSpan(utc$default(9, 0, 2, null)));
    var tmp_177 = to('TMT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_178 = to('TRT', new TimeSpan(utc$default(3, 0, 2, null)));
    var tmp_179 = to('TOT', new TimeSpan(utc$default(13, 0, 2, null)));
    var tmp_180 = to('TVT', new TimeSpan(utc$default(12, 0, 2, null)));
    var tmp_181 = to('ULAST', new TimeSpan(utc$default(9, 0, 2, null)));
    var tmp_182 = to('ULAT', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_183 = to('UTC', new TimeSpan(utc$default(0, 0, 2, null)));
    var tmp_184 = to('UYST', new TimeSpan(utc$default(-2, 0, 2, null)));
    var tmp_185 = to('UYT', new TimeSpan(utc$default(-3, 0, 2, null)));
    var tmp_186 = to('UZT', new TimeSpan(utc$default(5, 0, 2, null)));
    var tmp_187 = to('VET', new TimeSpan(utc$default(-4, 0, 2, null)));
    var tmp_188 = to('VLAT', new TimeSpan(utc$default(10, 0, 2, null)));
    var tmp_189 = to('VOLT', new TimeSpan(utc$default(4, 0, 2, null)));
    var tmp_190 = to('VOST', new TimeSpan(utc$default(6, 0, 2, null)));
    var tmp_191 = to('VUT', new TimeSpan(utc$default(11, 0, 2, null)));
    var tmp_192 = to('WAKT', new TimeSpan(utc$default(12, 0, 2, null)));
    var tmp_193 = to('WAST', new TimeSpan(utc$default(2, 0, 2, null)));
    var tmp_194 = to('WAT', new TimeSpan(utc$default(1, 0, 2, null)));
    var tmp_195 = to('WEST', new TimeSpan(utc$default(1, 0, 2, null)));
    var tmp_196 = to('WET', new TimeSpan(utc$default(0, 0, 2, null)));
    var tmp_197 = to('WIT', new TimeSpan(utc$default(7, 0, 2, null)));
    var tmp_198 = to('WST', new TimeSpan(utc$default(8, 0, 2, null)));
    var tmp_199 = to('YAKT', new TimeSpan(utc$default(9, 0, 2, null)));
    return TimezoneNames_init_$Create$([tmp, tmp_0, tmp_1, tmp_2, tmp_3, tmp_4, tmp_5, tmp_6, tmp_7, tmp_8, tmp_9, tmp_10, tmp_11, tmp_12, tmp_13, tmp_14, tmp_15, tmp_16, tmp_17, tmp_18, tmp_19, tmp_20, tmp_21, tmp_22, tmp_23, tmp_24, tmp_25, tmp_26, tmp_27, tmp_28, tmp_29, tmp_30, tmp_31, tmp_32, tmp_33, tmp_34, tmp_35, tmp_36, tmp_37, tmp_38, tmp_39, tmp_40, tmp_41, tmp_42, tmp_43, tmp_44, tmp_45, tmp_46, tmp_47, tmp_48, tmp_49, tmp_50, tmp_51, tmp_52, tmp_53, tmp_54, tmp_55, tmp_56, tmp_57, tmp_58, tmp_59, tmp_60, tmp_61, tmp_62, tmp_63, tmp_64, tmp_65, tmp_66, tmp_67, tmp_68, tmp_69, tmp_70, tmp_71, tmp_72, tmp_73, tmp_74, tmp_75, tmp_76, tmp_77, tmp_78, tmp_79, tmp_80, tmp_81, tmp_82, tmp_83, tmp_84, tmp_85, tmp_86, tmp_87, tmp_88, tmp_89, tmp_90, tmp_91, tmp_92, tmp_93, tmp_94, tmp_95, tmp_96, tmp_97, tmp_98, tmp_99, tmp_100, tmp_101, tmp_102, tmp_103, tmp_104, tmp_105, tmp_106, tmp_107, tmp_108, tmp_109, tmp_110, tmp_111, tmp_112, tmp_113, tmp_114, tmp_115, tmp_116, tmp_117, tmp_118, tmp_119, tmp_120, tmp_121, tmp_122, tmp_123, tmp_124, tmp_125, tmp_126, tmp_127, tmp_128, tmp_129, tmp_130, tmp_131, tmp_132, tmp_133, tmp_134, tmp_135, tmp_136, tmp_137, tmp_138, tmp_139, tmp_140, tmp_141, tmp_142, tmp_143, tmp_144, tmp_145, tmp_146, tmp_147, tmp_148, tmp_149, tmp_150, tmp_151, tmp_152, tmp_153, tmp_154, tmp_155, tmp_156, tmp_157, tmp_158, tmp_159, tmp_160, tmp_161, tmp_162, tmp_163, tmp_164, tmp_165, tmp_166, tmp_167, tmp_168, tmp_169, tmp_170, tmp_171, tmp_172, tmp_173, tmp_174, tmp_175, tmp_176, tmp_177, tmp_178, tmp_179, tmp_180, tmp_181, tmp_182, tmp_183, tmp_184, tmp_185, tmp_186, tmp_187, tmp_188, tmp_189, tmp_190, tmp_191, tmp_192, tmp_193, tmp_194, tmp_195, tmp_196, tmp_197, tmp_198, tmp_199, to('YEKT', new TimeSpan(utc$default(5, 0, 2, null)))]);
  }
  var klockIsKotlinNative;
  function klockLazyOrGet(initializer) {
    return lazy(initializer);
  }
  function klockAtomicLazy(initializer) {
    return lazy(initializer);
  }
  var isNode;
  var initialHrTime$delegate;
  function Serializable_0() {
  }
  Serializable_0.$metadata$ = {
    simpleName: 'Serializable',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided__263() {
  }
  _no_name_provided__263.prototype.invoke_1038 = function () {
    return process.hrtime();
  };
  _no_name_provided__263.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_254() {
    var i = new _no_name_provided__263();
    return function () {
      return i.invoke_1038();
    };
  }
  function ChartCalculator(chartState) {
    this._chartState = chartState;
  }
  ChartCalculator.prototype.withContentAreaSize = function (sizeOverride) {
    return this.withChartState(withContentAreaSize(this._chartState, sizeOverride));
  };
  ChartCalculator.prototype.withChartState = function (newChartState) {
    return new ChartCalculator(newChartState);
  };
  ChartCalculator.prototype.domainRelative2contentAreaRelativeX = function (x) {
    return InternalCalculations_getInstance().domainRelative2contentAreaRelative(x, this._chartState._get_axisOrientationX__3());
  };
  ChartCalculator.prototype.domainRelative2contentAreaRelativeY = function (y) {
    return InternalCalculations_getInstance().domainRelative2contentAreaRelative(y, this._chartState._get_axisOrientationY__3());
  };
  ChartCalculator.prototype.contentAreaRelative2contentAreaX = function (x) {
    return InternalCalculations_getInstance().contentAreaRelative2contentArea(x, this._chartState._get_contentAreaWidth__3());
  };
  ChartCalculator.prototype.contentAreaRelative2contentAreaY = function (y) {
    return InternalCalculations_getInstance().contentAreaRelative2contentArea(y, this._chartState._get_contentAreaHeight__3());
  };
  ChartCalculator.prototype.contentArea2zoomedX = function (x) {
    return InternalCalculations_getInstance().contentArea2zoomed(x, this._chartState._get_zoomX__3());
  };
  ChartCalculator.prototype.contentArea2zoomedY = function (y) {
    return InternalCalculations_getInstance().contentArea2zoomed(y, this._chartState._get_zoomY__3());
  };
  ChartCalculator.prototype.zoomed2windowX = function (x) {
    return InternalCalculations_getInstance().zoomed2window(x, this._chartState._get_windowTranslation__4()._x_0);
  };
  ChartCalculator.prototype.zoomed2windowY = function (y) {
    return InternalCalculations_getInstance().zoomed2window(y, this._chartState._get_windowTranslation__4()._y_0);
  };
  ChartCalculator.prototype.domainDelta2zoomedX = function (deltaX, valueRange) {
    var delta = valueRange.deltaToDomainRelative(deltaX);
    var base = this.contentArea2zoomedX(this.domainRelative2contentAreaX(0.0));
    var deltaZoomed = this.contentArea2zoomedX(this.domainRelative2contentAreaX(delta));
    return deltaZoomed - base;
  };
  ChartCalculator.prototype.domainDelta2zoomedY = function (deltaY, valueRange) {
    var delta = valueRange.deltaToDomainRelative(deltaY);
    var base = this.contentArea2zoomedY(this.domainRelative2contentAreaY(0.0));
    var deltaZoomed = this.contentArea2zoomedY(this.domainRelative2contentAreaY(delta));
    return deltaZoomed - base;
  };
  ChartCalculator.prototype.domainRelative2contentAreaX = function (x) {
    return this.contentAreaRelative2contentAreaX(this.domainRelative2contentAreaRelativeX(x));
  };
  ChartCalculator.prototype.domainRelative2contentAreaY = function (y) {
    return this.contentAreaRelative2contentAreaY(this.domainRelative2contentAreaRelativeY(y));
  };
  ChartCalculator.prototype.contentAreaRelative2zoomedX = function (x) {
    return this.contentArea2zoomedX(this.contentAreaRelative2contentAreaX(x));
  };
  ChartCalculator.prototype.contentAreaRelative2zoomedY = function (y) {
    return this.contentArea2zoomedY(this.contentAreaRelative2contentAreaY(y));
  };
  ChartCalculator.prototype.contentArea2windowX = function (x) {
    return this.zoomed2windowX(this.contentArea2zoomedX(x));
  };
  ChartCalculator.prototype.contentArea2windowY = function (y) {
    return this.zoomed2windowY(this.contentArea2zoomedY(y));
  };
  ChartCalculator.prototype.contentAreaRelative2windowX = function (x) {
    var zoomedX = this.contentAreaRelative2zoomedX(x);
    return this.zoomed2windowX(zoomedX);
  };
  ChartCalculator.prototype.contentAreaRelative2windowY = function (y) {
    var zoomedY = this.contentAreaRelative2zoomedY(y);
    return this.zoomed2windowY(zoomedY);
  };
  ChartCalculator.prototype.domainRelative2windowX = function (x) {
    var contentAreaRelativeX = this.domainRelative2contentAreaRelativeX(x);
    return this.contentAreaRelative2windowX(contentAreaRelativeX);
  };
  ChartCalculator.prototype.domainRelative2windowY = function (y) {
    var contentAreaRelativeY = this.domainRelative2contentAreaRelativeY(y);
    return this.contentAreaRelative2windowY(contentAreaRelativeY);
  };
  ChartCalculator.prototype.domainRelative2window = function (coordinates) {
    return Companion_getInstance_32().of_4(this.domainRelative2windowX(coordinates._x), this.domainRelative2windowY(coordinates._y));
  };
  ChartCalculator.prototype.domain2windowX = function (x, valueRange) {
    var contentAreaX = this.domainRelative2contentAreaX(valueRange.toDomainRelative_0(x));
    return this.contentArea2windowX(contentAreaX);
  };
  ChartCalculator.prototype.domain2windowY = function (y, valueRange) {
    var contentAreaY = this.domainRelative2contentAreaY(valueRange.toDomainRelative_0(y));
    return this.contentArea2windowY(contentAreaY);
  };
  ChartCalculator.prototype.window2zoomedX = function (x) {
    var translateX = this._chartState._get_windowTranslation__4()._x_0;
    return InternalCalculations_getInstance().window2zoomed(x, translateX);
  };
  ChartCalculator.prototype.window2zoomedY = function (y) {
    var translateY = this._chartState._get_windowTranslation__4()._y_0;
    return InternalCalculations_getInstance().window2zoomed(y, translateY);
  };
  ChartCalculator.prototype.zoomed2contentAreaX = function (x) {
    var zoomFactorX = this._chartState._get_zoomX__3();
    return InternalCalculations_getInstance().zoomed2contentArea(x, zoomFactorX);
  };
  ChartCalculator.prototype.zoomed2contentAreaY = function (y) {
    var zoomFactorY = this._chartState._get_zoomY__3();
    return InternalCalculations_getInstance().zoomed2contentArea(y, zoomFactorY);
  };
  ChartCalculator.prototype.contentArea2contentAreaRelativeX = function (x) {
    return InternalCalculations_getInstance().contentArea2contentAreaRelative(x, this._chartState._get_contentAreaWidth__3());
  };
  ChartCalculator.prototype.contentArea2contentAreaRelativeY = function (y) {
    return InternalCalculations_getInstance().contentArea2contentAreaRelative(y, this._chartState._get_contentAreaHeight__3());
  };
  ChartCalculator.prototype.contentAreaRelative2domainRelativeX = function (x) {
    return InternalCalculations_getInstance().contentAreaRelative2domainRelative(x, this._chartState._get_axisOrientationX__3());
  };
  ChartCalculator.prototype.contentAreaRelative2domainRelativeY = function (y) {
    return InternalCalculations_getInstance().contentAreaRelative2domainRelative(y, this._chartState._get_axisOrientationY__3());
  };
  ChartCalculator.prototype.window2contentAreaX = function (x) {
    var zoomedX = this.window2zoomedX(x);
    return this.zoomed2contentAreaX(zoomedX);
  };
  ChartCalculator.prototype.window2contentAreaY = function (y) {
    var zoomedY = this.window2zoomedY(y);
    return this.zoomed2contentAreaY(zoomedY);
  };
  ChartCalculator.prototype.window2contentAreaRelativeX = function (x) {
    return this.contentArea2contentAreaRelativeX(this.window2contentAreaX(x));
  };
  ChartCalculator.prototype.window2contentAreaRelativeY = function (y) {
    return this.contentArea2contentAreaRelativeY(this.window2contentAreaY(y));
  };
  ChartCalculator.prototype.window2contentAreaRelative = function (x, y) {
    return Companion_getInstance_32().of_4(this.window2contentAreaRelativeX(x), this.window2contentAreaRelativeY(y));
  };
  ChartCalculator.prototype.window2domainRelativeX = function (x) {
    return this.contentAreaRelative2domainRelativeX(this.window2contentAreaRelativeX(x));
  };
  ChartCalculator.prototype.window2domainRelativeY = function (y) {
    return this.contentAreaRelative2domainRelativeY(this.window2contentAreaRelativeY(y));
  };
  ChartCalculator.prototype.window2domainRelative = function (x, y) {
    return Companion_getInstance_32().of_4(this.window2domainRelativeX(x), this.window2domainRelativeY(y));
  };
  ChartCalculator.$metadata$ = {
    simpleName: 'ChartCalculator',
    kind: 'class',
    interfaces: []
  };
  function ChartState() {
  }
  ChartState.prototype._get_hasZeroSize__3 = function () {
    return this._get_windowSize__4().atLeastOneZero() ? true : this._get_contentAreaSize__4().atLeastOneZero();
  };
  ChartState.prototype._get_windowCenter__2 = function () {
    return new Coordinates(this._get_windowWidth__3() / 2.0, this._get_windowHeight__3() / 2.0);
  };
  ChartState.$metadata$ = {
    simpleName: 'ChartState',
    kind: 'interface',
    interfaces: []
  };
  function withContentAreaSize(_this_, sizeOverride) {
    return new ContentAreaOverrideChartState(sizeOverride, _this_);
  }
  function ContentAreaOverrideChartState(sizeOverride, delegate_0) {
    DelegatingChartState.call(this, delegate_0);
    this._sizeOverride = sizeOverride;
    var tmp0_require_0 = this._sizeOverride.bothNotNegative();
    if (!tmp0_require_0) {
      var message_1 = '' + 'Invalid size <' + this._sizeOverride + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }}
  ContentAreaOverrideChartState.prototype._get_contentAreaSize__4 = function () {
    return this._sizeOverride;
  };
  ContentAreaOverrideChartState.prototype._get_contentAreaWidth__3 = function () {
    return this._sizeOverride._width_0;
  };
  ContentAreaOverrideChartState.prototype._get_contentAreaHeight__3 = function () {
    return this._sizeOverride._height;
  };
  ContentAreaOverrideChartState.$metadata$ = {
    simpleName: 'ContentAreaOverrideChartState',
    kind: 'class',
    interfaces: []
  };
  function or0ifNaN(_this_) {
    return ifNaN(_this_, 0.0);
  }
  function isPositiveOrZero(_this_) {
    return _this_ >= 0.0;
  }
  function isNegative_0(_this_) {
    return _this_ < 0.0;
  }
  function isPositive(_this_) {
    return _this_ > 0.0;
  }
  function ifNaN(_this_, fallback) {
    var tmp;
    if (isNaN_0(_this_)) {
      tmp = fallback;
    } else {
      tmp = _this_;
    }
    return tmp;
  }
  function DelegatingChartState(delegate_0) {
    this._delegate_1 = delegate_0;
  }
  DelegatingChartState.prototype._get_axisOrientationX__3 = function () {
    return this._delegate_1._get_axisOrientationX__3();
  };
  DelegatingChartState.prototype._get_axisOrientationY__3 = function () {
    return this._delegate_1._get_axisOrientationY__3();
  };
  DelegatingChartState.prototype._get_contentAreaHeight__3 = function () {
    return this._delegate_1._get_contentAreaHeight__3();
  };
  DelegatingChartState.prototype._get_contentAreaSize__4 = function () {
    return this._delegate_1._get_contentAreaSize__4();
  };
  DelegatingChartState.prototype._get_contentAreaWidth__3 = function () {
    return this._delegate_1._get_contentAreaWidth__3();
  };
  DelegatingChartState.prototype._get_hasZeroSize__3 = function () {
    return this._delegate_1._get_hasZeroSize__3();
  };
  DelegatingChartState.prototype._get_windowHeight__3 = function () {
    return this._delegate_1._get_windowHeight__3();
  };
  DelegatingChartState.prototype._get_windowSize__4 = function () {
    return this._delegate_1._get_windowSize__4();
  };
  DelegatingChartState.prototype._get_windowTranslation__4 = function () {
    return this._delegate_1._get_windowTranslation__4();
  };
  DelegatingChartState.prototype._get_windowWidth__3 = function () {
    return this._delegate_1._get_windowWidth__3();
  };
  DelegatingChartState.prototype._get_zoomX__3 = function () {
    return this._delegate_1._get_zoomX__3();
  };
  DelegatingChartState.prototype._get_zoomY__3 = function () {
    return this._delegate_1._get_zoomY__3();
  };
  DelegatingChartState.$metadata$ = {
    simpleName: 'DelegatingChartState',
    kind: 'class',
    interfaces: [ChartState]
  };
  function InternalCalculations() {
    InternalCalculations_instance = this;
  }
  InternalCalculations.prototype.domainRelative2contentAreaRelative = function (domainRelative, axisInversionInformation) {
    var tmp;
    if (axisInversionInformation._get_axisInverted__1()) {
      tmp = 1 - domainRelative;
    } else {
      tmp = domainRelative;
    }
    return tmp;
  };
  InternalCalculations.prototype.contentAreaRelative2contentArea = function (value, factor) {
    return value * factor;
  };
  InternalCalculations.prototype.contentArea2zoomed = function (value, zoomFactor) {
    return value * zoomFactor;
  };
  InternalCalculations.prototype.zoomed2window = function (value, translate) {
    return value + translate;
  };
  InternalCalculations.prototype.window2zoomed = function (value, translate) {
    return value - translate;
  };
  InternalCalculations.prototype.zoomed2contentArea = function (value, zoomFactor) {
    return value / zoomFactor;
  };
  InternalCalculations.prototype.contentArea2contentAreaRelative = function (value, factor) {
    return value / factor;
  };
  InternalCalculations.prototype.contentAreaRelative2domainRelative = function (contentAreaRelative, axisInversionInformation) {
    var tmp;
    if (axisInversionInformation._get_axisInverted__1()) {
      tmp = 1 - contentAreaRelative;
    } else {
      tmp = contentAreaRelative;
    }
    return tmp;
  };
  InternalCalculations.$metadata$ = {
    simpleName: 'InternalCalculations',
    kind: 'object',
    interfaces: []
  };
  var InternalCalculations_instance;
  function InternalCalculations_getInstance() {
    if (InternalCalculations_instance == null)
      new InternalCalculations();
    return InternalCalculations_instance;
  }
  function LinearValueRange(start, end) {
    ValueRange.call(this, start, end);
  }
  LinearValueRange.prototype.toDomainRelative_0 = function (domainValue) {
    return (domainValue - this._get_start__3()) / this._get_delta__0();
  };
  LinearValueRange.prototype.deltaToDomainRelative = function (domainDelta) {
    return domainDelta / this._get_delta__0();
  };
  LinearValueRange.$metadata$ = {
    simpleName: 'LinearValueRange',
    kind: 'class',
    interfaces: []
  };
  function MutableChartState() {
  }
  MutableChartState.$metadata$ = {
    simpleName: 'MutableChartState',
    kind: 'interface',
    interfaces: [ObservableChartState]
  };
  function ObservableChartState() {
  }
  ObservableChartState.$metadata$ = {
    simpleName: 'ObservableChartState',
    kind: 'interface',
    interfaces: [ChartState]
  };
  function Companion_28() {
    Companion_instance_27 = this;
    this._percentage = this.linear_0(0.0, 1.0);
    this._degrees = this.linear_0(0.0, 360.0);
    this._default = this.linear_0(0.0, 100.0);
  }
  Companion_28.prototype.linear_0 = function (start, end) {
    return new LinearValueRange(start, end);
  };
  Companion_28.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_27;
  function Companion_getInstance_27() {
    if (Companion_instance_27 == null)
      new Companion_28();
    return Companion_instance_27;
  }
  function ValueRange(start, end) {
    Companion_getInstance_27();
    this._start = start;
    this._end = end;
    var tmp0_require_0 = this._start <= this._end;
    if (!tmp0_require_0) {
      var message_1 = '' + 'The start (' + this._start + ') must be smaller or same as the end (' + this._end + ').';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }}
  ValueRange.prototype._get_start__3 = function () {
    return this._start;
  };
  ValueRange.prototype._get_delta__0 = function () {
    return this._end - this._start;
  };
  ValueRange.prototype.toString = function () {
    return '' + '[' + this._start + ', ' + this._end + ']';
  };
  ValueRange.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (other == null ? true : !getKClassFromExpression_0(this).equals(getKClassFromExpression_0(other)))
      return false;
    if (other instanceof ValueRange)
      other;
    else
      THROW_CCE();
    Unit_getInstance();
    if (!(this._start === other._start))
      return false;
    if (!(this._end === other._end))
      return false;
    return true;
  };
  ValueRange.prototype.hashCode = function () {
    var result = getNumberHashCode(this._start);
    result = imul(31, result) + getNumberHashCode(this._end) | 0;
    return result;
  };
  ValueRange.$metadata$ = {
    simpleName: 'ValueRange',
    kind: 'class',
    interfaces: []
  };
  function AxisInversionInformation() {
  }
  AxisInversionInformation.$metadata$ = {
    simpleName: 'AxisInversionInformation',
    kind: 'interface',
    interfaces: []
  };
  var AxisOrientationX_OriginAtLeft_instance;
  var AxisOrientationX_OriginAtRight_instance;
  var AxisOrientationX_entriesInitialized;
  function AxisOrientationX_initEntries() {
    if (AxisOrientationX_entriesInitialized)
      return Unit_getInstance();
    AxisOrientationX_entriesInitialized = true;
    AxisOrientationX_OriginAtLeft_instance = new AxisOrientationX('OriginAtLeft', 0);
    AxisOrientationX_OriginAtRight_instance = new AxisOrientationX('OriginAtRight', 1);
  }
  function AxisOrientationX(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  AxisOrientationX.prototype._get_axisInverted__1 = function () {
    return this.equals(AxisOrientationX_OriginAtRight_getInstance());
  };
  AxisOrientationX.$metadata$ = {
    simpleName: 'AxisOrientationX',
    kind: 'class',
    interfaces: [AxisInversionInformation]
  };
  function AxisOrientationX_OriginAtLeft_getInstance() {
    AxisOrientationX_initEntries();
    return AxisOrientationX_OriginAtLeft_instance;
  }
  function AxisOrientationX_OriginAtRight_getInstance() {
    AxisOrientationX_initEntries();
    return AxisOrientationX_OriginAtRight_instance;
  }
  var AxisOrientationY_OriginAtBottom_instance;
  var AxisOrientationY_OriginAtTop_instance;
  var AxisOrientationY_entriesInitialized;
  function AxisOrientationY_initEntries() {
    if (AxisOrientationY_entriesInitialized)
      return Unit_getInstance();
    AxisOrientationY_entriesInitialized = true;
    AxisOrientationY_OriginAtBottom_instance = new AxisOrientationY('OriginAtBottom', 0);
    AxisOrientationY_OriginAtTop_instance = new AxisOrientationY('OriginAtTop', 1);
  }
  function AxisOrientationY(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  AxisOrientationY.prototype._get_axisInverted__1 = function () {
    return this.equals(AxisOrientationY_OriginAtBottom_getInstance());
  };
  AxisOrientationY.$metadata$ = {
    simpleName: 'AxisOrientationY',
    kind: 'class',
    interfaces: [AxisInversionInformation]
  };
  function AxisOrientationY_OriginAtBottom_getInstance() {
    AxisOrientationY_initEntries();
    return AxisOrientationY_OriginAtBottom_instance;
  }
  var AxisSelection_Both_instance;
  var AxisSelection_X_instance;
  var AxisSelection_Y_instance;
  var AxisSelection_None_instance;
  function Companion_29() {
    Companion_instance_28 = this;
  }
  Companion_29.prototype.get_21 = function (xSelected, ySelected) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_firstOrNull_0 = values_0();
      var indexedObject = tmp0_firstOrNull_0;
      var inductionVariable = 0;
      var last_1 = indexedObject.length;
      while (inductionVariable < last_1) {
        var element_2 = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if (element_2._containsX === xSelected ? element_2._containsY === ySelected : false) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalStateException_init_$Create$_0('' + 'No Axis selection found for <' + xSelected + '>, <' + ySelected + '>');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  Companion_29.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_28;
  function Companion_getInstance_28() {
    if (Companion_instance_28 == null)
      new Companion_29();
    return Companion_instance_28;
  }
  function values_0() {
    return [AxisSelection_Both_getInstance(), AxisSelection_X_getInstance(), AxisSelection_Y_getInstance(), AxisSelection_None_getInstance()];
  }
  var AxisSelection_entriesInitialized;
  function AxisSelection_initEntries() {
    if (AxisSelection_entriesInitialized)
      return Unit_getInstance();
    AxisSelection_entriesInitialized = true;
    AxisSelection_Both_instance = new AxisSelection('Both', 0, true, true);
    AxisSelection_X_instance = new AxisSelection('X', 1, true, false);
    AxisSelection_Y_instance = new AxisSelection('Y', 2, false, true);
    AxisSelection_None_instance = new AxisSelection('None', 3, false, false);
    Companion_getInstance_28();
  }
  function AxisSelection(name, ordinal, containsX, containsY) {
    Enum.call(this, name, ordinal);
    this._containsX = containsX;
    this._containsY = containsY;
  }
  AxisSelection.$metadata$ = {
    simpleName: 'AxisSelection',
    kind: 'class',
    interfaces: []
  };
  function AxisSelection_Both_getInstance() {
    AxisSelection_initEntries();
    return AxisSelection_Both_instance;
  }
  function AxisSelection_X_getInstance() {
    AxisSelection_initEntries();
    return AxisSelection_X_instance;
  }
  function AxisSelection_Y_getInstance() {
    AxisSelection_initEntries();
    return AxisSelection_Y_instance;
  }
  function AxisSelection_None_getInstance() {
    AxisSelection_initEntries();
    return AxisSelection_None_instance;
  }
  function Companion_30() {
    Companion_instance_29 = this;
    this._black50percent = Companion_getInstance_30().invoke_501(1.0, 1.0, 1.0, 0.5);
    this._transparent = new RgbaColor(0, 0, 0, 0.0);
    var tmp = this;
    tmp._aliceblue = RgbaColor_init_$Create$(240, 248, 255, null, 8, null);
    var tmp_0 = this;
    tmp_0._antiquewhite = RgbaColor_init_$Create$(250, 235, 215, null, 8, null);
    var tmp_1 = this;
    tmp_1._aqua = RgbaColor_init_$Create$(0, 255, 255, null, 8, null);
    var tmp_2 = this;
    tmp_2._aquamarine = RgbaColor_init_$Create$(127, 255, 212, null, 8, null);
    var tmp_3 = this;
    tmp_3._azure = RgbaColor_init_$Create$(240, 255, 255, null, 8, null);
    var tmp_4 = this;
    tmp_4._beige = RgbaColor_init_$Create$(245, 245, 220, null, 8, null);
    var tmp_5 = this;
    tmp_5._bisque = RgbaColor_init_$Create$(255, 228, 196, null, 8, null);
    var tmp_6 = this;
    tmp_6._black = RgbaColor_init_$Create$(0, 0, 0, null, 8, null);
    var tmp_7 = this;
    tmp_7._blanchedalmond = RgbaColor_init_$Create$(255, 235, 205, null, 8, null);
    var tmp_8 = this;
    tmp_8._blue = RgbaColor_init_$Create$(0, 0, 255, null, 8, null);
    var tmp_9 = this;
    tmp_9._blueviolet = RgbaColor_init_$Create$(138, 43, 226, null, 8, null);
    var tmp_10 = this;
    tmp_10._brown = RgbaColor_init_$Create$(165, 42, 42, null, 8, null);
    var tmp_11 = this;
    tmp_11._burlywood = RgbaColor_init_$Create$(222, 184, 135, null, 8, null);
    var tmp_12 = this;
    tmp_12._cadetblue = RgbaColor_init_$Create$(95, 158, 160, null, 8, null);
    var tmp_13 = this;
    tmp_13._chartreuse = RgbaColor_init_$Create$(127, 255, 0, null, 8, null);
    var tmp_14 = this;
    tmp_14._chocolate = RgbaColor_init_$Create$(210, 105, 30, null, 8, null);
    var tmp_15 = this;
    tmp_15._coral = RgbaColor_init_$Create$(255, 127, 80, null, 8, null);
    var tmp_16 = this;
    tmp_16._cornflowerblue = RgbaColor_init_$Create$(100, 149, 237, null, 8, null);
    var tmp_17 = this;
    tmp_17._cornsilk = RgbaColor_init_$Create$(255, 248, 220, null, 8, null);
    var tmp_18 = this;
    tmp_18._crimson = RgbaColor_init_$Create$(220, 20, 60, null, 8, null);
    var tmp_19 = this;
    tmp_19._cyan = RgbaColor_init_$Create$(0, 255, 255, null, 8, null);
    var tmp_20 = this;
    tmp_20._darkblue = RgbaColor_init_$Create$(0, 0, 139, null, 8, null);
    var tmp_21 = this;
    tmp_21._darkcyan = RgbaColor_init_$Create$(0, 139, 139, null, 8, null);
    var tmp_22 = this;
    tmp_22._darkgoldenrod = RgbaColor_init_$Create$(184, 134, 11, null, 8, null);
    var tmp_23 = this;
    tmp_23._darkgray = RgbaColor_init_$Create$(169, 169, 169, null, 8, null);
    var tmp_24 = this;
    tmp_24._darkgreen = RgbaColor_init_$Create$(0, 100, 0, null, 8, null);
    var tmp_25 = this;
    tmp_25._darkgrey = RgbaColor_init_$Create$(169, 169, 169, null, 8, null);
    var tmp_26 = this;
    tmp_26._darkkhaki = RgbaColor_init_$Create$(189, 183, 107, null, 8, null);
    var tmp_27 = this;
    tmp_27._darkmagenta = RgbaColor_init_$Create$(139, 0, 139, null, 8, null);
    var tmp_28 = this;
    tmp_28._darkolivegreen = RgbaColor_init_$Create$(85, 107, 47, null, 8, null);
    var tmp_29 = this;
    tmp_29._darkorange = RgbaColor_init_$Create$(255, 140, 0, null, 8, null);
    var tmp_30 = this;
    tmp_30._darkorchid = RgbaColor_init_$Create$(153, 50, 204, null, 8, null);
    var tmp_31 = this;
    tmp_31._darkred = RgbaColor_init_$Create$(139, 0, 0, null, 8, null);
    var tmp_32 = this;
    tmp_32._darksalmon = RgbaColor_init_$Create$(233, 150, 122, null, 8, null);
    var tmp_33 = this;
    tmp_33._darkseagreen = RgbaColor_init_$Create$(143, 188, 143, null, 8, null);
    var tmp_34 = this;
    tmp_34._darkslateblue = RgbaColor_init_$Create$(72, 61, 139, null, 8, null);
    var tmp_35 = this;
    tmp_35._darkslategray = RgbaColor_init_$Create$(47, 79, 79, null, 8, null);
    var tmp_36 = this;
    tmp_36._darkslategrey = RgbaColor_init_$Create$(47, 79, 79, null, 8, null);
    var tmp_37 = this;
    tmp_37._darkturquoise = RgbaColor_init_$Create$(0, 206, 209, null, 8, null);
    var tmp_38 = this;
    tmp_38._darkviolet = RgbaColor_init_$Create$(148, 0, 211, null, 8, null);
    var tmp_39 = this;
    tmp_39._deeppink = RgbaColor_init_$Create$(255, 20, 147, null, 8, null);
    var tmp_40 = this;
    tmp_40._deepskyblue = RgbaColor_init_$Create$(0, 191, 255, null, 8, null);
    var tmp_41 = this;
    tmp_41._dimgray = RgbaColor_init_$Create$(105, 105, 105, null, 8, null);
    var tmp_42 = this;
    tmp_42._dimgrey = RgbaColor_init_$Create$(105, 105, 105, null, 8, null);
    var tmp_43 = this;
    tmp_43._dodgerblue = RgbaColor_init_$Create$(30, 144, 255, null, 8, null);
    var tmp_44 = this;
    tmp_44._firebrick = RgbaColor_init_$Create$(178, 34, 34, null, 8, null);
    var tmp_45 = this;
    tmp_45._floralwhite = RgbaColor_init_$Create$(255, 250, 240, null, 8, null);
    var tmp_46 = this;
    tmp_46._forestgreen = RgbaColor_init_$Create$(34, 139, 34, null, 8, null);
    var tmp_47 = this;
    tmp_47._fuchsia = RgbaColor_init_$Create$(255, 0, 255, null, 8, null);
    var tmp_48 = this;
    tmp_48._gainsboro = RgbaColor_init_$Create$(220, 220, 220, null, 8, null);
    var tmp_49 = this;
    tmp_49._ghostwhite = RgbaColor_init_$Create$(248, 248, 255, null, 8, null);
    var tmp_50 = this;
    tmp_50._gold = RgbaColor_init_$Create$(255, 215, 0, null, 8, null);
    var tmp_51 = this;
    tmp_51._goldenrod = RgbaColor_init_$Create$(218, 165, 32, null, 8, null);
    var tmp_52 = this;
    tmp_52._gray = RgbaColor_init_$Create$(128, 128, 128, null, 8, null);
    var tmp_53 = this;
    tmp_53._green = RgbaColor_init_$Create$(0, 128, 0, null, 8, null);
    var tmp_54 = this;
    tmp_54._greenyellow = RgbaColor_init_$Create$(173, 255, 47, null, 8, null);
    var tmp_55 = this;
    tmp_55._grey = RgbaColor_init_$Create$(128, 128, 128, null, 8, null);
    var tmp_56 = this;
    tmp_56._honeydew = RgbaColor_init_$Create$(240, 255, 240, null, 8, null);
    var tmp_57 = this;
    tmp_57._hotpink = RgbaColor_init_$Create$(255, 105, 180, null, 8, null);
    var tmp_58 = this;
    tmp_58._indianred = RgbaColor_init_$Create$(205, 92, 92, null, 8, null);
    var tmp_59 = this;
    tmp_59._indigo = RgbaColor_init_$Create$(75, 0, 130, null, 8, null);
    var tmp_60 = this;
    tmp_60._ivory = RgbaColor_init_$Create$(255, 255, 240, null, 8, null);
    var tmp_61 = this;
    tmp_61._khaki = RgbaColor_init_$Create$(240, 230, 140, null, 8, null);
    var tmp_62 = this;
    tmp_62._lavender = RgbaColor_init_$Create$(230, 230, 250, null, 8, null);
    var tmp_63 = this;
    tmp_63._lavenderblush = RgbaColor_init_$Create$(255, 240, 245, null, 8, null);
    var tmp_64 = this;
    tmp_64._lawngreen = RgbaColor_init_$Create$(124, 252, 0, null, 8, null);
    var tmp_65 = this;
    tmp_65._lemonchiffon = RgbaColor_init_$Create$(255, 250, 205, null, 8, null);
    var tmp_66 = this;
    tmp_66._lightblue = RgbaColor_init_$Create$(173, 216, 230, null, 8, null);
    var tmp_67 = this;
    tmp_67._lightcoral = RgbaColor_init_$Create$(240, 128, 128, null, 8, null);
    var tmp_68 = this;
    tmp_68._lightcyan = RgbaColor_init_$Create$(224, 255, 255, null, 8, null);
    var tmp_69 = this;
    tmp_69._lightgoldenrodyellow = RgbaColor_init_$Create$(250, 250, 210, null, 8, null);
    var tmp_70 = this;
    tmp_70._lightgray = RgbaColor_init_$Create$(211, 211, 211, null, 8, null);
    var tmp_71 = this;
    tmp_71._lightgreen = RgbaColor_init_$Create$(144, 238, 144, null, 8, null);
    var tmp_72 = this;
    tmp_72._lightgrey = RgbaColor_init_$Create$(211, 211, 211, null, 8, null);
    var tmp_73 = this;
    tmp_73._lightpink = RgbaColor_init_$Create$(255, 182, 193, null, 8, null);
    var tmp_74 = this;
    tmp_74._lightsalmon = RgbaColor_init_$Create$(255, 160, 122, null, 8, null);
    var tmp_75 = this;
    tmp_75._lightseagreen = RgbaColor_init_$Create$(32, 178, 170, null, 8, null);
    var tmp_76 = this;
    tmp_76._lightskyblue = RgbaColor_init_$Create$(135, 206, 250, null, 8, null);
    var tmp_77 = this;
    tmp_77._lightslategray = RgbaColor_init_$Create$(119, 136, 153, null, 8, null);
    var tmp_78 = this;
    tmp_78._lightslategrey = RgbaColor_init_$Create$(119, 136, 153, null, 8, null);
    var tmp_79 = this;
    tmp_79._lightsteelblue = RgbaColor_init_$Create$(176, 196, 222, null, 8, null);
    var tmp_80 = this;
    tmp_80._lightyellow = RgbaColor_init_$Create$(255, 255, 224, null, 8, null);
    var tmp_81 = this;
    tmp_81._lime = RgbaColor_init_$Create$(0, 255, 0, null, 8, null);
    var tmp_82 = this;
    tmp_82._limegreen = RgbaColor_init_$Create$(50, 205, 50, null, 8, null);
    var tmp_83 = this;
    tmp_83._linen = RgbaColor_init_$Create$(250, 240, 230, null, 8, null);
    var tmp_84 = this;
    tmp_84._magenta = RgbaColor_init_$Create$(255, 0, 255, null, 8, null);
    var tmp_85 = this;
    tmp_85._maroon = RgbaColor_init_$Create$(128, 0, 0, null, 8, null);
    var tmp_86 = this;
    tmp_86._mediumaquamarine = RgbaColor_init_$Create$(102, 205, 170, null, 8, null);
    var tmp_87 = this;
    tmp_87._mediumblue = RgbaColor_init_$Create$(0, 0, 205, null, 8, null);
    var tmp_88 = this;
    tmp_88._mediumorchid = RgbaColor_init_$Create$(186, 85, 211, null, 8, null);
    var tmp_89 = this;
    tmp_89._mediumpurple = RgbaColor_init_$Create$(147, 112, 219, null, 8, null);
    var tmp_90 = this;
    tmp_90._mediumseagreen = RgbaColor_init_$Create$(60, 179, 113, null, 8, null);
    var tmp_91 = this;
    tmp_91._mediumslateblue = RgbaColor_init_$Create$(123, 104, 238, null, 8, null);
    var tmp_92 = this;
    tmp_92._mediumspringgreen = RgbaColor_init_$Create$(0, 250, 154, null, 8, null);
    var tmp_93 = this;
    tmp_93._mediumturquoise = RgbaColor_init_$Create$(72, 209, 204, null, 8, null);
    var tmp_94 = this;
    tmp_94._mediumvioletred = RgbaColor_init_$Create$(199, 21, 133, null, 8, null);
    var tmp_95 = this;
    tmp_95._midnightblue = RgbaColor_init_$Create$(25, 25, 112, null, 8, null);
    var tmp_96 = this;
    tmp_96._mintcream = RgbaColor_init_$Create$(245, 255, 250, null, 8, null);
    var tmp_97 = this;
    tmp_97._mistyrose = RgbaColor_init_$Create$(255, 228, 225, null, 8, null);
    var tmp_98 = this;
    tmp_98._moccasin = RgbaColor_init_$Create$(255, 228, 181, null, 8, null);
    var tmp_99 = this;
    tmp_99._navajowhite = RgbaColor_init_$Create$(255, 222, 173, null, 8, null);
    var tmp_100 = this;
    tmp_100._navy = RgbaColor_init_$Create$(0, 0, 128, null, 8, null);
    var tmp_101 = this;
    tmp_101._oldlace = RgbaColor_init_$Create$(253, 245, 230, null, 8, null);
    var tmp_102 = this;
    tmp_102._olive = RgbaColor_init_$Create$(128, 128, 0, null, 8, null);
    var tmp_103 = this;
    tmp_103._olivedrab = RgbaColor_init_$Create$(107, 142, 35, null, 8, null);
    var tmp_104 = this;
    tmp_104._orange = RgbaColor_init_$Create$(255, 165, 0, null, 8, null);
    var tmp_105 = this;
    tmp_105._orangered = RgbaColor_init_$Create$(255, 69, 0, null, 8, null);
    var tmp_106 = this;
    tmp_106._orchid = RgbaColor_init_$Create$(218, 112, 214, null, 8, null);
    var tmp_107 = this;
    tmp_107._palegoldenrod = RgbaColor_init_$Create$(238, 232, 170, null, 8, null);
    var tmp_108 = this;
    tmp_108._palegreen = RgbaColor_init_$Create$(152, 251, 152, null, 8, null);
    var tmp_109 = this;
    tmp_109._paleturquoise = RgbaColor_init_$Create$(175, 238, 238, null, 8, null);
    var tmp_110 = this;
    tmp_110._palevioletred = RgbaColor_init_$Create$(219, 112, 147, null, 8, null);
    var tmp_111 = this;
    tmp_111._papayawhip = RgbaColor_init_$Create$(255, 239, 213, null, 8, null);
    var tmp_112 = this;
    tmp_112._peachpuff = RgbaColor_init_$Create$(255, 218, 185, null, 8, null);
    var tmp_113 = this;
    tmp_113._peru = RgbaColor_init_$Create$(205, 133, 63, null, 8, null);
    var tmp_114 = this;
    tmp_114._pink = RgbaColor_init_$Create$(255, 192, 203, null, 8, null);
    var tmp_115 = this;
    tmp_115._plum = RgbaColor_init_$Create$(221, 160, 221, null, 8, null);
    var tmp_116 = this;
    tmp_116._powderblue = RgbaColor_init_$Create$(176, 224, 230, null, 8, null);
    var tmp_117 = this;
    tmp_117._purple = RgbaColor_init_$Create$(128, 0, 128, null, 8, null);
    var tmp_118 = this;
    tmp_118._red = RgbaColor_init_$Create$(255, 0, 0, null, 8, null);
    var tmp_119 = this;
    tmp_119._rosybrown = RgbaColor_init_$Create$(188, 143, 143, null, 8, null);
    var tmp_120 = this;
    tmp_120._royalblue = RgbaColor_init_$Create$(65, 105, 225, null, 8, null);
    var tmp_121 = this;
    tmp_121._saddlebrown = RgbaColor_init_$Create$(139, 69, 19, null, 8, null);
    var tmp_122 = this;
    tmp_122._salmon = RgbaColor_init_$Create$(250, 128, 114, null, 8, null);
    var tmp_123 = this;
    tmp_123._sandybrown = RgbaColor_init_$Create$(244, 164, 96, null, 8, null);
    var tmp_124 = this;
    tmp_124._seagreen = RgbaColor_init_$Create$(46, 139, 87, null, 8, null);
    var tmp_125 = this;
    tmp_125._seashell = RgbaColor_init_$Create$(255, 245, 238, null, 8, null);
    var tmp_126 = this;
    tmp_126._sienna = RgbaColor_init_$Create$(160, 82, 45, null, 8, null);
    var tmp_127 = this;
    tmp_127._silver = RgbaColor_init_$Create$(192, 192, 192, null, 8, null);
    var tmp_128 = this;
    tmp_128._skyblue = RgbaColor_init_$Create$(135, 206, 235, null, 8, null);
    var tmp_129 = this;
    tmp_129._slateblue = RgbaColor_init_$Create$(106, 90, 205, null, 8, null);
    var tmp_130 = this;
    tmp_130._slategray = RgbaColor_init_$Create$(112, 128, 144, null, 8, null);
    var tmp_131 = this;
    tmp_131._slategrey = RgbaColor_init_$Create$(112, 128, 144, null, 8, null);
    var tmp_132 = this;
    tmp_132._snow = RgbaColor_init_$Create$(255, 250, 250, null, 8, null);
    var tmp_133 = this;
    tmp_133._springgreen = RgbaColor_init_$Create$(0, 255, 127, null, 8, null);
    var tmp_134 = this;
    tmp_134._steelblue = RgbaColor_init_$Create$(70, 130, 180, null, 8, null);
    var tmp_135 = this;
    tmp_135._tan = RgbaColor_init_$Create$(210, 180, 140, null, 8, null);
    var tmp_136 = this;
    tmp_136._teal = RgbaColor_init_$Create$(0, 128, 128, null, 8, null);
    var tmp_137 = this;
    tmp_137._thistle = RgbaColor_init_$Create$(216, 191, 216, null, 8, null);
    var tmp_138 = this;
    tmp_138._tomato = RgbaColor_init_$Create$(255, 99, 71, null, 8, null);
    var tmp_139 = this;
    tmp_139._turquoise = RgbaColor_init_$Create$(64, 224, 208, null, 8, null);
    var tmp_140 = this;
    tmp_140._violet = RgbaColor_init_$Create$(238, 130, 238, null, 8, null);
    var tmp_141 = this;
    tmp_141._wheat = RgbaColor_init_$Create$(245, 222, 179, null, 8, null);
    var tmp_142 = this;
    tmp_142._white = RgbaColor_init_$Create$(255, 255, 255, null, 8, null);
    var tmp_143 = this;
    tmp_143._whitesmoke = RgbaColor_init_$Create$(245, 245, 245, null, 8, null);
    var tmp_144 = this;
    tmp_144._yellow = RgbaColor_init_$Create$(255, 255, 0, null, 8, null);
    var tmp_145 = this;
    tmp_145._yellowgreen = RgbaColor_init_$Create$(154, 205, 50, null, 8, null);
  }
  Companion_30.prototype.web_0 = function (web) {
    return new WebColor(web);
  };
  Companion_30.prototype.rgb = function (red, green, blue) {
    return this.rgba(red, green, blue, null);
  };
  Companion_30.prototype.rgba = function (red, green, blue, opacity) {
    return new RgbaColor(red, green, blue, opacity);
  };
  Companion_30.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_29;
  function Companion_getInstance_29() {
    if (Companion_instance_29 == null)
      new Companion_30();
    return Companion_instance_29;
  }
  function Color_0() {
  }
  Color_0.$metadata$ = {
    simpleName: 'Color',
    kind: 'interface',
    interfaces: [CanvasPaint, CanvasPaintProvider]
  };
  function darker($this, colorValue, shadeFactor) {
    return roundToInt(coerceAtLeast_0(colorValue * (1.0 - shadeFactor), 0.0));
  }
  function RgbaColor_init_$Init$(red, green, blue, alpha, $mask0, $marker, $this) {
    if (!(($mask0 & 8) === 0))
      alpha = null;
    RgbaColor.call($this, red, green, blue, alpha);
    return $this;
  }
  function RgbaColor_init_$Create$(red, green, blue, alpha, $mask0, $marker) {
    return RgbaColor_init_$Init$(red, green, blue, alpha, $mask0, $marker, Object.create(RgbaColor.prototype));
  }
  function Companion_31() {
    Companion_instance_30 = this;
  }
  Companion_31.prototype.invoke_501 = function (red, green, blue, alpha) {
    var tmp0_require_0 = rangeTo(0.0, 1.0).contains_10(red);
    if (!tmp0_require_0) {
      var message_1 = '' + 'Red out of range: <' + red + '>. Allowed values between 0 and 1.0';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp1_require_0 = rangeTo(0.0, 1.0).contains_10(green);
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'Green out of range: <' + green + '>. Allowed values between 0 and 1.0';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
    }var tmp2_require_0 = rangeTo(0.0, 1.0).contains_10(blue);
    if (!tmp2_require_0) {
      var message_1_1 = '' + 'Blue out of range: <' + blue + '>. Allowed values between 0 and 1.0';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_1));
    }return new RgbaColor(roundToInt(red * 255.0), roundToInt(green * 255.0), roundToInt(blue * 255.0), alpha);
  };
  Companion_31.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_30;
  function Companion_getInstance_30() {
    if (Companion_instance_30 == null)
      new Companion_31();
    return Companion_instance_30;
  }
  function RgbaColor(red, green, blue, alpha) {
    Companion_getInstance_30();
    this._red_0 = red;
    this._green_0 = green;
    this._blue_0 = blue;
    this._alpha = alpha;
    var tmp0_require_0 = numberRangeToNumber(0, 255).contains_11(this._red_0);
    if (!tmp0_require_0) {
      var message_1 = '' + 'Red out of range: <' + this._red_0 + '>. Allowed values between 0 and 255';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp1_require_0 = numberRangeToNumber(0, 255).contains_11(this._green_0);
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'Green out of range: <' + this._green_0 + '>. Allowed values between 0 and 255';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
    }var tmp2_require_0 = numberRangeToNumber(0, 255).contains_11(this._blue_0);
    if (!tmp2_require_0) {
      var message_1_1 = '' + 'Blue out of range: <' + this._blue_0 + '>. Allowed values between 0 and 255';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_1));
    }if (!(this._alpha == null)) {
      var tmp3_require_0 = rangeTo(0.0, 1.0).contains_10(this._alpha);
      if (!tmp3_require_0) {
        var message_1_2 = '' + 'Alpha out of range: <' + this._alpha + '>. Allowed values between 0.0 and 1.0';
        throw IllegalArgumentException_init_$Create$(toString_2(message_1_2));
      }}this._web = toWebString(this._red_0, this._green_0, this._blue_0, this._alpha);
  }
  RgbaColor.prototype._get_web__1 = function () {
    return this._web;
  };
  RgbaColor.prototype.darker = function (shadeFactor) {
    return new RgbaColor(darker(Companion_getInstance_30(), this._red_0, shadeFactor), darker(Companion_getInstance_30(), this._green_0, shadeFactor), darker(Companion_getInstance_30(), this._blue_0, shadeFactor), this._alpha);
  };
  RgbaColor.prototype.withAlpha = function (newAlpha) {
    return new RgbaColor(this._red_0, this._green_0, this._blue_0, newAlpha);
  };
  RgbaColor.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(!(other == null) ? isInterface(other, Color_0) : false))
      return false;
    else {
    }
    if (!(this._web === other._get_web__1()))
      return false;
    return true;
  };
  RgbaColor.prototype.hashCode = function () {
    return getStringHashCode(this._web);
  };
  RgbaColor.prototype.toString = function () {
    return this._web;
  };
  RgbaColor.$metadata$ = {
    simpleName: 'RgbaColor',
    kind: 'class',
    interfaces: [Color_0]
  };
  function WebColor(web) {
    this._web_0 = web;
    var tmp0_isNotEmpty_0 = this._web_0;
    var tmp1_require_0 = charSequenceLength(tmp0_isNotEmpty_0) > 0;
    if (!tmp1_require_0) {
      var message_1 = 'empty string not allowed';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }if (startsWith$default_0(this._web_0, '#', false, 2, null)) {
      var tmp = new Char(32);
      var tmp2_require_0 = !contains$default(this._web_0, tmp, false, 2, null);
      if (!tmp2_require_0) {
        var message_1_0 = '' + 'Must not contain spaces: <' + this._web_0 + '>';
        throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
      }} else {
    }
  }
  WebColor.prototype._get_web__1 = function () {
    return this._web_0;
  };
  WebColor.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(!(other == null) ? isInterface(other, Color_0) : false))
      return false;
    else {
    }
    if (!(this._web_0 === other._get_web__1()))
      return false;
    return true;
  };
  WebColor.prototype.hashCode = function () {
    return getStringHashCode(this._web_0);
  };
  WebColor.prototype.toString = function () {
    return this._web_0;
  };
  WebColor.$metadata$ = {
    simpleName: 'WebColor',
    kind: 'class',
    interfaces: [Color_0]
  };
  function CanvasPaint() {
  }
  CanvasPaint.$metadata$ = {
    simpleName: 'CanvasPaint',
    kind: 'interface',
    interfaces: []
  };
  function CanvasLinearGradient() {
  }
  CanvasLinearGradient.$metadata$ = {
    simpleName: 'CanvasLinearGradient',
    kind: 'class',
    interfaces: [CanvasPaint]
  };
  function CanvasRadialGradient() {
  }
  CanvasRadialGradient.$metadata$ = {
    simpleName: 'CanvasRadialGradient',
    kind: 'class',
    interfaces: [CanvasPaint]
  };
  function CanvasPaintProvider() {
  }
  CanvasPaintProvider.$metadata$ = {
    simpleName: 'CanvasPaintProvider',
    kind: 'interface',
    interfaces: []
  };
  var opacityFormat;
  function toWebString(red, green, blue, opacity) {
    if (opacity == null) {
      return toHex(red, green, blue, null);
    }return toRgbaString(red, green, blue, opacity);
  }
  function toHex(red, green, blue, opacity) {
    var tmp0_safe_receiver = opacity;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = to2DigitHex(tmp0_safe_receiver);
    }
    var tmp1_elvis_lhs = tmp;
    var opacityHex = tmp1_elvis_lhs == null ? '' : tmp1_elvis_lhs;
    return '' + '#' + to2DigitHex(red) + to2DigitHex(green) + to2DigitHex(blue) + opacityHex;
  }
  function toRgbaString(red, green, blue, opacity) {
    return '' + 'rgba(' + red + ',' + green + ',' + blue + ',' + opacityFormat.format_6(opacity, Companion_getInstance_40()._US) + ')';
  }
  function to2DigitHex(value) {
    var hex_0 = toString_0(value, 16);
    var tmp0_toUpperCase_0 = padStart(hex_0, 2, new Char(48));
    return tmp0_toUpperCase_0.toUpperCase();
  }
  function CachedTimeZoneOffsetProvider() {
  }
  CachedTimeZoneOffsetProvider.$metadata$ = {
    simpleName: 'CachedTimeZoneOffsetProvider',
    kind: 'interface',
    interfaces: [TimeZoneOffsetProvider]
  };
  function cached(_this_, cacheSize) {
    return new DefaultCachedTimeZoneOffsetProvider(_this_, cacheSize);
  }
  function cached$default(_this_, cacheSize, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      cacheSize = 500;
    return cached(_this_, cacheSize);
  }
  function Companion_32() {
    Companion_instance_31 = this;
    this._fifteenMinutes = _Duration___get_inMilliseconds__impl_(_get_minutes_(15));
  }
  Companion_32.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_31;
  function Companion_getInstance_31() {
    if (Companion_instance_31 == null)
      new Companion_32();
    return Companion_instance_31;
  }
  function DefaultCachedTimeZoneOffsetProvider(delegate_0, cacheSize) {
    Companion_getInstance_31();
    this._delegate_2 = delegate_0;
    this._cacheSize = cacheSize;
    var tmp = this._delegate_2;
    var tmp0_require_0 = !isInterface(tmp, CachedTimeZoneOffsetProvider);
    if (!tmp0_require_0) {
      var message_1 = 'cannot cache an already cached time-zone-offset provider';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp_0 = this;
    tmp_0._cache_0 = Cache_init_$Create$_0(this._cacheSize, null, 2, null);
  }
  DefaultCachedTimeZoneOffsetProvider.$metadata$ = {
    simpleName: 'DefaultCachedTimeZoneOffsetProvider',
    kind: 'class',
    interfaces: [CachedTimeZoneOffsetProvider]
  };
  var defaultTimeZoneOffsetProvider;
  var timeZoneOffsetProvider;
  function TimeZoneOffsetProvider() {
  }
  TimeZoneOffsetProvider.$metadata$ = {
    simpleName: 'TimeZoneOffsetProvider',
    kind: 'interface',
    interfaces: []
  };
  function defaultTimeZoneOffsetProvider$init$() {
    var tmp = new DefaultTimeZoneOffsetProvider();
    return cached$default(tmp, 0, 1, null);
  }
  function BasePointProvider() {
  }
  BasePointProvider.$metadata$ = {
    simpleName: 'BasePointProvider',
    kind: 'interface',
    interfaces: []
  };
  function DirectionBasedBasePointProvider_init_$Init$(direction, translation, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      translation = Companion_getInstance_34()._zero;
    DirectionBasedBasePointProvider.call($this, direction, translation);
    return $this;
  }
  function DirectionBasedBasePointProvider_init_$Create$(direction, translation, $mask0, $marker) {
    return DirectionBasedBasePointProvider_init_$Init$(direction, translation, $mask0, $marker, Object.create(DirectionBasedBasePointProvider.prototype));
  }
  function DirectionBasedBasePointProvider(direction, translation) {
    this._direction_0 = direction;
    this._translation = translation;
  }
  DirectionBasedBasePointProvider.prototype.calculateBasePoint_0 = function (boundingBox) {
    return boundingBox.findCoordinates(this._direction_0).plus_0(this._translation);
  };
  DirectionBasedBasePointProvider.$metadata$ = {
    simpleName: 'DirectionBasedBasePointProvider',
    kind: 'class',
    interfaces: [BasePointProvider]
  };
  function Coordinates_init_$Init$(x, y, $this) {
    Coordinates.call($this, x, y);
    return $this;
  }
  function Coordinates_init_$Create$(x, y) {
    return Coordinates_init_$Init$(x, y, Object.create(Coordinates.prototype));
  }
  function Companion_33() {
    Companion_instance_32 = this;
    this._origin_0 = Coordinates_init_$Create$(0, 0);
    this._none = this._origin_0;
  }
  Companion_33.prototype.of_4 = function (x, y) {
    return new Coordinates(x, y);
  };
  Companion_33.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_32;
  function Companion_getInstance_32() {
    if (Companion_instance_32 == null)
      new Companion_33();
    return Companion_instance_32;
  }
  function Coordinates(x, y) {
    Companion_getInstance_32();
    this._x = x;
    this._y = y;
  }
  Coordinates.prototype.plus_0 = function (vector) {
    if (vector.isZero()) {
      return this;
    }return Companion_getInstance_32().of_4(this._x + vector._x_0, this._y + vector._y_0);
  };
  Coordinates.prototype.toString = function () {
    return '' + this._x + '/' + this._y;
  };
  Coordinates.prototype.delta_0 = function (other) {
    return Companion_getInstance_34().of_4(this._x - other._x, this._y - other._y);
  };
  Coordinates.prototype.center = function (other) {
    return new Coordinates(0.5 * (this._x + other._x), 0.5 * (this._y + other._y));
  };
  Coordinates.prototype.component1_0 = function () {
    return this._x;
  };
  Coordinates.prototype.component2_0 = function () {
    return this._y;
  };
  Coordinates.prototype.hashCode = function () {
    var result = getNumberHashCode(this._x);
    result = imul(result, 31) + getNumberHashCode(this._y) | 0;
    return result;
  };
  Coordinates.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Coordinates))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Coordinates ? other : THROW_CCE();
    if (!equals_0(this._x, tmp0_other_with_cast._x))
      return false;
    if (!equals_0(this._y, tmp0_other_with_cast._y))
      return false;
    return true;
  };
  Coordinates.$metadata$ = {
    simpleName: 'Coordinates',
    kind: 'class',
    interfaces: []
  };
  var Direction_Center_instance;
  var Direction_CenterLeft_instance;
  var Direction_CenterRight_instance;
  var Direction_BaseLineCenter_instance;
  var Direction_BaseLineLeft_instance;
  var Direction_BaseLineRight_instance;
  var Direction_TopLeft_instance;
  var Direction_TopCenter_instance;
  var Direction_TopRight_instance;
  var Direction_BottomLeft_instance;
  var Direction_BottomCenter_instance;
  var Direction_BottomRight_instance;
  function Companion_34() {
    Companion_instance_33 = this;
    this._allButBaseline = listOf([Direction_Center_getInstance(), Direction_CenterLeft_getInstance(), Direction_CenterRight_getInstance(), Direction_TopLeft_getInstance(), Direction_TopCenter_getInstance(), Direction_TopRight_getInstance(), Direction_BottomLeft_getInstance(), Direction_BottomCenter_getInstance(), Direction_BottomRight_getInstance()]);
  }
  Companion_34.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_33;
  function Companion_getInstance_33() {
    if (Companion_instance_33 == null)
      new Companion_34();
    return Companion_instance_33;
  }
  var Direction_entriesInitialized;
  function Direction_initEntries() {
    if (Direction_entriesInitialized)
      return Unit_getInstance();
    Direction_entriesInitialized = true;
    Direction_Center_instance = new Direction_0('Center', 0, VerticalAlignment_Center_getInstance(), HorizontalAlignment_Center_getInstance());
    Direction_CenterLeft_instance = new Direction_0('CenterLeft', 1, VerticalAlignment_Center_getInstance(), HorizontalAlignment_Left_getInstance());
    Direction_CenterRight_instance = new Direction_0('CenterRight', 2, VerticalAlignment_Center_getInstance(), HorizontalAlignment_Right_getInstance());
    Direction_BaseLineCenter_instance = new Direction_0('BaseLineCenter', 3, VerticalAlignment_Baseline_getInstance(), HorizontalAlignment_Center_getInstance());
    Direction_BaseLineLeft_instance = new Direction_0('BaseLineLeft', 4, VerticalAlignment_Baseline_getInstance(), HorizontalAlignment_Left_getInstance());
    Direction_BaseLineRight_instance = new Direction_0('BaseLineRight', 5, VerticalAlignment_Baseline_getInstance(), HorizontalAlignment_Right_getInstance());
    Direction_TopLeft_instance = new Direction_0('TopLeft', 6, VerticalAlignment_Top_getInstance(), HorizontalAlignment_Left_getInstance());
    Direction_TopCenter_instance = new Direction_0('TopCenter', 7, VerticalAlignment_Top_getInstance(), HorizontalAlignment_Center_getInstance());
    Direction_TopRight_instance = new Direction_0('TopRight', 8, VerticalAlignment_Top_getInstance(), HorizontalAlignment_Right_getInstance());
    Direction_BottomLeft_instance = new Direction_0('BottomLeft', 9, VerticalAlignment_Bottom_getInstance(), HorizontalAlignment_Left_getInstance());
    Direction_BottomCenter_instance = new Direction_0('BottomCenter', 10, VerticalAlignment_Bottom_getInstance(), HorizontalAlignment_Center_getInstance());
    Direction_BottomRight_instance = new Direction_0('BottomRight', 11, VerticalAlignment_Bottom_getInstance(), HorizontalAlignment_Right_getInstance());
    Companion_getInstance_33();
  }
  function Direction_0(name, ordinal, verticalAlignment, horizontalAlignment) {
    Enum.call(this, name, ordinal);
    this._verticalAlignment = verticalAlignment;
    this._horizontalAlignment = horizontalAlignment;
  }
  Direction_0.$metadata$ = {
    simpleName: 'Direction',
    kind: 'class',
    interfaces: []
  };
  function Direction_Center_getInstance() {
    Direction_initEntries();
    return Direction_Center_instance;
  }
  function Direction_CenterLeft_getInstance() {
    Direction_initEntries();
    return Direction_CenterLeft_instance;
  }
  function Direction_CenterRight_getInstance() {
    Direction_initEntries();
    return Direction_CenterRight_instance;
  }
  function Direction_BaseLineCenter_getInstance() {
    Direction_initEntries();
    return Direction_BaseLineCenter_instance;
  }
  function Direction_BaseLineLeft_getInstance() {
    Direction_initEntries();
    return Direction_BaseLineLeft_instance;
  }
  function Direction_BaseLineRight_getInstance() {
    Direction_initEntries();
    return Direction_BaseLineRight_instance;
  }
  function Direction_TopLeft_getInstance() {
    Direction_initEntries();
    return Direction_TopLeft_instance;
  }
  function Direction_TopCenter_getInstance() {
    Direction_initEntries();
    return Direction_TopCenter_instance;
  }
  function Direction_TopRight_getInstance() {
    Direction_initEntries();
    return Direction_TopRight_instance;
  }
  function Direction_BottomLeft_getInstance() {
    Direction_initEntries();
    return Direction_BottomLeft_instance;
  }
  function Direction_BottomCenter_getInstance() {
    Direction_initEntries();
    return Direction_BottomCenter_instance;
  }
  function Direction_BottomRight_getInstance() {
    Direction_initEntries();
    return Direction_BottomRight_instance;
  }
  function Companion_35() {
    Companion_instance_34 = this;
    this._zero = new Distance(0.0, 0.0);
    this._none_0 = this._zero;
  }
  Companion_35.prototype.of_4 = function (x, y) {
    return new Distance(x, y);
  };
  Companion_35.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_34;
  function Companion_getInstance_34() {
    if (Companion_instance_34 == null)
      new Companion_35();
    return Companion_instance_34;
  }
  function Distance(x, y) {
    Companion_getInstance_34();
    this._x_0 = x;
    this._y_0 = y;
  }
  Distance.prototype.toString = function () {
    return '' + this._x_0 + '/' + this._y_0;
  };
  Distance.prototype.plus_1 = function (deltaX, deltaY) {
    return Companion_getInstance_34().of_4(this._x_0 + deltaX, this._y_0 + deltaY);
  };
  Distance.prototype.avoidNaN = function () {
    if (isNaN_0(this._x_0) ? true : isNaN_0(this._y_0)) {
      return new Distance(or0ifNaN(this._x_0), or0ifNaN(this._y_0));
    }return this;
  };
  Distance.prototype.isZero = function () {
    return this._x_0 === 0.0 ? this._y_0 === 0.0 : false;
  };
  Distance.prototype.abs_0 = function () {
    if (this._x_0 >= 0.0 ? this._y_0 >= 0.0 : false) {
      return this;
    }var tmp0__get_absoluteValue__0 = this._x_0;
    var tmp = Math.abs(tmp0__get_absoluteValue__0);
    var tmp1__get_absoluteValue__0 = this._y_0;
    return new Distance(tmp, Math.abs(tmp1__get_absoluteValue__0));
  };
  Distance.prototype.asSize = function () {
    return new Size(this._x_0, this._y_0);
  };
  Distance.prototype.hashCode = function () {
    var result = getNumberHashCode(this._x_0);
    result = imul(result, 31) + getNumberHashCode(this._y_0) | 0;
    return result;
  };
  Distance.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Distance))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Distance ? other : THROW_CCE();
    if (!equals_0(this._x_0, tmp0_other_with_cast._x_0))
      return false;
    if (!equals_0(this._y_0, tmp0_other_with_cast._y_0))
      return false;
    return true;
  };
  Distance.$metadata$ = {
    simpleName: 'Distance',
    kind: 'class',
    interfaces: []
  };
  var HorizontalAlignment_Left_instance;
  var HorizontalAlignment_Right_instance;
  var HorizontalAlignment_Center_instance;
  var HorizontalAlignment_entriesInitialized;
  function HorizontalAlignment_initEntries() {
    if (HorizontalAlignment_entriesInitialized)
      return Unit_getInstance();
    HorizontalAlignment_entriesInitialized = true;
    HorizontalAlignment_Left_instance = new HorizontalAlignment('Left', 0);
    HorizontalAlignment_Right_instance = new HorizontalAlignment('Right', 1);
    HorizontalAlignment_Center_instance = new HorizontalAlignment('Center', 2);
  }
  function HorizontalAlignment(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  HorizontalAlignment.$metadata$ = {
    simpleName: 'HorizontalAlignment',
    kind: 'class',
    interfaces: []
  };
  function HorizontalAlignment_Left_getInstance() {
    HorizontalAlignment_initEntries();
    return HorizontalAlignment_Left_instance;
  }
  function HorizontalAlignment_Right_getInstance() {
    HorizontalAlignment_initEntries();
    return HorizontalAlignment_Right_instance;
  }
  function HorizontalAlignment_Center_getInstance() {
    HorizontalAlignment_initEntries();
    return HorizontalAlignment_Center_instance;
  }
  function Companion_36() {
    Companion_instance_35 = this;
    this._empty = this.of_1(0.0);
  }
  Companion_36.prototype.of_1 = function (allSides) {
    return new Insets(allSides, allSides, allSides, allSides);
  };
  Companion_36.prototype.of_2 = function (top, right, bottom, left) {
    return new Insets(top, right, bottom, left);
  };
  Companion_36.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_35;
  function Companion_getInstance_35() {
    if (Companion_instance_35 == null)
      new Companion_36();
    return Companion_instance_35;
  }
  function Insets(top, right, bottom, left) {
    Companion_getInstance_35();
    this._top = top;
    this._right = right;
    this._bottom = bottom;
    this._left = left;
    this._offsetWidth = this._right + this._left;
    this._offsetHeight = this._top + this._bottom;
  }
  Insets.prototype.toString = function () {
    return '' + 'Insets(top=' + this._top + ', right=' + this._right + ', bottom=' + this._bottom + ', left=' + this._left + ')';
  };
  Insets.prototype.hashCode = function () {
    var result = getNumberHashCode(this._top);
    result = imul(result, 31) + getNumberHashCode(this._right) | 0;
    result = imul(result, 31) + getNumberHashCode(this._bottom) | 0;
    result = imul(result, 31) + getNumberHashCode(this._left) | 0;
    return result;
  };
  Insets.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Insets))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Insets ? other : THROW_CCE();
    if (!equals_0(this._top, tmp0_other_with_cast._top))
      return false;
    if (!equals_0(this._right, tmp0_other_with_cast._right))
      return false;
    if (!equals_0(this._bottom, tmp0_other_with_cast._bottom))
      return false;
    if (!equals_0(this._left, tmp0_other_with_cast._left))
      return false;
    return true;
  };
  Insets.$metadata$ = {
    simpleName: 'Insets',
    kind: 'class',
    interfaces: []
  };
  function Rectangle_init_$Init$(x, y, width, height, $this) {
    Rectangle.call($this, new Coordinates(x, y), new Size(width, height));
    return $this;
  }
  function Rectangle_init_$Create$(x, y, width, height) {
    return Rectangle_init_$Init$(x, y, width, height, Object.create(Rectangle.prototype));
  }
  function Companion_37() {
    Companion_instance_36 = this;
    this._zero_0 = new Rectangle(Companion_getInstance_32()._origin_0, Companion_getInstance_37()._zero_1);
  }
  Companion_37.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_36;
  function Companion_getInstance_36() {
    if (Companion_instance_36 == null)
      new Companion_37();
    return Companion_instance_36;
  }
  function Rectangle(location, size) {
    Companion_getInstance_36();
    this._location = location;
    this._size_2 = size;
  }
  Rectangle.prototype._get_width__41 = function () {
    return this._size_2._width_0;
  };
  Rectangle.prototype._get_height__41 = function () {
    return this._size_2._height;
  };
  Rectangle.prototype._get_left__32 = function () {
    if (isNegative_0(this._get_width__41())) {
      return this._location._x + this._get_width__41();
    }return this._location._x;
  };
  Rectangle.prototype._get_right__32 = function () {
    if (isNegative_0(this._get_width__41())) {
      return this._location._x;
    }return this._location._x + this._get_width__41();
  };
  Rectangle.prototype._get_top__32 = function () {
    if (isNegative_0(this._get_height__41())) {
      return this._location._y + this._get_height__41();
    }return this._location._y;
  };
  Rectangle.prototype._get_bottom__32 = function () {
    if (isNegative_0(this._get_height__41())) {
      return this._location._y;
    }return this._location._y + this._get_height__41();
  };
  Rectangle.prototype._get_widthAbs_ = function () {
    var tmp0__get_absoluteValue__0 = this._get_width__41();
    return Math.abs(tmp0__get_absoluteValue__0);
  };
  Rectangle.prototype._get_heightAbs_ = function () {
    var tmp0__get_absoluteValue__0 = this._get_height__41();
    return Math.abs(tmp0__get_absoluteValue__0);
  };
  Rectangle.prototype._get_centerX_ = function () {
    return this._get_left__32() + this._get_width__41() / 2.0;
  };
  Rectangle.prototype._get_centerY_ = function () {
    return this._get_top__32() + this._get_height__41() / 2.0;
  };
  Rectangle.prototype.findCoordinates = function (direction) {
    var tmp0_subject = direction._horizontalAlignment;
    var tmp;
    if (tmp0_subject.equals(HorizontalAlignment_Left_getInstance())) {
      tmp = this._get_left__32();
    } else if (tmp0_subject.equals(HorizontalAlignment_Right_getInstance())) {
      tmp = this._get_right__32();
    } else if (tmp0_subject.equals(HorizontalAlignment_Center_getInstance())) {
      tmp = this._get_centerX_();
    } else {
      noWhenBranchMatchedException();
    }
    var x = tmp;
    var tmp1_subject = direction._verticalAlignment;
    var tmp_0;
    if (tmp1_subject.equals(VerticalAlignment_Top_getInstance())) {
      tmp_0 = this._get_top__32();
    } else if (tmp1_subject.equals(VerticalAlignment_Center_getInstance())) {
      tmp_0 = this._get_centerY_();
    } else if (tmp1_subject.equals(VerticalAlignment_Baseline_getInstance())) {
      tmp_0 = this._get_centerY_();
    } else if (tmp1_subject.equals(VerticalAlignment_Bottom_getInstance())) {
      tmp_0 = this._get_bottom__32();
    } else {
      noWhenBranchMatchedException();
    }
    var y = tmp_0;
    return Companion_getInstance_32().of_4(x, y);
  };
  Rectangle.prototype.toString = function () {
    return '' + 'Rectangle(location=' + this._location + ', size=' + this._size_2 + ')';
  };
  Rectangle.prototype.hashCode = function () {
    var result = this._location.hashCode();
    result = imul(result, 31) + this._size_2.hashCode() | 0;
    return result;
  };
  Rectangle.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Rectangle))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Rectangle ? other : THROW_CCE();
    if (!this._location.equals(tmp0_other_with_cast._location))
      return false;
    if (!this._size_2.equals(tmp0_other_with_cast._size_2))
      return false;
    return true;
  };
  Rectangle.$metadata$ = {
    simpleName: 'Rectangle',
    kind: 'class',
    interfaces: []
  };
  function Size_init_$Init$(width, height, $this) {
    Size.call($this, width, height);
    return $this;
  }
  function Size_init_$Create$(width, height) {
    return Size_init_$Init$(width, height, Object.create(Size.prototype));
  }
  function Companion_38() {
    Companion_instance_37 = this;
    this._invalid = new Size(NaN, NaN);
    this._zero_1 = new Size(0.0, 0.0);
    this._none_1 = this._zero_1;
    this._one = new Size(1.0, 1.0);
    this._half = new Size(0.5, 0.5);
    this._PX_16 = this.both(16.0);
    this._PX_24 = this.both(24.0);
    this._PX_30 = this.both(30.0);
    this._PX_40 = this.both(40.0);
    this._PX_50 = this.both(50.0);
    this._PX_60 = this.both(60.0);
    this._PX_90 = this.both(90.0);
    this._PX_120 = this.both(120.0);
  }
  Companion_38.prototype.of_4 = function (width, height) {
    return new Size(width, height);
  };
  Companion_38.prototype.both = function (widthHeight) {
    return new Size(widthHeight, widthHeight);
  };
  Companion_38.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_37;
  function Companion_getInstance_37() {
    if (Companion_instance_37 == null)
      new Companion_38();
    return Companion_instance_37;
  }
  function Size(width, height) {
    Companion_getInstance_37();
    this._width_0 = width;
    this._height = height;
  }
  Size.prototype._get_aspectRatio__0 = function () {
    return this._width_0 / this._height;
  };
  Size.prototype.toString = function () {
    return '' + this._width_0 + 'x' + this._height;
  };
  Size.prototype.withMax = function (maxWidth, maxHeight) {
    var tmp = Companion_getInstance_37();
    var tmp0_min_0 = this._width_0;
    var tmp_0 = function () {
      var $externalVarargReceiverTmp = Math;
      return $externalVarargReceiverTmp.min.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Float64Array([tmp0_min_0, maxWidth]))));
    }.call(this);
    var tmp1_min_0 = this._height;
    return tmp.of_4(tmp_0, function () {
      var $externalVarargReceiverTmp = Math;
      return $externalVarargReceiverTmp.min.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Float64Array([tmp1_min_0, maxHeight]))));
    }.call(this));
  };
  Size.prototype.divide = function (divisor) {
    if (divisor === 1.0) {
      return this;
    }return new Size(this._width_0 / divisor, this._height / divisor);
  };
  Size.prototype.bothLargerThanOrEqual = function (width, height) {
    return this._width_0 >= width ? this._height >= height : false;
  };
  Size.prototype.bothNotNegative = function () {
    return this._width_0 >= 0.0 ? this._height >= 0.0 : false;
  };
  Size.prototype.atLeastOneZero = function () {
    return this._width_0 === 0.0 ? true : this._height === 0.0;
  };
  Size.prototype.minus_0 = function (minusWidth, minusHeight) {
    if (minusWidth === 0.0 ? minusHeight === 0.0 : false) {
      return this;
    }return Companion_getInstance_37().of_4(this._width_0 - minusWidth, this._height - minusHeight);
  };
  Size.prototype.fitWithAspectRatio = function (aspectRatio) {
    var maxWidth = this._height * aspectRatio;
    var maxHeight = this._width_0 / aspectRatio;
    return new Size(coerceAtMost_0(this._width_0, maxWidth), coerceAtMost_0(this._height, maxHeight));
  };
  Size.prototype.containWithAspectRatio = function (aspectRatio) {
    var minWidth = this._height * aspectRatio;
    var minHeight = this._width_0 / aspectRatio;
    return new Size(coerceAtLeast_0(this._width_0, minWidth), coerceAtLeast_0(this._height, minHeight));
  };
  Size.prototype.coerceAtLeast = function (minimum) {
    return Companion_getInstance_37().of_4(coerceAtLeast_0(this._width_0, minimum._width_0), coerceAtLeast_0(this._height, minimum._height));
  };
  Size.prototype.hashCode = function () {
    var result = getNumberHashCode(this._width_0);
    result = imul(result, 31) + getNumberHashCode(this._height) | 0;
    return result;
  };
  Size.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Size))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Size ? other : THROW_CCE();
    if (!equals_0(this._width_0, tmp0_other_with_cast._width_0))
      return false;
    if (!equals_0(this._height, tmp0_other_with_cast._height))
      return false;
    return true;
  };
  Size.$metadata$ = {
    simpleName: 'Size',
    kind: 'class',
    interfaces: []
  };
  var VerticalAlignment_Top_instance;
  var VerticalAlignment_Center_instance;
  var VerticalAlignment_Baseline_instance;
  var VerticalAlignment_Bottom_instance;
  var VerticalAlignment_entriesInitialized;
  function VerticalAlignment_initEntries() {
    if (VerticalAlignment_entriesInitialized)
      return Unit_getInstance();
    VerticalAlignment_entriesInitialized = true;
    VerticalAlignment_Top_instance = new VerticalAlignment('Top', 0);
    VerticalAlignment_Center_instance = new VerticalAlignment('Center', 1);
    VerticalAlignment_Baseline_instance = new VerticalAlignment('Baseline', 2);
    VerticalAlignment_Bottom_instance = new VerticalAlignment('Bottom', 3);
  }
  function VerticalAlignment(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  VerticalAlignment.$metadata$ = {
    simpleName: 'VerticalAlignment',
    kind: 'class',
    interfaces: []
  };
  function VerticalAlignment_Top_getInstance() {
    VerticalAlignment_initEntries();
    return VerticalAlignment_Top_instance;
  }
  function VerticalAlignment_Center_getInstance() {
    VerticalAlignment_initEntries();
    return VerticalAlignment_Center_instance;
  }
  function VerticalAlignment_Baseline_getInstance() {
    VerticalAlignment_initEntries();
    return VerticalAlignment_Baseline_instance;
  }
  function VerticalAlignment_Bottom_getInstance() {
    VerticalAlignment_initEntries();
    return VerticalAlignment_Bottom_instance;
  }
  function Companion_39() {
    Companion_instance_38 = this;
    this._default_0 = new Zoom(1.0, 1.0);
  }
  Companion_39.prototype.of_4 = function (zoomScaleX, zoomScaleY) {
    return new Zoom(zoomScaleX, zoomScaleY);
  };
  Companion_39.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_38;
  function Companion_getInstance_38() {
    if (Companion_instance_38 == null)
      new Companion_39();
    return Companion_instance_38;
  }
  function Zoom(scaleX, scaleY) {
    Companion_getInstance_38();
    this._scaleX = scaleX;
    this._scaleY = scaleY;
    var tmp0_require_0 = isPositive(this._scaleX) ? !isInfinite(this._scaleX) : false;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Scale x must be positive but was <' + this._scaleX + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp1_require_0 = isPositive(this._scaleY) ? !isInfinite(this._scaleY) : false;
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'Scale y must be positive but was <' + this._scaleY + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
    }}
  Zoom.prototype._get_aspectRatio__0 = function () {
    return this._scaleX / this._scaleY;
  };
  Zoom.prototype.toString = function () {
    return '' + 'scaleX: ' + this._scaleX + '; scaleY: ' + this._scaleY;
  };
  Zoom.prototype.smallerValueForBoth = function () {
    if (this._scaleX === this._scaleY) {
      return this;
    }var tmp0_min_0 = this._scaleX;
    var tmp1_min_0 = this._scaleY;
    var factor = function () {
      var $externalVarargReceiverTmp = Math;
      return $externalVarargReceiverTmp.min.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Float64Array([tmp0_min_0, tmp1_min_0]))));
    }.call(this);
    return new Zoom(factor, factor);
  };
  Zoom.prototype.hashCode = function () {
    var result = getNumberHashCode(this._scaleX);
    result = imul(result, 31) + getNumberHashCode(this._scaleY) | 0;
    return result;
  };
  Zoom.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Zoom))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Zoom ? other : THROW_CCE();
    if (!equals_0(this._scaleX, tmp0_other_with_cast._scaleX))
      return false;
    if (!equals_0(this._scaleY, tmp0_other_with_cast._scaleY))
      return false;
    return true;
  };
  Zoom.$metadata$ = {
    simpleName: 'Zoom',
    kind: 'class',
    interfaces: []
  };
  function Companion_40() {
    Companion_instance_39 = this;
  }
  Companion_40.prototype.forListModulo = function (values_3) {
    return new ListModuloProvider(values_3);
  };
  Companion_40.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_39;
  function Companion_getInstance_39() {
    if (Companion_instance_39 == null)
      new Companion_40();
    return Companion_instance_39;
  }
  function MultiProvider() {
  }
  MultiProvider.$metadata$ = {
    simpleName: 'MultiProvider',
    kind: 'interface',
    interfaces: []
  };
  function ListModuloProvider(values_3) {
    this._values_0 = values_3;
    var tmp0_isNotEmpty_0 = this._values_0;
    var tmp1_require_0 = !tmp0_isNotEmpty_0.isEmpty_33();
    if (!tmp1_require_0) {
      var message_1 = 'List must not be empty';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }}
  ListModuloProvider.$metadata$ = {
    simpleName: 'ListModuloProvider',
    kind: 'class',
    interfaces: [MultiProvider]
  };
  function Disposable() {
  }
  Disposable.$metadata$ = {
    simpleName: 'Disposable',
    kind: 'interface',
    interfaces: []
  };
  function verifyNotDisposed($this) {
    var tmp0_check_0 = !$this._disposed;
    if (!tmp0_check_0) {
      var message_1 = 'Already disposed';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }}
  function DisposeSupport() {
    var tmp = this;
    tmp._disposeActions = ArrayList_init_$Create$();
    this._disposed = false;
  }
  DisposeSupport.prototype.onDispose_10 = function (action) {
    verifyNotDisposed(this);
    this._disposeActions.add_57(action);
    Unit_getInstance();
  };
  DisposeSupport.prototype.dispose_46 = function () {
    var tmp0_fastForEach_0 = this._disposeActions;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4();
    }
    this._disposed = true;
  };
  DisposeSupport.$metadata$ = {
    simpleName: 'DisposeSupport',
    kind: 'class',
    interfaces: [Disposable, OnDispose]
  };
  function _no_name_provided__264($boundThis) {
    this._$boundThis_8 = $boundThis;
  }
  _no_name_provided__264.prototype.invoke_1037 = function () {
    return this._$boundThis_8.dispose_46();
  };
  _no_name_provided__264.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__264.prototype._get_name__47 = function () {
    return 'dispose';
  };
  _no_name_provided__264.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function OnDispose() {
  }
  OnDispose.prototype.onDispose_9 = function (disposable) {
    this.onDispose_10(_no_name_provided_$factory_255(disposable));
  };
  OnDispose.$metadata$ = {
    simpleName: 'OnDispose',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided_$factory_255($boundThis) {
    var i = new _no_name_provided__264($boundThis);
    var l = function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function CachedDateTimeFormatter() {
  }
  CachedDateTimeFormatter.$metadata$ = {
    simpleName: 'CachedDateTimeFormatter',
    kind: 'interface',
    interfaces: [DateTimeFormat]
  };
  function cached_0(_this_, cacheSize) {
    return new DefaultCachedDateTimeFormatter(_this_, cacheSize);
  }
  function cached$default_0(_this_, cacheSize, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      cacheSize = 100;
    return cached_0(_this_, cacheSize);
  }
  function DefaultCachedDateTimeFormatter(formatter, cacheSize) {
    this._formatter = formatter;
    this._cacheSize_0 = cacheSize;
    var tmp = this._formatter;
    var tmp0_require_0 = !isInterface(tmp, CachedFormatter);
    if (!tmp0_require_0) {
      var message_1 = 'cannot cache an already cached dateTime format';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }this._formatCache = cache('DefaultCachedDateTimeFormatter', this._cacheSize_0);
  }
  DefaultCachedDateTimeFormatter.$metadata$ = {
    simpleName: 'DefaultCachedDateTimeFormatter',
    kind: 'class',
    interfaces: [CachedDateTimeFormatter]
  };
  function CachedFormatter() {
  }
  CachedFormatter.$metadata$ = {
    simpleName: 'CachedFormatter',
    kind: 'interface',
    interfaces: [NumberFormat]
  };
  function cached_1(_this_, cacheSize) {
    return new DefaultCachedFormatter(_this_, cacheSize);
  }
  function cached$default_1(_this_, cacheSize, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      cacheSize = 500;
    return cached_1(_this_, cacheSize);
  }
  function DefaultCachedFormatter(formatter, cacheSize) {
    this._formatter_0 = formatter;
    this._cacheSize_1 = cacheSize;
    var tmp = this._formatter_0;
    var tmp0_require_0 = !isInterface(tmp, CachedFormatter);
    if (!tmp0_require_0) {
      var message_1 = 'cannot cache an already cached number format';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }this._formatCache_0 = cache('DefaultCachedFormatter', this._cacheSize_1);
  }
  DefaultCachedFormatter.prototype.format_6 = function (value, locale) {
    var key = imul(31, getNumberHashCode(value)) + locale.hashCode() | 0;
    var tmp0_getOrStore_0 = this._formatCache_0;
    var tmp0_getOrPut_0_1 = tmp0_getOrStore_0._map_1;
    var value_1_2 = tmp0_getOrPut_0_1.get_20(key);
    var tmp;
    if (value_1_2 == null) {
      var tmp0_this_4_4 = tmp0_getOrStore_0;
      var tmp1_5_5 = tmp0_this_4_4._cacheMissCounter;
      tmp0_this_4_4._cacheMissCounter = tmp1_5_5 + 1 | 0;
      Unit_getInstance();
      var answer_2_3 = this._formatter_0.format_6(value, locale);
      tmp0_getOrPut_0_1.put_6(key, answer_2_3);
      Unit_getInstance();
      tmp = answer_2_3;
    } else {
      tmp = value_1_2;
    }
    return tmp;
  };
  DefaultCachedFormatter.$metadata$ = {
    simpleName: 'DefaultCachedFormatter',
    kind: 'class',
    interfaces: [CachedFormatter]
  };
  var dataFormatIso8601;
  var dateFormatUTC;
  var timeFormat;
  var timeFormatWithMillis;
  var dateFormat;
  var dateTimeFormat;
  var dateTimeFormatShort;
  var dateTimeFormatWithMillis;
  var dateTimeFormatShortWithMillis;
  function DateTimeFormat() {
  }
  DateTimeFormat.$metadata$ = {
    simpleName: 'DateTimeFormat',
    kind: 'interface',
    interfaces: []
  };
  function dataFormatIso8601$init$() {
    var tmp = new DataFormatIso8601();
    return cached$default_0(tmp, 0, 1, null);
  }
  function dateFormatUTC$init$() {
    var tmp = new DateFormatUTC();
    return cached$default_0(tmp, 0, 1, null);
  }
  function timeFormat$init$() {
    var tmp = new TimeFormat();
    return cached$default_0(tmp, 0, 1, null);
  }
  function timeFormatWithMillis$init$() {
    var tmp = new TimeFormatWithMillis();
    return cached$default_0(tmp, 0, 1, null);
  }
  function dateFormat$init$() {
    var tmp = new DateFormat_0();
    return cached$default_0(tmp, 0, 1, null);
  }
  function dateTimeFormat$init$() {
    var tmp = new DefaultDateTimeFormat();
    return cached$default_0(tmp, 0, 1, null);
  }
  function dateTimeFormatShort$init$() {
    var tmp = new DateTimeFormatShort();
    return cached$default_0(tmp, 0, 1, null);
  }
  function dateTimeFormatWithMillis$init$() {
    var tmp = new DateTimeFormatWithMillis();
    return cached$default_0(tmp, 0, 1, null);
  }
  function dateTimeFormatShortWithMillis$init$() {
    var tmp = new DateTimeFormatShortWithMillis();
    return cached$default_0(tmp, 0, 1, null);
  }
  function calculateHashCode($this, maximumFractionDigits, minimumFractionDigits, minimumIntegerDigits, useGrouping) {
    var result = maximumFractionDigits;
    result = imul(31, result) + minimumFractionDigits | 0;
    result = imul(31, result) + minimumIntegerDigits | 0;
    result = imul(31, result) + (useGrouping | 0) | 0;
    return result;
  }
  function DecimalFormatsCache() {
    DecimalFormatsCache_instance = this;
    this._cache_1 = cache('DecimalFormatsCache', 50);
  }
  DecimalFormatsCache.prototype.get_44 = function (numberOfDecimals) {
    return this.get$default(numberOfDecimals, numberOfDecimals, 0, false, 12, null);
  };
  DecimalFormatsCache.prototype.get_23 = function (numberOfDecimals, useGrouping) {
    return this.get_24(numberOfDecimals, numberOfDecimals, 1, useGrouping);
  };
  DecimalFormatsCache.prototype.get_24 = function (maximumFractionDigits, minimumFractionDigits, minimumIntegerDigits, useGrouping) {
    var hashCode_1 = calculateHashCode(this, maximumFractionDigits, minimumFractionDigits, minimumIntegerDigits, useGrouping);
    var tmp0_getOrStore_0 = this._cache_1;
    var tmp0_getOrPut_0_1 = tmp0_getOrStore_0._map_1;
    var value_1_2 = tmp0_getOrPut_0_1.get_20(hashCode_1);
    var tmp;
    if (value_1_2 == null) {
      var tmp0_this_4_4 = tmp0_getOrStore_0;
      var tmp1_5_5 = tmp0_this_4_4._cacheMissCounter;
      tmp0_this_4_4._cacheMissCounter = tmp1_5_5 + 1 | 0;
      Unit_getInstance();
      var tmp_0 = new DecimalFormat(maximumFractionDigits, minimumFractionDigits, minimumIntegerDigits, useGrouping);
      var answer_2_3 = cached$default_1(tmp_0, 0, 1, null);
      tmp0_getOrPut_0_1.put_6(hashCode_1, answer_2_3);
      Unit_getInstance();
      tmp = answer_2_3;
    } else {
      tmp = value_1_2;
    }
    return tmp;
  };
  DecimalFormatsCache.prototype.get$default = function (maximumFractionDigits, minimumFractionDigits, minimumIntegerDigits, useGrouping, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      maximumFractionDigits = 2;
    if (!(($mask0 & 2) === 0))
      minimumFractionDigits = 0;
    if (!(($mask0 & 4) === 0))
      minimumIntegerDigits = 1;
    if (!(($mask0 & 8) === 0))
      useGrouping = true;
    return this.get_24(maximumFractionDigits, minimumFractionDigits, minimumIntegerDigits, useGrouping);
  };
  DecimalFormatsCache.$metadata$ = {
    simpleName: 'DecimalFormatsCache',
    kind: 'object',
    interfaces: []
  };
  var DecimalFormatsCache_instance;
  function DecimalFormatsCache_getInstance() {
    if (DecimalFormatsCache_instance == null)
      new DecimalFormatsCache();
    return DecimalFormatsCache_instance;
  }
  var decimalFormat;
  var intFormat;
  var decimalFormat1digit;
  var decimalFormat2digits;
  var percentageFormat;
  var percentageFormat2digits;
  var percentageFormat0digits;
  function decimalFormat_0(maximumFractionDigits, minimumFractionDigits, minimumIntegerDigits, useGrouping) {
    return DecimalFormatsCache_getInstance().get_24(maximumFractionDigits, minimumFractionDigits, minimumIntegerDigits, useGrouping);
  }
  function NumberFormat() {
  }
  NumberFormat.prototype.format$default_0 = function (value, locale, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      locale = DefaultLocale;
    return $handler == null ? this.format_6(value, locale) : $handler(value, locale);
  };
  NumberFormat.$metadata$ = {
    simpleName: 'NumberFormat',
    kind: 'interface',
    interfaces: []
  };
  function DecimalFormatDescriptor() {
  }
  DecimalFormatDescriptor.$metadata$ = {
    simpleName: 'DecimalFormatDescriptor',
    kind: 'interface',
    interfaces: []
  };
  function decimalFormat$init$() {
    var tmp = DecimalFormatsCache_getInstance();
    return tmp.get$default(0, 0, 0, false, 15, null);
  }
  function decimalFormat1digit$init$() {
    var tmp = DecimalFormatsCache_getInstance();
    return tmp.get$default(1, 1, 0, false, 12, null);
  }
  function decimalFormat2digits$init$() {
    var tmp = DecimalFormatsCache_getInstance();
    return tmp.get$default(2, 2, 0, false, 12, null);
  }
  function percentageFormat$init$() {
    var tmp = new PercentageFormatter(decimalFormat2digits);
    return cached$default_1(tmp, 0, 1, null);
  }
  function percentageFormat2digits$init$() {
    var tmp = new PercentageFormatter(decimalFormat2digits);
    return cached$default_1(tmp, 0, 1, null);
  }
  function percentageFormat0digits$init$() {
    var tmp = new PercentageFormatter(intFormat);
    return cached$default_1(tmp, 0, 1, null);
  }
  var decimalFormatFromPatternCache;
  function PercentageFormatter(delegate_0) {
    this._delegate_3 = delegate_0;
  }
  PercentageFormatter.prototype.format_6 = function (value, locale) {
    return this._delegate_3.format_6(value * 100.0, locale) + ' %';
  };
  PercentageFormatter.$metadata$ = {
    simpleName: 'PercentageFormatter',
    kind: 'class',
    interfaces: [NumberFormat]
  };
  var DefaultLocale;
  function Locales(format, text_0) {
    this._format_0 = format;
    this._text = text_0;
  }
  Locales.prototype.toString = function () {
    return '' + 'Locales(format=' + this._format_0 + ', text=' + this._text + ')';
  };
  Locales.prototype.hashCode = function () {
    var result = this._format_0.hashCode();
    result = imul(result, 31) + this._text.hashCode() | 0;
    return result;
  };
  Locales.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Locales))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Locales ? other : THROW_CCE();
    if (!this._format_0.equals(tmp0_other_with_cast._format_0))
      return false;
    if (!this._text.equals(tmp0_other_with_cast._text))
      return false;
    return true;
  };
  Locales.$metadata$ = {
    simpleName: 'Locales',
    kind: 'class',
    interfaces: []
  };
  function I18nSupport() {
    this._locales = new Locales(DefaultLocale, DefaultLocale);
    this._timeZone = DefaultTimeZone;
  }
  I18nSupport.prototype._get_textLocale_ = function () {
    return this._locales._text;
  };
  I18nSupport.$metadata$ = {
    simpleName: 'I18nSupport',
    kind: 'class',
    interfaces: []
  };
  function Companion_41() {
    Companion_instance_40 = this;
    this._US = new Locale('en-US');
    this._Germany = new Locale('de-DE');
  }
  Companion_41.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_40;
  function Companion_getInstance_40() {
    if (Companion_instance_40 == null)
      new Companion_41();
    return Companion_instance_40;
  }
  function Locale(locale) {
    Companion_getInstance_40();
    this._locale_0 = locale;
  }
  Locale.prototype.toString = function () {
    return '' + this._locale_0;
  };
  Locale.prototype.hashCode = function () {
    return getStringHashCode(this._locale_0);
  };
  Locale.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Locale))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Locale ? other : THROW_CCE();
    if (!(this._locale_0 === tmp0_other_with_cast._locale_0))
      return false;
    return true;
  };
  Locale.$metadata$ = {
    simpleName: 'Locale',
    kind: 'class',
    interfaces: []
  };
  function addFallbackTextResolver(_this_) {
    _this_.addTextResolver(FallbackTextResolver_getInstance());
  }
  function TextResolver() {
  }
  TextResolver.$metadata$ = {
    simpleName: 'TextResolver',
    kind: 'interface',
    interfaces: []
  };
  function FallbackTextResolver() {
    FallbackTextResolver_instance = this;
  }
  FallbackTextResolver.$metadata$ = {
    simpleName: 'FallbackTextResolver',
    kind: 'object',
    interfaces: [TextResolver]
  };
  var FallbackTextResolver_instance;
  function FallbackTextResolver_getInstance() {
    if (FallbackTextResolver_instance == null)
      new FallbackTextResolver();
    return FallbackTextResolver_instance;
  }
  function TextService() {
    var tmp = this;
    tmp._textResolvers = ArrayList_init_$Create$();
  }
  TextService.prototype.addTextResolver = function (resolver) {
    this._textResolvers.add_57(resolver);
    Unit_getInstance();
  };
  TextService.$metadata$ = {
    simpleName: 'TextService',
    kind: 'class',
    interfaces: [TextResolver]
  };
  function KLogger() {
  }
  KLogger.$metadata$ = {
    simpleName: 'KLogger',
    kind: 'interface',
    interfaces: []
  };
  function KotlinLogging() {
    KotlinLogging_instance = this;
  }
  KotlinLogging.prototype.logger = function (func) {
    return NullLogger_getInstance();
  };
  KotlinLogging.$metadata$ = {
    simpleName: 'KotlinLogging',
    kind: 'object',
    interfaces: []
  };
  var KotlinLogging_instance;
  function KotlinLogging_getInstance() {
    if (KotlinLogging_instance == null)
      new KotlinLogging();
    return KotlinLogging_instance;
  }
  function NullLogger() {
    NullLogger_instance = this;
  }
  NullLogger.prototype.debug_1 = function (msg) {
  };
  NullLogger.prototype.warn_1 = function (msg) {
  };
  NullLogger.$metadata$ = {
    simpleName: 'NullLogger',
    kind: 'object',
    interfaces: [KLogger]
  };
  var NullLogger_instance;
  function NullLogger_getInstance() {
    if (NullLogger_instance == null)
      new NullLogger();
    return NullLogger_instance;
  }
  function DependentObjectSupport() {
  }
  DependentObjectSupport.$metadata$ = {
    simpleName: 'DependentObjectSupport',
    kind: 'interface',
    interfaces: []
  };
  function DependentObjects() {
    var tmp = this;
    tmp._dependentObjects = HashMap_init_$Create$();
  }
  DependentObjects.$metadata$ = {
    simpleName: 'DependentObjects',
    kind: 'class',
    interfaces: [DependentObjectSupport]
  };
  function ObservableBoolean(initValue) {
    ObservableObject.call(this, initValue);
  }
  ObservableBoolean.$metadata$ = {
    simpleName: 'ObservableBoolean',
    kind: 'class',
    interfaces: [ReadOnlyObservableBoolean]
  };
  function ReadOnlyObservableBoolean() {
  }
  ReadOnlyObservableBoolean.$metadata$ = {
    simpleName: 'ReadOnlyObservableBoolean',
    kind: 'interface',
    interfaces: [ReadOnlyObservableObject]
  };
  function ReadOnlyObservableDouble() {
  }
  ReadOnlyObservableDouble.$metadata$ = {
    simpleName: 'ReadOnlyObservableDouble',
    kind: 'interface',
    interfaces: [ReadOnlyObservableObject]
  };
  function ObservableDouble(initValue) {
    ObservableObject.call(this, initValue);
  }
  ObservableDouble.$metadata$ = {
    simpleName: 'ObservableDouble',
    kind: 'class',
    interfaces: [ReadOnlyObservableDouble]
  };
  function notifyListeners($this, oldValue, newValue) {
    if (equals_0(oldValue, newValue)) {
      return Unit_getInstance();
    }var tmp0_fastForEach_0 = $this._valueChangeListeners;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4(oldValue, newValue);
    }
  }
  function sam$com_cedarsoft_dispose_Disposable$0(function_0) {
    this._function = function_0;
  }
  sam$com_cedarsoft_dispose_Disposable$0.prototype.dispose_46 = function () {
    return this._function();
  };
  sam$com_cedarsoft_dispose_Disposable$0.$metadata$ = {
    simpleName: 'sam$com_cedarsoft_dispose_Disposable$0',
    kind: 'class',
    interfaces: [Disposable]
  };
  function _no_name_provided__265(this$0, $action) {
    this._this$0_202 = this$0;
    this._$action = $action;
  }
  _no_name_provided__265.prototype.invoke_1037 = function () {
    this._this$0_202._valueChangeListeners.remove_59(this._$action);
    Unit_getInstance();
  };
  _no_name_provided__265.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__265.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__266($action) {
    this._$action_0 = $action;
  }
  _no_name_provided__266.prototype.invoke_506 = function (_anonymous_parameter_0_, newValue) {
    this._$action_0(newValue);
  };
  _no_name_provided__266.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE();
    this.invoke_506(tmp, (p2 == null ? true : isObject(p2)) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__266.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__267($intermediateObservable, $function) {
    this._$intermediateObservable = $intermediateObservable;
    this._$function = $function;
  }
  _no_name_provided__267.prototype.invoke_822 = function (newValue) {
    this._$intermediateObservable._set_value__12(this._$function(newValue));
  };
  _no_name_provided__267.prototype.invoke_1082 = function (p1) {
    this.invoke_822((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__267.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ObservableObject(initValue) {
    this._value_3 = initValue;
    var tmp = this;
    tmp._valueChangeListeners = ArrayList_init_$Create$();
    this._dependentObjects_0 = new DependentObjects();
  }
  ObservableObject.prototype._set_value__12 = function (value) {
    var oldValue = this._value_3;
    this._value_3 = value;
    notifyListeners(this, oldValue, value);
  };
  ObservableObject.prototype._get_value__18 = function () {
    return this._value_3;
  };
  ObservableObject.prototype.consumeChanges_1 = function (action) {
    this._valueChangeListeners.add_57(action);
    Unit_getInstance();
    var tmp = _no_name_provided_$factory_256(this, action);
    return new sam$com_cedarsoft_dispose_Disposable$0(tmp);
  };
  ObservableObject.prototype.consume_3 = function (immediately, action) {
    var tmp0_also_0 = this.consumeChanges_1(_no_name_provided_$factory_257(action));
    if (immediately) {
      action(this._get_value__18());
    }return tmp0_also_0;
  };
  ObservableObject.prototype.setValue_10 = function (thisRef, property, value) {
    this._set_value__12(value);
  };
  ObservableObject.prototype.get_28 = function () {
    return this._get_value__18();
  };
  ObservableObject.prototype.map_4 = function (function_0) {
    var intermediateObservable = new ObservableObject(function_0(this._get_value__18()));
    this.consume$default_3(false, _no_name_provided_$factory_258(intermediateObservable, function_0), 1, null);
    Unit_getInstance();
    return intermediateObservable;
  };
  ObservableObject.prototype.toString = function () {
    return '' + 'ObservableObject [value: ' + this._get_value__18() + ']';
  };
  ObservableObject.$metadata$ = {
    simpleName: 'ObservableObject',
    kind: 'class',
    interfaces: [ReadOnlyObservableObject]
  };
  function _no_name_provided_$factory_256(this$0, $action) {
    var i = new _no_name_provided__265(this$0, $action);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_257($action) {
    var i = new _no_name_provided__266($action);
    return function (p1, p2) {
      i.invoke_506(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_258($intermediateObservable, $function) {
    var i = new _no_name_provided__267($intermediateObservable, $function);
    return function (p1) {
      i.invoke_822(p1);
      return Unit_getInstance();
    };
  }
  function ObservableString(initValue) {
    ObservableObject.call(this, initValue);
  }
  ObservableString.$metadata$ = {
    simpleName: 'ObservableString',
    kind: 'class',
    interfaces: [ReadOnlyObservableString]
  };
  function ReadOnlyObservableString() {
  }
  ReadOnlyObservableString.$metadata$ = {
    simpleName: 'ReadOnlyObservableString',
    kind: 'interface',
    interfaces: [ReadOnlyObservableObject]
  };
  function ReadOnlyObservableObject() {
  }
  ReadOnlyObservableObject.prototype.consume$default_3 = function (immediately, action, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      immediately = false;
    return $handler == null ? this.consume_3(immediately, action) : $handler(immediately, action);
  };
  ReadOnlyObservableObject.prototype.consumeImmediately_3 = function (action) {
    return this.consume_3(true, action);
  };
  ReadOnlyObservableObject.prototype.getValue_10 = function (thisRef, property) {
    return this._get_value__18();
  };
  ReadOnlyObservableObject.$metadata$ = {
    simpleName: 'ReadOnlyObservableObject',
    kind: 'interface',
    interfaces: [DependentObjectSupport]
  };
  function nowMillis() {
    return currentTimeMillis();
  }
  var DefaultTimeZone;
  function _TimeZone___init__impl_(zoneId) {
    return zoneId;
  }
  function Companion_42() {
    Companion_instance_41 = this;
    this._UTC = _TimeZone___init__impl_('UTC');
    this._Berlin = _TimeZone___init__impl_('Europe/Berlin');
    this._Tokyo = _TimeZone___init__impl_('Asia/Tokyo');
  }
  Companion_42.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_41;
  function Companion_getInstance_41() {
    if (Companion_instance_41 == null)
      new Companion_42();
    return Companion_instance_41;
  }
  function DefaultTimeZoneOffsetProvider() {
  }
  DefaultTimeZoneOffsetProvider.$metadata$ = {
    simpleName: 'DefaultTimeZoneOffsetProvider',
    kind: 'class',
    interfaces: [TimeZoneOffsetProvider]
  };
  var localeOptionsCache;
  function DataFormatIso8601() {
  }
  DataFormatIso8601.$metadata$ = {
    simpleName: 'DataFormatIso8601',
    kind: 'class',
    interfaces: [DateTimeFormat]
  };
  function DateFormatUTC() {
  }
  DateFormatUTC.$metadata$ = {
    simpleName: 'DateFormatUTC',
    kind: 'class',
    interfaces: [DateTimeFormat]
  };
  function TimeFormat() {
  }
  TimeFormat.$metadata$ = {
    simpleName: 'TimeFormat',
    kind: 'class',
    interfaces: [DateTimeFormat]
  };
  function TimeFormatWithMillis() {
  }
  TimeFormatWithMillis.$metadata$ = {
    simpleName: 'TimeFormatWithMillis',
    kind: 'class',
    interfaces: [DateTimeFormat]
  };
  function DateFormat_0() {
  }
  DateFormat_0.$metadata$ = {
    simpleName: 'DateFormat',
    kind: 'class',
    interfaces: [DateTimeFormat]
  };
  function DefaultDateTimeFormat() {
  }
  DefaultDateTimeFormat.$metadata$ = {
    simpleName: 'DefaultDateTimeFormat',
    kind: 'class',
    interfaces: [DateTimeFormat]
  };
  function DateTimeFormatShort() {
  }
  DateTimeFormatShort.$metadata$ = {
    simpleName: 'DateTimeFormatShort',
    kind: 'class',
    interfaces: [DateTimeFormat]
  };
  function DateTimeFormatWithMillis() {
  }
  DateTimeFormatWithMillis.$metadata$ = {
    simpleName: 'DateTimeFormatWithMillis',
    kind: 'class',
    interfaces: [DateTimeFormat]
  };
  function DateTimeFormatShortWithMillis() {
  }
  DateTimeFormatShortWithMillis.$metadata$ = {
    simpleName: 'DateTimeFormatShortWithMillis',
    kind: 'class',
    interfaces: [DateTimeFormat]
  };
  function _no_name_provided__268(this$0) {
    this._this$0_203 = this$0;
    this._minimumIntegerDigits = this._this$0_203._minimumIntegerDigits_0;
    this._minimumFractionDigits = this._this$0_203._minimumFractionDigits_0;
    this._maximumFractionDigits = this._this$0_203._maximumFractionDigits_0;
    this._useGrouping = this._this$0_203._useGrouping_0;
  }
  _no_name_provided__268.prototype._get_minimumIntegerDigits_ = function () {
    return this._minimumIntegerDigits;
  };
  _no_name_provided__268.prototype._get_minimumFractionDigits_ = function () {
    return this._minimumFractionDigits;
  };
  _no_name_provided__268.prototype._get_maximumFractionDigits_ = function () {
    return this._maximumFractionDigits;
  };
  _no_name_provided__268.prototype._get_useGrouping_ = function () {
    return this._useGrouping;
  };
  _no_name_provided__268.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  Object.defineProperty(_no_name_provided__268.prototype, 'minimumIntegerDigits', {
    configurable: true,
    get: _no_name_provided__268.prototype._get_minimumIntegerDigits_
  });
  Object.defineProperty(_no_name_provided__268.prototype, 'minimumFractionDigits', {
    configurable: true,
    get: _no_name_provided__268.prototype._get_minimumFractionDigits_
  });
  Object.defineProperty(_no_name_provided__268.prototype, 'maximumFractionDigits', {
    configurable: true,
    get: _no_name_provided__268.prototype._get_maximumFractionDigits_
  });
  Object.defineProperty(_no_name_provided__268.prototype, 'useGrouping', {
    configurable: true,
    get: _no_name_provided__268.prototype._get_useGrouping_
  });
  function DecimalFormat(maximumFractionDigits, minimumFractionDigits, minimumIntegerDigits, useGrouping) {
    this._maximumFractionDigits_0 = maximumFractionDigits;
    this._minimumFractionDigits_0 = minimumFractionDigits;
    this._minimumIntegerDigits_0 = minimumIntegerDigits;
    this._useGrouping_0 = useGrouping;
    var tmp = this;
    tmp._formatOptions = new _no_name_provided__268(this);
    var tmp_0 = this;
    var tmp0_pow_0 = 10.0;
    var tmp1_pow_0 = -this._maximumFractionDigits_0 | 0;
    tmp_0._precision = Math.pow(tmp0_pow_0, tmp1_pow_0);
  }
  DecimalFormat.prototype.format_6 = function (value, locale) {
    var tmp0_asDynamic_0 = value + 0.0;
    var tmp = tmp0_asDynamic_0.toLocaleString(locale._locale_0, this._formatOptions);
    return (!(tmp == null) ? typeof tmp === 'string' : false) ? tmp : THROW_CCE();
  };
  DecimalFormat.$metadata$ = {
    simpleName: 'DecimalFormat',
    kind: 'class',
    interfaces: [NumberFormat, DecimalFormatDescriptor]
  };
  function _no_name_provided__269() {
  }
  _no_name_provided__269.prototype.invoke_1037 = function () {
    return Unit_getInstance();
  };
  _no_name_provided__269.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__269.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Companion_43() {
    Companion_instance_42 = this;
    var tmp = this;
    var tmp_0 = KotlinLogging_getInstance();
    tmp._logger = tmp_0.logger(_no_name_provided_$factory_260());
  }
  Companion_43.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_42;
  function Companion_getInstance_42() {
    if (Companion_instance_42 == null)
      new Companion_43();
    return Companion_instance_42;
  }
  function _no_name_provided__270($navigator) {
    this._$navigator = $navigator;
  }
  _no_name_provided__270.prototype.invoke_1038 = function () {
    return '' + 'navigator language: ' + this._$navigator.language;
  };
  _no_name_provided__270.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function DefaultLocaleProvider() {
    Companion_getInstance_42();
  }
  DefaultLocaleProvider.prototype._get_defaultLocale_ = function () {
    return this.getBrowserLocale();
  };
  DefaultLocaleProvider.prototype.getBrowserLocale = function () {
    var navigator = window.navigator;
    var tmp = Companion_getInstance_42()._logger;
    tmp.debug_1(_no_name_provided_$factory_259(navigator));
    if (navigator.language === 'C') {
      return Companion_getInstance_40()._US;
    }var locale = new Locale(navigator.language);
    var tmp_0;
    try {
      decimalFormat.format_6(0.0, locale);
      Unit_getInstance();
      tmp_0 = locale;
    } catch ($p) {
      var tmp_1;
      if ($p instanceof Error) {
        println('' + 'Locale <' + locale._locale_0 + '> not supported. Falling back to ' + Companion_getInstance_40()._US);
        tmp_1 = Companion_getInstance_40()._US;
      } else {
        {
          throw $p;
        }
      }
      tmp_0 = tmp_1;
    }
    return tmp_0;
  };
  DefaultLocaleProvider.$metadata$ = {
    simpleName: 'DefaultLocaleProvider',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_259($navigator) {
    var i = new _no_name_provided__270($navigator);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_260() {
    var i = new _no_name_provided__269();
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function currentTimeMillis() {
    return Date.now();
  }
  function _no_name_provided__271() {
  }
  _no_name_provided__271.prototype.invoke_1037 = function () {
    return Unit_getInstance();
  };
  _no_name_provided__271.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__271.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Companion_44() {
    Companion_instance_43 = this;
    var tmp = this;
    var tmp_0 = KotlinLogging_getInstance();
    tmp._logger_0 = tmp_0.logger(_no_name_provided_$factory_263());
  }
  Companion_44.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_43;
  function Companion_getInstance_43() {
    if (Companion_instance_43 == null)
      new Companion_44();
    return Companion_instance_43;
  }
  function _no_name_provided__272($e) {
    this._$e = $e;
  }
  _no_name_provided__272.prototype.invoke_1038 = function () {
    return '' + 'Could not get browser time zone due to : ' + this._$e.message;
  };
  _no_name_provided__272.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__273($timeZoneString) {
    this._$timeZoneString = $timeZoneString;
  }
  _no_name_provided__273.prototype.invoke_1038 = function () {
    return '' + 'time zone ' + this._$timeZoneString;
  };
  _no_name_provided__273.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function DefaultTimeZoneProvider() {
    Companion_getInstance_43();
  }
  DefaultTimeZoneProvider.prototype._get_defaultTimeZone_ = function () {
    var tmp;
    try {
      tmp = this.getBrowserTimeZone();
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Exception) {
        var tmp_1 = Companion_getInstance_43()._logger_0;
        tmp_1.warn_1(_no_name_provided_$factory_261($p));
        tmp_0 = Companion_getInstance_41()._UTC;
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  DefaultTimeZoneProvider.prototype.getBrowserTimeZone = function () {
    var timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var tmp = Companion_getInstance_43()._logger_0;
    tmp.debug_1(_no_name_provided_$factory_262(timeZoneString));
    return _TimeZone___init__impl_(timeZoneString);
  };
  DefaultTimeZoneProvider.$metadata$ = {
    simpleName: 'DefaultTimeZoneProvider',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_261($e) {
    var i = new _no_name_provided__272($e);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_262($timeZoneString) {
    var i = new _no_name_provided__273($timeZoneString);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_263() {
    var i = new _no_name_provided__271();
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  var environment;
  function Environment(multiTouchSupported, devicePixelRatio) {
    this._multiTouchSupported = multiTouchSupported;
    this._devicePixelRatio = devicePixelRatio;
  }
  Environment.prototype.toString = function () {
    return '' + 'Environment(multiTouchSupported=' + this._multiTouchSupported + ', devicePixelRatio=' + this._devicePixelRatio + ')';
  };
  Environment.prototype.hashCode = function () {
    var result = this._multiTouchSupported | 0;
    result = imul(result, 31) + getNumberHashCode(this._devicePixelRatio) | 0;
    return result;
  };
  Environment.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Environment))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Environment ? other : THROW_CCE();
    if (!(this._multiTouchSupported === tmp0_other_with_cast._multiTouchSupported))
      return false;
    if (!equals_0(this._devicePixelRatio, tmp0_other_with_cast._devicePixelRatio))
      return false;
    return true;
  };
  Environment.$metadata$ = {
    simpleName: 'Environment',
    kind: 'class',
    interfaces: []
  };
  function updateEnvironment() {
    environment = extractEnvironment(environment);
  }
  function ResizeBehavior() {
  }
  ResizeBehavior.$metadata$ = {
    simpleName: 'ResizeBehavior',
    kind: 'interface',
    interfaces: []
  };
  function KeepCenterOnResize() {
    KeepCenterOnResize_instance = this;
    KeepLocation.call(this, 0.5, 0.5);
  }
  KeepCenterOnResize.$metadata$ = {
    simpleName: 'KeepCenterOnResize',
    kind: 'object',
    interfaces: []
  };
  var KeepCenterOnResize_instance;
  function KeepCenterOnResize_getInstance() {
    if (KeepCenterOnResize_instance == null)
      new KeepCenterOnResize();
    return KeepCenterOnResize_instance;
  }
  function ResizeEvent(oldWindowSize, newWindowSize, oldContentAreaSize, newContentAreaSize) {
    this._oldWindowSize = oldWindowSize;
    this._newWindowSize = newWindowSize;
    this._oldContentAreaSize = oldContentAreaSize;
    this._newContentAreaSize = newContentAreaSize;
  }
  ResizeEvent.prototype.toString = function () {
    return '' + 'ResizeEvent(oldWindowSize=' + this._oldWindowSize + ', newWindowSize=' + this._newWindowSize + ', oldContentAreaSize=' + this._oldContentAreaSize + ', newContentAreaSize=' + this._newContentAreaSize + ')';
  };
  ResizeEvent.prototype.hashCode = function () {
    var result = this._oldWindowSize.hashCode();
    result = imul(result, 31) + this._newWindowSize.hashCode() | 0;
    result = imul(result, 31) + this._oldContentAreaSize.hashCode() | 0;
    result = imul(result, 31) + this._newContentAreaSize.hashCode() | 0;
    return result;
  };
  ResizeEvent.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ResizeEvent))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof ResizeEvent ? other : THROW_CCE();
    if (!this._oldWindowSize.equals(tmp0_other_with_cast._oldWindowSize))
      return false;
    if (!this._newWindowSize.equals(tmp0_other_with_cast._newWindowSize))
      return false;
    if (!this._oldContentAreaSize.equals(tmp0_other_with_cast._oldContentAreaSize))
      return false;
    if (!this._newContentAreaSize.equals(tmp0_other_with_cast._newContentAreaSize))
      return false;
    return true;
  };
  ResizeEvent.$metadata$ = {
    simpleName: 'ResizeEvent',
    kind: 'class',
    interfaces: []
  };
  function keepLocationOnResize($this, zoomAndTranslationSupport, resizeEvent) {
    if (resizeEvent._newWindowSize.atLeastOneZero()) {
      return Unit_getInstance();
    }if (resizeEvent._oldWindowSize.atLeastOneZero()) {
      zoomAndTranslationSupport.resetToDefaults$default(null, 1, null);
      return Unit_getInstance();
    }var chartCalculator = zoomAndTranslationSupport._chartCalculator;
    var oldDomainRelativeValuesAtCenter = chartCalculator.withContentAreaSize(resizeEvent._oldContentAreaSize).window2domainRelative(resizeEvent._oldWindowSize._width_0 * $this._windowRelativeX, resizeEvent._oldWindowSize._height * $this._windowRelativeY);
    var newLocationOfOldCenterValue = chartCalculator.domainRelative2window(oldDomainRelativeValuesAtCenter);
    var deltaX = resizeEvent._newWindowSize._width_0 * $this._windowRelativeX - newLocationOfOldCenterValue._x;
    var deltaY = resizeEvent._newWindowSize._height * $this._windowRelativeY - newLocationOfOldCenterValue._y;
    zoomAndTranslationSupport.moveWindow(deltaX, deltaY);
  }
  function KeepLocation(windowRelativeX, windowRelativeY) {
    this._windowRelativeX = windowRelativeX;
    this._windowRelativeY = windowRelativeY;
  }
  KeepLocation.prototype.handleResize_1 = function (zoomAndTranslationSupport, resizeEvent) {
    keepLocationOnResize(this, zoomAndTranslationSupport, resizeEvent);
  };
  KeepLocation.$metadata$ = {
    simpleName: 'KeepLocation',
    kind: 'class',
    interfaces: [ResizeBehavior]
  };
  function _no_name_provided__274() {
  }
  _no_name_provided__274.prototype.modifyTranslation_0 = function (translation, calculator) {
    return translation;
  };
  _no_name_provided__274.prototype.modifyZoom_0 = function (zoom, calculator) {
    return zoom;
  };
  _no_name_provided__274.$metadata$ = {
    kind: 'class',
    interfaces: [ZoomAndTranslationModifier]
  };
  function Companion_45() {
    Companion_instance_44 = this;
    var tmp = this;
    tmp._none_2 = new _no_name_provided__274();
  }
  Companion_45.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_44;
  function Companion_getInstance_44() {
    if (Companion_instance_44 == null)
      new Companion_45();
    return Companion_instance_44;
  }
  function ZoomAndTranslationModifier() {
  }
  ZoomAndTranslationModifier.$metadata$ = {
    simpleName: 'ZoomAndTranslationModifier',
    kind: 'interface',
    interfaces: []
  };
  function ZoomAndTranslationSupport(chartCalculator, chartState, zoomAndPanModifier, zoomAndTranslationDefaults, zoomChangeFactor) {
    this._chartCalculator = chartCalculator;
    this._chartState_0 = chartState;
    this._zoomAndPanModifier = zoomAndPanModifier;
    this._zoomAndTranslationDefaults = zoomAndTranslationDefaults;
    this._zoomChangeFactor = zoomChangeFactor;
    var tmp0_require_0 = this._chartCalculator._chartState === this._chartState_0;
    if (!tmp0_require_0) {
      var message_1 = 'Chart state of chart calculator must be the same as the chart state';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }this.resetToDefaults$default(null, 1, null);
  }
  ZoomAndTranslationSupport.prototype.translateWindow = function (axisSelection, deltaX, deltaY) {
    var relevantDeltaX = 0.0;
    if (axisSelection._containsX) {
      relevantDeltaX = deltaX;
    }var relevantDeltaY = 0.0;
    if (axisSelection._containsY) {
      relevantDeltaY = deltaY;
    }this.moveWindow(relevantDeltaX, relevantDeltaY);
  };
  ZoomAndTranslationSupport.prototype.moveWindowRelative = function (deltaX, deltaY) {
    var deltaXZoomed = or0ifNaN(this._chartCalculator.contentAreaRelative2zoomedX(deltaX));
    var deltaYZoomed = or0ifNaN(this._chartCalculator.contentAreaRelative2zoomedY(deltaY));
    this.moveWindow(deltaXZoomed, deltaYZoomed);
  };
  ZoomAndTranslationSupport.prototype.moveWindow = function (deltaX, deltaY) {
    this.setWindowTranslation(this._chartState_0._get_windowTranslation__4().plus_1(deltaX, deltaY));
  };
  ZoomAndTranslationSupport.prototype.setWindowTranslation = function (windowTranslation) {
    this._chartState_0._set_windowTranslation__1(this._zoomAndPanModifier.modifyTranslation_0(windowTranslation, this._chartCalculator).avoidNaN());
  };
  ZoomAndTranslationSupport.prototype.setZoom = function (zoom, zoomCenter) {
    this.setZoom_0(zoom._scaleX, zoom._scaleY, zoomCenter);
  };
  ZoomAndTranslationSupport.prototype.setZoom_0 = function (newZoomFactorX, newZoomFactorY, zoomCenter) {
    if (zoomCenter == null) {
      this._chartState_0._set_zoom__1(Companion_getInstance_38().of_4(newZoomFactorX, newZoomFactorY));
      return Unit_getInstance();
    }var tmp0_container = this._chartCalculator.window2contentAreaRelative(zoomCenter._x, zoomCenter._y);
    var x = tmp0_container.component1_0();
    var y = tmp0_container.component2_0();
    this._chartState_0._set_zoom__1(this._zoomAndPanModifier.modifyZoom_0(new Zoom(newZoomFactorX, newZoomFactorY), this._chartCalculator));
    var tmp1_container = this._chartCalculator.window2contentAreaRelative(zoomCenter._x, zoomCenter._y);
    var x1 = tmp1_container.component1_0();
    var y1 = tmp1_container.component2_0();
    this.moveWindowRelative(x1 - x, y1 - y);
  };
  ZoomAndTranslationSupport.prototype.modifyZoom_1 = function (zoomIn, axisSelection, zoomCenter, zoomChangeFactor) {
    var oldZoomFactors = this._chartState_0._get_zoom__1();
    var newZoomFactorX = oldZoomFactors._scaleX;
    var newZoomFactorY = oldZoomFactors._scaleY;
    if (zoomIn) {
      if (axisSelection._containsX) {
        newZoomFactorX = newZoomFactorX * zoomChangeFactor;
      }if (axisSelection._containsY) {
        newZoomFactorY = newZoomFactorY * zoomChangeFactor;
      }} else {
      if (axisSelection._containsX) {
        newZoomFactorX = newZoomFactorX / zoomChangeFactor;
      }if (axisSelection._containsY) {
        newZoomFactorY = newZoomFactorY / zoomChangeFactor;
      }}
    this.setZoom_0(newZoomFactorX, newZoomFactorY, zoomCenter);
  };
  ZoomAndTranslationSupport.prototype.modifyZoom$default = function (zoomIn, axisSelection, zoomCenter, zoomChangeFactor, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      zoomCenter = this._chartState_0._get_windowCenter__2();
    if (!(($mask0 & 8) === 0))
      zoomChangeFactor = this._zoomChangeFactor;
    return this.modifyZoom_1(zoomIn, axisSelection, zoomCenter, zoomChangeFactor);
  };
  ZoomAndTranslationSupport.prototype.resetToDefaults = function (zoomAndTranslationDefaults) {
    this.resetZoom$default(zoomAndTranslationDefaults, null, 2, null);
    this.resetWindowTranslation(zoomAndTranslationDefaults);
  };
  ZoomAndTranslationSupport.prototype.resetToDefaults$default = function (zoomAndTranslationDefaults, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      zoomAndTranslationDefaults = this._zoomAndTranslationDefaults;
    return this.resetToDefaults(zoomAndTranslationDefaults);
  };
  ZoomAndTranslationSupport.prototype.resetZoom = function (zoomAndTranslationDefaults, zoomCenter) {
    this.setZoom(zoomAndTranslationDefaults.defaultZoom_3(this._chartCalculator), zoomCenter);
  };
  ZoomAndTranslationSupport.prototype.resetZoom$default = function (zoomAndTranslationDefaults, zoomCenter, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      zoomAndTranslationDefaults = this._zoomAndTranslationDefaults;
    if (!(($mask0 & 2) === 0))
      zoomCenter = null;
    return this.resetZoom(zoomAndTranslationDefaults, zoomCenter);
  };
  ZoomAndTranslationSupport.prototype.resetWindowTranslation = function (zoomAndTranslationDefaults) {
    this.setWindowTranslation(zoomAndTranslationDefaults.defaultTranslation_3(this._chartCalculator));
  };
  ZoomAndTranslationSupport.$metadata$ = {
    simpleName: 'ZoomAndTranslationSupport',
    kind: 'class',
    interfaces: []
  };
  function AbstractChartState() {
  }
  AbstractChartState.prototype._get_zoomX__3 = function () {
    return this._get_zoom__1()._scaleX;
  };
  AbstractChartState.prototype._get_zoomY__3 = function () {
    return this._get_zoom__1()._scaleY;
  };
  AbstractChartState.prototype._get_contentAreaWidth__3 = function () {
    return this._get_contentAreaSize__4()._width_0;
  };
  AbstractChartState.prototype._get_contentAreaHeight__3 = function () {
    return this._get_contentAreaSize__4()._height;
  };
  AbstractChartState.prototype._get_windowWidth__3 = function () {
    return this._get_windowSize__4()._width_0;
  };
  AbstractChartState.prototype._get_windowHeight__3 = function () {
    return this._get_windowSize__4()._height;
  };
  AbstractChartState.$metadata$ = {
    simpleName: 'AbstractChartState',
    kind: 'class',
    interfaces: [MutableChartState]
  };
  function notifyListeners_0($this) {
    var tmp0_fastForEach_0 = $this._changeListeners;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4($this);
    }
  }
  function _no_name_provided__275() {
  }
  _no_name_provided__275.prototype.invoke_779 = function (newSize) {
    var tmp0_require_0_3 = newSize.bothNotNegative();
    if (!tmp0_require_0_3) {
      var message_1_4 = '' + 'Invalid content area size: ' + newSize;
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_4));
    }};
  _no_name_provided__275.prototype.invoke_1082 = function (p1) {
    this.invoke_779(p1 instanceof Size ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__275.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__276() {
  }
  _no_name_provided__276.prototype.invoke_779 = function (newSize) {
    var tmp0_require_0_3 = newSize.bothNotNegative();
    if (!tmp0_require_0_3) {
      var message_1_4 = '' + 'Invalid window size: ' + newSize;
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_4));
    }};
  _no_name_provided__276.prototype.invoke_1082 = function (p1) {
    this.invoke_779(p1 instanceof Size ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__276.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__277(this$0) {
    this._this$0_204 = this$0;
  }
  _no_name_provided__277.prototype.invoke_521 = function (it) {
    notifyListeners_0(this._this$0_204);
  };
  _no_name_provided__277.prototype.invoke_1082 = function (p1) {
    this.invoke_521(p1 instanceof Zoom ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__277.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__278(this$0) {
    this._this$0_205 = this$0;
  }
  _no_name_provided__278.prototype.invoke_547 = function (it) {
    notifyListeners_0(this._this$0_205);
  };
  _no_name_provided__278.prototype.invoke_1082 = function (p1) {
    this.invoke_547(p1 instanceof Distance ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__278.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__279(this$0) {
    this._this$0_206 = this$0;
  }
  _no_name_provided__279.prototype.invoke_779 = function (it) {
    notifyListeners_0(this._this$0_206);
  };
  _no_name_provided__279.prototype.invoke_1082 = function (p1) {
    this.invoke_779(p1 instanceof Size ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__279.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__280(this$0) {
    this._this$0_207 = this$0;
  }
  _no_name_provided__280.prototype.invoke_779 = function (it) {
    notifyListeners_0(this._this$0_207);
  };
  _no_name_provided__280.prototype.invoke_1082 = function (p1) {
    this.invoke_779(p1 instanceof Size ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__280.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__281(this$0) {
    this._this$0_208 = this$0;
  }
  _no_name_provided__281.prototype.invoke_529 = function (it) {
    notifyListeners_0(this._this$0_208);
  };
  _no_name_provided__281.prototype.invoke_1082 = function (p1) {
    this.invoke_529(p1 instanceof AxisOrientationX ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__281.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__282(this$0) {
    this._this$0_209 = this$0;
  }
  _no_name_provided__282.prototype.invoke_531 = function (it) {
    notifyListeners_0(this._this$0_209);
  };
  _no_name_provided__282.prototype.invoke_1082 = function (p1) {
    this.invoke_531(p1 instanceof AxisOrientationY ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__282.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function DefaultChartState() {
    AbstractChartState.call(this);
    this._zoomProperty = new ObservableObject(Companion_getInstance_38()._default_0);
    this._zoom$delegate = this._zoomProperty;
    this._windowTranslationProperty = new ObservableObject(Companion_getInstance_34()._none_0);
    this._windowTranslation$delegate = this._windowTranslationProperty;
    var tmp = this;
    var tmp0_also_0 = new ObservableObject(Companion_getInstance_37()._zero_1);
    tmp0_also_0.consume$default_3(false, _no_name_provided_$factory_264(), 1, null);
    Unit_getInstance();
    tmp._contentAreaSizeProperty = tmp0_also_0;
    this._contentAreaSize$delegate = this._contentAreaSizeProperty;
    var tmp_0 = this;
    var tmp0_also_0_0 = new ObservableObject(Companion_getInstance_37()._zero_1);
    tmp0_also_0_0.consume$default_3(false, _no_name_provided_$factory_265(), 1, null);
    Unit_getInstance();
    tmp_0._windowSizeProperty = tmp0_also_0_0;
    this._windowSize$delegate = this._windowSizeProperty;
    this._axisOrientationXProperty = new ObservableObject(AxisOrientationX_OriginAtLeft_getInstance());
    this._axisOrientationX$delegate = this._axisOrientationXProperty;
    this._axisOrientationYProperty = new ObservableObject(AxisOrientationY_OriginAtBottom_getInstance());
    this._axisOrientationY$delegate = this._axisOrientationYProperty;
    this._zoomProperty.consume$default_3(false, _no_name_provided_$factory_266(this), 1, null);
    Unit_getInstance();
    this._windowTranslationProperty.consume$default_3(false, _no_name_provided_$factory_267(this), 1, null);
    Unit_getInstance();
    this._contentAreaSizeProperty.consume$default_3(false, _no_name_provided_$factory_268(this), 1, null);
    Unit_getInstance();
    this._windowSizeProperty.consume$default_3(false, _no_name_provided_$factory_269(this), 1, null);
    Unit_getInstance();
    this._axisOrientationXProperty.consume$default_3(false, _no_name_provided_$factory_270(this), 1, null);
    Unit_getInstance();
    this._axisOrientationYProperty.consume$default_3(false, _no_name_provided_$factory_271(this), 1, null);
    Unit_getInstance();
    var tmp_1 = this;
    tmp_1._changeListeners = ArrayList_init_$Create$();
  }
  DefaultChartState.prototype._set_zoom__1 = function (_set___) {
    return this._zoom$delegate.setValue_10(this, zoom$factory(), _set___);
  };
  DefaultChartState.prototype._get_zoom__1 = function () {
    return this._zoom$delegate.getValue_10(this, zoom$factory_0());
  };
  DefaultChartState.prototype._set_windowTranslation__1 = function (_set___) {
    return this._windowTranslation$delegate.setValue_10(this, windowTranslation$factory(), _set___);
  };
  DefaultChartState.prototype._get_windowTranslation__4 = function () {
    return this._windowTranslation$delegate.getValue_10(this, windowTranslation$factory_0());
  };
  DefaultChartState.prototype._set_contentAreaSize__1 = function (_set___) {
    return this._contentAreaSize$delegate.setValue_10(this, contentAreaSize$factory(), _set___);
  };
  DefaultChartState.prototype._get_contentAreaSize__4 = function () {
    return this._contentAreaSize$delegate.getValue_10(this, contentAreaSize$factory_0());
  };
  DefaultChartState.prototype._get_windowSizeProperty__1 = function () {
    return this._windowSizeProperty;
  };
  DefaultChartState.prototype._set_windowSize__1 = function (_set___) {
    return this._windowSize$delegate.setValue_10(this, windowSize$factory(), _set___);
  };
  DefaultChartState.prototype._get_windowSize__4 = function () {
    return this._windowSize$delegate.getValue_10(this, windowSize$factory_0());
  };
  DefaultChartState.prototype._set_axisOrientationX__1 = function (_set___) {
    return this._axisOrientationX$delegate.setValue_10(this, axisOrientationX$factory(), _set___);
  };
  DefaultChartState.prototype._get_axisOrientationX__3 = function () {
    return this._axisOrientationX$delegate.getValue_10(this, axisOrientationX$factory_0());
  };
  DefaultChartState.prototype._set_axisOrientationY__1 = function (_set___) {
    return this._axisOrientationY$delegate.setValue_10(this, axisOrientationY$factory(), _set___);
  };
  DefaultChartState.prototype._get_axisOrientationY__3 = function () {
    return this._axisOrientationY$delegate.getValue_10(this, axisOrientationY$factory_0());
  };
  DefaultChartState.prototype.onChange_1 = function (listener) {
    this._changeListeners.add_57(listener);
    Unit_getInstance();
  };
  DefaultChartState.$metadata$ = {
    simpleName: 'DefaultChartState',
    kind: 'class',
    interfaces: [ObservableChartState]
  };
  function zoom$factory() {
    return getPropertyCallableRef('zoom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_zoom__1();
    }, function (receiver_0, value) {
      return receiver_0._set_zoom__1(value);
    });
  }
  function zoom$factory_0() {
    return getPropertyCallableRef('zoom', 1, KMutableProperty1, function (receiver) {
      return receiver._get_zoom__1();
    }, function (receiver_0, value) {
      return receiver_0._set_zoom__1(value);
    });
  }
  function windowTranslation$factory() {
    return getPropertyCallableRef('windowTranslation', 1, KMutableProperty1, function (receiver) {
      return receiver._get_windowTranslation__4();
    }, function (receiver_0, value) {
      return receiver_0._set_windowTranslation__1(value);
    });
  }
  function windowTranslation$factory_0() {
    return getPropertyCallableRef('windowTranslation', 1, KMutableProperty1, function (receiver) {
      return receiver._get_windowTranslation__4();
    }, function (receiver_0, value) {
      return receiver_0._set_windowTranslation__1(value);
    });
  }
  function contentAreaSize$factory() {
    return getPropertyCallableRef('contentAreaSize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_contentAreaSize__4();
    }, function (receiver_0, value) {
      return receiver_0._set_contentAreaSize__1(value);
    });
  }
  function contentAreaSize$factory_0() {
    return getPropertyCallableRef('contentAreaSize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_contentAreaSize__4();
    }, function (receiver_0, value) {
      return receiver_0._set_contentAreaSize__1(value);
    });
  }
  function windowSize$factory() {
    return getPropertyCallableRef('windowSize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_windowSize__4();
    }, function (receiver_0, value) {
      return receiver_0._set_windowSize__1(value);
    });
  }
  function windowSize$factory_0() {
    return getPropertyCallableRef('windowSize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_windowSize__4();
    }, function (receiver_0, value) {
      return receiver_0._set_windowSize__1(value);
    });
  }
  function axisOrientationX$factory() {
    return getPropertyCallableRef('axisOrientationX', 1, KMutableProperty1, function (receiver) {
      return receiver._get_axisOrientationX__3();
    }, function (receiver_0, value) {
      return receiver_0._set_axisOrientationX__1(value);
    });
  }
  function axisOrientationX$factory_0() {
    return getPropertyCallableRef('axisOrientationX', 1, KMutableProperty1, function (receiver) {
      return receiver._get_axisOrientationX__3();
    }, function (receiver_0, value) {
      return receiver_0._set_axisOrientationX__1(value);
    });
  }
  function axisOrientationY$factory() {
    return getPropertyCallableRef('axisOrientationY', 1, KMutableProperty1, function (receiver) {
      return receiver._get_axisOrientationY__3();
    }, function (receiver_0, value) {
      return receiver_0._set_axisOrientationY__1(value);
    });
  }
  function axisOrientationY$factory_0() {
    return getPropertyCallableRef('axisOrientationY', 1, KMutableProperty1, function (receiver) {
      return receiver._get_axisOrientationY__3();
    }, function (receiver_0, value) {
      return receiver_0._set_axisOrientationY__1(value);
    });
  }
  function _no_name_provided_$factory_264() {
    var i = new _no_name_provided__275();
    return function (p1) {
      i.invoke_779(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_265() {
    var i = new _no_name_provided__276();
    return function (p1) {
      i.invoke_779(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_266(this$0) {
    var i = new _no_name_provided__277(this$0);
    return function (p1) {
      i.invoke_521(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_267(this$0) {
    var i = new _no_name_provided__278(this$0);
    return function (p1) {
      i.invoke_547(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_268(this$0) {
    var i = new _no_name_provided__279(this$0);
    return function (p1) {
      i.invoke_779(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_269(this$0) {
    var i = new _no_name_provided__280(this$0);
    return function (p1) {
      i.invoke_779(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_270(this$0) {
    var i = new _no_name_provided__281(this$0);
    return function (p1) {
      i.invoke_529(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_271(this$0) {
    var i = new _no_name_provided__282(this$0);
    return function (p1) {
      i.invoke_531(p1);
      return Unit_getInstance();
    };
  }
  function Companion_46() {
    Companion_instance_45 = this;
    this._noTranslation = NoTranslation_getInstance();
    this._tenPercentMargin = new FittingWithMarginPercentage(0.1, 0.1);
    this._tenPercentYMargin = new FittingWithMarginPercentage(0.0, 0.1);
  }
  Companion_46.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_45;
  function Companion_getInstance_45() {
    if (Companion_instance_45 == null)
      new Companion_46();
    return Companion_instance_45;
  }
  function ZoomAndTranslationDefaults() {
  }
  ZoomAndTranslationDefaults.$metadata$ = {
    simpleName: 'ZoomAndTranslationDefaults',
    kind: 'interface',
    interfaces: []
  };
  function NoTranslation() {
    NoTranslation_instance = this;
  }
  NoTranslation.prototype.defaultZoom_3 = function (chartCalculator) {
    return Companion_getInstance_38()._default_0;
  };
  NoTranslation.prototype.defaultTranslation_3 = function (chartCalculator) {
    return Companion_getInstance_34()._zero;
  };
  NoTranslation.$metadata$ = {
    simpleName: 'NoTranslation',
    kind: 'object',
    interfaces: [ZoomAndTranslationDefaults]
  };
  var NoTranslation_instance;
  function NoTranslation_getInstance() {
    if (NoTranslation_instance == null)
      new NoTranslation();
    return NoTranslation_instance;
  }
  function FittingWithMarginPercentage(marginPercentageX, marginPercentageY) {
    this._marginPercentageX = marginPercentageX;
    this._marginPercentageY = marginPercentageY;
    var tmp0_require_0 = this._marginPercentageX < 1.0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'marginPercentageX must be smaller than 1.0 but was <' + this._marginPercentageX;
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp1_require_0 = this._marginPercentageY < 1.0;
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'marginPercentageY must be smaller than 1.0 but was <' + this._marginPercentageY;
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
    }}
  FittingWithMarginPercentage.prototype.defaultZoom_3 = function (chartCalculator) {
    return Companion_getInstance_38().of_4(1.0 - this._marginPercentageX, 1.0 - this._marginPercentageY);
  };
  FittingWithMarginPercentage.prototype.defaultTranslation_3 = function (chartCalculator) {
    return new Distance(chartCalculator.contentArea2zoomedX(chartCalculator._chartState._get_contentAreaWidth__3() * this._marginPercentageX / 2.0), chartCalculator.contentArea2zoomedY(chartCalculator._chartState._get_contentAreaHeight__3() * this._marginPercentageY / 2.0));
  };
  FittingWithMarginPercentage.$metadata$ = {
    simpleName: 'FittingWithMarginPercentage',
    kind: 'class',
    interfaces: [ZoomAndTranslationDefaults]
  };
  function FittingWithMarginAspectRatio(marginProvider) {
    FittingWithMargin.call(this, marginProvider);
  }
  FittingWithMarginAspectRatio.prototype.defaultZoom_3 = function (chartCalculator) {
    return FittingWithMargin.prototype.defaultZoom_3.call(this, chartCalculator).smallerValueForBoth();
  };
  FittingWithMarginAspectRatio.$metadata$ = {
    simpleName: 'FittingWithMarginAspectRatio',
    kind: 'class',
    interfaces: []
  };
  function FittingWithMargin(marginProvider) {
    this._marginProvider = marginProvider;
  }
  FittingWithMargin.prototype.defaultZoom_3 = function (chartCalculator) {
    var chartState = chartCalculator._chartState;
    if (chartState._get_hasZeroSize__3()) {
      return Companion_getInstance_38()._default_0;
    }var windowSize = chartState._get_windowSize__4();
    var margin = this._marginProvider();
    var windowNetWidth = windowSize._width_0 - margin._offsetWidth;
    var windowNetHeight = windowSize._height - margin._offsetHeight;
    if (windowNetHeight <= 0.0 ? true : windowNetHeight <= 0.0) {
      return Companion_getInstance_38()._default_0;
    }var contentAreaSize = chartState._get_contentAreaSize__4();
    return Companion_getInstance_38().of_4(1.0 / contentAreaSize._width_0 * windowNetWidth, 1.0 / contentAreaSize._height * windowNetHeight);
  };
  FittingWithMargin.prototype.defaultTranslation_3 = function (chartCalculator) {
    var tmp0_let_0 = this._marginProvider();
    return new Distance(tmp0_let_0._left, tmp0_let_0._top);
  };
  FittingWithMargin.$metadata$ = {
    simpleName: 'FittingWithMargin',
    kind: 'class',
    interfaces: [ZoomAndTranslationDefaults]
  };
  function AbstractLayer() {
    this._disposeSupport = new DisposeSupport();
  }
  AbstractLayer.$metadata$ = {
    simpleName: 'AbstractLayer',
    kind: 'class',
    interfaces: [Layer]
  };
  function BoundsLayoutCache() {
    this._xValues = new DoublesCache();
    this._yValues = new DoublesCache();
    this._widthValues = new DoublesCache();
    this._heightValues = new DoublesCache();
  }
  BoundsLayoutCache.prototype.ensureSize_0 = function (size) {
    this._xValues.ensureSize_0(size);
    this._yValues.ensureSize_0(size);
    this._widthValues.ensureSize_0(size);
    this._heightValues.ensureSize_0(size);
  };
  BoundsLayoutCache.prototype.x_0 = function (index, value) {
    this._xValues.set_9(index, value);
  };
  BoundsLayoutCache.prototype.y_0 = function (index, value) {
    this._yValues.set_9(index, value);
  };
  BoundsLayoutCache.prototype.width_0 = function (index, value) {
    this._widthValues.set_9(index, value);
  };
  BoundsLayoutCache.prototype.height_0 = function (index, value) {
    this._heightValues.set_9(index, value);
  };
  BoundsLayoutCache.prototype.findIndex = function (locationX, locationY) {
    var tmp1_fastForEachIndexed_0_1 = this._xValues;
    var tmp0_fastForEachIndexed_0_2 = tmp1_fastForEachIndexed_0_1._values_1;
    var n_1_3 = 0;
    var currentSize_2_4 = tmp0_fastForEachIndexed_0_2.length;
    while (n_1_3 < currentSize_2_4) {
      var tmp2__anonymous__1_5 = n_1_3;
      var tmp0_3_7 = n_1_3;
      n_1_3 = tmp0_3_7 + 1 | 0;
      var tmp3__anonymous__1_6 = tmp0_fastForEachIndexed_0_2[tmp0_3_7];
      var y_2_8 = this._yValues.get_44(tmp2__anonymous__1_5);
      var width_3_9 = this._widthValues.get_44(tmp2__anonymous__1_5);
      var height_4_10 = this._heightValues.get_44(tmp2__anonymous__1_5);
      var tmp;
      var tmp0_betweenInclusive_0_12 = tmp3__anonymous__1_6 + width_3_9;
      if ((locationX >= tmp3__anonymous__1_6 ? locationX <= tmp0_betweenInclusive_0_12 : false) ? true : locationX >= tmp0_betweenInclusive_0_12 ? locationX <= tmp3__anonymous__1_6 : false) {
        var tmp1_betweenInclusive_0_13 = y_2_8 + height_4_10;
        tmp = (locationY >= y_2_8 ? locationY <= tmp1_betweenInclusive_0_13 : false) ? true : locationY >= tmp1_betweenInclusive_0_13 ? locationY <= y_2_8 : false;
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        return tmp2__anonymous__1_5;
      } else {
      }
    }
    return -1;
  };
  BoundsLayoutCache.$metadata$ = {
    simpleName: 'BoundsLayoutCache',
    kind: 'class',
    interfaces: [LayoutVariablesCache]
  };
  function ClearBackgroundLayer() {
    this._type_2 = LayerType_Background_getInstance();
  }
  ClearBackgroundLayer.prototype._get_type__23 = function () {
    return this._type_2;
  };
  ClearBackgroundLayer.prototype.paint_12 = function (paintingContext) {
    paintingContext._gc.clear_27();
  };
  ClearBackgroundLayer.$metadata$ = {
    simpleName: 'ClearBackgroundLayer',
    kind: 'class',
    interfaces: [Layer]
  };
  function addClearBackground(_this_) {
    var tmp0_also_0 = new ClearBackgroundLayer();
    _this_.addLayer(tmp0_also_0);
    Unit_getInstance();
    return tmp0_also_0;
  }
  function DoublesCache() {
    var tmp = this;
    var tmp0_doubleArrayOf_0 = new Float64Array([]);
    tmp._values_1 = tmp0_doubleArrayOf_0;
  }
  DoublesCache.prototype.ensureSize_0 = function (size) {
    if (!(this._values_1.length === size)) {
      this._values_1 = new Float64Array(size);
    }};
  DoublesCache.prototype.set_9 = function (index, value) {
    this._values_1[index] = value;
  };
  DoublesCache.prototype.get_44 = function (index) {
    return this._values_1[index];
  };
  DoublesCache.$metadata$ = {
    simpleName: 'DoublesCache',
    kind: 'class',
    interfaces: [LayoutVariablesCache]
  };
  function Layer() {
  }
  Layer.prototype._get_description__6 = function () {
    var tmp0_elvis_lhs = getKClassFromExpression_0(this)._get_simpleName__4();
    return tmp0_elvis_lhs == null ? toString_2(this) : tmp0_elvis_lhs;
  };
  Layer.prototype.layout_6 = function (paintingContext) {
  };
  Layer.prototype._get_mouseEventHandler__6 = function () {
    return null;
  };
  Layer.prototype._get_keyEventHandler__6 = function () {
    return null;
  };
  Layer.prototype._get_pointerEventHandler__6 = function () {
    return null;
  };
  Layer.prototype._get_touchEventHandler__6 = function () {
    return null;
  };
  Layer.$metadata$ = {
    simpleName: 'Layer',
    kind: 'interface',
    interfaces: []
  };
  function LayerPaintingContext(gc, layerSupport, frameTimestamp, frameTimestampDelta) {
    this._gc = gc;
    this._layerSupport = layerSupport;
    this._frameTimestamp = frameTimestamp;
    this._frameTimestampDelta = frameTimestampDelta;
  }
  LayerPaintingContext.prototype._get_chartSupport__3 = function () {
    return this._layerSupport._get_chartSupport__3();
  };
  LayerPaintingContext.prototype._get_chartCalculator__0 = function () {
    return this._get_chartSupport__3()._get_chartCalculator__0();
  };
  LayerPaintingContext.prototype.toString = function () {
    return '' + 'LayerPaintingContext(gc=' + this._gc + ', layerSupport=' + this._layerSupport + ', frameTimestamp=' + this._frameTimestamp + ', frameTimestampDelta=' + this._frameTimestampDelta + ')';
  };
  LayerPaintingContext.prototype.hashCode = function () {
    var result = hashCode(this._gc);
    result = imul(result, 31) + hashCode(this._layerSupport) | 0;
    result = imul(result, 31) + getNumberHashCode(this._frameTimestamp) | 0;
    result = imul(result, 31) + getNumberHashCode(this._frameTimestampDelta) | 0;
    return result;
  };
  LayerPaintingContext.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof LayerPaintingContext))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof LayerPaintingContext ? other : THROW_CCE();
    if (!equals_0(this._gc, tmp0_other_with_cast._gc))
      return false;
    if (!equals_0(this._layerSupport, tmp0_other_with_cast._layerSupport))
      return false;
    if (!equals_0(this._frameTimestamp, tmp0_other_with_cast._frameTimestamp))
      return false;
    if (!equals_0(this._frameTimestampDelta, tmp0_other_with_cast._frameTimestampDelta))
      return false;
    return true;
  };
  LayerPaintingContext.$metadata$ = {
    simpleName: 'LayerPaintingContext',
    kind: 'class',
    interfaces: []
  };
  var LayerType_Background_instance;
  var LayerType_Content_instance;
  var LayerType_Notification_instance;
  var LayerType_entriesInitialized;
  function LayerType_initEntries() {
    if (LayerType_entriesInitialized)
      return Unit_getInstance();
    LayerType_entriesInitialized = true;
    LayerType_Background_instance = new LayerType('Background', 0);
    LayerType_Content_instance = new LayerType('Content', 1);
    LayerType_Notification_instance = new LayerType('Notification', 2);
  }
  function LayerType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  LayerType.prototype.sameOrBelow = function (type) {
    return this._get_ordinal__0() <= type._get_ordinal__0();
  };
  LayerType.$metadata$ = {
    simpleName: 'LayerType',
    kind: 'class',
    interfaces: []
  };
  function LayerType_Background_getInstance() {
    LayerType_initEntries();
    return LayerType_Background_instance;
  }
  function LayerType_Content_getInstance() {
    LayerType_initEntries();
    return LayerType_Content_instance;
  }
  function LayerType_Notification_getInstance() {
    LayerType_initEntries();
    return LayerType_Notification_instance;
  }
  function Layers() {
    var tmp = this;
    tmp._layersList = ArrayList_init_$Create$();
  }
  Layers.prototype.isEmpty_33 = function () {
    return this._layersList.isEmpty_33();
  };
  Layers.prototype._get_layers__1 = function () {
    return this._layersList;
  };
  Layers.prototype._get_layersOrderedForInteraction_ = function () {
    return reversed(this._get_layers__1());
  };
  Layers.prototype.addLayer = function (layer) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_indexOfLast_0 = this._layersList;
      var iterator_1 = tmp0_indexOfLast_0.listIterator_3(tmp0_indexOfLast_0._get_size__43());
      while (iterator_1.hasPrevious_1()) {
        var tmp1__anonymous__2 = iterator_1.previous_1();
        if (tmp1__anonymous__2._get_type__23().sameOrBelow(layer._get_type__23())) {
          tmp$ret$0 = iterator_1.nextIndex_1();
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = -1;
    }
     while (false);
    var insertionIndex = tmp$ret$0 + 1 | 0;
    this._layersList.add_20(insertionIndex, layer);
    return insertionIndex;
  };
  Layers.prototype.paintLayersWithStats = function (paintingContext) {
    var inductionVariable = 0;
    var last_1 = this._layersList._get_size__43();
    if (inductionVariable < last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp0_saved_0 = paintingContext._gc;
        tmp0_saved_0.save_3();
        try {
          this._layersList.get_44(i).layout_6(paintingContext);
        }finally {
          tmp0_saved_0.restore_3();
        }
      }
       while (inductionVariable < last_1);
    var tmp1_map_0 = this._layersList;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map_0, 10));
    var tmp0_iterator_1_2 = tmp1_map_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var item_2_3 = tmp0_iterator_1_2.next_13();
      var tmp0_measureTime_0_1_6 = Monotonic_getInstance();
      var mark_2_2_7 = tmp0_measureTime_0_1_6.markNow_5();
      var tmp0_saved_0_4_8 = paintingContext._gc;
      tmp0_saved_0_4_8.save_3();
      try {
        item_2_3.paint_12(paintingContext);
      }finally {
        tmp0_saved_0_4_8.restore_3();
      }
      var measureTime_5 = mark_2_2_7.elapsedNow_1();
      tmp0_mapTo_0_1.add_57(new LayerPaintDuration(item_2_3, item_2_3._get_description__6(), _Duration___get_inMilliseconds__impl_(measureTime_5)));
      Unit_getInstance();
    }
    var layerRepaintDurations = tmp0_mapTo_0_1;
    return new PaintingStats(paintingContext._frameTimestamp, paintingContext._frameTimestampDelta, new LayerPaintDurations(layerRepaintDurations));
  };
  Layers.prototype.paintLayers = function (paintingContext) {
    var inductionVariable = 0;
    var last_1 = this._layersList._get_size__43();
    if (inductionVariable < last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp0_saved_0 = paintingContext._gc;
        tmp0_saved_0.save_3();
        try {
          this._layersList.get_44(i).layout_6(paintingContext);
        }finally {
          tmp0_saved_0.restore_3();
        }
      }
       while (inductionVariable < last_1);
    var inductionVariable_0 = 0;
    var last_2 = this._layersList._get_size__43();
    if (inductionVariable_0 < last_2)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var tmp1_saved_0 = paintingContext._gc;
        tmp1_saved_0.save_3();
        try {
          this._layersList.get_44(i_0).paint_12(paintingContext);
        }finally {
          tmp1_saved_0.restore_3();
        }
      }
       while (inductionVariable_0 < last_2);
  };
  Layers.$metadata$ = {
    simpleName: 'Layers',
    kind: 'class',
    interfaces: []
  };
  function LayoutVariablesCache() {
  }
  LayoutVariablesCache.$metadata$ = {
    simpleName: 'LayoutVariablesCache',
    kind: 'interface',
    interfaces: []
  };
  function Data_init_$Init$(textsProvider, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0)) {
      textsProvider = _no_name_provided_$factory_273();
    }Data.call($this, textsProvider);
    return $this;
  }
  function Data_init_$Create$(textsProvider, $mask0, $marker) {
    return Data_init_$Init$(textsProvider, $mask0, $marker, Object.create(Data.prototype));
  }
  function _no_name_provided__283() {
  }
  _no_name_provided__283.prototype.invoke_535 = function (_anonymous_parameter_0_, _anonymous_parameter_1_) {
    return listOf(['48\xB024\'49.7"N', '9\xB003\'03.0"E']);
  };
  _no_name_provided__283.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof TextService ? p1 : THROW_CCE();
    return this.invoke_535(tmp, p2 instanceof Locale ? p2 : THROW_CCE());
  };
  _no_name_provided__283.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__284() {
  }
  _no_name_provided__284.prototype.invoke_535 = function (_anonymous_parameter_0_, _anonymous_parameter_1_) {
    return listOf_0('Hello QuickChart');
  };
  _no_name_provided__284.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof TextService ? p1 : THROW_CCE();
    return this.invoke_535(tmp, p2 instanceof Locale ? p2 : THROW_CCE());
  };
  _no_name_provided__284.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function TextLayer_init_$Init$(data, styleConfiguration, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0)) {
      data = Data_init_$Create$(null, 1, null);
    }if (!(($mask0 & 2) === 0)) {
      styleConfiguration = _no_name_provided_$factory_272();
    }TextLayer.call($this, data, styleConfiguration);
    return $this;
  }
  function TextLayer_init_$Create$(data, styleConfiguration, $mask0, $marker) {
    return TextLayer_init_$Init$(data, styleConfiguration, $mask0, $marker, Object.create(TextLayer.prototype));
  }
  function Data(textsProvider) {
    this._textsProvider = textsProvider;
  }
  Data.$metadata$ = {
    simpleName: 'Data',
    kind: 'class',
    interfaces: []
  };
  function Style_0() {
    this._textColor = Companion_getInstance_29()._black;
    this._boxStyle = Companion_getInstance_67()._get_none_();
    this._font = FontDescriptorFragment_init_$Create$_0(26.0);
    this._lineSpacing = Companion_getInstance_60()._Single;
    var tmp = this;
    var tmp_0 = Direction_Center_getInstance();
    tmp._anchorPointProvider = DirectionBasedBasePointProvider_init_$Create$(tmp_0, null, 2, null);
    this._anchorDirection = Direction_Center_getInstance();
    this._anchorGap = 0.0;
    this._horizontalAlignment_0 = HorizontalAlignment_Center_getInstance();
    this._margin = Companion_getInstance_35()._empty;
  }
  Style_0.$metadata$ = {
    simpleName: 'Style',
    kind: 'class',
    interfaces: []
  };
  function Companion_47() {
    Companion_instance_46 = this;
    var tmp = this;
    var tmp_0 = new Data(_no_name_provided_$factory_274());
    tmp._helloQuickChart = TextLayer_init_$Create$(tmp_0, null, 2, null);
  }
  Companion_47.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_46;
  function Companion_getInstance_46() {
    if (Companion_instance_46 == null)
      new Companion_47();
    return Companion_instance_46;
  }
  function _no_name_provided__285() {
  }
  _no_name_provided__285.prototype.invoke_537 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__285.prototype.invoke_1082 = function (p1) {
    this.invoke_537(p1 instanceof Style_0 ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__285.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function TextLayer(data, styleConfiguration) {
    Companion_getInstance_46();
    this._data = data;
    var tmp = this;
    var tmp0_apply_0 = new Style_0();
    styleConfiguration(tmp0_apply_0);
    tmp._style_1 = tmp0_apply_0;
    this._type_3 = LayerType_Content_getInstance();
    this._painter = new TextPainter();
  }
  TextLayer.prototype._get_type__23 = function () {
    return this._type_3;
  };
  TextLayer.prototype.paint_12 = function (paintingContext) {
    var gc = paintingContext._gc;
    gc.font_2(this._style_1._font);
    gc.save_3();
    try {
      var anchorPoint_3 = this._style_1._anchorPointProvider.calculateBasePoint_0(gc._get_boundingBox__1());
      gc.translate_35(anchorPoint_3._x, anchorPoint_3._y);
      var tmp = this._data._textsProvider(_get_textService_(paintingContext._get_chartSupport__3()), _get_i18nSupport_(paintingContext._get_chartSupport__3())._get_textLocale_());
      var tmp_0 = this._style_1._textColor;
      var tmp_1 = this._style_1._boxStyle;
      var tmp_2 = this._style_1._lineSpacing;
      var tmp_3 = this._style_1._horizontalAlignment_0;
      var tmp_4 = this._style_1._anchorDirection;
      var tmp_5 = this._style_1._anchorGap;
      this._painter.paintText$default(gc, tmp, tmp_0, tmp_1, tmp_2, tmp_3, tmp_4, tmp_5, null, 256, null);
    }finally {
      gc.restore_3();
    }
  };
  TextLayer.$metadata$ = {
    simpleName: 'TextLayer',
    kind: 'class',
    interfaces: [Layer]
  };
  function TextPainter() {
  }
  TextPainter.prototype.paintText = function (gc, lines, textColor, boxStyle, lineSpacing, horizontalAlignment, anchorDirection, anchorGap, maxStringWidth) {
    paintTextBox(gc, lines, lineSpacing, horizontalAlignment, anchorDirection, anchorGap, boxStyle, textColor, maxStringWidth);
    Unit_getInstance();
  };
  TextPainter.prototype.paintText$default = function (gc, lines, textColor, boxStyle, lineSpacing, horizontalAlignment, anchorDirection, anchorGap, maxStringWidth, $mask0, $handler) {
    if (!(($mask0 & 256) === 0))
      maxStringWidth = null;
    return this.paintText(gc, lines, textColor, boxStyle, lineSpacing, horizontalAlignment, anchorDirection, anchorGap, maxStringWidth);
  };
  TextPainter.$metadata$ = {
    simpleName: 'TextPainter',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_272() {
    var i = new _no_name_provided__285();
    return function (p1) {
      i.invoke_537(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_273() {
    var i = new _no_name_provided__283();
    return function (p1, p2) {
      return i.invoke_535(p1, p2);
    };
  }
  function _no_name_provided_$factory_274() {
    var i = new _no_name_provided__284();
    return function (p1, p2) {
      return i.invoke_535(p1, p2);
    };
  }
  function Data_0() {
    this._toggleKeyStroke = new KeyStroke(KeyCode_init_$Create$(new Char(68)), Companion_getInstance_65()._CtrlShiftAlt);
  }
  Data_0.$metadata$ = {
    simpleName: 'Data',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__286(this$0) {
    this._this$0_210 = this$0;
  }
  _no_name_provided__286.prototype.onDown_21 = function (event, chartSupport) {
    if (event._get_keyStroke__1().equals(this._this$0_210._data_0._toggleKeyStroke)) {
      _get_debug_(chartSupport).toggle_0();
      _get_layerSupport_(chartSupport).markAsDirty_4();
      event.consume_14();
    }};
  _no_name_provided__286.$metadata$ = {
    kind: 'class',
    interfaces: [CanvasKeyEventHandler]
  };
  function ToggleDebuggingModeLayer() {
    this._data_0 = new Data_0();
    this._type_4 = LayerType_Background_getInstance();
    var tmp = this;
    tmp._keyEventHandler = new _no_name_provided__286(this);
  }
  ToggleDebuggingModeLayer.prototype._get_type__23 = function () {
    return this._type_4;
  };
  ToggleDebuggingModeLayer.prototype.paint_12 = function (paintingContext) {
  };
  ToggleDebuggingModeLayer.prototype._get_keyEventHandler__6 = function () {
    return this._keyEventHandler;
  };
  ToggleDebuggingModeLayer.$metadata$ = {
    simpleName: 'ToggleDebuggingModeLayer',
    kind: 'class',
    interfaces: [Layer]
  };
  function MouseWheelZoomConfiguration_init_$Init$(zoomAxisSelection, zoomXandYModifier, zoomXModifier, zoomYModifier, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      zoomAxisSelection = AxisSelection_Both_getInstance();
    if (!(($mask0 & 2) === 0))
      zoomXandYModifier = Companion_getInstance_65()._CtrlShift;
    if (!(($mask0 & 4) === 0))
      zoomXModifier = Companion_getInstance_65()._Control;
    if (!(($mask0 & 8) === 0))
      zoomYModifier = Companion_getInstance_65()._Shift;
    MouseWheelZoomConfiguration.call($this, zoomAxisSelection, zoomXandYModifier, zoomXModifier, zoomYModifier);
    return $this;
  }
  function MouseWheelZoomConfiguration_init_$Create$(zoomAxisSelection, zoomXandYModifier, zoomXModifier, zoomYModifier, $mask0, $marker) {
    return MouseWheelZoomConfiguration_init_$Init$(zoomAxisSelection, zoomXandYModifier, zoomXModifier, zoomYModifier, $mask0, $marker, Object.create(MouseWheelZoomConfiguration.prototype));
  }
  function Companion_48() {
    Companion_instance_47 = this;
    var tmp = this;
    var tmp_0 = Companion_getInstance_65()._None;
    tmp._withoutModifiers = MouseWheelZoomConfiguration_init_$Create$(null, tmp_0, null, null, 13, null);
    var tmp_1 = this;
    tmp_1._bothAxis = MouseWheelZoomConfiguration_init_$Create$(null, Companion_getInstance_65()._None, null, null, 1, null);
  }
  Companion_48.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_47;
  function Companion_getInstance_47() {
    if (Companion_instance_47 == null)
      new Companion_48();
    return Companion_instance_47;
  }
  function MouseWheelZoomConfiguration(zoomAxisSelection, zoomXandYModifier, zoomXModifier, zoomYModifier) {
    Companion_getInstance_47();
    this._zoomAxisSelection = zoomAxisSelection;
    this._zoomXandYModifier = zoomXandYModifier;
    this._zoomXModifier = zoomXModifier;
    this._zoomYModifier = zoomYModifier;
  }
  MouseWheelZoomConfiguration.prototype.toString = function () {
    return '' + 'MouseWheelZoomConfiguration(zoomAxisSelection=' + this._zoomAxisSelection + ', zoomXandYModifier=' + this._zoomXandYModifier + ', zoomXModifier=' + this._zoomXModifier + ', zoomYModifier=' + this._zoomYModifier + ')';
  };
  MouseWheelZoomConfiguration.prototype.hashCode = function () {
    var result = this._zoomAxisSelection.hashCode();
    result = imul(result, 31) + (this._zoomXandYModifier == null ? 0 : this._zoomXandYModifier.hashCode()) | 0;
    result = imul(result, 31) + (this._zoomXModifier == null ? 0 : this._zoomXModifier.hashCode()) | 0;
    result = imul(result, 31) + (this._zoomYModifier == null ? 0 : this._zoomYModifier.hashCode()) | 0;
    return result;
  };
  MouseWheelZoomConfiguration.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MouseWheelZoomConfiguration))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof MouseWheelZoomConfiguration ? other : THROW_CCE();
    if (!this._zoomAxisSelection.equals(tmp0_other_with_cast._zoomAxisSelection))
      return false;
    if (!equals_0(this._zoomXandYModifier, tmp0_other_with_cast._zoomXandYModifier))
      return false;
    if (!equals_0(this._zoomXModifier, tmp0_other_with_cast._zoomXModifier))
      return false;
    if (!equals_0(this._zoomYModifier, tmp0_other_with_cast._zoomYModifier))
      return false;
    return true;
  };
  MouseWheelZoomConfiguration.$metadata$ = {
    simpleName: 'MouseWheelZoomConfiguration',
    kind: 'class',
    interfaces: []
  };
  function RubberBandVisualizationLayer_init_$Init$(data, styleConfiguration, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0)) {
      styleConfiguration = _no_name_provided_$factory_275();
    }RubberBandVisualizationLayer.call($this, data, styleConfiguration);
    return $this;
  }
  function RubberBandVisualizationLayer_init_$Create$(data, styleConfiguration, $mask0, $marker) {
    return RubberBandVisualizationLayer_init_$Init$(data, styleConfiguration, $mask0, $marker, Object.create(RubberBandVisualizationLayer.prototype));
  }
  function Style_1() {
    this._stroke = Companion_getInstance_29()._orange;
    this._fill = Companion_getInstance_29().rgba(255, 165, 0, 0.5);
  }
  Style_1.$metadata$ = {
    simpleName: 'Style',
    kind: 'class',
    interfaces: []
  };
  function Data_1(startLocation, currentLocation) {
    this._startLocation = startLocation;
    this._currentLocation = currentLocation;
  }
  Data_1.$metadata$ = {
    simpleName: 'Data',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__287() {
  }
  _no_name_provided__287.prototype.invoke_539 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__287.prototype.invoke_1082 = function (p1) {
    this.invoke_539(p1 instanceof Style_1 ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__287.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function RubberBandVisualizationLayer(data, styleConfiguration) {
    AbstractLayer.call(this);
    this._data_1 = data;
    var tmp = this;
    var tmp0_also_0 = new Style_1();
    styleConfiguration(tmp0_also_0);
    tmp._style_2 = tmp0_also_0;
    this._type_5 = LayerType_Notification_getInstance();
  }
  RubberBandVisualizationLayer.prototype._get_type__23 = function () {
    return this._type_5;
  };
  RubberBandVisualizationLayer.prototype.paint_12 = function (paintingContext) {
    var tmp0_elvis_lhs = this._data_1._startLocation();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_getInstance();
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var startLocation = tmp;
    var tmp1_elvis_lhs = this._data_1._currentLocation();
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return Unit_getInstance();
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var currentLocation = tmp_0;
    var gc = paintingContext._gc;
    gc.stroke_5(this._style_2._stroke);
    gc.fill_5(this._style_2._fill);
    fillRectCoordinates(gc, startLocation, currentLocation);
    strokeRectCoordinates(gc, startLocation, currentLocation);
  };
  RubberBandVisualizationLayer.$metadata$ = {
    simpleName: 'RubberBandVisualizationLayer',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_275() {
    var i = new _no_name_provided__287();
    return function (p1) {
      i.invoke_539(p1);
      return Unit_getInstance();
    };
  }
  function ZoomAndTranslationConfiguration() {
    this._resetOnDoubleClick = true;
    this._mouseWheelZoom = true;
    var tmp = this;
    tmp._mouseWheelZoomConfiguration = MouseWheelZoomConfiguration_init_$Create$(null, null, null, null, 15, null);
    this._translateOnDrag = true;
    this._translateAxisSelection = AxisSelection_Both_getInstance();
    this._rubberBandZoom = false;
  }
  ZoomAndTranslationConfiguration.prototype.configure = function (zoomAndTranslationLayer) {
    if (this._resetOnDoubleClick) {
      resetToDefaultsOnDoubleClick(zoomAndTranslationLayer);
    }if (this._mouseWheelZoom) {
      zoomOnMouseWheel(zoomAndTranslationLayer, this._mouseWheelZoomConfiguration);
    }if (this._translateOnDrag) {
      translateOnDrag(zoomAndTranslationLayer, this._translateAxisSelection);
    }if (this._rubberBandZoom) {
      rubberBandZoom(zoomAndTranslationLayer);
    }};
  ZoomAndTranslationConfiguration.$metadata$ = {
    simpleName: 'ZoomAndTranslationConfiguration',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__288(this$0) {
    this._this$0_211 = this$0;
  }
  _no_name_provided__288.prototype.onDoubleClick_8 = function (event, chartSupport) {
    var tmp0_safe_receiver = this._this$0_211._doubleClickAction;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver(event);
      event.consume_14();
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__288.prototype.onWheel_7 = function (event, chartSupport) {
    var tmp0_safe_receiver = this._this$0_211._mouseWheelAction;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver(event);
      event.consume_14();
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__288.$metadata$ = {
    kind: 'class',
    interfaces: [CanvasMouseEventHandler]
  };
  function _no_name_provided__289($action) {
    this._$action_1 = $action;
  }
  _no_name_provided__289.prototype.onDrag_12 = function (source, coordinates, distance, deltaTime, chartSupport) {
    this._$action_1(distance);
  };
  _no_name_provided__289.$metadata$ = {
    kind: 'class',
    interfaces: [Handler]
  };
  function _no_name_provided__290() {
  }
  _no_name_provided__290.prototype.onMove_8 = function (event, chartSupport) {
    event.consume_14();
  };
  _no_name_provided__290.$metadata$ = {
    kind: 'class',
    interfaces: [CanvasTouchEventHandler]
  };
  function ZoomAndTranslationLayer(zoomAndTranslationSupport, configuration) {
    AbstractLayer.call(this);
    this._zoomAndTranslationSupport = zoomAndTranslationSupport;
    this._type_6 = LayerType_Content_getInstance();
    var tmp = this;
    var tmp0_also_0 = new CanvasMouseEventHandlerBroker();
    tmp0_also_0.delegate_0(new _no_name_provided__288(this));
    tmp._mouseEventHandler = tmp0_also_0;
    this._pointerEventHandler = new CanvasPointerEventHandlerBroker();
    var tmp_0 = this;
    var tmp0_also_0_0 = new CanvasDragSupport();
    if (environment._multiTouchSupported) {
      this._pointerEventHandler.delegate_1(connectedPointerEventHandler(tmp0_also_0_0));
    } else {
      this._mouseEventHandler.delegate_0(connectedMouseEventHandler(tmp0_also_0_0));
    }
    tmp0_also_0_0.handle(delegate(dragAction$factory(this)));
    tmp_0._dragSupport = tmp0_also_0_0;
    this._doubleClickAction = null;
    this._mouseWheelAction = null;
    this._dragAction = null;
    var tmp_1 = this;
    tmp_1._touchEventHandler = new _no_name_provided__290();
    configuration(this);
    this._rubberBandStartLocation = null;
    this._rubberBandCurrentLocation = null;
  }
  ZoomAndTranslationLayer.prototype._get_type__23 = function () {
    return this._type_6;
  };
  ZoomAndTranslationLayer.prototype._get_mouseEventHandler__6 = function () {
    return this._mouseEventHandler;
  };
  ZoomAndTranslationLayer.prototype._get_pointerEventHandler__6 = function () {
    return this._pointerEventHandler;
  };
  ZoomAndTranslationLayer.prototype.onDoubleClick_5 = function (action) {
    this._doubleClickAction = action;
  };
  ZoomAndTranslationLayer.prototype.onMouseWheel = function (action) {
    this._mouseWheelAction = action;
  };
  ZoomAndTranslationLayer.prototype.onDrag_1 = function (action) {
    this._dragAction = action;
  };
  ZoomAndTranslationLayer.prototype.onDrag_2 = function (action) {
    var tmp = this;
    tmp._dragAction = new _no_name_provided__289(action);
  };
  ZoomAndTranslationLayer.prototype._get_touchEventHandler__6 = function () {
    return this._touchEventHandler;
  };
  ZoomAndTranslationLayer.prototype.paint_12 = function (paintingContext) {
  };
  ZoomAndTranslationLayer.$metadata$ = {
    simpleName: 'ZoomAndTranslationLayer',
    kind: 'class',
    interfaces: []
  };
  function addZoomAndTranslation(_this_, zoomAndTranslationSupport, configuration) {
    var tmp0_apply_0 = new ZoomAndTranslationLayer(zoomAndTranslationSupport, configuration);
    _this_.addLayer(tmp0_apply_0);
    Unit_getInstance();
    var tmp = _no_name_provided_$factory_276(tmp0_apply_0);
    var tmp_0 = new Data_1(tmp, _no_name_provided_$factory_277(tmp0_apply_0));
    var rubberBandVisualizationLayer_2 = RubberBandVisualizationLayer_init_$Create$(tmp_0, null, 2, null);
    _this_.addLayer(rubberBandVisualizationLayer_2);
    Unit_getInstance();
    return tmp0_apply_0;
  }
  function resetToDefaultsOnDoubleClick(_this_) {
    _this_.onDoubleClick_5(_no_name_provided_$factory_278(_this_));
  }
  function zoomOnMouseWheel(_this_, config) {
    _this_.onMouseWheel(_no_name_provided_$factory_279(config, _this_));
  }
  function translateOnDrag(_this_, axis) {
    _this_.onDrag_2(_no_name_provided_$factory_280(_this_, axis));
  }
  function rubberBandZoom(_this_) {
    _this_.onDrag_1(new _no_name_provided__296(_this_));
  }
  function _no_name_provided__291($tmp0_apply_0) {
    this._$tmp0_apply_0 = $tmp0_apply_0;
  }
  _no_name_provided__291.prototype.invoke_1038 = function () {
    return this._$tmp0_apply_0._rubberBandStartLocation;
  };
  _no_name_provided__291.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__292($tmp0_apply_0) {
    this._$tmp0_apply_0_0 = $tmp0_apply_0;
  }
  _no_name_provided__292.prototype.invoke_1038 = function () {
    return this._$tmp0_apply_0_0._rubberBandCurrentLocation;
  };
  _no_name_provided__292.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__293($this_resetToDefaultsOnDoubleClick) {
    this._$this_resetToDefaultsOnDoubleClick = $this_resetToDefaultsOnDoubleClick;
  }
  _no_name_provided__293.prototype.invoke_649 = function (it) {
    this._$this_resetToDefaultsOnDoubleClick._zoomAndTranslationSupport.resetToDefaults$default(null, 1, null);
  };
  _no_name_provided__293.prototype.invoke_1082 = function (p1) {
    this.invoke_649(p1 instanceof MouseDoubleClickEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__293.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__294($config, $this_zoomOnMouseWheel) {
    this._$config = $config;
    this._$this_zoomOnMouseWheel = $this_zoomOnMouseWheel;
  }
  _no_name_provided__294.prototype.invoke_645 = function (event) {
    if (event._delta + 0.0 === 0.0) {
      return Unit_getInstance();
    }var doZoomX = this._$config._zoomAxisSelection._containsX ? event._modifierCombination_4.equals(this._$config._zoomXModifier) ? true : event._modifierCombination_4.equals(this._$config._zoomXandYModifier) : false;
    var doZoomY = this._$config._zoomAxisSelection._containsY ? event._modifierCombination_4.equals(this._$config._zoomYModifier) ? true : event._modifierCombination_4.equals(this._$config._zoomXandYModifier) : false;
    var zoomAxis = Companion_getInstance_28().get_21(doZoomX, doZoomY);
    if (zoomAxis.equals(AxisSelection_None_getInstance())) {
      return Unit_getInstance();
    }var tmp = event._delta < 0.0;
    this._$this_zoomOnMouseWheel._zoomAndTranslationSupport.modifyZoom$default(tmp, zoomAxis, event._coordinates_3, 0.0, 8, null);
    event.consume_14();
  };
  _no_name_provided__294.prototype.invoke_1082 = function (p1) {
    this.invoke_645(p1 instanceof MouseWheelEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__294.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__295($this_translateOnDrag, $axis) {
    this._$this_translateOnDrag = $this_translateOnDrag;
    this._$axis = $axis;
  }
  _no_name_provided__295.prototype.invoke_547 = function (distance) {
    this._$this_translateOnDrag._zoomAndTranslationSupport.translateWindow(this._$axis, distance._x_0, distance._y_0);
  };
  _no_name_provided__295.prototype.invoke_1082 = function (p1) {
    this.invoke_547(p1 instanceof Distance ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__295.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__296($this_rubberBandZoom) {
    this._$this_rubberBandZoom = $this_rubberBandZoom;
  }
  _no_name_provided__296.prototype.onStart_8 = function (source, location, chartSupport) {
    this._$this_rubberBandZoom._rubberBandStartLocation = location;
  };
  _no_name_provided__296.prototype.onDrag_12 = function (source, coordinates, distance, deltaTime, chartSupport) {
    this._$this_rubberBandZoom._rubberBandCurrentLocation = coordinates;
    chartSupport.markAsDirty_4();
  };
  _no_name_provided__296.prototype.onFinish_2 = function (source, coordinates, chartSupport) {
    var tmp0_safe_receiver = this._$this_rubberBandZoom._rubberBandStartLocation;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp0_safe_receiver_2 = this._$this_rubberBandZoom._rubberBandCurrentLocation;
      if (tmp0_safe_receiver_2 == null)
        null;
      else {
        var rubberBandSize_2_3 = tmp0_safe_receiver.delta_0(tmp0_safe_receiver_2).abs_0().asSize();
        var relevantRubberBandSize_3_4 = rubberBandSize_2_3.containWithAspectRatio(chartSupport._rootChartState._get_zoom__1()._get_aspectRatio__0());
        var factorX_4_5 = 1 / relevantRubberBandSize_3_4._width_0 * chartSupport._canvas._get_width__41() * chartSupport._rootChartState._get_zoom__1()._scaleX;
        var factorY_5_6 = 1 / relevantRubberBandSize_3_4._height * chartSupport._canvas._get_height__41() * chartSupport._rootChartState._get_zoom__1()._scaleY;
        var center_6_7 = tmp0_safe_receiver.center(tmp0_safe_receiver_2);
        this._$this_rubberBandZoom._zoomAndTranslationSupport.setZoom_0(factorX_4_5, factorY_5_6, center_6_7);
        Unit_getInstance();
      }
    }
    Unit_getInstance();
    this._$this_rubberBandZoom._rubberBandStartLocation = null;
    this._$this_rubberBandZoom._rubberBandCurrentLocation = null;
    chartSupport.markAsDirty_4();
  };
  _no_name_provided__296.$metadata$ = {
    kind: 'class',
    interfaces: [Handler]
  };
  function dragAction$factory($b0) {
    return getPropertyCallableRef('dragAction', 0, KMutableProperty0, function () {
      return $b0._dragAction;
    }, function (value) {
      $b0._dragAction = value;
      return Unit_getInstance();
    });
  }
  function _no_name_provided_$factory_276($tmp0_apply_0) {
    var i = new _no_name_provided__291($tmp0_apply_0);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_277($tmp0_apply_0) {
    var i = new _no_name_provided__292($tmp0_apply_0);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_278($this_resetToDefaultsOnDoubleClick) {
    var i = new _no_name_provided__293($this_resetToDefaultsOnDoubleClick);
    return function (p1) {
      i.invoke_649(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_279($config, $this_zoomOnMouseWheel) {
    var i = new _no_name_provided__294($config, $this_zoomOnMouseWheel);
    return function (p1) {
      i.invoke_645(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_280($this_translateOnDrag, $axis) {
    var i = new _no_name_provided__295($this_translateOnDrag, $axis);
    return function (p1) {
      i.invoke_547(p1);
      return Unit_getInstance();
    };
  }
  var LatitudeTopEdge;
  var LatitudeBottomEdge;
  var SlippyMapTilePhysicalSize;
  function LatitudeTopEdge$init$() {
    var tmp0_sinh_0 = 3.141592653589793;
    var tmp1_atan_0 = Math.sinh(tmp0_sinh_0);
    return toDegrees(Math.atan(tmp1_atan_0));
  }
  function LatitudeBottomEdge$init$() {
    var tmp0_sinh_0 = -3.141592653589793;
    var tmp1_atan_0 = Math.sinh(tmp0_sinh_0);
    return toDegrees(Math.atan(tmp1_atan_0));
  }
  var defaultFillProvider;
  var defaultSizeProvider;
  function DefaultToolbarButtonFillProvider() {
  }
  DefaultToolbarButtonFillProvider.prototype.color_0 = function (state) {
    return state._disabled ? Companion_getInstance_29().rgba(200, 200, 200, 0.6) : state._pressed ? Companion_getInstance_29().rgba(150, 150, 150, 1.0) : state._hover ? Companion_getInstance_29().rgba(150, 150, 150, 0.75) : state._focused ? Companion_getInstance_29().rgba(150, 150, 150, 0.85) : Companion_getInstance_29().rgba(150, 150, 150, 0.6);
  };
  DefaultToolbarButtonFillProvider.$metadata$ = {
    simpleName: 'DefaultToolbarButtonFillProvider',
    kind: 'class',
    interfaces: []
  };
  function DefaultToolbarButtonSizeProvider_init_$Init$(defaultSize, activeSize, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      defaultSize = Companion_getInstance_37()._PX_40;
    if (!(($mask0 & 2) === 0))
      activeSize = Companion_getInstance_37()._PX_50;
    DefaultToolbarButtonSizeProvider.call($this, defaultSize, activeSize);
    return $this;
  }
  function DefaultToolbarButtonSizeProvider_init_$Create$(defaultSize, activeSize, $mask0, $marker) {
    return DefaultToolbarButtonSizeProvider_init_$Init$(defaultSize, activeSize, $mask0, $marker, Object.create(DefaultToolbarButtonSizeProvider.prototype));
  }
  function DefaultToolbarButtonSizeProvider(defaultSize, activeSize) {
    this._defaultSize = defaultSize;
    this._activeSize = activeSize;
  }
  DefaultToolbarButtonSizeProvider.prototype.size_0 = function (state) {
    return state._pressed ? this._activeSize : state._hover ? this._activeSize : this._defaultSize;
  };
  DefaultToolbarButtonSizeProvider.$metadata$ = {
    simpleName: 'DefaultToolbarButtonSizeProvider',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__297($boundThis) {
    this._$boundThis_9 = $boundThis;
  }
  _no_name_provided__297.prototype.invoke_551 = function (p0) {
    return this._$boundThis_9.color_0(p0);
  };
  _no_name_provided__297.prototype.invoke_1082 = function (p1) {
    return this.invoke_551(p1 instanceof ButtonState ? p1 : THROW_CCE());
  };
  _no_name_provided__297.prototype._get_name__47 = function () {
    return 'color';
  };
  _no_name_provided__297.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__298($boundThis) {
    this._$boundThis_10 = $boundThis;
  }
  _no_name_provided__298.prototype.invoke_551 = function (p0) {
    return this._$boundThis_10.size_0(p0);
  };
  _no_name_provided__298.prototype.invoke_1082 = function (p1) {
    return this.invoke_551(p1 instanceof ButtonState ? p1 : THROW_CCE());
  };
  _no_name_provided__298.prototype._get_name__47 = function () {
    return 'size';
  };
  _no_name_provided__298.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_281($boundThis) {
    var i = new _no_name_provided__297($boundThis);
    var l = function (p1) {
      return i.invoke_551(p1);
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_282($boundThis) {
    var i = new _no_name_provided__298($boundThis);
    var l = function (p1) {
      return i.invoke_551(p1);
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function defaultFillProvider$init$() {
    return _no_name_provided_$factory_281(new DefaultToolbarButtonFillProvider());
  }
  function defaultSizeProvider$init$() {
    return _no_name_provided_$factory_282(DefaultToolbarButtonSizeProvider_init_$Create$(null, null, 3, null));
  }
  var ObjectFit_None_instance;
  var ObjectFit_Fill_instance;
  var ObjectFit_Contain_instance;
  var ObjectFit_ContainNoGrow_instance;
  var ObjectFit_entriesInitialized;
  function ObjectFit_initEntries() {
    if (ObjectFit_entriesInitialized)
      return Unit_getInstance();
    ObjectFit_entriesInitialized = true;
    ObjectFit_None_instance = new ObjectFit('None', 0, true, false, false);
    ObjectFit_Fill_instance = new ObjectFit('Fill', 1, false, true, true);
    ObjectFit_Contain_instance = new ObjectFit('Contain', 2, true, true, true);
    ObjectFit_ContainNoGrow_instance = new ObjectFit('ContainNoGrow', 3, true, true, false);
  }
  function ObjectFit(name, ordinal, aspectRatioMaintained, mayBeScaledDown, mayBeScaledUp) {
    Enum.call(this, name, ordinal);
    this._aspectRatioMaintained = aspectRatioMaintained;
    this._mayBeScaledDown = mayBeScaledDown;
    this._mayBeScaledUp = mayBeScaledUp;
  }
  ObjectFit.$metadata$ = {
    simpleName: 'ObjectFit',
    kind: 'class',
    interfaces: []
  };
  function ObjectFit_None_getInstance() {
    ObjectFit_initEntries();
    return ObjectFit_None_instance;
  }
  function ObjectFit_Fill_getInstance() {
    ObjectFit_initEntries();
    return ObjectFit_Fill_instance;
  }
  function ObjectFit_Contain_getInstance() {
    ObjectFit_initEntries();
    return ObjectFit_Contain_instance;
  }
  function ObjectFit_ContainNoGrow_getInstance() {
    ObjectFit_initEntries();
    return ObjectFit_ContainNoGrow_instance;
  }
  function recalculateBoundingBox($this) {
    var tmp0_elvis_lhs = $this._sizeOverride_0;
    var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? $this._get_naturalSizeAdjusted_() : tmp0_elvis_lhs;
    return new Rectangle($this._alignmentPoint, tmp1_elvis_lhs == null ? Companion_getInstance_37()._none_1 : tmp1_elvis_lhs);
  }
  function Companion_49() {
    Companion_instance_48 = this;
  }
  Companion_49.prototype.naturalSize_0 = function (url, alignmentPoint) {
    return new UrlPaintable(url, null, alignmentPoint);
  };
  Companion_49.prototype.fixedSize = function (url, size, alignmentPoint) {
    return new UrlPaintable(url, size, alignmentPoint);
  };
  Companion_49.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_48;
  function Companion_getInstance_48() {
    if (Companion_instance_48 == null)
      new Companion_49();
    return Companion_instance_48;
  }
  function _no_name_provided__299(this$0) {
    this._this$0_212 = this$0;
  }
  _no_name_provided__299.prototype.invoke_623 = function (it) {
    this._this$0_212._image_0 = it;
    this._this$0_212._boundingBox = recalculateBoundingBox(this._this$0_212);
  };
  _no_name_provided__299.prototype.invoke_1082 = function (p1) {
    this.invoke_623(p1 instanceof Image ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__299.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function UrlPaintable(url, sizeOverride, alignmentPoint) {
    Companion_getInstance_48();
    this._url = url;
    this._sizeOverride_0 = sizeOverride;
    this._alignmentPoint = alignmentPoint;
    this._boundingBox = recalculateBoundingBox(this);
    this._image_0 = null;
    loadImage(this._url, _no_name_provided_$factory_283(this));
  }
  UrlPaintable.prototype.boundingBox_4 = function (chartSupport) {
    return this._boundingBox;
  };
  UrlPaintable.prototype._get_naturalSize_ = function () {
    var tmp0_safe_receiver = this._image_0;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._size_6;
  };
  UrlPaintable.prototype._get_naturalSizeAdjusted_ = function () {
    var tmp0_safe_receiver = this._get_naturalSize_();
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.divide(environment._devicePixelRatio);
  };
  UrlPaintable.prototype.paint_13 = function (paintingContext, x, y) {
    var tmp0_safe_receiver = this._image_0;
    if (tmp0_safe_receiver == null)
      null;
    else {
      if (this._sizeOverride_0 == null) {
        paintingContext._gc.paintImagePixelPerfect_1(tmp0_safe_receiver, x + this._alignmentPoint._x, y + this._alignmentPoint._y);
      } else {
        paintingContext._gc.paintImage_1(tmp0_safe_receiver, x + this._alignmentPoint._x, y + this._alignmentPoint._y, this._sizeOverride_0._width_0, this._sizeOverride_0._height);
      }
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  UrlPaintable.prototype.paintSizeForced_3 = function (paintingContext, x, y, forcedSize) {
    var tmp0_safe_receiver = this._image_0;
    if (tmp0_safe_receiver == null)
      null;
    else {
      paintingContext._gc.paintImage_1(tmp0_safe_receiver, x, y, forcedSize._width_0, forcedSize._height);
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  UrlPaintable.$metadata$ = {
    simpleName: 'UrlPaintable',
    kind: 'class',
    interfaces: [Paintable]
  };
  function _no_name_provided_$factory_283(this$0) {
    var i = new _no_name_provided__299(this$0);
    return function (p1) {
      i.invoke_623(p1);
      return Unit_getInstance();
    };
  }
  function AbstractCanvas(type) {
    this._type_7 = type;
    this._mouseCursor = new ObservableObject(MouseCursor_Default_getInstance());
    this._tooltip = new ObservableObject(null);
    this._mouseEvents = new DefaultMouseEventBroker();
    this._keyEvents = new DefaultKeyEventBroker();
    this._pointerEvents = new DefaultPointerEventBroker();
    this._touchEvents = new DefaultTouchEventBroker();
    this._nativeComponentsSupport = new NativeComponentsSupport();
  }
  AbstractCanvas.prototype._get_type__23 = function () {
    return this._type_7;
  };
  AbstractCanvas.prototype._get_mouseCursor__0 = function () {
    return this._mouseCursor;
  };
  AbstractCanvas.prototype._get_mouseEvents__1 = function () {
    return this._mouseEvents;
  };
  AbstractCanvas.prototype._get_keyEvents__1 = function () {
    return this._keyEvents;
  };
  AbstractCanvas.prototype._get_pointerEvents__1 = function () {
    return this._pointerEvents;
  };
  AbstractCanvas.prototype._get_touchEvents__1 = function () {
    return this._touchEvents;
  };
  AbstractCanvas.prototype._get_nativeComponentsSupport__1 = function () {
    return this._nativeComponentsSupport;
  };
  AbstractCanvas.prototype.requireMainCanvas_0 = function () {
    var tmp0_require_0 = this._get_type__23().equals(CanvasType_Main_getInstance());
    if (!tmp0_require_0) {
      var message_1_1 = 'Failed requirement.';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_1));
    }};
  AbstractCanvas.$metadata$ = {
    simpleName: 'AbstractCanvas',
    kind: 'class',
    interfaces: [Canvas]
  };
  function AbstractCanvasRenderingContext() {
    this._currentTransform = new CurrentTransform();
    this._debug = new DebugConfiguration();
    var tmp = this;
    tmp._delayedActions = ArrayList_init_$Create$();
  }
  AbstractCanvasRenderingContext.prototype.applyDefaults_1 = function () {
    CanvasRenderingContext.prototype.applyDefaults_1.call(this);
    this.resetTransform_1();
    this.scale_3(environment._devicePixelRatio, environment._devicePixelRatio);
  };
  AbstractCanvasRenderingContext.prototype.resetTransform_1 = function () {
    this._currentTransform.reset_3();
  };
  AbstractCanvasRenderingContext.prototype.save_3 = function () {
    this._currentTransform.save_3();
  };
  AbstractCanvasRenderingContext.prototype.restore_3 = function () {
    this._currentTransform.restore_3();
  };
  AbstractCanvasRenderingContext.prototype._get_debug__1 = function () {
    return this._debug;
  };
  AbstractCanvasRenderingContext.prototype._set_translation__0 = function (value) {
    var deltaX = value._x_0 - this._currentTransform._get_translationX__2();
    var deltaY = value._y_0 - this._currentTransform._get_translationY__2();
    this.translate_35(deltaX, deltaY);
  };
  AbstractCanvasRenderingContext.prototype._get_translation__1 = function () {
    return this._currentTransform._get_translation__1();
  };
  AbstractCanvasRenderingContext.prototype._get_translationX__2 = function () {
    return this._currentTransform._get_translationX__2();
  };
  AbstractCanvasRenderingContext.prototype._get_translationY__2 = function () {
    return this._currentTransform._get_translationY__2();
  };
  AbstractCanvasRenderingContext.prototype.translate_35 = function (deltaX, deltaY) {
    this._currentTransform.translate_35(deltaX, deltaY);
  };
  AbstractCanvasRenderingContext.prototype._set_scale__0 = function (value) {
    var deltaX = value._scaleX / this._currentTransform._get_scaleX__2();
    var deltaY = value._scaleY / this._currentTransform._get_scaleY__2();
    this.scale_3(deltaX, deltaY);
  };
  AbstractCanvasRenderingContext.prototype._get_scaleX__2 = function () {
    return this._currentTransform._get_scaleX__2();
  };
  AbstractCanvasRenderingContext.prototype._get_scaleY__2 = function () {
    return this._currentTransform._get_scaleX__2();
  };
  AbstractCanvasRenderingContext.prototype.scale_3 = function (x, y) {
    this._currentTransform.scale_3(x, y);
  };
  AbstractCanvasRenderingContext.$metadata$ = {
    simpleName: 'AbstractCanvasRenderingContext',
    kind: 'class',
    interfaces: [CanvasRenderingContext]
  };
  function Canvas() {
  }
  Canvas.prototype._get_width__41 = function () {
    return this._get_size__43()._width_0;
  };
  Canvas.prototype._get_height__41 = function () {
    return this._get_size__43()._height;
  };
  Canvas.$metadata$ = {
    simpleName: 'Canvas',
    kind: 'interface',
    interfaces: []
  };
  function CanvasFactory() {
  }
  CanvasFactory.prototype.createCanvas$default = function (type, size, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      size = Companion_getInstance_37()._zero_1;
    return $handler == null ? this.createCanvas_0(type, size) : $handler(type, size);
  };
  CanvasFactory.$metadata$ = {
    simpleName: 'CanvasFactory',
    kind: 'interface',
    interfaces: []
  };
  function CanvasRenderingContext() {
  }
  CanvasRenderingContext.prototype._get_boundingBox__1 = function () {
    return Rectangle_init_$Create$(0.0, 0.0, this._get_width__41(), this._get_height__41());
  };
  CanvasRenderingContext.prototype.applyDefaults_1 = function () {
    this._set_font__1(corporateDesign._get_textFont__1());
    this._set_lineJoin__1(LineJoin_Miter_getInstance());
  };
  CanvasRenderingContext.prototype.fillRect_4 = function (x, y, width, height, xType, yType) {
    var calculatedWidth = width;
    var calculatedX = x;
    if (width < 0.0) {
      calculatedWidth = -width;
      calculatedX = x - calculatedWidth;
    }if (xType.equals(LocationType_Center_getInstance())) {
      calculatedX = calculatedX - width / 2.0;
    }var calculatedHeight = height;
    var calculatedY = y;
    if (height < 0.0) {
      calculatedHeight = -height;
      calculatedY = y - calculatedHeight;
    }if (yType.equals(LocationType_Center_getInstance())) {
      calculatedY = calculatedY - height / 2.0;
    }this.fillRectInternal_1(calculatedX, calculatedY, calculatedWidth, calculatedHeight);
  };
  CanvasRenderingContext.prototype.fillRect$default_0 = function (x, y, width, height, xType, yType, $mask0, $handler) {
    if (!(($mask0 & 16) === 0))
      xType = LocationType_Origin_getInstance();
    if (!(($mask0 & 32) === 0))
      yType = LocationType_Origin_getInstance();
    return $handler == null ? this.fillRect_4(x, y, width, height, xType, yType) : $handler(x, y, width, height, xType, yType);
  };
  CanvasRenderingContext.prototype.fillRect_5 = function (bounds) {
    var tmp = bounds._get_left__32();
    var tmp_0 = bounds._get_top__32();
    var tmp_1 = bounds._get_widthAbs_();
    var tmp_2 = bounds._get_heightAbs_();
    this.fillRect$default_0(tmp, tmp_0, tmp_1, tmp_2, null, null, 48, null);
  };
  CanvasRenderingContext.prototype.strokeRect_2 = function (x, y, width, height, strokeLocation) {
    var calculatedWidth = width;
    var calculatedX = x;
    if (width < 0.0) {
      calculatedWidth = -width;
      calculatedX = x - calculatedWidth;
    }var calculatedHeight = height;
    var calculatedY = y;
    if (height < 0.0) {
      calculatedHeight = -height;
      calculatedY = y - calculatedHeight;
    }var tmp0_subject = strokeLocation;
    if (tmp0_subject.equals(StrokeLocation_Center_getInstance())) {
    } else if (tmp0_subject.equals(StrokeLocation_Inside_getInstance())) {
      var currentLineWidth = this._get_lineWidth__1();
      calculatedX = calculatedX + currentLineWidth / 2.0;
      calculatedY = calculatedY + currentLineWidth / 2.0;
      calculatedWidth = calculatedWidth - currentLineWidth;
      calculatedHeight = calculatedHeight - currentLineWidth;
    } else if (tmp0_subject.equals(StrokeLocation_Outside_getInstance())) {
      var currentLineWidth_0 = this._get_lineWidth__1();
      calculatedX = calculatedX - currentLineWidth_0 / 2.0;
      calculatedY = calculatedY - currentLineWidth_0 / 2.0;
      calculatedWidth = calculatedWidth + currentLineWidth_0;
      calculatedHeight = calculatedHeight + currentLineWidth_0;
    }this.strokeRectInternal_1(calculatedX, calculatedY, calculatedWidth, calculatedHeight);
  };
  CanvasRenderingContext.prototype.strokeRect$default_0 = function (x, y, width, height, strokeLocation, $mask0, $handler) {
    if (!(($mask0 & 16) === 0))
      strokeLocation = StrokeLocation_Center_getInstance();
    return $handler == null ? this.strokeRect_2(x, y, width, height, strokeLocation) : $handler(x, y, width, height, strokeLocation);
  };
  CanvasRenderingContext.prototype.fillText$default_0 = function (text_0, x, y, anchorDirection, gap, maxWidth, maxHeight, stringShortener, $mask0, $handler) {
    if (!(($mask0 & 16) === 0))
      gap = 0.0;
    if (!(($mask0 & 32) === 0))
      maxWidth = null;
    if (!(($mask0 & 64) === 0))
      maxHeight = null;
    if (!(($mask0 & 128) === 0))
      stringShortener = Companion_getInstance_49()._exactButSlowTruncateToLength;
    return $handler == null ? this.fillText_2(text_0, x, y, anchorDirection, gap, maxWidth, maxHeight, stringShortener) : $handler(text_0, x, y, anchorDirection, gap, maxWidth, maxHeight, stringShortener);
  };
  CanvasRenderingContext.prototype.font_2 = function (fontFragment) {
    if (fontFragment.isEmpty_33()) {
      return Unit_getInstance();
    }this._set_font__1(combineWith(this._get_font__1(), fontFragment));
  };
  CanvasRenderingContext.prototype.stroke_5 = function (color) {
    this.strokeStyle_2(color);
  };
  CanvasRenderingContext.prototype.fill_5 = function (color) {
    this.fillStyle_2(color);
  };
  CanvasRenderingContext.prototype.getFontAlignmentCorrection_0 = function (anchorDirection) {
    return this.getFontMetrics_1()._alignmentCorrectionInformation.get_33(anchorDirection._verticalAlignment);
  };
  CanvasRenderingContext.prototype.clear_27 = function () {
    this.clearRect_2(-this._get_translationX__2(), -this._get_translationY__2(), this._get_canvas__1()._get_physicalWidth__1() / this._get_scaleX__2(), this._get_canvas__1()._get_physicalHeight__1() / this._get_scaleY__2());
  };
  CanvasRenderingContext.$metadata$ = {
    simpleName: 'CanvasRenderingContext',
    kind: 'interface',
    interfaces: []
  };
  var LineJoin_Miter_instance;
  var LineJoin_Bevel_instance;
  var LineJoin_Round_instance;
  var LineJoin_entriesInitialized;
  function LineJoin_initEntries() {
    if (LineJoin_entriesInitialized)
      return Unit_getInstance();
    LineJoin_entriesInitialized = true;
    LineJoin_Miter_instance = new LineJoin('Miter', 0);
    LineJoin_Bevel_instance = new LineJoin('Bevel', 1);
    LineJoin_Round_instance = new LineJoin('Round', 2);
  }
  function LineJoin(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  LineJoin.$metadata$ = {
    simpleName: 'LineJoin',
    kind: 'class',
    interfaces: []
  };
  var LocationType_Origin_instance;
  var LocationType_Center_instance;
  var LocationType_entriesInitialized;
  function LocationType_initEntries() {
    if (LocationType_entriesInitialized)
      return Unit_getInstance();
    LocationType_entriesInitialized = true;
    LocationType_Origin_instance = new LocationType('Origin', 0);
    LocationType_Center_instance = new LocationType('Center', 1);
  }
  function LocationType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  LocationType.$metadata$ = {
    simpleName: 'LocationType',
    kind: 'class',
    interfaces: []
  };
  var StrokeLocation_Center_instance;
  var StrokeLocation_Inside_instance;
  var StrokeLocation_Outside_instance;
  var StrokeLocation_entriesInitialized;
  function StrokeLocation_initEntries() {
    if (StrokeLocation_entriesInitialized)
      return Unit_getInstance();
    StrokeLocation_entriesInitialized = true;
    StrokeLocation_Center_instance = new StrokeLocation('Center', 0);
    StrokeLocation_Inside_instance = new StrokeLocation('Inside', 1);
    StrokeLocation_Outside_instance = new StrokeLocation('Outside', 2);
  }
  function StrokeLocation(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  StrokeLocation.$metadata$ = {
    simpleName: 'StrokeLocation',
    kind: 'class',
    interfaces: []
  };
  function LineJoin_Miter_getInstance() {
    LineJoin_initEntries();
    return LineJoin_Miter_instance;
  }
  function LineJoin_Bevel_getInstance() {
    LineJoin_initEntries();
    return LineJoin_Bevel_instance;
  }
  function LineJoin_Round_getInstance() {
    LineJoin_initEntries();
    return LineJoin_Round_instance;
  }
  function LocationType_Origin_getInstance() {
    LocationType_initEntries();
    return LocationType_Origin_instance;
  }
  function LocationType_Center_getInstance() {
    LocationType_initEntries();
    return LocationType_Center_instance;
  }
  function StrokeLocation_Center_getInstance() {
    StrokeLocation_initEntries();
    return StrokeLocation_Center_instance;
  }
  function StrokeLocation_Inside_getInstance() {
    StrokeLocation_initEntries();
    return StrokeLocation_Inside_instance;
  }
  function StrokeLocation_Outside_getInstance() {
    StrokeLocation_initEntries();
    return StrokeLocation_Outside_instance;
  }
  function ExactButSlow(stringShortener) {
    this._stringShortener = stringShortener;
  }
  ExactButSlow.prototype.shorten_0 = function (text_0, maxWidth, gc) {
    if (gc.calculateTextWidth_1(text_0) <= maxWidth) {
      return text_0;
    }if (text_0.length <= 1) {
      return null;
    }var tmp0_genericBinarySearchLeft_0 = 0;
    var tmp1_genericBinarySearchLeft_0 = text_0.length;
    var tmp$ret$2;
    l$ret$3: do {
      var low_2_1 = tmp0_genericBinarySearchLeft_0;
      var high_3_2 = tmp1_genericBinarySearchLeft_0 - 1 | 0;
      while (low_2_1 <= high_3_2) {
        var mid_4_3 = (low_2_1 + high_3_2 | 0) / 2 | 0;
        var tmp$ret$0;
        l$ret$1: do {
          var tmp0_elvis_lhs_9 = this._stringShortener.shorten$default_0(text_0, mid_4_3, null, 4, null);
          var tmp;
          if (tmp0_elvis_lhs_9 == null) {
            tmp$ret$0 = -1;
            break l$ret$1;
          } else {
            tmp = tmp0_elvis_lhs_9;
          }
          var truncatedText_8 = tmp;
          var textWidth_10 = gc.calculateTextWidth_1(truncatedText_8);
          tmp$ret$0 = compareTo(textWidth_10, maxWidth);
        }
         while (false);
        var mval_5_4 = tmp$ret$0;
        if (mval_5_4 < 0)
          low_2_1 = mid_4_3 + 1 | 0;
        else if (mval_5_4 > 0)
          high_3_2 = mid_4_3 - 1 | 0;
        else {
          tmp$ret$2 = mid_4_3;
          break l$ret$3;
        }
      }
      var tmp0__anonymous__6_5 = low_2_1;
      var tmp1__anonymous__6_6 = high_3_2;
      tmp$ret$2 = coerceIn(function () {
        var $externalVarargReceiverTmp = Math;
        return $externalVarargReceiverTmp.min.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp0__anonymous__6_5, tmp1__anonymous__6_6]))));
      }.call(this), tmp0_genericBinarySearchLeft_0, tmp1_genericBinarySearchLeft_0 - 1 | 0);
    }
     while (false);
    var targetLength = tmp$ret$2;
    if (targetLength < 1) {
      return null;
    }return this._stringShortener.shorten$default_0(text_0, targetLength, null, 4, null);
  };
  ExactButSlow.$metadata$ = {
    simpleName: 'ExactButSlow',
    kind: 'class',
    interfaces: [CanvasStringShortener]
  };
  function Companion_50() {
    Companion_instance_49 = this;
    this._exactButSlowTruncateToLength = new ExactButSlow(TruncateToLength_getInstance());
  }
  Companion_50.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_49;
  function Companion_getInstance_49() {
    if (Companion_instance_49 == null)
      new Companion_50();
    return Companion_instance_49;
  }
  function CanvasStringShortener() {
  }
  CanvasStringShortener.$metadata$ = {
    simpleName: 'CanvasStringShortener',
    kind: 'interface',
    interfaces: []
  };
  var CanvasType_Main_instance;
  var CanvasType_OffScreen_instance;
  var CanvasType_entriesInitialized;
  function CanvasType_initEntries() {
    if (CanvasType_entriesInitialized)
      return Unit_getInstance();
    CanvasType_entriesInitialized = true;
    CanvasType_Main_instance = new CanvasType('Main', 0);
    CanvasType_OffScreen_instance = new CanvasType('OffScreen', 1);
  }
  function CanvasType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  CanvasType.$metadata$ = {
    simpleName: 'CanvasType',
    kind: 'class',
    interfaces: []
  };
  function CanvasType_Main_getInstance() {
    CanvasType_initEntries();
    return CanvasType_Main_instance;
  }
  function CanvasType_OffScreen_getInstance() {
    CanvasType_initEntries();
    return CanvasType_OffScreen_instance;
  }
  function Companion_51() {
    Companion_instance_50 = this;
    this._zero_2 = new ChartSizeClassification(SizeClassification_Zero_getInstance(), SizeClassification_Zero_getInstance());
  }
  Companion_51.prototype.get_30 = function (size) {
    return new ChartSizeClassification(Companion_getInstance_51().get_31(size._width_0), Companion_getInstance_51().get_31(size._height));
  };
  Companion_51.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_50;
  function Companion_getInstance_50() {
    if (Companion_instance_50 == null)
      new Companion_51();
    return Companion_instance_50;
  }
  function ChartSizeClassification(width, height) {
    Companion_getInstance_50();
    this._width_1 = width;
    this._height_0 = height;
  }
  ChartSizeClassification.prototype.toString = function () {
    return '' + 'ChartSizeClassification(width=' + this._width_1 + ', height=' + this._height_0 + ')';
  };
  ChartSizeClassification.prototype.hashCode = function () {
    var result = this._width_1.hashCode();
    result = imul(result, 31) + this._height_0.hashCode() | 0;
    return result;
  };
  ChartSizeClassification.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ChartSizeClassification))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof ChartSizeClassification ? other : THROW_CCE();
    if (!this._width_1.equals(tmp0_other_with_cast._width_1))
      return false;
    if (!this._height_0.equals(tmp0_other_with_cast._height_0))
      return false;
    return true;
  };
  ChartSizeClassification.$metadata$ = {
    simpleName: 'ChartSizeClassification',
    kind: 'class',
    interfaces: []
  };
  var SizeClassification_Zero_instance;
  var SizeClassification_XS_instance;
  var SizeClassification_S_instance;
  var SizeClassification_M_instance;
  var SizeClassification_L_instance;
  var SizeClassification_XL_instance;
  function Companion_52() {
    Companion_instance_51 = this;
  }
  Companion_52.prototype.get_31 = function (size) {
    var tmp0_fastForEach_0 = values_1();
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0.length;
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0[tmp0_3];
      if (tmp1__anonymous__4._min <= size ? tmp1__anonymous__4._max > size : false) {
        return tmp1__anonymous__4;
      }}
    throw IllegalArgumentException_init_$Create$('' + 'No size classification found for <' + size + '>');
  };
  Companion_52.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_51;
  function Companion_getInstance_51() {
    if (Companion_instance_51 == null)
      new Companion_52();
    return Companion_instance_51;
  }
  function values_1() {
    return [SizeClassification_Zero_getInstance(), SizeClassification_XS_getInstance(), SizeClassification_S_getInstance(), SizeClassification_M_getInstance(), SizeClassification_L_getInstance(), SizeClassification_XL_getInstance()];
  }
  var SizeClassification_entriesInitialized;
  function SizeClassification_initEntries() {
    if (SizeClassification_entriesInitialized)
      return Unit_getInstance();
    SizeClassification_entriesInitialized = true;
    SizeClassification_Zero_instance = new SizeClassification('Zero', 0, 0, 1);
    SizeClassification_XS_instance = new SizeClassification('XS', 1, 1, 576);
    SizeClassification_S_instance = new SizeClassification('S', 2, 576, 768);
    SizeClassification_M_instance = new SizeClassification('M', 3, 768, 992);
    SizeClassification_L_instance = new SizeClassification('L', 4, 992, 1200);
    SizeClassification_XL_instance = new SizeClassification('XL', 5, 1200, IntCompanionObject_getInstance()._MAX_VALUE);
    Companion_getInstance_51();
  }
  function SizeClassification(name, ordinal, min, max) {
    Enum.call(this, name, ordinal);
    this._min = min;
    this._max = max;
  }
  SizeClassification.$metadata$ = {
    simpleName: 'SizeClassification',
    kind: 'class',
    interfaces: []
  };
  function SizeClassification_Zero_getInstance() {
    SizeClassification_initEntries();
    return SizeClassification_Zero_instance;
  }
  function SizeClassification_XS_getInstance() {
    SizeClassification_initEntries();
    return SizeClassification_XS_instance;
  }
  function SizeClassification_S_getInstance() {
    SizeClassification_initEntries();
    return SizeClassification_S_instance;
  }
  function SizeClassification_M_getInstance() {
    SizeClassification_initEntries();
    return SizeClassification_M_instance;
  }
  function SizeClassification_L_getInstance() {
    SizeClassification_initEntries();
    return SizeClassification_L_instance;
  }
  function SizeClassification_XL_getInstance() {
    SizeClassification_initEntries();
    return SizeClassification_XL_instance;
  }
  var currentFrameTimestampOrNull;
  function sam$com_cedarsoft_charting_canvas_RefreshListener$0(function_0) {
    this._function_0 = function_0;
  }
  sam$com_cedarsoft_charting_canvas_RefreshListener$0.prototype.refresh_36 = function (chartSupport, frameTimestamp, refreshDelta) {
    return this._function_0(chartSupport, frameTimestamp, refreshDelta);
  };
  sam$com_cedarsoft_charting_canvas_RefreshListener$0.$metadata$ = {
    simpleName: 'sam$com_cedarsoft_charting_canvas_RefreshListener$0',
    kind: 'class',
    interfaces: [RefreshListener]
  };
  function _no_name_provided__300($tmp0_also_0) {
    this._$tmp0_also_0 = $tmp0_also_0;
  }
  _no_name_provided__300.prototype.invoke_1037 = function () {
    this._$tmp0_also_0.dispose_46();
  };
  _no_name_provided__300.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__300.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__301(this$0) {
    this._this$0_213 = this$0;
  }
  _no_name_provided__301.prototype.invoke_557 = function (_anonymous_parameter_0_, _anonymous_parameter_1_, _anonymous_parameter_2_) {
    updateEnvironment();
    _get_devicePixelRatioSupport_(this._this$0_213).updateDevicePixelRatio(environment._devicePixelRatio);
  };
  _no_name_provided__301.prototype.invoke_558 = function (p1, p2, p3) {
    var tmp = p1 instanceof ChartSupport ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE();
    this.invoke_557(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__301.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__302(this$0) {
    this._this$0_214 = this$0;
  }
  _no_name_provided__302.prototype.invoke_559 = function (it) {
    this._this$0_214.markAsDirty_4();
  };
  _no_name_provided__302.prototype.invoke_1082 = function (p1) {
    this.invoke_559((!(p1 == null) ? isInterface(p1, ObservableChartState) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__302.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__303(this$0) {
    this._this$0_215 = this$0;
  }
  _no_name_provided__303.prototype.invoke_771 = function (newValue) {
    if (newValue) {
      paintPaintDisabledText(this._this$0_215._canvas._get_gc__1());
    } else {
      this._this$0_215.markAsDirty_4();
    }
  };
  _no_name_provided__303.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__303.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ChartSupport(canvas, zoomAndPanModifier, zoomAndTranslationDefaults, zoomChangeFactor, windowSizeBindingStrategy) {
    this._canvas = canvas;
    this._disposeSupport_0 = new DisposeSupport();
    this._dirtySupport = new DirtySupport();
    this._disabledProperty = new ObservableBoolean(false);
    this._disabled$delegate_3 = this._disabledProperty;
    this._rootChartState = new DefaultChartState();
    this._currentChartState = this._rootChartState;
    this._chartCalculator_0 = new ChartCalculator(this._currentChartState);
    this._zoomAndTranslationSupport_0 = new ZoomAndTranslationSupport(this._get_chartCalculator__0(), this._rootChartState, zoomAndPanModifier, zoomAndTranslationDefaults, zoomChangeFactor);
    this._resizeBehaviorProperty = new ObservableObject(KeepCenterOnResize_getInstance());
    this._resizeBehavior$delegate = this._resizeBehaviorProperty;
    this._targetRefreshRateProperty = new ObservableObject(Companion_getInstance_61()._veryFast60);
    this._targetRefreshRate$delegate = this._targetRefreshRateProperty;
    var tmp = this;
    var tmp0_also_0 = new ServiceRegistry();
    this._disposeSupport_0.onDispose_10(_no_name_provided_$factory_284(tmp0_also_0));
    tmp._serviceRegistry = tmp0_also_0;
    this._mouseEvents_0 = this._canvas._get_mouseEvents__1();
    this._keyEvents_0 = this._canvas._get_keyEvents__1();
    this._pointerEvents_0 = this._canvas._get_pointerEvents__1();
    this._touchEvents_0 = this._canvas._get_touchEvents__1();
    var tmp_0 = this;
    tmp_0._refreshListeners = ArrayList_init_$Create$();
    var tmp_1 = this;
    tmp_1._refreshListenersToRemove = ArrayList_init_$Create$();
    var tmp_2 = this;
    tmp_2._paintListeners = ArrayList_init_$Create$();
    var tmp0_require_0 = this._canvas._get_type__23().equals(CanvasType_Main_getInstance());
    if (!tmp0_require_0) {
      var message_1 = 'A canvas with type main is required';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp_3 = _no_name_provided_$factory_285(this);
    this.onRefresh(new sam$com_cedarsoft_charting_canvas_RefreshListener$0(tmp_3));
    windowSizeBindingStrategy.bind_1(this._rootChartState, this._canvas, this._disposeSupport_0);
    this._rootChartState.onChange_1(_no_name_provided_$factory_286(this));
    registerDirtyListener(_get_pixelSnapSupport_(this)._snapConfigurationProperty, this);
    registerDirtyListener(_get_tooltipSupport_(this)._get_tooltip_(), this);
    this._disabledProperty.consume_3(false, _no_name_provided_$factory_287(this));
    Unit_getInstance();
    this.markAsDirty_4();
    this._lastRefreshTime = NaN;
    this._nextRefreshMinTime = 0.0;
    this._lastPaintTime = 0.0;
  }
  ChartSupport.prototype._get_disposed_ = function () {
    return this._disposeSupport_0._disposed;
  };
  ChartSupport.prototype._set_disabled__5 = function (_set___) {
    return this._disabled$delegate_3.setValue_10(this, disabled$factory_11(), _set___);
  };
  ChartSupport.prototype._get_disabled__5 = function () {
    return this._disabled$delegate_3.getValue_10(this, disabled$factory_12());
  };
  ChartSupport.prototype._get_chartCalculator__0 = function () {
    var tmp0_require_0 = equals_0(this._chartCalculator_0._chartState, this._currentChartState);
    if (!tmp0_require_0) {
      var message_1 = 'Uups';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }return this._chartCalculator_0;
  };
  ChartSupport.prototype._set_resizeBehavior_ = function (_set___) {
    return this._resizeBehavior$delegate.setValue_10(this, resizeBehavior$factory(), _set___);
  };
  ChartSupport.prototype._get_resizeBehavior_ = function () {
    return this._resizeBehavior$delegate.getValue_10(this, resizeBehavior$factory_0());
  };
  ChartSupport.prototype._set_targetRefreshRate_ = function (_set___) {
    return this._targetRefreshRate$delegate.setValue_10(this, targetRefreshRate$factory(), _set___);
  };
  ChartSupport.prototype._get_targetRefreshRate_ = function () {
    return this._targetRefreshRate$delegate.getValue_10(this, targetRefreshRate$factory_0());
  };
  ChartSupport.prototype.markAsDirty_4 = function () {
    this._dirtySupport.markAsDirty_4();
  };
  ChartSupport.prototype.refresh_35 = function (frameTimestamp) {
    var tmp0_require_0 = !this._disposeSupport_0._disposed;
    if (!tmp0_require_0) {
      var message_1 = 'Already disposed! Do not paint anymore';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }if (this._get_disabled__5()) {
      return Unit_getInstance();
    }if (this._nextRefreshMinTime > frameTimestamp) {
      return Unit_getInstance();
    }var tmp0_safe_receiver = this._get_targetRefreshRate_()._distance;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp0_this_2 = this;
      tmp0_this_2._nextRefreshMinTime = tmp0_this_2._nextRefreshMinTime + tmp0_safe_receiver;
      if (this._nextRefreshMinTime < frameTimestamp) {
        this._nextRefreshMinTime = frameTimestamp + tmp0_safe_receiver * 0.5;
      }Unit_getInstance();
    }
    Unit_getInstance();
    var tmp;
    if (isNaN_0(this._lastRefreshTime)) {
      tmp = NaN;
    } else {
      tmp = frameTimestamp - this._lastRefreshTime;
    }
    var refreshDelta = tmp;
    this._lastRefreshTime = frameTimestamp;
    var tmp1_isNotEmpty_0 = this._refreshListenersToRemove;
    if (!tmp1_isNotEmpty_0.isEmpty_33()) {
      this._refreshListeners.removeAll_2(this._refreshListenersToRemove);
      Unit_getInstance();
      this._refreshListenersToRemove.clear_27();
    } else {
    }
    currentFrameTimestampOrNull = frameTimestamp;
    try {
      var tmp2_fastForEach_0 = this._refreshListeners;
      var n_1 = 0;
      var currentSize_2 = tmp2_fastForEach_0._get_size__43();
      while (n_1 < currentSize_2) {
        var tmp0_3 = n_1;
        n_1 = tmp0_3 + 1 | 0;
        var tmp3__anonymous__4 = tmp2_fastForEach_0.get_44(tmp0_3);
        tmp3__anonymous__4.refresh_36(this, frameTimestamp, refreshDelta);
      }
      var tmp$ret$0;
      l$ret$1: do {
        var tmp4_ifDirty_0 = this._dirtySupport;
        if (!tmp4_ifDirty_0._dirty) {
          tmp$ret$0 = Unit_getInstance();
          break l$ret$1;
        }tmp4_ifDirty_0.clearIsDirty();
        var tmp$ret$2;
        l$ret$3: do {
          if (this._canvas._get_width__41() <= 0.0 ? true : this._canvas._get_height__41() <= 0.0) {
            tmp$ret$2 = Unit_getInstance();
            break l$ret$3;
          }if (this._currentChartState._get_hasZeroSize__3()) {
            tmp$ret$2 = Unit_getInstance();
            break l$ret$3;
          }this._canvas._get_gc__1().applyDefaults_1();
          var tmp0_saved_0_2 = this._canvas._get_gc__1();
          tmp0_saved_0_2.save_3();
          try {
            var repaintDelta_2_3 = this._lastPaintTime === 0.0 ? 0.0 : frameTimestamp - this._lastPaintTime;
            this._lastPaintTime = frameTimestamp;
            var inductionVariable = 0;
            var last_1 = this._paintListeners._get_size__43();
            if (inductionVariable < last_1)
              do {
                var i_4_5 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                this._paintListeners.get_44(i_4_5).paint_8(frameTimestamp, repaintDelta_2_3);
              }
               while (inductionVariable < last_1);
          }finally {
            tmp0_saved_0_2.restore_3();
          }
        }
         while (false);
      }
       while (false);
    }finally {
      currentFrameTimestampOrNull = null;
    }
  };
  ChartSupport.prototype.dispose_46 = function () {
    this._disposeSupport_0.dispose_46();
  };
  ChartSupport.prototype.onDispose_10 = function (action) {
    this._disposeSupport_0.onDispose_10(action);
  };
  ChartSupport.prototype.onPaint = function (paintListener) {
    this._paintListeners.add_57(paintListener);
    Unit_getInstance();
  };
  ChartSupport.prototype.onRefresh = function (refreshListener) {
    this._refreshListeners.add_57(refreshListener);
    Unit_getInstance();
  };
  ChartSupport.$metadata$ = {
    simpleName: 'ChartSupport',
    kind: 'class',
    interfaces: [Disposable, OnDispose]
  };
  function RefreshListener() {
  }
  RefreshListener.$metadata$ = {
    simpleName: 'RefreshListener',
    kind: 'interface',
    interfaces: []
  };
  function PaintListener() {
  }
  PaintListener.$metadata$ = {
    simpleName: 'PaintListener',
    kind: 'interface',
    interfaces: []
  };
  function _get_devicePixelRatioSupport_(_this_) {
    var tmp = getKClass_0(DevicePixelRatioSupport);
    return _this_._serviceRegistry.get_34(tmp, _no_name_provided_$factory_288(_this_));
  }
  function registerDirtyListener(_this_, chartSupport) {
    _this_.consume$default_3(false, _no_name_provided_$factory_289(chartSupport), 1, null);
    Unit_getInstance();
  }
  function _get_pixelSnapSupport_(_this_) {
    var tmp = getKClass_0(PixelSnapSupport);
    return _this_._serviceRegistry.get_34(tmp, _no_name_provided_$factory_290());
  }
  function _get_tooltipSupport_(_this_) {
    var tmp = getKClass_0(TooltipSupport);
    return _this_._serviceRegistry.get_34(tmp, _no_name_provided_$factory_291());
  }
  function paintPaintDisabledText(gc) {
    gc.fillStyle_2(Companion_getInstance_29()._lightgray);
    var tmp = gc._get_width__41();
    var tmp_0 = gc._get_height__41();
    gc.fillRect$default_0(0.0, 0.0, tmp, tmp_0, null, null, 48, null);
    gc.fillStyle_2(Companion_getInstance_29()._darkgray);
    var tmp_1 = Direction_TopLeft_getInstance();
    gc.fillText$default_0('Painting is disabled', 0.0, 0.0, tmp_1, 0.0, null, null, null, 240, null);
  }
  function _get_i18nSupport_(_this_) {
    var tmp = getKClass_0(I18nSupport);
    return _this_._serviceRegistry.get_34(tmp, _no_name_provided_$factory_292());
  }
  function _get_debug_(_this_) {
    return _this_._canvas._get_gc__1()._get_debug__1();
  }
  function resetZoomAndTranslationToDefaults(_this_) {
    _this_._zoomAndTranslationSupport_0.resetToDefaults$default(null, 1, null);
  }
  function _get_layerSupport_(_this_) {
    var tmp = getKClass_0(LayerSupport);
    return _this_._serviceRegistry.get_34(tmp, _no_name_provided_$factory_293(_this_));
  }
  function _get_nativeComponentsSupport_(_this_) {
    return _this_._canvas._get_nativeComponentsSupport__1();
  }
  function _get_textService_(_this_) {
    var tmp = getKClass_0(TextService);
    return _this_._serviceRegistry.get_34(tmp, _no_name_provided_$factory_294());
  }
  function _get_devicePixelRatio_(_this_) {
    return _get_devicePixelRatioSupport_(_this_)._get_devicePixelRatio_();
  }
  function _no_name_provided__304($this_devicePixelRatioSupport) {
    this._$this_devicePixelRatioSupport = $this_devicePixelRatioSupport;
  }
  _no_name_provided__304.prototype.invoke_1038 = function () {
    var tmp0_apply_0 = new DevicePixelRatioSupport();
    registerDirtyListener(tmp0_apply_0._devicePixelRatioProperty, this._$this_devicePixelRatioSupport);
    return tmp0_apply_0;
  };
  _no_name_provided__304.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__305($chartSupport) {
    this._$chartSupport = $chartSupport;
  }
  _no_name_provided__305.prototype.invoke_575 = function (it) {
    this._$chartSupport.markAsDirty_4();
  };
  _no_name_provided__305.prototype.invoke_1082 = function (p1) {
    this.invoke_575((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__305.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__306() {
  }
  _no_name_provided__306.prototype.invoke_1038 = function () {
    return new PixelSnapSupport();
  };
  _no_name_provided__306.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__307() {
  }
  _no_name_provided__307.prototype.invoke_1038 = function () {
    return TooltipSupport_init_$Create$(null, 1, null);
  };
  _no_name_provided__307.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__308() {
  }
  _no_name_provided__308.prototype.invoke_1038 = function () {
    return new I18nSupport();
  };
  _no_name_provided__308.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__309($this_layerSupport) {
    this._$this_layerSupport = $this_layerSupport;
  }
  _no_name_provided__309.prototype.invoke_1038 = function () {
    return new DefaultLayerSupport(this._$this_layerSupport);
  };
  _no_name_provided__309.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__310() {
  }
  _no_name_provided__310.prototype.invoke_1038 = function () {
    var tmp0_also_0 = new TextService();
    addFallbackTextResolver(tmp0_also_0);
    return tmp0_also_0;
  };
  _no_name_provided__310.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function disabled$factory_11() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function disabled$factory_12() {
    return getPropertyCallableRef('disabled', 1, KMutableProperty1, function (receiver) {
      return receiver._get_disabled__5();
    }, function (receiver_0, value) {
      return receiver_0._set_disabled__5(value);
    });
  }
  function resizeBehavior$factory() {
    return getPropertyCallableRef('resizeBehavior', 1, KMutableProperty1, function (receiver) {
      return receiver._get_resizeBehavior_();
    }, function (receiver_0, value) {
      return receiver_0._set_resizeBehavior_(value);
    });
  }
  function resizeBehavior$factory_0() {
    return getPropertyCallableRef('resizeBehavior', 1, KMutableProperty1, function (receiver) {
      return receiver._get_resizeBehavior_();
    }, function (receiver_0, value) {
      return receiver_0._set_resizeBehavior_(value);
    });
  }
  function targetRefreshRate$factory() {
    return getPropertyCallableRef('targetRefreshRate', 1, KMutableProperty1, function (receiver) {
      return receiver._get_targetRefreshRate_();
    }, function (receiver_0, value) {
      return receiver_0._set_targetRefreshRate_(value);
    });
  }
  function targetRefreshRate$factory_0() {
    return getPropertyCallableRef('targetRefreshRate', 1, KMutableProperty1, function (receiver) {
      return receiver._get_targetRefreshRate_();
    }, function (receiver_0, value) {
      return receiver_0._set_targetRefreshRate_(value);
    });
  }
  function _no_name_provided_$factory_284($tmp0_also_0) {
    var i = new _no_name_provided__300($tmp0_also_0);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_285(this$0) {
    var i = new _no_name_provided__301(this$0);
    return function (p1, p2, p3) {
      i.invoke_557(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_286(this$0) {
    var i = new _no_name_provided__302(this$0);
    return function (p1) {
      i.invoke_559(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_287(this$0) {
    var i = new _no_name_provided__303(this$0);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_288($this_devicePixelRatioSupport) {
    var i = new _no_name_provided__304($this_devicePixelRatioSupport);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_289($chartSupport) {
    var i = new _no_name_provided__305($chartSupport);
    return function (p1) {
      i.invoke_575(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_290() {
    var i = new _no_name_provided__306();
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_291() {
    var i = new _no_name_provided__307();
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_292() {
    var i = new _no_name_provided__308();
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_293($this_layerSupport) {
    var i = new _no_name_provided__309($this_layerSupport);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_294() {
    var i = new _no_name_provided__310();
    return function () {
      return i.invoke_1038();
    };
  }
  function ContentAreaSizingStrategy() {
  }
  ContentAreaSizingStrategy.$metadata$ = {
    simpleName: 'ContentAreaSizingStrategy',
    kind: 'interface',
    interfaces: [Disposable]
  };
  function BindContentArea2WindowSize_init_$Init$($this) {
    BindContentArea2WindowSize.call($this, null);
    return $this;
  }
  function BindContentArea2WindowSize_init_$Create$() {
    return BindContentArea2WindowSize_init_$Init$(Object.create(BindContentArea2WindowSize.prototype));
  }
  function _no_name_provided__311($marginProperty) {
    this._$marginProperty = $marginProperty;
  }
  _no_name_provided__311.prototype.invoke_577 = function (chartState) {
    var tmp0_safe_receiver = this._$marginProperty;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__18();
    var margin = tmp1_elvis_lhs == null ? Companion_getInstance_35()._empty : tmp1_elvis_lhs;
    return chartState._get_windowSize__4().minus_0(margin._offsetWidth, margin._offsetHeight).coerceAtLeast(Companion_getInstance_37()._zero_1);
  };
  _no_name_provided__311.prototype.invoke_1082 = function (p1) {
    return this.invoke_577((!(p1 == null) ? isInterface(p1, ChartState) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__311.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function BindContentArea2WindowSize(marginProperty) {
    var tmp = _no_name_provided_$factory_295(marginProperty);
    var tmp_0;
    if (!(marginProperty == null)) {
      var tmp0_arrayOf_0_1 = [marginProperty];
      tmp_0 = tmp0_arrayOf_0_1;
    } else {
      tmp_0 = [];
    }
    AbstractContentAreaSizingStrategy.call(this, tmp, tmp_0.slice());
    this._marginProperty = marginProperty;
  }
  BindContentArea2WindowSize.$metadata$ = {
    simpleName: 'BindContentArea2WindowSize',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__312(this$0, $chartState, $chartSupport) {
    this._this$0_216 = this$0;
    this._$chartState = $chartState;
    this._$chartSupport_0 = $chartSupport;
  }
  _no_name_provided__312.prototype.invoke_573 = function (oldWindowSize, newWindowSize) {
    this._this$0_216.recalculate_1(this._$chartState, oldWindowSize, newWindowSize, this._$chartSupport_0);
  };
  _no_name_provided__312.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Size ? p1 : THROW_CCE();
    this.invoke_573(tmp, p2 instanceof Size ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__312.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__313($chartSupport, this$0, $chartState) {
    this._$chartSupport_1 = $chartSupport;
    this._this$0_217 = this$0;
    this._$chartState_0 = $chartState;
  }
  _no_name_provided__313.prototype.invoke_575 = function (it) {
    var windowSize_6 = this._$chartSupport_1._rootChartState._get_windowSize__4();
    this._this$0_217.recalculate_1(this._$chartState_0, windowSize_6, windowSize_6, this._$chartSupport_1);
  };
  _no_name_provided__313.prototype.invoke_1082 = function (p1) {
    this.invoke_575((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__313.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractContentAreaSizingStrategy(contentAreaSizeCalculator, dependencies) {
    this._contentAreaSizeCalculator = contentAreaSizeCalculator;
    this._dependencies = dependencies;
    this._disposeSupport_1 = new DisposeSupport();
  }
  AbstractContentAreaSizingStrategy.prototype.bindResize_2 = function (chartSupport) {
    var chartState = chartSupport._rootChartState;
    var oldContentAreaSize_2 = chartState._get_contentAreaSize__4();
    chartState._set_contentAreaSize__1(this._contentAreaSizeCalculator(chartState));
    chartSupport._get_resizeBehavior_().handleResize_1(chartSupport._zoomAndTranslationSupport_0, new ResizeEvent(chartState._get_windowSize__4(), chartState._get_windowSize__4(), oldContentAreaSize_2, chartState._get_contentAreaSize__4()));
    var tmp = chartState._get_windowSizeProperty__1();
    tmp.consumeChanges_1(_no_name_provided_$factory_296(this, chartState, chartSupport));
    Unit_getInstance();
    var tmp0_forEach_0 = this._dependencies;
    var indexedObject = tmp0_forEach_0;
    var inductionVariable = 0;
    var last_1 = indexedObject.length;
    while (inductionVariable < last_1) {
      var element_2 = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp0_also_0_4 = element_2.consume$default_3(false, _no_name_provided_$factory_297(chartSupport, this, chartState), 1, null);
      this._disposeSupport_1.onDispose_9(tmp0_also_0_4);
      Unit_getInstance();
    }
  };
  AbstractContentAreaSizingStrategy.prototype.dispose_46 = function () {
    this._disposeSupport_1.dispose_46();
  };
  AbstractContentAreaSizingStrategy.prototype.recalculate_1 = function (chartState, oldWindowSize, newWindowSize, chartSupport) {
    var oldContentAreaSize = chartState._get_contentAreaSize__4();
    var newContentAreaSize = this._contentAreaSizeCalculator(chartState);
    if (newContentAreaSize.equals(chartState._get_contentAreaSize__4()) ? oldWindowSize.equals(newWindowSize) : false) {
      return Unit_getInstance();
    }chartState._set_contentAreaSize__1(newContentAreaSize);
    chartSupport._get_resizeBehavior_().handleResize_1(chartSupport._zoomAndTranslationSupport_0, new ResizeEvent(oldWindowSize, newWindowSize, oldContentAreaSize, newContentAreaSize));
  };
  AbstractContentAreaSizingStrategy.$metadata$ = {
    simpleName: 'AbstractContentAreaSizingStrategy',
    kind: 'class',
    interfaces: [ContentAreaSizingStrategy]
  };
  function _no_name_provided__314($contentAreaSizeProperty) {
    this._$contentAreaSizeProperty = $contentAreaSizeProperty;
  }
  _no_name_provided__314.prototype.invoke_577 = function (_anonymous_parameter_0_) {
    return this._$contentAreaSizeProperty.get_28();
  };
  _no_name_provided__314.prototype.invoke_1082 = function (p1) {
    return this.invoke_577((!(p1 == null) ? isInterface(p1, ChartState) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__314.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function BindContentArea2Property(contentAreaSizeProperty) {
    AbstractContentAreaSizingStrategy.call(this, _no_name_provided_$factory_298(contentAreaSizeProperty), [contentAreaSizeProperty]);
    this._contentAreaSizeProperty_0 = contentAreaSizeProperty;
  }
  BindContentArea2Property.$metadata$ = {
    simpleName: 'BindContentArea2Property',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_295($marginProperty) {
    var i = new _no_name_provided__311($marginProperty);
    return function (p1) {
      return i.invoke_577(p1);
    };
  }
  function _no_name_provided_$factory_296(this$0, $chartState, $chartSupport) {
    var i = new _no_name_provided__312(this$0, $chartState, $chartSupport);
    return function (p1, p2) {
      i.invoke_573(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_297($chartSupport, this$0, $chartState) {
    var i = new _no_name_provided__313($chartSupport, this$0, $chartState);
    return function (p1) {
      i.invoke_575(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_298($contentAreaSizeProperty) {
    var i = new _no_name_provided__314($contentAreaSizeProperty);
    return function (p1) {
      return i.invoke_577(p1);
    };
  }
  function Companion_53() {
    Companion_instance_52 = this;
    this._SaveRestoreStackSize = 10;
  }
  Companion_53.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_52;
  function Companion_getInstance_52() {
    if (Companion_instance_52 == null)
      new Companion_53();
    return Companion_instance_52;
  }
  function CurrentTransform() {
    Companion_getInstance_52();
    this._stackDepth = 0;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = 10;
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      tmp_2[tmp_3] = Matrix_init_$Create$(0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 63, null);
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp._matrices = tmp_2;
  }
  CurrentTransform.prototype._get_matrix_ = function () {
    return this._matrices[this._stackDepth];
  };
  CurrentTransform.prototype._get_translation__1 = function () {
    return Companion_getInstance_34().of_4(this._get_matrix_()._tx, this._get_matrix_()._ty);
  };
  CurrentTransform.prototype._set_translationX_ = function (value) {
    this._get_matrix_()._tx = value;
  };
  CurrentTransform.prototype._get_translationX__2 = function () {
    return this._get_matrix_()._tx;
  };
  CurrentTransform.prototype._set_translationY_ = function (value) {
    this._get_matrix_()._ty = value;
  };
  CurrentTransform.prototype._get_translationY__2 = function () {
    return this._get_matrix_()._ty;
  };
  CurrentTransform.prototype._set_scaleX_ = function (value) {
    this._get_matrix_()._a = value;
  };
  CurrentTransform.prototype._get_scaleX__2 = function () {
    return this._get_matrix_()._a;
  };
  CurrentTransform.prototype._set_scaleY_ = function (value) {
    this._get_matrix_()._d = value;
  };
  CurrentTransform.prototype._get_scaleY__2 = function () {
    return this._get_matrix_()._d;
  };
  CurrentTransform.prototype.reset_3 = function () {
    this._get_matrix_().reset_1();
    Unit_getInstance();
  };
  CurrentTransform.prototype.save_3 = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._stackDepth;
    tmp0_this._stackDepth = tmp1 + 1 | 0;
    Unit_getInstance();
    var tmp0_check_0 = this._stackDepth < 10;
    if (!tmp0_check_0) {
      var message_1 = '' + 'Saved too deep:: ' + this._stackDepth;
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }this._get_matrix_().copyFrom(this._matrices[this._stackDepth - 1 | 0]);
    Unit_getInstance();
  };
  CurrentTransform.prototype.restore_3 = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._stackDepth;
    tmp0_this._stackDepth = tmp1 - 1 | 0;
    Unit_getInstance();
    var tmp0_check_0 = this._stackDepth >= 0;
    if (!tmp0_check_0) {
      var message_1 = '' + 'Invalid stack depth: ' + this._stackDepth;
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }};
  CurrentTransform.prototype.translate_35 = function (deltaX, deltaY) {
    var tmp0_this = this;
    tmp0_this._set_translationX_(tmp0_this._get_translationX__2() + deltaX * this._get_scaleX__2());
    var tmp1_this = this;
    tmp1_this._set_translationY_(tmp1_this._get_translationY__2() + deltaY * this._get_scaleY__2());
  };
  CurrentTransform.prototype.scale_3 = function (x, y) {
    var tmp0_this = this;
    tmp0_this._set_scaleX_(tmp0_this._get_scaleX__2() * x);
    var tmp1_this = this;
    tmp1_this._set_scaleY_(tmp1_this._get_scaleY__2() * y);
  };
  CurrentTransform.$metadata$ = {
    simpleName: 'CurrentTransform',
    kind: 'class',
    interfaces: []
  };
  function DebugConfiguration() {
    this._enabledFeaturesProperty = new ObservableObject(emptySet());
    this._enabledFeatures$delegate = this._enabledFeaturesProperty;
  }
  DebugConfiguration.prototype._get_enabledFeatures_ = function () {
    return this._enabledFeatures$delegate.getValue_10(this, enabledFeatures$factory());
  };
  DebugConfiguration.prototype.get_32 = function (feature) {
    return this._get_enabledFeatures_().contains_31(feature);
  };
  DebugConfiguration.prototype.setAll = function (enabled) {
    if (enabled) {
      this._enabledFeaturesProperty._set_value__12(toSet(values_2()));
    } else {
      this._enabledFeaturesProperty._set_value__12(emptySet());
    }
  };
  DebugConfiguration.prototype.toggle_0 = function () {
    this.setAll(this._get_enabledFeatures_().isEmpty_33());
  };
  DebugConfiguration.$metadata$ = {
    simpleName: 'DebugConfiguration',
    kind: 'class',
    interfaces: []
  };
  var DebugFeature_ShowBounds_instance;
  var DebugFeature_ShowValues_instance;
  var DebugFeature_UnShortenedTexts_instance;
  var DebugFeature_ShowMaxTextWidth_instance;
  var DebugFeature_OverlappingTexts_instance;
  var DebugFeature_TilesDebug_instance;
  var DebugFeature_HistoryGaps_instance;
  function values_2() {
    return [DebugFeature_ShowBounds_getInstance(), DebugFeature_ShowValues_getInstance(), DebugFeature_UnShortenedTexts_getInstance(), DebugFeature_ShowMaxTextWidth_getInstance(), DebugFeature_OverlappingTexts_getInstance(), DebugFeature_TilesDebug_getInstance(), DebugFeature_HistoryGaps_getInstance()];
  }
  var DebugFeature_entriesInitialized;
  function DebugFeature_initEntries() {
    if (DebugFeature_entriesInitialized)
      return Unit_getInstance();
    DebugFeature_entriesInitialized = true;
    DebugFeature_ShowBounds_instance = new DebugFeature('ShowBounds', 0);
    DebugFeature_ShowValues_instance = new DebugFeature('ShowValues', 1);
    DebugFeature_UnShortenedTexts_instance = new DebugFeature('UnShortenedTexts', 2);
    DebugFeature_ShowMaxTextWidth_instance = new DebugFeature('ShowMaxTextWidth', 3);
    DebugFeature_OverlappingTexts_instance = new DebugFeature('OverlappingTexts', 4);
    DebugFeature_TilesDebug_instance = new DebugFeature('TilesDebug', 5);
    DebugFeature_HistoryGaps_instance = new DebugFeature('HistoryGaps', 6);
  }
  function DebugFeature(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  DebugFeature.$metadata$ = {
    simpleName: 'DebugFeature',
    kind: 'class',
    interfaces: []
  };
  function DebugFeature_ShowBounds_getInstance() {
    DebugFeature_initEntries();
    return DebugFeature_ShowBounds_instance;
  }
  function DebugFeature_ShowValues_getInstance() {
    DebugFeature_initEntries();
    return DebugFeature_ShowValues_instance;
  }
  function DebugFeature_UnShortenedTexts_getInstance() {
    DebugFeature_initEntries();
    return DebugFeature_UnShortenedTexts_instance;
  }
  function DebugFeature_ShowMaxTextWidth_getInstance() {
    DebugFeature_initEntries();
    return DebugFeature_ShowMaxTextWidth_instance;
  }
  function DebugFeature_OverlappingTexts_getInstance() {
    DebugFeature_initEntries();
    return DebugFeature_OverlappingTexts_instance;
  }
  function DebugFeature_TilesDebug_getInstance() {
    DebugFeature_initEntries();
    return DebugFeature_TilesDebug_instance;
  }
  function DebugFeature_HistoryGaps_getInstance() {
    DebugFeature_initEntries();
    return DebugFeature_HistoryGaps_instance;
  }
  function enabledFeatures$factory() {
    return getPropertyCallableRef('enabledFeatures', 1, KProperty1, function (receiver) {
      return receiver._get_enabledFeatures_();
    }, null);
  }
  function _no_name_provided__315($event, this$0) {
    this._$event = $event;
    this._this$0_218 = this$0;
  }
  _no_name_provided__315.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_mouseEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onClick_8(this._$event, this._this$0_218._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event._get_consumed__21();
  };
  _no_name_provided__315.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__315.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__316($event, this$0) {
    this._$event_0 = $event;
    this._this$0_219 = this$0;
  }
  _no_name_provided__316.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_mouseEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onDown_20(this._$event_0, this._this$0_219._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_0._get_consumed__21();
  };
  _no_name_provided__316.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__316.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__317($event, this$0) {
    this._$event_1 = $event;
    this._this$0_220 = this$0;
  }
  _no_name_provided__317.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_mouseEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onUp_20(this._$event_1, this._this$0_220._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_1._get_consumed__21();
  };
  _no_name_provided__317.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__317.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__318($event, this$0) {
    this._$event_2 = $event;
    this._this$0_221 = this$0;
  }
  _no_name_provided__318.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_mouseEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onDoubleClick_8(this._$event_2, this._this$0_221._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_2._get_consumed__21();
  };
  _no_name_provided__318.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__318.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__319($event, this$0) {
    this._$event_3 = $event;
    this._this$0_222 = this$0;
  }
  _no_name_provided__319.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_mouseEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onMove_20(this._$event_3, this._this$0_222._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_3._get_consumed__21();
  };
  _no_name_provided__319.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__319.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__320($event, this$0) {
    this._$event_4 = $event;
    this._this$0_223 = this$0;
  }
  _no_name_provided__320.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_mouseEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onDrag_13(this._$event_4, this._this$0_223._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_4._get_consumed__21();
  };
  _no_name_provided__320.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__320.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__321($event, this$0) {
    this._$event_5 = $event;
    this._this$0_224 = this$0;
  }
  _no_name_provided__321.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_mouseEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onWheel_7(this._$event_5, this._this$0_224._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_5._get_consumed__21();
  };
  _no_name_provided__321.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__321.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__322($event, this$0) {
    this._$event_6 = $event;
    this._this$0_225 = this$0;
  }
  _no_name_provided__322.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_keyEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onDown_21(this._$event_6, this._this$0_225._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_6._get_consumed__21();
  };
  _no_name_provided__322.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__322.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__323($event, this$0) {
    this._$event_7 = $event;
    this._this$0_226 = this$0;
  }
  _no_name_provided__323.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_keyEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onUp_21(this._$event_7, this._this$0_226._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_7._get_consumed__21();
  };
  _no_name_provided__323.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__323.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__324($event, this$0) {
    this._$event_8 = $event;
    this._this$0_227 = this$0;
  }
  _no_name_provided__324.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_keyEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onType_5(this._$event_8, this._this$0_227._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_8._get_consumed__21();
  };
  _no_name_provided__324.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__324.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__325($event, this$0) {
    this._$event_9 = $event;
    this._this$0_228 = this$0;
  }
  _no_name_provided__325.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_touchEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onStart_3(this._$event_9, this._this$0_228._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_9._get_consumed__21();
  };
  _no_name_provided__325.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__325.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__326($event, this$0) {
    this._$event_10 = $event;
    this._this$0_229 = this$0;
  }
  _no_name_provided__326.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_touchEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onEnd_1(this._$event_10, this._this$0_229._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_10._get_consumed__21();
  };
  _no_name_provided__326.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__326.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__327($event, this$0) {
    this._$event_11 = $event;
    this._this$0_230 = this$0;
  }
  _no_name_provided__327.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_touchEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onMove_8(this._$event_11, this._this$0_230._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_11._get_consumed__21();
  };
  _no_name_provided__327.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__327.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__328($event, this$0) {
    this._$event_12 = $event;
    this._this$0_231 = this$0;
  }
  _no_name_provided__328.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_touchEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onCancel_4(this._$event_12, this._this$0_231._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_12._get_consumed__21();
  };
  _no_name_provided__328.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__328.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__329($event, this$0) {
    this._$event_13 = $event;
    this._this$0_232 = this$0;
  }
  _no_name_provided__329.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_pointerEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onOver_5(this._$event_13, this._this$0_232._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_13._get_consumed__21();
  };
  _no_name_provided__329.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__329.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__330($event, this$0) {
    this._$event_14 = $event;
    this._this$0_233 = this$0;
  }
  _no_name_provided__330.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_pointerEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onEnter_5(this._$event_14, this._this$0_233._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_14._get_consumed__21();
  };
  _no_name_provided__330.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__330.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__331($event, this$0) {
    this._$event_15 = $event;
    this._this$0_234 = this$0;
  }
  _no_name_provided__331.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_pointerEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onDown_18(this._$event_15, this._this$0_234._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_15._get_consumed__21();
  };
  _no_name_provided__331.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__331.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__332($event, this$0) {
    this._$event_16 = $event;
    this._this$0_235 = this$0;
  }
  _no_name_provided__332.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_pointerEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onMove_18(this._$event_16, this._this$0_235._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_16._get_consumed__21();
  };
  _no_name_provided__332.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__332.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__333($event, this$0) {
    this._$event_17 = $event;
    this._this$0_236 = this$0;
  }
  _no_name_provided__333.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_pointerEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onUp_18(this._$event_17, this._this$0_236._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_17._get_consumed__21();
  };
  _no_name_provided__333.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__333.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__334($event, this$0) {
    this._$event_18 = $event;
    this._this$0_237 = this$0;
  }
  _no_name_provided__334.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_pointerEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onCancel_11(this._$event_18, this._this$0_237._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_18._get_consumed__21();
  };
  _no_name_provided__334.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__334.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__335($event, this$0) {
    this._$event_19 = $event;
    this._this$0_238 = this$0;
  }
  _no_name_provided__335.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_pointerEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onOut_5(this._$event_19, this._this$0_238._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_19._get_consumed__21();
  };
  _no_name_provided__335.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__335.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__336($event, this$0) {
    this._$event_20 = $event;
    this._this$0_239 = this$0;
  }
  _no_name_provided__336.prototype.invoke_621 = function (it) {
    var tmp0_safe_receiver = it._get_pointerEventHandler__6();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onLeave_5(this._$event_20, this._this$0_239._chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
    return this._$event_20._get_consumed__21();
  };
  _no_name_provided__336.prototype.invoke_1082 = function (p1) {
    return this.invoke_621((!(p1 == null) ? isInterface(p1, Layer) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__336.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function setUpMouseEventsForLayers($this) {
    register_0($this._mouseEvents_1, new _no_name_provided__337($this));
  }
  function setUpKeyEventsForLayers($this) {
    register($this._keyEvents_1, new _no_name_provided__338($this));
  }
  function setUpTouchEventsForLayers($this) {
    register_2($this._touchEvents_1, new _no_name_provided__339($this));
  }
  function setUpPointerEventsForLayers($this) {
    register_1($this._pointerEvents_1, new _no_name_provided__340($this));
  }
  function _no_name_provided__337(this$0) {
    this._this$0_240 = this$0;
  }
  _no_name_provided__337.prototype.onClick_6 = function (event) {
    var tmp = this._this$0_240._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_299(event, this._this$0_240));
  };
  _no_name_provided__337.prototype.onDown_15 = function (event) {
    var tmp = this._this$0_240._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_300(event, this._this$0_240));
  };
  _no_name_provided__337.prototype.onUp_15 = function (event) {
    var tmp = this._this$0_240._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_301(event, this._this$0_240));
  };
  _no_name_provided__337.prototype.onDoubleClick_6 = function (event) {
    var tmp = this._this$0_240._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_302(event, this._this$0_240));
  };
  _no_name_provided__337.prototype.onMove_13 = function (event) {
    var tmp = this._this$0_240._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_303(event, this._this$0_240));
  };
  _no_name_provided__337.prototype.onDrag_9 = function (event) {
    var tmp = this._this$0_240._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_304(event, this._this$0_240));
  };
  _no_name_provided__337.prototype.onWheel_5 = function (event) {
    var tmp = this._this$0_240._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_305(event, this._this$0_240));
  };
  _no_name_provided__337.$metadata$ = {
    kind: 'class',
    interfaces: [MouseEventHandler]
  };
  function _no_name_provided__338(this$0) {
    this._this$0_241 = this$0;
  }
  _no_name_provided__338.prototype.onDown_13 = function (event) {
    var tmp = this._this$0_241._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_306(event, this._this$0_241));
  };
  _no_name_provided__338.prototype.onUp_13 = function (event) {
    var tmp = this._this$0_241._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_307(event, this._this$0_241));
  };
  _no_name_provided__338.prototype.onType_4 = function (event) {
    var tmp = this._this$0_241._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_308(event, this._this$0_241));
  };
  _no_name_provided__338.$metadata$ = {
    kind: 'class',
    interfaces: [KeyEventHandler]
  };
  function _no_name_provided__339(this$0) {
    this._this$0_242 = this$0;
  }
  _no_name_provided__339.prototype.onStart_6 = function (event) {
    var tmp = this._this$0_242._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_309(event, this._this$0_242));
  };
  _no_name_provided__339.prototype.onEnd_4 = function (event) {
    var tmp = this._this$0_242._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_310(event, this._this$0_242));
  };
  _no_name_provided__339.prototype.onMove_17 = function (event) {
    var tmp = this._this$0_242._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_311(event, this._this$0_242));
  };
  _no_name_provided__339.prototype.onCancel_10 = function (event) {
    var tmp = this._this$0_242._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_312(event, this._this$0_242));
  };
  _no_name_provided__339.$metadata$ = {
    kind: 'class',
    interfaces: [TouchEventHandler]
  };
  function _no_name_provided__340(this$0) {
    this._this$0_243 = this$0;
  }
  _no_name_provided__340.prototype.onOver_4 = function (event) {
    var tmp = this._this$0_243._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_313(event, this._this$0_243));
  };
  _no_name_provided__340.prototype.onEnter_4 = function (event) {
    var tmp = this._this$0_243._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_314(event, this._this$0_243));
  };
  _no_name_provided__340.prototype.onDown_17 = function (event) {
    var tmp = this._this$0_243._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_315(event, this._this$0_243));
  };
  _no_name_provided__340.prototype.onMove_15 = function (event) {
    var tmp = this._this$0_243._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_316(event, this._this$0_243));
  };
  _no_name_provided__340.prototype.onUp_17 = function (event) {
    var tmp = this._this$0_243._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_317(event, this._this$0_243));
  };
  _no_name_provided__340.prototype.onCancel_8 = function (event) {
    var tmp = this._this$0_243._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_318(event, this._this$0_243));
  };
  _no_name_provided__340.prototype.onOut_4 = function (event) {
    var tmp = this._this$0_243._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_319(event, this._this$0_243));
  };
  _no_name_provided__340.prototype.onLeave_4 = function (event) {
    var tmp = this._this$0_243._layers._get_layersOrderedForInteraction_();
    consumeUntil(tmp, _no_name_provided_$factory_320(event, this._this$0_243));
  };
  _no_name_provided__340.$metadata$ = {
    kind: 'class',
    interfaces: [PointerEventHandler]
  };
  function DefaultLayerSupport(chartSupport) {
    this._chartSupport = chartSupport;
    this._layers = new Layers();
    this._mouseEvents_1 = this._chartSupport._mouseEvents_0;
    this._keyEvents_1 = this._chartSupport._keyEvents_0;
    this._pointerEvents_1 = this._chartSupport._pointerEvents_0;
    this._touchEvents_1 = this._chartSupport._touchEvents_0;
    var tmp = this;
    tmp._paintStatisticsSupport = PaintStatisticsSupport_init_$Create$(0, 1, null);
    this._recordPaintStatistics = false;
    this._chartSupport.onPaint(this);
    setUpMouseEventsForLayers(this);
    setUpKeyEventsForLayers(this);
    setUpPointerEventsForLayers(this);
    setUpTouchEventsForLayers(this);
  }
  DefaultLayerSupport.prototype._get_chartSupport__3 = function () {
    return this._chartSupport;
  };
  DefaultLayerSupport.prototype._get_layers__1 = function () {
    return this._layers;
  };
  DefaultLayerSupport.prototype.markAsDirty_4 = function () {
    this._chartSupport.markAsDirty_4();
  };
  DefaultLayerSupport.prototype.paint_8 = function (frameTimestamp, delta) {
    var paintingContext = new LayerPaintingContext(this._chartSupport._canvas._get_gc__1(), this, frameTimestamp, delta);
    if (this._layers.isEmpty_33()) {
      var tmp0_run_0 = Companion_getInstance_46()._helloQuickChart;
      tmp0_run_0.layout_6(paintingContext);
      tmp0_run_0.paint_12(paintingContext);
    } else {
      if (this._recordPaintStatistics) {
        this._paintStatisticsSupport.store_0(this._layers.paintLayersWithStats(paintingContext));
      } else {
        this._layers.paintLayers(paintingContext);
      }
    }
  };
  DefaultLayerSupport.$metadata$ = {
    simpleName: 'DefaultLayerSupport',
    kind: 'class',
    interfaces: [LayerSupport]
  };
  function _no_name_provided_$factory_299($event, this$0) {
    var i = new _no_name_provided__315($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_300($event, this$0) {
    var i = new _no_name_provided__316($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_301($event, this$0) {
    var i = new _no_name_provided__317($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_302($event, this$0) {
    var i = new _no_name_provided__318($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_303($event, this$0) {
    var i = new _no_name_provided__319($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_304($event, this$0) {
    var i = new _no_name_provided__320($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_305($event, this$0) {
    var i = new _no_name_provided__321($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_306($event, this$0) {
    var i = new _no_name_provided__322($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_307($event, this$0) {
    var i = new _no_name_provided__323($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_308($event, this$0) {
    var i = new _no_name_provided__324($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_309($event, this$0) {
    var i = new _no_name_provided__325($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_310($event, this$0) {
    var i = new _no_name_provided__326($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_311($event, this$0) {
    var i = new _no_name_provided__327($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_312($event, this$0) {
    var i = new _no_name_provided__328($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_313($event, this$0) {
    var i = new _no_name_provided__329($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_314($event, this$0) {
    var i = new _no_name_provided__330($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_315($event, this$0) {
    var i = new _no_name_provided__331($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_316($event, this$0) {
    var i = new _no_name_provided__332($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_317($event, this$0) {
    var i = new _no_name_provided__333($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_318($event, this$0) {
    var i = new _no_name_provided__334($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_319($event, this$0) {
    var i = new _no_name_provided__335($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function _no_name_provided_$factory_320($event, this$0) {
    var i = new _no_name_provided__336($event, this$0);
    return function (p1) {
      return i.invoke_621(p1);
    };
  }
  function DevicePixelRatioSupport() {
    this._devicePixelRatioProperty = new ObservableDouble(1.0);
    this._devicePixelRatio$delegate = this._devicePixelRatioProperty;
  }
  DevicePixelRatioSupport.prototype._get_devicePixelRatio_ = function () {
    return this._devicePixelRatio$delegate.getValue_10(this, devicePixelRatio$factory());
  };
  DevicePixelRatioSupport.prototype.updateDevicePixelRatio = function (devicePixelRatio) {
    var tmp = this._devicePixelRatioProperty;
    (tmp instanceof ObservableDouble ? tmp : THROW_CCE())._set_value__12(devicePixelRatio);
  };
  DevicePixelRatioSupport.$metadata$ = {
    simpleName: 'DevicePixelRatioSupport',
    kind: 'class',
    interfaces: []
  };
  function devicePixelRatio$factory() {
    return getPropertyCallableRef('devicePixelRatio', 1, KProperty1, function (receiver) {
      return receiver._get_devicePixelRatio_();
    }, null);
  }
  function DirtySupport() {
    this._dirty = false;
  }
  DirtySupport.prototype.markAsDirty_4 = function () {
    this._dirty = true;
  };
  DirtySupport.prototype.clearIsDirty = function () {
    this._dirty = false;
  };
  DirtySupport.$metadata$ = {
    simpleName: 'DirtySupport',
    kind: 'class',
    interfaces: []
  };
  function FontDescriptorFragment_init_$Init$(family, size, weight, style, variant, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      family = null;
    if (!(($mask0 & 2) === 0))
      size = null;
    if (!(($mask0 & 4) === 0))
      weight = null;
    if (!(($mask0 & 8) === 0))
      style = null;
    if (!(($mask0 & 16) === 0))
      variant = null;
    FontDescriptorFragment.call($this, family, size, weight, style, variant);
    return $this;
  }
  function FontDescriptorFragment_init_$Create$(family, size, weight, style, variant, $mask0, $marker) {
    return FontDescriptorFragment_init_$Init$(family, size, weight, style, variant, $mask0, $marker, Object.create(FontDescriptorFragment.prototype));
  }
  function FontDescriptorFragment_init_$Init$_0(size, $this) {
    var tmp = new FontSize(size);
    FontDescriptorFragment_init_$Init$(null, tmp, null, null, null, 29, null, $this);
    return $this;
  }
  function FontDescriptorFragment_init_$Create$_0(size) {
    return FontDescriptorFragment_init_$Init$_0(size, Object.create(FontDescriptorFragment.prototype));
  }
  function Companion_54() {
    Companion_instance_53 = this;
    var tmp = this;
    tmp._empty_0 = FontDescriptorFragment_init_$Create$(null, null, null, null, null, 31, null);
    var tmp_0 = this;
    var tmp_1 = Companion_getInstance_58()._XXS_0;
    tmp_0._XXS = FontDescriptorFragment_init_$Create$(null, tmp_1, null, null, null, 29, null);
    var tmp_2 = this;
    var tmp_3 = Companion_getInstance_58()._XS_0;
    tmp_2._XS = FontDescriptorFragment_init_$Create$(null, tmp_3, null, null, null, 29, null);
    var tmp_4 = this;
    var tmp_5 = Companion_getInstance_58()._S_0;
    tmp_4._S = FontDescriptorFragment_init_$Create$(null, tmp_5, null, null, null, 29, null);
    var tmp_6 = this;
    var tmp_7 = Companion_getInstance_58()._Default_0;
    tmp_6._DefaultSize = FontDescriptorFragment_init_$Create$(null, tmp_7, null, null, null, 29, null);
    var tmp_8 = this;
    var tmp_9 = Companion_getInstance_58()._L_1;
    tmp_8._L = FontDescriptorFragment_init_$Create$(null, tmp_9, null, null, null, 29, null);
    var tmp_10 = this;
    var tmp_11 = Companion_getInstance_58()._XL_1;
    tmp_10._XL = FontDescriptorFragment_init_$Create$(null, tmp_11, null, null, null, 29, null);
  }
  Companion_54.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_53;
  function Companion_getInstance_53() {
    if (Companion_instance_53 == null)
      new Companion_54();
    return Companion_instance_53;
  }
  function FontDescriptorFragment(family, size, weight, style, variant) {
    Companion_getInstance_53();
    this._family = family;
    this._size_3 = size;
    this._weight = weight;
    this._style_3 = style;
    this._variant = variant;
  }
  FontDescriptorFragment.prototype._get_family__0 = function () {
    return this._family;
  };
  FontDescriptorFragment.prototype._get_size__43 = function () {
    return this._size_3;
  };
  FontDescriptorFragment.prototype._get_weight__0 = function () {
    return this._weight;
  };
  FontDescriptorFragment.prototype._get_style__2 = function () {
    return this._style_3;
  };
  FontDescriptorFragment.prototype._get_variant__0 = function () {
    return this._variant;
  };
  FontDescriptorFragment.prototype.isEmpty_33 = function () {
    return (((this._get_family__0() == null ? this._get_size__43() == null : false) ? this._get_weight__0() == null : false) ? this._get_style__2() == null : false) ? this._get_variant__0() == null : false;
  };
  FontDescriptorFragment.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FontDescriptorFragment))
      return false;
    else {
    }
    if (!equals_0(this._get_family__0(), other._get_family__0()))
      return false;
    if (!equals_0(this._get_size__43(), other._get_size__43()))
      return false;
    if (!equals_0(this._get_weight__0(), other._get_weight__0()))
      return false;
    if (!equals_0(this._get_style__2(), other._get_style__2()))
      return false;
    if (!equals_0(this._get_variant__0(), other._get_variant__0()))
      return false;
    return true;
  };
  FontDescriptorFragment.prototype.hashCode = function () {
    var tmp0_safe_receiver = this._get_family__0();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.hashCode();
    var result = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
    var tmp = imul(31, result);
    var tmp2_safe_receiver = this._get_size__43();
    var tmp3_elvis_lhs = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.hashCode();
    result = tmp + (tmp3_elvis_lhs == null ? 0 : tmp3_elvis_lhs) | 0;
    var tmp_0 = imul(31, result);
    var tmp4_safe_receiver = this._get_weight__0();
    var tmp5_elvis_lhs = tmp4_safe_receiver == null ? null : tmp4_safe_receiver.hashCode();
    result = tmp_0 + (tmp5_elvis_lhs == null ? 0 : tmp5_elvis_lhs) | 0;
    var tmp_1 = imul(31, result);
    var tmp6_safe_receiver = this._get_style__2();
    var tmp7_elvis_lhs = tmp6_safe_receiver == null ? null : tmp6_safe_receiver.hashCode();
    result = tmp_1 + (tmp7_elvis_lhs == null ? 0 : tmp7_elvis_lhs) | 0;
    var tmp_2 = imul(31, result);
    var tmp8_safe_receiver = this._get_variant__0();
    var tmp9_elvis_lhs = tmp8_safe_receiver == null ? null : tmp8_safe_receiver.hashCode();
    result = tmp_2 + (tmp9_elvis_lhs == null ? 0 : tmp9_elvis_lhs) | 0;
    return result;
  };
  FontDescriptorFragment.prototype.toString = function () {
    var tmp = this._get_family__0();
    var tmp0_safe_receiver = this._get_size__43();
    var tmp_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._size_5;
    var tmp1_safe_receiver = this._get_weight__0();
    return '' + 'FontDescriptorFragment(' + tmp + ', ' + tmp_0 + ', ' + (tmp1_safe_receiver == null ? null : tmp1_safe_receiver._weight_1) + ', ' + this._get_style__2() + ', ' + this._get_variant__0() + ')';
  };
  FontDescriptorFragment.$metadata$ = {
    simpleName: 'FontDescriptorFragment',
    kind: 'class',
    interfaces: []
  };
  function FontDescriptor_init_$Init$(family, size, weight, style, variant, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      family = Companion_getInstance_55()._SansSerif;
    if (!(($mask0 & 2) === 0))
      size = Companion_getInstance_58()._Default_0;
    if (!(($mask0 & 4) === 0))
      weight = Companion_getInstance_59()._Normal;
    if (!(($mask0 & 8) === 0))
      style = FontStyle_Normal_getInstance();
    if (!(($mask0 & 16) === 0))
      variant = FontVariant_Normal_getInstance();
    FontDescriptor.call($this, family, size, weight, style, variant);
    return $this;
  }
  function FontDescriptor_init_$Create$(family, size, weight, style, variant, $mask0, $marker) {
    return FontDescriptor_init_$Init$(family, size, weight, style, variant, $mask0, $marker, Object.create(FontDescriptor.prototype));
  }
  function Companion_55() {
    Companion_instance_54 = this;
    var tmp = this;
    var tmp_0 = Companion_getInstance_55()._SansSerif;
    var tmp_1 = Companion_getInstance_58()._Default_0;
    tmp._Default = FontDescriptor_init_$Create$(tmp_0, tmp_1, null, null, null, 28, null);
    var tmp_2 = this;
    var tmp_3 = Companion_getInstance_55()._SansSerif;
    var tmp_4 = Companion_getInstance_58()._L_1;
    tmp_2._L_0 = FontDescriptor_init_$Create$(tmp_3, tmp_4, null, null, null, 28, null);
    var tmp_5 = this;
    var tmp_6 = Companion_getInstance_55()._SansSerif;
    var tmp_7 = Companion_getInstance_58()._XL_1;
    tmp_5._XL_0 = FontDescriptor_init_$Create$(tmp_6, tmp_7, null, null, null, 28, null);
  }
  Companion_55.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_54;
  function Companion_getInstance_54() {
    if (Companion_instance_54 == null)
      new Companion_55();
    return Companion_instance_54;
  }
  function FontDescriptor(family, size, weight, style, variant) {
    Companion_getInstance_54();
    FontDescriptorFragment_init_$Init$(null, null, null, null, null, 31, null, this);
    this._family_0 = family;
    this._size_4 = size;
    this._weight_0 = weight;
    this._style_4 = style;
    this._variant_0 = variant;
  }
  FontDescriptor.prototype._get_family__0 = function () {
    return this._family_0;
  };
  FontDescriptor.prototype._get_size__43 = function () {
    return this._size_4;
  };
  FontDescriptor.prototype._get_weight__0 = function () {
    return this._weight_0;
  };
  FontDescriptor.prototype._get_style__2 = function () {
    return this._style_4;
  };
  FontDescriptor.prototype._get_variant__0 = function () {
    return this._variant_0;
  };
  FontDescriptor.prototype.toString = function () {
    return '' + 'FontDescriptor(' + this._family_0 + ', ' + this._size_4._size_5 + ', ' + this._weight_0._weight_1 + ', ' + this._style_4 + ', ' + this._variant_0 + ')';
  };
  FontDescriptor.$metadata$ = {
    simpleName: 'FontDescriptor',
    kind: 'class',
    interfaces: []
  };
  function combineWith(_this_, moreImportant) {
    if ((moreImportant == null ? true : moreImportant.isEmpty_33()) ? true : _this_.equals(moreImportant)) {
      return _this_;
    }if (moreImportant instanceof FontDescriptor) {
      return moreImportant;
    } else {
    }
    var tmp0_elvis_lhs = moreImportant._get_family__0();
    var combinedFamily = tmp0_elvis_lhs == null ? _this_._family_0 : tmp0_elvis_lhs;
    var tmp1_elvis_lhs = moreImportant._get_size__43();
    var combinedSize = tmp1_elvis_lhs == null ? _this_._size_4 : tmp1_elvis_lhs;
    var tmp2_elvis_lhs = moreImportant._get_weight__0();
    var combinedWeight = tmp2_elvis_lhs == null ? _this_._weight_0 : tmp2_elvis_lhs;
    var tmp3_elvis_lhs = moreImportant._get_style__2();
    var combinedStyle = tmp3_elvis_lhs == null ? _this_._style_4 : tmp3_elvis_lhs;
    var tmp4_elvis_lhs = moreImportant._get_variant__0();
    var combinedVariant = tmp4_elvis_lhs == null ? _this_._variant_0 : tmp4_elvis_lhs;
    return new FontDescriptor(combinedFamily, combinedSize, combinedWeight, combinedStyle, combinedVariant);
  }
  function Companion_56() {
    Companion_instance_55 = this;
    this._SansSerif = new FontFamily('sans-serif');
    this._Serif = new FontFamily('serif');
    this._Monospace = new FontFamily('monospace');
  }
  Companion_56.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_55;
  function Companion_getInstance_55() {
    if (Companion_instance_55 == null)
      new Companion_56();
    return Companion_instance_55;
  }
  function FontFamily(family) {
    Companion_getInstance_55();
    this._family_1 = family;
  }
  FontFamily.prototype.toString = function () {
    return this._family_1;
  };
  FontFamily.prototype.hashCode = function () {
    return getStringHashCode(this._family_1);
  };
  FontFamily.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FontFamily))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof FontFamily ? other : THROW_CCE();
    if (!(this._family_1 === tmp0_other_with_cast._family_1))
      return false;
    return true;
  };
  FontFamily.$metadata$ = {
    simpleName: 'FontFamily',
    kind: 'class',
    interfaces: []
  };
  function Companion_57() {
    Companion_instance_56 = this;
    this._empty_1 = new FontMetrics(0.0, 0.0, 0.0, 0.0, Companion_getInstance_57()._empty_2);
  }
  Companion_57.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_56;
  function Companion_getInstance_56() {
    if (Companion_instance_56 == null)
      new Companion_57();
    return Companion_instance_56;
  }
  function FontMetrics(xLine, capitalHLine, accentLine, pLine, alignmentCorrectionInformation) {
    Companion_getInstance_56();
    this._xLine = xLine;
    this._capitalHLine = capitalHLine;
    this._accentLine = accentLine;
    this._pLine = pLine;
    this._alignmentCorrectionInformation = alignmentCorrectionInformation;
  }
  FontMetrics.prototype._get_totalHeight_ = function () {
    return this._pLine + this._accentLine;
  };
  FontMetrics.prototype.toString = function () {
    return '' + 'FontMetrics(xLine=' + this._xLine + ', capitalHLine=' + this._capitalHLine + ', accentLine=' + this._accentLine + ', pLine=' + this._pLine + ', alignmentCorrectionInformation=' + this._alignmentCorrectionInformation + ')';
  };
  FontMetrics.prototype.hashCode = function () {
    var result = getNumberHashCode(this._xLine);
    result = imul(result, 31) + getNumberHashCode(this._capitalHLine) | 0;
    result = imul(result, 31) + getNumberHashCode(this._accentLine) | 0;
    result = imul(result, 31) + getNumberHashCode(this._pLine) | 0;
    result = imul(result, 31) + this._alignmentCorrectionInformation.hashCode() | 0;
    return result;
  };
  FontMetrics.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FontMetrics))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof FontMetrics ? other : THROW_CCE();
    if (!equals_0(this._xLine, tmp0_other_with_cast._xLine))
      return false;
    if (!equals_0(this._capitalHLine, tmp0_other_with_cast._capitalHLine))
      return false;
    if (!equals_0(this._accentLine, tmp0_other_with_cast._accentLine))
      return false;
    if (!equals_0(this._pLine, tmp0_other_with_cast._pLine))
      return false;
    if (!this._alignmentCorrectionInformation.equals(tmp0_other_with_cast._alignmentCorrectionInformation))
      return false;
    return true;
  };
  FontMetrics.$metadata$ = {
    simpleName: 'FontMetrics',
    kind: 'class',
    interfaces: []
  };
  function AlignmentCorrectionInformation_init_$Init$(top, center, bottom, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      top = 0.0;
    if (!(($mask0 & 2) === 0))
      center = 0.0;
    if (!(($mask0 & 4) === 0))
      bottom = 0.0;
    AlignmentCorrectionInformation.call($this, top, center, bottom);
    return $this;
  }
  function AlignmentCorrectionInformation_init_$Create$(top, center, bottom, $mask0, $marker) {
    return AlignmentCorrectionInformation_init_$Init$(top, center, bottom, $mask0, $marker, Object.create(AlignmentCorrectionInformation.prototype));
  }
  function Companion_58() {
    Companion_instance_57 = this;
    var tmp = this;
    tmp._empty_2 = AlignmentCorrectionInformation_init_$Create$(0.0, 0.0, 0.0, 7, null);
  }
  Companion_58.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_57;
  function Companion_getInstance_57() {
    if (Companion_instance_57 == null)
      new Companion_58();
    return Companion_instance_57;
  }
  function AlignmentCorrectionInformation(top, center, bottom) {
    Companion_getInstance_57();
    this._top_0 = top;
    this._center = center;
    this._bottom_0 = bottom;
  }
  AlignmentCorrectionInformation.prototype.get_33 = function (verticalAlignment) {
    var tmp0_subject = verticalAlignment;
    return tmp0_subject.equals(VerticalAlignment_Top_getInstance()) ? this._top_0 : tmp0_subject.equals(VerticalAlignment_Center_getInstance()) ? this._center : tmp0_subject.equals(VerticalAlignment_Bottom_getInstance()) ? this._bottom_0 : 0.0;
  };
  AlignmentCorrectionInformation.prototype.toString = function () {
    return '' + 'AlignmentCorrectionInformation(top=' + this._top_0 + ', center=' + this._center + ', bottom=' + this._bottom_0 + ')';
  };
  AlignmentCorrectionInformation.prototype.hashCode = function () {
    var result = getNumberHashCode(this._top_0);
    result = imul(result, 31) + getNumberHashCode(this._center) | 0;
    result = imul(result, 31) + getNumberHashCode(this._bottom_0) | 0;
    return result;
  };
  AlignmentCorrectionInformation.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AlignmentCorrectionInformation))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof AlignmentCorrectionInformation ? other : THROW_CCE();
    if (!equals_0(this._top_0, tmp0_other_with_cast._top_0))
      return false;
    if (!equals_0(this._center, tmp0_other_with_cast._center))
      return false;
    if (!equals_0(this._bottom_0, tmp0_other_with_cast._bottom_0))
      return false;
    return true;
  };
  AlignmentCorrectionInformation.$metadata$ = {
    simpleName: 'AlignmentCorrectionInformation',
    kind: 'class',
    interfaces: []
  };
  function Companion_59() {
    Companion_instance_58 = this;
    this._maxSize_1 = 256.0;
    this._XXS_0 = new FontSize(8.0);
    this._XS_0 = new FontSize(10.0);
    this._S_0 = new FontSize(12.0);
    this._Default_0 = new FontSize(16.0);
    this._L_1 = new FontSize(20.0);
    this._XL_1 = new FontSize(24.0);
    this._Max = new FontSize(256.0);
  }
  Companion_59.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_58;
  function Companion_getInstance_58() {
    if (Companion_instance_58 == null)
      new Companion_59();
    return Companion_instance_58;
  }
  function FontSize(size) {
    Companion_getInstance_58();
    this._size_5 = size;
    var containsUpper = 256.0;
    var containsArg = this._size_5;
    var tmp0_require_0 = 0.0 <= containsArg ? containsArg <= containsUpper : false;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Font size out of range: <' + this._size_5 + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }}
  FontSize.prototype.compareTo_41 = function (other) {
    return compareTo(this._size_5, other._size_5);
  };
  FontSize.prototype.compareTo_42 = function (other) {
    return this.compareTo_41(other instanceof FontSize ? other : THROW_CCE());
  };
  FontSize.prototype.toString = function () {
    return '' + 'FontSize(size=' + this._size_5 + ')';
  };
  FontSize.prototype.hashCode = function () {
    return getNumberHashCode(this._size_5);
  };
  FontSize.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FontSize))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof FontSize ? other : THROW_CCE();
    if (!equals_0(this._size_5, tmp0_other_with_cast._size_5))
      return false;
    return true;
  };
  FontSize.$metadata$ = {
    simpleName: 'FontSize',
    kind: 'class',
    interfaces: [Comparable]
  };
  var FontStyle_Normal_instance;
  var FontStyle_Italic_instance;
  var FontStyle_Oblique_instance;
  var FontStyle_entriesInitialized;
  function FontStyle_initEntries() {
    if (FontStyle_entriesInitialized)
      return Unit_getInstance();
    FontStyle_entriesInitialized = true;
    FontStyle_Normal_instance = new FontStyle_0('Normal', 0);
    FontStyle_Italic_instance = new FontStyle_0('Italic', 1);
    FontStyle_Oblique_instance = new FontStyle_0('Oblique', 2);
  }
  function FontStyle_0(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  FontStyle_0.$metadata$ = {
    simpleName: 'FontStyle',
    kind: 'class',
    interfaces: []
  };
  function FontStyle_Normal_getInstance() {
    FontStyle_initEntries();
    return FontStyle_Normal_instance;
  }
  function FontStyle_Italic_getInstance() {
    FontStyle_initEntries();
    return FontStyle_Italic_instance;
  }
  function FontStyle_Oblique_getInstance() {
    FontStyle_initEntries();
    return FontStyle_Oblique_instance;
  }
  var FontVariant_Normal_instance;
  var FontVariant_SmallCaps_instance;
  var FontVariant_entriesInitialized;
  function FontVariant_initEntries() {
    if (FontVariant_entriesInitialized)
      return Unit_getInstance();
    FontVariant_entriesInitialized = true;
    FontVariant_Normal_instance = new FontVariant_0('Normal', 0);
    FontVariant_SmallCaps_instance = new FontVariant_0('SmallCaps', 1);
  }
  function FontVariant_0(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  FontVariant_0.$metadata$ = {
    simpleName: 'FontVariant',
    kind: 'class',
    interfaces: []
  };
  function FontVariant_Normal_getInstance() {
    FontVariant_initEntries();
    return FontVariant_Normal_instance;
  }
  function FontVariant_SmallCaps_getInstance() {
    FontVariant_initEntries();
    return FontVariant_SmallCaps_instance;
  }
  function Companion_60() {
    Companion_instance_59 = this;
    this._Thin = new FontWeight_0(100);
    this._ExtraLight = new FontWeight_0(200);
    this._Light = new FontWeight_0(300);
    this._Regular = new FontWeight_0(400);
    this._Normal = new FontWeight_0(400);
    this._Medium = new FontWeight_0(500);
    this._SemiBold = new FontWeight_0(600);
    this._Bold = new FontWeight_0(700);
    this._ExtraBold = new FontWeight_0(800);
    this._Black = new FontWeight_0(900);
  }
  Companion_60.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_59;
  function Companion_getInstance_59() {
    if (Companion_instance_59 == null)
      new Companion_60();
    return Companion_instance_59;
  }
  function FontWeight_0(weight) {
    Companion_getInstance_59();
    this._weight_1 = weight;
    var containsArg = this._weight_1;
    var tmp0_require_0 = 1 <= containsArg ? containsArg <= 1000 : false;
    if (!tmp0_require_0) {
      var message_1 = '' + 'weight must be a value between 1 and 1000, inclusive, but was <' + this._weight_1 + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }}
  FontWeight_0.prototype.toString = function () {
    return '' + 'FontWeight(weight=' + this._weight_1 + ')';
  };
  FontWeight_0.prototype.hashCode = function () {
    return this._weight_1;
  };
  FontWeight_0.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FontWeight_0))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof FontWeight_0 ? other : THROW_CCE();
    if (!(this._weight_1 === tmp0_other_with_cast._weight_1))
      return false;
    return true;
  };
  FontWeight_0.$metadata$ = {
    simpleName: 'FontWeight',
    kind: 'class',
    interfaces: []
  };
  function Image_init_$Init$(data, size, alignmentPoint, $mask0, $marker, $this) {
    if (!(($mask0 & 4) === 0))
      alignmentPoint = Companion_getInstance_32()._origin_0;
    Image.call($this, data, size, alignmentPoint);
    return $this;
  }
  function Image_init_$Create$(data, size, alignmentPoint, $mask0, $marker) {
    return Image_init_$Init$(data, size, alignmentPoint, $mask0, $marker, Object.create(Image.prototype));
  }
  function Image(data, size, alignmentPoint) {
    this._data_2 = data;
    this._size_6 = size;
    this._alignmentPoint_0 = alignmentPoint;
  }
  Image.prototype.boundingBox_4 = function (chartSupport) {
    return new Rectangle(this._alignmentPoint_0, this._size_6);
  };
  Image.prototype.paint_13 = function (paintingContext, x, y) {
    var gc = paintingContext._gc;
    gc.paintImage_1(this, x + this._alignmentPoint_0._x, y + this._alignmentPoint_0._y, this._size_6._width_0, this._size_6._height);
  };
  Image.prototype.toString = function () {
    return '' + 'Image(data=' + this._data_2 + ', size=' + this._size_6 + ', alignmentPoint=' + this._alignmentPoint_0 + ')';
  };
  Image.prototype.hashCode = function () {
    var result = hashCode(this._data_2);
    result = imul(result, 31) + this._size_6.hashCode() | 0;
    result = imul(result, 31) + this._alignmentPoint_0.hashCode() | 0;
    return result;
  };
  Image.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Image))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Image ? other : THROW_CCE();
    if (!equals_0(this._data_2, tmp0_other_with_cast._data_2))
      return false;
    if (!this._size_6.equals(tmp0_other_with_cast._size_6))
      return false;
    if (!this._alignmentPoint_0.equals(tmp0_other_with_cast._alignmentPoint_0))
      return false;
    return true;
  };
  Image.$metadata$ = {
    simpleName: 'Image',
    kind: 'class',
    interfaces: [Paintable]
  };
  function LayerSupport() {
  }
  LayerSupport.$metadata$ = {
    simpleName: 'LayerSupport',
    kind: 'interface',
    interfaces: [PaintListener]
  };
  function Companion_61() {
    Companion_instance_60 = this;
    this._Single = new LineSpacing(1.15);
  }
  Companion_61.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_60;
  function Companion_getInstance_60() {
    if (Companion_instance_60 == null)
      new Companion_61();
    return Companion_instance_60;
  }
  function LineSpacing(percentage) {
    Companion_getInstance_60();
    this._percentage_0 = percentage;
  }
  LineSpacing.prototype._get_spacePercentage_ = function () {
    return this._percentage_0 - 1.0;
  };
  LineSpacing.$metadata$ = {
    simpleName: 'LineSpacing',
    kind: 'class',
    interfaces: []
  };
  var imageCache;
  function loadImage(url, callback) {
    var cachedImage = imageCache.get_20(url);
    if (!(cachedImage == null)) {
      callback(cachedImage);
    } else {
      loadImageUncached(url, _no_name_provided_$factory_321(url, callback));
    }
  }
  function _no_name_provided__341($url, $callback) {
    this._$url = $url;
    this._$callback = $callback;
  }
  _no_name_provided__341.prototype.invoke_623 = function (image) {
    imageCache.store(this._$url, image);
    Unit_getInstance();
    this._$callback(image);
  };
  _no_name_provided__341.prototype.invoke_1082 = function (p1) {
    this.invoke_623(p1 instanceof Image ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__341.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_321($url, $callback) {
    var i = new _no_name_provided__341($url, $callback);
    return function (p1) {
      i.invoke_623(p1);
      return Unit_getInstance();
    };
  }
  var MouseCursor_Default_instance;
  var MouseCursor_Hand_instance;
  var MouseCursor_OpenHand_instance;
  var MouseCursor_ClosedHand_instance;
  var MouseCursor_CrossHair_instance;
  var MouseCursor_Text_instance;
  var MouseCursor_Busy_instance;
  var MouseCursor_Move_instance;
  var MouseCursor_None_instance;
  var MouseCursor_ResizeNorth_instance;
  var MouseCursor_ResizeNorthEast_instance;
  var MouseCursor_ResizeEast_instance;
  var MouseCursor_ResizeSouthEast_instance;
  var MouseCursor_ResizeSouth_instance;
  var MouseCursor_ResizeSouthWest_instance;
  var MouseCursor_ResizeWest_instance;
  var MouseCursor_ResizeNorthWest_instance;
  var MouseCursor_entriesInitialized;
  function MouseCursor_initEntries() {
    if (MouseCursor_entriesInitialized)
      return Unit_getInstance();
    MouseCursor_entriesInitialized = true;
    MouseCursor_Default_instance = new MouseCursor('Default', 0);
    MouseCursor_Hand_instance = new MouseCursor('Hand', 1);
    MouseCursor_OpenHand_instance = new MouseCursor('OpenHand', 2);
    MouseCursor_ClosedHand_instance = new MouseCursor('ClosedHand', 3);
    MouseCursor_CrossHair_instance = new MouseCursor('CrossHair', 4);
    MouseCursor_Text_instance = new MouseCursor('Text', 5);
    MouseCursor_Busy_instance = new MouseCursor('Busy', 6);
    MouseCursor_Move_instance = new MouseCursor('Move', 7);
    MouseCursor_None_instance = new MouseCursor('None', 8);
    MouseCursor_ResizeNorth_instance = new MouseCursor('ResizeNorth', 9);
    MouseCursor_ResizeNorthEast_instance = new MouseCursor('ResizeNorthEast', 10);
    MouseCursor_ResizeEast_instance = new MouseCursor('ResizeEast', 11);
    MouseCursor_ResizeSouthEast_instance = new MouseCursor('ResizeSouthEast', 12);
    MouseCursor_ResizeSouth_instance = new MouseCursor('ResizeSouth', 13);
    MouseCursor_ResizeSouthWest_instance = new MouseCursor('ResizeSouthWest', 14);
    MouseCursor_ResizeWest_instance = new MouseCursor('ResizeWest', 15);
    MouseCursor_ResizeNorthWest_instance = new MouseCursor('ResizeNorthWest', 16);
  }
  function MouseCursor(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  MouseCursor.$metadata$ = {
    simpleName: 'MouseCursor',
    kind: 'class',
    interfaces: []
  };
  function MouseCursor_Default_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_Default_instance;
  }
  function MouseCursor_Hand_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_Hand_instance;
  }
  function MouseCursor_OpenHand_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_OpenHand_instance;
  }
  function MouseCursor_ClosedHand_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_ClosedHand_instance;
  }
  function MouseCursor_CrossHair_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_CrossHair_instance;
  }
  function MouseCursor_Text_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_Text_instance;
  }
  function MouseCursor_Busy_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_Busy_instance;
  }
  function MouseCursor_Move_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_Move_instance;
  }
  function MouseCursor_None_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_None_instance;
  }
  function MouseCursor_ResizeNorth_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_ResizeNorth_instance;
  }
  function MouseCursor_ResizeNorthEast_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_ResizeNorthEast_instance;
  }
  function MouseCursor_ResizeEast_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_ResizeEast_instance;
  }
  function MouseCursor_ResizeSouthEast_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_ResizeSouthEast_instance;
  }
  function MouseCursor_ResizeSouth_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_ResizeSouth_instance;
  }
  function MouseCursor_ResizeSouthWest_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_ResizeSouthWest_instance;
  }
  function MouseCursor_ResizeWest_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_ResizeWest_instance;
  }
  function MouseCursor_ResizeNorthWest_getInstance() {
    MouseCursor_initEntries();
    return MouseCursor_ResizeNorthWest_instance;
  }
  function PaintStatisticsSupport_init_$Init$(maxStatsSize, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      maxStatsSize = 100;
    PaintStatisticsSupport.call($this, maxStatsSize);
    return $this;
  }
  function PaintStatisticsSupport_init_$Create$(maxStatsSize, $mask0, $marker) {
    return PaintStatisticsSupport_init_$Init$(maxStatsSize, $mask0, $marker, Object.create(PaintStatisticsSupport.prototype));
  }
  function PaintStatisticsSupport(maxStatsSize) {
    this._maxStatsSize = maxStatsSize;
    var tmp = this;
    tmp.__paintingStatsList = ArrayList_init_$Create$();
  }
  PaintStatisticsSupport.prototype.store_0 = function (paintingStats) {
    this.__paintingStatsList.add_57(paintingStats);
    Unit_getInstance();
    while (this.__paintingStatsList._get_size__43() > this._maxStatsSize) {
      this.__paintingStatsList.removeAt_2(0);
      Unit_getInstance();
    }
  };
  PaintStatisticsSupport.$metadata$ = {
    simpleName: 'PaintStatisticsSupport',
    kind: 'class',
    interfaces: []
  };
  function calculateXWithAnchor(_this_, width, anchorGap, horizontalAlignment) {
    var tmp0_subject = horizontalAlignment;
    var tmp;
    if (tmp0_subject.equals(HorizontalAlignment_Left_getInstance())) {
      tmp = 0.0;
    } else if (tmp0_subject.equals(HorizontalAlignment_Center_getInstance())) {
      tmp = -width / 2.0;
    } else if (tmp0_subject.equals(HorizontalAlignment_Right_getInstance())) {
      tmp = -width;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp + calculateXOffsetForGap(horizontalAlignment, anchorGap);
  }
  function calculateYWithAnchor(height, anchorGap, verticalAlignment, gc) {
    var tmp0_subject = verticalAlignment;
    var tmp;
    if (tmp0_subject.equals(VerticalAlignment_Top_getInstance())) {
      tmp = 0.0;
    } else if (tmp0_subject.equals(VerticalAlignment_Center_getInstance())) {
      tmp = -height / 2.0;
    } else if (tmp0_subject.equals(VerticalAlignment_Baseline_getInstance())) {
      var fontMetrics = gc.getFontMetrics_1();
      tmp = -height / 2.0 - fontMetrics._accentLine / 2.0 + fontMetrics._pLine / 2.0;
    } else if (tmp0_subject.equals(VerticalAlignment_Bottom_getInstance())) {
      tmp = -height;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp + calculateYOffsetForGap(verticalAlignment, anchorGap);
  }
  function calculateXOffsetForGap(_this_, gap) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(HorizontalAlignment_Left_getInstance())) {
      tmp = gap;
    } else if (tmp0_subject.equals(HorizontalAlignment_Right_getInstance())) {
      tmp = -gap;
    } else if (tmp0_subject.equals(HorizontalAlignment_Center_getInstance())) {
      tmp = 0.0;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function calculateYOffsetForGap(_this_, gap) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(VerticalAlignment_Top_getInstance())) {
      tmp = gap;
    } else if (tmp0_subject.equals(VerticalAlignment_Center_getInstance())) {
      tmp = 0.0;
    } else if (tmp0_subject.equals(VerticalAlignment_Baseline_getInstance())) {
      tmp = 0.0;
    } else if (tmp0_subject.equals(VerticalAlignment_Bottom_getInstance())) {
      tmp = -gap;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function calculateXOffsetForGap_0(_this_, gap) {
    return calculateXOffsetForGap(_this_._horizontalAlignment, gap);
  }
  function calculateYOffsetForGap_0(_this_, gap) {
    return calculateYOffsetForGap(_this_._verticalAlignment, gap);
  }
  function calculateLeft(_this_, length) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(HorizontalAlignment_Left_getInstance())) {
      tmp = 0.0;
    } else if (tmp0_subject.equals(HorizontalAlignment_Right_getInstance())) {
      tmp = -length;
    } else if (tmp0_subject.equals(HorizontalAlignment_Center_getInstance())) {
      tmp = -length / 2.0;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function fillRectCoordinates(_this_, corner0, corner1) {
    fillRectCoordinates_0(_this_, corner0._x, corner0._y, corner1._x, corner1._y);
  }
  function strokeRectCoordinates(_this_, corner0, corner1) {
    strokeRectCoordinates_0(_this_, corner0._x, corner0._y, corner1._x, corner1._y);
  }
  function fillRectCoordinates_0(_this_, x0, y0, x1, y1) {
    var tmp = x1 - x0;
    var tmp_0 = y1 - y0;
    _this_.fillRect$default_0(x0, y0, tmp, tmp_0, null, null, 48, null);
  }
  function strokeRectCoordinates_0(_this_, x0, y0, x1, y1) {
    var tmp = x1 - x0;
    var tmp_0 = y1 - y0;
    _this_.strokeRect$default_0(x0, y0, tmp, tmp_0, null, 16, null);
  }
  function paintTextBox(_this_, lines, lineSpacing, horizontalAlignment, anchorDirection, anchorGap, boxStyle, textColor, maxStringWidth) {
    var targetList_1 = ArrayList_init_$Create$();
    var n_1_2 = 0;
    var currentSize_2_3 = lines._get_size__43();
    while (n_1_2 < currentSize_2_3) {
      var tmp0_3_5 = n_1_2;
      n_1_2 = tmp0_3_5 + 1 | 0;
      var tmp0__anonymous__4_4 = lines.get_44(tmp0_3_5);
      var tmp0_let_0_5_6 = _this_.calculateTextWidth_1(tmp0__anonymous__4_4);
      targetList_1.add_57(tmp0_let_0_5_6);
      Unit_getInstance();
    }
    var tmp0_fastReduce_0 = targetList_1;
    var tmp0_require_0_1 = !tmp0_fastReduce_0.isEmpty_33();
    if (!tmp0_require_0_1) {
      var message_1_2 = 'not supported for empty lists';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_2));
    }var reduced_3 = tmp0_fastReduce_0.get_44(0);
    var n_1_4 = 0;
    var currentSize_2_5 = tmp0_fastReduce_0._get_size__43();
    while (n_1_4 < currentSize_2_5) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp1__anonymous__4_6 = n_1_4;
        var tmp2__anonymous__4_7 = tmp0_fastReduce_0.get_44(n_1_4);
        if (tmp1__anonymous__4_6 === 0) {
          tmp$ret$0 = Unit_getInstance();
          break l$ret$1;
        }var tmp1__anonymous__9 = reduced_3;
        reduced_3 = function () {
          var $externalVarargReceiverTmp = Math;
          return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Float64Array([tmp1__anonymous__9, tmp2__anonymous__4_7]))));
        }.call(this);
      }
       while (false);
      var tmp0_3_8 = n_1_4;
      n_1_4 = tmp0_3_8 + 1 | 0;
      Unit_getInstance();
    }
    var textBlockWidth = reduced_3;
    var fontMetrics = _this_.getFontMetrics_1();
    var spaceBetweenLines = fontMetrics._get_totalHeight_() * lineSpacing._get_spacePercentage_();
    var textBlockHeight = lines._get_size__43() * fontMetrics._get_totalHeight_() + (lines._get_size__43() - 1 | 0) * spaceBetweenLines;
    var boxWidth = textBlockWidth + boxStyle._padding._offsetWidth;
    var boxHeight = textBlockHeight + boxStyle._padding._offsetHeight;
    var boxOriginXWithoutGapOffset;
    var boxOriginYWithoutGapOffset;
    var tmp0_subject = anchorDirection;
    if (tmp0_subject.equals(Direction_TopLeft_getInstance())) {
      boxOriginXWithoutGapOffset = 0.0;
      boxOriginYWithoutGapOffset = 0.0;
    } else if (tmp0_subject.equals(Direction_TopCenter_getInstance())) {
      boxOriginXWithoutGapOffset = -boxWidth / 2.0;
      boxOriginYWithoutGapOffset = 0.0;
    } else if (tmp0_subject.equals(Direction_TopRight_getInstance())) {
      boxOriginXWithoutGapOffset = -boxWidth;
      boxOriginYWithoutGapOffset = 0.0;
    } else if (tmp0_subject.equals(Direction_CenterLeft_getInstance())) {
      boxOriginXWithoutGapOffset = 0.0;
      boxOriginYWithoutGapOffset = -boxHeight / 2.0;
    } else if (tmp0_subject.equals(Direction_Center_getInstance())) {
      boxOriginXWithoutGapOffset = -boxWidth / 2.0;
      boxOriginYWithoutGapOffset = -boxHeight / 2.0;
    } else if (tmp0_subject.equals(Direction_CenterRight_getInstance())) {
      boxOriginXWithoutGapOffset = -boxWidth;
      boxOriginYWithoutGapOffset = -boxHeight / 2.0;
    } else if (tmp0_subject.equals(Direction_BaseLineLeft_getInstance())) {
      boxOriginXWithoutGapOffset = 0.0;
      boxOriginYWithoutGapOffset = -fontMetrics._accentLine - boxStyle._padding._top;
    } else if (tmp0_subject.equals(Direction_BaseLineCenter_getInstance())) {
      boxOriginXWithoutGapOffset = -boxWidth / 2.0;
      boxOriginYWithoutGapOffset = -fontMetrics._accentLine - boxStyle._padding._top;
    } else if (tmp0_subject.equals(Direction_BaseLineRight_getInstance())) {
      boxOriginXWithoutGapOffset = -boxWidth;
      boxOriginYWithoutGapOffset = -fontMetrics._accentLine - boxStyle._padding._top;
    } else if (tmp0_subject.equals(Direction_BottomLeft_getInstance())) {
      boxOriginXWithoutGapOffset = 0.0;
      boxOriginYWithoutGapOffset = -boxHeight;
    } else if (tmp0_subject.equals(Direction_BottomCenter_getInstance())) {
      boxOriginXWithoutGapOffset = -boxWidth / 2.0;
      boxOriginYWithoutGapOffset = -boxHeight;
    } else if (tmp0_subject.equals(Direction_BottomRight_getInstance())) {
      boxOriginXWithoutGapOffset = -boxWidth;
      boxOriginYWithoutGapOffset = -boxHeight;
    } else {
      noWhenBranchMatchedException();
    }
    var offsetForGapX = calculateXOffsetForGap_0(anchorDirection, anchorGap);
    var offsetForGapY = calculateYOffsetForGap_0(anchorDirection, anchorGap);
    var boxOriginX = boxOriginXWithoutGapOffset + offsetForGapX;
    var boxOriginY = boxOriginYWithoutGapOffset + offsetForGapY;
    var tmp1_subject = anchorDirection._horizontalAlignment;
    var tmp;
    if (tmp1_subject.equals(HorizontalAlignment_Left_getInstance())) {
      var tmp2_subject = horizontalAlignment;
      var tmp_0;
      if (tmp2_subject.equals(HorizontalAlignment_Left_getInstance())) {
        tmp_0 = boxStyle._padding._left;
      } else if (tmp2_subject.equals(HorizontalAlignment_Center_getInstance())) {
        tmp_0 = boxStyle._padding._left + textBlockWidth / 2.0;
      } else if (tmp2_subject.equals(HorizontalAlignment_Right_getInstance())) {
        tmp_0 = boxStyle._padding._left + textBlockWidth;
      } else {
        noWhenBranchMatchedException();
      }
      tmp = tmp_0;
    } else if (tmp1_subject.equals(HorizontalAlignment_Center_getInstance())) {
      var tmp3_subject = horizontalAlignment;
      var tmp_1;
      if (tmp3_subject.equals(HorizontalAlignment_Left_getInstance())) {
        tmp_1 = -textBlockWidth / 2.0;
      } else if (tmp3_subject.equals(HorizontalAlignment_Center_getInstance())) {
        tmp_1 = 0.0;
      } else if (tmp3_subject.equals(HorizontalAlignment_Right_getInstance())) {
        tmp_1 = textBlockWidth / 2.0;
      } else {
        noWhenBranchMatchedException();
      }
      tmp = tmp_1;
    } else if (tmp1_subject.equals(HorizontalAlignment_Right_getInstance())) {
      var tmp4_subject = horizontalAlignment;
      var tmp_2;
      if (tmp4_subject.equals(HorizontalAlignment_Left_getInstance())) {
        tmp_2 = -boxStyle._padding._right - textBlockWidth;
      } else if (tmp4_subject.equals(HorizontalAlignment_Center_getInstance())) {
        tmp_2 = -boxStyle._padding._right - textBlockWidth / 2.0;
      } else if (tmp4_subject.equals(HorizontalAlignment_Right_getInstance())) {
        tmp_2 = -boxStyle._padding._right;
      } else {
        noWhenBranchMatchedException();
      }
      tmp = tmp_2;
    } else {
      noWhenBranchMatchedException();
    }
    var textX = tmp + calculateXOffsetForGap_0(anchorDirection, anchorGap);
    var textY = boxOriginY + boxStyle._padding._top + fontMetrics._accentLine;
    var boxBounds = Rectangle_init_$Create$(boxOriginX, boxOriginY, boxWidth, boxHeight);
    var tmp5_safe_receiver = boxStyle._fill_0;
    if (tmp5_safe_receiver == null)
      null;
    else {
      _this_.fill_5(tmp5_safe_receiver);
      fillRoundedRect(_this_, boxBounds, boxStyle._radii);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp6_safe_receiver = boxStyle._border;
    if (tmp6_safe_receiver == null)
      null;
    else {
      _this_.stroke_5(tmp6_safe_receiver);
      _this_._set_lineWidth__1(boxStyle._borderWidth);
      var tmp_3 = boxStyle._radii;
      strokeRoundedRect$default(_this_, boxBounds, tmp_3, null, 4, null);
      Unit_getInstance();
    }
    Unit_getInstance();
    var currentTextY = textY;
    var tmp7_safe_receiver = textColor;
    if (tmp7_safe_receiver == null)
      null;
    else {
      _this_.fill_5(textColor);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp0_iterator_1 = lines.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      var tmp_4 = currentTextY;
      var tmp_5 = toAnchor(horizontalAlignment);
      _this_.fillText$default_0(element_2, textX, tmp_4, tmp_5, 0.0, maxStringWidth, null, null, 192, null);
      currentTextY = currentTextY + (fontMetrics._get_totalHeight_() + spaceBetweenLines);
    }
    return boxBounds;
  }
  function fillRoundedRect(_this_, bounds, radii) {
    fillRoundedRect_0(_this_, bounds._get_left__32(), bounds._get_top__32(), bounds._get_widthAbs_(), bounds._get_heightAbs_(), radii);
  }
  function strokeRoundedRect(_this_, bounds, radii, strokeLocation) {
    strokeRoundedRect_0(_this_, bounds._get_left__32(), bounds._get_top__32(), bounds._get_widthAbs_(), bounds._get_heightAbs_(), radii, strokeLocation);
  }
  function strokeRoundedRect$default(_this_, bounds, radii, strokeLocation, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      strokeLocation = StrokeLocation_Center_getInstance();
    return strokeRoundedRect(_this_, bounds, radii, strokeLocation);
  }
  function toAnchor(_this_) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(HorizontalAlignment_Left_getInstance())) {
      tmp = Direction_BaseLineLeft_getInstance();
    } else if (tmp0_subject.equals(HorizontalAlignment_Right_getInstance())) {
      tmp = Direction_BaseLineRight_getInstance();
    } else if (tmp0_subject.equals(HorizontalAlignment_Center_getInstance())) {
      tmp = Direction_BaseLineCenter_getInstance();
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function fillRoundedRect_0(_this_, x, y, width, height, radii) {
    if (radii == null ? true : radii._get_isEmpty___error()) {
      _this_.fillRect$default_0(x, y, width, height, null, null, 48, null);
    } else {
      roundedRect$default(_this_, x, y, width, height, radii.topLeft__error, radii.topRight__error, radii.bottomRight__error, radii.bottomLeft__error, null, 256, null);
      _this_.fill_4();
    }
  }
  function strokeRoundedRect_0(_this_, x, y, width, height, radii, strokeLocation) {
    if (radii == null ? true : radii._get_isEmpty___error()) {
      _this_.strokeRect_2(x, y, width, height, strokeLocation);
    } else {
      roundedRect(_this_, x, y, width, height, radii.topLeft__error, radii.topRight__error, radii.bottomRight__error, radii.bottomLeft__error, strokeLocation);
      _this_.stroke_4();
    }
  }
  function roundedRect(_this_, x, y, width, height, radiusTopLeft, radiusTopRight, radiusBottomRight, radiusBottomLeft, strokeLocation) {
    var calculatedWidth;
    var calculatedX;
    if (width >= 0.0) {
      calculatedWidth = width;
      calculatedX = x;
    } else {
      calculatedWidth = -width;
      calculatedX = x - calculatedWidth;
    }
    var calculatedHeight;
    var calculatedY;
    if (height < 0.0) {
      calculatedHeight = -height;
      calculatedY = y - calculatedHeight;
    } else {
      calculatedHeight = height;
      calculatedY = y;
    }
    var tmp0_subject = strokeLocation;
    if (tmp0_subject.equals(StrokeLocation_Center_getInstance())) {
    } else if (tmp0_subject.equals(StrokeLocation_Inside_getInstance())) {
      var currentLineWidth = _this_._get_lineWidth__1();
      calculatedX = calculatedX + currentLineWidth / 2.0;
      calculatedY = calculatedY + currentLineWidth / 2.0;
      calculatedWidth = calculatedWidth - currentLineWidth;
      calculatedHeight = calculatedHeight - currentLineWidth;
    } else if (tmp0_subject.equals(StrokeLocation_Outside_getInstance())) {
      var currentLineWidth_0 = _this_._get_lineWidth__1();
      calculatedX = calculatedX - currentLineWidth_0 / 2.0;
      calculatedY = calculatedY - currentLineWidth_0 / 2.0;
      calculatedWidth = calculatedWidth + currentLineWidth_0;
      calculatedHeight = calculatedHeight + currentLineWidth_0;
    }roundedRectInternal(_this_, calculatedX, calculatedY, calculatedWidth, calculatedHeight, radiusTopLeft, radiusTopRight, radiusBottomRight, radiusBottomLeft);
  }
  function roundedRect$default(_this_, x, y, width, height, radiusTopLeft, radiusTopRight, radiusBottomRight, radiusBottomLeft, strokeLocation, $mask0, $handler) {
    if (!(($mask0 & 256) === 0))
      strokeLocation = StrokeLocation_Center_getInstance();
    return roundedRect(_this_, x, y, width, height, radiusTopLeft, radiusTopRight, radiusBottomRight, radiusBottomLeft, strokeLocation);
  }
  function roundedRectInternal(_this_, x, y, width, height, radiusTopLeft, radiusTopRight, radiusBottomRight, radiusBottomLeft) {
    if (((((width < 0.0 ? true : height < 0.0) ? true : width < radiusTopLeft + radiusTopRight) ? true : width < radiusBottomLeft + radiusBottomRight) ? true : height < radiusTopLeft + radiusBottomLeft) ? true : height < radiusTopRight + radiusBottomRight) {
      _this_.beginPath_2();
      _this_.rect_2(x, y, width, height);
      _this_.closePath_2();
      return Unit_getInstance();
    }var tmp0_require_0 = isPositive(width);
    if (!tmp0_require_0) {
      var message_1 = '' + 'width must be > 0 but was <' + width + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp1_require_0 = isPositive(height);
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'height must be > 0 but was <' + height + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
    }var tmp2_require_0 = isPositiveOrZero(radiusTopLeft);
    if (!tmp2_require_0) {
      var message_1_1 = '' + 'radiusTopLeft must be >= 0 but was <' + radiusTopLeft + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_1));
    }var tmp3_require_0 = isPositiveOrZero(radiusTopRight);
    if (!tmp3_require_0) {
      var message_1_2 = '' + 'radiusTopRight must be >= 0 but was <' + radiusTopRight + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_2));
    }var tmp4_require_0 = isPositiveOrZero(radiusBottomRight);
    if (!tmp4_require_0) {
      var message_1_3 = '' + 'radiusBottomRight must be >= 0 but was <' + radiusBottomRight + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_3));
    }var tmp5_require_0 = isPositiveOrZero(radiusBottomLeft);
    if (!tmp5_require_0) {
      var message_1_4 = '' + 'radiusBottomLeft must be >= 0 but was <' + radiusBottomLeft + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_4));
    }var fixedRadiusTopLeft = coerceAtMost_0(coerceAtMost_0(radiusTopLeft, width / 2.0), height / 2.0);
    var fixedRadiusTopRight = coerceAtMost_0(coerceAtMost_0(radiusTopRight, width / 2.0), height / 2.0);
    var fixedRadiusBottomRight = coerceAtMost_0(coerceAtMost_0(radiusBottomRight, width / 2.0), height / 2.0);
    var fixedRadiusBottomLeft = coerceAtMost_0(coerceAtMost_0(radiusBottomLeft, width / 2.0), height / 2.0);
    _this_.beginPath_2();
    _this_.moveTo_2(x, y + fixedRadiusTopLeft);
    _this_.arcTo_2(x, y, x + fixedRadiusTopLeft, y, radiusTopRight);
    _this_.arcTo_2(x + width, y, x + width, y + fixedRadiusTopRight, fixedRadiusTopRight);
    _this_.lineTo_2(x + width, y + height - fixedRadiusBottomRight);
    _this_.arcTo_2(x + width, y + height, x + width - fixedRadiusBottomRight, y + height, fixedRadiusBottomRight);
    _this_.lineTo_2(x + fixedRadiusBottomLeft, y + height);
    _this_.arcTo_2(x, y + height, x, y + height - fixedRadiusBottomLeft, fixedRadiusBottomLeft);
    _this_.closePath_2();
  }
  function PaintingStats(frameTimestamp, frameTimestampDelta, layerPaintDurations) {
    this._frameTimestamp_0 = frameTimestamp;
    this._frameTimestampDelta_0 = frameTimestampDelta;
    this._layerPaintDurations = layerPaintDurations;
  }
  PaintingStats.prototype.toString = function () {
    return '' + 'PaintingStats(frameTimestamp=' + this._frameTimestamp_0 + ', frameTimestampDelta=' + this._frameTimestampDelta_0 + ', layerPaintDurations=' + this._layerPaintDurations + ')';
  };
  PaintingStats.prototype.hashCode = function () {
    var result = getNumberHashCode(this._frameTimestamp_0);
    result = imul(result, 31) + getNumberHashCode(this._frameTimestampDelta_0) | 0;
    result = imul(result, 31) + this._layerPaintDurations.hashCode() | 0;
    return result;
  };
  PaintingStats.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PaintingStats))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof PaintingStats ? other : THROW_CCE();
    if (!equals_0(this._frameTimestamp_0, tmp0_other_with_cast._frameTimestamp_0))
      return false;
    if (!equals_0(this._frameTimestampDelta_0, tmp0_other_with_cast._frameTimestampDelta_0))
      return false;
    if (!this._layerPaintDurations.equals(tmp0_other_with_cast._layerPaintDurations))
      return false;
    return true;
  };
  PaintingStats.$metadata$ = {
    simpleName: 'PaintingStats',
    kind: 'class',
    interfaces: []
  };
  function LayerPaintDuration(key, layerDescription, duration) {
    this._key_0 = key;
    this._layerDescription = layerDescription;
    this._duration = duration;
  }
  LayerPaintDuration.prototype.toString = function () {
    return '' + 'LayerPaintDuration(key=' + this._key_0 + ', layerDescription=' + this._layerDescription + ', duration=' + this._duration + ')';
  };
  LayerPaintDuration.prototype.hashCode = function () {
    var result = hashCode(this._key_0);
    result = imul(result, 31) + getStringHashCode(this._layerDescription) | 0;
    result = imul(result, 31) + getNumberHashCode(this._duration) | 0;
    return result;
  };
  LayerPaintDuration.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof LayerPaintDuration))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof LayerPaintDuration ? other : THROW_CCE();
    if (!equals_0(this._key_0, tmp0_other_with_cast._key_0))
      return false;
    if (!(this._layerDescription === tmp0_other_with_cast._layerDescription))
      return false;
    if (!equals_0(this._duration, tmp0_other_with_cast._duration))
      return false;
    return true;
  };
  LayerPaintDuration.$metadata$ = {
    simpleName: 'LayerPaintDuration',
    kind: 'class',
    interfaces: []
  };
  function LayerPaintDurations(paintDurations) {
    this._paintDurations = paintDurations;
  }
  LayerPaintDurations.prototype.toString = function () {
    return '' + 'LayerPaintDurations(paintDurations=' + this._paintDurations + ')';
  };
  LayerPaintDurations.prototype.hashCode = function () {
    return hashCode(this._paintDurations);
  };
  LayerPaintDurations.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof LayerPaintDurations))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof LayerPaintDurations ? other : THROW_CCE();
    if (!equals_0(this._paintDurations, tmp0_other_with_cast._paintDurations))
      return false;
    return true;
  };
  LayerPaintDurations.$metadata$ = {
    simpleName: 'LayerPaintDurations',
    kind: 'class',
    interfaces: []
  };
  function PixelSnapSupport() {
    this._snapConfigurationProperty = new ObservableObject(SnapConfiguration_None_getInstance());
    this._snapConfiguration$delegate = this._snapConfigurationProperty;
  }
  PixelSnapSupport.$metadata$ = {
    simpleName: 'PixelSnapSupport',
    kind: 'class',
    interfaces: []
  };
  function QuickChart() {
  }
  QuickChart.prototype.dispose_46 = function () {
    this._get_chartSupport__3().dispose_46();
  };
  QuickChart.prototype.onDispose_10 = function (action) {
    this._get_chartSupport__3().onDispose_10(action);
  };
  QuickChart.prototype.markAsDirty_4 = function () {
    this._get_chartSupport__3().markAsDirty_4();
  };
  QuickChart.$metadata$ = {
    simpleName: 'QuickChart',
    kind: 'interface',
    interfaces: [Disposable, OnDispose]
  };
  function QuickChartBuilder_init_$Init$(description, quickChartFactory_0, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      quickChartFactory_0 = quickChartFactory();
    QuickChartBuilder.call($this, description, quickChartFactory_0);
    return $this;
  }
  function _no_name_provided__342($boundThis) {
    this._$boundThis_11 = $boundThis;
  }
  _no_name_provided__342.prototype.invoke_627 = function (p0) {
    return this._$boundThis_11.configure(p0);
  };
  _no_name_provided__342.prototype.invoke_1082 = function (p1) {
    this.invoke_627(p1 instanceof ZoomAndTranslationLayer ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__342.prototype._get_name__47 = function () {
    return 'configure';
  };
  _no_name_provided__342.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__343($boundThis) {
    this._$boundThis_12 = $boundThis;
  }
  _no_name_provided__343.prototype.invoke_627 = function (p0) {
    return this._$boundThis_12.configure(p0);
  };
  _no_name_provided__343.prototype.invoke_1082 = function (p1) {
    this.invoke_627(p1 instanceof ZoomAndTranslationLayer ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__343.prototype._get_name__47 = function () {
    return 'configure';
  };
  _no_name_provided__343.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function QuickChartBuilder(description, quickChartFactory_0) {
    this._description = description;
    this._quickChartFactory = quickChartFactory_0;
    this._hasBeenBuilt = false;
    this._zoomAndTranslationModifier = Companion_getInstance_44()._none_2;
    this._zoomAndTranslationDefaults_0 = Companion_getInstance_45()._noTranslation;
    this._windowSizeBindingStrategy = ImmediateWindowSizeBindingStrategy_getInstance();
    this._contentAreaSizingStrategy = BindContentArea2WindowSize_init_$Create$();
    this._zoomChangeFactor_0 = 1.4142135623730951;
    this._enableZoomAndTranslation = true;
    var tmp = this;
    tmp._zoomAndTranslationConfiguration = _no_name_provided_$factory_322(new ZoomAndTranslationConfiguration());
    this._addToggleDebuggingModeLayer = true;
    var tmp_0 = this;
    tmp_0._layerSupportConfigurations = ArrayList_init_$Create$();
    var tmp_1 = this;
    tmp_1._disposeActions_0 = ArrayList_init_$Create$();
  }
  QuickChartBuilder.prototype.zoomAndTranslationDefaults_1 = function (provider) {
    this.zoomAndTranslationDefaults_2(provider());
  };
  QuickChartBuilder.prototype.zoomAndTranslationDefaults_2 = function (zoomAndTranslationDefaults) {
    this._zoomAndTranslationDefaults_0 = zoomAndTranslationDefaults;
  };
  QuickChartBuilder.prototype.zoomAndTranslationConfiguration_0 = function (config) {
    var tmp = this;
    var tmp0_also_0 = new ZoomAndTranslationConfiguration();
    config(tmp0_also_0);
    tmp._zoomAndTranslationConfiguration = _no_name_provided_$factory_323(tmp0_also_0);
  };
  QuickChartBuilder.prototype.createChartSupport_1 = function () {
    var tmp = this._quickChartFactory._get_canvasFactory__0();
    var tmp_0 = CanvasType_Main_getInstance();
    return this.createChartSupport_2(tmp.createCanvas$default(tmp_0, null, 2, null));
  };
  QuickChartBuilder.prototype.createChartSupport_2 = function (canvas) {
    var chartSupport = new ChartSupport(canvas, this._zoomAndTranslationModifier, this._zoomAndTranslationDefaults_0, this._zoomChangeFactor_0, this._windowSizeBindingStrategy);
    var layerSupport = _get_layerSupport_(chartSupport);
    if (this._addToggleDebuggingModeLayer) {
      layerSupport._get_layers__1().addLayer(new ToggleDebuggingModeLayer());
      Unit_getInstance();
    }if (this._enableZoomAndTranslation) {
      addZoomAndTranslation(layerSupport._get_layers__1(), chartSupport._zoomAndTranslationSupport_0, this._zoomAndTranslationConfiguration);
      Unit_getInstance();
    }var tmp0_fastForEach_0 = this._disposeActions_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      chartSupport.onDispose_10(tmp1__anonymous__4);
    }
    var tmp2_forEach_0 = this._layerSupportConfigurations;
    var tmp0_iterator_1 = tmp2_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(layerSupport);
    }
    this._contentAreaSizingStrategy.bindResize_2(chartSupport);
    this.onDispose_9(this._contentAreaSizingStrategy);
    return chartSupport;
  };
  QuickChartBuilder.prototype.configure_1 = function (configuration) {
    var tmp0_check_0 = !this._hasBeenBuilt;
    if (!tmp0_check_0) {
      var message_1 = 'do not call configure() after calling build()';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var tmp0_this = this;
    var tmp1_plusAssign_0 = tmp0_this._layerSupportConfigurations;
    tmp1_plusAssign_0.add_57(configuration);
    Unit_getInstance();
  };
  QuickChartBuilder.prototype.onDispose_10 = function (action) {
    var tmp0_this = this;
    var tmp0_plusAssign_0 = tmp0_this._disposeActions_0;
    tmp0_plusAssign_0.add_57(action);
    Unit_getInstance();
  };
  QuickChartBuilder.prototype.build_1 = function () {
    this._hasBeenBuilt = true;
    var chartSupport = this.createChartSupport_1();
    return this._quickChartFactory.createQuickChart_0(chartSupport, this._description);
  };
  QuickChartBuilder.$metadata$ = {
    simpleName: 'QuickChartBuilder',
    kind: 'class',
    interfaces: [OnDispose]
  };
  function _no_name_provided_$factory_322($boundThis) {
    var i = new _no_name_provided__342($boundThis);
    var l = function (p1) {
      i.invoke_627(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_323($boundThis) {
    var i = new _no_name_provided__343($boundThis);
    var l = function (p1) {
      i.invoke_627(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function QuickChartFactoryAccess() {
    QuickChartFactoryAccess_instance = this;
    this._factory = null;
  }
  QuickChartFactoryAccess.$metadata$ = {
    simpleName: 'QuickChartFactoryAccess',
    kind: 'object',
    interfaces: []
  };
  var QuickChartFactoryAccess_instance;
  function QuickChartFactoryAccess_getInstance() {
    if (QuickChartFactoryAccess_instance == null)
      new QuickChartFactoryAccess();
    return QuickChartFactoryAccess_instance;
  }
  function QuickChartFactory() {
  }
  QuickChartFactory.$metadata$ = {
    simpleName: 'QuickChartFactory',
    kind: 'interface',
    interfaces: []
  };
  function quickChartFactory() {
    var tmp0_elvis_lhs = QuickChartFactoryAccess_getInstance()._factory;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalStateException_init_$Create$_0('No quickChartFactory set - please call QuickChartPlatform.init()');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function QuickChartPlatformState() {
    QuickChartPlatformState_instance = this;
    var tmp = this;
    tmp._activeInstances = LinkedHashSet_init_$Create$();
    var tmp_0 = this;
    tmp_0._platformStateListeners = ArrayList_init_$Create$();
  }
  QuickChartPlatformState.prototype.newInstance = function (quickChart) {
    this._activeInstances.add_57(quickChart);
    Unit_getInstance();
    var tmp0_fastForEach_0 = this._platformStateListeners;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.instanceCreated__error(quickChart);
    }
  };
  QuickChartPlatformState.prototype.instanceDisposed = function (quickChart) {
    if (!this._activeInstances.remove_59(quickChart)) {
      throw IllegalStateException_init_$Create$_0('cannot remove an inactive QuickChart instance');
    }if (this._activeInstances.isEmpty_33()) {
      var tmp0_fastForEach_0 = this._platformStateListeners;
      var n_1 = 0;
      var currentSize_2 = tmp0_fastForEach_0._get_size__43();
      while (n_1 < currentSize_2) {
        var tmp0_3 = n_1;
        n_1 = tmp0_3 + 1 | 0;
        var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
        tmp1__anonymous__4.lastInstanceDisposed__error();
      }
    }};
  QuickChartPlatformState.$metadata$ = {
    simpleName: 'QuickChartPlatformState',
    kind: 'object',
    interfaces: []
  };
  var QuickChartPlatformState_instance;
  function QuickChartPlatformState_getInstance() {
    if (QuickChartPlatformState_instance == null)
      new QuickChartPlatformState();
    return QuickChartPlatformState_instance;
  }
  var SnapConfiguration_None_instance;
  var SnapConfiguration_OnlyX_instance;
  var SnapConfiguration_OnlyY_instance;
  var SnapConfiguration_Both_instance;
  var SnapConfiguration_entriesInitialized;
  function SnapConfiguration_initEntries() {
    if (SnapConfiguration_entriesInitialized)
      return Unit_getInstance();
    SnapConfiguration_entriesInitialized = true;
    SnapConfiguration_None_instance = new SnapConfiguration('None', 0, false, false);
    SnapConfiguration_OnlyX_instance = new SnapConfiguration('OnlyX', 1, true, false);
    SnapConfiguration_OnlyY_instance = new SnapConfiguration('OnlyY', 2, false, true);
    SnapConfiguration_Both_instance = new SnapConfiguration('Both', 3, true, true);
  }
  function SnapConfiguration(name, ordinal, snapX, snapY) {
    Enum.call(this, name, ordinal);
    this._snapX = snapX;
    this._snapY = snapY;
  }
  SnapConfiguration.$metadata$ = {
    simpleName: 'SnapConfiguration',
    kind: 'class',
    interfaces: []
  };
  function SnapConfiguration_None_getInstance() {
    SnapConfiguration_initEntries();
    return SnapConfiguration_None_instance;
  }
  function TruncateToLength() {
    TruncateToLength_instance = this;
  }
  TruncateToLength.prototype.shorten_2 = function (text_0, maxCharacters, truncationSymbol) {
    return truncateToLength(text_0, maxCharacters, truncationSymbol);
  };
  TruncateToLength.prototype.toString = function () {
    return 'Truncate to length';
  };
  TruncateToLength.$metadata$ = {
    simpleName: 'TruncateToLength',
    kind: 'object',
    interfaces: [StringShortener]
  };
  var TruncateToLength_instance;
  function TruncateToLength_getInstance() {
    if (TruncateToLength_instance == null)
      new TruncateToLength();
    return TruncateToLength_instance;
  }
  function StringShortener() {
  }
  StringShortener.prototype.shorten$default_0 = function (text_0, maxCharacters, truncationSymbol, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      truncationSymbol = '\u2026';
    return $handler == null ? this.shorten_2(text_0, maxCharacters, truncationSymbol) : $handler(text_0, maxCharacters, truncationSymbol);
  };
  StringShortener.$metadata$ = {
    simpleName: 'StringShortener',
    kind: 'interface',
    interfaces: []
  };
  function truncateToLength(text_0, maxCharacters, truncationSymbol) {
    if (maxCharacters === 0) {
      return null;
    }if (text_0.length <= maxCharacters) {
      return text_0;
    }if (truncationSymbol.length > maxCharacters) {
      return '!';
    }if (maxCharacters < truncationSymbol.length) {
      return take(text_0, maxCharacters);
    }var tmp0_trim_0 = take(text_0, maxCharacters - truncationSymbol.length | 0);
    return toString_2(trim(isCharSequence(tmp0_trim_0) ? tmp0_trim_0 : THROW_CCE())) + truncationSymbol;
  }
  function Companion_62() {
    Companion_instance_61 = this;
    this._unlimited = new TargetRefreshRate(null);
    this._veryFast60 = new TargetRefreshRate(60.0);
    this._fast30 = new TargetRefreshRate(30.0);
    this._slow15 = new TargetRefreshRate(15.0);
    this._verySlow5 = new TargetRefreshRate(5.0);
    this._halfSecond = new TargetRefreshRate(2.0);
    this._onceASecond = new TargetRefreshRate(1.0);
    this._everyTwoSeconds = new TargetRefreshRate(0.5);
    this._everyFiveSeconds = new TargetRefreshRate(0.2);
    this._predefined = listOf([this._unlimited, this._veryFast60, this._fast30, this._slow15, this._verySlow5, this._halfSecond, this._onceASecond, this._everyTwoSeconds, this._everyFiveSeconds]);
  }
  Companion_62.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_61;
  function Companion_getInstance_61() {
    if (Companion_instance_61 == null)
      new Companion_62();
    return Companion_instance_61;
  }
  function TargetRefreshRate(refreshRate) {
    Companion_getInstance_61();
    this._refreshRate = refreshRate;
    var tmp0_require_0 = this._refreshRate == null ? true : this._refreshRate > 0.0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Invalid refresh rate: <' + this._refreshRate + '>. Must be greater then <0.0>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp = this;
    var tmp0_safe_receiver = this._refreshRate;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      tmp_0 = 1000.0 / this._refreshRate;
    }
    tmp._distance = tmp_0;
  }
  TargetRefreshRate.prototype.toString = function () {
    return '' + 'TargetRefreshRate(refreshRate=' + this._refreshRate + ')';
  };
  TargetRefreshRate.prototype.hashCode = function () {
    return this._refreshRate == null ? 0 : getNumberHashCode(this._refreshRate);
  };
  TargetRefreshRate.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TargetRefreshRate))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof TargetRefreshRate ? other : THROW_CCE();
    if (!equals_0(this._refreshRate, tmp0_other_with_cast._refreshRate))
      return false;
    return true;
  };
  TargetRefreshRate.$metadata$ = {
    simpleName: 'TargetRefreshRate',
    kind: 'class',
    interfaces: []
  };
  function TooltipSupport_init_$Init$(_tooltip, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      _tooltip = new ObservableObject(null);
    TooltipSupport.call($this, _tooltip);
    return $this;
  }
  function TooltipSupport_init_$Create$(_tooltip, $mask0, $marker) {
    return TooltipSupport_init_$Init$(_tooltip, $mask0, $marker, Object.create(TooltipSupport.prototype));
  }
  function TooltipSupport(_tooltip) {
    this.__tooltip = _tooltip;
    var tmp = this;
    tmp._toolTips = LinkedHashMap_init_$Create$();
  }
  TooltipSupport.prototype._get_tooltip_ = function () {
    return this.__tooltip;
  };
  TooltipSupport.$metadata$ = {
    simpleName: 'TooltipSupport',
    kind: 'class',
    interfaces: []
  };
  function WindowSizeBindingStrategy() {
  }
  WindowSizeBindingStrategy.$metadata$ = {
    simpleName: 'WindowSizeBindingStrategy',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided__344($chartState) {
    this._$chartState_1 = $chartState;
  }
  _no_name_provided__344.prototype.invoke_779 = function (it) {
    this._$chartState_1._set_windowSize__1(it);
  };
  _no_name_provided__344.prototype.invoke_1082 = function (p1) {
    this.invoke_779(p1 instanceof Size ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__344.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ImmediateWindowSizeBindingStrategy() {
    ImmediateWindowSizeBindingStrategy_instance = this;
  }
  ImmediateWindowSizeBindingStrategy.prototype.bind_1 = function (chartState, canvas, onDispose) {
    var tmp = canvas._get_sizeProperty__1();
    tmp.consumeImmediately_3(_no_name_provided_$factory_324(chartState));
    Unit_getInstance();
  };
  ImmediateWindowSizeBindingStrategy.prototype.toString = function () {
    return 'ImmediateWindowSizeBindingStrategy';
  };
  ImmediateWindowSizeBindingStrategy.$metadata$ = {
    simpleName: 'ImmediateWindowSizeBindingStrategy',
    kind: 'object',
    interfaces: [WindowSizeBindingStrategy]
  };
  var ImmediateWindowSizeBindingStrategy_instance;
  function ImmediateWindowSizeBindingStrategy_getInstance() {
    if (ImmediateWindowSizeBindingStrategy_instance == null)
      new ImmediateWindowSizeBindingStrategy();
    return ImmediateWindowSizeBindingStrategy_instance;
  }
  function _no_name_provided_$factory_324($chartState) {
    var i = new _no_name_provided__344($chartState);
    return function (p1) {
      i.invoke_779(p1);
      return Unit_getInstance();
    };
  }
  function Choice() {
  }
  Choice.$metadata$ = {
    simpleName: 'Choice',
    kind: 'class',
    interfaces: []
  };
  function choices$factory() {
    return getPropertyCallableRef('choices', 1, KMutableProperty1, function (receiver) {
      return receiver._get_choices___error();
    }, function (receiver_0, value) {
      return receiver_0._set_choices___error(value);
    });
  }
  function choices$factory_0() {
    return getPropertyCallableRef('choices', 1, KMutableProperty1, function (receiver) {
      return receiver._get_choices___error();
    }, function (receiver_0, value) {
      return receiver_0._set_choices___error(value);
    });
  }
  function converter$factory() {
    return getPropertyCallableRef('converter', 1, KMutableProperty1, function (receiver) {
      return receiver._get_converter___error();
    }, function (receiver_0, value) {
      return receiver_0._set_converter___error(value);
    });
  }
  function converter$factory_0() {
    return getPropertyCallableRef('converter', 1, KMutableProperty1, function (receiver) {
      return receiver._get_converter___error();
    }, function (receiver_0, value) {
      return receiver_0._set_converter___error(value);
    });
  }
  function NativeComponentsSupport() {
    var tmp = this;
    tmp._nativeComponentsHandlers = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._textInputs = ArrayList_init_$Create$();
    var tmp_1 = this;
    tmp_1._checkBoxes = ArrayList_init_$Create$();
    var tmp_2 = this;
    tmp_2._comboBoxes = ArrayList_init_$Create$();
  }
  NativeComponentsSupport.prototype.onComponent = function (listener) {
    this._nativeComponentsHandlers.add_57(listener);
    Unit_getInstance();
    var tmp0_forEach_0 = this._textInputs;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      listener.textInput_0(element_2);
    }
    var tmp1_forEach_0 = this._checkBoxes;
    var tmp0_iterator_1_0 = tmp1_forEach_0.iterator_38();
    while (tmp0_iterator_1_0.hasNext_11()) {
      var element_2_0 = tmp0_iterator_1_0.next_13();
      listener.checkBox_0(element_2_0);
    }
    var tmp2_forEach_0 = this._comboBoxes;
    var tmp0_iterator_1_1 = tmp2_forEach_0.iterator_38();
    while (tmp0_iterator_1_1.hasNext_11()) {
      var element_2_1 = tmp0_iterator_1_1.next_13();
      listener.comboBox_0(element_2_1);
    }
  };
  NativeComponentsSupport.$metadata$ = {
    simpleName: 'NativeComponentsSupport',
    kind: 'class',
    interfaces: []
  };
  function NativeComponentsHandler() {
  }
  NativeComponentsHandler.$metadata$ = {
    simpleName: 'NativeComponentsHandler',
    kind: 'interface',
    interfaces: []
  };
  function CanvasKeyEventHandler() {
  }
  CanvasKeyEventHandler.prototype.onDown_21 = function (event, chartSupport) {
  };
  CanvasKeyEventHandler.prototype.onUp_21 = function (event, chartSupport) {
  };
  CanvasKeyEventHandler.prototype.onType_5 = function (event, chartSupport) {
  };
  CanvasKeyEventHandler.$metadata$ = {
    simpleName: 'CanvasKeyEventHandler',
    kind: 'interface',
    interfaces: []
  };
  function CanvasMouseEventHandler() {
  }
  CanvasMouseEventHandler.prototype.onClick_8 = function (event, chartSupport) {
  };
  CanvasMouseEventHandler.prototype.onDown_20 = function (event, chartSupport) {
  };
  CanvasMouseEventHandler.prototype.onUp_20 = function (event, chartSupport) {
  };
  CanvasMouseEventHandler.prototype.onDoubleClick_8 = function (event, chartSupport) {
  };
  CanvasMouseEventHandler.prototype.onMove_20 = function (event, chartSupport) {
  };
  CanvasMouseEventHandler.prototype.onDrag_13 = function (event, chartSupport) {
  };
  CanvasMouseEventHandler.prototype.onWheel_7 = function (event, chartSupport) {
  };
  CanvasMouseEventHandler.$metadata$ = {
    simpleName: 'CanvasMouseEventHandler',
    kind: 'interface',
    interfaces: []
  };
  function CanvasMouseEventHandlerBroker() {
    var tmp = this;
    tmp._delegates = ArrayList_init_$Create$();
  }
  CanvasMouseEventHandlerBroker.prototype.delegate_0 = function (eventHandler) {
    this._delegates.add_57(eventHandler);
    Unit_getInstance();
  };
  CanvasMouseEventHandlerBroker.prototype.onClick_8 = function (event, chartSupport) {
    CanvasMouseEventHandler.prototype.onClick_8.call(this, event, chartSupport);
    var tmp0_fastForEach_0 = this._delegates;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onClick_8(event, chartSupport);
    }
  };
  CanvasMouseEventHandlerBroker.prototype.onDown_20 = function (event, chartSupport) {
    CanvasMouseEventHandler.prototype.onDown_20.call(this, event, chartSupport);
    var tmp0_fastForEach_0 = this._delegates;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onDown_20(event, chartSupport);
    }
  };
  CanvasMouseEventHandlerBroker.prototype.onUp_20 = function (event, chartSupport) {
    CanvasMouseEventHandler.prototype.onUp_20.call(this, event, chartSupport);
    var tmp0_fastForEach_0 = this._delegates;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onUp_20(event, chartSupport);
    }
  };
  CanvasMouseEventHandlerBroker.prototype.onDoubleClick_8 = function (event, chartSupport) {
    CanvasMouseEventHandler.prototype.onDoubleClick_8.call(this, event, chartSupport);
    var tmp0_fastForEach_0 = this._delegates;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onDoubleClick_8(event, chartSupport);
    }
  };
  CanvasMouseEventHandlerBroker.prototype.onMove_20 = function (event, chartSupport) {
    CanvasMouseEventHandler.prototype.onMove_20.call(this, event, chartSupport);
    var tmp0_fastForEach_0 = this._delegates;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onMove_20(event, chartSupport);
    }
  };
  CanvasMouseEventHandlerBroker.prototype.onDrag_13 = function (event, chartSupport) {
    CanvasMouseEventHandler.prototype.onDrag_13.call(this, event, chartSupport);
    var tmp0_fastForEach_0 = this._delegates;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onDrag_13(event, chartSupport);
    }
  };
  CanvasMouseEventHandlerBroker.prototype.onWheel_7 = function (event, chartSupport) {
    CanvasMouseEventHandler.prototype.onWheel_7.call(this, event, chartSupport);
    var tmp0_fastForEach_0 = this._delegates;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onWheel_7(event, chartSupport);
    }
  };
  CanvasMouseEventHandlerBroker.$metadata$ = {
    simpleName: 'CanvasMouseEventHandlerBroker',
    kind: 'class',
    interfaces: [CanvasMouseEventHandler]
  };
  function CanvasPointerEventHandler() {
  }
  CanvasPointerEventHandler.prototype.onOver_5 = function (event, chartSupport) {
  };
  CanvasPointerEventHandler.prototype.onEnter_5 = function (event, chartSupport) {
  };
  CanvasPointerEventHandler.prototype.onDown_18 = function (event, chartSupport) {
  };
  CanvasPointerEventHandler.prototype.onMove_18 = function (event, chartSupport) {
  };
  CanvasPointerEventHandler.prototype.onUp_18 = function (event, chartSupport) {
  };
  CanvasPointerEventHandler.prototype.onCancel_11 = function (event, chartSupport) {
  };
  CanvasPointerEventHandler.prototype.onOut_5 = function (event, chartSupport) {
  };
  CanvasPointerEventHandler.prototype.onLeave_5 = function (event, chartSupport) {
  };
  CanvasPointerEventHandler.$metadata$ = {
    simpleName: 'CanvasPointerEventHandler',
    kind: 'interface',
    interfaces: []
  };
  function CanvasPointerEventHandlerBroker() {
    var tmp = this;
    tmp._delegates_0 = ArrayList_init_$Create$();
  }
  CanvasPointerEventHandlerBroker.prototype.delegate_1 = function (eventHandler) {
    this._delegates_0.add_57(eventHandler);
    Unit_getInstance();
  };
  CanvasPointerEventHandlerBroker.prototype.onOver_5 = function (event, chartSupport) {
    var tmp0_fastForEach_0 = this._delegates_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onOver_5(event, chartSupport);
    }
  };
  CanvasPointerEventHandlerBroker.prototype.onEnter_5 = function (event, chartSupport) {
    var tmp0_fastForEach_0 = this._delegates_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onEnter_5(event, chartSupport);
    }
  };
  CanvasPointerEventHandlerBroker.prototype.onDown_18 = function (event, chartSupport) {
    var tmp0_fastForEach_0 = this._delegates_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onDown_18(event, chartSupport);
    }
  };
  CanvasPointerEventHandlerBroker.prototype.onMove_18 = function (event, chartSupport) {
    var tmp0_fastForEach_0 = this._delegates_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onMove_18(event, chartSupport);
    }
  };
  CanvasPointerEventHandlerBroker.prototype.onUp_18 = function (event, chartSupport) {
    var tmp0_fastForEach_0 = this._delegates_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onUp_18(event, chartSupport);
    }
  };
  CanvasPointerEventHandlerBroker.prototype.onCancel_11 = function (event, chartSupport) {
    var tmp0_fastForEach_0 = this._delegates_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onCancel_11(event, chartSupport);
    }
  };
  CanvasPointerEventHandlerBroker.prototype.onOut_5 = function (event, chartSupport) {
    var tmp0_fastForEach_0 = this._delegates_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onOut_5(event, chartSupport);
    }
  };
  CanvasPointerEventHandlerBroker.prototype.onLeave_5 = function (event, chartSupport) {
    var tmp0_fastForEach_0 = this._delegates_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onLeave_5(event, chartSupport);
    }
  };
  CanvasPointerEventHandlerBroker.$metadata$ = {
    simpleName: 'CanvasPointerEventHandlerBroker',
    kind: 'class',
    interfaces: [CanvasPointerEventHandler]
  };
  function CanvasTouchEventHandler() {
  }
  CanvasTouchEventHandler.prototype.onStart_3 = function (event, chartSupport) {
  };
  CanvasTouchEventHandler.prototype.onEnd_1 = function (event, chartSupport) {
  };
  CanvasTouchEventHandler.prototype.onMove_8 = function (event, chartSupport) {
  };
  CanvasTouchEventHandler.prototype.onCancel_4 = function (event, chartSupport) {
  };
  CanvasTouchEventHandler.$metadata$ = {
    simpleName: 'CanvasTouchEventHandler',
    kind: 'interface',
    interfaces: []
  };
  function MutableInterpolable() {
  }
  MutableInterpolable.$metadata$ = {
    simpleName: 'MutableInterpolable',
    kind: 'interface',
    interfaces: []
  };
  function Interpolable() {
  }
  Interpolable.$metadata$ = {
    simpleName: 'Interpolable',
    kind: 'interface',
    interfaces: []
  };
  function Matrix_init_$Init$(a, b, c, d, tx, ty, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      a = 1.0;
    if (!(($mask0 & 2) === 0))
      b = 0.0;
    if (!(($mask0 & 4) === 0))
      c = 0.0;
    if (!(($mask0 & 8) === 0))
      d = 1.0;
    if (!(($mask0 & 16) === 0))
      tx = 0.0;
    if (!(($mask0 & 32) === 0))
      ty = 0.0;
    Matrix.call($this, a, b, c, d, tx, ty);
    return $this;
  }
  function Matrix_init_$Create$(a, b, c, d, tx, ty, $mask0, $marker) {
    return Matrix_init_$Init$(a, b, c, d, tx, ty, $mask0, $marker, Object.create(Matrix.prototype));
  }
  function Companion_63() {
    Companion_instance_62 = this;
  }
  Companion_63.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_62;
  function Companion_getInstance_62() {
    if (Companion_instance_62 == null)
      new Companion_63();
    return Companion_instance_62;
  }
  function Matrix(a, b, c, d, tx, ty) {
    Companion_getInstance_62();
    this._a = a;
    this._b = b;
    this._c = c;
    this._d = d;
    this._tx = tx;
    this._ty = ty;
  }
  Matrix.prototype.setTo = function (a, b, c, d, tx, ty) {
    this._a = a;
    this._b = b;
    this._c = c;
    this._d = d;
    this._tx = tx;
    this._ty = ty;
    return this;
  };
  Matrix.prototype.copyFrom = function (that) {
    this.setTo(that._a, that._b, that._c, that._d, that._tx, that._ty);
    Unit_getInstance();
    return this;
  };
  Matrix.prototype.reset_1 = function () {
    return this.setTo(1.0, 0.0, 0.0, 1.0, 0.0, 0.0);
  };
  Matrix.prototype.toString = function () {
    return '' + 'Matrix(a=' + this._a + ', b=' + this._b + ', c=' + this._c + ', d=' + this._d + ', tx=' + this._tx + ', ty=' + this._ty + ')';
  };
  Matrix.prototype.hashCode = function () {
    var result = getNumberHashCode(this._a);
    result = imul(result, 31) + getNumberHashCode(this._b) | 0;
    result = imul(result, 31) + getNumberHashCode(this._c) | 0;
    result = imul(result, 31) + getNumberHashCode(this._d) | 0;
    result = imul(result, 31) + getNumberHashCode(this._tx) | 0;
    result = imul(result, 31) + getNumberHashCode(this._ty) | 0;
    return result;
  };
  Matrix.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Matrix))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Matrix ? other : THROW_CCE();
    if (!equals_0(this._a, tmp0_other_with_cast._a))
      return false;
    if (!equals_0(this._b, tmp0_other_with_cast._b))
      return false;
    if (!equals_0(this._c, tmp0_other_with_cast._c))
      return false;
    if (!equals_0(this._d, tmp0_other_with_cast._d))
      return false;
    if (!equals_0(this._tx, tmp0_other_with_cast._tx))
      return false;
    if (!equals_0(this._ty, tmp0_other_with_cast._ty))
      return false;
    return true;
  };
  Matrix.$metadata$ = {
    simpleName: 'Matrix',
    kind: 'class',
    interfaces: [MutableInterpolable, Interpolable]
  };
  function ButtonState() {
  }
  ButtonState.$metadata$ = {
    simpleName: 'ButtonState',
    kind: 'class',
    interfaces: []
  };
  function ButtonColorProvider() {
  }
  ButtonColorProvider.$metadata$ = {
    simpleName: 'ButtonColorProvider',
    kind: 'interface',
    interfaces: []
  };
  function DefaultButtonColorProvider(disabledColor, pressedColor, hoverColor, focusedColor, defaultColor) {
    this._disabledColor = disabledColor;
    this._pressedColor = pressedColor;
    this._hoverColor = hoverColor;
    this._focusedColor = focusedColor;
    this._defaultColor = defaultColor;
  }
  DefaultButtonColorProvider.$metadata$ = {
    simpleName: 'DefaultButtonColorProvider',
    kind: 'class',
    interfaces: [ButtonColorProvider]
  };
  function SingleButtonColorProvider(buttonColor) {
    this._buttonColor = buttonColor;
  }
  SingleButtonColorProvider.$metadata$ = {
    simpleName: 'SingleButtonColorProvider',
    kind: 'class',
    interfaces: [ButtonColorProvider]
  };
  function Paintable() {
  }
  Paintable.prototype.paintInBoundingBox_3 = function (paintingContext, x, y, anchorDirection, gap, width, height, objectFit) {
    var alignmentX = x + calculateXWithAnchor(paintingContext._gc, width, gap, anchorDirection._horizontalAlignment);
    var alignmentY = y + calculateYWithAnchor(height, gap, anchorDirection._verticalAlignment, paintingContext._gc);
    var currentBoundingBox = this.boundingBox_4(paintingContext._get_chartSupport__3());
    var size = currentBoundingBox._size_2;
    var tmp0_subject = objectFit;
    if (tmp0_subject.equals(ObjectFit_None_getInstance())) {
      var paintableAlignment = currentBoundingBox._location;
      this.paint_13(paintingContext, alignmentX - paintableAlignment._x, alignmentY - paintableAlignment._y);
    } else if (tmp0_subject.equals(ObjectFit_ContainNoGrow_getInstance())) {
      var targetSize = size.withMax(width, height).fitWithAspectRatio(size._get_aspectRatio__0());
      var scaleX = 1.0 / size._width_0 * targetSize._width_0;
      var scaleY = 1.0 / size._height * targetSize._height;
      var deltaX = size._width_0 * scaleX - width;
      var deltaY = size._height * scaleY - height;
      this.paintSizeForced_3(paintingContext, alignmentX - deltaX / 2.0, alignmentY - deltaY / 2.0, targetSize);
    } else if (tmp0_subject.equals(ObjectFit_Contain_getInstance())) {
      var targetSize_0 = (new Size(width, height)).fitWithAspectRatio(size._get_aspectRatio__0());
      var scaleX_0 = 1.0 / size._width_0 * targetSize_0._width_0;
      var scaleY_0 = 1.0 / size._height * targetSize_0._height;
      var deltaX_0 = size._width_0 * scaleX_0 - width;
      var deltaY_0 = size._height * scaleY_0 - height;
      this.paintSizeForced_3(paintingContext, alignmentX - deltaX_0 / 2.0, alignmentY - deltaY_0 / 2.0, targetSize_0);
    } else if (tmp0_subject.equals(ObjectFit_Fill_getInstance())) {
      this.paintSizeForced_3(paintingContext, alignmentX, alignmentY, new Size(width, height));
    }};
  Paintable.prototype.paintSizeForced_3 = function (paintingContext, x, y, forcedSize) {
    var currentBoundingBox = this.boundingBox_4(paintingContext._get_chartSupport__3());
    var size = currentBoundingBox._size_2;
    var scaleX = 1.0 / size._width_0 * forcedSize._width_0;
    var scaleY = 1.0 / size._height * forcedSize._height;
    var gc = paintingContext._gc;
    gc.translate_35(x, y);
    gc.scale_3(scaleX, scaleY);
    var location = this.boundingBox_4(paintingContext._get_chartSupport__3())._location;
    this.paint_13(paintingContext, -location._x, -location._y);
  };
  Paintable.$metadata$ = {
    simpleName: 'Paintable',
    kind: 'interface',
    interfaces: []
  };
  function ChartGestalt() {
  }
  ChartGestalt.$metadata$ = {
    simpleName: 'ChartGestalt',
    kind: 'interface',
    interfaces: []
  };
  var corporateDesign;
  function CorporateDesign() {
  }
  CorporateDesign.$metadata$ = {
    simpleName: 'CorporateDesign',
    kind: 'interface',
    interfaces: []
  };
  function NeckarITDesign() {
    NeckarITDesign_instance = this;
    this._id = 'Neckar IT';
    this._headlineFontFamily = new FontFamily('Oswald');
    this._defaultFontFamily = new FontFamily('Open Sans');
    this._primaryColor = Companion_getInstance_29().web_0('#00a1e5');
    this._primaryColorDarker = Companion_getInstance_29().web_0('#002e46');
    this._primaryColorLighter = Companion_getInstance_29().web_0('#9fd5d8');
    this._secondaryColor = Companion_getInstance_29().web_0('#f3c500');
    this._defaultLineColor = Companion_getInstance_29().web_0('#737f85');
    this._h1 = new FontDescriptorFragment(this._headlineFontFamily, new FontSize(53.0), Companion_getInstance_59()._Normal, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h1Color = this._primaryColorDarker;
    this._h2 = new FontDescriptorFragment(this._headlineFontFamily, new FontSize(44.0), Companion_getInstance_59()._SemiBold, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h2Color = this._primaryColorDarker;
    this._h3 = new FontDescriptorFragment(this._headlineFontFamily, new FontSize(29.0), Companion_getInstance_59()._Normal, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h3Color = this._primaryColor;
    this._h4 = new FontDescriptorFragment(this._headlineFontFamily, new FontSize(22.0), Companion_getInstance_59()._Normal, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h4Color = this._primaryColorDarker;
    this._h5 = new FontDescriptorFragment(this._headlineFontFamily, new FontSize(15.0), Companion_getInstance_59()._SemiBold, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h5Color = this._primaryColorDarker;
    this._textFont = new FontDescriptor(this._defaultFontFamily, new FontSize(15.0), Companion_getInstance_59()._Normal, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._textColor_0 = this._primaryColorDarker;
    this._backgroundColorLight = Companion_getInstance_29()._white;
    this._backgroundColorDark = Companion_getInstance_29().web_0('#002e46');
    this._chartColors = Companion_getInstance_39().forListModulo(Palette_getInstance()._chartColors_0);
    this._primaryButtonBackgroundColors = new DefaultButtonColorProvider(Companion_getInstance_29().rgba(59, 145, 129, 0.3), Companion_getInstance_29().web_0('#9fd5d8'), Companion_getInstance_29().rgba(59, 145, 129, 0.7), Companion_getInstance_29().rgba(59, 145, 129, 0.7), Companion_getInstance_29().rgb(59, 145, 129));
    this._primaryButtonForegroundColors = new DefaultButtonColorProvider(Companion_getInstance_29()._white, Companion_getInstance_29().web_0('#002e46'), Companion_getInstance_29()._white, Companion_getInstance_29()._white, Companion_getInstance_29()._white);
    this._secondaryButtonBackgroundColors = new DefaultButtonColorProvider(Companion_getInstance_29().rgba(0, 0, 0, 0.0), Companion_getInstance_29().web_0('#9fd5d8'), Companion_getInstance_29().rgba(0, 0, 0, 0.0), Companion_getInstance_29().rgba(0, 0, 0, 0.0), Companion_getInstance_29().rgba(0, 0, 0, 0.0));
    this._secondaryButtonForegroundColors = new DefaultButtonColorProvider(Companion_getInstance_29().rgba(59, 145, 129, 0.3), Companion_getInstance_29().web_0('#002e46'), Companion_getInstance_29().rgb(59, 145, 129), Companion_getInstance_29().rgb(59, 145, 129), Companion_getInstance_29().rgb(59, 145, 129));
  }
  NeckarITDesign.prototype._get_textFont__1 = function () {
    return this._textFont;
  };
  NeckarITDesign.$metadata$ = {
    simpleName: 'NeckarITDesign',
    kind: 'object',
    interfaces: [CorporateDesign]
  };
  var NeckarITDesign_instance;
  function NeckarITDesign_getInstance() {
    if (NeckarITDesign_instance == null)
      new NeckarITDesign();
    return NeckarITDesign_instance;
  }
  function initCorporateDesign(newCorporateDesign) {
    corporateDesign = newCorporateDesign;
  }
  function Companion_64() {
    Companion_instance_63 = this;
    this._accentLineChar = new Char(193);
    this._pLineChar = new Char(112);
    this._capitalHLineChar = new Char(72);
    this._xLineChar = new Char(120);
    this._lineChars = 'xH\xC1p';
  }
  Companion_64.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_63;
  function Companion_getInstance_63() {
    if (Companion_instance_63 == null)
      new Companion_64();
    return Companion_instance_63;
  }
  function AbstractCanvasFontMetricsCalculator(canvas, scaleFactorY) {
    Companion_getInstance_63();
    this._canvas_0 = canvas;
    this._scaleFactorY = scaleFactorY;
  }
  AbstractCanvasFontMetricsCalculator.prototype._get_scaleFactorY__0 = function () {
    return this._scaleFactorY;
  };
  AbstractCanvasFontMetricsCalculator.prototype._get_width__41 = function () {
    return this._canvas_0._get_physicalWidth__1();
  };
  AbstractCanvasFontMetricsCalculator.prototype._get_height__41 = function () {
    return this._canvas_0._get_physicalHeight__1();
  };
  AbstractCanvasFontMetricsCalculator.prototype._get_anchorY__0 = function () {
    return numberToInt(this._get_height__41() / 2);
  };
  AbstractCanvasFontMetricsCalculator.prototype.calculateFontMetrics_0 = function (font) {
    var xLineAscent = this.calculateXLineAscent_0(font);
    var capitalHLineAscent = this.calculateCapitalHAscent_0(font);
    var pLineDescent = this.calculatePLineDescent_0(font);
    var accentLineAscent = this.calculateAscentLineAscent_0(font);
    var alignmentCorrectionInformation = this.calculateCorrectionValue_0(font, accentLineAscent * this._scaleFactorY, pLineDescent * this._scaleFactorY);
    return new FontMetrics(xLineAscent, capitalHLineAscent, accentLineAscent, pLineDescent, alignmentCorrectionInformation);
  };
  AbstractCanvasFontMetricsCalculator.prototype.calculateAscentLineAscent_0 = function (font) {
    var imageData = this.createImageData_1(font, VerticalAlignment_Baseline_getInstance(), new Char(193));
    var accentLineDescent = (this.findBottomLine(imageData, this._get_anchorY__0()) - this._get_anchorY__0() | 0) / this._scaleFactorY;
    return (this._get_anchorY__0() - this.findTopLine(imageData, this._get_anchorY__0(), font) | 0) / this._scaleFactorY;
  };
  AbstractCanvasFontMetricsCalculator.prototype.calculatePLineDescent_0 = function (font) {
    var imageData = this.createImageData_1(font, VerticalAlignment_Baseline_getInstance(), new Char(112));
    var pLineAscent = (this._get_anchorY__0() - this.findTopLine(imageData, this._get_anchorY__0(), font) | 0) / this._scaleFactorY;
    return (this.findBottomLine(imageData, this._get_anchorY__0()) - this._get_anchorY__0() | 0) / this._scaleFactorY;
  };
  AbstractCanvasFontMetricsCalculator.prototype.calculateCapitalHAscent_0 = function (font) {
    var imageData = this.createImageData_1(font, VerticalAlignment_Baseline_getInstance(), new Char(72));
    var capitalHLineDescent = (this.findBottomLine(imageData, this._get_anchorY__0()) - this._get_anchorY__0() | 0) / this._scaleFactorY;
    return (this._get_anchorY__0() - this.findTopLine(imageData, this._get_anchorY__0(), font) | 0) / this._scaleFactorY;
  };
  AbstractCanvasFontMetricsCalculator.prototype.calculateXLineAscent_0 = function (font) {
    var imageData_2 = this.createImageData_1(font, VerticalAlignment_Baseline_getInstance(), new Char(120));
    return (this._get_anchorY__0() - this.findTopLine(imageData_2, this._get_anchorY__0(), font) | 0) / this._scaleFactorY;
  };
  AbstractCanvasFontMetricsCalculator.prototype.calculateCorrectionValue_0 = function (font, accentLineAscent, pLineDescent) {
    var correctionValueTop = this.calculateCorrectionValueTop_0(font, accentLineAscent);
    var correctionValueCenter = this.calculateCorrectionValueCenter_0(font);
    var correctionValueBottom = this.calculateCorrectionValueBottom_0(font, pLineDescent);
    return new AlignmentCorrectionInformation(correctionValueTop, correctionValueCenter, correctionValueBottom);
  };
  AbstractCanvasFontMetricsCalculator.prototype.calculateCorrectionValueTop_0 = function (font, ascent) {
    var imageData = this.createImageData_1(font, VerticalAlignment_Top_getInstance(), new Char(193));
    var searchBase = numberToInt(this._get_anchorY__0() + ascent);
    var topLine = this.findTopLine(imageData, searchBase, font);
    return (this._get_anchorY__0() - topLine - 0.5) / this._scaleFactorY;
  };
  AbstractCanvasFontMetricsCalculator.prototype.calculateCorrectionValueBottom_0 = function (font, descent) {
    var imageData = this.createImageData_1(font, VerticalAlignment_Bottom_getInstance(), new Char(112));
    var searchBase = numberToInt(this._get_anchorY__0() - descent);
    var bottomLine = this.findBottomLine(imageData, searchBase);
    return (this._get_anchorY__0() - bottomLine - 0.5) / this._scaleFactorY;
  };
  AbstractCanvasFontMetricsCalculator.prototype.calculateCorrectionValueCenter_0 = function (font) {
    var imageData = this.createImageData_1(font, VerticalAlignment_Center_getInstance(), new Char(72));
    var topLine = this.findTopLine(imageData, this._get_anchorY__0(), font) / this._scaleFactorY;
    var bottomLine = this.findBottomLine(imageData, this._get_anchorY__0()) / this._scaleFactorY;
    var delta = bottomLine - topLine;
    var centerOptimal = topLine + delta / 2.0 + FontCenterAlignmentStrategy_getInstance().calculateCenterOffset(delta);
    return this._get_anchorY__0() / this._scaleFactorY - centerOptimal;
  };
  AbstractCanvasFontMetricsCalculator.prototype.findTopLine = function (imageData, baseLineY, font) {
    var threshold = font._size_4._size_5 / 2.0 * this._scaleFactorY;
    var emptyLineCounter = 0;
    var inductionVariable = baseLineY;
    if (0 <= inductionVariable)
      do {
        var y = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        if (!this.containsPixel_1(imageData, y)) {
          var tmp1 = emptyLineCounter;
          emptyLineCounter = tmp1 + 1 | 0;
          Unit_getInstance();
          if (emptyLineCounter > threshold) {
            return y + emptyLineCounter | 0;
          }} else {
          emptyLineCounter = 0;
        }
      }
       while (0 <= inductionVariable);
    if (emptyLineCounter > 0) {
      return emptyLineCounter;
    }println('WARNING: No top line found');
    return numberToInt(this._get_height__41() / 2.0);
  };
  AbstractCanvasFontMetricsCalculator.prototype.findBottomLine = function (imageData, baseLineY) {
    var inductionVariable = baseLineY;
    var last_1 = this._get_imageHeight__1(imageData);
    if (inductionVariable <= last_1)
      do {
        var y = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!this.containsPixel_1(imageData, y)) {
          return y - 1 | 0;
        }}
       while (!(y === last_1));
    println('WARNING: No bottom line found');
    return numberToInt(this._get_height__41() / 2.0);
  };
  AbstractCanvasFontMetricsCalculator.$metadata$ = {
    simpleName: 'AbstractCanvasFontMetricsCalculator',
    kind: 'class',
    interfaces: []
  };
  function FontCenterAlignmentStrategy() {
    FontCenterAlignmentStrategy_instance = this;
  }
  FontCenterAlignmentStrategy.prototype.calculateCenterOffset = function (capitalHHeight) {
    return -capitalHHeight * 0.02;
  };
  FontCenterAlignmentStrategy.$metadata$ = {
    simpleName: 'FontCenterAlignmentStrategy',
    kind: 'object',
    interfaces: []
  };
  var FontCenterAlignmentStrategy_instance;
  function FontCenterAlignmentStrategy_getInstance() {
    if (FontCenterAlignmentStrategy_instance == null)
      new FontCenterAlignmentStrategy();
    return FontCenterAlignmentStrategy_instance;
  }
  var emptyCategoryLinePainter;
  function DelegatingCategoryLinePainter(delegate_0) {
    this._delegate_4 = delegate_0;
  }
  DelegatingCategoryLinePainter.$metadata$ = {
    simpleName: 'DelegatingCategoryLinePainter',
    kind: 'class',
    interfaces: [CategoryLinePainter]
  };
  function CategoryLinePainter() {
  }
  CategoryLinePainter.$metadata$ = {
    simpleName: 'CategoryLinePainter',
    kind: 'interface',
    interfaces: []
  };
  var emptyCategoryPointPainter;
  function CategoryPointPainter() {
  }
  CategoryPointPainter.$metadata$ = {
    simpleName: 'CategoryPointPainter',
    kind: 'interface',
    interfaces: []
  };
  function DelegatingCategoryPointPainter(delegate_0) {
    this._delegate_5 = delegate_0;
  }
  DelegatingCategoryPointPainter.$metadata$ = {
    simpleName: 'DelegatingCategoryPointPainter',
    kind: 'class',
    interfaces: [CategoryPointPainter]
  };
  function LinePainter() {
  }
  LinePainter.$metadata$ = {
    simpleName: 'LinePainter',
    kind: 'interface',
    interfaces: []
  };
  function NoLinePainter() {
    NoLinePainter_instance = this;
  }
  NoLinePainter.$metadata$ = {
    simpleName: 'NoLinePainter',
    kind: 'object',
    interfaces: [LinePainter]
  };
  var NoLinePainter_instance;
  function NoLinePainter_getInstance() {
    if (NoLinePainter_instance == null)
      new NoLinePainter();
    return NoLinePainter_instance;
  }
  function NoPointPainter() {
    NoPointPainter_instance = this;
  }
  NoPointPainter.$metadata$ = {
    simpleName: 'NoPointPainter',
    kind: 'object',
    interfaces: [PointPainter]
  };
  var NoPointPainter_instance;
  function NoPointPainter_getInstance() {
    if (NoPointPainter_instance == null)
      new NoPointPainter();
    return NoPointPainter_instance;
  }
  function PointPainter() {
  }
  PointPainter.$metadata$ = {
    simpleName: 'PointPainter',
    kind: 'interface',
    interfaces: []
  };
  function ServiceRegistry() {
    var tmp = this;
    tmp._map_3 = LinkedHashMap_init_$Create$();
  }
  ServiceRegistry.prototype.dispose_46 = function () {
    var tmp0_filterIsInstance_0 = this._map_3._get_values__3();
    var tmp0_filterIsInstanceTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filterIsInstance_0.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      if (!(element_2_3 == null) ? isInterface(element_2_3, Disposable) : false) {
        tmp0_filterIsInstanceTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var tmp1_forEach_0 = tmp0_filterIsInstanceTo_0_1;
    var tmp0_iterator_1 = tmp1_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2.dispose_46();
    }
    this._map_3.clear_27();
  };
  ServiceRegistry.prototype.find_0 = function (type) {
    var tmp = this._map_3.get_20(type);
    return isObject(tmp) ? tmp : null;
  };
  ServiceRegistry.prototype.get_34 = function (type, serviceProvider) {
    var tmp0_elvis_lhs = this.find_0(type);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_also_0 = serviceProvider();
      var tmp0_set_0_2 = this._map_3;
      tmp0_set_0_2.put_6(type, tmp0_also_0);
      Unit_getInstance();
      tmp = tmp0_also_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  ServiceRegistry.$metadata$ = {
    simpleName: 'ServiceRegistry',
    kind: 'class',
    interfaces: [Disposable]
  };
  function DefaultKeyEventBroker() {
    var tmp = this;
    tmp._downCallbacks = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._upCallbacks = ArrayList_init_$Create$();
    var tmp_1 = this;
    tmp_1._typeCallbacks = ArrayList_init_$Create$();
  }
  DefaultKeyEventBroker.prototype.onDown_12 = function (handler) {
    this._downCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultKeyEventBroker.prototype.notifyDown = function (keyEvent) {
    var tmp0_forEach_0 = this._downCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(keyEvent);
      if (keyEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultKeyEventBroker.prototype.onUp_12 = function (handler) {
    this._upCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultKeyEventBroker.prototype.notifyUp = function (keyEvent) {
    var tmp0_forEach_0 = this._upCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(keyEvent);
      if (keyEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultKeyEventBroker.prototype.onType_3 = function (handler) {
    this._typeCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultKeyEventBroker.prototype.notifyTyped = function (keyEvent) {
    var tmp0_forEach_0 = this._typeCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(keyEvent);
      if (keyEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultKeyEventBroker.$metadata$ = {
    simpleName: 'DefaultKeyEventBroker',
    kind: 'class',
    interfaces: [KeyEventBroker]
  };
  function _no_name_provided__345($tmp0_also_0) {
    this._$tmp0_also_0_0 = $tmp0_also_0;
  }
  _no_name_provided__345.prototype.invoke_651 = function (mouseMoveEvent) {
    this._$tmp0_also_0_0._set_value__12(mouseMoveEvent._coordinates_1);
  };
  _no_name_provided__345.prototype.invoke_1082 = function (p1) {
    this.invoke_651(p1 instanceof MouseMoveEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__345.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__346($event) {
    this._$event_21 = $event;
  }
  _no_name_provided__346.prototype.invoke_633 = function (it) {
    it(this._$event_21);
    return this._$event_21._get_consumed__21();
  };
  _no_name_provided__346.prototype.invoke_1082 = function (p1) {
    return this.invoke_633((!(p1 == null) ? typeof p1 === 'function' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__346.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__347($event) {
    this._$event_22 = $event;
  }
  _no_name_provided__347.prototype.invoke_635 = function (it) {
    it(this._$event_22);
    return this._$event_22._get_consumed__21();
  };
  _no_name_provided__347.prototype.invoke_1082 = function (p1) {
    return this.invoke_635((!(p1 == null) ? typeof p1 === 'function' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__347.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function DefaultMouseEventBroker() {
    var tmp = this;
    tmp._clickCallbacks = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._doubleClickCallbacks = ArrayList_init_$Create$();
    var tmp_1 = this;
    tmp_1._moveCallbacks = ArrayList_init_$Create$();
    var tmp_2 = this;
    tmp_2._dragCallbacks = ArrayList_init_$Create$();
    var tmp_3 = this;
    tmp_3._wheelCallbacks = ArrayList_init_$Create$();
    var tmp_4 = this;
    tmp_4._downCallbacks_0 = ArrayList_init_$Create$();
    var tmp_5 = this;
    tmp_5._upCallbacks_0 = ArrayList_init_$Create$();
    var tmp_6 = this;
    var tmp0_also_0 = new ObservableObject(null);
    this.onMove_12(_no_name_provided_$factory_325(tmp0_also_0));
    tmp_6._mousePositionProperty = tmp0_also_0;
    this._mousePosition$delegate = this._mousePositionProperty;
  }
  DefaultMouseEventBroker.prototype.onMove_12 = function (callback) {
    this._moveCallbacks.add_57(callback);
    Unit_getInstance();
  };
  DefaultMouseEventBroker.prototype.onDrag_8 = function (callback) {
    this._dragCallbacks.add_57(callback);
    Unit_getInstance();
  };
  DefaultMouseEventBroker.prototype.onClick_5 = function (callback) {
    this._clickCallbacks.add_57(callback);
    Unit_getInstance();
  };
  DefaultMouseEventBroker.prototype.onDoubleClick_5 = function (callback) {
    this._doubleClickCallbacks.add_57(callback);
    Unit_getInstance();
  };
  DefaultMouseEventBroker.prototype.onWheel_4 = function (callback) {
    this._wheelCallbacks.add_57(callback);
    Unit_getInstance();
  };
  DefaultMouseEventBroker.prototype.onDown_14 = function (callback) {
    this._downCallbacks_0.add_57(callback);
    Unit_getInstance();
  };
  DefaultMouseEventBroker.prototype.onUp_14 = function (callback) {
    this._upCallbacks_0.add_57(callback);
    Unit_getInstance();
  };
  DefaultMouseEventBroker.prototype.notifyClick = function (event) {
    consumeUntil(this._clickCallbacks, _no_name_provided_$factory_326(event));
  };
  DefaultMouseEventBroker.prototype.notifyDoubleClick = function (event) {
    consumeUntil(this._doubleClickCallbacks, _no_name_provided_$factory_327(event));
  };
  DefaultMouseEventBroker.prototype.notifyMove = function (event) {
    var tmp0_fastForEach_0 = this._moveCallbacks;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4(event);
    }
  };
  DefaultMouseEventBroker.prototype.notifyDrag = function (event) {
    var tmp0_fastForEach_0 = this._dragCallbacks;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4(event);
    }
  };
  DefaultMouseEventBroker.prototype.notifyWheel = function (event) {
    var tmp0_fastForEach_0 = this._wheelCallbacks;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4(event);
    }
  };
  DefaultMouseEventBroker.prototype.notifyDown_0 = function (event) {
    var tmp0_fastForEach_0 = this._downCallbacks_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4(event);
    }
  };
  DefaultMouseEventBroker.prototype.notifyUp_0 = function (event) {
    var tmp0_fastForEach_0 = this._upCallbacks_0;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4(event);
    }
  };
  DefaultMouseEventBroker.$metadata$ = {
    simpleName: 'DefaultMouseEventBroker',
    kind: 'class',
    interfaces: [MouseEventBroker]
  };
  function _no_name_provided_$factory_325($tmp0_also_0) {
    var i = new _no_name_provided__345($tmp0_also_0);
    return function (p1) {
      i.invoke_651(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_326($event) {
    var i = new _no_name_provided__346($event);
    return function (p1) {
      return i.invoke_633(p1);
    };
  }
  function _no_name_provided_$factory_327($event) {
    var i = new _no_name_provided__347($event);
    return function (p1) {
      return i.invoke_635(p1);
    };
  }
  function DefaultPointerEventBroker() {
    var tmp = this;
    tmp._pointerOverCallbacks = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._pointerEnterCallbacks = ArrayList_init_$Create$();
    var tmp_1 = this;
    tmp_1._pointerDownCallbacks = ArrayList_init_$Create$();
    var tmp_2 = this;
    tmp_2._pointerMoveCallbacks = ArrayList_init_$Create$();
    var tmp_3 = this;
    tmp_3._pointerUpCallbacks = ArrayList_init_$Create$();
    var tmp_4 = this;
    tmp_4._pointerCancelCallbacks = ArrayList_init_$Create$();
    var tmp_5 = this;
    tmp_5._pointerOutCallbacks = ArrayList_init_$Create$();
    var tmp_6 = this;
    tmp_6._pointerLeaveCallbacks = ArrayList_init_$Create$();
  }
  DefaultPointerEventBroker.prototype.onOver_3 = function (handler) {
    this._pointerOverCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultPointerEventBroker.prototype.notifyOver = function (pointerEvent) {
    var tmp0_forEach_0 = this._pointerOverCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(pointerEvent);
      if (pointerEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultPointerEventBroker.prototype.onEnter_3 = function (handler) {
    this._pointerEnterCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultPointerEventBroker.prototype.notifyEnter = function (pointerEvent) {
    var tmp0_forEach_0 = this._pointerEnterCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(pointerEvent);
      if (pointerEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultPointerEventBroker.prototype.onDown_16 = function (handler) {
    this._pointerDownCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultPointerEventBroker.prototype.notifyDown_1 = function (pointerEvent) {
    var tmp0_forEach_0 = this._pointerDownCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(pointerEvent);
      if (pointerEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultPointerEventBroker.prototype.onMove_14 = function (handler) {
    this._pointerMoveCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultPointerEventBroker.prototype.notifyMove_0 = function (pointerEvent) {
    var tmp0_forEach_0 = this._pointerMoveCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(pointerEvent);
      if (pointerEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultPointerEventBroker.prototype.onUp_16 = function (handler) {
    this._pointerUpCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultPointerEventBroker.prototype.notifyUp_1 = function (pointerEvent) {
    var tmp0_forEach_0 = this._pointerUpCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(pointerEvent);
      if (pointerEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultPointerEventBroker.prototype.onCancel_7 = function (handler) {
    this._pointerCancelCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultPointerEventBroker.prototype.notifyCancel = function (pointerEvent) {
    var tmp0_forEach_0 = this._pointerCancelCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(pointerEvent);
      if (pointerEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultPointerEventBroker.prototype.onOut_3 = function (handler) {
    this._pointerOutCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultPointerEventBroker.prototype.notifyOut = function (pointerEvent) {
    var tmp0_forEach_0 = this._pointerOutCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(pointerEvent);
      if (pointerEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultPointerEventBroker.prototype.onLeave_3 = function (handler) {
    this._pointerLeaveCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultPointerEventBroker.prototype.notifyLeave = function (pointerEvent) {
    var tmp0_forEach_0 = this._pointerLeaveCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(pointerEvent);
      if (pointerEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultPointerEventBroker.$metadata$ = {
    simpleName: 'DefaultPointerEventBroker',
    kind: 'class',
    interfaces: [PointerEventBroker]
  };
  function DefaultTouchEventBroker() {
    var tmp = this;
    tmp._touchStartCallbacks = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._touchEndCallbacks = ArrayList_init_$Create$();
    var tmp_1 = this;
    tmp_1._touchMoveCallbacks = ArrayList_init_$Create$();
    var tmp_2 = this;
    tmp_2._touchCancelCallbacks = ArrayList_init_$Create$();
  }
  DefaultTouchEventBroker.prototype.onStart_5 = function (handler) {
    this._touchStartCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultTouchEventBroker.prototype.onEnd_3 = function (handler) {
    this._touchEndCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultTouchEventBroker.prototype.onMove_16 = function (handler) {
    this._touchMoveCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultTouchEventBroker.prototype.onCancel_9 = function (handler) {
    this._touchCancelCallbacks.add_57(handler);
    Unit_getInstance();
  };
  DefaultTouchEventBroker.prototype.notifyOnStart = function (touchEvent) {
    var tmp0_forEach_0 = this._touchStartCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(touchEvent);
      if (touchEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultTouchEventBroker.prototype.notifyOnEnd = function (touchEvent) {
    var tmp0_forEach_0 = this._touchEndCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(touchEvent);
      if (touchEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultTouchEventBroker.prototype.notifyOnMove = function (touchEvent) {
    var tmp0_forEach_0 = this._touchMoveCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(touchEvent);
      if (touchEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultTouchEventBroker.prototype.notifyOnCancel = function (touchEvent) {
    var tmp0_forEach_0 = this._touchCancelCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(touchEvent);
      if (touchEvent._get_consumed__21()) {
        return Unit_getInstance();
      }}
  };
  DefaultTouchEventBroker.$metadata$ = {
    simpleName: 'DefaultTouchEventBroker',
    kind: 'class',
    interfaces: [TouchEventBroker]
  };
  function KeyDownEvent(relativeTimestamp, text_0, keyStroke) {
    KeyEvent.call(this, relativeTimestamp, text_0, keyStroke);
  }
  KeyDownEvent.$metadata$ = {
    simpleName: 'KeyDownEvent',
    kind: 'class',
    interfaces: []
  };
  function KeyUpEvent(relativeTimestamp, text_0, keyStroke) {
    KeyEvent.call(this, relativeTimestamp, text_0, keyStroke);
  }
  KeyUpEvent.$metadata$ = {
    simpleName: 'KeyUpEvent',
    kind: 'class',
    interfaces: []
  };
  function KeyTypeEvent(relativeTimestamp, text_0, keyStroke) {
    KeyEvent.call(this, relativeTimestamp, text_0, keyStroke);
  }
  KeyTypeEvent.$metadata$ = {
    simpleName: 'KeyTypeEvent',
    kind: 'class',
    interfaces: []
  };
  function KeyEvent(relativeTimestamp, text_0, keyStroke) {
    UiEvent.call(this, relativeTimestamp);
    this._text_0 = text_0;
    this._keyStroke = keyStroke;
  }
  KeyEvent.prototype._get_keyStroke__1 = function () {
    return this._keyStroke;
  };
  KeyEvent.prototype.toString = function () {
    return '' + "KeyEvent(text='" + this._text_0 + "', keyStroke=" + this._keyStroke + ')';
  };
  KeyEvent.$metadata$ = {
    simpleName: 'KeyEvent',
    kind: 'class',
    interfaces: []
  };
  function KeyCode_init_$Init$(char, $this) {
    KeyCode.call($this, char.toInt_5());
    return $this;
  }
  function KeyCode_init_$Create$(char) {
    return KeyCode_init_$Init$(char, Object.create(KeyCode.prototype));
  }
  function Companion_65() {
    Companion_instance_64 = this;
    this._Left = new KeyCode(37);
    this._Up = new KeyCode(38);
    this._Right = new KeyCode(39);
    this._Down = new KeyCode(40);
    this._Delete = new KeyCode(46);
  }
  Companion_65.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_64;
  function Companion_getInstance_64() {
    if (Companion_instance_64 == null)
      new Companion_65();
    return Companion_instance_64;
  }
  function KeyCode(code) {
    Companion_getInstance_64();
    this._code = code;
  }
  KeyCode.prototype.toString = function () {
    return '' + 'KeyCode(code=' + this._code + ')';
  };
  KeyCode.prototype.hashCode = function () {
    return this._code;
  };
  KeyCode.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof KeyCode))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof KeyCode ? other : THROW_CCE();
    if (!(this._code === tmp0_other_with_cast._code))
      return false;
    return true;
  };
  KeyCode.$metadata$ = {
    simpleName: 'KeyCode',
    kind: 'class',
    interfaces: []
  };
  function KeyEventBroker() {
  }
  KeyEventBroker.$metadata$ = {
    simpleName: 'KeyEventBroker',
    kind: 'interface',
    interfaces: []
  };
  function register(_this_, eventHandler) {
    _this_.onDown_12(_no_name_provided_$factory_328(eventHandler));
    _this_.onUp_12(_no_name_provided_$factory_329(eventHandler));
    _this_.onType_3(_no_name_provided_$factory_330(eventHandler));
  }
  function KeyEventHandler() {
  }
  KeyEventHandler.$metadata$ = {
    simpleName: 'KeyEventHandler',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided__348($boundThis) {
    this._$boundThis_13 = $boundThis;
  }
  _no_name_provided__348.prototype.invoke_637 = function (p0) {
    return this._$boundThis_13.onDown_13(p0);
  };
  _no_name_provided__348.prototype.invoke_1082 = function (p1) {
    this.invoke_637(p1 instanceof KeyDownEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__348.prototype._get_name__47 = function () {
    return 'onDown';
  };
  _no_name_provided__348.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__349($boundThis) {
    this._$boundThis_14 = $boundThis;
  }
  _no_name_provided__349.prototype.invoke_639 = function (p0) {
    return this._$boundThis_14.onUp_13(p0);
  };
  _no_name_provided__349.prototype.invoke_1082 = function (p1) {
    this.invoke_639(p1 instanceof KeyUpEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__349.prototype._get_name__47 = function () {
    return 'onUp';
  };
  _no_name_provided__349.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__350($boundThis) {
    this._$boundThis_15 = $boundThis;
  }
  _no_name_provided__350.prototype.invoke_641 = function (p0) {
    return this._$boundThis_15.onType_4(p0);
  };
  _no_name_provided__350.prototype.invoke_1082 = function (p1) {
    this.invoke_641(p1 instanceof KeyTypeEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__350.prototype._get_name__47 = function () {
    return 'onType';
  };
  _no_name_provided__350.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_328($boundThis) {
    var i = new _no_name_provided__348($boundThis);
    var l = function (p1) {
      i.invoke_637(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_329($boundThis) {
    var i = new _no_name_provided__349($boundThis);
    var l = function (p1) {
      i.invoke_639(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_330($boundThis) {
    var i = new _no_name_provided__350($boundThis);
    var l = function (p1) {
      i.invoke_641(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function ModifierCombination_init_$Init$(shift, control, alt, meta, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      shift = false;
    if (!(($mask0 & 2) === 0))
      control = false;
    if (!(($mask0 & 4) === 0))
      alt = false;
    if (!(($mask0 & 8) === 0))
      meta = false;
    ModifierCombination.call($this, shift, control, alt, meta);
    return $this;
  }
  function ModifierCombination_init_$Create$(shift, control, alt, meta, $mask0, $marker) {
    return ModifierCombination_init_$Init$(shift, control, alt, meta, $mask0, $marker, Object.create(ModifierCombination.prototype));
  }
  function Companion_66() {
    Companion_instance_65 = this;
    var tmp = this;
    tmp._None = ModifierCombination_init_$Create$(false, false, false, false, 15, null);
    var tmp_0 = this;
    tmp_0._Alt = ModifierCombination_init_$Create$(false, false, true, false, 11, null);
    var tmp_1 = this;
    tmp_1._Shift = ModifierCombination_init_$Create$(true, false, false, false, 14, null);
    var tmp_2 = this;
    tmp_2._Control = ModifierCombination_init_$Create$(false, true, false, false, 13, null);
    var tmp_3 = this;
    tmp_3._Meta = ModifierCombination_init_$Create$(false, false, false, true, 7, null);
    var tmp_4 = this;
    tmp_4._CtrlAlt = ModifierCombination_init_$Create$(false, true, true, false, 9, null);
    var tmp_5 = this;
    tmp_5._CtrlShift = ModifierCombination_init_$Create$(true, true, false, false, 12, null);
    var tmp_6 = this;
    tmp_6._CtrlShiftAlt = ModifierCombination_init_$Create$(true, true, true, false, 8, null);
  }
  Companion_66.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_65;
  function Companion_getInstance_65() {
    if (Companion_instance_65 == null)
      new Companion_66();
    return Companion_instance_65;
  }
  function ModifierCombination(shift, control, alt, meta) {
    Companion_getInstance_65();
    this._shift = shift;
    this._control = control;
    this._alt = alt;
    this._meta = meta;
  }
  ModifierCombination.prototype.toString = function () {
    return '' + 'ModifierCombination(shift=' + this._shift + ', control=' + this._control + ', alt=' + this._alt + ', meta=' + this._meta + ')';
  };
  ModifierCombination.prototype.hashCode = function () {
    var result = this._shift | 0;
    result = imul(result, 31) + (this._control | 0) | 0;
    result = imul(result, 31) + (this._alt | 0) | 0;
    result = imul(result, 31) + (this._meta | 0) | 0;
    return result;
  };
  ModifierCombination.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ModifierCombination))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof ModifierCombination ? other : THROW_CCE();
    if (!(this._shift === tmp0_other_with_cast._shift))
      return false;
    if (!(this._control === tmp0_other_with_cast._control))
      return false;
    if (!(this._alt === tmp0_other_with_cast._alt))
      return false;
    if (!(this._meta === tmp0_other_with_cast._meta))
      return false;
    return true;
  };
  ModifierCombination.$metadata$ = {
    simpleName: 'ModifierCombination',
    kind: 'class',
    interfaces: []
  };
  function KeyStroke_init_$Init$(keyCode, modifierCombination, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      modifierCombination = Companion_getInstance_65()._None;
    KeyStroke.call($this, keyCode, modifierCombination);
    return $this;
  }
  function KeyStroke_init_$Create$(keyCode, modifierCombination, $mask0, $marker) {
    return KeyStroke_init_$Init$(keyCode, modifierCombination, $mask0, $marker, Object.create(KeyStroke.prototype));
  }
  function KeyStroke(keyCode, modifierCombination) {
    this._keyCode = keyCode;
    this._modifierCombination = modifierCombination;
  }
  KeyStroke.prototype.toString = function () {
    return '' + 'KeyStroke(keyCode=' + this._keyCode + ', modifierCombination=' + this._modifierCombination + ')';
  };
  KeyStroke.prototype.hashCode = function () {
    var result = this._keyCode.hashCode();
    result = imul(result, 31) + this._modifierCombination.hashCode() | 0;
    return result;
  };
  KeyStroke.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof KeyStroke))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof KeyStroke ? other : THROW_CCE();
    if (!this._keyCode.equals(tmp0_other_with_cast._keyCode))
      return false;
    if (!this._modifierCombination.equals(tmp0_other_with_cast._modifierCombination))
      return false;
    return true;
  };
  KeyStroke.$metadata$ = {
    simpleName: 'KeyStroke',
    kind: 'class',
    interfaces: []
  };
  function sam$com_cedarsoft_dispose_Disposable$0_0(function_0) {
    this._function_1 = function_0;
  }
  sam$com_cedarsoft_dispose_Disposable$0_0.prototype.dispose_46 = function () {
    return this._function_1();
  };
  sam$com_cedarsoft_dispose_Disposable$0_0.$metadata$ = {
    simpleName: 'sam$com_cedarsoft_dispose_Disposable$0',
    kind: 'class',
    interfaces: [Disposable]
  };
  function _no_name_provided__351($callback) {
    this._$callback_0 = $callback;
  }
  _no_name_provided__351.prototype.invoke_1037 = function () {
    FontLoadedEventBroker_getInstance()._callbacks.remove_59(this._$callback_0);
    Unit_getInstance();
  };
  _no_name_provided__351.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__351.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function FontLoadedEventBroker() {
    FontLoadedEventBroker_instance = this;
    var tmp = this;
    tmp._callbacks = ArrayList_init_$Create$();
  }
  FontLoadedEventBroker.prototype.onLoaded = function (callback) {
    this._callbacks.add_57(callback);
    Unit_getInstance();
    var tmp = _no_name_provided_$factory_331(callback);
    return new sam$com_cedarsoft_dispose_Disposable$0_0(tmp);
  };
  FontLoadedEventBroker.prototype.notifyLoaded_0 = function () {
    var event = new FontLoadedEvent();
    var tmp0_fastForEach_0 = this._callbacks;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4(event);
    }
  };
  FontLoadedEventBroker.$metadata$ = {
    simpleName: 'FontLoadedEventBroker',
    kind: 'object',
    interfaces: []
  };
  var FontLoadedEventBroker_instance;
  function FontLoadedEventBroker_getInstance() {
    if (FontLoadedEventBroker_instance == null)
      new FontLoadedEventBroker();
    return FontLoadedEventBroker_instance;
  }
  function FontLoadedEvent() {
  }
  FontLoadedEvent.$metadata$ = {
    simpleName: 'FontLoadedEvent',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_331($callback) {
    var i = new _no_name_provided__351($callback);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided__352($callback) {
    this._$callback_1 = $callback;
  }
  _no_name_provided__352.prototype.dispose_46 = function () {
    ImageLoadedEventBroker_getInstance()._callbacks_0.remove_59(this._$callback_1);
    Unit_getInstance();
  };
  _no_name_provided__352.$metadata$ = {
    kind: 'class',
    interfaces: [Disposable]
  };
  function ImageLoadedEventBroker() {
    ImageLoadedEventBroker_instance = this;
    var tmp = this;
    tmp._callbacks_0 = ArrayList_init_$Create$();
  }
  ImageLoadedEventBroker.prototype.onLoaded_0 = function (callback) {
    this._callbacks_0.add_57(callback);
    Unit_getInstance();
    return new _no_name_provided__352(callback);
  };
  ImageLoadedEventBroker.prototype.notifyLoaded_0 = function () {
    var event = new ImageLoadedEvent();
    var tmp0_forEach_0 = this._callbacks_0;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      element_2(event);
    }
  };
  ImageLoadedEventBroker.$metadata$ = {
    simpleName: 'ImageLoadedEventBroker',
    kind: 'object',
    interfaces: []
  };
  var ImageLoadedEventBroker_instance;
  function ImageLoadedEventBroker_getInstance() {
    if (ImageLoadedEventBroker_instance == null)
      new ImageLoadedEventBroker();
    return ImageLoadedEventBroker_instance;
  }
  function ImageLoadedEvent() {
  }
  ImageLoadedEvent.$metadata$ = {
    simpleName: 'ImageLoadedEvent',
    kind: 'class',
    interfaces: []
  };
  function MouseClickEvent(relativeTimestamp, coordinates, modifierCombination) {
    MouseEvent_0.call(this, relativeTimestamp);
    this._coordinates = coordinates;
    this._modifierCombination_0 = modifierCombination;
  }
  MouseClickEvent.$metadata$ = {
    simpleName: 'MouseClickEvent',
    kind: 'class',
    interfaces: []
  };
  function MouseDoubleClickEvent(relativeTimestamp, coordinates, modifierCombination) {
    MouseEvent_0.call(this, relativeTimestamp);
    this._coordinates_0 = coordinates;
    this._modifierCombination_1 = modifierCombination;
  }
  MouseDoubleClickEvent.$metadata$ = {
    simpleName: 'MouseDoubleClickEvent',
    kind: 'class',
    interfaces: []
  };
  function MouseMoveEvent(relativeTimestamp, coordinates, modifierCombination) {
    MouseEvent_0.call(this, relativeTimestamp);
    this._coordinates_1 = coordinates;
    this._modifierCombination_2 = modifierCombination;
  }
  MouseMoveEvent.$metadata$ = {
    simpleName: 'MouseMoveEvent',
    kind: 'class',
    interfaces: []
  };
  function MouseDragEvent(relativeTimestamp, coordinates, modifierCombination) {
    MouseEvent_0.call(this, relativeTimestamp);
    this._coordinates_2 = coordinates;
    this._modifierCombination_3 = modifierCombination;
  }
  MouseDragEvent.$metadata$ = {
    simpleName: 'MouseDragEvent',
    kind: 'class',
    interfaces: []
  };
  function MouseWheelEvent(relativeTimestamp, coordinates, delta, modifierCombination) {
    MouseEvent_0.call(this, relativeTimestamp);
    this._coordinates_3 = coordinates;
    this._delta = delta;
    this._modifierCombination_4 = modifierCombination;
  }
  MouseWheelEvent.$metadata$ = {
    simpleName: 'MouseWheelEvent',
    kind: 'class',
    interfaces: []
  };
  function MouseDownEvent(relativeTimestamp, coordinates, modifierCombination) {
    MouseEvent_0.call(this, relativeTimestamp);
    this._coordinates_4 = coordinates;
    this._modifierCombination_5 = modifierCombination;
  }
  MouseDownEvent.$metadata$ = {
    simpleName: 'MouseDownEvent',
    kind: 'class',
    interfaces: []
  };
  function MouseUpEvent(relativeTimestamp, coordinates, modifierCombination) {
    MouseEvent_0.call(this, relativeTimestamp);
    this._coordinates_5 = coordinates;
    this._modifierCombination_6 = modifierCombination;
  }
  MouseUpEvent.$metadata$ = {
    simpleName: 'MouseUpEvent',
    kind: 'class',
    interfaces: []
  };
  function MouseEvent_0(relativeTimestamp) {
    UiEvent.call(this, relativeTimestamp);
  }
  MouseEvent_0.$metadata$ = {
    simpleName: 'MouseEvent',
    kind: 'class',
    interfaces: []
  };
  function MouseEventBroker() {
  }
  MouseEventBroker.$metadata$ = {
    simpleName: 'MouseEventBroker',
    kind: 'interface',
    interfaces: []
  };
  function register_0(_this_, eventHandler) {
    _this_.onWheel_4(_no_name_provided_$factory_332(eventHandler));
    _this_.onClick_5(_no_name_provided_$factory_333(eventHandler));
    _this_.onDoubleClick_5(_no_name_provided_$factory_334(eventHandler));
    _this_.onMove_12(_no_name_provided_$factory_335(eventHandler));
    _this_.onDrag_8(_no_name_provided_$factory_336(eventHandler));
    _this_.onDown_14(_no_name_provided_$factory_337(eventHandler));
    _this_.onUp_14(_no_name_provided_$factory_338(eventHandler));
  }
  function MouseEventHandler() {
  }
  MouseEventHandler.prototype.onClick_6 = function (event) {
  };
  MouseEventHandler.prototype.onDoubleClick_6 = function (event) {
  };
  MouseEventHandler.prototype.onMove_13 = function (event) {
  };
  MouseEventHandler.prototype.onDrag_9 = function (event) {
  };
  MouseEventHandler.prototype.onWheel_5 = function (event) {
  };
  MouseEventHandler.prototype.onDown_15 = function (event) {
  };
  MouseEventHandler.prototype.onUp_15 = function (event) {
  };
  MouseEventHandler.$metadata$ = {
    simpleName: 'MouseEventHandler',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided__353($boundThis) {
    this._$boundThis_16 = $boundThis;
  }
  _no_name_provided__353.prototype.invoke_645 = function (p0) {
    return this._$boundThis_16.onWheel_5(p0);
  };
  _no_name_provided__353.prototype.invoke_1082 = function (p1) {
    this.invoke_645(p1 instanceof MouseWheelEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__353.prototype._get_name__47 = function () {
    return 'onWheel';
  };
  _no_name_provided__353.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__354($boundThis) {
    this._$boundThis_17 = $boundThis;
  }
  _no_name_provided__354.prototype.invoke_647 = function (p0) {
    return this._$boundThis_17.onClick_6(p0);
  };
  _no_name_provided__354.prototype.invoke_1082 = function (p1) {
    this.invoke_647(p1 instanceof MouseClickEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__354.prototype._get_name__47 = function () {
    return 'onClick';
  };
  _no_name_provided__354.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__355($boundThis) {
    this._$boundThis_18 = $boundThis;
  }
  _no_name_provided__355.prototype.invoke_649 = function (p0) {
    return this._$boundThis_18.onDoubleClick_6(p0);
  };
  _no_name_provided__355.prototype.invoke_1082 = function (p1) {
    this.invoke_649(p1 instanceof MouseDoubleClickEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__355.prototype._get_name__47 = function () {
    return 'onDoubleClick';
  };
  _no_name_provided__355.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__356($boundThis) {
    this._$boundThis_19 = $boundThis;
  }
  _no_name_provided__356.prototype.invoke_651 = function (p0) {
    return this._$boundThis_19.onMove_13(p0);
  };
  _no_name_provided__356.prototype.invoke_1082 = function (p1) {
    this.invoke_651(p1 instanceof MouseMoveEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__356.prototype._get_name__47 = function () {
    return 'onMove';
  };
  _no_name_provided__356.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__357($boundThis) {
    this._$boundThis_20 = $boundThis;
  }
  _no_name_provided__357.prototype.invoke_653 = function (p0) {
    return this._$boundThis_20.onDrag_9(p0);
  };
  _no_name_provided__357.prototype.invoke_1082 = function (p1) {
    this.invoke_653(p1 instanceof MouseDragEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__357.prototype._get_name__47 = function () {
    return 'onDrag';
  };
  _no_name_provided__357.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__358($boundThis) {
    this._$boundThis_21 = $boundThis;
  }
  _no_name_provided__358.prototype.invoke_655 = function (p0) {
    return this._$boundThis_21.onDown_15(p0);
  };
  _no_name_provided__358.prototype.invoke_1082 = function (p1) {
    this.invoke_655(p1 instanceof MouseDownEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__358.prototype._get_name__47 = function () {
    return 'onDown';
  };
  _no_name_provided__358.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__359($boundThis) {
    this._$boundThis_22 = $boundThis;
  }
  _no_name_provided__359.prototype.invoke_657 = function (p0) {
    return this._$boundThis_22.onUp_15(p0);
  };
  _no_name_provided__359.prototype.invoke_1082 = function (p1) {
    this.invoke_657(p1 instanceof MouseUpEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__359.prototype._get_name__47 = function () {
    return 'onUp';
  };
  _no_name_provided__359.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_332($boundThis) {
    var i = new _no_name_provided__353($boundThis);
    var l = function (p1) {
      i.invoke_645(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_333($boundThis) {
    var i = new _no_name_provided__354($boundThis);
    var l = function (p1) {
      i.invoke_647(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_334($boundThis) {
    var i = new _no_name_provided__355($boundThis);
    var l = function (p1) {
      i.invoke_649(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_335($boundThis) {
    var i = new _no_name_provided__356($boundThis);
    var l = function (p1) {
      i.invoke_651(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_336($boundThis) {
    var i = new _no_name_provided__357($boundThis);
    var l = function (p1) {
      i.invoke_653(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_337($boundThis) {
    var i = new _no_name_provided__358($boundThis);
    var l = function (p1) {
      i.invoke_655(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_338($boundThis) {
    var i = new _no_name_provided__359($boundThis);
    var l = function (p1) {
      i.invoke_657(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function PointerOverEvent(relativeTimestamp, pointer) {
    PointerEvent_0.call(this, relativeTimestamp, pointer);
  }
  PointerOverEvent.$metadata$ = {
    simpleName: 'PointerOverEvent',
    kind: 'class',
    interfaces: []
  };
  function PointerEnterEvent(relativeTimestamp, pointer) {
    PointerEvent_0.call(this, relativeTimestamp, pointer);
  }
  PointerEnterEvent.$metadata$ = {
    simpleName: 'PointerEnterEvent',
    kind: 'class',
    interfaces: []
  };
  function PointerDownEvent(relativeTimestamp, pointer) {
    PointerEvent_0.call(this, relativeTimestamp, pointer);
  }
  PointerDownEvent.$metadata$ = {
    simpleName: 'PointerDownEvent',
    kind: 'class',
    interfaces: []
  };
  function PointerMoveEvent(relativeTimestamp, pointer) {
    PointerEvent_0.call(this, relativeTimestamp, pointer);
  }
  PointerMoveEvent.$metadata$ = {
    simpleName: 'PointerMoveEvent',
    kind: 'class',
    interfaces: []
  };
  function PointerUpEvent(relativeTimestamp, pointer) {
    PointerEvent_0.call(this, relativeTimestamp, pointer);
  }
  PointerUpEvent.$metadata$ = {
    simpleName: 'PointerUpEvent',
    kind: 'class',
    interfaces: []
  };
  function PointerCancelEvent(relativeTimestamp, pointer) {
    PointerEvent_0.call(this, relativeTimestamp, pointer);
  }
  PointerCancelEvent.$metadata$ = {
    simpleName: 'PointerCancelEvent',
    kind: 'class',
    interfaces: []
  };
  function PointerOutEvent(relativeTimestamp, pointer) {
    PointerEvent_0.call(this, relativeTimestamp, pointer);
  }
  PointerOutEvent.$metadata$ = {
    simpleName: 'PointerOutEvent',
    kind: 'class',
    interfaces: []
  };
  function PointerLeaveEvent(relativeTimestamp, pointer) {
    PointerEvent_0.call(this, relativeTimestamp, pointer);
  }
  PointerLeaveEvent.$metadata$ = {
    simpleName: 'PointerLeaveEvent',
    kind: 'class',
    interfaces: []
  };
  function PointerEvent_0(relativeTimestamp, pointer) {
    UiEvent.call(this, relativeTimestamp);
    this._pointer = pointer;
  }
  PointerEvent_0.prototype._get_pointer__2 = function () {
    return this._pointer;
  };
  PointerEvent_0.prototype._get_coordinates__1 = function () {
    return this._pointer._coordinates_6;
  };
  PointerEvent_0.$metadata$ = {
    simpleName: 'PointerEvent',
    kind: 'class',
    interfaces: []
  };
  function Pointer(pointerId, coordinates) {
    this._pointerId = pointerId;
    this._coordinates_6 = coordinates;
  }
  Pointer.prototype.toString = function () {
    return '' + 'Pointer(pointerId=' + new PointerId(this._pointerId) + ', coordinates=' + this._coordinates_6 + ')';
  };
  Pointer.prototype.hashCode = function () {
    var result = PointerId__hashCode_impl(this._pointerId);
    result = imul(result, 31) + this._coordinates_6.hashCode() | 0;
    return result;
  };
  Pointer.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Pointer))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Pointer ? other : THROW_CCE();
    if (!equals_0(new PointerId(this._pointerId), new PointerId(tmp0_other_with_cast._pointerId)))
      return false;
    if (!this._coordinates_6.equals(tmp0_other_with_cast._coordinates_6))
      return false;
    return true;
  };
  Pointer.$metadata$ = {
    simpleName: 'Pointer',
    kind: 'class',
    interfaces: []
  };
  function _PointerId___init__impl_(id) {
    return id;
  }
  function PointerId__toString_impl(this_0) {
    return '' + 'PointerId(id=' + this_0 + ')';
  }
  function PointerId__hashCode_impl(this_0) {
    return this_0;
  }
  function PointerId__equals_impl(this_0, other) {
    if (!(other instanceof PointerId))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof PointerId ? other._id_0 : THROW_CCE();
    if (!(this_0 === tmp0_other_with_cast))
      return false;
    return true;
  }
  function PointerId(id) {
    this._id_0 = id;
  }
  PointerId.prototype.toString = function () {
    return PointerId__toString_impl(this._id_0);
  };
  PointerId.prototype.hashCode = function () {
    return PointerId__hashCode_impl(this._id_0);
  };
  PointerId.prototype.equals = function (other) {
    return PointerId__equals_impl(this._id_0, other);
  };
  PointerId.$metadata$ = {
    simpleName: 'PointerId',
    kind: 'class',
    interfaces: []
  };
  function PointerEventBroker() {
  }
  PointerEventBroker.$metadata$ = {
    simpleName: 'PointerEventBroker',
    kind: 'interface',
    interfaces: []
  };
  function register_1(_this_, eventHandler) {
    _this_.onOver_3(_no_name_provided_$factory_339(eventHandler));
    _this_.onEnter_3(_no_name_provided_$factory_340(eventHandler));
    _this_.onDown_16(_no_name_provided_$factory_341(eventHandler));
    _this_.onMove_14(_no_name_provided_$factory_342(eventHandler));
    _this_.onUp_16(_no_name_provided_$factory_343(eventHandler));
    _this_.onCancel_7(_no_name_provided_$factory_344(eventHandler));
    _this_.onOut_3(_no_name_provided_$factory_345(eventHandler));
    _this_.onLeave_3(_no_name_provided_$factory_346(eventHandler));
  }
  function PointerEventHandler() {
  }
  PointerEventHandler.prototype.onOver_4 = function (event) {
  };
  PointerEventHandler.prototype.onEnter_4 = function (event) {
  };
  PointerEventHandler.prototype.onDown_17 = function (event) {
  };
  PointerEventHandler.prototype.onMove_15 = function (event) {
  };
  PointerEventHandler.prototype.onUp_17 = function (event) {
  };
  PointerEventHandler.prototype.onCancel_8 = function (event) {
  };
  PointerEventHandler.prototype.onOut_4 = function (event) {
  };
  PointerEventHandler.prototype.onLeave_4 = function (event) {
  };
  PointerEventHandler.$metadata$ = {
    simpleName: 'PointerEventHandler',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided__360($boundThis) {
    this._$boundThis_23 = $boundThis;
  }
  _no_name_provided__360.prototype.invoke_659 = function (p0) {
    return this._$boundThis_23.onOver_4(p0);
  };
  _no_name_provided__360.prototype.invoke_1082 = function (p1) {
    this.invoke_659(p1 instanceof PointerOverEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__360.prototype._get_name__47 = function () {
    return 'onOver';
  };
  _no_name_provided__360.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__361($boundThis) {
    this._$boundThis_24 = $boundThis;
  }
  _no_name_provided__361.prototype.invoke_661 = function (p0) {
    return this._$boundThis_24.onEnter_4(p0);
  };
  _no_name_provided__361.prototype.invoke_1082 = function (p1) {
    this.invoke_661(p1 instanceof PointerEnterEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__361.prototype._get_name__47 = function () {
    return 'onEnter';
  };
  _no_name_provided__361.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__362($boundThis) {
    this._$boundThis_25 = $boundThis;
  }
  _no_name_provided__362.prototype.invoke_663 = function (p0) {
    return this._$boundThis_25.onDown_17(p0);
  };
  _no_name_provided__362.prototype.invoke_1082 = function (p1) {
    this.invoke_663(p1 instanceof PointerDownEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__362.prototype._get_name__47 = function () {
    return 'onDown';
  };
  _no_name_provided__362.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__363($boundThis) {
    this._$boundThis_26 = $boundThis;
  }
  _no_name_provided__363.prototype.invoke_665 = function (p0) {
    return this._$boundThis_26.onMove_15(p0);
  };
  _no_name_provided__363.prototype.invoke_1082 = function (p1) {
    this.invoke_665(p1 instanceof PointerMoveEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__363.prototype._get_name__47 = function () {
    return 'onMove';
  };
  _no_name_provided__363.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__364($boundThis) {
    this._$boundThis_27 = $boundThis;
  }
  _no_name_provided__364.prototype.invoke_667 = function (p0) {
    return this._$boundThis_27.onUp_17(p0);
  };
  _no_name_provided__364.prototype.invoke_1082 = function (p1) {
    this.invoke_667(p1 instanceof PointerUpEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__364.prototype._get_name__47 = function () {
    return 'onUp';
  };
  _no_name_provided__364.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__365($boundThis) {
    this._$boundThis_28 = $boundThis;
  }
  _no_name_provided__365.prototype.invoke_669 = function (p0) {
    return this._$boundThis_28.onCancel_8(p0);
  };
  _no_name_provided__365.prototype.invoke_1082 = function (p1) {
    this.invoke_669(p1 instanceof PointerCancelEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__365.prototype._get_name__47 = function () {
    return 'onCancel';
  };
  _no_name_provided__365.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__366($boundThis) {
    this._$boundThis_29 = $boundThis;
  }
  _no_name_provided__366.prototype.invoke_671 = function (p0) {
    return this._$boundThis_29.onOut_4(p0);
  };
  _no_name_provided__366.prototype.invoke_1082 = function (p1) {
    this.invoke_671(p1 instanceof PointerOutEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__366.prototype._get_name__47 = function () {
    return 'onOut';
  };
  _no_name_provided__366.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__367($boundThis) {
    this._$boundThis_30 = $boundThis;
  }
  _no_name_provided__367.prototype.invoke_673 = function (p0) {
    return this._$boundThis_30.onLeave_4(p0);
  };
  _no_name_provided__367.prototype.invoke_1082 = function (p1) {
    this.invoke_673(p1 instanceof PointerLeaveEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__367.prototype._get_name__47 = function () {
    return 'onLeave';
  };
  _no_name_provided__367.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_339($boundThis) {
    var i = new _no_name_provided__360($boundThis);
    var l = function (p1) {
      i.invoke_659(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_340($boundThis) {
    var i = new _no_name_provided__361($boundThis);
    var l = function (p1) {
      i.invoke_661(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_341($boundThis) {
    var i = new _no_name_provided__362($boundThis);
    var l = function (p1) {
      i.invoke_663(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_342($boundThis) {
    var i = new _no_name_provided__363($boundThis);
    var l = function (p1) {
      i.invoke_665(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_343($boundThis) {
    var i = new _no_name_provided__364($boundThis);
    var l = function (p1) {
      i.invoke_667(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_344($boundThis) {
    var i = new _no_name_provided__365($boundThis);
    var l = function (p1) {
      i.invoke_669(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_345($boundThis) {
    var i = new _no_name_provided__366($boundThis);
    var l = function (p1) {
      i.invoke_671(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_346($boundThis) {
    var i = new _no_name_provided__367($boundThis);
    var l = function (p1) {
      i.invoke_673(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function TouchStartEvent(relativeTimestamp, changedTouches, targetTouches, touches) {
    TouchEvent_0.call(this, relativeTimestamp, changedTouches, targetTouches, touches);
  }
  TouchStartEvent.$metadata$ = {
    simpleName: 'TouchStartEvent',
    kind: 'class',
    interfaces: []
  };
  function TouchEndEvent(relativeTimestamp, changedTouches, targetTouches, touches) {
    TouchEvent_0.call(this, relativeTimestamp, changedTouches, targetTouches, touches);
  }
  TouchEndEvent.$metadata$ = {
    simpleName: 'TouchEndEvent',
    kind: 'class',
    interfaces: []
  };
  function TouchMoveEvent(relativeTimestamp, changedTouches, targetTouches, touches) {
    TouchEvent_0.call(this, relativeTimestamp, changedTouches, targetTouches, touches);
  }
  TouchMoveEvent.$metadata$ = {
    simpleName: 'TouchMoveEvent',
    kind: 'class',
    interfaces: []
  };
  function TouchCancelEvent(relativeTimestamp, changedTouches, targetTouches, touches) {
    TouchEvent_0.call(this, relativeTimestamp, changedTouches, targetTouches, touches);
  }
  TouchCancelEvent.$metadata$ = {
    simpleName: 'TouchCancelEvent',
    kind: 'class',
    interfaces: []
  };
  function TouchEvent_0(relativeTimestamp, changedTouches, targetTouches, touches) {
    UiEvent.call(this, relativeTimestamp);
    this._changedTouches = changedTouches;
    this._targetTouches = targetTouches;
    this._touches = touches;
  }
  TouchEvent_0.$metadata$ = {
    simpleName: 'TouchEvent',
    kind: 'class',
    interfaces: []
  };
  function Touch_0(touchId, coordinates) {
    this._touchId = touchId;
    this._coordinates_7 = coordinates;
  }
  Touch_0.prototype.toString = function () {
    return '' + 'Touch(touchId=' + new TouchId(this._touchId) + ', coordinates=' + this._coordinates_7 + ')';
  };
  Touch_0.prototype.hashCode = function () {
    var result = TouchId__hashCode_impl(this._touchId);
    result = imul(result, 31) + this._coordinates_7.hashCode() | 0;
    return result;
  };
  Touch_0.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Touch_0))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Touch_0 ? other : THROW_CCE();
    if (!equals_0(new TouchId(this._touchId), new TouchId(tmp0_other_with_cast._touchId)))
      return false;
    if (!this._coordinates_7.equals(tmp0_other_with_cast._coordinates_7))
      return false;
    return true;
  };
  Touch_0.$metadata$ = {
    simpleName: 'Touch',
    kind: 'class',
    interfaces: []
  };
  function _TouchId___init__impl_(id) {
    return id;
  }
  function TouchId__toString_impl(this_0) {
    return '' + 'TouchId(id=' + this_0 + ')';
  }
  function TouchId__hashCode_impl(this_0) {
    return this_0;
  }
  function TouchId__equals_impl(this_0, other) {
    if (!(other instanceof TouchId))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof TouchId ? other._id_1 : THROW_CCE();
    if (!(this_0 === tmp0_other_with_cast))
      return false;
    return true;
  }
  function TouchId(id) {
    this._id_1 = id;
  }
  TouchId.prototype.toString = function () {
    return TouchId__toString_impl(this._id_1);
  };
  TouchId.prototype.hashCode = function () {
    return TouchId__hashCode_impl(this._id_1);
  };
  TouchId.prototype.equals = function (other) {
    return TouchId__equals_impl(this._id_1, other);
  };
  TouchId.$metadata$ = {
    simpleName: 'TouchId',
    kind: 'class',
    interfaces: []
  };
  function TouchEventBroker() {
  }
  TouchEventBroker.$metadata$ = {
    simpleName: 'TouchEventBroker',
    kind: 'interface',
    interfaces: []
  };
  function register_2(_this_, eventHandler) {
    _this_.onStart_5(_no_name_provided_$factory_347(eventHandler));
    _this_.onEnd_3(_no_name_provided_$factory_348(eventHandler));
    _this_.onMove_16(_no_name_provided_$factory_349(eventHandler));
    _this_.onCancel_9(_no_name_provided_$factory_350(eventHandler));
  }
  function _no_name_provided__368($boundThis) {
    this._$boundThis_31 = $boundThis;
  }
  _no_name_provided__368.prototype.invoke_675 = function (p0) {
    return this._$boundThis_31.onStart_6(p0);
  };
  _no_name_provided__368.prototype.invoke_1082 = function (p1) {
    this.invoke_675(p1 instanceof TouchStartEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__368.prototype._get_name__47 = function () {
    return 'onStart';
  };
  _no_name_provided__368.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__369($boundThis) {
    this._$boundThis_32 = $boundThis;
  }
  _no_name_provided__369.prototype.invoke_677 = function (p0) {
    return this._$boundThis_32.onEnd_4(p0);
  };
  _no_name_provided__369.prototype.invoke_1082 = function (p1) {
    this.invoke_677(p1 instanceof TouchEndEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__369.prototype._get_name__47 = function () {
    return 'onEnd';
  };
  _no_name_provided__369.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__370($boundThis) {
    this._$boundThis_33 = $boundThis;
  }
  _no_name_provided__370.prototype.invoke_679 = function (p0) {
    return this._$boundThis_33.onMove_17(p0);
  };
  _no_name_provided__370.prototype.invoke_1082 = function (p1) {
    this.invoke_679(p1 instanceof TouchMoveEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__370.prototype._get_name__47 = function () {
    return 'onMove';
  };
  _no_name_provided__370.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__371($boundThis) {
    this._$boundThis_34 = $boundThis;
  }
  _no_name_provided__371.prototype.invoke_681 = function (p0) {
    return this._$boundThis_34.onCancel_10(p0);
  };
  _no_name_provided__371.prototype.invoke_1082 = function (p1) {
    this.invoke_681(p1 instanceof TouchCancelEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__371.prototype._get_name__47 = function () {
    return 'onCancel';
  };
  _no_name_provided__371.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_347($boundThis) {
    var i = new _no_name_provided__368($boundThis);
    var l = function (p1) {
      i.invoke_675(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_348($boundThis) {
    var i = new _no_name_provided__369($boundThis);
    var l = function (p1) {
      i.invoke_677(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_349($boundThis) {
    var i = new _no_name_provided__370($boundThis);
    var l = function (p1) {
      i.invoke_679(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function _no_name_provided_$factory_350($boundThis) {
    var i = new _no_name_provided__371($boundThis);
    var l = function (p1) {
      i.invoke_681(p1);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function TouchEventHandler() {
  }
  TouchEventHandler.prototype.onStart_6 = function (event) {
  };
  TouchEventHandler.prototype.onEnd_4 = function (event) {
  };
  TouchEventHandler.prototype.onMove_17 = function (event) {
  };
  TouchEventHandler.prototype.onCancel_10 = function (event) {
  };
  TouchEventHandler.$metadata$ = {
    simpleName: 'TouchEventHandler',
    kind: 'interface',
    interfaces: []
  };
  function UiEvent(timestamp) {
    this._timestamp = timestamp;
    this._consumed = false;
  }
  UiEvent.prototype._get_timestamp__1 = function () {
    return this._timestamp;
  };
  UiEvent.prototype._get_consumed__21 = function () {
    return this._consumed;
  };
  UiEvent.prototype.consume_14 = function () {
    this._consumed = true;
  };
  UiEvent.$metadata$ = {
    simpleName: 'UiEvent',
    kind: 'class',
    interfaces: []
  };
  function updateDragLocation($this, coordinates, eventTime) {
    $this._currentLocation_0 = coordinates;
    var delta = eventTime - $this._currentLocationUpdateTime;
    $this._currentLocationUpdateTime = eventTime;
    return delta;
  }
  function Handler() {
  }
  Handler.prototype.onStart_8 = function (source, location, chartSupport) {
  };
  Handler.prototype.onFinish_2 = function (source, coordinates, chartSupport) {
  };
  Handler.$metadata$ = {
    simpleName: 'Handler',
    kind: 'interface',
    interfaces: []
  };
  function CanvasDragSupport() {
    this._dragging = false;
    this._currentLocation_0 = null;
    this._currentLocationUpdateTime = 0.0;
    this._draggingAllowed = true;
    this._dragSpeedCalculator = new MouseSpeedCalculator();
    var tmp = this;
    tmp._handlers = ArrayList_init_$Create$();
  }
  CanvasDragSupport.prototype.prepareForDragging = function () {
    this._dragging = false;
  };
  CanvasDragSupport.prototype.dragging = function (coordinates, eventTime, chartSupport) {
    if (!this._dragging) {
      if (!this._draggingAllowed) {
        return Unit_getInstance();
      }this._dragging = true;
      updateDragLocation(this, coordinates, eventTime);
      Unit_getInstance();
      var tmp0_fastForEach_0 = this._handlers;
      var n_1 = 0;
      var currentSize_2 = tmp0_fastForEach_0._get_size__43();
      while (n_1 < currentSize_2) {
        var tmp0_3 = n_1;
        n_1 = tmp0_3 + 1 | 0;
        var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
        tmp1__anonymous__4.onStart_8(this, coordinates, chartSupport);
      }
      return Unit_getInstance();
    }var tmp0_safe_receiver = this._currentLocation_0;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var distance_2 = coordinates.delta_0(tmp0_safe_receiver);
      var deltaTime_3 = updateDragLocation(this, coordinates, eventTime);
      this._dragSpeedCalculator.add_58(deltaTime_3, distance_2);
      var tmp0_fastForEach_0_4 = this._handlers;
      var n_1_5 = 0;
      var currentSize_2_6 = tmp0_fastForEach_0_4._get_size__43();
      while (n_1_5 < currentSize_2_6) {
        var tmp0_3_8 = n_1_5;
        n_1_5 = tmp0_3_8 + 1 | 0;
        var tmp1__anonymous__4_7 = tmp0_fastForEach_0_4.get_44(tmp0_3_8);
        tmp1__anonymous__4_7.onDrag_12(this, coordinates, distance_2, deltaTime_3, chartSupport);
      }
      tmp = Unit_getInstance();
    }
    var tmp1_elvis_lhs = tmp;
    if (tmp1_elvis_lhs == null)
      throw IllegalStateException_init_$Create$_0('No current mouse location available');
    else {
      Unit_getInstance();
    }
    return Unit_getInstance();
  };
  CanvasDragSupport.prototype.finishDragging = function (coordinates, chartSupport) {
    if (!this._dragging) {
      return Unit_getInstance();
    }this.reset_3();
    var tmp0_fastForEach_0 = this._handlers;
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEach_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      var tmp1__anonymous__4 = tmp0_fastForEach_0.get_44(tmp0_3);
      tmp1__anonymous__4.onFinish_2(this, coordinates, chartSupport);
    }
  };
  CanvasDragSupport.prototype.reset_3 = function () {
    this._dragging = false;
    this._currentLocation_0 = null;
    this._currentLocationUpdateTime = 0.0;
  };
  CanvasDragSupport.prototype.handle = function (handler) {
    this._handlers.add_57(handler);
    Unit_getInstance();
  };
  CanvasDragSupport.$metadata$ = {
    simpleName: 'CanvasDragSupport',
    kind: 'class',
    interfaces: []
  };
  function connectedPointerEventHandler(_this_) {
    return new _no_name_provided__372(_this_);
  }
  function connectedMouseEventHandler(_this_) {
    return new _no_name_provided__373(_this_);
  }
  function delegate(_this_) {
    return new _no_name_provided__374(_this_);
  }
  function _no_name_provided__372($this_connectedPointerEventHandler) {
    this._$this_connectedPointerEventHandler = $this_connectedPointerEventHandler;
    this._currentPointerId = null;
  }
  _no_name_provided__372.prototype.reset_3 = function () {
    this._$this_connectedPointerEventHandler.reset_3();
    this._currentPointerId = null;
  };
  _no_name_provided__372.prototype.onDown_18 = function (event, chartSupport) {
    var tmp = this._currentPointerId;
    if ((tmp == null ? null : new PointerId(tmp)) == null) {
      this._currentPointerId = event._get_pointer__2()._pointerId;
      this._$this_connectedPointerEventHandler.prepareForDragging();
      event.consume_14();
    } else {
    }
  };
  _no_name_provided__372.prototype.onMove_18 = function (event, chartSupport) {
    var tmp = this._currentPointerId;
    if (!equals_0(tmp == null ? null : new PointerId(tmp), new PointerId(event._get_pointer__2()._pointerId))) {
      return Unit_getInstance();
    } else {
    }
    this._$this_connectedPointerEventHandler.dragging(event._get_coordinates__1(), event._get_timestamp__1(), chartSupport);
    event.consume_14();
  };
  _no_name_provided__372.prototype.onUp_18 = function (event, chartSupport) {
    var tmp = this._currentPointerId;
    if (!equals_0(tmp == null ? null : new PointerId(tmp), new PointerId(event._get_pointer__2()._pointerId))) {
      return Unit_getInstance();
    } else {
    }
    this._$this_connectedPointerEventHandler.finishDragging(event._get_coordinates__1(), chartSupport);
    this.reset_3();
    event.consume_14();
  };
  _no_name_provided__372.prototype.onCancel_11 = function (event, chartSupport) {
    this.reset_3();
  };
  _no_name_provided__372.prototype.onOut_5 = function (event, chartSupport) {
    this.reset_3();
  };
  _no_name_provided__372.$metadata$ = {
    kind: 'class',
    interfaces: [CanvasPointerEventHandler]
  };
  function _no_name_provided__373($this_connectedMouseEventHandler) {
    this._$this_connectedMouseEventHandler = $this_connectedMouseEventHandler;
  }
  _no_name_provided__373.prototype.onDown_20 = function (event, chartSupport) {
    this._$this_connectedMouseEventHandler.prepareForDragging();
    event.consume_14();
  };
  _no_name_provided__373.prototype.onDrag_13 = function (event, chartSupport) {
    this._$this_connectedMouseEventHandler.dragging(event._coordinates_2, event._get_timestamp__1(), chartSupport);
    event.consume_14();
  };
  _no_name_provided__373.prototype.onUp_20 = function (event, chartSupport) {
    this._$this_connectedMouseEventHandler.finishDragging(event._coordinates_5, chartSupport);
    event.consume_14();
  };
  _no_name_provided__373.$metadata$ = {
    kind: 'class',
    interfaces: [CanvasMouseEventHandler]
  };
  function _no_name_provided__374($this_delegate) {
    this._$this_delegate = $this_delegate;
  }
  _no_name_provided__374.prototype.onStart_8 = function (source, location, chartSupport) {
    var tmp0_safe_receiver = this._$this_delegate.get();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onStart_8(source, location, chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__374.prototype.onDrag_12 = function (source, coordinates, distance, deltaTime, chartSupport) {
    var tmp0_safe_receiver = this._$this_delegate.get();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onDrag_12(source, coordinates, distance, deltaTime, chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__374.prototype.onFinish_2 = function (source, coordinates, chartSupport) {
    var tmp0_safe_receiver = this._$this_delegate.get();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.onFinish_2(source, coordinates, chartSupport);
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__374.$metadata$ = {
    kind: 'class',
    interfaces: [Handler]
  };
  function Companion_67() {
    Companion_instance_66 = this;
    this._SpeedQueueSize = 15;
    this._MaxAge = 400000;
  }
  Companion_67.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_66;
  function Companion_getInstance_66() {
    if (Companion_instance_66 == null)
      new Companion_67();
    return Companion_instance_66;
  }
  function _no_name_provided__375($now) {
    this._$now = $now;
  }
  _no_name_provided__375.prototype.invoke_683 = function (entry) {
    return this._$now - entry._time > 400000.0;
  };
  _no_name_provided__375.prototype.invoke_1082 = function (p1) {
    return this.invoke_683(p1 instanceof Entry_0 ? p1 : THROW_CCE());
  };
  _no_name_provided__375.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function MouseSpeedCalculator() {
    Companion_getInstance_66();
    this._entries = new EvictingQueue(15);
  }
  MouseSpeedCalculator.prototype.add_58 = function (deltaTime, distance) {
    this.add_59(deltaTime, distance._x_0, distance._y_0);
  };
  MouseSpeedCalculator.prototype.add_59 = function (deltaTime, deltaX, deltaY) {
    if (deltaTime <= 0.0) {
      return Unit_getInstance();
    }var now = nowMillis();
    removeAll_0(this._entries, _no_name_provided_$factory_351(now));
    Unit_getInstance();
    this._entries.add_57(new Entry_0(now, deltaTime, deltaX / deltaTime, deltaY / deltaTime));
    Unit_getInstance();
  };
  MouseSpeedCalculator.$metadata$ = {
    simpleName: 'MouseSpeedCalculator',
    kind: 'class',
    interfaces: []
  };
  function Entry_0(time, deltaTime, speedX, speedY) {
    this._time = time;
    this._deltaTime = deltaTime;
    this._speedX = speedX;
    this._speedY = speedY;
  }
  Entry_0.prototype.toString = function () {
    return '' + 'Entry{time=' + this._time + ', deltaTime=' + this._deltaTime + ', speedX=' + this._speedX + ', speedY=' + this._speedY + '}';
  };
  Entry_0.$metadata$ = {
    simpleName: 'Entry',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_351($now) {
    var i = new _no_name_provided__375($now);
    return function (p1) {
      return i.invoke_683(p1);
    };
  }
  function BoxStyle_init_$Init$(fill_0, border, borderWidth, padding, radii, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      fill_0 = null;
    if (!(($mask0 & 2) === 0))
      border = null;
    if (!(($mask0 & 4) === 0))
      borderWidth = 1.0;
    if (!(($mask0 & 8) === 0))
      padding = Companion_getInstance_35().of_1(5.0);
    if (!(($mask0 & 16) === 0))
      radii = null;
    BoxStyle.call($this, fill_0, border, borderWidth, padding, radii);
    return $this;
  }
  function BoxStyle_init_$Create$(fill_0, border, borderWidth, padding, radii, $mask0, $marker) {
    return BoxStyle_init_$Init$(fill_0, border, borderWidth, padding, radii, $mask0, $marker, Object.create(BoxStyle.prototype));
  }
  function Companion_68() {
    Companion_instance_67 = this;
  }
  Companion_68.prototype._get_none_ = function () {
    return BoxStyle_init_$Create$(null, null, 0.0, null, null, 31, null);
  };
  Companion_68.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_67;
  function Companion_getInstance_67() {
    if (Companion_instance_67 == null)
      new Companion_68();
    return Companion_instance_67;
  }
  function BoxStyle(fill_0, border, borderWidth, padding, radii) {
    Companion_getInstance_67();
    this._fill_0 = fill_0;
    this._border = border;
    this._borderWidth = borderWidth;
    this._padding = padding;
    this._radii = radii;
  }
  BoxStyle.prototype.toString = function () {
    return '' + 'BoxStyle(fill=' + this._fill_0 + ', border=' + this._border + ', borderWidth=' + this._borderWidth + ', padding=' + this._padding + ', radii=' + this._radii + ')';
  };
  BoxStyle.prototype.hashCode = function () {
    var result = this._fill_0 == null ? 0 : hashCode(this._fill_0);
    result = imul(result, 31) + (this._border == null ? 0 : hashCode(this._border)) | 0;
    result = imul(result, 31) + getNumberHashCode(this._borderWidth) | 0;
    result = imul(result, 31) + this._padding.hashCode() | 0;
    result = imul(result, 31) + (this._radii == null ? 0 : this._radii.hashCode()) | 0;
    return result;
  };
  BoxStyle.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof BoxStyle))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof BoxStyle ? other : THROW_CCE();
    if (!equals_0(this._fill_0, tmp0_other_with_cast._fill_0))
      return false;
    if (!equals_0(this._border, tmp0_other_with_cast._border))
      return false;
    if (!equals_0(this._borderWidth, tmp0_other_with_cast._borderWidth))
      return false;
    if (!this._padding.equals(tmp0_other_with_cast._padding))
      return false;
    if (!equals_0(this._radii, tmp0_other_with_cast._radii))
      return false;
    return true;
  };
  BoxStyle.$metadata$ = {
    simpleName: 'BoxStyle',
    kind: 'class',
    interfaces: []
  };
  function Palette() {
    Palette_instance = this;
    this._defaultGray = Companion_getInstance_29().rgb(115, 127, 133);
    this._primaryColors = listOf([Companion_getInstance_29().rgb(0, 161, 229), Companion_getInstance_29().rgb(0, 46, 70), Companion_getInstance_29().rgb(159, 213, 216)]);
    this._chartColors_0 = listOf([Companion_getInstance_29().rgb(0, 161, 229), Companion_getInstance_29().rgb(0, 46, 70), Companion_getInstance_29().rgb(159, 213, 216), Companion_getInstance_29().rgb(0, 104, 150), Companion_getInstance_29().rgb(118, 199, 238), Companion_getInstance_29().rgb(59, 145, 129)]);
    this._stateSuperior = Companion_getInstance_29().rgb(146, 194, 89);
    this._stateNormal = Companion_getInstance_29().rgb(0, 161, 229);
    this._stateWarning = Companion_getInstance_29().rgb(243, 197, 0);
    this._stateCritical = Companion_getInstance_29().rgb(241, 132, 34);
    this._stateError = Companion_getInstance_29().rgb(230, 44, 86);
    this._stateOffline = Companion_getInstance_29().rgb(0, 46, 70);
    this._stateColors = listOf([this._stateSuperior, this._stateNormal, this._stateWarning, this._stateCritical, this._stateError, this._stateOffline]);
    this._all = listOf([this._primaryColors, this._chartColors_0, this._stateColors]);
  }
  Palette.$metadata$ = {
    simpleName: 'Palette',
    kind: 'object',
    interfaces: []
  };
  var Palette_instance;
  function Palette_getInstance() {
    if (Palette_instance == null)
      new Palette();
    return Palette_instance;
  }
  function extractEnvironment(oldEnvironment) {
    var multiTouchSupported = window.navigator.maxTouchPoints > 0;
    var devicePixelRatio = window.devicePixelRatio;
    if (oldEnvironment._multiTouchSupported === multiTouchSupported ? oldEnvironment._devicePixelRatio === devicePixelRatio : false) {
      return oldEnvironment;
    }return new Environment(multiTouchSupported, devicePixelRatio);
  }
  function loadImageUncached(url, callback) {
    var tmp = document.createElement('IMG');
    var tmp0_apply_0 = tmp instanceof HTMLImageElement ? tmp : THROW_CCE();
    tmp0_apply_0.style.width = '' + tmp0_apply_0.width + ' px';
    tmp0_apply_0.style.height = '' + tmp0_apply_0.height + ' px';
    tmp0_apply_0.addEventListener('load', _no_name_provided_$factory_352(callback, tmp0_apply_0));
    tmp0_apply_0.src = url;
    if (tmp0_apply_0.complete ? !startsWith_0(tmp0_apply_0.src, 'data:image', true) : false) {
      callback(createImage(tmp0_apply_0));
    }Unit_getInstance();
  }
  function createImage(_this_) {
    var tmp = new Size(_this_.naturalWidth, _this_.naturalHeight);
    return Image_init_$Create$(_this_, tmp, null, 4, null);
  }
  function _no_name_provided__376($callback, $tmp0_apply_0) {
    this._$callback_2 = $callback;
    this._$tmp0_apply_0_1 = $tmp0_apply_0;
  }
  _no_name_provided__376.prototype.invoke_795 = function (it) {
    this._$callback_2(createImage(this._$tmp0_apply_0_1));
    ImageLoadedEventBroker_getInstance().notifyLoaded_0();
  };
  _no_name_provided__376.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__376.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_352($callback, $tmp0_apply_0) {
    var i = new _no_name_provided__376($callback, $tmp0_apply_0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function CanvasFactoryJS() {
  }
  CanvasFactoryJS.prototype.createCanvas_0 = function (type, size) {
    var tmp0_also_0 = new CanvasJS(type);
    tmp0_also_0.applySize(size);
    tmp0_also_0._canvasElement.style.width = '' + size._width_0 + ' px';
    tmp0_also_0._canvasElement.style.height = '' + size._height + ' px';
    tmp0_also_0._canvasElement.style.margin = '0';
    tmp0_also_0._canvasElement.style.padding = '0';
    return tmp0_also_0;
  };
  CanvasFactoryJS.$metadata$ = {
    simpleName: 'CanvasFactoryJS',
    kind: 'class',
    interfaces: [CanvasFactory]
  };
  function _no_name_provided__377() {
  }
  _no_name_provided__377.prototype.invoke_1037 = function () {
    return Unit_getInstance();
  };
  _no_name_provided__377.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__377.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function CanvasFontMetricsCalculatorJS_init_$Init$(canvasFactor, scaleFactorY, canvasSize, $this) {
    CanvasFontMetricsCalculatorJS.call($this, canvasFactor.createCanvas_0(CanvasType_OffScreen_getInstance(), canvasSize), scaleFactorY);
    return $this;
  }
  function CanvasFontMetricsCalculatorJS_init_$Init$_0(canvasFactor, scaleFactorY, canvasSize, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      canvasFactor = new CanvasFactoryJS();
    if (!(($mask0 & 2) === 0))
      scaleFactorY = 4.0;
    if (!(($mask0 & 4) === 0))
      canvasSize = new Size(200.0, 600 * scaleFactorY);
    CanvasFontMetricsCalculatorJS_init_$Init$(canvasFactor, scaleFactorY, canvasSize, $this);
    return $this;
  }
  function CanvasFontMetricsCalculatorJS_init_$Create$(canvasFactor, scaleFactorY, canvasSize, $mask0, $marker) {
    return CanvasFontMetricsCalculatorJS_init_$Init$_0(canvasFactor, scaleFactorY, canvasSize, $mask0, $marker, Object.create(CanvasFontMetricsCalculatorJS.prototype));
  }
  function Companion_69() {
    Companion_instance_68 = this;
    this._heightCalculationText = 'Bp\xC5\xC1q\xDCg\xD6f\xC4PqLT';
    var tmp = this;
    var tmp_0 = KotlinLogging_getInstance();
    tmp._logger_1 = tmp_0.logger(_no_name_provided_$factory_353());
  }
  Companion_69.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_68;
  function Companion_getInstance_68() {
    if (Companion_instance_68 == null)
      new Companion_69();
    return Companion_instance_68;
  }
  function CanvasFontMetricsCalculatorJS(canvas, scaleFactorY) {
    Companion_getInstance_68();
    AbstractCanvasFontMetricsCalculator.call(this, canvas, scaleFactorY);
    this._canvasRenderingContext = new CanvasRenderingContextJS(canvas);
  }
  CanvasFontMetricsCalculatorJS.prototype._get_imageHeight__0 = function (_this__0) {
    return _this__0.height;
  };
  CanvasFontMetricsCalculatorJS.prototype._get_imageHeight__1 = function (_this__0) {
    return this._get_imageHeight__0(_this__0 instanceof ImageData ? _this__0 : THROW_CCE());
  };
  CanvasFontMetricsCalculatorJS.prototype.createImageData_1 = function (font, verticalAlignment, char) {
    this._canvasRenderingContext.resetTransform_1();
    this._canvasRenderingContext.clear_27();
    var tmp0_saved_0 = this._canvasRenderingContext;
    tmp0_saved_0.save_3();
    try {
      tmp0_saved_0.translate_35(0.0, this._get_anchorY__0());
      tmp0_saved_0.scale_3(1.0, this._get_scaleFactorY__0());
      this._canvasRenderingContext._set_font__1(font);
      this._canvasRenderingContext._set_textBaseline_(verticalAlignment);
      this._canvasRenderingContext._set_horizontalAlignment_(HorizontalAlignment_Left_getInstance());
      this._canvasRenderingContext._context.fillText(char.toString(), 0.0, 0.0);
    }finally {
      tmp0_saved_0.restore_3();
    }
    return this._canvasRenderingContext._context.getImageData(0.0, 0.0, this._get_width__41(), this._get_height__41());
  };
  CanvasFontMetricsCalculatorJS.prototype.containsPixel_0 = function (_this__0, y) {
    var inductionVariable = 0;
    var last_1 = _this__0.width;
    if (inductionVariable < last_1)
      do {
        var x = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var baseOffset = imul(imul(_this__0.width, y) + x | 0, 4);
        var offsetRed = baseOffset + 0 | 0;
        var offsetGreen = baseOffset + 1 | 0;
        var offsetBlue = baseOffset + 2 | 0;
        var offsetAlpha = baseOffset + 3 | 0;
        var tmp0_get_0 = _this__0.data;
        var alpha = tmp0_get_0[offsetAlpha];
        if (alpha > 0) {
          return true;
        }}
       while (inductionVariable < last_1);
    return false;
  };
  CanvasFontMetricsCalculatorJS.prototype.containsPixel_1 = function (_this__0, y) {
    return this.containsPixel_0(_this__0 instanceof ImageData ? _this__0 : THROW_CCE(), y);
  };
  CanvasFontMetricsCalculatorJS.$metadata$ = {
    simpleName: 'CanvasFontMetricsCalculatorJS',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_353() {
    var i = new _no_name_provided__377();
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  var deltaRelativeMillisToAbsolute;
  function setUpMouseEventListeners($this) {
    $this.requireMainCanvas_0();
    $this._canvasElement.addEventListener('click', _no_name_provided_$factory_360($this));
    $this._canvasElement.addEventListener('mousedown', _no_name_provided_$factory_361($this));
    $this._canvasElement.addEventListener('mouseup', _no_name_provided_$factory_362($this));
    $this._canvasElement.addEventListener('dblclick', _no_name_provided_$factory_363($this));
    $this._canvasElement.addEventListener('mousemove', _no_name_provided_$factory_364($this));
    $this._canvasElement.addEventListener('mouseenter', _no_name_provided_$factory_365($this));
    $this._canvasElement.addEventListener('mouseleave', _no_name_provided_$factory_366($this));
    $this._canvasElement.addEventListener('wheel', _no_name_provided_$factory_367($this));
  }
  function notifyWheel($this, event) {
    var tmp0_subject = event.deltaMode;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = event.deltaY;
        break;
      case 1:
        tmp = event.deltaY * 25.0;
        break;
      case 2:
        tmp = event.deltaY * 500.0;
        break;
      default:throw IllegalArgumentException_init_$Create$('' + 'Unsupported mode: ' + event.deltaMode);
    }
    var distance = tmp;
    var modifierCombination = extractModifierCombination(event);
    var tmp0_let_0 = new MouseWheelEvent(_get_timeStampAsDoubleWorkaround_(event), offset(event), distance, modifierCombination);
    $this._get_mouseEvents__1().notifyWheel(tmp0_let_0);
    cancelIfConsumed(event, $this, tmp0_let_0._get_consumed__21());
  }
  function notifyMouseLeave($this, event) {
    var tmp0_let_0 = new MouseMoveEvent(_get_timeStampAsDoubleWorkaround_(event), null, extractModifierCombination(event));
    $this._get_mouseEvents__1().notifyMove(tmp0_let_0);
    cancelIfConsumed(event, $this, tmp0_let_0._get_consumed__21());
  }
  function notifyMouseEnter($this, event) {
    var tmp0_let_0 = new MouseMoveEvent(_get_timeStampAsDoubleWorkaround_(event), offset(event), extractModifierCombination(event));
    $this._get_mouseEvents__1().notifyMove(tmp0_let_0);
    cancelIfConsumed(event, $this, tmp0_let_0._get_consumed__21());
  }
  function notifyMouseMove($this, event) {
    var isPrimaryButton = (event.buttons & 1) === 1;
    if (isPrimaryButton) {
      var tmp0_let_0 = new MouseDragEvent(_get_timeStampAsDoubleWorkaround_(event), offset(event), extractModifierCombination(event));
      $this._get_mouseEvents__1().notifyDrag(tmp0_let_0);
      cancelIfConsumed(event, $this, tmp0_let_0._get_consumed__21());
    } else {
      var tmp1_let_0 = new MouseMoveEvent(_get_timeStampAsDoubleWorkaround_(event), offset(event), extractModifierCombination(event));
      $this._get_mouseEvents__1().notifyMove(tmp1_let_0);
      cancelIfConsumed(event, $this, tmp1_let_0._get_consumed__21());
    }
  }
  function notifyMouseDblClick($this, event) {
    var tmp0_let_0 = new MouseDoubleClickEvent(_get_timeStampAsDoubleWorkaround_(event), offset(event), extractModifierCombination(event));
    $this._get_mouseEvents__1().notifyDoubleClick(tmp0_let_0);
    cancelIfConsumed(event, $this, tmp0_let_0._get_consumed__21());
  }
  function notifyMouseClick($this, event) {
    var tmp0_let_0 = new MouseClickEvent(_get_timeStampAsDoubleWorkaround_(event), offset(event), extractModifierCombination(event));
    $this._get_mouseEvents__1().notifyClick(tmp0_let_0);
    cancelIfConsumed(event, $this, tmp0_let_0._get_consumed__21());
  }
  function notifyMouseDown($this, event) {
    var tmp0_let_0 = new MouseDownEvent(_get_timeStampAsDoubleWorkaround_(event), offset(event), extractModifierCombination(event));
    $this._get_mouseEvents__1().notifyDown_0(tmp0_let_0);
    cancelIfConsumed(event, $this, tmp0_let_0._get_consumed__21());
  }
  function notifyMouseUp($this, event) {
    var tmp0_let_0 = new MouseUpEvent(_get_timeStampAsDoubleWorkaround_(event), offset(event), extractModifierCombination(event));
    $this._get_mouseEvents__1().notifyUp_0(tmp0_let_0);
    cancelIfConsumed(event, $this, tmp0_let_0._get_consumed__21());
  }
  function setUpPointerEventListeners($this) {
    $this._canvasElement.addEventListener('pointerover', _no_name_provided_$factory_368($this));
    $this._canvasElement.addEventListener('pointerenter', _no_name_provided_$factory_369($this));
    $this._canvasElement.addEventListener('pointerdown', _no_name_provided_$factory_370($this));
    $this._canvasElement.addEventListener('pointermove', _no_name_provided_$factory_371($this));
    $this._canvasElement.addEventListener('pointerup', _no_name_provided_$factory_372($this));
    $this._canvasElement.addEventListener('pointercancel', _no_name_provided_$factory_373($this));
    $this._canvasElement.addEventListener('pointerout', _no_name_provided_$factory_374($this));
    $this._canvasElement.addEventListener('pointerleave', _no_name_provided_$factory_375($this));
  }
  function setUpKeyEventListeners($this) {
    $this.requireMainCanvas_0();
    $this._canvasElement.tabIndex = 1;
    $this._canvasElement.addEventListener('keydown', _no_name_provided_$factory_376($this));
    $this._canvasElement.addEventListener('keyup', _no_name_provided_$factory_377($this));
    $this._canvasElement.addEventListener('keypress', _no_name_provided_$factory_378($this));
  }
  function setUpTouchEventListeners($this) {
    $this._canvasElement.addEventListener('touchstart', _no_name_provided_$factory_379($this));
    $this._canvasElement.addEventListener('touchend', _no_name_provided_$factory_380($this));
    $this._canvasElement.addEventListener('touchmove', _no_name_provided_$factory_381($this));
    $this._canvasElement.addEventListener('touchcancel', _no_name_provided_$factory_382($this));
  }
  function cancelIfConsumed(_this_, $this, consumed) {
    if (consumed) {
      _this_.stopImmediatePropagation();
      _this_.preventDefault();
    }}
  function _no_name_provided__378() {
  }
  _no_name_provided__378.prototype.invoke_779 = function (newSize) {
    var tmp0_require_0_3 = newSize.bothNotNegative();
    if (!tmp0_require_0_3) {
      var message_1_4 = '' + 'Invalid size: ' + newSize;
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_4));
    }};
  _no_name_provided__378.prototype.invoke_1082 = function (p1) {
    this.invoke_779(p1 instanceof Size ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__378.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__379() {
  }
  _no_name_provided__379.prototype.invoke_691 = function (it) {
    return Companion_getInstance_50().get_30(it);
  };
  _no_name_provided__379.prototype.invoke_1082 = function (p1) {
    return this.invoke_691(p1 instanceof Size ? p1 : THROW_CCE());
  };
  _no_name_provided__379.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__380(this$0) {
    this._this$0_244 = this$0;
  }
  _no_name_provided__380.prototype.invoke_693 = function (entries, _anonymous_parameter_1_) {
    if (!(entries.length === 0)) {
      this._this$0_244._clientSizeHasChanged = true;
    } else {
    }
  };
  _no_name_provided__380.prototype.invoke_1052 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE();
    this.invoke_693(tmp, p2 instanceof ResizeObserver ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__380.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__381($tmp0_apply_0, this$0) {
    this._$tmp0_apply_0_2 = $tmp0_apply_0;
    this._this$0_245 = this$0;
  }
  _no_name_provided__381.prototype.invoke_1037 = function () {
    this._$tmp0_apply_0_2.unobserve(this._this$0_245._canvasElement);
  };
  _no_name_provided__381.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__381.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__382(this$0) {
    this._this$0_246 = this$0;
  }
  _no_name_provided__382.prototype.invoke_779 = function (it) {
    this._this$0_246.recalculateCanvasRenderingSize();
  };
  _no_name_provided__382.prototype.invoke_1082 = function (p1) {
    this.invoke_779(p1 instanceof Size ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__382.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__383(this$0) {
    this._this$0_247 = this$0;
  }
  _no_name_provided__383.prototype.invoke_699 = function (it) {
    this._this$0_247._canvasElement.style.cursor = toCss(it);
  };
  _no_name_provided__383.prototype.invoke_1082 = function (p1) {
    this.invoke_699(p1 instanceof MouseCursor ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__383.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__384(this$0) {
    this._this$0_248 = this$0;
  }
  _no_name_provided__384.prototype.invoke_795 = function (event) {
    this._this$0_248._canvasElement.focus();
    notifyMouseClick(this._this$0_248, event);
  };
  _no_name_provided__384.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__384.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__385(this$0) {
    this._this$0_249 = this$0;
  }
  _no_name_provided__385.prototype.invoke_795 = function (event) {
    this._this$0_249._canvasElement.focus();
    notifyMouseDown(this._this$0_249, event);
  };
  _no_name_provided__385.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__385.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__386(this$0) {
    this._this$0_250 = this$0;
  }
  _no_name_provided__386.prototype.invoke_795 = function (event) {
    notifyMouseUp(this._this$0_250, event);
  };
  _no_name_provided__386.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__386.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__387(this$0) {
    this._this$0_251 = this$0;
  }
  _no_name_provided__387.prototype.invoke_795 = function (event) {
    notifyMouseDblClick(this._this$0_251, event);
  };
  _no_name_provided__387.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__387.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__388(this$0) {
    this._this$0_252 = this$0;
  }
  _no_name_provided__388.prototype.invoke_795 = function (event) {
    notifyMouseMove(this._this$0_252, event);
  };
  _no_name_provided__388.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__388.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__389(this$0) {
    this._this$0_253 = this$0;
  }
  _no_name_provided__389.prototype.invoke_795 = function (event) {
    notifyMouseEnter(this._this$0_253, event);
  };
  _no_name_provided__389.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__389.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__390(this$0) {
    this._this$0_254 = this$0;
  }
  _no_name_provided__390.prototype.invoke_795 = function (event) {
    notifyMouseLeave(this._this$0_254, event);
  };
  _no_name_provided__390.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__390.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__391(this$0) {
    this._this$0_255 = this$0;
  }
  _no_name_provided__391.prototype.invoke_795 = function (event) {
    notifyWheel(this._this$0_255, event);
  };
  _no_name_provided__391.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__391.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__392(this$0) {
    this._this$0_256 = this$0;
  }
  _no_name_provided__392.prototype.invoke_795 = function (event) {
    this._this$0_256._canvasElement.focus();
    var tmp0_let_0 = convertOver(event);
    this._this$0_256._get_pointerEvents__1().notifyOver(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_256, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__392.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__392.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__393(this$0) {
    this._this$0_257 = this$0;
  }
  _no_name_provided__393.prototype.invoke_795 = function (event) {
    this._this$0_257._canvasElement.focus();
    var tmp0_let_0 = convertEnter(event);
    this._this$0_257._get_pointerEvents__1().notifyEnter(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_257, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__393.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__393.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__394(this$0) {
    this._this$0_258 = this$0;
  }
  _no_name_provided__394.prototype.invoke_795 = function (event) {
    this._this$0_258._canvasElement.focus();
    var tmp0_let_0 = convertDown(event);
    this._this$0_258._get_pointerEvents__1().notifyDown_1(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_258, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__394.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__394.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__395(this$0) {
    this._this$0_259 = this$0;
  }
  _no_name_provided__395.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertMove(event);
    this._this$0_259._get_pointerEvents__1().notifyMove_0(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_259, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__395.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__395.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__396(this$0) {
    this._this$0_260 = this$0;
  }
  _no_name_provided__396.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertUp(event);
    this._this$0_260._get_pointerEvents__1().notifyUp_1(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_260, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__396.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__396.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__397(this$0) {
    this._this$0_261 = this$0;
  }
  _no_name_provided__397.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertCancel(event);
    this._this$0_261._get_pointerEvents__1().notifyCancel(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_261, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__397.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__397.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__398(this$0) {
    this._this$0_262 = this$0;
  }
  _no_name_provided__398.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertOut(event);
    this._this$0_262._get_pointerEvents__1().notifyOut(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_262, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__398.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__398.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__399(this$0) {
    this._this$0_263 = this$0;
  }
  _no_name_provided__399.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertLeave(event);
    this._this$0_263._get_pointerEvents__1().notifyLeave(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_263, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__399.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__399.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__400(this$0) {
    this._this$0_264 = this$0;
  }
  _no_name_provided__400.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertPress(event);
    this._this$0_264._get_keyEvents__1().notifyDown(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_264, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__400.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__400.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__401(this$0) {
    this._this$0_265 = this$0;
  }
  _no_name_provided__401.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertRelease(event);
    this._this$0_265._get_keyEvents__1().notifyUp(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_265, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__401.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__401.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__402(this$0) {
    this._this$0_266 = this$0;
  }
  _no_name_provided__402.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertType(event);
    this._this$0_266._get_keyEvents__1().notifyTyped(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_266, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__402.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__402.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__403(this$0) {
    this._this$0_267 = this$0;
  }
  _no_name_provided__403.prototype.invoke_795 = function (event) {
    this._this$0_267._canvasElement.focus();
    var tmp0_let_0 = convertStart(event, this._this$0_267._boundingClientLocation);
    this._this$0_267._get_touchEvents__1().notifyOnStart(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_267, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__403.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__403.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__404(this$0) {
    this._this$0_268 = this$0;
  }
  _no_name_provided__404.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertEnd(event, this._this$0_268._boundingClientLocation);
    this._this$0_268._get_touchEvents__1().notifyOnEnd(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_268, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__404.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__404.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__405(this$0) {
    this._this$0_269 = this$0;
  }
  _no_name_provided__405.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertMove_0(event, this._this$0_269._boundingClientLocation);
    this._this$0_269._get_touchEvents__1().notifyOnMove(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_269, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__405.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__405.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__406(this$0) {
    this._this$0_270 = this$0;
  }
  _no_name_provided__406.prototype.invoke_795 = function (event) {
    var tmp0_let_0 = convertCancel_0(event, this._this$0_270._boundingClientLocation);
    this._this$0_270._get_touchEvents__1().notifyOnCancel(tmp0_let_0);
    cancelIfConsumed(event, this._this$0_270, tmp0_let_0._get_consumed__21());
  };
  _no_name_provided__406.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__406.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function CanvasJS(type) {
    AbstractCanvas.call(this, type);
    var tmp = this;
    var tmp_0 = document.createElement('CANVAS');
    tmp._canvasElement = tmp_0 instanceof HTMLCanvasElement ? tmp_0 : THROW_CCE();
    this._gc_0 = new CanvasRenderingContextJS(this);
    var tmp_1 = this;
    var tmp0_also_0 = new ObservableObject(Companion_getInstance_37()._zero_1);
    tmp0_also_0.consume$default_3(false, _no_name_provided_$factory_354(), 1, null);
    Unit_getInstance();
    tmp_1._sizeProperty = tmp0_also_0;
    this._size$delegate_5 = this._sizeProperty;
    var tmp_2 = this;
    tmp_2._chartSizeClassificationProperty = this._sizeProperty.map_4(_no_name_provided_$factory_355());
    this._chartSizeClassification$delegate = this._chartSizeClassificationProperty;
    this._boundingClientLocation = Companion_getInstance_32()._none;
    this._clientSizeHasChanged = true;
    this._disposeSupport_2 = new DisposeSupport();
    if (type.equals(CanvasType_Main_getInstance())) {
      var tmp0_apply_0 = new ResizeObserver(_no_name_provided_$factory_356(this));
      tmp0_apply_0.observe(this._canvasElement);
      this._disposeSupport_2.onDispose_10(_no_name_provided_$factory_357(tmp0_apply_0, this));
      Unit_getInstance();
    }this._sizeProperty.consumeImmediately_3(_no_name_provided_$factory_358(this));
    Unit_getInstance();
    unselectable(this._canvasElement);
    noFocusBorder(this._canvasElement);
    if (type.equals(CanvasType_Main_getInstance())) {
      var tmp_3 = this._get_mouseCursor__0();
      tmp_3.consumeImmediately_3(_no_name_provided_$factory_359(this));
      Unit_getInstance();
      setUpMouseEventListeners(this);
      setUpPointerEventListeners(this);
      setUpKeyEventListeners(this);
      setUpTouchEventListeners(this);
    }}
  CanvasJS.prototype._get_gc__1 = function () {
    return this._gc_0;
  };
  CanvasJS.prototype._get_sizeProperty__1 = function () {
    return this._sizeProperty;
  };
  CanvasJS.prototype._get_size__43 = function () {
    return this._size$delegate_5.getValue_10(this, size$factory_17());
  };
  CanvasJS.prototype._get_physicalWidth__1 = function () {
    return this._canvasElement.width;
  };
  CanvasJS.prototype._get_physicalHeight__1 = function () {
    return this._canvasElement.height;
  };
  CanvasJS.prototype.dispose_46 = function () {
    this._disposeSupport_2.dispose_46();
  };
  CanvasJS.prototype.recalculateCanvasRenderingSize = function () {
    var devicePixelRatio = environment._devicePixelRatio;
    var targetRenderingWidth = devicePixelRatio * this._get_size__43()._width_0;
    var targetRenderingHeight = devicePixelRatio * this._get_size__43()._height;
    this._canvasElement.width = numberToInt(targetRenderingWidth);
    this._canvasElement.height = numberToInt(targetRenderingHeight);
  };
  CanvasJS.prototype.applySizeFromClientSize = function () {
    if (!this._clientSizeHasChanged) {
      return Unit_getInstance();
    }this._clientSizeHasChanged = false;
    var tmp0_let_0 = this._canvasElement.getBoundingClientRect();
    this._boundingClientLocation = new Coordinates(tmp0_let_0.left, tmp0_let_0.top);
    this.applySize(new Size(tmp0_let_0.width, tmp0_let_0.height));
  };
  CanvasJS.prototype.applySize = function (newSize) {
    var tmp = this._sizeProperty;
    (tmp instanceof ObservableObject ? tmp : THROW_CCE())._set_value__12(newSize);
  };
  CanvasJS.$metadata$ = {
    simpleName: 'CanvasJS',
    kind: 'class',
    interfaces: [Disposable]
  };
  function scheduleRepaints(_this_, frameTimestamp) {
    if (_this_._get_disposed_()) {
      return Unit_getInstance();
    }var now = currentTimeMillis();
    var exactAbsoluteTimestamp = frameTimestamp + deltaRelativeMillisToAbsolute;
    if (abs(exactAbsoluteTimestamp - now) > 10.0) {
      deltaRelativeMillisToAbsolute = now - frameTimestamp;
      exactAbsoluteTimestamp = frameTimestamp + deltaRelativeMillisToAbsolute;
    }var tmp = _this_._canvas;
    (tmp instanceof CanvasJS ? tmp : THROW_CCE()).applySizeFromClientSize();
    _this_.refresh_35(exactAbsoluteTimestamp);
    var tmp_0 = window;
    tmp_0.requestAnimationFrame(_no_name_provided_$factory_383(_this_));
    Unit_getInstance();
  }
  function _no_name_provided__407($this_scheduleRepaints) {
    this._$this_scheduleRepaints = $this_scheduleRepaints;
  }
  _no_name_provided__407.prototype.invoke_791 = function (it) {
    scheduleRepaints(this._$this_scheduleRepaints, it);
  };
  _no_name_provided__407.prototype.invoke_1082 = function (p1) {
    this.invoke_791((!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__407.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function size$factory_17() {
    return getPropertyCallableRef('size', 1, KProperty1, function (receiver) {
      return receiver._get_size__43();
    }, null);
  }
  function _no_name_provided_$factory_354() {
    var i = new _no_name_provided__378();
    return function (p1) {
      i.invoke_779(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_355() {
    var i = new _no_name_provided__379();
    return function (p1) {
      return i.invoke_691(p1);
    };
  }
  function _no_name_provided_$factory_356(this$0) {
    var i = new _no_name_provided__380(this$0);
    return function (p1, p2) {
      i.invoke_693(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_357($tmp0_apply_0, this$0) {
    var i = new _no_name_provided__381($tmp0_apply_0, this$0);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_358(this$0) {
    var i = new _no_name_provided__382(this$0);
    return function (p1) {
      i.invoke_779(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_359(this$0) {
    var i = new _no_name_provided__383(this$0);
    return function (p1) {
      i.invoke_699(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_360(this$0) {
    var i = new _no_name_provided__384(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_361(this$0) {
    var i = new _no_name_provided__385(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_362(this$0) {
    var i = new _no_name_provided__386(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_363(this$0) {
    var i = new _no_name_provided__387(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_364(this$0) {
    var i = new _no_name_provided__388(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_365(this$0) {
    var i = new _no_name_provided__389(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_366(this$0) {
    var i = new _no_name_provided__390(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_367(this$0) {
    var i = new _no_name_provided__391(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_368(this$0) {
    var i = new _no_name_provided__392(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_369(this$0) {
    var i = new _no_name_provided__393(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_370(this$0) {
    var i = new _no_name_provided__394(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_371(this$0) {
    var i = new _no_name_provided__395(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_372(this$0) {
    var i = new _no_name_provided__396(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_373(this$0) {
    var i = new _no_name_provided__397(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_374(this$0) {
    var i = new _no_name_provided__398(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_375(this$0) {
    var i = new _no_name_provided__399(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_376(this$0) {
    var i = new _no_name_provided__400(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_377(this$0) {
    var i = new _no_name_provided__401(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_378(this$0) {
    var i = new _no_name_provided__402(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_379(this$0) {
    var i = new _no_name_provided__403(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_380(this$0) {
    var i = new _no_name_provided__404(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_381(this$0) {
    var i = new _no_name_provided__405(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_382(this$0) {
    var i = new _no_name_provided__406(this$0);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_383($this_scheduleRepaints) {
    var i = new _no_name_provided__407($this_scheduleRepaints);
    return function (p1) {
      i.invoke_791(p1);
      return Unit_getInstance();
    };
  }
  function toHtml(_this_, $this) {
    var tmp0_subject = _this_;
    var tmp;
    if (isInterface(tmp0_subject, Color_0)) {
      tmp = _this_._get_web__1();
    } else {
      if (tmp0_subject instanceof CanvasLinearGradient) {
        tmp = $this.toHtmlGradient(_this_);
      } else {
        if (tmp0_subject instanceof CanvasRadialGradient) {
          tmp = $this.toHtmlGradient_0(_this_);
        } else {
          {
            throw UnsupportedOperationException_init_$Create$_0('' + 'Unsupported paint <' + _this_ + '>');
          }
        }
      }
    }
    return tmp;
  }
  function _no_name_provided__408(this$0, $text) {
    this._this$0_271 = this$0;
    this._$text = $text;
  }
  _no_name_provided__408.prototype.invoke_1038 = function () {
    return this._this$0_271._context.measureText(this._$text).width;
  };
  _no_name_provided__408.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function CanvasRenderingContextJS(canvas) {
    AbstractCanvasRenderingContext.call(this);
    this._canvas_1 = canvas;
    this._context = _get_canvasRenderingContext2D_(this._canvas_1._canvasElement);
    this.applyDefaults_1();
  }
  CanvasRenderingContextJS.prototype._get_canvas__1 = function () {
    return this._canvas_1;
  };
  CanvasRenderingContextJS.prototype.resetTransform_1 = function () {
    AbstractCanvasRenderingContext.prototype.resetTransform_1.call(this);
    this._context.resetTransform();
  };
  CanvasRenderingContextJS.prototype.save_3 = function () {
    AbstractCanvasRenderingContext.prototype.save_3.call(this);
    this._context.save();
  };
  CanvasRenderingContextJS.prototype.restore_3 = function () {
    AbstractCanvasRenderingContext.prototype.restore_3.call(this);
    this._context.restore();
  };
  CanvasRenderingContextJS.prototype._get_width__41 = function () {
    return this._canvas_1._get_width__41();
  };
  CanvasRenderingContextJS.prototype._get_height__41 = function () {
    return this._canvas_1._get_height__41();
  };
  CanvasRenderingContextJS.prototype._set_lineWidth__1 = function (value) {
    this._context.lineWidth = value;
  };
  CanvasRenderingContextJS.prototype._get_lineWidth__1 = function () {
    return this._context.lineWidth;
  };
  CanvasRenderingContextJS.prototype._set_font__1 = function (value) {
    this._context.font = convertToHtmlFontString(value);
    FontConversionCacheJS_getInstance().store_1(value, this._context.font);
  };
  CanvasRenderingContextJS.prototype._get_font__1 = function () {
    var tmp;
    try {
      tmp = FontConversionCacheJS_getInstance().reverse(this._context.font);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Exception) {
        println('' + 'Warning: Could not reverse <' + this._context.font + '>');
        tmp_0 = Companion_getInstance_54()._Default;
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  CanvasRenderingContextJS.prototype.strokeLine_1 = function (startX, startY, endX, endY) {
    this._context.beginPath();
    this._context.moveTo(startX, startY);
    this._context.lineTo(endX, endY);
    this._context.stroke();
  };
  CanvasRenderingContextJS.prototype.fillRectInternal_1 = function (x, y, width, height) {
    this._context.fillRect(x, y, width, height);
  };
  CanvasRenderingContextJS.prototype.strokeRectInternal_1 = function (x, y, width, height) {
    this._context.strokeRect(x, y, width, height);
  };
  CanvasRenderingContextJS.prototype.arcTo_2 = function (controlX, controlY, x, y, radius) {
    var tmp0_require_0 = isPositiveOrZero(radius);
    if (!tmp0_require_0) {
      var message_1 = '' + 'Radius must be >= 0 but was <' + radius + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }this._context.arcTo(controlX, controlY, x, y, radius);
  };
  CanvasRenderingContextJS.prototype.beginPath_2 = function () {
    this._context.beginPath();
  };
  CanvasRenderingContextJS.prototype.closePath_2 = function () {
    this._context.closePath();
  };
  CanvasRenderingContextJS.prototype.moveTo_2 = function (x, y) {
    this._context.moveTo(x, y);
  };
  CanvasRenderingContextJS.prototype.lineTo_2 = function (x, y) {
    this._context.lineTo(x, y);
  };
  CanvasRenderingContextJS.prototype.stroke_4 = function () {
    this._context.stroke();
  };
  CanvasRenderingContextJS.prototype.fill_4 = function () {
    this._context.fill();
  };
  CanvasRenderingContextJS.prototype.translate_35 = function (deltaX, deltaY) {
    AbstractCanvasRenderingContext.prototype.translate_35.call(this, deltaX, deltaY);
    this._context.translate(deltaX, deltaY);
  };
  CanvasRenderingContextJS.prototype.scale_3 = function (x, y) {
    AbstractCanvasRenderingContext.prototype.scale_3.call(this, x, y);
    this._context.scale(x, y);
  };
  CanvasRenderingContextJS.prototype._set_textBaseline_ = function (value) {
    this._context.textBaseline = toHtml_0(value);
  };
  CanvasRenderingContextJS.prototype._set_horizontalAlignment_ = function (value) {
    this._context.textAlign = toHtml_1(value);
  };
  CanvasRenderingContextJS.prototype.fillText_2 = function (text_0, x, y, anchorDirection, gap, maxWidth, maxHeight, stringShortener) {
    if (!(maxHeight == null) ? maxHeight < this.getFontMetrics_1()._get_totalHeight_() : false) {
      return Unit_getInstance();
    }this._set_horizontalAlignment_(anchorDirection._horizontalAlignment);
    this._set_textBaseline_(anchorDirection._verticalAlignment);
    var relevantX = x + calculateXOffsetForGap_0(anchorDirection, gap);
    var relevantY = y + calculateYOffsetForGap_0(anchorDirection, gap) + this.getFontAlignmentCorrection_0(anchorDirection);
    if (!(maxWidth == null)) {
      if (this._get_debug__1().get_32(DebugFeature_ShowMaxTextWidth_getInstance())) {
        this.save_3();
        try {
          var left_2 = relevantX + calculateLeft(anchorDirection._horizontalAlignment, maxWidth);
          var right_3 = left_2 + maxWidth;
          this.strokeLine_1(left_2, relevantY - 5, left_2, relevantY + 5);
          this.strokeLine_1(left_2, relevantY, right_3, relevantY);
          this.strokeLine_1(right_3, relevantY - 5, right_3, relevantY + 5);
        }finally {
          this.restore_3();
        }
      }if (this._get_debug__1().get_32(DebugFeature_UnShortenedTexts_getInstance())) {
        this.save_3();
        try {
          var width_2 = this.calculateTextWidth_1(text_0);
          if (width_2 > maxWidth) {
            this.fill_5(Companion_getInstance_29()._silver);
            this._context.fillText(text_0, relevantX, relevantY);
            this.stroke_5(Companion_getInstance_29()._darkgray);
            this._context.strokeText(text_0, relevantX, relevantY);
          }}finally {
          this.restore_3();
        }
      }var tmp0_safe_receiver = stringShortener.shorten_0(text_0, maxWidth, this);
      if (tmp0_safe_receiver == null)
        null;
      else {
        this._context.fillText(tmp0_safe_receiver, relevantX, relevantY);
        Unit_getInstance();
      }
      Unit_getInstance();
    } else {
      this._context.fillText(text_0, relevantX, relevantY);
    }
  };
  CanvasRenderingContextJS.prototype.clearRect_2 = function (x, y, width, height) {
    this._context.clearRect(x, y, width, height);
  };
  CanvasRenderingContextJS.prototype.rect_2 = function (x, y, width, height) {
    this._context.rect(x, y, width, height);
  };
  CanvasRenderingContextJS.prototype.strokeStyle_2 = function (color) {
    this._context.strokeStyle = toHtml(color, this);
  };
  CanvasRenderingContextJS.prototype.fillStyle_2 = function (color) {
    this._context.fillStyle = toHtml(color, this);
  };
  CanvasRenderingContextJS.prototype.toHtmlGradient = function (_this__0) {
    var gradient = this._context.createLinearGradient(_this__0._x0, _this__0._y0, _this__0._x1, _this__0._y1);
    gradient.addColorStop(0.0, _this__0._color0._get_web__1());
    gradient.addColorStop(1.0, _this__0._color1._get_web__1());
    return gradient;
  };
  CanvasRenderingContextJS.prototype.toHtmlGradient_0 = function (_this__0) {
    var gradient = this._context.createRadialGradient(_this__0._positionX_0, _this__0._positionY_0, 0.0, _this__0._positionX_0, _this__0._positionY_0, _this__0._radius);
    gradient.addColorStop(0.0, _this__0._color0_0._get_web__1());
    gradient.addColorStop(1.0, _this__0._color1_0._get_web__1());
    return gradient;
  };
  CanvasRenderingContextJS.prototype.getFontMetrics_1 = function () {
    return FontMetricsCacheJS_getInstance().getValue_11(this._get_font__1());
  };
  CanvasRenderingContextJS.prototype.calculateTextWidth_1 = function (text_0) {
    var tmp = FontBoundsCacheJS_getInstance();
    var tmp_0 = this._get_font__1();
    return tmp.calculateWidth(text_0, tmp_0, _no_name_provided_$factory_384(this, text_0));
  };
  CanvasRenderingContextJS.prototype.paintImage_1 = function (image, x, y, width, height) {
    var tmp = image._data_2;
    this._context.drawImage(isObject(tmp) ? tmp : THROW_CCE(), x, y, width, height);
  };
  CanvasRenderingContextJS.prototype.paintImagePixelPerfect_1 = function (image, x, y) {
    this.save_3();
    try {
      var tmp0_let_0_2 = this._get_translation__1();
      var exactTargetTranslationX_2_3 = tmp0_let_0_2._x_0 + x;
      var exactTargetTranslationY_3_4 = tmp0_let_0_2._y_0 + y;
      var roundedTargetTranslationX_4_5 = round_0(exactTargetTranslationX_2_3);
      var roundedTargetTranslationY_5_6 = round_0(exactTargetTranslationY_3_4);
      this._set_translation__0(new Distance(roundedTargetTranslationX_4_5, roundedTargetTranslationY_5_6));
      this._set_scale__0(new Zoom(1.0, 1.0));
      var tmp = image._data_2;
      var imageJS_7 = isObject(tmp) ? tmp : THROW_CCE();
      this._context.drawImage(imageJS_7, 0.0, 0.0);
    }finally {
      this.restore_3();
    }
  };
  CanvasRenderingContextJS.prototype._set_lineJoin__1 = function (value) {
    this._context.lineJoin = toHtml_2(value);
  };
  CanvasRenderingContextJS.$metadata$ = {
    simpleName: 'CanvasRenderingContextJS',
    kind: 'class',
    interfaces: []
  };
  function _get_canvasRenderingContext2D_(_this_) {
    var tmp = function () {
      var $externalVarargReceiverTmp = _this_;
      return $externalVarargReceiverTmp.getContext.apply($externalVarargReceiverTmp, ['2d']);
    }.call(this);
    var tmp0_elvis_lhs = tmp instanceof CanvasRenderingContext2D ? tmp : null;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      throw IllegalStateException_init_$Create$_0('context not found');
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    return tmp_0;
  }
  function toHtml_0(_this_) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(VerticalAlignment_Center_getInstance())) {
      var tmp0__get_MIDDLE__0 = null;
      var tmp0_asDynamic_0_2 = 'middle';
      var tmp1_unsafeCast_0_1 = tmp0_asDynamic_0_2;
      tmp = tmp1_unsafeCast_0_1;
    } else if (tmp0_subject.equals(VerticalAlignment_Baseline_getInstance())) {
      var tmp1__get_ALPHABETIC__0 = null;
      var tmp0_asDynamic_0_2_0 = 'alphabetic';
      var tmp1_unsafeCast_0_1_0 = tmp0_asDynamic_0_2_0;
      tmp = tmp1_unsafeCast_0_1_0;
    } else if (tmp0_subject.equals(VerticalAlignment_Bottom_getInstance())) {
      var tmp2__get_BOTTOM__0 = null;
      var tmp0_asDynamic_0_2_1 = 'bottom';
      var tmp1_unsafeCast_0_1_1 = tmp0_asDynamic_0_2_1;
      tmp = tmp1_unsafeCast_0_1_1;
    } else if (tmp0_subject.equals(VerticalAlignment_Top_getInstance())) {
      var tmp3__get_TOP__0 = null;
      var tmp0_asDynamic_0_2_2 = 'top';
      var tmp1_unsafeCast_0_1_2 = tmp0_asDynamic_0_2_2;
      tmp = tmp1_unsafeCast_0_1_2;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function toHtml_1(_this_) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(HorizontalAlignment_Left_getInstance())) {
      var tmp0__get_LEFT__0 = null;
      var tmp0_asDynamic_0_2 = 'left';
      var tmp1_unsafeCast_0_1 = tmp0_asDynamic_0_2;
      tmp = tmp1_unsafeCast_0_1;
    } else if (tmp0_subject.equals(HorizontalAlignment_Right_getInstance())) {
      var tmp1__get_RIGHT__0 = null;
      var tmp0_asDynamic_0_2_0 = 'right';
      var tmp1_unsafeCast_0_1_0 = tmp0_asDynamic_0_2_0;
      tmp = tmp1_unsafeCast_0_1_0;
    } else if (tmp0_subject.equals(HorizontalAlignment_Center_getInstance())) {
      var tmp2__get_CENTER__0 = null;
      var tmp0_asDynamic_0_2_1 = 'center';
      var tmp1_unsafeCast_0_1_1 = tmp0_asDynamic_0_2_1;
      tmp = tmp1_unsafeCast_0_1_1;
    } else {
      throw IllegalArgumentException_init_$Create$('' + 'Invalid text-alignment ' + _this_);
    }
    return tmp;
  }
  function toHtml_2(_this_) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(LineJoin_Miter_getInstance())) {
      var tmp0__get_MITER__0 = null;
      var tmp0_asDynamic_0_2 = 'miter';
      var tmp1_unsafeCast_0_1 = tmp0_asDynamic_0_2;
      tmp = tmp1_unsafeCast_0_1;
    } else if (tmp0_subject.equals(LineJoin_Bevel_getInstance())) {
      var tmp1__get_BEVEL__0 = null;
      var tmp0_asDynamic_0_2_0 = 'bevel';
      var tmp1_unsafeCast_0_1_0 = tmp0_asDynamic_0_2_0;
      tmp = tmp1_unsafeCast_0_1_0;
    } else if (tmp0_subject.equals(LineJoin_Round_getInstance())) {
      var tmp2__get_ROUND__0 = null;
      var tmp0_asDynamic_0_2_1 = 'round';
      var tmp1_unsafeCast_0_1_1 = tmp0_asDynamic_0_2_1;
      tmp = tmp1_unsafeCast_0_1_1;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function _no_name_provided_$factory_384(this$0, $text) {
    var i = new _no_name_provided__408(this$0, $text);
    return function () {
      return i.invoke_1038();
    };
  }
  var additionalMouseCursorTypes;
  function unselectable(_this_) {
    _this_.style.setProperty('user-select', 'none');
    _this_.style.setProperty('-moz-user-select', '-moz-none');
    _this_.style.setProperty('-khtml-user-select', 'none');
    _this_.style.setProperty('-webkit-user-select', 'none');
    _this_.style.setProperty('-ms -user-select', 'none');
  }
  function noFocusBorder(_this_) {
    _this_.style.setProperty('outline', 'none');
  }
  function toCss(_this_) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(MouseCursor_Default_getInstance())) {
      tmp = 'default';
    } else if (tmp0_subject.equals(MouseCursor_Hand_getInstance())) {
      tmp = 'pointer';
    } else if (tmp0_subject.equals(MouseCursor_OpenHand_getInstance())) {
      tmp = 'grab';
    } else if (tmp0_subject.equals(MouseCursor_ClosedHand_getInstance())) {
      tmp = 'grabbing';
    } else if (tmp0_subject.equals(MouseCursor_CrossHair_getInstance())) {
      tmp = 'crosshair';
    } else if (tmp0_subject.equals(MouseCursor_Text_getInstance())) {
      tmp = 'text';
    } else if (tmp0_subject.equals(MouseCursor_Busy_getInstance())) {
      tmp = 'wait';
    } else if (tmp0_subject.equals(MouseCursor_Move_getInstance())) {
      tmp = 'move';
    } else if (tmp0_subject.equals(MouseCursor_None_getInstance())) {
      tmp = 'none';
    } else if (tmp0_subject.equals(MouseCursor_ResizeNorth_getInstance())) {
      tmp = 'n-resize';
    } else if (tmp0_subject.equals(MouseCursor_ResizeNorthEast_getInstance())) {
      tmp = 'ne-resize';
    } else if (tmp0_subject.equals(MouseCursor_ResizeEast_getInstance())) {
      tmp = 'e-resize';
    } else if (tmp0_subject.equals(MouseCursor_ResizeSouthEast_getInstance())) {
      tmp = 'se-resize';
    } else if (tmp0_subject.equals(MouseCursor_ResizeSouth_getInstance())) {
      tmp = 's-resize';
    } else if (tmp0_subject.equals(MouseCursor_ResizeSouthWest_getInstance())) {
      tmp = 'sw-resize';
    } else if (tmp0_subject.equals(MouseCursor_ResizeWest_getInstance())) {
      tmp = 'w-resize';
    } else if (tmp0_subject.equals(MouseCursor_ResizeNorthWest_getInstance())) {
      tmp = 'nw-resize';
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function extractModifierCombination(_this_) {
    return new ModifierCombination(_this_.shiftKey, _this_.ctrlKey, _this_.altKey, _this_.metaKey);
  }
  function _get_timeStampAsDoubleWorkaround_(_this_) {
    var tmp = _this_.timeStamp;
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  }
  function offset(_this_) {
    return Companion_getInstance_32().of_4(_this_.offsetX, _this_.offsetY);
  }
  function convertOver(_this_) {
    return new PointerOverEvent(_get_timeStampAsDoubleWorkaround_(_this_), _get_pointer_(_this_));
  }
  function convertEnter(_this_) {
    return new PointerEnterEvent(_get_timeStampAsDoubleWorkaround_(_this_), _get_pointer_(_this_));
  }
  function convertDown(_this_) {
    return new PointerDownEvent(_get_timeStampAsDoubleWorkaround_(_this_), _get_pointer_(_this_));
  }
  function convertMove(_this_) {
    return new PointerMoveEvent(_get_timeStampAsDoubleWorkaround_(_this_), _get_pointer_(_this_));
  }
  function convertUp(_this_) {
    return new PointerUpEvent(_get_timeStampAsDoubleWorkaround_(_this_), _get_pointer_(_this_));
  }
  function convertCancel(_this_) {
    return new PointerCancelEvent(_get_timeStampAsDoubleWorkaround_(_this_), _get_pointer_(_this_));
  }
  function convertOut(_this_) {
    return new PointerOutEvent(_get_timeStampAsDoubleWorkaround_(_this_), _get_pointer_(_this_));
  }
  function convertLeave(_this_) {
    return new PointerLeaveEvent(_get_timeStampAsDoubleWorkaround_(_this_), _get_pointer_(_this_));
  }
  function convertPress(_this_) {
    var combinedCode = _this_.keyCode | _this_.which;
    return new KeyDownEvent(_get_timeStampAsDoubleWorkaround_(_this_), _this_.key, new KeyStroke(new KeyCode(combinedCode), extractModifierCombination_0(_this_)));
  }
  function convertRelease(_this_) {
    var combinedCode = _this_.keyCode | _this_.which;
    return new KeyUpEvent(_get_timeStampAsDoubleWorkaround_(_this_), _this_.key, new KeyStroke(new KeyCode(combinedCode), extractModifierCombination_0(_this_)));
  }
  function convertType(_this_) {
    var combinedCode = _this_.keyCode | _this_.which;
    return new KeyTypeEvent(_get_timeStampAsDoubleWorkaround_(_this_), _this_.key, new KeyStroke(new KeyCode(combinedCode), extractModifierCombination_0(_this_)));
  }
  function convertStart(_this_, boundingClientLocation) {
    return new TouchStartEvent(_get_timeStampAsDoubleWorkaround_(_this_), convert(_this_.changedTouches, boundingClientLocation), convert(_this_.targetTouches, boundingClientLocation), convert(_this_.touches, boundingClientLocation));
  }
  function convertEnd(_this_, boundingClientLocation) {
    return new TouchEndEvent(_get_timeStampAsDoubleWorkaround_(_this_), convert(_this_.changedTouches, boundingClientLocation), convert(_this_.targetTouches, boundingClientLocation), convert(_this_.touches, boundingClientLocation));
  }
  function convertMove_0(_this_, boundingClientLocation) {
    return new TouchMoveEvent(_get_timeStampAsDoubleWorkaround_(_this_), convert(_this_.changedTouches, boundingClientLocation), convert(_this_.targetTouches, boundingClientLocation), convert(_this_.touches, boundingClientLocation));
  }
  function convertCancel_0(_this_, boundingClientLocation) {
    return new TouchCancelEvent(_get_timeStampAsDoubleWorkaround_(_this_), convert(_this_.changedTouches, boundingClientLocation), convert(_this_.targetTouches, boundingClientLocation), convert(_this_.touches, boundingClientLocation));
  }
  function _get_pointer_(_this_) {
    return new Pointer(_PointerId___init__impl_(_this_.pointerId), offset(_this_));
  }
  function extractModifierCombination_0(_this_) {
    return new ModifierCombination(_this_.shiftKey, _this_.ctrlKey, _this_.altKey, _this_.metaKey);
  }
  function convert(_this_, boundingClientLocation) {
    var result = ArrayList_init_$Create$();
    var inductionVariable = 0;
    var last_1 = _this_.length;
    if (inductionVariable < last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp1_safe_receiver = _this_.item(i);
        var tmp2_safe_receiver = tmp1_safe_receiver == null ? null : convert_0(tmp1_safe_receiver, boundingClientLocation);
        if (tmp2_safe_receiver == null)
          null;
        else {
          result.add_57(tmp2_safe_receiver);
        }
        Unit_getInstance();
      }
       while (inductionVariable < last_1);
    return result;
  }
  function convert_0(_this_, boundingClientLocation) {
    return new Touch_0(_TouchId___init__impl_(_this_.identifier), new Coordinates(_this_.pageX - boundingClientLocation._x - window.pageXOffset, _this_.pageY - boundingClientLocation._y - window.pageYOffset));
  }
  function removeFromParent(_this_) {
    var tmp0_safe_receiver = _this_.parentNode;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.removeChild(_this_);
    Unit_getInstance();
  }
  function _no_name_provided__409() {
  }
  _no_name_provided__409.prototype.invoke_785 = function (it) {
    FontBoundsCacheJS_getInstance()._widthCache.clear_27();
  };
  _no_name_provided__409.prototype.invoke_1082 = function (p1) {
    this.invoke_785(p1 instanceof FontLoadedEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__409.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function FontBoundsCacheJS() {
    FontBoundsCacheJS_instance = this;
    this._widthCache = cache('HtmlFontBoundsCache-width', 500);
    var tmp = FontLoadedEventBroker_getInstance();
    tmp.onLoaded(_no_name_provided_$factory_385());
    Unit_getInstance();
  }
  FontBoundsCacheJS.prototype.calculateWidth = function (text_0, font, calculate) {
    var key = imul(31, getStringHashCode(text_0)) + imul(17, font.hashCode()) | 0;
    var tmp0_getOrStore_0 = this._widthCache;
    var tmp0_getOrPut_0_1 = tmp0_getOrStore_0._map_1;
    var value_1_2 = tmp0_getOrPut_0_1.get_20(key);
    var tmp;
    if (value_1_2 == null) {
      var tmp0_this_4_4 = tmp0_getOrStore_0;
      var tmp1_5_5 = tmp0_this_4_4._cacheMissCounter;
      tmp0_this_4_4._cacheMissCounter = tmp1_5_5 + 1 | 0;
      Unit_getInstance();
      var answer_2_3 = calculate();
      tmp0_getOrPut_0_1.put_6(key, answer_2_3);
      Unit_getInstance();
      tmp = answer_2_3;
    } else {
      tmp = value_1_2;
    }
    return tmp;
  };
  FontBoundsCacheJS.$metadata$ = {
    simpleName: 'FontBoundsCacheJS',
    kind: 'object',
    interfaces: []
  };
  var FontBoundsCacheJS_instance;
  function FontBoundsCacheJS_getInstance() {
    if (FontBoundsCacheJS_instance == null)
      new FontBoundsCacheJS();
    return FontBoundsCacheJS_instance;
  }
  function _no_name_provided_$factory_385() {
    var i = new _no_name_provided__409();
    return function (p1) {
      i.invoke_785(p1);
      return Unit_getInstance();
    };
  }
  function FontConversionCacheJS() {
    FontConversionCacheJS_instance = this;
    this._fromHtmlCache = cache('HtmlFontConversionCache', 500);
  }
  FontConversionCacheJS.prototype.store_1 = function (value, htmlFontString) {
    this._fromHtmlCache.set_5(htmlFontString, value);
  };
  FontConversionCacheJS.prototype.reverse = function (htmlFontString) {
    var tmp0_elvis_lhs = this._fromHtmlCache.get_20(htmlFontString);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException_init_$Create$('' + 'No entry available for <' + htmlFontString + '>');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  FontConversionCacheJS.$metadata$ = {
    simpleName: 'FontConversionCacheJS',
    kind: 'object',
    interfaces: []
  };
  var FontConversionCacheJS_instance;
  function FontConversionCacheJS_getInstance() {
    if (FontConversionCacheJS_instance == null)
      new FontConversionCacheJS();
    return FontConversionCacheJS_instance;
  }
  function convertToHtmlFontString(_this_) {
    var htmlFontWeight = toHtmlFontWeightString(_this_._weight_0);
    var htmlFontStyle = toHtmlFontStyleString(_this_._style_4);
    var htmlFontVariant = toHtmlFontVariantString(_this_._variant_0);
    var htmlFontSize = '' + _this_._size_4._size_5 + 'px';
    var htmlFontFamily = _this_._family_0._family_1;
    return '' + htmlFontStyle + ' ' + htmlFontVariant + ' ' + htmlFontWeight + ' ' + htmlFontSize + ' ' + htmlFontFamily;
  }
  function toHtmlFontWeightString(_this_) {
    return _this_._weight_1.toString();
  }
  function toHtmlFontStyleString(_this_) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(FontStyle_Normal_getInstance())) {
      tmp = '';
    } else if (tmp0_subject.equals(FontStyle_Italic_getInstance())) {
      tmp = 'italic';
    } else if (tmp0_subject.equals(FontStyle_Oblique_getInstance())) {
      tmp = 'oblique';
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function toHtmlFontVariantString(_this_) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject.equals(FontVariant_Normal_getInstance())) {
      tmp = '';
    } else if (tmp0_subject.equals(FontVariant_SmallCaps_getInstance())) {
      tmp = 'small-caps';
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function _no_name_provided__410() {
  }
  _no_name_provided__410.prototype.invoke_785 = function (it) {
    FontMetricsCacheJS_getInstance()._fontMetricsCache.clear_27();
  };
  _no_name_provided__410.prototype.invoke_1082 = function (p1) {
    this.invoke_785(p1 instanceof FontLoadedEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__410.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function FontMetricsCacheJS() {
    FontMetricsCacheJS_instance = this;
    this._fontMetricsCache = cache('HtmlCanvasFontMetricsCache', 500);
    var tmp = this;
    tmp._fontMetricsCalculator = CanvasFontMetricsCalculatorJS_init_$Create$(null, 0.0, null, 7, null);
    var tmp_0 = FontLoadedEventBroker_getInstance();
    tmp_0.onLoaded(_no_name_provided_$factory_386());
    Unit_getInstance();
  }
  FontMetricsCacheJS.prototype.getValue_11 = function (font) {
    var tmp0_getOrStore_0 = this._fontMetricsCache;
    var tmp0_getOrPut_0_1 = tmp0_getOrStore_0._map_1;
    var value_1_2 = tmp0_getOrPut_0_1.get_20(font);
    var tmp;
    if (value_1_2 == null) {
      var tmp0_this_4_4 = tmp0_getOrStore_0;
      var tmp1_5_5 = tmp0_this_4_4._cacheMissCounter;
      tmp0_this_4_4._cacheMissCounter = tmp1_5_5 + 1 | 0;
      Unit_getInstance();
      var answer_2_3 = FontMetricsCacheJS_getInstance()._fontMetricsCalculator.calculateFontMetrics_0(font);
      tmp0_getOrPut_0_1.put_6(font, answer_2_3);
      Unit_getInstance();
      tmp = answer_2_3;
    } else {
      tmp = value_1_2;
    }
    return tmp;
  };
  FontMetricsCacheJS.$metadata$ = {
    simpleName: 'FontMetricsCacheJS',
    kind: 'object',
    interfaces: []
  };
  var FontMetricsCacheJS_instance;
  function FontMetricsCacheJS_getInstance() {
    if (FontMetricsCacheJS_instance == null)
      new FontMetricsCacheJS();
    return FontMetricsCacheJS_instance;
  }
  function _no_name_provided_$factory_386() {
    var i = new _no_name_provided__410();
    return function (p1) {
      i.invoke_785(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__411($it, $comboBox) {
    this._$it_0 = $it;
    this._$comboBox = $comboBox;
  }
  _no_name_provided__411.prototype.invoke_754 = function (choices) {
    var options = this._$it_0.options;
    while (options.length > 0) {
      var tmp0_get_0 = 0;
      var tmp0_safe_receiver = options[tmp0_get_0];
      if (tmp0_safe_receiver == null)
        null;
      else {
        removeFromParent(tmp0_safe_receiver);
        Unit_getInstance();
      }
      Unit_getInstance();
    }
    var tmp0_iterator_1 = choices.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      var tmp = document.createElement('option');
      var optionElement_4 = tmp instanceof HTMLOptionElement ? tmp : THROW_CCE();
      optionElement_4.text = this._$comboBox._get_converter___error()(element_2);
      options.add(optionElement_4);
    }
  };
  _no_name_provided__411.prototype.invoke_1082 = function (p1) {
    this.invoke_754((!(p1 == null) ? isInterface(p1, List) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__411.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__412($comboBox) {
    this._$comboBox_0 = $comboBox;
  }
  _no_name_provided__412.prototype.invoke_1038 = function () {
    return this._$comboBox_0._get_choices___error();
  };
  _no_name_provided__412.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__413($textInput) {
    this._$textInput = $textInput;
  }
  _no_name_provided__413.prototype.invoke_759 = function (it) {
    it.setAttribute('type', 'text');
    bindTextBidirectional(it, this._$textInput.textProperty__error);
  };
  _no_name_provided__413.prototype.invoke_1082 = function (p1) {
    this.invoke_759(p1 instanceof HTMLInputElement ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__413.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__414($checkBox) {
    this._$checkBox = $checkBox;
  }
  _no_name_provided__414.prototype.invoke_759 = function (it) {
    it.setAttribute('type', 'checkbox');
    bindSelectedBidirectional(it, this._$checkBox.selectedProperty__error);
  };
  _no_name_provided__414.prototype.invoke_1082 = function (p1) {
    this.invoke_759(p1 instanceof HTMLInputElement ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__414.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__415($comboBox) {
    this._$comboBox_1 = $comboBox;
  }
  _no_name_provided__415.prototype.invoke_761 = function (it) {
    this._$comboBox_1.choicesProperty__error.consumeImmediately_3(_no_name_provided_$factory_391(it, this._$comboBox_1));
    Unit_getInstance();
    bindChoiceBidirectional(it, this._$comboBox_1.selectedProperty__error, _no_name_provided_$factory_392(this._$comboBox_1));
  };
  _no_name_provided__415.prototype.invoke_1082 = function (p1) {
    this.invoke_761(p1 instanceof HTMLSelectElement ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__415.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function registerPlattformComponent($this, nativeComponent, plattformComponent, binder) {
    nativeComponent._set_id___error(plattformComponent);
    plattformComponent.style.setProperty('pointer-events', 'auto');
    plattformComponent.style.setProperty('position', 'absolute');
    bindLocation(plattformComponent, nativeComponent);
    $this._div.appendChild(plattformComponent);
    Unit_getInstance();
    nativeComponent.onDispose_10(_no_name_provided_$factory_387(plattformComponent));
    binder(plattformComponent);
  }
  function _no_name_provided__416(this$0) {
    this._this$0_272 = this$0;
  }
  _no_name_provided__416.prototype.textInput_0 = function (textInput_1) {
    var tmp = document.createElement('input');
    var tmp_0 = tmp instanceof HTMLInputElement ? tmp : THROW_CCE();
    registerPlattformComponent(this._this$0_272, textInput_1, tmp_0, _no_name_provided_$factory_388(textInput_1));
  };
  _no_name_provided__416.prototype.checkBox_0 = function (checkBox) {
    var tmp = document.createElement('input');
    var tmp_0 = tmp instanceof HTMLInputElement ? tmp : THROW_CCE();
    registerPlattformComponent(this._this$0_272, checkBox, tmp_0, _no_name_provided_$factory_389(checkBox));
  };
  _no_name_provided__416.prototype.comboBox_0 = function (comboBox) {
    var tmp = document.createElement('select');
    var tmp_0 = tmp instanceof HTMLSelectElement ? tmp : THROW_CCE();
    registerPlattformComponent(this._this$0_272, comboBox, tmp_0, _no_name_provided_$factory_390(comboBox));
  };
  _no_name_provided__416.$metadata$ = {
    kind: 'class',
    interfaces: [NativeComponentsHandler]
  };
  function _no_name_provided__417($plattformComponent) {
    this._$plattformComponent = $plattformComponent;
  }
  _no_name_provided__417.prototype.invoke_1037 = function () {
    removeFromParent(this._$plattformComponent);
  };
  _no_name_provided__417.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__417.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function NativeComponentsJS(chartSupport) {
    var tmp = this;
    var tmp_0 = document.createElement('DIV');
    var tmp0_also_0 = tmp_0 instanceof HTMLDivElement ? tmp_0 : THROW_CCE();
    tmp0_also_0.style.setProperty('width', '100%');
    tmp0_also_0.style.setProperty('height', '100%');
    tmp0_also_0.style.setProperty('position', 'absolute');
    tmp0_also_0.style.setProperty('top', '0');
    tmp0_also_0.style.setProperty('left', '0');
    tmp0_also_0.style.setProperty('pointer-events', 'none');
    tmp._div = tmp0_also_0;
    var tmp_1 = _get_nativeComponentsSupport_(chartSupport);
    tmp_1.onComponent(new _no_name_provided__416(this));
  }
  NativeComponentsJS.$metadata$ = {
    simpleName: 'NativeComponentsJS',
    kind: 'class',
    interfaces: []
  };
  function bindTextBidirectional(_this_, property) {
    _this_.oninput = _no_name_provided_$factory_393(property, _this_);
    property.consumeImmediately_3(_no_name_provided_$factory_394(_this_));
    Unit_getInstance();
  }
  function bindSelectedBidirectional(_this_, property) {
    _this_.oninput = _no_name_provided_$factory_395(property, _this_);
    property.consumeImmediately_3(_no_name_provided_$factory_396(_this_));
    Unit_getInstance();
  }
  function bindChoiceBidirectional(_this_, property, choicesProvider) {
    _this_.oninput = _no_name_provided_$factory_397(choicesProvider, _this_, property);
    property.consumeImmediately_3(_no_name_provided_$factory_398(_this_, choicesProvider));
    Unit_getInstance();
  }
  function bindLocation(_this_, nativeComponent) {
    var tmp = nativeComponent._get_locationProperty___error();
    tmp.consumeImmediately_3(_no_name_provided_$factory_399(_this_));
    Unit_getInstance();
  }
  function _no_name_provided__418($property, $this_bindTextBidirectional) {
    this._$property = $property;
    this._$this_bindTextBidirectional = $this_bindTextBidirectional;
  }
  _no_name_provided__418.prototype.invoke_773 = function (it) {
    this._$property._set_value__12(this._$this_bindTextBidirectional.value);
    Unit_getInstance();
  };
  _no_name_provided__418.prototype.invoke_1082 = function (p1) {
    this.invoke_773(p1 instanceof InputEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__418.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__419($this_bindTextBidirectional) {
    this._$this_bindTextBidirectional_0 = $this_bindTextBidirectional;
  }
  _no_name_provided__419.prototype.invoke_767 = function (it) {
    this._$this_bindTextBidirectional_0.value = it;
  };
  _no_name_provided__419.prototype.invoke_1082 = function (p1) {
    this.invoke_767((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__419.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__420($property, $this_bindSelectedBidirectional) {
    this._$property_0 = $property;
    this._$this_bindSelectedBidirectional = $this_bindSelectedBidirectional;
  }
  _no_name_provided__420.prototype.invoke_773 = function (it) {
    this._$property_0._set_value__12(this._$this_bindSelectedBidirectional.checked);
    Unit_getInstance();
  };
  _no_name_provided__420.prototype.invoke_1082 = function (p1) {
    this.invoke_773(p1 instanceof InputEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__420.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__421($this_bindSelectedBidirectional) {
    this._$this_bindSelectedBidirectional_0 = $this_bindSelectedBidirectional;
  }
  _no_name_provided__421.prototype.invoke_771 = function (it) {
    this._$this_bindSelectedBidirectional_0.checked = it;
  };
  _no_name_provided__421.prototype.invoke_1082 = function (p1) {
    this.invoke_771((!(p1 == null) ? typeof p1 === 'boolean' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__421.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__422($choicesProvider, $this_bindChoiceBidirectional, $property) {
    this._$choicesProvider = $choicesProvider;
    this._$this_bindChoiceBidirectional = $this_bindChoiceBidirectional;
    this._$property_1 = $property;
  }
  _no_name_provided__422.prototype.invoke_773 = function (it) {
    var choice = this._$choicesProvider().get_44(this._$this_bindChoiceBidirectional.selectedIndex);
    this._$property_1._set_value__12(choice);
    Unit_getInstance();
  };
  _no_name_provided__422.prototype.invoke_1082 = function (p1) {
    this.invoke_773(p1 instanceof InputEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__422.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__423($this_bindChoiceBidirectional, $choicesProvider) {
    this._$this_bindChoiceBidirectional_0 = $this_bindChoiceBidirectional;
    this._$choicesProvider_0 = $choicesProvider;
  }
  _no_name_provided__423.prototype.invoke_775 = function (it) {
    this._$this_bindChoiceBidirectional_0.selectedIndex = indexOf_0(this._$choicesProvider_0(), it);
  };
  _no_name_provided__423.prototype.invoke_1082 = function (p1) {
    this.invoke_775((p1 == null ? true : p1 instanceof Choice) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__423.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__424($this_bindLocation) {
    this._$this_bindLocation = $this_bindLocation;
  }
  _no_name_provided__424.prototype.invoke_777 = function (coordinates) {
    this._$this_bindLocation.style.setProperty('left', '' + coordinates._x + 'px');
    this._$this_bindLocation.style.setProperty('top', '' + coordinates._y + 'px');
  };
  _no_name_provided__424.prototype.invoke_1082 = function (p1) {
    this.invoke_777(p1 instanceof Coordinates ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__424.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_387($plattformComponent) {
    var i = new _no_name_provided__417($plattformComponent);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_388($textInput) {
    var i = new _no_name_provided__413($textInput);
    return function (p1) {
      i.invoke_759(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_389($checkBox) {
    var i = new _no_name_provided__414($checkBox);
    return function (p1) {
      i.invoke_759(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_390($comboBox) {
    var i = new _no_name_provided__415($comboBox);
    return function (p1) {
      i.invoke_761(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_391($it, $comboBox) {
    var i = new _no_name_provided__411($it, $comboBox);
    return function (p1) {
      i.invoke_754(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_392($comboBox) {
    var i = new _no_name_provided__412($comboBox);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_393($property, $this_bindTextBidirectional) {
    var i = new _no_name_provided__418($property, $this_bindTextBidirectional);
    return function (p1) {
      i.invoke_773(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_394($this_bindTextBidirectional) {
    var i = new _no_name_provided__419($this_bindTextBidirectional);
    return function (p1) {
      i.invoke_767(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_395($property, $this_bindSelectedBidirectional) {
    var i = new _no_name_provided__420($property, $this_bindSelectedBidirectional);
    return function (p1) {
      i.invoke_773(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_396($this_bindSelectedBidirectional) {
    var i = new _no_name_provided__421($this_bindSelectedBidirectional);
    return function (p1) {
      i.invoke_771(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_397($choicesProvider, $this_bindChoiceBidirectional, $property) {
    var i = new _no_name_provided__422($choicesProvider, $this_bindChoiceBidirectional, $property);
    return function (p1) {
      i.invoke_773(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_398($this_bindChoiceBidirectional, $choicesProvider) {
    var i = new _no_name_provided__423($this_bindChoiceBidirectional, $choicesProvider);
    return function (p1) {
      i.invoke_775(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_399($this_bindLocation) {
    var i = new _no_name_provided__424($this_bindLocation);
    return function (p1) {
      i.invoke_777(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__425($this_configure) {
    this._$this_configure = $this_configure;
  }
  _no_name_provided__425.prototype.invoke_779 = function (it) {
    this._$this_configure._get_chartSupport__3().markAsDirty_4();
  };
  _no_name_provided__425.prototype.invoke_1082 = function (p1) {
    this.invoke_779(p1 instanceof Size ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__425.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Companion_70() {
    Companion_instance_69 = this;
  }
  Companion_70.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_69;
  function Companion_getInstance_69() {
    if (Companion_instance_69 == null)
      new Companion_70();
    return Companion_instance_69;
  }
  function _no_name_provided__426() {
  }
  _no_name_provided__426.prototype.invoke_816 = function ($this$configure) {
    var tmp = $this$configure._get_chartSupport__3()._canvas._get_sizeProperty__1();
    tmp.consume$default_3(false, _no_name_provided_$factory_401($this$configure), 1, null);
    Unit_getInstance();
  };
  _no_name_provided__426.prototype.invoke_1082 = function (p1) {
    this.invoke_816((!(p1 == null) ? isInterface(p1, LayerSupport) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__426.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function QuickChartBuilderJS(description) {
    Companion_getInstance_69();
    QuickChartBuilder_init_$Init$(description, null, 2, null, this);
    this.configure_1(_no_name_provided_$factory_400());
  }
  QuickChartBuilderJS.prototype.build_1 = function () {
    var tmp = QuickChartBuilder.prototype.build_1.call(this);
    return tmp instanceof QuickChartJS ? tmp : THROW_CCE();
  };
  QuickChartBuilderJS.$metadata$ = {
    simpleName: 'QuickChartBuilderJS',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_400() {
    var i = new _no_name_provided__426();
    return function (p1) {
      i.invoke_816(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_401($this_configure) {
    var i = new _no_name_provided__425($this_configure);
    return function (p1) {
      i.invoke_779(p1);
      return Unit_getInstance();
    };
  }
  function QuickChartFactoryJS() {
    this._canvasFactory = new CanvasFactoryJS();
  }
  QuickChartFactoryJS.prototype._get_canvasFactory__0 = function () {
    return this._canvasFactory;
  };
  QuickChartFactoryJS.prototype.createQuickChart_0 = function (chartSupport, description) {
    return new QuickChartJS(chartSupport, description);
  };
  QuickChartFactoryJS.$metadata$ = {
    simpleName: 'QuickChartFactoryJS',
    kind: 'class',
    interfaces: [QuickChartFactory]
  };
  function _no_name_provided__427(this$0) {
    this._this$0_273 = this$0;
  }
  _no_name_provided__427.prototype.invoke_783 = function (it) {
    this._this$0_273._chartSupport_0.markAsDirty_4();
  };
  _no_name_provided__427.prototype.invoke_1082 = function (p1) {
    this.invoke_783(p1 instanceof ImageLoadedEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__427.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__428(this$0) {
    this._this$0_274 = this$0;
  }
  _no_name_provided__428.prototype.invoke_785 = function (it) {
    this._this$0_274._chartSupport_0.markAsDirty_4();
  };
  _no_name_provided__428.prototype.invoke_1082 = function (p1) {
    this.invoke_785(p1 instanceof FontLoadedEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__428.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__429(this$0) {
    this._this$0_275 = this$0;
  }
  _no_name_provided__429.prototype.invoke_791 = function (it) {
    this._this$0_275._get_htmlCanvas_().recalculateCanvasRenderingSize();
  };
  _no_name_provided__429.prototype.invoke_1082 = function (p1) {
    this.invoke_791((!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__429.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__430(this$0) {
    this._this$0_276 = this$0;
  }
  _no_name_provided__430.prototype.invoke_1037 = function () {
    QuickChartPlatformState_getInstance().instanceDisposed(this._this$0_276);
  };
  _no_name_provided__430.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__430.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__431(this$0) {
    this._this$0_277 = this$0;
  }
  _no_name_provided__431.prototype.invoke_791 = function (it) {
    scheduleRepaints(this._this$0_277._chartSupport_0, it);
  };
  _no_name_provided__431.prototype.invoke_1082 = function (p1) {
    this.invoke_791((!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__431.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function QuickChartJS(chartSupport, description) {
    this._chartSupport_0 = chartSupport;
    this._description_0 = description;
    var tmp = this;
    var tmp_0 = document.createElement('DIV');
    var tmp0_also_0 = tmp_0 instanceof HTMLDivElement ? tmp_0 : THROW_CCE();
    tmp0_also_0.appendChild(this._get_htmlCanvas_()._canvasElement);
    Unit_getInstance();
    tmp0_also_0.style.setProperty('width', '100%');
    tmp0_also_0.style.setProperty('height', '100%');
    tmp0_also_0.style.setProperty('position', 'relative');
    tmp0_also_0.style.setProperty('border', '0');
    tmp0_also_0.style.setProperty('padding', '0');
    tmp0_also_0.style.setProperty('margin', '0');
    tmp._holder = tmp0_also_0;
    var tmp_1 = this;
    var tmp0_also_0_0 = new NativeComponentsJS(this._chartSupport_0);
    this._holder.appendChild(tmp0_also_0_0._div);
    Unit_getInstance();
    tmp_1._nativeComponents = tmp0_also_0_0;
    var tmp_2 = ImageLoadedEventBroker_getInstance();
    var tmp0_also_0_1 = tmp_2.onLoaded_0(_no_name_provided_$factory_402(this));
    var tmp1_also = this._chartSupport_0;
    tmp1_also.onDispose_9(tmp0_also_0_1);
    Unit_getInstance();
    var tmp_3 = FontLoadedEventBroker_getInstance();
    var tmp2_also_0 = tmp_3.onLoaded(_no_name_provided_$factory_403(this));
    var tmp3_also = this._chartSupport_0;
    tmp3_also.onDispose_9(tmp2_also_0);
    Unit_getInstance();
    this._chartSupport_0.onDispose_9(this._get_htmlCanvas_());
    var tmp_4 = _get_devicePixelRatioSupport_(this._chartSupport_0)._devicePixelRatioProperty;
    tmp_4.consume$default_3(false, _no_name_provided_$factory_404(this), 1, null);
    Unit_getInstance();
    this._get_htmlCanvas_()._canvasElement.style.setProperty('width', '100%');
    this._get_htmlCanvas_()._canvasElement.style.setProperty('height', '100%');
    this._get_htmlCanvas_()._canvasElement.style.setProperty('box-sizing', 'border-box');
    QuickChartPlatformState_getInstance().newInstance(this);
    this.onDispose_10(_no_name_provided_$factory_405(this));
    var tmp_5 = window;
    tmp_5.requestAnimationFrame(_no_name_provided_$factory_406(this));
    Unit_getInstance();
  }
  QuickChartJS.prototype._get_chartSupport__3 = function () {
    return this._chartSupport_0;
  };
  QuickChartJS.prototype._get_htmlCanvas_ = function () {
    var tmp = this._chartSupport_0._canvas;
    return tmp instanceof CanvasJS ? tmp : THROW_CCE();
  };
  QuickChartJS.$metadata$ = {
    simpleName: 'QuickChartJS',
    kind: 'class',
    interfaces: [QuickChart]
  };
  function _no_name_provided_$factory_402(this$0) {
    var i = new _no_name_provided__427(this$0);
    return function (p1) {
      i.invoke_783(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_403(this$0) {
    var i = new _no_name_provided__428(this$0);
    return function (p1) {
      i.invoke_785(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_404(this$0) {
    var i = new _no_name_provided__429(this$0);
    return function (p1) {
      i.invoke_791(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_405(this$0) {
    var i = new _no_name_provided__430(this$0);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_406(this$0) {
    var i = new _no_name_provided__431(this$0);
    return function (p1) {
      i.invoke_791(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__432() {
  }
  _no_name_provided__432.prototype.invoke_793 = function (it) {
    FontLoadedEventBroker_getInstance().notifyLoaded_0();
  };
  _no_name_provided__432.prototype.invoke_1082 = function (p1) {
    this.invoke_793(p1 instanceof FontFaceSetLoadEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__432.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function QuickChartPlatform() {
    QuickChartPlatform_instance = this;
    var tmp0_get_0 = document;
    var tmp1_get_0 = 'fonts';
    var tmp0_safe_receiver = tmp0_get_0[tmp1_get_0];
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = tmp0_safe_receiver;
    }
    var tmp1_safe_receiver = tmp;
    var tmp_0;
    if (tmp1_safe_receiver == null) {
      tmp_0 = null;
    } else {
      listenForLoadingDone(tmp1_safe_receiver, _no_name_provided_$factory_407());
      tmp_0 = Unit_getInstance();
    }
    var tmp2_elvis_lhs = tmp_0;
    if (tmp2_elvis_lhs == null)
      println('WARNING: document.fonts is not supported by this browser -> fonts loaded from now on may not be rendered correctly');
    else {
      Unit_getInstance();
    }
  }
  QuickChartPlatform.prototype.init_17 = function (corporateDesign_0) {
    var tmp0_safe_receiver = corporateDesign_0;
    if (tmp0_safe_receiver == null)
      null;
    else {
      initCorporateDesign(tmp0_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    QuickChartFactoryAccess_getInstance()._factory = new QuickChartFactoryJS();
  };
  QuickChartPlatform.$metadata$ = {
    simpleName: 'QuickChartPlatform',
    kind: 'object',
    interfaces: []
  };
  var QuickChartPlatform_instance;
  function QuickChartPlatform_getInstance() {
    if (QuickChartPlatform_instance == null)
      new QuickChartPlatform();
    return QuickChartPlatform_instance;
  }
  function _no_name_provided_$factory_407() {
    var i = new _no_name_provided__432();
    return function (p1) {
      i.invoke_793(p1);
      return Unit_getInstance();
    };
  }
  function listenForLoadingDone(_this_, listener) {
    _this_.addEventListener('loadingdone', _no_name_provided_$factory_408(listener));
  }
  function _no_name_provided__433($listener) {
    this._$listener = $listener;
  }
  _no_name_provided__433.prototype.invoke_795 = function (event) {
    this._$listener(event);
  };
  _no_name_provided__433.prototype.invoke_1082 = function (p1) {
    this.invoke_795(p1 instanceof Event ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__433.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_408($listener) {
    var i = new _no_name_provided__433($listener);
    return function (p1) {
      i.invoke_795(p1);
      return Unit_getInstance();
    };
  }
  function LocalResourcePaintable_init_$Init$(relativePath, size, alignmentPoint, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      size = null;
    if (!(($mask0 & 4) === 0))
      alignmentPoint = Companion_getInstance_32()._origin_0;
    LocalResourcePaintable.call($this, relativePath, size, alignmentPoint);
    return $this;
  }
  function LocalResourcePaintable_init_$Create$(relativePath, size, alignmentPoint, $mask0, $marker) {
    return LocalResourcePaintable_init_$Init$(relativePath, size, alignmentPoint, $mask0, $marker, Object.create(LocalResourcePaintable.prototype));
  }
  function Companion_71() {
    Companion_instance_70 = this;
  }
  Companion_71.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_70;
  function Companion_getInstance_70() {
    if (Companion_instance_70 == null)
      new Companion_71();
    return Companion_instance_70;
  }
  function LocalResourcePaintable(relativePath, size, alignmentPoint) {
    Companion_getInstance_70();
    this._relativePath = relativePath;
    this._alignmentPoint_1 = alignmentPoint;
    this._delegate_6 = !(size == null) ? Companion_getInstance_48().fixedSize(this._relativePath, size, this._alignmentPoint_1) : Companion_getInstance_48().naturalSize_0(this._relativePath, this._alignmentPoint_1);
  }
  LocalResourcePaintable.prototype.boundingBox_4 = function (chartSupport) {
    return this._delegate_6.boundingBox_4(chartSupport);
  };
  LocalResourcePaintable.prototype.paint_13 = function (paintingContext, x, y) {
    this._delegate_6.paint_13(paintingContext, x, y);
  };
  LocalResourcePaintable.$metadata$ = {
    simpleName: 'LocalResourcePaintable',
    kind: 'class',
    interfaces: [Paintable]
  };
  function LizergyDesign() {
    LizergyDesign_instance = this;
    this._id_2 = 'Lizergy';
    this._headlineFontFamily_0 = new FontFamily('myriad-pro');
    this._defaultFontFamily_0 = new FontFamily('myriad-pro');
    this._primaryColor_0 = Companion_getInstance_29().web_0('#75B640');
    this._primaryColorDarker_0 = Companion_getInstance_29().web_0('#5C951F');
    this._primaryColorLighter_0 = Companion_getInstance_29().web_0('#D6E5BC');
    this._secondaryColor_0 = Companion_getInstance_29().web_0('#447618');
    this._defaultLineColor_0 = Companion_getInstance_29().web_0('#447618');
    this._h1_0 = new FontDescriptorFragment(this._headlineFontFamily_0, new FontSize(53.0), Companion_getInstance_59()._Normal, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h1Color_0 = this._primaryColorDarker_0;
    this._h2_0 = new FontDescriptorFragment(this._headlineFontFamily_0, new FontSize(44.0), Companion_getInstance_59()._SemiBold, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h2Color_0 = this._primaryColorDarker_0;
    this._h3_0 = new FontDescriptorFragment(this._headlineFontFamily_0, new FontSize(29.0), Companion_getInstance_59()._Normal, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h3Color_0 = this._primaryColor_0;
    this._h4_0 = new FontDescriptorFragment(this._headlineFontFamily_0, new FontSize(22.0), Companion_getInstance_59()._Normal, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h4Color_0 = this._primaryColorDarker_0;
    this._h5_0 = new FontDescriptorFragment(this._headlineFontFamily_0, new FontSize(15.0), Companion_getInstance_59()._SemiBold, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._h5Color_0 = this._primaryColorDarker_0;
    this._textFont_0 = new FontDescriptor(this._defaultFontFamily_0, new FontSize(15.0), Companion_getInstance_59()._Normal, FontStyle_Normal_getInstance(), FontVariant_Normal_getInstance());
    this._textColor_1 = this._primaryColorDarker_0;
    this._backgroundColorLight_0 = Companion_getInstance_29().web_0('#F5F9ED');
    this._backgroundColorDark_0 = Companion_getInstance_29().web_0('#56891D');
    this._chartColors_1 = Companion_getInstance_39().forListModulo(Palette_getInstance()._chartColors_0);
    this._primaryButtonBackgroundColors_0 = new DefaultButtonColorProvider(Companion_getInstance_29().rgb(59, 145, 129), Companion_getInstance_29().web_0('#9fd5d8'), Companion_getInstance_29().rgba(59, 145, 129, 0.7), Companion_getInstance_29().rgba(59, 145, 129, 0.7), Companion_getInstance_29().rgb(59, 145, 129));
    this._primaryButtonForegroundColors_0 = new SingleButtonColorProvider(Companion_getInstance_29()._white);
    this._secondaryButtonBackgroundColors_0 = new DefaultButtonColorProvider(Companion_getInstance_29()._gray, Companion_getInstance_29().rgb(38, 79, 16).darker(0.2), Companion_getInstance_29().rgb(38, 79, 16), Companion_getInstance_29().rgb(38, 79, 16), Companion_getInstance_29()._white);
    this._secondaryButtonForegroundColors_0 = new SingleButtonColorProvider(Companion_getInstance_29().web_0('#264F10'));
  }
  LizergyDesign.prototype._get_textFont__1 = function () {
    return this._textFont_0;
  };
  LizergyDesign.$metadata$ = {
    simpleName: 'LizergyDesign',
    kind: 'object',
    interfaces: [CorporateDesign]
  };
  var LizergyDesign_instance;
  function LizergyDesign_getInstance() {
    if (LizergyDesign_instance == null)
      new LizergyDesign();
    return LizergyDesign_instance;
  }
  function Module_0(size, layout) {
    this._size_7 = size;
    this._layout = layout;
    this._location$delegate = location$factory(this._layout);
  }
  Module_0.prototype.isVertical = function () {
    return this._layout._get_orientation__2().equals(ModuleOrientation_Vertical_getInstance());
  };
  Module_0.prototype._get_location__2 = function () {
    var tmp0_getValue_0 = this._location$delegate;
    var tmp1_getValue_0 = location$factory_0();
    return tmp0_getValue_0.get();
  };
  Module_0.prototype._get_width__41 = function () {
    var tmp0_subject = this._layout._get_orientation__2();
    var tmp;
    if (tmp0_subject.equals(ModuleOrientation_Vertical_getInstance())) {
      tmp = this._size_7._shorter;
    } else if (tmp0_subject.equals(ModuleOrientation_Horizontal_getInstance())) {
      tmp = this._size_7._longer;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  };
  Module_0.prototype._get_height__41 = function () {
    var tmp0_subject = this._layout._get_orientation__2();
    var tmp;
    if (tmp0_subject.equals(ModuleOrientation_Vertical_getInstance())) {
      tmp = this._size_7._longer;
    } else if (tmp0_subject.equals(ModuleOrientation_Horizontal_getInstance())) {
      tmp = this._size_7._shorter;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  };
  Module_0.$metadata$ = {
    simpleName: 'Module',
    kind: 'class',
    interfaces: []
  };
  function location$factory($b0) {
    return getPropertyCallableRef('location', 0, KProperty0, function () {
      return $b0._get_location__2();
    }, null);
  }
  function location$factory_0() {
    return getPropertyCallableRef('location', 1, KProperty1, function (receiver) {
      return receiver._get_location__2();
    }, null);
  }
  function ModuleGrid_init_$Init$(modulesSize, orientation, gap, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0)) {
      orientation = _no_name_provided_$factory_409();
    }if (!(($mask0 & 4) === 0))
      gap = 20.0;
    ModuleGrid.call($this, modulesSize, orientation, gap);
    return $this;
  }
  function ModuleGrid_init_$Create$(modulesSize, orientation, gap, $mask0, $marker) {
    return ModuleGrid_init_$Init$(modulesSize, orientation, gap, $mask0, $marker, Object.create(ModuleGrid.prototype));
  }
  function _no_name_provided__434() {
  }
  _no_name_provided__434.prototype.invoke_1038 = function () {
    return ModuleOrientation_Vertical_getInstance();
  };
  _no_name_provided__434.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ModuleGrid(modulesSize, orientation, gap) {
    this._modulesSize = modulesSize;
    this._orientation = orientation;
    this._gap = gap;
    this._offset_1 = Companion_getInstance_32()._origin_0;
  }
  ModuleGrid.prototype.calculateModuleOrigin = function (moduleIndex) {
    var originX = this._offset_1._x + imul(moduleIndex._x_1, this.moduleWidth()) + moduleIndex._x_1 * this._gap;
    var originY = this._offset_1._y + imul(moduleIndex._y_1, this.moduleHeight()) + moduleIndex._y_1 * this._gap;
    return new Coordinates(originX, originY);
  };
  ModuleGrid.prototype.maxModulesCountHorizontal = function (netWidth) {
    var netWidthWithCorrection = netWidth + this._gap;
    return toIntFloor(netWidthWithCorrection / (this.moduleWidth() + this._gap));
  };
  ModuleGrid.prototype.maxModulesCountVertical = function (netHeight) {
    var netHeightWithCorrection = netHeight + this._gap;
    return toIntFloor(netHeightWithCorrection / (this.moduleHeight() + this._gap));
  };
  ModuleGrid.prototype.moduleWidth = function () {
    return this._modulesSize().width_1(this._orientation());
  };
  ModuleGrid.prototype.moduleHeight = function () {
    return this._modulesSize().height_1(this._orientation());
  };
  ModuleGrid.$metadata$ = {
    simpleName: 'ModuleGrid',
    kind: 'class',
    interfaces: []
  };
  function Companion_72() {
    Companion_instance_71 = this;
    this._origin_1 = new ModuleIndex(0, 0);
  }
  Companion_72.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_71;
  function Companion_getInstance_71() {
    if (Companion_instance_71 == null)
      new Companion_72();
    return Companion_instance_71;
  }
  function ModuleIndex(x, y) {
    Companion_getInstance_71();
    this._x_1 = x;
    this._y_1 = y;
    var tmp0_require_0 = this._x_1 >= 0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'x must be >= 0 but was <' + this._x_1 + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp1_require_0 = this._y_1 >= 0;
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'y must be >= 0 but was <' + this._y_1 + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
    }}
  ModuleIndex.prototype.toString = function () {
    return '' + 'ModuleIndex(x=' + this._x_1 + ', y=' + this._y_1 + ')';
  };
  ModuleIndex.prototype.hashCode = function () {
    var result = this._x_1;
    result = imul(result, 31) + this._y_1 | 0;
    return result;
  };
  ModuleIndex.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ModuleIndex))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof ModuleIndex ? other : THROW_CCE();
    if (!(this._x_1 === tmp0_other_with_cast._x_1))
      return false;
    if (!(this._y_1 === tmp0_other_with_cast._y_1))
      return false;
    return true;
  };
  ModuleIndex.$metadata$ = {
    simpleName: 'ModuleIndex',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_409() {
    var i = new _no_name_provided__434();
    return function () {
      return i.invoke_1038();
    };
  }
  function AbsoluteLayout() {
    ModuleLayout.call(this);
    this._location_0 = Companion_getInstance_32()._origin_0;
    this._orientation_0 = ModuleOrientation_Vertical_getInstance();
  }
  AbsoluteLayout.prototype._get_location__2 = function () {
    return this._location_0;
  };
  AbsoluteLayout.prototype._get_orientation__2 = function () {
    return this._orientation_0;
  };
  AbsoluteLayout.$metadata$ = {
    simpleName: 'AbsoluteLayout',
    kind: 'class',
    interfaces: []
  };
  function ModuleLayout() {
  }
  ModuleLayout.$metadata$ = {
    simpleName: 'ModuleLayout',
    kind: 'class',
    interfaces: []
  };
  function GridLayout(moduleIndex, grid) {
    ModuleLayout.call(this);
    this._moduleIndex = moduleIndex;
    this._grid = grid;
  }
  GridLayout.prototype._get_location__2 = function () {
    return this._grid.calculateModuleOrigin(this._moduleIndex);
  };
  GridLayout.prototype._get_orientation__2 = function () {
    return this._grid._orientation();
  };
  GridLayout.$metadata$ = {
    simpleName: 'GridLayout',
    kind: 'class',
    interfaces: []
  };
  var ModuleOrientation_Vertical_instance;
  var ModuleOrientation_Horizontal_instance;
  function valueOf(value) {
    switch (value) {
      case 'Vertical':
        return ModuleOrientation_Vertical_getInstance();
      case 'Horizontal':
        return ModuleOrientation_Horizontal_getInstance();
      default:return THROW_ISE();
    }
  }
  var ModuleOrientation_entriesInitialized;
  function ModuleOrientation_initEntries() {
    if (ModuleOrientation_entriesInitialized)
      return Unit_getInstance();
    ModuleOrientation_entriesInitialized = true;
    ModuleOrientation_Vertical_instance = new ModuleOrientation('Vertical', 0);
    ModuleOrientation_Horizontal_instance = new ModuleOrientation('Horizontal', 1);
  }
  function ModuleOrientation(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  ModuleOrientation.$metadata$ = {
    simpleName: 'ModuleOrientation',
    kind: 'class',
    interfaces: []
  };
  function ModuleOrientation_Vertical_getInstance() {
    ModuleOrientation_initEntries();
    return ModuleOrientation_Vertical_instance;
  }
  function ModuleOrientation_Horizontal_getInstance() {
    ModuleOrientation_initEntries();
    return ModuleOrientation_Horizontal_instance;
  }
  function ModuleSize(longer, shorter) {
    this._longer = longer;
    this._shorter = shorter;
    var tmp0_require_0 = this._longer >= this._shorter;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Longer (' + this._longer + ') must >= shorter (' + this._shorter + ')';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp1_require_0 = this._longer > 0.0;
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'Longer <' + this._longer + '> is too small';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_0));
    }var tmp2_require_0 = this._shorter > 0.0;
    if (!tmp2_require_0) {
      var message_1_1 = '' + 'Longer <' + this._shorter + '> is too small';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1_1));
    }}
  ModuleSize.prototype.width_1 = function (orientation) {
    var tmp0_subject = orientation;
    var tmp;
    if (tmp0_subject.equals(ModuleOrientation_Vertical_getInstance())) {
      tmp = this._shorter;
    } else if (tmp0_subject.equals(ModuleOrientation_Horizontal_getInstance())) {
      tmp = this._longer;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  };
  ModuleSize.prototype.height_1 = function (orientation) {
    var tmp0_subject = orientation;
    var tmp;
    if (tmp0_subject.equals(ModuleOrientation_Vertical_getInstance())) {
      tmp = this._longer;
    } else if (tmp0_subject.equals(ModuleOrientation_Horizontal_getInstance())) {
      tmp = this._shorter;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  };
  ModuleSize.prototype.toString = function () {
    var tmp = this._longer;
    var tmp_0 = intFormat.format$default_0(tmp, null, 2, null);
    var tmp_1 = this._shorter;
    return '' + tmp_0 + '/' + intFormat.format$default_0(tmp_1, null, 2, null);
  };
  ModuleSize.prototype.hashCode = function () {
    var result = this._longer;
    result = imul(result, 31) + this._shorter | 0;
    return result;
  };
  ModuleSize.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ModuleSize))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof ModuleSize ? other : THROW_CCE();
    if (!(this._longer === tmp0_other_with_cast._longer))
      return false;
    if (!(this._shorter === tmp0_other_with_cast._shorter))
      return false;
    return true;
  };
  ModuleSize.$metadata$ = {
    simpleName: 'ModuleSize',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__435() {
  }
  _no_name_provided__435.prototype.invoke_798 = function (a, b) {
    var layoutA = a._layout;
    var layoutB = b._layout;
    var aHasGridLayout = layoutA instanceof GridLayout;
    var bHasGridLayout = layoutB instanceof GridLayout;
    return compareTo(bHasGridLayout, aHasGridLayout);
  };
  _no_name_provided__435.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Module_0 ? p1 : THROW_CCE();
    return this.invoke_798(tmp, p2 instanceof Module_0 ? p2 : THROW_CCE());
  };
  _no_name_provided__435.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Companion_73() {
    Companion_instance_72 = this;
    var tmp = this;
    var tmp_0 = _no_name_provided_$factory_415();
    tmp._byLayoutComparator = new sam$kotlin_Comparator$0(tmp_0);
  }
  Companion_73.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_72;
  function Companion_getInstance_72() {
    if (Companion_instance_72 == null)
      new Companion_73();
    return Companion_instance_72;
  }
  function sam$kotlin_Comparator$0(function_0) {
    this._function_2 = function_0;
  }
  sam$kotlin_Comparator$0.prototype.compare_0 = function (a, b) {
    return this._function_2(a, b);
  };
  sam$kotlin_Comparator$0.prototype.compare = function (a, b) {
    return this.compare_0(a, b);
  };
  sam$kotlin_Comparator$0.$metadata$ = {
    simpleName: 'sam$kotlin_Comparator$0',
    kind: 'class',
    interfaces: [Comparator]
  };
  function _no_name_provided__436() {
  }
  _no_name_provided__436.prototype.invoke_802 = function (it) {
    var tmp0_filterTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = it.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      var tmp = element_2_3._layout;
      if (tmp instanceof AbsoluteLayout) {
        tmp0_filterTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    return tmp0_filterTo_0_1;
  };
  _no_name_provided__436.prototype.invoke_1082 = function (p1) {
    return this.invoke_802((!(p1 == null) ? isInterface(p1, List) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__436.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__437() {
  }
  _no_name_provided__437.prototype.invoke_802 = function (it) {
    var tmp0_filterTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = it.iterator_38();
    while (tmp0_iterator_1_2.hasNext_11()) {
      var element_2_3 = tmp0_iterator_1_2.next_13();
      var tmp = element_2_3._layout;
      if (tmp instanceof GridLayout) {
        tmp0_filterTo_0_1.add_57(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    return tmp0_filterTo_0_1;
  };
  _no_name_provided__437.prototype.invoke_1082 = function (p1) {
    return this.invoke_802((!(p1 == null) ? isInterface(p1, List) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__437.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__438() {
  }
  _no_name_provided__438.prototype.invoke_808 = function (it) {
    var tmp = it._layout;
    return tmp instanceof GridLayout;
  };
  _no_name_provided__438.prototype.invoke_1082 = function (p1) {
    return this.invoke_808(p1 instanceof Module_0 ? p1 : THROW_CCE());
  };
  _no_name_provided__438.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__439($toRemove) {
    this._$toRemove = $toRemove;
  }
  _no_name_provided__439.prototype.invoke_808 = function (it) {
    return this._$toRemove.contains_31(it);
  };
  _no_name_provided__439.prototype.invoke_1082 = function (p1) {
    return this.invoke_808(p1 instanceof Module_0 ? p1 : THROW_CCE());
  };
  _no_name_provided__439.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__440($moduleIndex) {
    this._$moduleIndex = $moduleIndex;
  }
  _no_name_provided__440.prototype.invoke_808 = function (module_0) {
    var tmp = module_0._layout;
    var gridLayout = tmp instanceof GridLayout ? tmp : null;
    var tmp0_safe_receiver = gridLayout;
    return equals_0(tmp0_safe_receiver == null ? null : tmp0_safe_receiver._moduleIndex, this._$moduleIndex);
  };
  _no_name_provided__440.prototype.invoke_1082 = function (p1) {
    return this.invoke_808(p1 instanceof Module_0 ? p1 : THROW_CCE());
  };
  _no_name_provided__440.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Modules() {
    Companion_getInstance_72();
    var tmp = this;
    tmp._modulesProperty = new ObservableObject(emptyList());
    var tmp_0 = this;
    tmp_0._modulesWithAbsoluteLayoutProperty = this._modulesProperty.map_4(_no_name_provided_$factory_410());
    this._modulesWithAbsoluteLayout$delegate = this._modulesWithAbsoluteLayoutProperty;
    var tmp_1 = this;
    tmp_1._modulesWithGridLayoutProperty = this._modulesProperty.map_4(_no_name_provided_$factory_411());
    this._modulesWithGridLayout$delegate = this._modulesWithGridLayoutProperty;
    this._modules$delegate = this._modulesProperty;
  }
  Modules.prototype._get_modules_ = function () {
    return this._modules$delegate.getValue_10(this, modules$factory());
  };
  Modules.prototype._get_count__0 = function () {
    return this._get_modules_()._get_size__43();
  };
  Modules.prototype.removeModulesWithGridLayout = function () {
    this.removeAll_29(_no_name_provided_$factory_412());
  };
  Modules.prototype.add_60 = function (module_0) {
    var tmp0_getAndSet_0 = this._modulesProperty;
    var tmp1__anonymous__1 = tmp0_getAndSet_0._get_value__18();
    tmp0_getAndSet_0._set_value__12(sortedWith(plus_0(tmp1__anonymous__1, module_0), Companion_getInstance_72()._byLayoutComparator));
  };
  Modules.prototype.removeAll_28 = function (toRemove) {
    this.removeAll_29(_no_name_provided_$factory_413(toRemove));
  };
  Modules.prototype.removeAll_29 = function (predicate) {
    var tmp0_getAndSet_0 = this._modulesProperty;
    var tmp1__anonymous__1 = tmp0_getAndSet_0._get_value__18();
    var tmp0_apply_0_2 = toMutableList(tmp1__anonymous__1);
    removeAll(tmp0_apply_0_2, predicate);
    Unit_getInstance();
    tmp0_getAndSet_0._set_value__12(tmp0_apply_0_2);
  };
  Modules.prototype.remove_60 = function (module_0) {
    var tmp0_getAndSet_0 = this._modulesProperty;
    var tmp1__anonymous__1 = tmp0_getAndSet_0._get_value__18();
    tmp0_getAndSet_0._set_value__12(minus(tmp1__anonymous__1, module_0));
  };
  Modules.prototype.remove_61 = function (moduleIndex) {
    this.removeAll_29(_no_name_provided_$factory_414(moduleIndex));
  };
  Modules.$metadata$ = {
    simpleName: 'Modules',
    kind: 'class',
    interfaces: []
  };
  function modules$factory() {
    return getPropertyCallableRef('modules', 1, KProperty1, function (receiver) {
      return receiver._get_modules_();
    }, null);
  }
  function _no_name_provided_$factory_410() {
    var i = new _no_name_provided__436();
    return function (p1) {
      return i.invoke_802(p1);
    };
  }
  function _no_name_provided_$factory_411() {
    var i = new _no_name_provided__437();
    return function (p1) {
      return i.invoke_802(p1);
    };
  }
  function _no_name_provided_$factory_412() {
    var i = new _no_name_provided__438();
    return function (p1) {
      return i.invoke_808(p1);
    };
  }
  function _no_name_provided_$factory_413($toRemove) {
    var i = new _no_name_provided__439($toRemove);
    return function (p1) {
      return i.invoke_808(p1);
    };
  }
  function _no_name_provided_$factory_414($moduleIndex) {
    var i = new _no_name_provided__440($moduleIndex);
    return function (p1) {
      return i.invoke_808(p1);
    };
  }
  function _no_name_provided_$factory_415() {
    var i = new _no_name_provided__435();
    return function (p1, p2) {
      return i.invoke_798(p1, p2);
    };
  }
  function ModulesSelection() {
    var tmp = this;
    tmp.__selectedModules = LinkedHashSet_init_$Create$();
  }
  ModulesSelection.prototype._get_selectedModules_ = function () {
    return this.__selectedModules;
  };
  ModulesSelection.prototype.toggle_1 = function (moduleToToggle) {
    if (this.__selectedModules.contains_31(moduleToToggle)) {
      this.__selectedModules.remove_59(moduleToToggle);
      Unit_getInstance();
    } else {
      this.__selectedModules.add_57(moduleToToggle);
      Unit_getInstance();
    }
  };
  ModulesSelection.prototype.contains_32 = function (module_0) {
    return this.__selectedModules.contains_31(module_0);
  };
  ModulesSelection.$metadata$ = {
    simpleName: 'ModulesSelection',
    kind: 'class',
    interfaces: []
  };
  function MultiSizePaintable(paintables) {
    this._paintables = paintables;
    var tmp0_isNotEmpty_0 = this._paintables;
    var tmp1_require_0 = !tmp0_isNotEmpty_0.isEmpty_33();
    if (!tmp1_require_0) {
      var message_1 = 'Need at least one paintable';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }}
  MultiSizePaintable.prototype.sameOrLarger = function (width, height, chartSupport) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_lastOrNull_0 = this._paintables;
      var iterator_1 = tmp0_lastOrNull_0.listIterator_3(tmp0_lastOrNull_0._get_size__43());
      while (iterator_1.hasPrevious_1()) {
        var element_2 = iterator_1.previous_1();
        if (element_2.boundingBox_4(chartSupport)._size_2.bothLargerThanOrEqual(width, height)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    var tmp0_elvis_lhs = tmp$ret$0;
    return tmp0_elvis_lhs == null ? first_0(this._paintables) : tmp0_elvis_lhs;
  };
  MultiSizePaintable.$metadata$ = {
    simpleName: 'MultiSizePaintable',
    kind: 'class',
    interfaces: []
  };
  function Roof_init_$Init$(width, height, $this) {
    Roof.call($this, Size_init_$Create$(width, height));
    return $this;
  }
  function Roof_init_$Create$(width, height) {
    return Roof_init_$Init$(width, height, Object.create(Roof.prototype));
  }
  function Roof(initialSize) {
    this._sizeProperty_0 = new ObservableObject(initialSize);
    this._size$delegate_6 = this._sizeProperty_0;
    this._inclinationProperty = new ObservableDouble(40.0);
    this._inclination$delegate = this._inclinationProperty;
    this._orientationProperty = new ObservableDouble(0.0);
    this._orientation$delegate = this._orientationProperty;
  }
  Roof.prototype._set_size__7 = function (_set___) {
    return this._size$delegate_6.setValue_10(this, size$factory_18(), _set___);
  };
  Roof.prototype._get_size__43 = function () {
    return this._size$delegate_6.getValue_10(this, size$factory_19());
  };
  Roof.prototype._set_inclination_ = function (_set___) {
    return this._inclination$delegate.setValue_10(this, inclination$factory(), _set___);
  };
  Roof.prototype._get_inclination_ = function () {
    return this._inclination$delegate.getValue_10(this, inclination$factory_0());
  };
  Roof.prototype._set_orientation_ = function (_set___) {
    return this._orientation$delegate.setValue_10(this, orientation$factory(), _set___);
  };
  Roof.prototype._get_orientation__2 = function () {
    return this._orientation$delegate.getValue_10(this, orientation$factory_0());
  };
  Roof.$metadata$ = {
    simpleName: 'Roof',
    kind: 'class',
    interfaces: []
  };
  function size$factory_18() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__7(value);
    });
  }
  function size$factory_19() {
    return getPropertyCallableRef('size', 1, KMutableProperty1, function (receiver) {
      return receiver._get_size__43();
    }, function (receiver_0, value) {
      return receiver_0._set_size__7(value);
    });
  }
  function inclination$factory() {
    return getPropertyCallableRef('inclination', 1, KMutableProperty1, function (receiver) {
      return receiver._get_inclination_();
    }, function (receiver_0, value) {
      return receiver_0._set_inclination_(value);
    });
  }
  function inclination$factory_0() {
    return getPropertyCallableRef('inclination', 1, KMutableProperty1, function (receiver) {
      return receiver._get_inclination_();
    }, function (receiver_0, value) {
      return receiver_0._set_inclination_(value);
    });
  }
  function orientation$factory() {
    return getPropertyCallableRef('orientation', 1, KMutableProperty1, function (receiver) {
      return receiver._get_orientation__2();
    }, function (receiver_0, value) {
      return receiver_0._set_orientation_(value);
    });
  }
  function orientation$factory_0() {
    return getPropertyCallableRef('orientation', 1, KMutableProperty1, function (receiver) {
      return receiver._get_orientation__2();
    }, function (receiver_0, value) {
      return receiver_0._set_orientation_(value);
    });
  }
  function Data_init_$Init$_0(model, modulesSelection, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      model = SolarModuleSampleModels_getInstance().realistic();
    if (!(($mask0 & 2) === 0))
      modulesSelection = new ModulesSelection();
    Data_2.call($this, model, modulesSelection);
    return $this;
  }
  function Data_init_$Create$_0(model, modulesSelection, $mask0, $marker) {
    return Data_init_$Init$_0(model, modulesSelection, $mask0, $marker, Object.create(Data_2.prototype));
  }
  function _no_name_provided__441() {
  }
  _no_name_provided__441.prototype.invoke_1038 = function () {
    return Companion_getInstance_35().of_1(20.0);
  };
  _no_name_provided__441.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function RoofPlanningGestalt_init_$Init$(data, styleConfiguration, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0)) {
      data = Data_init_$Create$_0(null, null, 3, null);
    }if (!(($mask0 & 2) === 0)) {
      styleConfiguration = _no_name_provided_$factory_416();
    }RoofPlanningGestalt.call($this, data, styleConfiguration);
    return $this;
  }
  function RoofPlanningGestalt_init_$Create$(data, styleConfiguration, $mask0, $marker) {
    return RoofPlanningGestalt_init_$Init$(data, styleConfiguration, $mask0, $marker, Object.create(RoofPlanningGestalt.prototype));
  }
  function Data_2(model, modulesSelection) {
    this._model = model;
    this._modulesSelection = modulesSelection;
  }
  Data_2.$metadata$ = {
    simpleName: 'Data',
    kind: 'class',
    interfaces: []
  };
  function Style_2() {
  }
  Style_2.$metadata$ = {
    simpleName: 'Style',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__442() {
  }
  _no_name_provided__442.prototype.invoke_818 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__442.prototype.invoke_1082 = function (p1) {
    this.invoke_818(p1 instanceof Style_2 ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__442.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__443() {
  }
  _no_name_provided__443.prototype.invoke_813 = function ($this$zoomAndTranslationConfiguration) {
    $this$zoomAndTranslationConfiguration._mouseWheelZoomConfiguration = Companion_getInstance_47()._bothAxis;
  };
  _no_name_provided__443.prototype.invoke_1082 = function (p1) {
    this.invoke_813(p1 instanceof ZoomAndTranslationConfiguration ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__443.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__444() {
  }
  _no_name_provided__444.prototype.invoke_1038 = function () {
    return new FittingWithMarginAspectRatio(_no_name_provided_$factory_420());
  };
  _no_name_provided__444.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__445(this$0) {
    this._this$0_278 = this$0;
  }
  _no_name_provided__445.prototype.invoke_816 = function ($this$configure) {
    $this$configure._get_chartSupport__3()._rootChartState._set_axisOrientationY__1(AxisOrientationY_OriginAtBottom_getInstance());
    $this$configure._get_chartSupport__3()._rootChartState._set_axisOrientationX__1(AxisOrientationX_OriginAtLeft_getInstance());
    addClearBackground($this$configure._get_layers__1());
    Unit_getInstance();
    $this$configure._get_layers__1().addLayer(this._this$0_278._roofPlanningLayer);
    Unit_getInstance();
  };
  _no_name_provided__445.prototype.invoke_1082 = function (p1) {
    this.invoke_816((!(p1 == null) ? isInterface(p1, LayerSupport) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__445.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function RoofPlanningGestalt(data, styleConfiguration) {
    this._data_3 = data;
    var tmp = this;
    var tmp0_also_0 = new Style_2();
    styleConfiguration(tmp0_also_0);
    tmp._style_5 = tmp0_also_0;
    var tmp_0 = this;
    var tmp_1 = new Data_3(this._data_3._model, this._data_3._modulesSelection);
    tmp_0._roofPlanningLayer = RoofPlanningLayer_init_$Create$(tmp_1, null, 2, null);
  }
  RoofPlanningGestalt.prototype.configure_2 = function (quickChartBuilder) {
    quickChartBuilder.zoomAndTranslationConfiguration_0(_no_name_provided_$factory_417());
    quickChartBuilder._contentAreaSizingStrategy = new BindContentArea2Property(this._data_3._model._roof._sizeProperty_0);
    quickChartBuilder.zoomAndTranslationDefaults_1(_no_name_provided_$factory_418());
    quickChartBuilder.configure_1(_no_name_provided_$factory_419(this));
  };
  RoofPlanningGestalt.$metadata$ = {
    simpleName: 'RoofPlanningGestalt',
    kind: 'class',
    interfaces: [ChartGestalt]
  };
  function _no_name_provided_$factory_416() {
    var i = new _no_name_provided__442();
    return function (p1) {
      i.invoke_818(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_417() {
    var i = new _no_name_provided__443();
    return function (p1) {
      i.invoke_813(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_418() {
    var i = new _no_name_provided__444();
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_419(this$0) {
    var i = new _no_name_provided__445(this$0);
    return function (p1) {
      i.invoke_816(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_420() {
    var i = new _no_name_provided__441();
    return function () {
      return i.invoke_1038();
    };
  }
  function setHighLightedModuleIndex($this, newHighlightedModuleIndex, dirtySupport) {
    if ($this._this$0_279._moduleUnderMouseIndex === newHighlightedModuleIndex) {
      return Unit_getInstance();
    }$this._this$0_279._moduleUnderMouseIndex = newHighlightedModuleIndex;
    dirtySupport.markAsDirty_4();
  }
  function RoofPlanningLayer_init_$Init$(data, styleConfiguration, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0)) {
      styleConfiguration = _no_name_provided_$factory_421();
    }RoofPlanningLayer.call($this, data, styleConfiguration);
    return $this;
  }
  function RoofPlanningLayer_init_$Create$(data, styleConfiguration, $mask0, $marker) {
    return RoofPlanningLayer_init_$Init$(data, styleConfiguration, $mask0, $marker, Object.create(RoofPlanningLayer.prototype));
  }
  function Layout($outer) {
    this._$this_9 = $outer;
    this._roofOriginWindowX = 0.0;
    this._roofOriginWindowY = 0.0;
    this._roofWidthZoomed = 0.0;
    this._roofHeightZoomed = 0.0;
    this._roofTilesCountHorizontal = 0;
    this._roofTilesCountVertical = 0;
    this._roofTileWidthZoomed = 0.0;
    this._roofTileHeightZoomed = 0.0;
    this._moduleBounds = new BoundsLayoutCache();
    this._unusableAreaBounds = new BoundsLayoutCache();
  }
  Layout.prototype.calculateLayout = function (paintingContext) {
    var gc = paintingContext._gc;
    var chartCalculator = paintingContext._get_chartCalculator__0();
    var roof = this._$this_9._data_4._roofPlanningModel._roof;
    var valueRangeX = Companion_getInstance_27().linear_0(0.0, roof._get_size__43()._width_0);
    var valueRangeY = Companion_getInstance_27().linear_0(0.0, roof._get_size__43()._height);
    var roofSize = roof._get_size__43();
    this._roofOriginWindowX = chartCalculator.domainRelative2windowX(0.0);
    this._roofOriginWindowY = chartCalculator.domainRelative2windowY(0.0);
    this._roofWidthZoomed = chartCalculator.domainDelta2zoomedX(roofSize._width_0, valueRangeX);
    this._roofHeightZoomed = chartCalculator.domainDelta2zoomedY(roofSize._height, valueRangeY);
    this._roofTilesCountHorizontal = roundToInt(roof._get_size__43()._width_0 / this._$this_9._style_6._roofTileSize._width_0);
    this._roofTilesCountVertical = roundToInt(roof._get_size__43()._height / this._$this_9._style_6._roofTileSize._height);
    this._roofTileWidthZoomed = this._roofWidthZoomed / this._roofTilesCountHorizontal;
    this._roofTileHeightZoomed = this._roofHeightZoomed / this._roofTilesCountVertical;
    this._moduleBounds.ensureSize_0(this._$this_9._data_4._roofPlanningModel._modules._get_count__0());
    var tmp0_fastForEachIndexed_0 = this._$this_9._data_4._roofPlanningModel._modules._get_modules_();
    var n_1 = 0;
    var currentSize_2 = tmp0_fastForEachIndexed_0._get_size__43();
    while (n_1 < currentSize_2) {
      var tmp1__anonymous__4 = n_1;
      var tmp2__anonymous__4 = tmp0_fastForEachIndexed_0.get_44(n_1);
      var moduleLocation_5 = tmp2__anonymous__4._get_location__2();
      this._moduleBounds.x_0(tmp1__anonymous__4, chartCalculator.domain2windowX(moduleLocation_5._x, valueRangeX));
      this._moduleBounds.y_0(tmp1__anonymous__4, chartCalculator.domain2windowY(moduleLocation_5._y, valueRangeY));
      this._moduleBounds.width_0(tmp1__anonymous__4, chartCalculator.domainDelta2zoomedX(tmp2__anonymous__4._get_width__41(), valueRangeX));
      this._moduleBounds.height_0(tmp1__anonymous__4, chartCalculator.domainDelta2zoomedY(tmp2__anonymous__4._get_height__41(), valueRangeY));
      var tmp0_3 = n_1;
      n_1 = tmp0_3 + 1 | 0;
      Unit_getInstance();
    }
    this._unusableAreaBounds.ensureSize_0(this._$this_9._data_4._roofPlanningModel._unusableAreas._get_count__0());
    var tmp3_fastForEachIndexed_0 = this._$this_9._data_4._roofPlanningModel._unusableAreas._get_unusableAreas_();
    var n_1_0 = 0;
    var currentSize_2_0 = tmp3_fastForEachIndexed_0._get_size__43();
    while (n_1_0 < currentSize_2_0) {
      var tmp4__anonymous__4 = n_1_0;
      var tmp5__anonymous__4 = tmp3_fastForEachIndexed_0.get_44(n_1_0);
      var unusableAreaLocation_5 = tmp5__anonymous__4._location_1;
      this._unusableAreaBounds.x_0(tmp4__anonymous__4, chartCalculator.domain2windowX(unusableAreaLocation_5._x, valueRangeX));
      this._unusableAreaBounds.y_0(tmp4__anonymous__4, chartCalculator.domain2windowY(unusableAreaLocation_5._y, valueRangeY));
      this._unusableAreaBounds.width_0(tmp4__anonymous__4, chartCalculator.domainDelta2zoomedX(tmp5__anonymous__4._size_8._width_0, valueRangeX));
      this._unusableAreaBounds.height_0(tmp4__anonymous__4, chartCalculator.domainDelta2zoomedY(tmp5__anonymous__4._size_8._height, valueRangeY));
      var tmp0_3_0 = n_1_0;
      n_1_0 = tmp0_3_0 + 1 | 0;
      Unit_getInstance();
    }
  };
  Layout.prototype.roofTileLocationX = function (xIndex) {
    return this._$this_9._layout_0._roofOriginWindowX + xIndex * this._$this_9._layout_0._roofTileWidthZoomed;
  };
  Layout.prototype.roofTileLocationY = function (yIndex) {
    return this._roofOriginWindowY + yIndex * this._roofTileHeightZoomed;
  };
  Layout.$metadata$ = {
    simpleName: 'Layout',
    kind: 'class',
    interfaces: []
  };
  function Data_3(roofPlanningModel, modulesSelection) {
    this._roofPlanningModel = roofPlanningModel;
    this._modulesSelection_0 = modulesSelection;
  }
  Data_3.$metadata$ = {
    simpleName: 'Data',
    kind: 'class',
    interfaces: []
  };
  function Style_3() {
    this._mouseOverModuleOverlayColor = Companion_getInstance_29().web_0('#639C2122');
    this._mouseOverModuleOverlayBorderColor = Companion_getInstance_29().web_0('#639C21');
    this._selectedModulesOverlayColor = Companion_getInstance_29().web_0('#639C2144');
    this._selectedModulesOverlayBorderColor = Companion_getInstance_29().web_0('#639C21');
    var tmp = this;
    var tmp_0 = new Size(1334.0, 1860.0);
    var tmp_1 = LocalResourcePaintable_init_$Create$('solar/panel-vertical.png', tmp_0, null, 4, null);
    var tmp_2 = new Size(930.0, 567.0);
    var tmp_3 = LocalResourcePaintable_init_$Create$('solar/panel-vertical_50.png', tmp_2, null, 4, null);
    var tmp_4 = new Size(465.0, 284.0);
    var tmp_5 = LocalResourcePaintable_init_$Create$('solar/panel-vertical_25.png', tmp_4, null, 4, null);
    var tmp_6 = new Size(186.0, 113.0);
    var tmp_7 = LocalResourcePaintable_init_$Create$('solar/panel-vertical_10.png', tmp_6, null, 4, null);
    var tmp_8 = new Size(93.0, 57.0);
    tmp._panelPaintableVertical = new MultiSizePaintable(listOf([tmp_1, tmp_3, tmp_5, tmp_7, LocalResourcePaintable_init_$Create$('solar/panel-vertical_5.png', tmp_8, null, 4, null)]));
    var tmp_9 = this;
    var tmp_10 = new Size(1334.0, 1860.0);
    var tmp_11 = LocalResourcePaintable_init_$Create$('solar/panel-horizontal.png', tmp_10, null, 4, null);
    var tmp_12 = new Size(930.0, 567.0);
    var tmp_13 = LocalResourcePaintable_init_$Create$('solar/panel-horizontal_50.png', tmp_12, null, 4, null);
    var tmp_14 = new Size(465.0, 284.0);
    var tmp_15 = LocalResourcePaintable_init_$Create$('solar/panel-horizontal_25.png', tmp_14, null, 4, null);
    var tmp_16 = new Size(186.0, 113.0);
    var tmp_17 = LocalResourcePaintable_init_$Create$('solar/panel-horizontal_10.png', tmp_16, null, 4, null);
    var tmp_18 = new Size(93.0, 57.0);
    tmp_9._panelPaintableHorizontal = new MultiSizePaintable(listOf([tmp_11, tmp_13, tmp_15, tmp_17, LocalResourcePaintable_init_$Create$('solar/panel-horizontal_5.png', tmp_18, null, 4, null)]));
    var tmp_19 = this;
    var tmp_20 = new Size(374.0, 476.0);
    tmp_19._roofTilePaintable = LocalResourcePaintable_init_$Create$('solar/roofTile-red.png', tmp_20, null, 4, null);
    this._unusableAreaPaintable = new UnusableAreaPaintable();
    this._roofTileSize = Companion_getInstance_37().of_4(330.0, 420.0);
  }
  Style_3.$metadata$ = {
    simpleName: 'Style',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__446() {
  }
  _no_name_provided__446.prototype.invoke_818 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__446.prototype.invoke_1082 = function (p1) {
    this.invoke_818(p1 instanceof Style_3 ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__446.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__447(this$0) {
    this._this$0_279 = this$0;
  }
  _no_name_provided__447.prototype.onMove_20 = function (event, chartSupport) {
    var coordinates = event._coordinates_1;
    if (coordinates == null) {
      setHighLightedModuleIndex(this, -1, chartSupport._dirtySupport);
      return Unit_getInstance();
    }setHighLightedModuleIndex(this, this._this$0_279._layout_0._moduleBounds.findIndex(coordinates._x, coordinates._y), chartSupport._dirtySupport);
  };
  _no_name_provided__447.prototype.onClick_8 = function (event, chartSupport) {
    var coordinates = event._coordinates;
    var clickIndex = this._this$0_279._layout_0._moduleBounds.findIndex(coordinates._x, coordinates._y);
    if (clickIndex < 0) {
      return Unit_getInstance();
    }var clickedModule = this._this$0_279._data_4._roofPlanningModel._modules._get_modules_().get_44(clickIndex);
    this._this$0_279._data_4._modulesSelection_0.toggle_1(clickedModule);
    chartSupport.markAsDirty_4();
  };
  _no_name_provided__447.$metadata$ = {
    kind: 'class',
    interfaces: [CanvasMouseEventHandler]
  };
  function _no_name_provided__448(this$0) {
    this._this$0_280 = this$0;
  }
  _no_name_provided__448.prototype.onUp_21 = function (event, chartSupport) {
    var tmp = event._get_keyStroke__1();
    var tmp_0 = Companion_getInstance_64()._Delete;
    if (tmp.equals(KeyStroke_init_$Create$(tmp_0, null, 2, null))) {
      this._this$0_280._data_4._roofPlanningModel._modules.removeAll_28(this._this$0_280._data_4._modulesSelection_0._get_selectedModules_());
      chartSupport.markAsDirty_4();
    } else {
    }
  };
  _no_name_provided__448.$metadata$ = {
    kind: 'class',
    interfaces: [CanvasKeyEventHandler]
  };
  function RoofPlanningLayer(data, styleConfiguration) {
    AbstractLayer.call(this);
    this._data_4 = data;
    this._type_8 = LayerType_Content_getInstance();
    var tmp = this;
    var tmp0_also_0 = new Style_3();
    styleConfiguration(tmp0_also_0);
    tmp._style_6 = tmp0_also_0;
    var tmp_0 = this;
    tmp_0._mouseEventHandler_0 = new _no_name_provided__447(this);
    this._moduleUnderMouseIndex = -1;
    var tmp_1 = this;
    tmp_1._keyEventHandler_0 = new _no_name_provided__448(this);
    this._layout_0 = new Layout(this);
  }
  RoofPlanningLayer.prototype._get_type__23 = function () {
    return this._type_8;
  };
  RoofPlanningLayer.prototype._get_mouseEventHandler__6 = function () {
    return this._mouseEventHandler_0;
  };
  RoofPlanningLayer.prototype._get_keyEventHandler__6 = function () {
    return this._keyEventHandler_0;
  };
  RoofPlanningLayer.prototype.layout_6 = function (paintingContext) {
    this._layout_0.calculateLayout(paintingContext);
  };
  RoofPlanningLayer.prototype.paint_12 = function (paintingContext) {
    var gc = paintingContext._gc;
    var chartCalculator = paintingContext._get_chartCalculator__0();
    gc.fill_5(Companion_getInstance_29()._darkred);
    var tmp = this._layout_0._roofOriginWindowX;
    var tmp_0 = this._layout_0._roofOriginWindowY;
    var tmp_1 = this._layout_0._roofWidthZoomed;
    var tmp_2 = this._layout_0._roofHeightZoomed;
    gc.fillRect$default_0(tmp, tmp_0, tmp_1, tmp_2, null, null, 48, null);
    var tmp0_fastFor_0_2 = this._layout_0._roofTilesCountHorizontal;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_fastFor_0_2)
      do {
        var i_2_4 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var roofTileLocationX_4_5 = this._layout_0.roofTileLocationX(i_2_4);
        var tmp0_fastFor_0_5_6 = this._layout_0._roofTilesCountVertical;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < tmp0_fastFor_0_5_6)
          do {
            var i_2_7_8 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var roofTileLocationY_4_8_9 = this._layout_0.roofTileLocationY(i_2_7_8);
            gc.save_3();
            try {
              this._style_6._roofTilePaintable.paintInBoundingBox_3(paintingContext, roofTileLocationX_4_5, roofTileLocationY_4_8_9, Direction_TopLeft_getInstance(), 0.0, this._layout_0._roofTileWidthZoomed, this._layout_0._roofTileHeightZoomed, ObjectFit_Fill_getInstance());
            }finally {
              gc.restore_3();
            }
          }
           while (inductionVariable_0 < tmp0_fastFor_0_5_6);
      }
       while (inductionVariable < tmp0_fastFor_0_2);
    var modules = this._data_4._roofPlanningModel._modules._get_modules_();
    var tmp0_fastForEachIndexed_0 = this._layout_0._moduleBounds;
    var tmp1_fastForEachIndexed_0_1 = tmp0_fastForEachIndexed_0._xValues;
    var tmp0_fastForEachIndexed_0_2 = tmp1_fastForEachIndexed_0_1._values_1;
    var n_1_3 = 0;
    var currentSize_2_4 = tmp0_fastForEachIndexed_0_2.length;
    while (n_1_3 < currentSize_2_4) {
      var tmp2__anonymous__1_5 = n_1_3;
      var tmp0_3_7 = n_1_3;
      n_1_3 = tmp0_3_7 + 1 | 0;
      var tmp3__anonymous__1_6 = tmp0_fastForEachIndexed_0_2[tmp0_3_7];
      var y_2_8 = tmp0_fastForEachIndexed_0._yValues.get_44(tmp2__anonymous__1_5);
      var width_3_9 = tmp0_fastForEachIndexed_0._widthValues.get_44(tmp2__anonymous__1_5);
      var height_4_10 = tmp0_fastForEachIndexed_0._heightValues.get_44(tmp2__anonymous__1_5);
      var module_12 = modules.get_44(tmp2__anonymous__1_5);
      gc.save_3();
      try {
        var multiSizePaintable_2_13 = module_12.isVertical() ? this._style_6._panelPaintableVertical : this._style_6._panelPaintableHorizontal;
        var devicePixelRatio_3_14 = _get_devicePixelRatio_(paintingContext._get_chartSupport__3());
        var paintable_4_15 = multiSizePaintable_2_13.sameOrLarger(abs(width_3_9 * devicePixelRatio_3_14), abs(height_4_10 * devicePixelRatio_3_14), paintingContext._get_chartSupport__3());
        paintable_4_15.paintInBoundingBox_3(paintingContext, tmp3__anonymous__1_6, y_2_8, Direction_TopLeft_getInstance(), 0.0, width_3_9, height_4_10, ObjectFit_Fill_getInstance());
      }finally {
        gc.restore_3();
      }
      if (this._data_4._modulesSelection_0.contains_32(module_12)) {
        gc.fill_5(this._style_6._selectedModulesOverlayColor);
        gc.fillRect$default_0(tmp3__anonymous__1_6, y_2_8, width_3_9, height_4_10, null, null, 48, null);
        gc.stroke_5(this._style_6._selectedModulesOverlayBorderColor);
        gc.strokeRect$default_0(tmp3__anonymous__1_6, y_2_8, width_3_9, height_4_10, null, 16, null);
      }if (this._moduleUnderMouseIndex === tmp2__anonymous__1_5) {
        gc.fill_5(this._style_6._mouseOverModuleOverlayColor);
        gc.fillRect$default_0(tmp3__anonymous__1_6, y_2_8, width_3_9, height_4_10, null, null, 48, null);
        gc.stroke_5(this._style_6._mouseOverModuleOverlayBorderColor);
        gc.strokeRect$default_0(tmp3__anonymous__1_6, y_2_8, width_3_9, height_4_10, null, 16, null);
      }gc.stroke_5(Companion_getInstance_29()._white);
      gc.strokeRect_2(tmp3__anonymous__1_6, y_2_8, width_3_9, height_4_10, StrokeLocation_Inside_getInstance());
    }
    var unusableAreas = this._data_4._roofPlanningModel._unusableAreas._get_unusableAreas_();
    var tmp1_fastForEachIndexed_0 = this._layout_0._unusableAreaBounds;
    var tmp1_fastForEachIndexed_0_1_0 = tmp1_fastForEachIndexed_0._xValues;
    var tmp0_fastForEachIndexed_0_2_0 = tmp1_fastForEachIndexed_0_1_0._values_1;
    var n_1_3_0 = 0;
    var currentSize_2_4_0 = tmp0_fastForEachIndexed_0_2_0.length;
    while (n_1_3_0 < currentSize_2_4_0) {
      var tmp2__anonymous__1_5_0 = n_1_3_0;
      var tmp0_3_7_0 = n_1_3_0;
      n_1_3_0 = tmp0_3_7_0 + 1 | 0;
      var tmp3__anonymous__1_6_0 = tmp0_fastForEachIndexed_0_2_0[tmp0_3_7_0];
      var y_2_8_0 = tmp1_fastForEachIndexed_0._yValues.get_44(tmp2__anonymous__1_5_0);
      var width_3_9_0 = tmp1_fastForEachIndexed_0._widthValues.get_44(tmp2__anonymous__1_5_0);
      var height_4_10_0 = tmp1_fastForEachIndexed_0._heightValues.get_44(tmp2__anonymous__1_5_0);
      var unusableArea_12 = unusableAreas.get_44(tmp2__anonymous__1_5_0);
      gc.save_3();
      try {
        var paintable_2_13 = this._style_6._unusableAreaPaintable;
        paintable_2_13.paintInBoundingBox_3(paintingContext, tmp3__anonymous__1_6_0, y_2_8_0, Direction_TopLeft_getInstance(), 0.0, width_3_9_0, height_4_10_0, ObjectFit_Fill_getInstance());
      }finally {
        gc.restore_3();
      }
    }
  };
  RoofPlanningLayer.$metadata$ = {
    simpleName: 'RoofPlanningLayer',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_421() {
    var i = new _no_name_provided__446();
    return function (p1) {
      i.invoke_818(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__449(this$0) {
    this._this$0_281 = this$0;
  }
  _no_name_provided__449.prototype.invoke_1038 = function () {
    return this._this$0_281._get_modulesSize_();
  };
  _no_name_provided__449.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__450(this$0) {
    this._this$0_282 = this$0;
  }
  _no_name_provided__450.prototype.invoke_1038 = function () {
    return this._this$0_282._get_modulesOrientation_();
  };
  _no_name_provided__450.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function RoofPlanningModel() {
    this._roof = Roof_init_$Create$(12000, 8000);
    this._modulesSizeProperty = new ObservableObject(new ModuleSize(1640, 1000));
    this._modulesSize$delegate = this._modulesSizeProperty;
    this._modulesOrientationProperty = new ObservableObject(ModuleOrientation_Vertical_getInstance());
    this._modulesOrientation$delegate = this._modulesOrientationProperty;
    this._suggestedRoofInsetsProperty = new ObservableObject(Companion_getInstance_35().of_2(500.0, 500.0, 500.0, 500.0));
    this._suggestedRoofInsets$delegate = this._suggestedRoofInsetsProperty;
    this._modules = new Modules();
    var tmp = this;
    var tmp_0 = _no_name_provided_$factory_422(this);
    var tmp_1 = _no_name_provided_$factory_423(this);
    var tmp0_also_0 = ModuleGrid_init_$Create$(tmp_0, tmp_1, 0.0, 4, null);
    tmp0_also_0._offset_1 = new Coordinates(this._get_suggestedRoofInsets_()._left, this._get_suggestedRoofInsets_()._top);
    tmp._grid_0 = tmp0_also_0;
    this._unusableAreas = new UnusableAreas();
  }
  RoofPlanningModel.prototype._set_modulesSize_ = function (_set___) {
    return this._modulesSize$delegate.setValue_10(this, modulesSize$factory(), _set___);
  };
  RoofPlanningModel.prototype._get_modulesSize_ = function () {
    return this._modulesSize$delegate.getValue_10(this, modulesSize$factory_0());
  };
  RoofPlanningModel.prototype._set_modulesOrientation_ = function (_set___) {
    return this._modulesOrientation$delegate.setValue_10(this, modulesOrientation$factory(), _set___);
  };
  RoofPlanningModel.prototype._get_modulesOrientation_ = function () {
    return this._modulesOrientation$delegate.getValue_10(this, modulesOrientation$factory_0());
  };
  RoofPlanningModel.prototype._get_suggestedRoofInsets_ = function () {
    return this._suggestedRoofInsets$delegate.getValue_10(this, suggestedRoofInsets$factory());
  };
  RoofPlanningModel.prototype.updateGridModules_0 = function () {
    var modulesCountHorizontal = this._grid_0.maxModulesCountHorizontal(this._roof._get_size__43()._width_0 - this._get_suggestedRoofInsets_()._offsetWidth);
    var modulesCountVertical = this._grid_0.maxModulesCountVertical(this._roof._get_size__43()._height - this._get_suggestedRoofInsets_()._offsetHeight);
    this._modules.removeModulesWithGridLayout();
    var inductionVariable = 0;
    if (inductionVariable < modulesCountVertical)
      do {
        var y = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < modulesCountHorizontal)
          do {
            var x = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            this._modules.add_60(new Module_0(this._get_modulesSize_(), new GridLayout(new ModuleIndex(x, y), this._grid_0)));
          }
           while (inductionVariable_0 < modulesCountHorizontal);
      }
       while (inductionVariable < modulesCountVertical);
  };
  RoofPlanningModel.$metadata$ = {
    simpleName: 'RoofPlanningModel',
    kind: 'class',
    interfaces: []
  };
  function modulesSize$factory() {
    return getPropertyCallableRef('modulesSize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_modulesSize_();
    }, function (receiver_0, value) {
      return receiver_0._set_modulesSize_(value);
    });
  }
  function modulesSize$factory_0() {
    return getPropertyCallableRef('modulesSize', 1, KMutableProperty1, function (receiver) {
      return receiver._get_modulesSize_();
    }, function (receiver_0, value) {
      return receiver_0._set_modulesSize_(value);
    });
  }
  function modulesOrientation$factory() {
    return getPropertyCallableRef('modulesOrientation', 1, KMutableProperty1, function (receiver) {
      return receiver._get_modulesOrientation_();
    }, function (receiver_0, value) {
      return receiver_0._set_modulesOrientation_(value);
    });
  }
  function modulesOrientation$factory_0() {
    return getPropertyCallableRef('modulesOrientation', 1, KMutableProperty1, function (receiver) {
      return receiver._get_modulesOrientation_();
    }, function (receiver_0, value) {
      return receiver_0._set_modulesOrientation_(value);
    });
  }
  function suggestedRoofInsets$factory() {
    return getPropertyCallableRef('suggestedRoofInsets', 1, KProperty1, function (receiver) {
      return receiver._get_suggestedRoofInsets_();
    }, null);
  }
  function _no_name_provided_$factory_422(this$0) {
    var i = new _no_name_provided__449(this$0);
    return function () {
      return i.invoke_1038();
    };
  }
  function _no_name_provided_$factory_423(this$0) {
    var i = new _no_name_provided__450(this$0);
    return function () {
      return i.invoke_1038();
    };
  }
  function SolarModuleSampleModels() {
    SolarModuleSampleModels_instance = this;
  }
  SolarModuleSampleModels.prototype.realistic = function () {
    var tmp0_also_0 = new RoofPlanningModel();
    tmp0_also_0.updateGridModules_0();
    tmp0_also_0._modules.remove_61(new ModuleIndex(1, 1));
    tmp0_also_0._modules.remove_61(new ModuleIndex(1, 2));
    tmp0_also_0._unusableAreas.add_61(new UnusableArea(Companion_getInstance_32().of_4(2300.0, 1750.0), new Size(1000.0, 1400.0)));
    return tmp0_also_0;
  };
  SolarModuleSampleModels.$metadata$ = {
    simpleName: 'SolarModuleSampleModels',
    kind: 'object',
    interfaces: []
  };
  var SolarModuleSampleModels_instance;
  function SolarModuleSampleModels_getInstance() {
    if (SolarModuleSampleModels_instance == null)
      new SolarModuleSampleModels();
    return SolarModuleSampleModels_instance;
  }
  function UnusableArea(location, size) {
    this._location_1 = location;
    this._size_8 = size;
    this._descriptionProperty = new ObservableString('Unnamed');
    this._description$delegate = this._descriptionProperty;
  }
  UnusableArea.$metadata$ = {
    simpleName: 'UnusableArea',
    kind: 'class',
    interfaces: []
  };
  function UnusableAreaPaintable() {
    var tmp = this;
    tmp._strokeColor = RgbaColor_init_$Create$(86, 86, 86, null, 8, null);
    this._fillColor = Companion_getInstance_29()._white.withAlpha(0.5);
  }
  UnusableAreaPaintable.prototype.boundingBox_4 = function (chartSupport) {
    return Rectangle_init_$Create$(0.0, 0.0, 100.0, 100.0);
  };
  UnusableAreaPaintable.prototype.paint_13 = function (paintingContext, x, y) {
    var gc = paintingContext._gc;
    gc.fill_5(Companion_getInstance_29()._red);
    gc.fillRect_5(this.boundingBox_4(paintingContext._get_chartSupport__3()));
  };
  UnusableAreaPaintable.prototype.paintInBoundingBox_3 = function (paintingContext, x, y, anchorDirection, gap, width, height, objectFit) {
    var tmp0_require_0 = objectFit.equals(ObjectFit_Fill_getInstance());
    if (!tmp0_require_0) {
      var message_1 = '' + 'Unsupported object fit <' + objectFit + '>';
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var gc = paintingContext._gc;
    gc.fill_5(this._fillColor);
    gc.fillRect$default_0(x, y, width, height, null, null, 48, null);
    gc.stroke_5(this._strokeColor);
    gc.strokeRect$default_0(x, y, width, height, null, 16, null);
    gc._set_lineWidth__1(0.5);
    gc.strokeLine_1(x, y, x + width, y + height);
    gc.strokeLine_1(x, y + height, x + width, y);
  };
  UnusableAreaPaintable.$metadata$ = {
    simpleName: 'UnusableAreaPaintable',
    kind: 'class',
    interfaces: [Paintable]
  };
  function UnusableAreas() {
    var tmp = this;
    tmp._unusableAreasProperty = new ObservableObject(emptyList());
    this._unusableAreas$delegate = this._unusableAreasProperty;
  }
  UnusableAreas.prototype._get_unusableAreas_ = function () {
    return this._unusableAreas$delegate.getValue_10(this, unusableAreas$factory());
  };
  UnusableAreas.prototype._get_count__0 = function () {
    return this._get_unusableAreas_()._get_size__43();
  };
  UnusableAreas.prototype.add_61 = function (unusableArea) {
    var tmp0_getAndSet_0 = this._unusableAreasProperty;
    var tmp1__anonymous__1 = tmp0_getAndSet_0._get_value__18();
    tmp0_getAndSet_0._set_value__12(plus_0(tmp1__anonymous__1, unusableArea));
  };
  UnusableAreas.prototype.remove_62 = function (unusableArea) {
    var tmp0_getAndSet_0 = this._unusableAreasProperty;
    var tmp1__anonymous__1 = tmp0_getAndSet_0._get_value__18();
    tmp0_getAndSet_0._set_value__12(minus(tmp1__anonymous__1, unusableArea));
  };
  UnusableAreas.$metadata$ = {
    simpleName: 'UnusableAreas',
    kind: 'class',
    interfaces: []
  };
  function unusableAreas$factory() {
    return getPropertyCallableRef('unusableAreas', 1, KProperty1, function (receiver) {
      return receiver._get_unusableAreas_();
    }, null);
  }
  function valid(_this_) {
    _this_._set_validationStatus__10(ValidationStatus_VALID_getInstance());
    _this_._set_validatorError__6(null);
  }
  function invalid(_this_, invalidMessage) {
    _this_._set_validationStatus__10(ValidationStatus_INVALID_getInstance());
    _this_._set_validatorError__6(invalidMessage);
  }
  function addCssClassIf(_this_, observableValue, cssClass, predicate) {
    observableValue.subscribe_20(_no_name_provided_$factory_424(predicate, _this_, cssClass));
    Unit_getInstance();
  }
  function card(_this_, classes, init) {
    var tmp = plus_1(classes, 'card');
    return div$default(_this_, null, false, null, tmp, null, init, 23, null);
  }
  function cardHeader(_this_, classes, init) {
    var tmp = plus_1(classes, 'card-header');
    return div$default(_this_, null, false, null, tmp, null, init, 23, null);
  }
  function cardHeader$default(_this_, classes, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      classes = emptySet();
    if (!(($mask0 & 2) === 0))
      init = null;
    return cardHeader(_this_, classes, init);
  }
  function cardBody(_this_, classes, init) {
    var tmp = plus_1(classes, 'card-body');
    return div$default(_this_, null, false, null, tmp, null, init, 23, null);
  }
  function cardBody$default(_this_, classes, init, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      classes = emptySet();
    if (!(($mask0 & 2) === 0))
      init = null;
    return cardBody(_this_, classes, init);
  }
  function i18n(key) {
    return I18n_getInstance().gettext_2(key);
  }
  function i18n_0(singularKey, pluralKey, value) {
    return I18n_getInstance().ngettext_2(singularKey, pluralKey, value);
  }
  function _no_name_provided__451($predicate, $this_addCssClassIf, $cssClass) {
    this._$predicate = $predicate;
    this._$this_addCssClassIf = $this_addCssClassIf;
    this._$cssClass = $cssClass;
  }
  _no_name_provided__451.prototype.invoke_822 = function (it) {
    if (this._$predicate(it)) {
      this._$this_addCssClassIf.addCssClass_31(this._$cssClass);
      Unit_getInstance();
    } else {
      this._$this_addCssClassIf.removeCssClass_31(this._$cssClass);
      Unit_getInstance();
    }
  };
  _no_name_provided__451.prototype.invoke_1082 = function (p1) {
    this.invoke_822((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__451.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_424($predicate, $this_addCssClassIf, $cssClass) {
    var i = new _no_name_provided__451($predicate, $this_addCssClassIf, $cssClass);
    return function (p1) {
      i.invoke_822(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__452() {
  }
  _no_name_provided__452.prototype.invoke_1043 = function ($this$div) {
    var tmp = i18n('Lizergy Solar Planner');
    h1$default($this$div, tmp, false, null, null, null, null, 62, null);
    Unit_getInstance();
  };
  _no_name_provided__452.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__452.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__453() {
  }
  _no_name_provided__453.prototype.invoke_1043 = function ($this$div) {
    $this$div.addCssClass_31('banner');
    Unit_getInstance();
    $this$div.addCssClass_31('flex-shrink-0');
    Unit_getInstance();
    div$default($this$div, null, false, null, null, 'col-md-12', _no_name_provided_$factory_432(), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__453.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__453.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__454(this$0) {
    this._this$0_283 = this$0;
  }
  _no_name_provided__454.prototype.invoke_1037 = function () {
    this._this$0_283._uiModel.updateGridModules_0();
    resetZoomAndTranslationToDefaults(this._this$0_283._quickChart._chartSupport_0);
    this._this$0_283._quickChart.markAsDirty_4();
  };
  _no_name_provided__454.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__454.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__455() {
  }
  _no_name_provided__455.prototype.invoke_830 = function ($this$textWithButton, it) {
    var tmp = intFormat.format$default_0(it._width_0, null, 2, null);
    $this$textWithButton._set_value__6('' + tmp + ' x ' + intFormat.format$default_0(it._height, null, 2, null) + ' mm');
    $this$textWithButton._set_readonly__1(true);
  };
  _no_name_provided__455.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof TextInput ? p1 : THROW_CCE();
    this.invoke_830(tmp, p2 instanceof Size ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__455.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__456(this$0) {
    this._this$0_284 = this$0;
  }
  _no_name_provided__456.prototype.invoke_1051 = function ($this$onClick, it) {
    var tmp = ConfigureRoofSizeDialog_init_$Create$(this._this$0_284._uiModel._roofPlanningModel_0._roof._sizeProperty_0, 0.0, 0.0, 6, null);
    tmp.show_32(_no_name_provided_$factory_450(this._this$0_284));
    Unit_getInstance();
  };
  _no_name_provided__456.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__456.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__457() {
  }
  _no_name_provided__457.prototype.invoke_840 = function ($this$spinner, it) {
    $this$spinner._set_value__9(it);
    $this$spinner._set_label__2(i18n('Inclination (\xB0)'));
  };
  _no_name_provided__457.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Spinner ? p1 : THROW_CCE();
    this.invoke_840(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__457.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__458(this$0) {
    this._this$0_285 = this$0;
  }
  _no_name_provided__458.prototype.invoke_1077 = function (it) {
    if (!(it == null)) {
      this._this$0_285._uiModel._roofPlanningModel_0._roof._set_inclination_(numberToDouble(it));
    }};
  _no_name_provided__458.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__458.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__459() {
  }
  _no_name_provided__459.prototype.invoke_844 = function ($this$span, it) {
    $this$span._set_content__9('' + 'Placeholder Inclination ' + it + '\xB0');
  };
  _no_name_provided__459.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Span ? p1 : THROW_CCE();
    this.invoke_844(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__459.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__460() {
  }
  _no_name_provided__460.prototype.invoke_840 = function ($this$spinner, it) {
    $this$spinner._set_value__9(it);
    $this$spinner._set_label__2(i18n('Orientation (\xB0)'));
  };
  _no_name_provided__460.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Spinner ? p1 : THROW_CCE();
    this.invoke_840(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__460.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__461(this$0) {
    this._this$0_286 = this$0;
  }
  _no_name_provided__461.prototype.invoke_1077 = function (it) {
    if (!(it == null)) {
      this._this$0_286._uiModel._roofPlanningModel_0._roof._set_orientation_(numberToDouble(it));
    }};
  _no_name_provided__461.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__461.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__462() {
  }
  _no_name_provided__462.prototype.invoke_844 = function ($this$span, it) {
    $this$span._set_content__9('' + 'Placeholder Orientation ' + it + '\xB0');
  };
  _no_name_provided__462.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Span ? p1 : THROW_CCE();
    this.invoke_844(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__462.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__463() {
  }
  _no_name_provided__463.prototype.invoke_1043 = function ($this$cardHeader) {
    var tmp = i18n('Roof');
    span$default($this$cardHeader, tmp, false, null, null, null, null, 62, null);
    Unit_getInstance();
  };
  _no_name_provided__463.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__463.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__464(this$0) {
    this._this$0_287 = this$0;
  }
  _no_name_provided__464.prototype.invoke_1043 = function ($this$cardBody) {
    var tmp0_apply_0 = textWithButton($this$cardBody, this._this$0_287._uiModel._roofSizeObservable, 'Size', _no_name_provided_$factory_442());
    tmp0_apply_0._button.onClick(_no_name_provided_$factory_443(this._this$0_287));
    Unit_getInstance();
    Unit_getInstance();
    var tmp = spinner$default($this$cardBody, this._this$0_287._uiModel._roofInclinationObservable, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_444(), 4094, null);
    tmp.subscribe_18(_no_name_provided_$factory_445(this._this$0_287));
    Unit_getInstance();
    span$default_0($this$cardBody, this._this$0_287._uiModel._roofInclinationObservable, null, false, null, null, null, _no_name_provided_$factory_446(), 62, null);
    Unit_getInstance();
    var tmp_0 = spinner$default($this$cardBody, this._this$0_287._uiModel._roofOrientationObservable, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_447(), 4094, null);
    tmp_0.subscribe_18(_no_name_provided_$factory_448(this._this$0_287));
    Unit_getInstance();
    span$default_0($this$cardBody, this._this$0_287._uiModel._roofOrientationObservable, null, false, null, null, null, _no_name_provided_$factory_449(), 62, null);
    Unit_getInstance();
  };
  _no_name_provided__464.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__464.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__465(this$0) {
    this._this$0_288 = this$0;
  }
  _no_name_provided__465.prototype.invoke_1037 = function () {
    this._this$0_288._uiModel.updateGridModules_0();
    resetZoomAndTranslationToDefaults(this._this$0_288._quickChart._chartSupport_0);
    this._this$0_288._quickChart.markAsDirty_4();
  };
  _no_name_provided__465.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__465.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__466(this$0) {
    this._this$0_289 = this$0;
  }
  _no_name_provided__466.prototype.invoke_1037 = function () {
    this._this$0_289._uiModel.updateGridModules_0();
    resetZoomAndTranslationToDefaults(this._this$0_289._quickChart._chartSupport_0);
    this._this$0_289._quickChart.markAsDirty_4();
  };
  _no_name_provided__466.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__466.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__467() {
  }
  _no_name_provided__467.prototype.invoke_854 = function ($this$textWithButton, it) {
    var tmp = it._longer;
    var tmp_0 = intFormat.format$default_0(tmp, null, 2, null);
    var tmp_1 = it._shorter;
    $this$textWithButton._set_value__6('' + tmp_0 + ' x ' + intFormat.format$default_0(tmp_1, null, 2, null) + ' mm');
    $this$textWithButton._set_readonly__1(true);
  };
  _no_name_provided__467.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof TextInput ? p1 : THROW_CCE();
    this.invoke_854(tmp, p2 instanceof ModuleSize ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__467.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__468(this$0) {
    this._this$0_290 = this$0;
  }
  _no_name_provided__468.prototype.invoke_1051 = function ($this$onClick, it) {
    var tmp = ConfigureModuleSizeDialog_init_$Create$(this._this$0_290._uiModel._roofPlanningModel_0._modulesSizeProperty, 0.0, 0.0, 6, null);
    tmp.show_32(_no_name_provided_$factory_457(this._this$0_290));
    Unit_getInstance();
  };
  _no_name_provided__468.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__468.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__469() {
  }
  _no_name_provided__469.prototype.invoke_858 = function ($this$textWithButton, it) {
    $this$textWithButton._set_value__6(it._get_name__47());
    $this$textWithButton._set_readonly__1(true);
  };
  _no_name_provided__469.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof TextInput ? p1 : THROW_CCE();
    this.invoke_858(tmp, p2 instanceof ModuleOrientation ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__469.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__470(this$0) {
    this._this$0_291 = this$0;
  }
  _no_name_provided__470.prototype.invoke_1051 = function ($this$onClick, it) {
    var tmp = new ConfigureModuleOrientationDialog(this._this$0_291._uiModel._roofPlanningModel_0._modulesOrientationProperty);
    tmp.show_32(_no_name_provided_$factory_458(this._this$0_291));
    Unit_getInstance();
  };
  _no_name_provided__470.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__470.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__471() {
  }
  _no_name_provided__471.prototype.invoke_1043 = function ($this$cardHeader) {
    var tmp = i18n('Modules');
    span$default($this$cardHeader, tmp, false, null, null, null, null, 62, null);
    Unit_getInstance();
  };
  _no_name_provided__471.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__471.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__472(this$0) {
    this._this$0_292 = this$0;
  }
  _no_name_provided__472.prototype.invoke_1043 = function ($this$cardBody) {
    var tmp = i18n('Size');
    var tmp0_apply_0 = textWithButton($this$cardBody, this._this$0_292._uiModel._moduleSizeObservable, tmp, _no_name_provided_$factory_453());
    tmp0_apply_0._button.onClick(_no_name_provided_$factory_454(this._this$0_292));
    Unit_getInstance();
    Unit_getInstance();
    var tmp_0 = i18n('Orientation');
    var tmp1_apply_0 = textWithButton($this$cardBody, this._this$0_292._uiModel._moduleOrientationObservable, tmp_0, _no_name_provided_$factory_455());
    tmp1_apply_0._button.onClick(_no_name_provided_$factory_456(this._this$0_292));
    Unit_getInstance();
    Unit_getInstance();
  };
  _no_name_provided__472.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__472.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__473(this$0) {
    this._this$0_293 = this$0;
  }
  _no_name_provided__473.prototype.invoke_1037 = function () {
    this._this$0_293._uiModel.updateGridModules_0();
    resetZoomAndTranslationToDefaults(this._this$0_293._quickChart._chartSupport_0);
    this._this$0_293._quickChart.markAsDirty_4();
  };
  _no_name_provided__473.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__473.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__474() {
  }
  _no_name_provided__474.prototype.invoke_1024 = function ($this$button) {
    return Unit_getInstance();
  };
  _no_name_provided__474.prototype.invoke_1082 = function (p1) {
    this.invoke_1024(p1 instanceof Button ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__474.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__475(this$0) {
    this._this$0_294 = this$0;
  }
  _no_name_provided__475.prototype.invoke_1051 = function ($this$onClick, it) {
    var tmp = Companion_getInstance_18();
    var tmp_0 = i18n('Recalculate Grid');
    var tmp_1 = i18n('Really recalculate the grid?');
    var tmp_2 = i18n('Recalculate');
    var tmp_3 = i18n('Cancel');
    tmp.show$default(tmp_0, tmp_1, false, null, null, false, false, false, tmp_2, tmp_3, null, null, _no_name_provided_$factory_470(this._this$0_294), 3324, null);
  };
  _no_name_provided__475.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__475.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__476() {
  }
  _no_name_provided__476.prototype.invoke_1024 = function ($this$button) {
    return Unit_getInstance();
  };
  _no_name_provided__476.prototype.invoke_1082 = function (p1) {
    this.invoke_1024(p1 instanceof Button ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__476.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__477(this$0) {
    this._this$0_295 = this$0;
  }
  _no_name_provided__477.prototype.invoke_1051 = function ($this$onClick, it) {
    this._this$0_295._quickChart._chartSupport_0._zoomAndTranslationSupport_0.resetToDefaults$default(null, 1, null);
    this._this$0_295._quickChart.markAsDirty_4();
  };
  _no_name_provided__477.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__477.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__478(this$0) {
    this._this$0_296 = this$0;
  }
  _no_name_provided__478.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._this$0_296._uiModel._roofPlanningModel_0._grid_0._offset_1._x);
    $this$spinner._set_step__0(100.0);
    $this$spinner._set_label__2(i18n('Offset X (mm)'));
  };
  _no_name_provided__478.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__478.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__479(this$0) {
    this._this$0_297 = this$0;
  }
  _no_name_provided__479.prototype.invoke_1077 = function (it) {
    if (!(it == null)) {
      this._this$0_297._uiModel._roofPlanningModel_0._grid_0._offset_1 = new Coordinates(numberToDouble(it), this._this$0_297._uiModel._roofPlanningModel_0._grid_0._offset_1._y);
      this._this$0_297._quickChart.markAsDirty_4();
    }};
  _no_name_provided__479.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__479.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__480(this$0) {
    this._this$0_298 = this$0;
  }
  _no_name_provided__480.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_label__2(i18n('Offset Y (mm)'));
    $this$spinner._set_value__9(this._this$0_298._uiModel._roofPlanningModel_0._grid_0._offset_1._y);
    $this$spinner._set_step__0(100.0);
  };
  _no_name_provided__480.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__480.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__481(this$0) {
    this._this$0_299 = this$0;
  }
  _no_name_provided__481.prototype.invoke_1077 = function (it) {
    if (!(it == null)) {
      this._this$0_299._uiModel._roofPlanningModel_0._grid_0._offset_1 = new Coordinates(this._this$0_299._uiModel._roofPlanningModel_0._grid_0._offset_1._x, numberToDouble(it));
      this._this$0_299._quickChart.markAsDirty_4();
    }};
  _no_name_provided__481.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__481.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__482(this$0) {
    this._this$0_300 = this$0;
  }
  _no_name_provided__482.prototype.invoke_884 = function ($this$buttonGroup) {
    var tmp = i18n('Update Grid');
    var tmp_0 = ButtonStyle_WARNING_getInstance();
    var tmp_1 = button$default($this$buttonGroup, tmp, null, tmp_0, null, false, null, false, null, null, _no_name_provided_$factory_466(), 506, null);
    tmp_1.onClick(_no_name_provided_$factory_467(this._this$0_300));
    Unit_getInstance();
    var tmp_2 = i18n('Reset View');
    var tmp_3 = button$default($this$buttonGroup, tmp_2, null, null, null, false, null, false, null, null, _no_name_provided_$factory_468(), 510, null);
    tmp_3.onClick(_no_name_provided_$factory_469(this._this$0_300));
    Unit_getInstance();
  };
  _no_name_provided__482.prototype.invoke_1082 = function (p1) {
    this.invoke_884(p1 instanceof ButtonGroup ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__482.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__483() {
  }
  _no_name_provided__483.prototype.invoke_1043 = function ($this$cardHeader) {
    var tmp = i18n('Grid');
    span$default($this$cardHeader, tmp, false, null, null, null, null, 62, null);
    Unit_getInstance();
  };
  _no_name_provided__483.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__483.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__484(this$0) {
    this._this$0_301 = this$0;
  }
  _no_name_provided__484.prototype.invoke_1043 = function ($this$cardBody) {
    var tmp = spinner$default_0($this$cardBody, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_461(this._this$0_301), 2047, null);
    tmp.subscribe_18(_no_name_provided_$factory_462(this._this$0_301));
    Unit_getInstance();
    var tmp_0 = spinner$default_0($this$cardBody, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_463(this._this$0_301), 2047, null);
    tmp_0.subscribe_18(_no_name_provided_$factory_464(this._this$0_301));
    Unit_getInstance();
    buttonGroup$default($this$cardBody, null, false, null, null, _no_name_provided_$factory_465(this._this$0_301), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__484.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__484.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__485(this$0) {
    this._this$0_302 = this$0;
  }
  _no_name_provided__485.prototype.invoke_1043 = function ($this$card) {
    cardHeader$default($this$card, null, _no_name_provided_$factory_440(), 1, null);
    Unit_getInstance();
    cardBody$default($this$card, null, _no_name_provided_$factory_441(this._this$0_302), 1, null);
    Unit_getInstance();
  };
  _no_name_provided__485.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__485.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__486(this$0) {
    this._this$0_303 = this$0;
  }
  _no_name_provided__486.prototype.invoke_1043 = function ($this$card) {
    cardHeader$default($this$card, null, _no_name_provided_$factory_451(), 1, null);
    Unit_getInstance();
    cardBody$default($this$card, null, _no_name_provided_$factory_452(this._this$0_303), 1, null);
    Unit_getInstance();
  };
  _no_name_provided__486.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__486.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__487(this$0) {
    this._this$0_304 = this$0;
  }
  _no_name_provided__487.prototype.invoke_1043 = function ($this$card) {
    cardHeader$default($this$card, null, _no_name_provided_$factory_459(), 1, null);
    Unit_getInstance();
    cardBody$default($this$card, null, _no_name_provided_$factory_460(this._this$0_304), 1, null);
    Unit_getInstance();
  };
  _no_name_provided__487.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__487.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__488(this$0, $this_div) {
    this._this$0_305 = this$0;
    this._$this_div = $this_div;
  }
  _no_name_provided__488.prototype.invoke_896 = function (it) {
    var tmp0_let_0 = this._this$0_305._quickChart._holder;
    tmp0_let_0.style.maxHeight = '1200px';
    var tmp0_safe_receiver_2 = this._$this_div.getElement_30();
    if (tmp0_safe_receiver_2 == null)
      null;
    else
      tmp0_safe_receiver_2.appendChild(tmp0_let_0);
    Unit_getInstance();
  };
  _no_name_provided__488.prototype.invoke_1082 = function (p1) {
    this.invoke_896((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__488.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__489() {
  }
  _no_name_provided__489.prototype.invoke_898 = function ($this$span, it) {
    $this$span._set_content__9(it._get_size__43().toString());
  };
  _no_name_provided__489.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Span ? p1 : THROW_CCE();
    this.invoke_898(tmp, (!(p2 == null) ? isInterface(p2, List) : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__489.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__490() {
  }
  _no_name_provided__490.prototype.invoke_1043 = function ($this$cardHeader) {
    var tmp = i18n('Modules');
    span$default($this$cardHeader, tmp, false, null, null, null, null, 62, null);
    Unit_getInstance();
  };
  _no_name_provided__490.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__490.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__491(this$0) {
    this._this$0_306 = this$0;
  }
  _no_name_provided__491.prototype.invoke_1043 = function ($this$cardBody) {
    var tmp = i18n('Number of modules:');
    span$default($this$cardBody, tmp, false, null, null, null, null, 62, null);
    Unit_getInstance();
    span$default_0($this$cardBody, this._this$0_306._uiModel._modulesObservable, null, false, null, null, 'pl-5', _no_name_provided_$factory_479(), 30, null);
    Unit_getInstance();
  };
  _no_name_provided__491.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__491.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__492(this$0) {
    this._this$0_307 = this$0;
  }
  _no_name_provided__492.prototype.invoke_1051 = function ($this$onClick, it) {
    var roofPlanningModel = this._this$0_307._uiModel._roofPlanningModel_0;
    var tmp0_let_0 = new UnusableArea(Companion_getInstance_32()._origin_0, new Size(500.0, 1300.0));
    roofPlanningModel._unusableAreas.add_61(tmp0_let_0);
    this._this$0_307._uiModel._selectedUnusableArea._set_value__12(tmp0_let_0);
  };
  _no_name_provided__492.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__492.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__493() {
  }
  _no_name_provided__493.prototype.invoke_916 = function ($this$span) {
    $this$span._set_title__30(i18n('Areas that are unusable'));
    $this$span.enableTooltip$default(null, 1, null);
    Unit_getInstance();
  };
  _no_name_provided__493.prototype.invoke_1082 = function (p1) {
    this.invoke_916(p1 instanceof Span ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__493.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__494(this$0) {
    this._this$0_308 = this$0;
  }
  _no_name_provided__494.prototype.invoke_1024 = function ($this$button) {
    $this$button._set_title__30(i18n('Add new'));
    $this$button.enableTooltip$default(null, 1, null);
    Unit_getInstance();
    $this$button.onClick(_no_name_provided_$factory_484(this._this$0_308));
    Unit_getInstance();
  };
  _no_name_provided__494.prototype.invoke_1082 = function (p1) {
    this.invoke_1024(p1 instanceof Button ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__494.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__495(this$0) {
    this._this$0_309 = this$0;
  }
  _no_name_provided__495.prototype.invoke_920 = function ($this$hPanel) {
    var tmp = i18n('Unusable Areas');
    span$default($this$hPanel, tmp, false, null, null, null, _no_name_provided_$factory_482(), 30, null);
    Unit_getInstance();
    var tmp_0 = ButtonStyle_LINK_getInstance();
    button$default($this$hPanel, '', 'fas fa-plus', tmp_0, null, false, null, false, null, null, _no_name_provided_$factory_483(this._this$0_309), 504, null);
    Unit_getInstance();
  };
  _no_name_provided__495.prototype.invoke_1082 = function (p1) {
    this.invoke_920(p1 instanceof HPanel ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__495.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__496(this$0) {
    this._this$0_310 = this$0;
  }
  _no_name_provided__496.prototype.invoke_1043 = function ($this$cardHeader) {
    var tmp = JustifyContent_SPACEBETWEEN_getInstance();
    var tmp_0 = AlignItems_CENTER_getInstance();
    hPanel$default($this$cardHeader, null, tmp, tmp_0, null, false, null, null, _no_name_provided_$factory_481(this._this$0_310), 121, null);
    Unit_getInstance();
  };
  _no_name_provided__496.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__496.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__497(this$0) {
    this._this$0_311 = this$0;
  }
  _no_name_provided__497.prototype.invoke_1051 = function ($this$onClick, it) {
    var roofPlanningModel = this._this$0_311._uiModel._roofPlanningModel_0;
    var tmp0_let_0 = new Module_0(roofPlanningModel._get_modulesSize_(), new AbsoluteLayout());
    roofPlanningModel._modules.add_60(tmp0_let_0);
    this._this$0_311._uiModel._selectedModule._set_value__12(tmp0_let_0);
  };
  _no_name_provided__497.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__497.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__498() {
  }
  _no_name_provided__498.prototype.invoke_916 = function ($this$span) {
    $this$span._set_title__30(i18n('Modules that are layouted manually'));
    $this$span.enableTooltip$default(null, 1, null);
    Unit_getInstance();
  };
  _no_name_provided__498.prototype.invoke_1082 = function (p1) {
    this.invoke_916(p1 instanceof Span ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__498.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__499(this$0) {
    this._this$0_312 = this$0;
  }
  _no_name_provided__499.prototype.invoke_1024 = function ($this$button) {
    $this$button._set_title__30(i18n('Add new'));
    $this$button.enableTooltip$default(null, 1, null);
    Unit_getInstance();
    $this$button.onClick(_no_name_provided_$factory_489(this._this$0_312));
    Unit_getInstance();
  };
  _no_name_provided__499.prototype.invoke_1082 = function (p1) {
    this.invoke_1024(p1 instanceof Button ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__499.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__500(this$0) {
    this._this$0_313 = this$0;
  }
  _no_name_provided__500.prototype.invoke_920 = function ($this$hPanel) {
    var tmp = i18n_0('Manually layouted module', 'Manually layouted modules', 2);
    span$default($this$hPanel, tmp, false, null, null, null, _no_name_provided_$factory_487(), 30, null);
    Unit_getInstance();
    var tmp_0 = ButtonStyle_LINK_getInstance();
    button$default($this$hPanel, '', 'fas fa-plus', tmp_0, null, false, null, false, null, null, _no_name_provided_$factory_488(this._this$0_313), 504, null);
    Unit_getInstance();
  };
  _no_name_provided__500.prototype.invoke_1082 = function (p1) {
    this.invoke_920(p1 instanceof HPanel ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__500.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__501(this$0) {
    this._this$0_314 = this$0;
  }
  _no_name_provided__501.prototype.invoke_1043 = function ($this$cardHeader) {
    var tmp = JustifyContent_SPACEBETWEEN_getInstance();
    var tmp_0 = AlignItems_CENTER_getInstance();
    hPanel$default($this$cardHeader, null, tmp, tmp_0, null, false, null, null, _no_name_provided_$factory_486(this._this$0_314), 121, null);
    Unit_getInstance();
  };
  _no_name_provided__501.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__501.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__502(this$0) {
    this._this$0_315 = this$0;
  }
  _no_name_provided__502.prototype.invoke_1043 = function ($this$card) {
    cardHeader$default($this$card, null, _no_name_provided_$factory_477(), 1, null);
    Unit_getInstance();
    cardBody$default($this$card, null, _no_name_provided_$factory_478(this._this$0_315), 1, null);
    Unit_getInstance();
  };
  _no_name_provided__502.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__502.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__503(this$0) {
    this._this$0_316 = this$0;
  }
  _no_name_provided__503.prototype.invoke_1043 = function ($this$card) {
    cardHeader$default($this$card, null, _no_name_provided_$factory_480(this._this$0_316), 1, null);
    Unit_getInstance();
  };
  _no_name_provided__503.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__503.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__504(this$0) {
    this._this$0_317 = this$0;
  }
  _no_name_provided__504.prototype.invoke_928 = function ($this$div, it) {
    var tmp0_iterator_1 = it.iterator_38();
    while (tmp0_iterator_1.hasNext_11()) {
      var element_2 = tmp0_iterator_1.next_13();
      addUnusableConfigCard($this$div, this._this$0_317, element_2);
    }
  };
  _no_name_provided__504.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Div ? p1 : THROW_CCE();
    this.invoke_928(tmp, (!(p2 == null) ? isInterface(p2, List) : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__504.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__505(this$0) {
    this._this$0_318 = this$0;
  }
  _no_name_provided__505.prototype.invoke_1043 = function ($this$card) {
    cardHeader$default($this$card, null, _no_name_provided_$factory_485(this._this$0_318), 1, null);
    Unit_getInstance();
  };
  _no_name_provided__505.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__505.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__506(this$0) {
    this._this$0_319 = this$0;
  }
  _no_name_provided__506.prototype.invoke_932 = function ($this$div, it) {
    var index_1 = 0;
    var tmp0_iterator_2 = it.iterator_38();
    while (tmp0_iterator_2.hasNext_11()) {
      var item_3 = tmp0_iterator_2.next_13();
      var tmp1_4 = index_1;
      index_1 = tmp1_4 + 1 | 0;
      var tmp0__anonymous__5 = checkIndexOverflow(tmp1_4);
      addManualModuleConfigCard($this$div, this._this$0_319, item_3, tmp0__anonymous__5);
    }
  };
  _no_name_provided__506.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Div ? p1 : THROW_CCE();
    this.invoke_932(tmp, (!(p2 == null) ? isInterface(p2, List) : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__506.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__507(this$0) {
    this._this$0_320 = this$0;
  }
  _no_name_provided__507.prototype.invoke_1043 = function ($this$div) {
    $this$div.addCssClass_31('h-100');
    Unit_getInstance();
    $this$div.addCssClass_31('overflow-auto');
    Unit_getInstance();
    var tmp = setOf('mb-3');
    card($this$div, tmp, _no_name_provided_$factory_437(this._this$0_320));
    Unit_getInstance();
    var tmp_0 = setOf('mb-3');
    card($this$div, tmp_0, _no_name_provided_$factory_438(this._this$0_320));
    Unit_getInstance();
    var tmp_1 = setOf('mb-3');
    card($this$div, tmp_1, _no_name_provided_$factory_439(this._this$0_320));
    Unit_getInstance();
  };
  _no_name_provided__507.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__507.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__508(this$0) {
    this._this$0_321 = this$0;
  }
  _no_name_provided__508.prototype.invoke_1043 = function ($this$div) {
    $this$div.addAfterInsertHook_0(_no_name_provided_$factory_471(this._this$0_321, $this$div));
    Unit_getInstance();
  };
  _no_name_provided__508.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__508.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__509(this$0) {
    this._this$0_322 = this$0;
  }
  _no_name_provided__509.prototype.invoke_1043 = function ($this$div) {
    $this$div.addCssClass_31('h-100');
    Unit_getInstance();
    $this$div.addCssClass_31('overflow-auto');
    Unit_getInstance();
    var tmp = setOf('mb-3');
    card($this$div, tmp, _no_name_provided_$factory_472(this._this$0_322));
    Unit_getInstance();
    var tmp_0 = emptySet();
    card($this$div, tmp_0, _no_name_provided_$factory_473(this._this$0_322));
    Unit_getInstance();
    div$default_0($this$div, this._this$0_322._uiModel._unusableAreasObservable, null, false, null, null, null, _no_name_provided_$factory_474(this._this$0_322), 62, null);
    Unit_getInstance();
    var tmp_1 = setOf('mt-3');
    card($this$div, tmp_1, _no_name_provided_$factory_475(this._this$0_322));
    Unit_getInstance();
    div$default_0($this$div, this._this$0_322._uiModel._modulesWithAbsoluteLayoutObservable, null, false, null, null, null, _no_name_provided_$factory_476(this._this$0_322), 62, null);
    Unit_getInstance();
  };
  _no_name_provided__509.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__509.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__510(this$0) {
    this._this$0_323 = this$0;
  }
  _no_name_provided__510.prototype.invoke_1043 = function ($this$div) {
    $this$div.addCssClass_31('h-100');
    Unit_getInstance();
    div$default($this$div, null, false, null, null, 'col-md-2', _no_name_provided_$factory_434(this._this$0_323), 15, null);
    Unit_getInstance();
    var tmp = setOf('col-md-8');
    div$default($this$div, null, false, null, tmp, null, _no_name_provided_$factory_435(this._this$0_323), 23, null);
    Unit_getInstance();
    div$default($this$div, null, false, null, null, 'col-md-2', _no_name_provided_$factory_436(this._this$0_323), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__510.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__510.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__511() {
  }
  _no_name_provided__511.prototype.invoke_942 = function ($this$header) {
    div$default($this$header, null, false, null, null, 'row', _no_name_provided_$factory_431(), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__511.prototype.invoke_1082 = function (p1) {
    this.invoke_942(p1 instanceof Header ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__511.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__512(this$0) {
    this._this$0_324 = this$0;
  }
  _no_name_provided__512.prototype.invoke_944 = function ($this$main) {
    $this$main.addCssClass_31('flex-fill');
    Unit_getInstance();
    $this$main.addCssClass_31('overflow-hidden');
    Unit_getInstance();
    div$default($this$main, null, false, null, null, 'row', _no_name_provided_$factory_433(this._this$0_324), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__512.prototype.invoke_1082 = function (p1) {
    this.invoke_944(p1 instanceof Main ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__512.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__513() {
  }
  _no_name_provided__513.prototype.invoke_946 = function ($this$footer) {
    h6$default($this$footer, 'Provided by Neckar.IT GmbH', false, null, null, null, null, 62, null);
    Unit_getInstance();
  };
  _no_name_provided__513.prototype.invoke_1082 = function (p1) {
    this.invoke_946(p1 instanceof Footer ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__513.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__514($tmp0_onClick_0, $this_setEventListener) {
    this._$tmp0_onClick_0 = $tmp0_onClick_0;
    this._$this_setEventListener_2 = $this_setEventListener;
  }
  _no_name_provided__514.prototype.invoke_992 = function (e) {
    this._$tmp0_onClick_0(this._$this_setEventListener_2.self, e);
  };
  _no_name_provided__514.prototype.invoke_1082 = function (p1) {
    this.invoke_992(p1 instanceof MouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__514.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__515(this$0, $unusableArea) {
    this._this$0_325 = this$0;
    this._$unusableArea = $unusableArea;
  }
  _no_name_provided__515.prototype.invoke_1051 = function ($this$onClick, it) {
    it.stopImmediatePropagation();
    this._this$0_325._uiModel._selectedUnusableArea._set_value__12(this._$unusableArea);
    var tmp = new UnusableAreaDescriptionDialog(this._$unusableArea._descriptionProperty);
    tmp.show$default_4(null, 1, null);
    Unit_getInstance();
  };
  _no_name_provided__515.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__515.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__516() {
  }
  _no_name_provided__516.prototype.invoke_952 = function ($this$span, it) {
    $this$span._set_content__9(it);
  };
  _no_name_provided__516.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Span ? p1 : THROW_CCE();
    this.invoke_952(tmp, (!(p2 == null) ? typeof p2 === 'string' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__516.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__517(this$0, $unusableArea) {
    this._this$0_326 = this$0;
    this._$unusableArea_0 = $unusableArea;
  }
  _no_name_provided__517.prototype.invoke_1024 = function ($this$button) {
    $this$button.addCssClass_31('py-0');
    Unit_getInstance();
    $this$button._set_title__30(i18n('Edit description'));
    $this$button.enableTooltip$default(null, 1, null);
    Unit_getInstance();
    $this$button.onClick(_no_name_provided_$factory_499(this._this$0_326, this._$unusableArea_0));
    Unit_getInstance();
  };
  _no_name_provided__517.prototype.invoke_1082 = function (p1) {
    this.invoke_1024(p1 instanceof Button ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__517.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__518(this$0, $unusableArea) {
    this._this$0_327 = this$0;
    this._$unusableArea_1 = $unusableArea;
  }
  _no_name_provided__518.prototype.invoke_994 = function ($this$onClick, it) {
    if (equals_0(this._this$0_327._uiModel._selectedUnusableArea._get_value__18(), this._$unusableArea_1)) {
      this._this$0_327._uiModel._selectedUnusableArea._set_value__12(null);
    } else {
      this._this$0_327._uiModel._selectedUnusableArea._set_value__12(this._$unusableArea_1);
    }
  };
  _no_name_provided__518.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Div ? p1 : THROW_CCE();
    this.invoke_994(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__518.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__519($tmp0_onClick_0) {
    this._$tmp0_onClick_0_0 = $tmp0_onClick_0;
  }
  _no_name_provided__519.prototype.invoke_996 = function ($this$setEventListener) {
    $this$setEventListener.click = _no_name_provided_$factory_496(this._$tmp0_onClick_0_0, $this$setEventListener);
  };
  _no_name_provided__519.prototype.invoke_1082 = function (p1) {
    this.invoke_996((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__519.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__520($unusableArea) {
    this._$unusableArea_2 = $unusableArea;
  }
  _no_name_provided__520.prototype.invoke_960 = function (it) {
    return equals_0(it, this._$unusableArea_2);
  };
  _no_name_provided__520.prototype.invoke_1082 = function (p1) {
    return this.invoke_960((p1 == null ? true : p1 instanceof UnusableArea) ? p1 : THROW_CCE());
  };
  _no_name_provided__520.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__521($unusableArea, this$0) {
    this._$unusableArea_3 = $unusableArea;
    this._this$0_328 = this$0;
  }
  _no_name_provided__521.prototype.invoke_1000 = function ($this$h6) {
    var tmp = asObservableState(this._$unusableArea_3._descriptionProperty);
    span$default_0($this$h6, tmp, '', false, null, null, null, _no_name_provided_$factory_497(), 60, null);
    Unit_getInstance();
    var tmp_0 = ButtonStyle_LINK_getInstance();
    button$default($this$h6, '', 'fas fa-pen', tmp_0, null, false, null, false, null, null, _no_name_provided_$factory_498(this._this$0_328, this._$unusableArea_3), 504, null);
    Unit_getInstance();
  };
  _no_name_provided__521.prototype.invoke_1082 = function (p1) {
    this.invoke_1000(p1 instanceof H6 ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__521.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__522(this$0, $unusableArea) {
    this._this$0_329 = this$0;
    this._$unusableArea_4 = $unusableArea;
  }
  _no_name_provided__522.prototype.invoke_1037 = function () {
    this._this$0_329._uiModel._roofPlanningModel_0._unusableAreas.remove_62(this._$unusableArea_4);
    this._this$0_329._quickChart.markAsDirty_4();
  };
  _no_name_provided__522.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__522.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__523(this$0, $unusableArea) {
    this._this$0_330 = this$0;
    this._$unusableArea_5 = $unusableArea;
  }
  _no_name_provided__523.prototype.invoke_1051 = function ($this$onClick, it) {
    var tmp = Companion_getInstance_18();
    var tmp_0 = i18n('Delete unusable area');
    var tmp_1 = i18n('Really delete the unusable area');
    var tmp_2 = i18n('Delete');
    var tmp_3 = i18n('Cancel');
    tmp.show$default(tmp_0, tmp_1, false, null, null, false, false, false, tmp_2, tmp_3, null, null, _no_name_provided_$factory_511(this._this$0_330, this._$unusableArea_5), 3324, null);
  };
  _no_name_provided__523.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__523.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__524($unusableArea, $this_div) {
    this._$unusableArea_6 = $unusableArea;
    this._$this_div_0 = $this_div;
  }
  _no_name_provided__524.prototype.invoke_968 = function (it) {
    if (equals_0(it, this._$unusableArea_6)) {
      if (!this._$this_div_0._get_visible__31()) {
        this._$this_div_0.slideDown$default(0, null, null, 7, null);
        Unit_getInstance();
      }} else {
      if (this._$this_div_0._get_visible__31()) {
        this._$this_div_0.slideUp$default(0, null, null, 7, null);
        Unit_getInstance();
      }}
  };
  _no_name_provided__524.prototype.invoke_1082 = function (p1) {
    this.invoke_968((p1 == null ? true : p1 instanceof UnusableArea) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__524.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__525($unusableArea) {
    this._$unusableArea_7 = $unusableArea;
  }
  _no_name_provided__525.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$unusableArea_7._location_1._x);
    $this$spinner._set_step__0(100.0);
    $this$spinner._set_label__2(i18n('X (mm)'));
  };
  _no_name_provided__525.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__525.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__526($unusableArea, this$0) {
    this._$unusableArea_8 = $unusableArea;
    this._this$0_331 = this$0;
  }
  _no_name_provided__526.prototype.invoke_1077 = function (newValue) {
    if (!(newValue == null)) {
      this._$unusableArea_8._location_1 = new Coordinates(numberToDouble(newValue), this._$unusableArea_8._location_1._y);
      this._this$0_331._quickChart.markAsDirty_4();
    }};
  _no_name_provided__526.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__526.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__527($unusableArea) {
    this._$unusableArea_9 = $unusableArea;
  }
  _no_name_provided__527.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$unusableArea_9._location_1._y);
    $this$spinner._set_step__0(100.0);
    $this$spinner._set_label__2(i18n('Y (mm)'));
  };
  _no_name_provided__527.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__527.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__528($unusableArea, this$0) {
    this._$unusableArea_10 = $unusableArea;
    this._this$0_332 = this$0;
  }
  _no_name_provided__528.prototype.invoke_1077 = function (newValue) {
    if (!(newValue == null)) {
      this._$unusableArea_10._location_1 = new Coordinates(this._$unusableArea_10._location_1._x, numberToDouble(newValue));
      this._this$0_332._quickChart.markAsDirty_4();
    }};
  _no_name_provided__528.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__528.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__529($unusableArea) {
    this._$unusableArea_11 = $unusableArea;
  }
  _no_name_provided__529.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$unusableArea_11._size_8._width_0);
    $this$spinner._set_step__0(100.0);
    $this$spinner._set_label__2(i18n('Height (mm)'));
  };
  _no_name_provided__529.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__529.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__530($unusableArea, this$0) {
    this._$unusableArea_12 = $unusableArea;
    this._this$0_333 = this$0;
  }
  _no_name_provided__530.prototype.invoke_1077 = function (newValue) {
    if (!(newValue == null)) {
      this._$unusableArea_12._size_8 = new Size(numberToDouble(newValue), this._$unusableArea_12._size_8._height);
      this._this$0_333._quickChart.markAsDirty_4();
    }};
  _no_name_provided__530.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__530.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__531($unusableArea) {
    this._$unusableArea_13 = $unusableArea;
  }
  _no_name_provided__531.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$unusableArea_13._size_8._height);
    $this$spinner._set_step__0(100.0);
    $this$spinner._set_label__2(i18n('Width (mm)'));
  };
  _no_name_provided__531.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__531.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__532($unusableArea, this$0) {
    this._$unusableArea_14 = $unusableArea;
    this._this$0_334 = this$0;
  }
  _no_name_provided__532.prototype.invoke_1077 = function (newValue) {
    if (!(newValue == null)) {
      this._$unusableArea_14._size_8 = new Size(this._$unusableArea_14._size_8._width_0, numberToDouble(newValue));
      this._this$0_334._quickChart.markAsDirty_4();
    }};
  _no_name_provided__532.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__532.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__533(this$0, $unusableArea) {
    this._this$0_335 = this$0;
    this._$unusableArea_15 = $unusableArea;
  }
  _no_name_provided__533.prototype.invoke_1024 = function ($this$button) {
    $this$button._set_title__30(i18n('Delete'));
    $this$button.enableTooltip$default(null, 1, null);
    Unit_getInstance();
    $this$button.onClick(_no_name_provided_$factory_510(this._this$0_335, this._$unusableArea_15));
    Unit_getInstance();
  };
  _no_name_provided__533.prototype.invoke_1082 = function (p1) {
    this.invoke_1024(p1 instanceof Button ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__533.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__534(this$0, $unusableArea) {
    this._this$0_336 = this$0;
    this._$unusableArea_16 = $unusableArea;
  }
  _no_name_provided__534.prototype.invoke_1043 = function ($this$div) {
    var tmp0_onClick_0 = _no_name_provided_$factory_492(this._this$0_336, this._$unusableArea_16);
    $this$div.setEventListener_30(_no_name_provided_$factory_493(tmp0_onClick_0));
    Unit_getInstance();
    $this$div._set_cursor__31(Cursor_POINTER_getInstance());
    addCssClassIf($this$div, this._this$0_336._uiModel._selectedUnusableArea, 'text-primary', _no_name_provided_$factory_494(this._$unusableArea_16));
    h6$default($this$div, null, false, null, null, 'mb-0', _no_name_provided_$factory_495(this._$unusableArea_16, this._this$0_336), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__534.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__534.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__535(this$0, $unusableArea) {
    this._this$0_337 = this$0;
    this._$unusableArea_17 = $unusableArea;
  }
  _no_name_provided__535.prototype.invoke_1043 = function ($this$div) {
    this._this$0_337._uiModel._selectedUnusableArea.subscribe_20(_no_name_provided_$factory_500(this._$unusableArea_17, $this$div));
    Unit_getInstance();
    var tmp = spinner$default_0($this$div, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_501(this._$unusableArea_17), 2047, null);
    tmp.subscribe_18(_no_name_provided_$factory_502(this._$unusableArea_17, this._this$0_337));
    Unit_getInstance();
    var tmp_0 = spinner$default_0($this$div, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_503(this._$unusableArea_17), 2047, null);
    tmp_0.subscribe_18(_no_name_provided_$factory_504(this._$unusableArea_17, this._this$0_337));
    Unit_getInstance();
    var tmp_1 = spinner$default_0($this$div, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_505(this._$unusableArea_17), 2047, null);
    tmp_1.subscribe_18(_no_name_provided_$factory_506(this._$unusableArea_17, this._this$0_337));
    Unit_getInstance();
    var tmp_2 = spinner$default_0($this$div, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_507(this._$unusableArea_17), 2047, null);
    tmp_2.subscribe_18(_no_name_provided_$factory_508(this._$unusableArea_17, this._this$0_337));
    Unit_getInstance();
    var tmp_3 = ButtonStyle_WARNING_getInstance();
    button$default($this$div, '', 'fas fa-trash', tmp_3, null, false, null, false, null, null, _no_name_provided_$factory_509(this._this$0_337, this._$unusableArea_17), 504, null);
    Unit_getInstance();
  };
  _no_name_provided__535.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__535.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__536($tmp0_onClick_0, $this_setEventListener) {
    this._$tmp0_onClick_0_1 = $tmp0_onClick_0;
    this._$this_setEventListener_3 = $this_setEventListener;
  }
  _no_name_provided__536.prototype.invoke_992 = function (e) {
    this._$tmp0_onClick_0_1(this._$this_setEventListener_3.self, e);
  };
  _no_name_provided__536.prototype.invoke_1082 = function (p1) {
    this.invoke_992(p1 instanceof MouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__536.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__537(this$0, $module) {
    this._this$0_338 = this$0;
    this._$module = $module;
  }
  _no_name_provided__537.prototype.invoke_994 = function ($this$onClick, it) {
    if (equals_0(this._this$0_338._uiModel._selectedModule._get_value__18(), this._$module)) {
      this._this$0_338._uiModel._selectedModule._set_value__12(null);
    } else {
      this._this$0_338._uiModel._selectedModule._set_value__12(this._$module);
    }
  };
  _no_name_provided__537.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Div ? p1 : THROW_CCE();
    this.invoke_994(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__537.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__538($tmp0_onClick_0) {
    this._$tmp0_onClick_0_2 = $tmp0_onClick_0;
  }
  _no_name_provided__538.prototype.invoke_996 = function ($this$setEventListener) {
    $this$setEventListener.click = _no_name_provided_$factory_518(this._$tmp0_onClick_0_2, $this$setEventListener);
  };
  _no_name_provided__538.prototype.invoke_1082 = function (p1) {
    this.invoke_996((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__538.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__539($module) {
    this._$module_0 = $module;
  }
  _no_name_provided__539.prototype.invoke_998 = function (it) {
    return equals_0(it, this._$module_0);
  };
  _no_name_provided__539.prototype.invoke_1082 = function (p1) {
    return this.invoke_998((p1 == null ? true : p1 instanceof Module_0) ? p1 : THROW_CCE());
  };
  _no_name_provided__539.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__540($index) {
    this._$index = $index;
  }
  _no_name_provided__540.prototype.invoke_1000 = function ($this$h6) {
    var tmp = i18n_0('Manually layouted module', 'Manually layouted modules', 1) + ('' + ' ' + (this._$index + 1 | 0));
    span$default($this$h6, tmp, false, null, null, null, null, 62, null);
    Unit_getInstance();
  };
  _no_name_provided__540.prototype.invoke_1082 = function (p1) {
    this.invoke_1000(p1 instanceof H6 ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__540.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__541(this$0, $module) {
    this._this$0_339 = this$0;
    this._$module_1 = $module;
  }
  _no_name_provided__541.prototype.invoke_1037 = function () {
    this._this$0_339._uiModel._roofPlanningModel_0._modules.remove_60(this._$module_1);
    this._this$0_339._quickChart.markAsDirty_4();
  };
  _no_name_provided__541.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__541.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__542(this$0, $module) {
    this._this$0_340 = this$0;
    this._$module_2 = $module;
  }
  _no_name_provided__542.prototype.invoke_1051 = function ($this$onClick, it) {
    var tmp = Companion_getInstance_18();
    var tmp_0 = i18n('Delete module');
    var tmp_1 = i18n('Really delete the module');
    var tmp_2 = i18n('Delete');
    var tmp_3 = i18n('Cancel');
    tmp.show$default(tmp_0, tmp_1, false, null, null, false, false, false, tmp_2, tmp_3, null, null, _no_name_provided_$factory_530(this._this$0_340, this._$module_2), 3324, null);
  };
  _no_name_provided__542.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__542.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__543($module, $this_div) {
    this._$module_3 = $module;
    this._$this_div_1 = $this_div;
  }
  _no_name_provided__543.prototype.invoke_1006 = function (it) {
    if (equals_0(it, this._$module_3)) {
      if (!this._$this_div_1._get_visible__31()) {
        this._$this_div_1.slideDown$default(0, null, null, 7, null);
        Unit_getInstance();
      }} else {
      if (this._$this_div_1._get_visible__31()) {
        this._$this_div_1.slideUp$default(0, null, null, 7, null);
        Unit_getInstance();
      }}
  };
  _no_name_provided__543.prototype.invoke_1082 = function (p1) {
    this.invoke_1006((p1 == null ? true : p1 instanceof Module_0) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__543.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__544($module) {
    this._$module_4 = $module;
  }
  _no_name_provided__544.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$module_4._get_location__2()._x);
    $this$spinner._set_step__0(100.0);
    $this$spinner._set_label__2(i18n('X (mm)'));
  };
  _no_name_provided__544.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__544.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__545($layout, $module, this$0) {
    this._$layout = $layout;
    this._$module_5 = $module;
    this._this$0_341 = this$0;
  }
  _no_name_provided__545.prototype.invoke_1077 = function (newValue) {
    if (!(newValue == null)) {
      this._$layout._location_0 = new Coordinates(numberToDouble(newValue), this._$module_5._get_location__2()._y);
      this._this$0_341._quickChart.markAsDirty_4();
    }};
  _no_name_provided__545.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__545.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__546($module) {
    this._$module_6 = $module;
  }
  _no_name_provided__546.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$module_6._get_location__2()._y);
    $this$spinner._set_step__0(100.0);
    $this$spinner._set_label__2(i18n('Y (mm)'));
  };
  _no_name_provided__546.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__546.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__547($layout, $module, this$0) {
    this._$layout_0 = $layout;
    this._$module_7 = $module;
    this._this$0_342 = this$0;
  }
  _no_name_provided__547.prototype.invoke_1077 = function (newValue) {
    if (!(newValue == null)) {
      this._$layout_0._location_0 = new Coordinates(this._$module_7._get_location__2()._x, numberToDouble(newValue));
      this._this$0_342._quickChart.markAsDirty_4();
    }};
  _no_name_provided__547.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__547.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__548() {
  }
  _no_name_provided__548.prototype.invoke_1055 = function (moduleOrientation) {
    return moduleOrientation._get_name__47();
  };
  _no_name_provided__548.prototype.invoke_1082 = function (p1) {
    return this.invoke_1055(p1 instanceof ModuleOrientation ? p1 : THROW_CCE());
  };
  _no_name_provided__548.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__549() {
  }
  _no_name_provided__549.prototype.invoke_1057 = function (s) {
    return valueOf(s);
  };
  _no_name_provided__549.prototype.invoke_1082 = function (p1) {
    return this.invoke_1057((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__549.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__550($layout) {
    this._$layout_1 = $layout;
  }
  _no_name_provided__550.prototype.invoke_1059 = function ($this$genericRadioGroup) {
    $this$genericRadioGroup._set_label__2(i18n('Module orientation'));
    $this$genericRadioGroup._set_value__0(this._$layout_1._orientation_0);
  };
  _no_name_provided__550.prototype.invoke_1082 = function (p1) {
    this.invoke_1059(p1 instanceof GenericRadioGroup ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__550.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__551($layout, this$0) {
    this._$layout_2 = $layout;
    this._this$0_343 = this$0;
  }
  _no_name_provided__551.prototype.invoke_1061 = function (it) {
    var tmp0_safe_receiver = it;
    if (tmp0_safe_receiver == null)
      null;
    else {
      this._$layout_2._orientation_0 = tmp0_safe_receiver;
      this._this$0_343._quickChart.markAsDirty_4();
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__551.prototype.invoke_1082 = function (p1) {
    this.invoke_1061((p1 == null ? true : p1 instanceof ModuleOrientation) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__551.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__552(this$0, $module) {
    this._this$0_344 = this$0;
    this._$module_8 = $module;
  }
  _no_name_provided__552.prototype.invoke_1024 = function ($this$button) {
    $this$button._set_title__30(i18n('Delete'));
    $this$button.enableTooltip$default(null, 1, null);
    Unit_getInstance();
    $this$button.onClick(_no_name_provided_$factory_529(this._this$0_344, this._$module_8));
    Unit_getInstance();
  };
  _no_name_provided__552.prototype.invoke_1082 = function (p1) {
    this.invoke_1024(p1 instanceof Button ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__552.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__553(this$0, $module, $index) {
    this._this$0_345 = this$0;
    this._$module_9 = $module;
    this._$index_0 = $index;
  }
  _no_name_provided__553.prototype.invoke_1043 = function ($this$div) {
    var tmp0_onClick_0 = _no_name_provided_$factory_514(this._this$0_345, this._$module_9);
    $this$div.setEventListener_30(_no_name_provided_$factory_515(tmp0_onClick_0));
    Unit_getInstance();
    $this$div._set_cursor__31(Cursor_POINTER_getInstance());
    addCssClassIf($this$div, this._this$0_345._uiModel._selectedModule, 'text-primary', _no_name_provided_$factory_516(this._$module_9));
    h6$default($this$div, null, false, null, null, 'mb-0', _no_name_provided_$factory_517(this._$index_0), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__553.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__553.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__554(this$0, $module, $layout) {
    this._this$0_346 = this$0;
    this._$module_10 = $module;
    this._$layout_3 = $layout;
  }
  _no_name_provided__554.prototype.invoke_1043 = function ($this$div) {
    this._this$0_346._uiModel._selectedModule.subscribe_20(_no_name_provided_$factory_519(this._$module_10, $this$div));
    Unit_getInstance();
    var tmp = spinner$default_0($this$div, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_520(this._$module_10), 2047, null);
    tmp.subscribe_18(_no_name_provided_$factory_521(this._$layout_3, this._$module_10, this._this$0_346));
    Unit_getInstance();
    var tmp_0 = spinner$default_0($this$div, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_522(this._$module_10), 2047, null);
    tmp_0.subscribe_18(_no_name_provided_$factory_523(this._$layout_3, this._$module_10, this._this$0_346));
    Unit_getInstance();
    var tmp_1 = listOf([to(ModuleOrientation_Vertical_getInstance(), i18n('Vertical')), to(ModuleOrientation_Horizontal_getInstance(), i18n('Horizontal'))]);
    var tmp_2 = _no_name_provided_$factory_524();
    var tmp_3 = _no_name_provided_$factory_525();
    var tmp_4 = genericRadioGroup$default($this$div, tmp_1, null, null, false, null, false, tmp_2, tmp_3, _no_name_provided_$factory_526(this._$layout_3), 62, null);
    tmp_4.subscribe_2(_no_name_provided_$factory_527(this._$layout_3, this._this$0_346));
    Unit_getInstance();
    var tmp_5 = ButtonStyle_WARNING_getInstance();
    button$default($this$div, '', 'fas fa-trash', tmp_5, null, false, null, false, null, null, _no_name_provided_$factory_528(this._this$0_346, this._$module_10), 504, null);
    Unit_getInstance();
  };
  _no_name_provided__554.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__554.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function addUnusableConfigCard(_this_, $this, unusableArea) {
    var tmp = setOf('card');
    div$default(_this_, null, false, null, tmp, null, _no_name_provided_$factory_426($this, unusableArea), 23, null);
    Unit_getInstance();
  }
  function addManualModuleConfigCard(_this_, $this, module_0, index) {
    var tmp = module_0._layout;
    var tmp0_require_0 = tmp instanceof AbsoluteLayout;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Expected AbsoluteLayout but was <' + module_0._layout + '> for ' + module_0;
      throw IllegalArgumentException_init_$Create$(toString_2(message_1));
    }var tmp_0 = module_0._layout;
    var layout = tmp_0 instanceof AbsoluteLayout ? tmp_0 : THROW_CCE();
    var tmp_1 = setOf('card');
    div$default(_this_, null, false, null, tmp_1, null, _no_name_provided_$factory_427($this, module_0, index, layout), 23, null);
    Unit_getInstance();
  }
  function _no_name_provided__555(this$0) {
    this._this$0_347 = this$0;
  }
  _no_name_provided__555.prototype.invoke_1030 = function ($this$root) {
    $this$root.addCssClass_31('h-100');
    Unit_getInstance();
    $this$root.addCssClass_31('d-flex');
    Unit_getInstance();
    $this$root.addCssClass_31('flex-column');
    Unit_getInstance();
    header$default($this$root, null, false, null, null, 'container', _no_name_provided_$factory_428(), 15, null);
    Unit_getInstance();
    main$default($this$root, null, false, null, null, 'container-fluid', _no_name_provided_$factory_429(this._this$0_347), 15, null);
    Unit_getInstance();
    footer$default($this$root, null, false, null, null, null, _no_name_provided_$factory_430(), 31, null);
    Unit_getInstance();
  };
  _no_name_provided__555.prototype.invoke_1082 = function (p1) {
    this.invoke_1030(p1 instanceof Root ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__555.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__556(this$0, $unusableArea) {
    this._this$0_348 = this$0;
    this._$unusableArea_18 = $unusableArea;
  }
  _no_name_provided__556.prototype.invoke_1043 = function ($this$div) {
    var tmp = setOf('card-header');
    div$default($this$div, null, false, null, tmp, null, _no_name_provided_$factory_490(this._this$0_348, this._$unusableArea_18), 23, null);
    Unit_getInstance();
    div$default($this$div, null, false, null, null, 'card-body', _no_name_provided_$factory_491(this._this$0_348, this._$unusableArea_18), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__556.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__556.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__557(this$0, $module, $index, $layout) {
    this._this$0_349 = this$0;
    this._$module_11 = $module;
    this._$index_1 = $index;
    this._$layout_4 = $layout;
  }
  _no_name_provided__557.prototype.invoke_1043 = function ($this$div) {
    var tmp = setOf('card-header');
    div$default($this$div, null, false, null, tmp, null, _no_name_provided_$factory_512(this._this$0_349, this._$module_11, this._$index_1), 23, null);
    Unit_getInstance();
    div$default($this$div, null, false, null, null, 'card-body', _no_name_provided_$factory_513(this._this$0_349, this._$module_11, this._$layout_4), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__557.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__557.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function App() {
    Application.call(this);
    require('css/lizergy.css');
    QuickChartPlatform_getInstance().init_17(LizergyDesign_getInstance());
    this._uiModel = new UiModel();
    var tmp = this;
    var tmp_0 = Data_init_$Create$_0(this._uiModel._roofPlanningModel_0, null, 2, null);
    tmp._gestalt = RoofPlanningGestalt_init_$Create$(tmp_0, null, 2, null);
    var tmp_1 = this;
    var tmp0_also_0 = new QuickChartBuilderJS('Roof Planner');
    this._gestalt.configure_2(tmp0_also_0);
    tmp_1._quickChart = tmp0_also_0.build_1();
  }
  App.prototype.start_3 = function (state) {
    I18n_getInstance()._manager = new DefaultI18nManager(mapOf([to('en', require('i18n/messages-en.json')), to('de', require('i18n/messages-de.json'))]));
    I18n_getInstance()._set_language_('de');
    var tmp = ContainerType_NONE_getInstance();
    root(this, 'rootContainer', tmp, false, _no_name_provided_$factory_425(this));
    Unit_getInstance();
  };
  App.$metadata$ = {
    simpleName: 'App',
    kind: 'class',
    interfaces: []
  };
  function main_0() {
    startApplication(_no_name_provided_$factory_531(), module.hot);
  }
  function _no_name_provided__558() {
  }
  _no_name_provided__558.prototype.invoke_1038 = function () {
    return new App();
  };
  _no_name_provided__558.prototype._get_name__47 = function () {
    return '<init>';
  };
  _no_name_provided__558.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_425(this$0) {
    var i = new _no_name_provided__555(this$0);
    return function (p1) {
      i.invoke_1030(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_426(this$0, $unusableArea) {
    var i = new _no_name_provided__556(this$0, $unusableArea);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_427(this$0, $module, $index, $layout) {
    var i = new _no_name_provided__557(this$0, $module, $index, $layout);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_428() {
    var i = new _no_name_provided__511();
    return function (p1) {
      i.invoke_942(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_429(this$0) {
    var i = new _no_name_provided__512(this$0);
    return function (p1) {
      i.invoke_944(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_430() {
    var i = new _no_name_provided__513();
    return function (p1) {
      i.invoke_946(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_431() {
    var i = new _no_name_provided__453();
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_432() {
    var i = new _no_name_provided__452();
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_433(this$0) {
    var i = new _no_name_provided__510(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_434(this$0) {
    var i = new _no_name_provided__507(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_435(this$0) {
    var i = new _no_name_provided__508(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_436(this$0) {
    var i = new _no_name_provided__509(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_437(this$0) {
    var i = new _no_name_provided__485(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_438(this$0) {
    var i = new _no_name_provided__486(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_439(this$0) {
    var i = new _no_name_provided__487(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_440() {
    var i = new _no_name_provided__463();
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_441(this$0) {
    var i = new _no_name_provided__464(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_442() {
    var i = new _no_name_provided__455();
    return function (p1, p2) {
      i.invoke_830(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_443(this$0) {
    var i = new _no_name_provided__456(this$0);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_444() {
    var i = new _no_name_provided__457();
    return function (p1, p2) {
      i.invoke_840(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_445(this$0) {
    var i = new _no_name_provided__458(this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_446() {
    var i = new _no_name_provided__459();
    return function (p1, p2) {
      i.invoke_844(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_447() {
    var i = new _no_name_provided__460();
    return function (p1, p2) {
      i.invoke_840(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_448(this$0) {
    var i = new _no_name_provided__461(this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_449() {
    var i = new _no_name_provided__462();
    return function (p1, p2) {
      i.invoke_844(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_450(this$0) {
    var i = new _no_name_provided__454(this$0);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_451() {
    var i = new _no_name_provided__471();
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_452(this$0) {
    var i = new _no_name_provided__472(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_453() {
    var i = new _no_name_provided__467();
    return function (p1, p2) {
      i.invoke_854(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_454(this$0) {
    var i = new _no_name_provided__468(this$0);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_455() {
    var i = new _no_name_provided__469();
    return function (p1, p2) {
      i.invoke_858(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_456(this$0) {
    var i = new _no_name_provided__470(this$0);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_457(this$0) {
    var i = new _no_name_provided__465(this$0);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_458(this$0) {
    var i = new _no_name_provided__466(this$0);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_459() {
    var i = new _no_name_provided__483();
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_460(this$0) {
    var i = new _no_name_provided__484(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_461(this$0) {
    var i = new _no_name_provided__478(this$0);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_462(this$0) {
    var i = new _no_name_provided__479(this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_463(this$0) {
    var i = new _no_name_provided__480(this$0);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_464(this$0) {
    var i = new _no_name_provided__481(this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_465(this$0) {
    var i = new _no_name_provided__482(this$0);
    return function (p1) {
      i.invoke_884(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_466() {
    var i = new _no_name_provided__474();
    return function (p1) {
      i.invoke_1024(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_467(this$0) {
    var i = new _no_name_provided__475(this$0);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_468() {
    var i = new _no_name_provided__476();
    return function (p1) {
      i.invoke_1024(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_469(this$0) {
    var i = new _no_name_provided__477(this$0);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_470(this$0) {
    var i = new _no_name_provided__473(this$0);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_471(this$0, $this_div) {
    var i = new _no_name_provided__488(this$0, $this_div);
    return function (p1) {
      i.invoke_896(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_472(this$0) {
    var i = new _no_name_provided__502(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_473(this$0) {
    var i = new _no_name_provided__503(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_474(this$0) {
    var i = new _no_name_provided__504(this$0);
    return function (p1, p2) {
      i.invoke_928(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_475(this$0) {
    var i = new _no_name_provided__505(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_476(this$0) {
    var i = new _no_name_provided__506(this$0);
    return function (p1, p2) {
      i.invoke_932(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_477() {
    var i = new _no_name_provided__490();
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_478(this$0) {
    var i = new _no_name_provided__491(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_479() {
    var i = new _no_name_provided__489();
    return function (p1, p2) {
      i.invoke_898(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_480(this$0) {
    var i = new _no_name_provided__496(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_481(this$0) {
    var i = new _no_name_provided__495(this$0);
    return function (p1) {
      i.invoke_920(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_482() {
    var i = new _no_name_provided__493();
    return function (p1) {
      i.invoke_916(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_483(this$0) {
    var i = new _no_name_provided__494(this$0);
    return function (p1) {
      i.invoke_1024(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_484(this$0) {
    var i = new _no_name_provided__492(this$0);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_485(this$0) {
    var i = new _no_name_provided__501(this$0);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_486(this$0) {
    var i = new _no_name_provided__500(this$0);
    return function (p1) {
      i.invoke_920(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_487() {
    var i = new _no_name_provided__498();
    return function (p1) {
      i.invoke_916(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_488(this$0) {
    var i = new _no_name_provided__499(this$0);
    return function (p1) {
      i.invoke_1024(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_489(this$0) {
    var i = new _no_name_provided__497(this$0);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_490(this$0, $unusableArea) {
    var i = new _no_name_provided__534(this$0, $unusableArea);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_491(this$0, $unusableArea) {
    var i = new _no_name_provided__535(this$0, $unusableArea);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_492(this$0, $unusableArea) {
    var i = new _no_name_provided__518(this$0, $unusableArea);
    return function (p1, p2) {
      i.invoke_994(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_493($tmp0_onClick_0) {
    var i = new _no_name_provided__519($tmp0_onClick_0);
    return function (p1) {
      i.invoke_996(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_494($unusableArea) {
    var i = new _no_name_provided__520($unusableArea);
    return function (p1) {
      return i.invoke_960(p1);
    };
  }
  function _no_name_provided_$factory_495($unusableArea, this$0) {
    var i = new _no_name_provided__521($unusableArea, this$0);
    return function (p1) {
      i.invoke_1000(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_496($tmp0_onClick_0, $this_setEventListener) {
    var i = new _no_name_provided__514($tmp0_onClick_0, $this_setEventListener);
    return function (p1) {
      i.invoke_992(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_497() {
    var i = new _no_name_provided__516();
    return function (p1, p2) {
      i.invoke_952(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_498(this$0, $unusableArea) {
    var i = new _no_name_provided__517(this$0, $unusableArea);
    return function (p1) {
      i.invoke_1024(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_499(this$0, $unusableArea) {
    var i = new _no_name_provided__515(this$0, $unusableArea);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_500($unusableArea, $this_div) {
    var i = new _no_name_provided__524($unusableArea, $this_div);
    return function (p1) {
      i.invoke_968(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_501($unusableArea) {
    var i = new _no_name_provided__525($unusableArea);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_502($unusableArea, this$0) {
    var i = new _no_name_provided__526($unusableArea, this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_503($unusableArea) {
    var i = new _no_name_provided__527($unusableArea);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_504($unusableArea, this$0) {
    var i = new _no_name_provided__528($unusableArea, this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_505($unusableArea) {
    var i = new _no_name_provided__529($unusableArea);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_506($unusableArea, this$0) {
    var i = new _no_name_provided__530($unusableArea, this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_507($unusableArea) {
    var i = new _no_name_provided__531($unusableArea);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_508($unusableArea, this$0) {
    var i = new _no_name_provided__532($unusableArea, this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_509(this$0, $unusableArea) {
    var i = new _no_name_provided__533(this$0, $unusableArea);
    return function (p1) {
      i.invoke_1024(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_510(this$0, $unusableArea) {
    var i = new _no_name_provided__523(this$0, $unusableArea);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_511(this$0, $unusableArea) {
    var i = new _no_name_provided__522(this$0, $unusableArea);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_512(this$0, $module, $index) {
    var i = new _no_name_provided__553(this$0, $module, $index);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_513(this$0, $module, $layout) {
    var i = new _no_name_provided__554(this$0, $module, $layout);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_514(this$0, $module) {
    var i = new _no_name_provided__537(this$0, $module);
    return function (p1, p2) {
      i.invoke_994(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_515($tmp0_onClick_0) {
    var i = new _no_name_provided__538($tmp0_onClick_0);
    return function (p1) {
      i.invoke_996(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_516($module) {
    var i = new _no_name_provided__539($module);
    return function (p1) {
      return i.invoke_998(p1);
    };
  }
  function _no_name_provided_$factory_517($index) {
    var i = new _no_name_provided__540($index);
    return function (p1) {
      i.invoke_1000(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_518($tmp0_onClick_0, $this_setEventListener) {
    var i = new _no_name_provided__536($tmp0_onClick_0, $this_setEventListener);
    return function (p1) {
      i.invoke_992(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_519($module, $this_div) {
    var i = new _no_name_provided__543($module, $this_div);
    return function (p1) {
      i.invoke_1006(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_520($module) {
    var i = new _no_name_provided__544($module);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_521($layout, $module, this$0) {
    var i = new _no_name_provided__545($layout, $module, this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_522($module) {
    var i = new _no_name_provided__546($module);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_523($layout, $module, this$0) {
    var i = new _no_name_provided__547($layout, $module, this$0);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_524() {
    var i = new _no_name_provided__548();
    return function (p1) {
      return i.invoke_1055(p1);
    };
  }
  function _no_name_provided_$factory_525() {
    var i = new _no_name_provided__549();
    return function (p1) {
      return i.invoke_1057(p1);
    };
  }
  function _no_name_provided_$factory_526($layout) {
    var i = new _no_name_provided__550($layout);
    return function (p1) {
      i.invoke_1059(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_527($layout, this$0) {
    var i = new _no_name_provided__551($layout, this$0);
    return function (p1) {
      i.invoke_1061(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_528(this$0, $module) {
    var i = new _no_name_provided__552(this$0, $module);
    return function (p1) {
      i.invoke_1024(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_529(this$0, $module) {
    var i = new _no_name_provided__542(this$0, $module);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_530(this$0, $module) {
    var i = new _no_name_provided__541(this$0, $module);
    return function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_531() {
    var i = new _no_name_provided__558();
    var l = function () {
      return i.invoke_1038();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function asObservableState(_this_) {
    var observableObject = _this_;
    return new _no_name_provided__560(_this_, observableObject);
  }
  function _no_name_provided__559($boundThis) {
    this._$boundThis_35 = $boundThis;
  }
  _no_name_provided__559.prototype.invoke_1037 = function () {
    return this._$boundThis_35.dispose_46();
  };
  _no_name_provided__559.prototype.invoke_1038 = function () {
    this.invoke_1037();
    return Unit_getInstance();
  };
  _no_name_provided__559.prototype._get_name__47 = function () {
    return 'dispose';
  };
  _no_name_provided__559.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__560($this_asObservableState, $observableObject) {
    this._$this_asObservableState = $this_asObservableState;
    this._$observableObject = $observableObject;
  }
  _no_name_provided__560.prototype.subscribe_20 = function (observer) {
    return _no_name_provided_$factory_532(this._$observableObject.consumeImmediately_3(observer));
  };
  _no_name_provided__560.prototype.subscribe_21 = function (observer) {
    return this.subscribe_20(observer);
  };
  _no_name_provided__560.$metadata$ = {
    kind: 'class',
    interfaces: [ObservableState]
  };
  function _no_name_provided_$factory_532($boundThis) {
    var i = new _no_name_provided__559($boundThis);
    var l = function () {
      i.invoke_1037();
      return Unit_getInstance();
    };
    l.callableName = i._get_name__47();
    return l;
  }
  function Companion_74() {
    Companion_instance_73 = this;
    this._counter_8 = 0;
  }
  Companion_74.prototype.generateId = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._counter_8;
    tmp0_this._counter_8 = tmp1 + 1 | 0;
    Unit_getInstance();
    return '' + 'kv_form_text_' + Companion_getInstance_73()._counter_8;
  };
  Companion_74.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_73;
  function Companion_getInstance_73() {
    if (Companion_instance_73 == null)
      new Companion_74();
    return Companion_instance_73;
  }
  function TextWithButton(button_0, textInput_1) {
    Companion_getInstance_73();
    this._button = button_0;
    this._textInput = textInput_1;
  }
  TextWithButton.$metadata$ = {
    simpleName: 'TextWithButton',
    kind: 'class',
    interfaces: []
  };
  function textWithButton(_this_, state, label, init) {
    var id = Companion_getInstance_73().generateId();
    var button_0 = {_v: null};
    var textInput_1 = {_v: null};
    div$default(_this_, null, false, null, null, 'form-group', _no_name_provided_$factory_533(id, label, textInput_1, state, init, button_0), 15, null);
    Unit_getInstance();
    var tmp;
    if (button_0._v == null) {
      throwUninitializedPropertyAccessException('button');
    } else {
      tmp = button_0._v;
    }
    var tmp_0 = tmp;
    var tmp_1;
    if (textInput_1._v == null) {
      throwUninitializedPropertyAccessException('textInput');
    } else {
      tmp_1 = textInput_1._v;
    }
    return new TextWithButton(tmp_0, tmp_1);
  }
  function _no_name_provided__561($button) {
    this._$button = $button;
  }
  _no_name_provided__561.prototype.invoke_1043 = function ($this$div) {
    var tmp = ButtonStyle_SECONDARY_getInstance();
    this._$button._v = button$default($this$div, '', 'fas fa-pen', tmp, null, false, null, false, null, null, null, 1016, null);
  };
  _no_name_provided__561.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__561.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__562($textInput, $state, $init, $id, $button) {
    this._$textInput_0 = $textInput;
    this._$state_0 = $state;
    this._$init = $init;
    this._$id = $id;
    this._$button_0 = $button;
  }
  _no_name_provided__562.prototype.invoke_1043 = function ($this$div) {
    var tmp0_apply_0 = textInput$default($this$div, this._$state_0, null, null, null, null, this._$init, 30, null);
    tmp0_apply_0._set_id__30(this._$id);
    this._$textInput_0._v = tmp0_apply_0;
    div$default($this$div, null, false, null, null, 'input-group-append', _no_name_provided_$factory_535(this._$button_0), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__562.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__562.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__563($id, $label, $textInput, $state, $init, $button) {
    this._$id_0 = $id;
    this._$label = $label;
    this._$textInput_1 = $textInput;
    this._$state_1 = $state;
    this._$init_0 = $init;
    this._$button_1 = $button;
  }
  _no_name_provided__563.prototype.invoke_1043 = function ($this$div) {
    fieldLabel$default($this$div, this._$id_0, this._$label, false, null, null, null, 60, null);
    Unit_getInstance();
    div$default($this$div, null, false, null, null, 'input-group', _no_name_provided_$factory_534(this._$textInput_1, this._$state_1, this._$init_0, this._$id_0, this._$button_1), 15, null);
    Unit_getInstance();
  };
  _no_name_provided__563.prototype.invoke_1082 = function (p1) {
    this.invoke_1043(p1 instanceof Div ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__563.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_533($id, $label, $textInput, $state, $init, $button) {
    var i = new _no_name_provided__563($id, $label, $textInput, $state, $init, $button);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_534($textInput, $state, $init, $id, $button) {
    var i = new _no_name_provided__562($textInput, $state, $init, $id, $button);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_535($button) {
    var i = new _no_name_provided__561($button);
    return function (p1) {
      i.invoke_1043(p1);
      return Unit_getInstance();
    };
  }
  function UiModel() {
    var tmp = this;
    var tmp0_also_0 = new RoofPlanningModel();
    tmp0_also_0.updateGridModules_0();
    tmp._roofPlanningModel_0 = tmp0_also_0;
    this._roofSizeObservable = asObservableState(this._roofPlanningModel_0._roof._sizeProperty_0);
    this._moduleSizeObservable = asObservableState(this._roofPlanningModel_0._modulesSizeProperty);
    this._moduleOrientationObservable = asObservableState(this._roofPlanningModel_0._modulesOrientationProperty);
    this._roofInclinationObservable = asObservableState(this._roofPlanningModel_0._roof._inclinationProperty);
    this._roofOrientationObservable = asObservableState(this._roofPlanningModel_0._roof._orientationProperty);
    this._modulesObservable = asObservableState(this._roofPlanningModel_0._modules._modulesProperty);
    this._modulesWithAbsoluteLayoutObservable = asObservableState(this._roofPlanningModel_0._modules._modulesWithAbsoluteLayoutProperty);
    this._modulesWithGridLayoutObservable = asObservableState(this._roofPlanningModel_0._modules._modulesWithGridLayoutProperty);
    this._unusableAreasObservable = asObservableState(this._roofPlanningModel_0._unusableAreas._unusableAreasProperty);
    this._selectedUnusableArea = new ObservableValue(null);
    this._selectedModule = new ObservableValue(null);
  }
  UiModel.prototype.updateGridModules_0 = function () {
    this._roofPlanningModel_0.updateGridModules_0();
  };
  UiModel.$metadata$ = {
    simpleName: 'UiModel',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__564(this$0) {
    this._this$0_350 = this$0;
  }
  _no_name_provided__564.prototype.invoke_1045 = function (it) {
    var tmp0_safe_receiver_9 = this._this$0_350._initialFocusComponent;
    if (tmp0_safe_receiver_9 == null)
      null;
    else {
      tmp0_safe_receiver_9.focus_32();
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__564.prototype.invoke_1082 = function (p1) {
    this.invoke_1045(p1 instanceof KvEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__564.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__565(this$0, $tmp0_also_0, $okCallback) {
    this._this$0_351 = this$0;
    this._$tmp0_also_0_1 = $tmp0_also_0;
    this._$okCallback = $okCallback;
  }
  _no_name_provided__565.prototype.invoke_1047 = function (it) {
    if (it.ctrlKey ? it.keyCode === 13 : false) {
      createModalDialog$closeOk_2(this._this$0_351, this._$tmp0_also_0_1, this._$okCallback);
    }};
  _no_name_provided__565.prototype.invoke_1082 = function (p1) {
    this.invoke_1047(p1 instanceof KeyboardEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__565.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function createModalDialog$closeOk_2(this$0, $tmp0_also_0, $okCallback) {
    this$0.commitChanges_7(this$0._data_5);
    $tmp0_also_0.hide_26();
    Unit_getInstance();
    var tmp0_safe_receiver_3 = $okCallback;
    if (tmp0_safe_receiver_3 == null)
      null;
    else
      tmp0_safe_receiver_3();
    Unit_getInstance();
  }
  function _no_name_provided__566($tmp0_also_0) {
    this._$tmp0_also_0_2 = $tmp0_also_0;
  }
  _no_name_provided__566.prototype.invoke_1051 = function ($this$onClick, it) {
    this._$tmp0_also_0_2.hide_26();
    Unit_getInstance();
  };
  _no_name_provided__566.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__566.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__567(this$0, $tmp0_also_0, $okCallback) {
    this._this$0_352 = this$0;
    this._$tmp0_also_0_3 = $tmp0_also_0;
    this._$okCallback_0 = $okCallback;
  }
  _no_name_provided__567.prototype.invoke_1051 = function ($this$onClick, it) {
    createModalDialog$closeOk_2(this._this$0_352, this._$tmp0_also_0_3, this._$okCallback_0);
  };
  _no_name_provided__567.prototype.invoke_1052 = function (p1, p2) {
    var tmp = p1 instanceof Button ? p1 : THROW_CCE();
    this.invoke_1051(tmp, p2 instanceof MouseEvent ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__567.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__568(this$0, $tmp0_also_0, $okCallback) {
    this._this$0_353 = this$0;
    this._$tmp0_also_0_4 = $tmp0_also_0;
    this._$okCallback_1 = $okCallback;
  }
  _no_name_provided__568.prototype.invoke_1053 = function ($this$onEvent) {
    $this$onEvent.shownBsModal = _no_name_provided_$factory_539(this._this$0_353);
    $this$onEvent.keypress = _no_name_provided_$factory_540(this._this$0_353, this._$tmp0_also_0_4, this._$okCallback_1);
  };
  _no_name_provided__568.prototype.invoke_1082 = function (p1) {
    this.invoke_1053((!(p1 == null) ? isObject(p1) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__568.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractEditDialog(caption, data) {
    this._caption = caption;
    this._data_5 = data;
    this._initialFocusComponent = null;
  }
  AbstractEditDialog.prototype.createModalDialog_3 = function (okCallback) {
    var tmp0_caption = this._caption;
    var tmp1_size = ModalSize_SMALL_getInstance();
    var tmp0_also_0 = Modal_init_$Create$(tmp0_caption, true, tmp1_size, true, false, false, true, null, null, 432, null);
    this.fill_14(tmp0_also_0, this._data_5);
    var tmp = i18n('Cancel');
    var tmp_0 = ButtonStyle_SECONDARY_getInstance();
    var tmp_1 = Button_init_$Create$(tmp, null, tmp_0, null, false, null, false, null, 250, null);
    tmp0_also_0.addButton_0(tmp_1.onClick(_no_name_provided_$factory_536(tmp0_also_0)));
    Unit_getInstance();
    var tmp_2 = i18n('Ok');
    var tmp_3 = ButtonStyle_PRIMARY_getInstance();
    var tmp_4 = Button_init_$Create$(tmp_2, null, tmp_3, null, false, null, false, null, 250, null);
    tmp0_also_0.addButton_0(tmp_4.onClick(_no_name_provided_$factory_537(this, tmp0_also_0, okCallback)));
    Unit_getInstance();
    var tmp0_onEvent_0_6 = _no_name_provided_$factory_538(this, tmp0_also_0, okCallback);
    tmp0_also_0.setEventListener_30(tmp0_onEvent_0_6);
    Unit_getInstance();
    return tmp0_also_0;
  };
  AbstractEditDialog.prototype.show_32 = function (okCallback) {
    return this.createModalDialog_3(okCallback).show_27();
  };
  AbstractEditDialog.prototype.show$default_4 = function (okCallback, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      okCallback = null;
    return this.show_32(okCallback);
  };
  AbstractEditDialog.prototype.initialFocus_3 = function (_this__0) {
    this._initialFocusComponent = _this__0;
  };
  AbstractEditDialog.$metadata$ = {
    simpleName: 'AbstractEditDialog',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_536($tmp0_also_0) {
    var i = new _no_name_provided__566($tmp0_also_0);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_537(this$0, $tmp0_also_0, $okCallback) {
    var i = new _no_name_provided__567(this$0, $tmp0_also_0, $okCallback);
    return function (p1, p2) {
      i.invoke_1051(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_538(this$0, $tmp0_also_0, $okCallback) {
    var i = new _no_name_provided__568(this$0, $tmp0_also_0, $okCallback);
    return function (p1) {
      i.invoke_1053(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_539(this$0) {
    var i = new _no_name_provided__564(this$0);
    return function (p1) {
      i.invoke_1045(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_540(this$0, $tmp0_also_0, $okCallback) {
    var i = new _no_name_provided__565(this$0, $tmp0_also_0, $okCallback);
    return function (p1) {
      i.invoke_1047(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__569() {
  }
  _no_name_provided__569.prototype.invoke_1055 = function (moduleOrientation) {
    return moduleOrientation._get_name__47();
  };
  _no_name_provided__569.prototype.invoke_1082 = function (p1) {
    return this.invoke_1055(p1 instanceof ModuleOrientation ? p1 : THROW_CCE());
  };
  _no_name_provided__569.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__570() {
  }
  _no_name_provided__570.prototype.invoke_1057 = function (s) {
    return valueOf(s);
  };
  _no_name_provided__570.prototype.invoke_1082 = function (p1) {
    return this.invoke_1057((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__570.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__571($data, this$0) {
    this._$data = $data;
    this._this$0_354 = this$0;
  }
  _no_name_provided__571.prototype.invoke_1059 = function ($this$genericRadioGroup) {
    $this$genericRadioGroup._set_label__2(i18n('Module orientation'));
    $this$genericRadioGroup._set_value__0(this._$data._orientation_1);
    this._this$0_354.initialFocus_3($this$genericRadioGroup);
  };
  _no_name_provided__571.prototype.invoke_1082 = function (p1) {
    this.invoke_1059(p1 instanceof GenericRadioGroup ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__571.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__572($data) {
    this._$data_0 = $data;
  }
  _no_name_provided__572.prototype.invoke_1061 = function (it) {
    var tmp0_safe_receiver = it;
    if (tmp0_safe_receiver == null)
      null;
    else {
      this._$data_0._orientation_1 = tmp0_safe_receiver;
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__572.prototype.invoke_1082 = function (p1) {
    this.invoke_1061((p1 == null ? true : p1 instanceof ModuleOrientation) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__572.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ConfigureModuleOrientationDialog(orientationProperty) {
    var tmp = i18n('Module orientation');
    var tmp0_apply_0 = new ModuleOrientationDialogData();
    tmp0_apply_0._orientation_1 = orientationProperty._get_value__18();
    AbstractEditDialog.call(this, tmp, tmp0_apply_0);
    this._orientationProperty_0 = orientationProperty;
  }
  ConfigureModuleOrientationDialog.prototype.commitChanges_0 = function (data) {
    this._orientationProperty_0._set_value__12(data._orientation_1);
  };
  ConfigureModuleOrientationDialog.prototype.commitChanges_7 = function (data) {
    return this.commitChanges_0(data instanceof ModuleOrientationDialogData ? data : THROW_CCE());
  };
  ConfigureModuleOrientationDialog.prototype.fill_7 = function (_this__0, data) {
    var tmp = listOf([to(ModuleOrientation_Vertical_getInstance(), i18n('Vertical')), to(ModuleOrientation_Horizontal_getInstance(), i18n('Horizontal'))]);
    var tmp_0 = _no_name_provided_$factory_541();
    var tmp_1 = _no_name_provided_$factory_542();
    var tmp_2 = genericRadioGroup$default(_this__0, tmp, null, null, false, null, false, tmp_0, tmp_1, _no_name_provided_$factory_543(data, this), 62, null);
    tmp_2.subscribe_2(_no_name_provided_$factory_544(data));
    Unit_getInstance();
  };
  ConfigureModuleOrientationDialog.prototype.fill_14 = function (_this__0, data) {
    return this.fill_7(_this__0, data instanceof ModuleOrientationDialogData ? data : THROW_CCE());
  };
  ConfigureModuleOrientationDialog.$metadata$ = {
    simpleName: 'ConfigureModuleOrientationDialog',
    kind: 'class',
    interfaces: []
  };
  function ModuleOrientationDialogData() {
    this._orientation_1 = ModuleOrientation_Vertical_getInstance();
  }
  ModuleOrientationDialogData.$metadata$ = {
    simpleName: 'ModuleOrientationDialogData',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_541() {
    var i = new _no_name_provided__569();
    return function (p1) {
      return i.invoke_1055(p1);
    };
  }
  function _no_name_provided_$factory_542() {
    var i = new _no_name_provided__570();
    return function (p1) {
      return i.invoke_1057(p1);
    };
  }
  function _no_name_provided_$factory_543($data, this$0) {
    var i = new _no_name_provided__571($data, this$0);
    return function (p1) {
      i.invoke_1059(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_544($data) {
    var i = new _no_name_provided__572($data);
    return function (p1) {
      i.invoke_1061(p1);
      return Unit_getInstance();
    };
  }
  function ConfigureModuleSizeDialog_init_$Init$(sizeProperty, minLength, maxLength, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      minLength = 500.0;
    if (!(($mask0 & 4) === 0))
      maxLength = 3000.0;
    ConfigureModuleSizeDialog.call($this, sizeProperty, minLength, maxLength);
    return $this;
  }
  function ConfigureModuleSizeDialog_init_$Create$(sizeProperty, minLength, maxLength, $mask0, $marker) {
    return ConfigureModuleSizeDialog_init_$Init$(sizeProperty, minLength, maxLength, $mask0, $marker, Object.create(ConfigureModuleSizeDialog.prototype));
  }
  function _no_name_provided__573($data) {
    this._$data_1 = $data;
  }
  _no_name_provided__573.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$data_1._longer_0);
  };
  _no_name_provided__573.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__573.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__574($tmp0_apply_0, this$0, $data) {
    this._$tmp0_apply_0_3 = $tmp0_apply_0;
    this._this$0_355 = this$0;
    this._$data_2 = $data;
  }
  _no_name_provided__574.prototype.invoke_1077 = function (newValue) {
    if (newValue == null) {
      invalid(this._$tmp0_apply_0_3, i18n('Not a number!'));
      return Unit_getInstance();
    }if (numberToDouble(newValue) < this._this$0_355._minLength) {
      invalid(this._$tmp0_apply_0_3, i18n('Value too small'));
      return Unit_getInstance();
    }if (numberToDouble(newValue) > this._this$0_355._maxLength) {
      invalid(this._$tmp0_apply_0_3, i18n('Value too large'));
      return Unit_getInstance();
    }valid(this._$tmp0_apply_0_3);
    this._$data_2._longer_0 = numberToInt(newValue);
  };
  _no_name_provided__574.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__574.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__575($data) {
    this._$data_3 = $data;
  }
  _no_name_provided__575.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$data_3._shorter_0);
  };
  _no_name_provided__575.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__575.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__576($tmp1_apply_0, this$0, $data) {
    this._$tmp1_apply_0 = $tmp1_apply_0;
    this._this$0_356 = this$0;
    this._$data_4 = $data;
  }
  _no_name_provided__576.prototype.invoke_1077 = function (newValue) {
    if (newValue == null) {
      invalid(this._$tmp1_apply_0, i18n('Not a number!'));
      return Unit_getInstance();
    }if (numberToDouble(newValue) < this._this$0_356._minLength) {
      invalid(this._$tmp1_apply_0, i18n('Value too small'));
      return Unit_getInstance();
    }if (numberToDouble(newValue) > this._this$0_356._maxLength) {
      invalid(this._$tmp1_apply_0, i18n('Value too large'));
      return Unit_getInstance();
    }valid(this._$tmp1_apply_0);
    this._$data_4._shorter_0 = numberToInt(newValue);
  };
  _no_name_provided__576.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__576.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ConfigureModuleSizeDialog(sizeProperty, minLength, maxLength) {
    var tmp = i18n('Module size');
    var tmp0_apply_0 = new ModuleSizeDialogData();
    tmp0_apply_0._longer_0 = sizeProperty._get_value__18()._longer;
    tmp0_apply_0._shorter_0 = sizeProperty._get_value__18()._shorter;
    AbstractEditDialog.call(this, tmp, tmp0_apply_0);
    this._sizeProperty_1 = sizeProperty;
    this._minLength = minLength;
    this._maxLength = maxLength;
    this._widthHeightFormatter = DecimalFormatsCache_getInstance().get_23(0, false);
  }
  ConfigureModuleSizeDialog.prototype.fill_9 = function (_this__0, data) {
    var tmp0_apply_0 = spinner$default_0(_this__0, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_545(data), 2047, null);
    tmp0_apply_0._set_label__2(i18n('Longer side (mm)'));
    tmp0_apply_0._set_step__0(100);
    tmp0_apply_0._set_max__0(this._maxLength);
    tmp0_apply_0._set_min__0(this._minLength);
    this.initialFocus_3(tmp0_apply_0);
    tmp0_apply_0.subscribe_18(_no_name_provided_$factory_546(tmp0_apply_0, this, data));
    Unit_getInstance();
    Unit_getInstance();
    var tmp1_apply_0 = spinner$default_0(_this__0, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_547(data), 2047, null);
    tmp1_apply_0._set_label__2(i18n('Shorter side (mm)'));
    tmp1_apply_0._set_step__0(100);
    tmp1_apply_0.subscribe_18(_no_name_provided_$factory_548(tmp1_apply_0, this, data));
    Unit_getInstance();
    Unit_getInstance();
  };
  ConfigureModuleSizeDialog.prototype.fill_14 = function (_this__0, data) {
    return this.fill_9(_this__0, data instanceof ModuleSizeDialogData ? data : THROW_CCE());
  };
  ConfigureModuleSizeDialog.prototype.commitChanges_2 = function (data) {
    this._sizeProperty_1._set_value__12(new ModuleSize(data._longer_0, data._shorter_0));
  };
  ConfigureModuleSizeDialog.prototype.commitChanges_7 = function (data) {
    return this.commitChanges_2(data instanceof ModuleSizeDialogData ? data : THROW_CCE());
  };
  ConfigureModuleSizeDialog.$metadata$ = {
    simpleName: 'ConfigureModuleSizeDialog',
    kind: 'class',
    interfaces: []
  };
  function ModuleSizeDialogData() {
    this._longer_0 = 0;
    this._shorter_0 = 0;
  }
  ModuleSizeDialogData.$metadata$ = {
    simpleName: 'ModuleSizeDialogData',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_545($data) {
    var i = new _no_name_provided__573($data);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_546($tmp0_apply_0, this$0, $data) {
    var i = new _no_name_provided__574($tmp0_apply_0, this$0, $data);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_547($data) {
    var i = new _no_name_provided__575($data);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_548($tmp1_apply_0, this$0, $data) {
    var i = new _no_name_provided__576($tmp1_apply_0, this$0, $data);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function ConfigureRoofSizeDialog_init_$Init$(sizeProperty, minWidthHeight, maxWidthHeight, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      minWidthHeight = 1000.0;
    if (!(($mask0 & 4) === 0))
      maxWidthHeight = 100000.0;
    ConfigureRoofSizeDialog.call($this, sizeProperty, minWidthHeight, maxWidthHeight);
    return $this;
  }
  function ConfigureRoofSizeDialog_init_$Create$(sizeProperty, minWidthHeight, maxWidthHeight, $mask0, $marker) {
    return ConfigureRoofSizeDialog_init_$Init$(sizeProperty, minWidthHeight, maxWidthHeight, $mask0, $marker, Object.create(ConfigureRoofSizeDialog.prototype));
  }
  function _no_name_provided__577($data) {
    this._$data_5 = $data;
  }
  _no_name_provided__577.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$data_5._width_2);
  };
  _no_name_provided__577.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__577.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__578($tmp0_apply_0, this$0, $data) {
    this._$tmp0_apply_0_4 = $tmp0_apply_0;
    this._this$0_357 = this$0;
    this._$data_6 = $data;
  }
  _no_name_provided__578.prototype.invoke_1077 = function (newValue) {
    if (newValue == null) {
      invalid(this._$tmp0_apply_0_4, i18n('Not a number!'));
      return Unit_getInstance();
    }if (numberToDouble(newValue) < this._this$0_357._minWidthHeight) {
      invalid(this._$tmp0_apply_0_4, i18n('Value too small'));
      return Unit_getInstance();
    }if (numberToDouble(newValue) > this._this$0_357._maxWidthHeight) {
      invalid(this._$tmp0_apply_0_4, i18n('Value too large'));
      return Unit_getInstance();
    }valid(this._$tmp0_apply_0_4);
    this._$data_6._width_2 = numberToDouble(newValue);
  };
  _no_name_provided__578.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__578.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__579($data) {
    this._$data_7 = $data;
  }
  _no_name_provided__579.prototype.invoke_1075 = function ($this$spinner) {
    $this$spinner._set_value__9(this._$data_7._height_1);
  };
  _no_name_provided__579.prototype.invoke_1082 = function (p1) {
    this.invoke_1075(p1 instanceof Spinner ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__579.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__580($tmp1_apply_0, this$0, $data) {
    this._$tmp1_apply_0_0 = $tmp1_apply_0;
    this._this$0_358 = this$0;
    this._$data_8 = $data;
  }
  _no_name_provided__580.prototype.invoke_1077 = function (newValue) {
    if (newValue == null) {
      invalid(this._$tmp1_apply_0_0, i18n('Not a number!'));
      return Unit_getInstance();
    }if (numberToDouble(newValue) < this._this$0_358._minWidthHeight) {
      invalid(this._$tmp1_apply_0_0, i18n('Value too small'));
      return Unit_getInstance();
    }if (numberToDouble(newValue) > this._this$0_358._maxWidthHeight) {
      invalid(this._$tmp1_apply_0_0, i18n('Value too large'));
      return Unit_getInstance();
    }valid(this._$tmp1_apply_0_0);
    this._$data_8._height_1 = numberToDouble(newValue);
  };
  _no_name_provided__580.prototype.invoke_1082 = function (p1) {
    this.invoke_1077((p1 == null ? true : isNumber(p1)) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__580.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ConfigureRoofSizeDialog(sizeProperty, minWidthHeight, maxWidthHeight) {
    var tmp = i18n('Roof size');
    var tmp0_apply_0 = new RoofSizeDialogData();
    tmp0_apply_0._width_2 = sizeProperty._get_value__18()._width_0;
    tmp0_apply_0._height_1 = sizeProperty._get_value__18()._height;
    AbstractEditDialog.call(this, tmp, tmp0_apply_0);
    this._sizeProperty_2 = sizeProperty;
    this._minWidthHeight = minWidthHeight;
    this._maxWidthHeight = maxWidthHeight;
    this._widthHeightFormatter_0 = DecimalFormatsCache_getInstance().get_23(0, false);
  }
  ConfigureRoofSizeDialog.prototype.fill_11 = function (_this__0, data) {
    var tmp0_apply_0 = spinner$default_0(_this__0, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_549(data), 2047, null);
    tmp0_apply_0._set_label__2('Width (mm)');
    tmp0_apply_0._set_step__0(100);
    tmp0_apply_0._set_max__0(this._maxWidthHeight);
    tmp0_apply_0._set_min__0(this._minWidthHeight);
    this.initialFocus_3(tmp0_apply_0);
    tmp0_apply_0.subscribe_18(_no_name_provided_$factory_550(tmp0_apply_0, this, data));
    Unit_getInstance();
    Unit_getInstance();
    var tmp1_apply_0 = spinner$default_0(_this__0, null, null, null, null, null, 0, null, null, null, null, false, _no_name_provided_$factory_551(data), 2047, null);
    tmp1_apply_0._set_label__2(i18n('Height (mm)'));
    tmp1_apply_0._set_step__0(100);
    tmp1_apply_0.subscribe_18(_no_name_provided_$factory_552(tmp1_apply_0, this, data));
    Unit_getInstance();
    Unit_getInstance();
  };
  ConfigureRoofSizeDialog.prototype.fill_14 = function (_this__0, data) {
    return this.fill_11(_this__0, data instanceof RoofSizeDialogData ? data : THROW_CCE());
  };
  ConfigureRoofSizeDialog.prototype.commitChanges_4 = function (data) {
    this._sizeProperty_2._set_value__12(new Size(data._width_2, data._height_1));
  };
  ConfigureRoofSizeDialog.prototype.commitChanges_7 = function (data) {
    return this.commitChanges_4(data instanceof RoofSizeDialogData ? data : THROW_CCE());
  };
  ConfigureRoofSizeDialog.$metadata$ = {
    simpleName: 'ConfigureRoofSizeDialog',
    kind: 'class',
    interfaces: []
  };
  function RoofSizeDialogData() {
    this._width_2 = 0.0;
    this._height_1 = 0.0;
  }
  RoofSizeDialogData.$metadata$ = {
    simpleName: 'RoofSizeDialogData',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_549($data) {
    var i = new _no_name_provided__577($data);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_550($tmp0_apply_0, this$0, $data) {
    var i = new _no_name_provided__578($tmp0_apply_0, this$0, $data);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_551($data) {
    var i = new _no_name_provided__579($data);
    return function (p1) {
      i.invoke_1075(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_552($tmp1_apply_0, this$0, $data) {
    var i = new _no_name_provided__580($tmp1_apply_0, this$0, $data);
    return function (p1) {
      i.invoke_1077(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__581($this_text, $data) {
    this._$this_text = $this_text;
    this._$data_9 = $data;
  }
  _no_name_provided__581.prototype.invoke_1079 = function (newValue) {
    if (newValue == null) {
      invalid(this._$this_text, i18n('Please enter a description'));
      return Unit_getInstance();
    }valid(this._$this_text);
    var tmp = this._$data_9;
    tmp._description_1 = toString_2(trim((!(newValue == null) ? isCharSequence(newValue) : false) ? newValue : THROW_CCE()));
  };
  _no_name_provided__581.prototype.invoke_1082 = function (p1) {
    this.invoke_1079((p1 == null ? true : typeof p1 === 'string') ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__581.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__582($data, this$0) {
    this._$data_10 = $data;
    this._this$0_359 = this$0;
  }
  _no_name_provided__582.prototype.invoke_1081 = function ($this$text) {
    $this$text._set_value__6(this._$data_10._description_1);
    $this$text._set_label__2(i18n('Description'));
    this._this$0_359.initialFocus_3($this$text);
    $this$text.subscribe_12(_no_name_provided_$factory_554($this$text, this._$data_10));
    Unit_getInstance();
  };
  _no_name_provided__582.prototype.invoke_1082 = function (p1) {
    this.invoke_1081(p1 instanceof Text_0 ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__582.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function UnusableAreaDescriptionDialog(descriptionProperty) {
    var tmp = i18n('Description');
    var tmp0_apply_0 = new UnusableAreaDescriptionData();
    tmp0_apply_0._description_1 = descriptionProperty._get_value__18();
    AbstractEditDialog.call(this, tmp, tmp0_apply_0);
    this._descriptionProperty_0 = descriptionProperty;
  }
  UnusableAreaDescriptionDialog.prototype.fill_13 = function (_this__0, data) {
    text$default(_this__0, null, null, null, null, false, _no_name_provided_$factory_553(data, this), 31, null);
    Unit_getInstance();
  };
  UnusableAreaDescriptionDialog.prototype.fill_14 = function (_this__0, data) {
    return this.fill_13(_this__0, data instanceof UnusableAreaDescriptionData ? data : THROW_CCE());
  };
  UnusableAreaDescriptionDialog.prototype.commitChanges_6 = function (data) {
    this._descriptionProperty_0._set_value__12(data._description_1);
  };
  UnusableAreaDescriptionDialog.prototype.commitChanges_7 = function (data) {
    return this.commitChanges_6(data instanceof UnusableAreaDescriptionData ? data : THROW_CCE());
  };
  UnusableAreaDescriptionDialog.$metadata$ = {
    simpleName: 'UnusableAreaDescriptionDialog',
    kind: 'class',
    interfaces: []
  };
  function UnusableAreaDescriptionData() {
    this._description_1 = '';
  }
  UnusableAreaDescriptionData.$metadata$ = {
    simpleName: 'UnusableAreaDescriptionData',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_553($data, this$0) {
    var i = new _no_name_provided__582($data, this$0);
    return function (p1) {
      i.invoke_1081(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_554($this_text, $data) {
    var i = new _no_name_provided__581($this_text, $data);
    return function (p1) {
      i.invoke_1079(p1);
      return Unit_getInstance();
    };
  }
  AbstractMap.prototype._get_entries__5 = Map_0.prototype._get_entries__5;
  AbstractMutableList.prototype.get_44 = List.prototype.get_44;
  IntIterator.prototype.hasNext_11 = Iterator.prototype.hasNext_11;
  AbstractMutableMap.prototype._get_entries__5 = MutableMap.prototype._get_entries__5;
  InternalHashCodeMap.prototype.createJsMap_0 = InternalMap.prototype.createJsMap_0;
  KClassImpl.prototype._get_simpleName__4 = KClass.prototype._get_simpleName__4;
  AttributeSetBuilderImpl.prototype.add$default = AttributeSetBuilder.prototype.add$default;
  AttributeSetBuilderImpl.prototype.addAll_15 = AttributeSetBuilder.prototype.addAll_15;
  ClassSetBuilderImpl.prototype.add_28 = ClassSetBuilder.prototype.add_28;
  LazyCache.prototype.clearOn_0 = SingleObjectCache.prototype.clearOn_0;
  Widget.prototype.focus_32 = Component.prototype.focus_32;
  SimplePanel.prototype.focus_32 = Component.prototype.focus_32;
  WidgetWrapper.prototype.focus_32 = Component.prototype.focus_32;
  Tag.prototype.focus_32 = Component.prototype.focus_32;
  Tag.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  FieldLabel.prototype.focus_32 = Component.prototype.focus_32;
  FieldLabel.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  InvalidFeedback.prototype.focus_32 = Component.prototype.focus_32;
  InvalidFeedback.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  Radio.prototype._set_name__6 = FormControl.prototype._set_name__6;
  Radio.prototype._get_name__47 = FormControl.prototype._get_name__47;
  Radio.prototype._set_validationStatus__10 = FormControl.prototype._set_validationStatus__10;
  Radio.prototype._set_validatorError__6 = FormControl.prototype._set_validatorError__6;
  Radio.prototype._get_validatorError__6 = FormControl.prototype._get_validatorError__6;
  AbstractText.prototype._set_validationStatus__10 = FormControl.prototype._set_validationStatus__10;
  AbstractText.prototype._set_validatorError__6 = FormControl.prototype._set_validatorError__6;
  AbstractText.prototype._get_validatorError__6 = FormControl.prototype._get_validatorError__6;
  Text_0.prototype._set_validationStatus__10 = FormControl.prototype._set_validationStatus__10;
  Text_0.prototype._set_validatorError__6 = FormControl.prototype._set_validatorError__6;
  Text_0.prototype._get_validatorError__6 = FormControl.prototype._get_validatorError__6;
  Button.prototype.focus_32 = Component.prototype.focus_32;
  Div.prototype.focus_32 = Component.prototype.focus_32;
  Div.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  Footer.prototype.focus_32 = Component.prototype.focus_32;
  Footer.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  H1.prototype.focus_32 = Component.prototype.focus_32;
  H1.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  H6.prototype.focus_32 = Component.prototype.focus_32;
  H6.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  Header.prototype.focus_32 = Component.prototype.focus_32;
  Header.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  Main.prototype.focus_32 = Component.prototype.focus_32;
  Main.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  Span.prototype.focus_32 = Component.prototype.focus_32;
  Span.prototype._set_templateData__9 = Template.prototype._set_templateData__9;
  I18n.prototype.trans_0 = I18nManager.prototype.trans_0;
  FlexPanel.prototype.focus_32 = Component.prototype.focus_32;
  HPanel.prototype.focus_32 = Component.prototype.focus_32;
  Root.prototype.focus_32 = Component.prototype.focus_32;
  CloseIcon.prototype.focus_32 = Component.prototype.focus_32;
  Modal.prototype.focus_32 = Component.prototype.focus_32;
  Confirm.prototype.focus_32 = Component.prototype.focus_32;
  ModalDialog.prototype.focus_32 = Component.prototype.focus_32;
  ButtonGroup.prototype.focus_32 = Component.prototype.focus_32;
  Spinner.prototype._set_validationStatus__10 = FormControl.prototype._set_validationStatus__10;
  SpinnerInput.prototype.focus_32 = Component.prototype.focus_32;
  DisposeSupport.prototype.onDispose_9 = OnDispose.prototype.onDispose_9;
  DefaultCachedFormatter.prototype.format$default_0 = NumberFormat.prototype.format$default_0;
  PercentageFormatter.prototype.format$default_0 = NumberFormat.prototype.format$default_0;
  ObservableObject.prototype.consume$default_3 = ReadOnlyObservableObject.prototype.consume$default_3;
  ObservableObject.prototype.consumeImmediately_3 = ReadOnlyObservableObject.prototype.consumeImmediately_3;
  ObservableObject.prototype.getValue_10 = ReadOnlyObservableObject.prototype.getValue_10;
  ObservableBoolean.prototype.consume$default_3 = ReadOnlyObservableObject.prototype.consume$default_3;
  ObservableBoolean.prototype.consumeImmediately_3 = ReadOnlyObservableObject.prototype.consumeImmediately_3;
  ObservableBoolean.prototype.getValue_10 = ReadOnlyObservableObject.prototype.getValue_10;
  ObservableDouble.prototype.consume$default_3 = ReadOnlyObservableObject.prototype.consume$default_3;
  ObservableDouble.prototype.consumeImmediately_3 = ReadOnlyObservableObject.prototype.consumeImmediately_3;
  ObservableDouble.prototype.getValue_10 = ReadOnlyObservableObject.prototype.getValue_10;
  ObservableString.prototype.consume$default_3 = ReadOnlyObservableObject.prototype.consume$default_3;
  ObservableString.prototype.consumeImmediately_3 = ReadOnlyObservableObject.prototype.consumeImmediately_3;
  ObservableString.prototype.getValue_10 = ReadOnlyObservableObject.prototype.getValue_10;
  DecimalFormat.prototype.format$default_0 = NumberFormat.prototype.format$default_0;
  AbstractChartState.prototype._set_axisOrientationX__1 = MutableChartState.prototype._set_axisOrientationX__1;
  AbstractChartState.prototype._get_axisOrientationX__3 = MutableChartState.prototype._get_axisOrientationX__3;
  AbstractChartState.prototype._set_axisOrientationY__1 = MutableChartState.prototype._set_axisOrientationY__1;
  AbstractChartState.prototype._get_axisOrientationY__3 = MutableChartState.prototype._get_axisOrientationY__3;
  AbstractChartState.prototype._set_windowTranslation__1 = MutableChartState.prototype._set_windowTranslation__1;
  AbstractChartState.prototype._get_windowTranslation__4 = MutableChartState.prototype._get_windowTranslation__4;
  AbstractChartState.prototype._set_zoom__1 = MutableChartState.prototype._set_zoom__1;
  AbstractChartState.prototype._get_zoom__1 = MutableChartState.prototype._get_zoom__1;
  AbstractChartState.prototype._set_contentAreaSize__1 = MutableChartState.prototype._set_contentAreaSize__1;
  AbstractChartState.prototype._get_contentAreaSize__4 = MutableChartState.prototype._get_contentAreaSize__4;
  AbstractChartState.prototype._set_windowSize__1 = MutableChartState.prototype._set_windowSize__1;
  AbstractChartState.prototype._get_windowSize__4 = MutableChartState.prototype._get_windowSize__4;
  AbstractChartState.prototype._get_windowSizeProperty__1 = MutableChartState.prototype._get_windowSizeProperty__1;
  AbstractChartState.prototype.onChange_1 = ObservableChartState.prototype.onChange_1;
  AbstractChartState.prototype._get_hasZeroSize__3 = ChartState.prototype._get_hasZeroSize__3;
  AbstractChartState.prototype._get_windowCenter__2 = ChartState.prototype._get_windowCenter__2;
  DefaultChartState.prototype._get_hasZeroSize__3 = ChartState.prototype._get_hasZeroSize__3;
  DefaultChartState.prototype._get_windowCenter__2 = ChartState.prototype._get_windowCenter__2;
  AbstractLayer.prototype._get_type__23 = Layer.prototype._get_type__23;
  AbstractLayer.prototype._get_description__6 = Layer.prototype._get_description__6;
  AbstractLayer.prototype.layout_6 = Layer.prototype.layout_6;
  AbstractLayer.prototype.paint_12 = Layer.prototype.paint_12;
  AbstractLayer.prototype._get_mouseEventHandler__6 = Layer.prototype._get_mouseEventHandler__6;
  AbstractLayer.prototype._get_keyEventHandler__6 = Layer.prototype._get_keyEventHandler__6;
  AbstractLayer.prototype._get_pointerEventHandler__6 = Layer.prototype._get_pointerEventHandler__6;
  AbstractLayer.prototype._get_touchEventHandler__6 = Layer.prototype._get_touchEventHandler__6;
  ClearBackgroundLayer.prototype._get_description__6 = Layer.prototype._get_description__6;
  ClearBackgroundLayer.prototype.layout_6 = Layer.prototype.layout_6;
  ClearBackgroundLayer.prototype._get_mouseEventHandler__6 = Layer.prototype._get_mouseEventHandler__6;
  ClearBackgroundLayer.prototype._get_keyEventHandler__6 = Layer.prototype._get_keyEventHandler__6;
  ClearBackgroundLayer.prototype._get_pointerEventHandler__6 = Layer.prototype._get_pointerEventHandler__6;
  ClearBackgroundLayer.prototype._get_touchEventHandler__6 = Layer.prototype._get_touchEventHandler__6;
  TextLayer.prototype._get_description__6 = Layer.prototype._get_description__6;
  TextLayer.prototype.layout_6 = Layer.prototype.layout_6;
  TextLayer.prototype._get_mouseEventHandler__6 = Layer.prototype._get_mouseEventHandler__6;
  TextLayer.prototype._get_keyEventHandler__6 = Layer.prototype._get_keyEventHandler__6;
  TextLayer.prototype._get_pointerEventHandler__6 = Layer.prototype._get_pointerEventHandler__6;
  TextLayer.prototype._get_touchEventHandler__6 = Layer.prototype._get_touchEventHandler__6;
  _no_name_provided__286.prototype.onUp_21 = CanvasKeyEventHandler.prototype.onUp_21;
  _no_name_provided__286.prototype.onType_5 = CanvasKeyEventHandler.prototype.onType_5;
  ToggleDebuggingModeLayer.prototype._get_description__6 = Layer.prototype._get_description__6;
  ToggleDebuggingModeLayer.prototype.layout_6 = Layer.prototype.layout_6;
  ToggleDebuggingModeLayer.prototype._get_mouseEventHandler__6 = Layer.prototype._get_mouseEventHandler__6;
  ToggleDebuggingModeLayer.prototype._get_pointerEventHandler__6 = Layer.prototype._get_pointerEventHandler__6;
  ToggleDebuggingModeLayer.prototype._get_touchEventHandler__6 = Layer.prototype._get_touchEventHandler__6;
  RubberBandVisualizationLayer.prototype._get_description__6 = Layer.prototype._get_description__6;
  RubberBandVisualizationLayer.prototype.layout_6 = Layer.prototype.layout_6;
  RubberBandVisualizationLayer.prototype._get_mouseEventHandler__6 = Layer.prototype._get_mouseEventHandler__6;
  RubberBandVisualizationLayer.prototype._get_keyEventHandler__6 = Layer.prototype._get_keyEventHandler__6;
  RubberBandVisualizationLayer.prototype._get_pointerEventHandler__6 = Layer.prototype._get_pointerEventHandler__6;
  RubberBandVisualizationLayer.prototype._get_touchEventHandler__6 = Layer.prototype._get_touchEventHandler__6;
  _no_name_provided__288.prototype.onClick_8 = CanvasMouseEventHandler.prototype.onClick_8;
  _no_name_provided__288.prototype.onDown_20 = CanvasMouseEventHandler.prototype.onDown_20;
  _no_name_provided__288.prototype.onUp_20 = CanvasMouseEventHandler.prototype.onUp_20;
  _no_name_provided__288.prototype.onMove_20 = CanvasMouseEventHandler.prototype.onMove_20;
  _no_name_provided__288.prototype.onDrag_13 = CanvasMouseEventHandler.prototype.onDrag_13;
  _no_name_provided__289.prototype.onStart_8 = Handler.prototype.onStart_8;
  _no_name_provided__289.prototype.onFinish_2 = Handler.prototype.onFinish_2;
  _no_name_provided__290.prototype.onStart_3 = CanvasTouchEventHandler.prototype.onStart_3;
  _no_name_provided__290.prototype.onEnd_1 = CanvasTouchEventHandler.prototype.onEnd_1;
  _no_name_provided__290.prototype.onCancel_4 = CanvasTouchEventHandler.prototype.onCancel_4;
  ZoomAndTranslationLayer.prototype._get_description__6 = Layer.prototype._get_description__6;
  ZoomAndTranslationLayer.prototype.layout_6 = Layer.prototype.layout_6;
  ZoomAndTranslationLayer.prototype._get_keyEventHandler__6 = Layer.prototype._get_keyEventHandler__6;
  UrlPaintable.prototype.paintInBoundingBox_3 = Paintable.prototype.paintInBoundingBox_3;
  AbstractCanvas.prototype._get_gc__1 = Canvas.prototype._get_gc__1;
  AbstractCanvas.prototype._get_sizeProperty__1 = Canvas.prototype._get_sizeProperty__1;
  AbstractCanvas.prototype._get_size__43 = Canvas.prototype._get_size__43;
  AbstractCanvas.prototype._get_width__41 = Canvas.prototype._get_width__41;
  AbstractCanvas.prototype._get_height__41 = Canvas.prototype._get_height__41;
  AbstractCanvas.prototype._get_physicalWidth__1 = Canvas.prototype._get_physicalWidth__1;
  AbstractCanvas.prototype._get_physicalHeight__1 = Canvas.prototype._get_physicalHeight__1;
  AbstractCanvasRenderingContext.prototype._get_canvas__1 = CanvasRenderingContext.prototype._get_canvas__1;
  AbstractCanvasRenderingContext.prototype._get_boundingBox__1 = CanvasRenderingContext.prototype._get_boundingBox__1;
  AbstractCanvasRenderingContext.prototype._get_width__41 = CanvasRenderingContext.prototype._get_width__41;
  AbstractCanvasRenderingContext.prototype._get_height__41 = CanvasRenderingContext.prototype._get_height__41;
  AbstractCanvasRenderingContext.prototype._set_font__1 = CanvasRenderingContext.prototype._set_font__1;
  AbstractCanvasRenderingContext.prototype._get_font__1 = CanvasRenderingContext.prototype._get_font__1;
  AbstractCanvasRenderingContext.prototype.font_2 = CanvasRenderingContext.prototype.font_2;
  AbstractCanvasRenderingContext.prototype.paintImage_1 = CanvasRenderingContext.prototype.paintImage_1;
  AbstractCanvasRenderingContext.prototype.paintImagePixelPerfect_1 = CanvasRenderingContext.prototype.paintImagePixelPerfect_1;
  AbstractCanvasRenderingContext.prototype.strokeLine_1 = CanvasRenderingContext.prototype.strokeLine_1;
  AbstractCanvasRenderingContext.prototype.fillRectInternal_1 = CanvasRenderingContext.prototype.fillRectInternal_1;
  AbstractCanvasRenderingContext.prototype.fillRect_4 = CanvasRenderingContext.prototype.fillRect_4;
  AbstractCanvasRenderingContext.prototype.fillRect$default_0 = CanvasRenderingContext.prototype.fillRect$default_0;
  AbstractCanvasRenderingContext.prototype.fillRect_5 = CanvasRenderingContext.prototype.fillRect_5;
  AbstractCanvasRenderingContext.prototype.strokeRect_2 = CanvasRenderingContext.prototype.strokeRect_2;
  AbstractCanvasRenderingContext.prototype.strokeRect$default_0 = CanvasRenderingContext.prototype.strokeRect$default_0;
  AbstractCanvasRenderingContext.prototype.strokeRectInternal_1 = CanvasRenderingContext.prototype.strokeRectInternal_1;
  AbstractCanvasRenderingContext.prototype.beginPath_2 = CanvasRenderingContext.prototype.beginPath_2;
  AbstractCanvasRenderingContext.prototype.closePath_2 = CanvasRenderingContext.prototype.closePath_2;
  AbstractCanvasRenderingContext.prototype.moveTo_2 = CanvasRenderingContext.prototype.moveTo_2;
  AbstractCanvasRenderingContext.prototype.lineTo_2 = CanvasRenderingContext.prototype.lineTo_2;
  AbstractCanvasRenderingContext.prototype.arcTo_2 = CanvasRenderingContext.prototype.arcTo_2;
  AbstractCanvasRenderingContext.prototype.stroke_4 = CanvasRenderingContext.prototype.stroke_4;
  AbstractCanvasRenderingContext.prototype.stroke_5 = CanvasRenderingContext.prototype.stroke_5;
  AbstractCanvasRenderingContext.prototype.fill_4 = CanvasRenderingContext.prototype.fill_4;
  AbstractCanvasRenderingContext.prototype.fill_5 = CanvasRenderingContext.prototype.fill_5;
  AbstractCanvasRenderingContext.prototype.fillText_2 = CanvasRenderingContext.prototype.fillText_2;
  AbstractCanvasRenderingContext.prototype.fillText$default_0 = CanvasRenderingContext.prototype.fillText$default_0;
  AbstractCanvasRenderingContext.prototype.calculateTextWidth_1 = CanvasRenderingContext.prototype.calculateTextWidth_1;
  AbstractCanvasRenderingContext.prototype.rect_2 = CanvasRenderingContext.prototype.rect_2;
  AbstractCanvasRenderingContext.prototype.clearRect_2 = CanvasRenderingContext.prototype.clearRect_2;
  AbstractCanvasRenderingContext.prototype.strokeStyle_2 = CanvasRenderingContext.prototype.strokeStyle_2;
  AbstractCanvasRenderingContext.prototype.fillStyle_2 = CanvasRenderingContext.prototype.fillStyle_2;
  AbstractCanvasRenderingContext.prototype.getFontMetrics_1 = CanvasRenderingContext.prototype.getFontMetrics_1;
  AbstractCanvasRenderingContext.prototype.clear_27 = CanvasRenderingContext.prototype.clear_27;
  AbstractCanvasRenderingContext.prototype._set_lineWidth__1 = CanvasRenderingContext.prototype._set_lineWidth__1;
  AbstractCanvasRenderingContext.prototype._get_lineWidth__1 = CanvasRenderingContext.prototype._get_lineWidth__1;
  AbstractCanvasRenderingContext.prototype._set_lineJoin__1 = CanvasRenderingContext.prototype._set_lineJoin__1;
  ChartSupport.prototype.onDispose_9 = OnDispose.prototype.onDispose_9;
  Image.prototype.paintInBoundingBox_3 = Paintable.prototype.paintInBoundingBox_3;
  Image.prototype.paintSizeForced_3 = Paintable.prototype.paintSizeForced_3;
  QuickChartBuilder.prototype.onDispose_9 = OnDispose.prototype.onDispose_9;
  TruncateToLength.prototype.shorten$default_0 = StringShortener.prototype.shorten$default_0;
  _no_name_provided__372.prototype.onOver_5 = CanvasPointerEventHandler.prototype.onOver_5;
  _no_name_provided__372.prototype.onEnter_5 = CanvasPointerEventHandler.prototype.onEnter_5;
  _no_name_provided__372.prototype.onLeave_5 = CanvasPointerEventHandler.prototype.onLeave_5;
  _no_name_provided__373.prototype.onClick_8 = CanvasMouseEventHandler.prototype.onClick_8;
  _no_name_provided__373.prototype.onDoubleClick_8 = CanvasMouseEventHandler.prototype.onDoubleClick_8;
  _no_name_provided__373.prototype.onMove_20 = CanvasMouseEventHandler.prototype.onMove_20;
  _no_name_provided__373.prototype.onWheel_7 = CanvasMouseEventHandler.prototype.onWheel_7;
  CanvasFactoryJS.prototype.createCanvas$default = CanvasFactory.prototype.createCanvas$default;
  CanvasJS.prototype._get_width__41 = Canvas.prototype._get_width__41;
  CanvasJS.prototype._get_height__41 = Canvas.prototype._get_height__41;
  CanvasRenderingContextJS.prototype.fillText$default_0 = CanvasRenderingContext.prototype.fillText$default_0;
  CanvasRenderingContextJS.prototype._get_boundingBox__1 = CanvasRenderingContext.prototype._get_boundingBox__1;
  CanvasRenderingContextJS.prototype.font_2 = CanvasRenderingContext.prototype.font_2;
  CanvasRenderingContextJS.prototype.fillRect_4 = CanvasRenderingContext.prototype.fillRect_4;
  CanvasRenderingContextJS.prototype.fillRect$default_0 = CanvasRenderingContext.prototype.fillRect$default_0;
  CanvasRenderingContextJS.prototype.fillRect_5 = CanvasRenderingContext.prototype.fillRect_5;
  CanvasRenderingContextJS.prototype.strokeRect_2 = CanvasRenderingContext.prototype.strokeRect_2;
  CanvasRenderingContextJS.prototype.strokeRect$default_0 = CanvasRenderingContext.prototype.strokeRect$default_0;
  CanvasRenderingContextJS.prototype.stroke_5 = CanvasRenderingContext.prototype.stroke_5;
  CanvasRenderingContextJS.prototype.fill_5 = CanvasRenderingContext.prototype.fill_5;
  CanvasRenderingContextJS.prototype.getFontAlignmentCorrection_0 = CanvasRenderingContext.prototype.getFontAlignmentCorrection_0;
  CanvasRenderingContextJS.prototype.clear_27 = CanvasRenderingContext.prototype.clear_27;
  QuickChartBuilderJS.prototype.onDispose_9 = OnDispose.prototype.onDispose_9;
  QuickChartJS.prototype.dispose_46 = QuickChart.prototype.dispose_46;
  QuickChartJS.prototype.onDispose_10 = QuickChart.prototype.onDispose_10;
  QuickChartJS.prototype.markAsDirty_4 = QuickChart.prototype.markAsDirty_4;
  LocalResourcePaintable.prototype.paintInBoundingBox_3 = Paintable.prototype.paintInBoundingBox_3;
  LocalResourcePaintable.prototype.paintSizeForced_3 = Paintable.prototype.paintSizeForced_3;
  _no_name_provided__447.prototype.onDown_20 = CanvasMouseEventHandler.prototype.onDown_20;
  _no_name_provided__447.prototype.onUp_20 = CanvasMouseEventHandler.prototype.onUp_20;
  _no_name_provided__447.prototype.onDoubleClick_8 = CanvasMouseEventHandler.prototype.onDoubleClick_8;
  _no_name_provided__447.prototype.onDrag_13 = CanvasMouseEventHandler.prototype.onDrag_13;
  _no_name_provided__447.prototype.onWheel_7 = CanvasMouseEventHandler.prototype.onWheel_7;
  _no_name_provided__448.prototype.onDown_21 = CanvasKeyEventHandler.prototype.onDown_21;
  _no_name_provided__448.prototype.onType_5 = CanvasKeyEventHandler.prototype.onType_5;
  RoofPlanningLayer.prototype._get_description__6 = Layer.prototype._get_description__6;
  RoofPlanningLayer.prototype._get_pointerEventHandler__6 = Layer.prototype._get_pointerEventHandler__6;
  RoofPlanningLayer.prototype._get_touchEventHandler__6 = Layer.prototype._get_touchEventHandler__6;
  UnusableAreaPaintable.prototype.paintSizeForced_3 = Paintable.prototype.paintSizeForced_3;
  _stableSortingIsSupported = null;
  output = output$init$();
  functionClasses = functionClasses$init$();
  buf = new ArrayBuffer(8);
  bufFloat64 = bufFloat64$init$();
  bufInt32 = bufInt32$init$();
  lowIndex = lowIndex$init$();
  highIndex = 1 - lowIndex | 0;
  ZERO = fromInt(0);
  ONE = fromInt(1);
  NEG_ONE = fromInt(-1);
  MAX_VALUE = new Long(-1, 2147483647);
  MIN_VALUE = new Long(0, -2147483648);
  TWO_PWR_24_ = fromInt(16777216);
  propertyRefClassMetadataCache = propertyRefClassMetadataCache$init$();
  EmptySerializersModule = new SerialModuleImpl(emptyMap(), emptyMap(), emptyMap(), emptyMap());
  attributesModule = attributesModule_ext.default;
  classModule = classModule_ext.default;
  datasetModule = datasetModule_ext.default;
  eventListenersModule = eventListenersModule_ext.default;
  heroModule = heroModule_ext.default;
  htmlDomApi = htmlDomApiModule.default;
  propsModule = propsModule_ext.default;
  styleModule = styleModule_ext.default;
  routing = routing$init$();
  auto = new Pair(0, UNIT_auto_getInstance());
  normal = new Pair(0, UNIT_normal_getInstance());
  hex = hex$init$();
  defaultJson = Json$default(null, _no_name_provided_$factory_205(), 1, null);
  kVManagerReduxInit = KVManagerRedux_getInstance().init_16();
  kVManagerBootstrapInit = KVManagerBootstrap_getInstance().init_16();
  kVManagerBootstrapCssInit = KVManagerBootstrapCss_getInstance().init_16();
  kVManagerDatetimeInit = KVManagerDatetime_getInstance().init_16();
  kVManagerSelectInit = KVManagerSelect_getInstance().init_16();
  kVManagerSpinnerInit = KVManagerSpinner_getInstance().init_16();
  kVManagerUploadInit = KVManagerUpload_getInstance().init_16();
  kVManagerFontAwesomeInit = KVManagerFontAwesome_getInstance().init_16();
  kVManagerI18nInit = KVManagerI18n_getInstance().init_16();
  kVManagerRichTextInit = KVManagerRichText_getInstance().init_16();
  kVManagerHandlebarsInit = KVManagerHandlebars_getInstance().init_16();
  kVManagerChartInit = KVManagerChart_getInstance().init_16();
  kVManagerTabulatorInit = KVManagerTabulator_getInstance().init_16();
  kVManagerPaceInit = KVManagerPace_getInstance().init_16();
  kVManagerMomentInit = KVManagerMoment_getInstance().init_16();
  kVManagerToastInit = KVManagerToast_getInstance().init_16();
  kVManagerPrintInit = KVManagerPrint_getInstance().init_16();
  formatRegex = Regex_init_$Create$('%([-]?\\d+)?(\\w)');
  replaceNonPrintableCharactersRegex$delegate = lazy(_no_name_provided_$factory_249());
  emptyArrayOfBytes = new Int8Array(0);
  emptyArrayOfInts = new Int32Array(0);
  emptyArrayOfDoubles = new Float64Array(0);
  emptyArrayOfFloats = new Float32Array(0);
  cacheStatsHandler = null;
  KlockLocale_default = Companion_getInstance_21();
  ExtendedTimezoneNames = ExtendedTimezoneNames$init$();
  klockIsKotlinNative = false;
  isNode = typeof window === 'undefined';
  initialHrTime$delegate = klockAtomicLazy(_no_name_provided_$factory_254());
  opacityFormat = decimalFormat_0(2, 0, 1, false);
  defaultTimeZoneOffsetProvider = defaultTimeZoneOffsetProvider$init$();
  timeZoneOffsetProvider = defaultTimeZoneOffsetProvider;
  dataFormatIso8601 = dataFormatIso8601$init$();
  dateFormatUTC = dateFormatUTC$init$();
  timeFormat = timeFormat$init$();
  timeFormatWithMillis = timeFormatWithMillis$init$();
  dateFormat = dateFormat$init$();
  dateTimeFormat = dateTimeFormat$init$();
  dateTimeFormatShort = dateTimeFormatShort$init$();
  dateTimeFormatWithMillis = dateTimeFormatWithMillis$init$();
  dateTimeFormatShortWithMillis = dateTimeFormatShortWithMillis$init$();
  decimalFormat = decimalFormat$init$();
  intFormat = DecimalFormatsCache_getInstance().get_44(0);
  decimalFormat1digit = decimalFormat1digit$init$();
  decimalFormat2digits = decimalFormat2digits$init$();
  percentageFormat = percentageFormat$init$();
  percentageFormat2digits = percentageFormat2digits$init$();
  percentageFormat0digits = percentageFormat0digits$init$();
  decimalFormatFromPatternCache = cache('decimalFormatCache', 100);
  DefaultLocale = (new DefaultLocaleProvider())._get_defaultLocale_();
  DefaultTimeZone = (new DefaultTimeZoneProvider())._get_defaultTimeZone_();
  localeOptionsCache = cache('localeOptionsCache', 10);
  environment = extractEnvironment(new Environment(false, 1.0));
  LatitudeTopEdge = LatitudeTopEdge$init$();
  LatitudeBottomEdge = LatitudeBottomEdge$init$();
  SlippyMapTilePhysicalSize = new Size(256.0, 256.0);
  defaultFillProvider = defaultFillProvider$init$();
  defaultSizeProvider = defaultSizeProvider$init$();
  currentFrameTimestampOrNull = null;
  imageCache = cache('CachedImageDownloader', 100);
  corporateDesign = NeckarITDesign_getInstance();
  emptyCategoryLinePainter = new DelegatingCategoryLinePainter(NoLinePainter_getInstance());
  emptyCategoryPointPainter = new DelegatingCategoryPointPainter(NoPointPainter_getInstance());
  deltaRelativeMillisToAbsolute = 0.0;
  additionalMouseCursorTypes = listOf(['zoom-in', 'zoom-out', 'help', 'context-menu', 'pointer', 'progress', 'cell', 'not-allowed', 'all-scroll', 'col-resize', 'row-resize', 'no-drop']);
  main_0();
  return _;
}));
